import {v4} from 'uuid';
import moment from 'moment-timezone';
import {IEmployeesCardCreateParams} from '../../types';
import Demo, {DemoApiHandler} from '../demo';
import {DEMO_USER_ID} from '../../constants/demo';

const createEmployeesCard: DemoApiHandler = ({config, instance, data}) => {
  const items = instance.getData().employees[instance.getData().productId];

  if (items.find((a) => a.status !== 'Deleted' && a.mobile === data.mobile)) {
    return Demo.makeDataResponse<{
      message: string;
      error_code: number;
      error_text: string;
    }>(config, {
      message: 'Unprocessable Entity',
      error_code: 90978,
      error_text: 'employee with same mobile already exists',
    });
  }

  const currentData = data as IEmployeesCardCreateParams;

  items.push({
    ...data,
    id: v4(),
    iban: '',
    name: currentData.name || 'DEMO NAME',
    person_first_name: 'DEMO FIRST NAME',
    person_last_name: 'DEMO LAST NAME',
    mobile: currentData.mobile || '+40777777777',
    email: currentData.email || 'demo@demo.demo',
    status: 'Active',
    created_at: moment().toISOString(),
    created_by: DEMO_USER_ID,
    last_invited_at: moment().toISOString(),
  });

  instance.saveImpl(['employees']);

  return Demo.makeDataResponse<string>(config, 'ok');
};

export default createEmployeesCard;
