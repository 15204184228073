import {AxiosRequestConfig, AxiosResponse} from 'axios';
import appConfig from '../constants/config';

type CacheListener = (data?: AxiosResponse, error?: Error) => void;

type CacheItem = {
  listeners: CacheListener[];
  ttl: number;
  response: AxiosResponse | null;
  error: Error | null;
};

class RequestCache {
  private static instance: RequestCache | null = null;

  public static getInstance(): RequestCache {
    if (RequestCache.instance === null) {
      RequestCache.instance = new RequestCache();
    }
    return RequestCache.instance;
  }

  public static makeCacheKey(config: AxiosRequestConfig): string {
    return `${config.url}_${JSON.stringify(config.params)}_${JSON.stringify(
      config.cacheTags
    )}`;
  }

  private cache: Record<string, CacheItem> = {};

  public clearCache(): void {
    this.cache = {};
  }

  public isCached(config: AxiosRequestConfig): boolean {
    if (config.method?.toLowerCase() === 'get') {
      const key = RequestCache.makeCacheKey(config);
      if (typeof this.cache[key] !== 'undefined') {
        const cacheItem = this.cache[key];
        const ts = +new Date();
        if (cacheItem.ttl >= ts) {
          return true;
        }
        delete this.cache[key];
      }
    }

    return false;
  }

  public shouldThrottle(
    config: AxiosRequestConfig,
    noCache?: boolean
  ): boolean {
    return (
      config.method?.toLowerCase() === 'get' &&
      (noCache === true ||
        typeof this.cache[RequestCache.makeCacheKey(config)] === 'undefined')
    );
  }

  public waitForResponse(config: AxiosRequestConfig, noCache?: boolean): void {
    const key = RequestCache.makeCacheKey(config);
    this.cache[key] = {
      // listeners: this.cache[key]?.listeners || [],
      listeners: noCache === true ? this.cache[key]?.listeners || [] : [],
      ttl: +new Date() + appConfig.requestCacheTTL * 1000,
      response: null,
      error: null,
    };
    const deleteProc = (ttl: number) => {
      setTimeout(() => {
        const ts = +new Date();
        if (this.cache[key]) {
          if (
            this.cache[key].ttl < ts &&
            this.cache[key].listeners.length === 0
          ) {
            delete this.cache[key];
          } else {
            deleteProc(1000);
          }
        }
      }, ttl);
    };
    deleteProc(appConfig.requestCacheTTL * 1000 + 50);
  }

  public setCachedResponse(
    config: AxiosRequestConfig,
    response: AxiosResponse
  ): void {
    if (config.method?.toLowerCase() === 'get') {
      const key = RequestCache.makeCacheKey(config);
      if (typeof this.cache[key] !== 'undefined') {
        const cacheItem = this.cache[key];

        cacheItem.response = response;
        cacheItem.listeners.forEach((listener) => {
          listener(response);
        });
        cacheItem.listeners = [];
      }
    }
  }

  public setCachedError(config: AxiosRequestConfig, error: Error): void {
    if (config.method?.toLowerCase() === 'get') {
      const key = RequestCache.makeCacheKey(config);
      if (typeof this.cache[key] !== 'undefined') {
        const cacheItem = this.cache[key];
        cacheItem.listeners.forEach((listener) => {
          listener(undefined, error);
        });
        cacheItem.listeners = [];
        cacheItem.error = error;
      }
    }
  }

  public getCachedResponse(config: AxiosRequestConfig): Promise<AxiosResponse> {
    return new Promise<AxiosResponse>((response, reject) => {
      const key = RequestCache.makeCacheKey(config);
      if (typeof this.cache[key] !== 'undefined') {
        const cacheItem = this.cache[key];
        if (cacheItem.response !== null) {
          response(cacheItem.response);
        } else if (cacheItem.error) {
          reject(cacheItem.error);
        } else {
          // this.cache[key].ttl = +new Date() + appConfig.requestCacheTTL * 1000;
          this.cache[key].listeners.push((responseResult, errorResult) => {
            if (responseResult) {
              response(responseResult);
            } else if (errorResult) {
              reject(errorResult);
            }
          });
        }
      }
    });
  }
}

export default RequestCache;
