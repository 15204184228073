import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
  Store,
  StoreWritable,
} from 'effector';
import TUTORIAL, {DEMO_TUTORIAL} from '../../constants/tutorial';
import {AppState, TUTORIAL_STEP} from '../../types';
import SYSTEM_NOTIFICATION_DIALOG_TYPES from '../../types/systemDialogNotifications';
import TutorialStep from '../../types/tutorial';
import {App$} from '../app';
import {closeSystemNotificationDialog} from '../systemDialogNotifications';

type TutorialInnerStoreType = {
  active: boolean;
  activeStep: TutorialStep | null;
  finishStep: boolean;
  existingTutorialTargets: TUTORIAL_STEP[];
  prepared?: boolean;
};

type TutorialStoreType = TutorialInnerStoreType & {
  steps: TutorialStep[];
};

export type PopupPlacement =
  | 'topLeft'
  | 'top'
  | 'topRight'
  | 'rightTop'
  | 'right'
  | 'rightBottom'
  | 'bottomLeft'
  | 'bottom'
  | 'bottomRight'
  | 'leftTop'
  | 'left'
  | 'leftBottom';

export type TutorialPrepareParams = {
  target: HTMLDivElement;
  placement: PopupPlacement;
  step: TUTORIAL_STEP;
};

export type ShowTutorialStepParams = {
  step: TUTORIAL_STEP;
  target: HTMLDivElement;
  prepare?: (data: TutorialPrepareParams) => Promise<void>;
  placement: PopupPlacement;
  targetProps?: {
    deltaWidth?: number;
    deltaHeight?: number;
    deltaX?: number;
    deltaY?: number;
  };
};

const TutorialInnerStore: StoreWritable<TutorialInnerStoreType> = createStore<TutorialInnerStoreType>(
  {
    active: false,
    activeStep: null,
    finishStep: false,
    existingTutorialTargets: [],
  }
);

const TutorialStore = combine<
  StoreWritable<TutorialInnerStoreType>,
  Store<AppState>,
  TutorialStoreType
>(TutorialInnerStore, App$, (inner, app) => {
  return {
    ...inner,
    steps: (app.config.demo ? DEMO_TUTORIAL : TUTORIAL).filter((item) => {
      return inner.existingTutorialTargets.includes(item.id);
    }),
  };
});

export const startTutorial = createEvent('startTutorial');

sample({
  source: TutorialStore,
  target: TutorialInnerStore,
  clock: startTutorial,
  fn: (store) => {
    if (!store.active && store.steps.length > 0) {
      return {...store, activeStep: store.steps[0], active: true};
    }
    return store;
  },
});

export const nextStep = createEvent('nextStep');

export const tutorialShowFinishDialog = createEvent('tutorialShowFinishDialog');

sample({
  source: TutorialStore,
  target: closeSystemNotificationDialog,
  clock: nextStep,
  fn: () => {
    return {dialog: SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL};
  },
  filter: (store) => {
    if (store.active && store.activeStep && store.steps.length > 0) {
      const currentIndex = store.steps.findIndex(
        (s) => s.id === store.activeStep?.id
      );
      if (currentIndex === store.steps.length - 1) {
        return true;
      }
    }
    return false;
  },
});

sample({
  source: TutorialStore,
  target: TutorialInnerStore,
  clock: nextStep,
  fn: (store) => {
    if (store.active && store.activeStep && store.steps.length > 0) {
      const currentIndex = store.steps.findIndex(
        (s) => s.id === store.activeStep?.id
      );
      if (currentIndex !== -1 && store.steps.length - 1 >= currentIndex + 1) {
        return {
          ...store,
          activeStep: store.steps[currentIndex + 1] || store.steps[0],
        };
      }
      return {
        ...store,
        finishStep: true,
        active: false,
        activeStep: null,
      };
    }
    return store;
  },
});

export const showTutorialStep = createEffect<
  ShowTutorialStepParams,
  ShowTutorialStepParams
>({
  name: '',
  handler: async (params) => {
    if (params.prepare) {
      await params.prepare(params);
    }

    return params;
  },
});

TutorialInnerStore.on(showTutorialStep, (store) => {
  return {
    ...store,
    prepared: false,
  };
});

TutorialInnerStore.on(showTutorialStep.done, (store) => {
  return {
    ...store,
    prepared: true,
  };
});

export const confirmFinish = createEvent('confirmFinish');
TutorialInnerStore.on(confirmFinish, (store) => {
  return {...store, finishStep: false};
});

export const setTutorialTargetExisting = createEvent<TUTORIAL_STEP>(
  'setTutorialTargetExisting'
);
TutorialInnerStore.on(setTutorialTargetExisting, (State, step) => {
  const newState: TUTORIAL_STEP[] = [...State.existingTutorialTargets].filter(
    (s) => s !== step
  );
  if (step) {
    newState.push(step);
  }

  return {
    ...State,
    existingTutorialTargets: newState,
  };
});

export const setTutorialTargetDestroyed = createEvent<TUTORIAL_STEP>(
  'setTutorialTargetDestroyed'
);
TutorialInnerStore.on(setTutorialTargetDestroyed, (State, step) => {
  return {
    ...State,
    existingTutorialTargets: [...State.existingTutorialTargets].filter(
      (s) => s !== step
    ),
  };
});
export default TutorialStore;
