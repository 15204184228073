import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
  Store,
} from 'effector';
import moment from 'moment-timezone';
import {
  getAllowedCountries,
  getAllowedEndpoints,
  updateUserTimezone,
} from '../../api';
import AppStatus from '../../constants/app';
import config from '../../constants/config';
import {TTimeZone} from '../../constants/dateTime';
import Endpoints from '../../constants/endpoints';
import {InitialProductKey} from '../../constants/routes';
import logger from '../../logger';
import AppService from '../../services/AppService';
import AuthService from '../../services/AuthService';
import SavedFiltersService from '../../services/SavedFiltersService';
import WebSocketService from '../../services/WebSocketService';
import {
  AllowedEndpoints,
  AppConfig,
  AppInnerState,
  AppMenuItem,
  AppRestrictedCountries,
  AppState,
  CountryCodes3l,
  CurrencyType,
  RestrictedCountryType,
  SystemUser,
  TypedMap,
  UUID4,
} from '../../types';
import BrowserDetect from '../../utils/browserDetect';
import ObjectKeys from '../../utils/object';
import RequestError from '../../utils/RequestError';
import {Verification} from '../../utils/VerificationHelper';
import clearAppStores, {softClearAppStores} from '../clearStore';
import {
  ClientVerificationChangeEvent,
  updateClientVerificationState,
} from '../client';
import CurrentClientStore from '../currentClient';
import CurrentUserStore, {reloadCurrentUser} from '../currentUser';
import {updateFilterStoreDateFilter} from '../filterStore';
import unloadHints from '../hints/unload';
import {ProductStore, setCurrentProduct} from '../product';
import {getProductSettingsFx} from '../productSettings';
import {replaceRoute} from '../router';
import SystemAccountStore from '../systemAccount';

const log = logger.module('App/actions');

function getGuestMenuItems(): AppMenuItem[] {
  return [];
}

export const AppInner$ = createStore<AppInnerState>({
  pathname: window.location.pathname,
  title: 'Wallester',
  menuItems: getGuestMenuItems(),
  isGuest: true,
  appStatus: AppStatus.wait,
  config: {...config},
  isMobile: BrowserDetect.isMobile(),
  refreshTokenExpireTs: 0,
  userUpdated: false,
  allowedEndpoints: null,
  restrictedCountries: {},
  designChanging: false,
  isWhiteLabeled: false,
});

export const App$: Store<AppState> = combine(
  AppInner$,
  CurrentUserStore,
  CurrentClientStore,
  SystemAccountStore,
  ProductStore,
  (
    innerStore,
    userStore,
    clientStore,
    systemAccountStore,
    productStore
  ): AppState => {
    const mobileVerified = true;
    let fullyVerified = false;
    let {isWhiteLabeled} = innerStore;
    let isClientFrozen = false;
    let systemAccountId: UUID4 | null = null;
    let companyId: UUID4 | null = null;
    let systemAccountCurrencyCode: CurrencyType | null = null;

    if (clientStore) {
      // mobileVerified = Boolean(clientStore.mobile_verified);
      fullyVerified = Verification.isFullyVerified(clientStore);
      isClientFrozen = !isWhiteLabeled && clientStore.status === 'Frozen';
      if (!productStore) {
        isWhiteLabeled = clientStore.white_label === true;
      }
    }

    if (!isWhiteLabeled && systemAccountStore) {
      systemAccountId = systemAccountStore.id;
      companyId = systemAccountStore.company_id || null;
      systemAccountCurrencyCode = systemAccountStore.currency_code;
    }

    return {
      ...innerStore,
      mobileVerified,
      fullyVerified,
      isWhiteLabeled,
      systemAccountId,
      companyId,
      isClientFrozen,
      systemAccountCurrencyCode,
    };
  }
);

AppInner$.reset(clearAppStores);

export const setIsWhiteLabeled = createEvent<boolean>('updateAppStatus');

export const updateAppStatus = createEvent<AppStatus>('updateAppStatus');
export const updateAppConfig = createEvent<AppConfig>('updateAppConfig');
export const setBrowserCompatibilityModalVisibleState = createEvent<boolean>(
  'setBrowserCompatibilityModalVisibleState'
);
export const setServiceWorkerRegistration = createEvent<ServiceWorkerRegistration>(
  'setServiceWorkerRegistration'
);

export const setTitle = createEvent<string | undefined>('setTitle');

export const setIsGuest = createEvent();

export const updateAuthData = createEvent<{
  sessionStorage?: Record<string, string>;
  refreshTokenExpiredTs?: number;
}>('updateAuthData');

export const reloadAllowedEndpoints = createEffect<
  void,
  AllowedEndpoints,
  Error
>({
  name: 'reloadAllowedEndpoints',
  handler: async (): Promise<AllowedEndpoints> => {
    const endpoints = await getAllowedEndpoints();
    const allowedEndpoints: AllowedEndpoints = {};
    endpoints.forEach((endpoint) => {
      const be: Endpoints[] = [
        // 'POST:/v1/product/pricing-plan',
        // 'PATCH:/v1/product/pricing-plan/cancel',
        // 'PATCH:/v1/companies/{company_id}/limits',
        // 'POST:/v1/product/api-keys',
        // 'DELETE:/v1/product/api-keys/{api_key_id}',
        // 'POST:/v1/accounts',
        // 'PATCH:/v1/accounts/{account_id}/balance',
        // 'DELETE:/v1/accounts/{account_id}/close',
        // 'PATCH:/v1/accounts/{account_id}/close',
        // 'PATCH:/v1/accounts/{account_id}/credit-limit',
        // 'PATCH:/v1/accounts/{account_id}/limits',
        // 'PATCH:/v1/accounts/{account_id}/name',
        // 'PATCH:/v1/accounts/{account_id}/reopen',
        // 'PUT:/v1/accounts/{account_id}/top-up-details',
        // 'POST:/v1/accounts/{account_id}/generate-top-up-pdf',
        // 'PATCH:/v1/batch/cards/block',
        // 'PATCH:/v1/batch/cards/close',
        // 'PATCH:/v1/batch/cards/replace',
        // 'PATCH:/v1/batch/cards/unblock',
        // 'POST:/v1/cards',
        // 'DELETE:/v1/cards/{card_id}/3d-secure',
        // 'PATCH:/v1/cards/{card_id}/3d-secure',
        // 'PATCH:/v1/cards/{card_id}/3d-secure/settings',
        // 'PATCH:/v1/cards/{card_id}/activate',
        // 'PATCH:/v1/cards/{card_id}/block',
        // 'PATCH:/v1/cards/{card_id}/close',
        // 'PATCH:/v1/cards/{card_id}/delivery-address',
        // 'POST:/v1/cards/{card_id}/encrypted-3ds-password',
        // 'POST:/v1/cards/{card_id}/encrypted-card-number',
        // 'POST:/v1/cards/{card_id}/encrypted-cvv2',
        // 'POST:/v1/cards/{card_id}/encrypted-pin',
        // 'PATCH:/v1/cards/{card_id}/limits',
        // 'PATCH:/v1/cards/{card_id}/link-to-account/{account_id}',
        // 'PATCH:/v1/cards/{card_id}/name',
        // 'PATCH:/v1/cards/{card_id}/pin',
        // 'PATCH:/v1/cards/{card_id}/replace',
        // 'PATCH:/v1/cards/{card_id}/security',
        // 'PATCH:/v1/cards/{card_id}/unblock',
        // 'DELETE:/v1/cards/{card_id}/users',
        // 'POST:/v1/cards/{card_id}/users',
      ] as Endpoints[];
      if (!be.includes(endpoint)) {
        allowedEndpoints[endpoint] = true;
      }
    });

    return allowedEndpoints;
  },
});

export const loadRestrictedCountries = createEffect<
  RestrictedCountryType[],
  TypedMap<RestrictedCountryType, CountryCodes3l[]>,
  Error
>({
  name: 'loadRestrictedCountries',
  handler: async (): Promise<
    TypedMap<RestrictedCountryType, CountryCodes3l[]>
  > => {
    const ret: TypedMap<
      RestrictedCountryType,
      CountryCodes3l[]
    > = await getAllowedCountries();

    return ret;
  },
});

export const updateTimezone = createEffect<TTimeZone, SystemUser, Error>({
  name: 'updateTimezone',
  handler: async (timeZone): Promise<SystemUser> => {
    return updateUserTimezone(timeZone);
  },
});

export const loadProfile = createEffect<
  void,
  {
    refreshTokenExpiredTs: number;
    sessionStorage: Record<string, string>;
  } | null,
  Error
>('loadProfile', {
  handler: async (): Promise<{
    refreshTokenExpiredTs: number;
    sessionStorage: Record<string, string>;
  } | null> => {
    const isValid = await AuthService.getInstance().checkToken();

    if (isValid) {
      const user = await reloadCurrentUser();
      // await reloadCurrentClient();
      // await reloadSystemAccount();
      const tokenInfo = AuthService.getInstance().getTokenInfo();
      if (tokenInfo && user) {
        return {
          refreshTokenExpiredTs: tokenInfo.refresh_token_expire_ts || 0,
          sessionStorage: tokenInfo.sessionStorage || {},
        };
      }
    }
    return null;
  },
});

AppInner$.on(setTitle, (store, title) => {
  let newTitle = 'Wallester';
  if (title !== undefined && title.trim() !== '') {
    newTitle = title.trim();
  }
  document.title = newTitle;
  return {
    ...store,
    title: newTitle,
  };
});

AppInner$.on(updateAppConfig, ($store, newConfig) => ({
  ...$store,
  config: {...newConfig},
}));

AppInner$.on(
  setServiceWorkerRegistration,
  (State, serviceWorkerRegistration) => {
    return {
      ...State,
      serviceWorkerRegistration,
    };
  }
);

AppInner$.on(setBrowserCompatibilityModalVisibleState, (State, isVisible) => {
  return {
    ...State,
    browserCompatibilityModalVisible: isVisible,
  };
});

AppInner$.on(updateAppStatus, (store$, status: AppStatus) => ({
  ...store$,
  appStatus: status,
}));

AppInner$.on(setIsWhiteLabeled, (store$, isWhiteLabeled) => ({
  ...store$,
  isWhiteLabeled,
}));

AppInner$.on(loadProfile.doneData, (store$, result) => {
  if (result !== null) {
    return {
      ...store$,
      refreshTokenExpireTs: result.refreshTokenExpiredTs,
      sessionStorage: result.sessionStorage,
      isGuest: false,
    };
  }
  return {...store$};
});

AppInner$.on(
  updateAuthData,
  (store$, {sessionStorage, refreshTokenExpiredTs}) => {
    return {
      ...store$,
      // isGuest: false,
      sessionStorage,
      refreshTokenExpireTs: refreshTokenExpiredTs || 0,
    };
  }
);

AppInner$.on(updateTimezone, (store) => {
  return {
    ...store,
    userUpdated: true,
  };
});

AppInner$.on(loadRestrictedCountries.done, (store, {result}) => {
  const restrictedCountries: AppRestrictedCountries = {
    ...store.restrictedCountries,
  };
  ObjectKeys(RestrictedCountryType).forEach((en) => {
    const type = RestrictedCountryType[en];
    const typeData = restrictedCountries[type] || {};
    const resultData =
      result[type] === undefined ? result.countries || [] : result[type] || [];

    resultData.forEach((countryCode) => {
      typeData[countryCode] = true;
    });
    restrictedCountries[type] = typeData;
  });

  return {
    ...store,
    restrictedCountries,
  };
});

AppInner$.on(reloadAllowedEndpoints.doneData, (store, allowedEndpoints) => {
  return {
    ...store,
    allowedEndpoints,
  };
});

AppInner$.on(reloadAllowedEndpoints.fail, (store) => {
  return {
    ...store,
    allowedEndpoints: null,
  };
});

AppInner$.on(updateTimezone.finally, (store) => {
  return {
    ...store,
    userUpdated: false,
  };
});

CurrentUserStore.on(updateTimezone.doneData, (store$, user) => {
  if (store$) {
    return {
      ...store$,
      timezone_name: user.timezone_name,
    };
  }
  return store$;
});

AppInner$.on(setIsGuest, (store$) => {
  return {
    ...store$,
    isGuest: true,
    lastLogoutAt: moment().format(),
  };
});

clearAppStores.watch(() => {
  softClearAppStores();
  unloadHints();
});

type initAuthActionParams = {
  cleanLoad: boolean;
  productId?: UUID4;
  silentMode?: boolean;
};
export const initAuthAction = createEffect<
  initAuthActionParams,
  boolean,
  Error
>({
  name: 'initAuthAction',
  handler: async ({cleanLoad, productId, silentMode}) => {
    const {isGuest} = App$.getState();
    const status = isGuest ? await AuthService.getInstance().init() : true;
    if (status) {
      // await initServerStorage();
      if (cleanLoad) {
        const profile = (CurrentUserStore.getState()?.profiles || []).find(
          (p) =>
            p.target_entity_name === 'product' &&
            p.target_entity_id === productId
        );
        await AuthService.getInstance().reAssignProfile(
          profile?.id || null,
          silentMode
        );
      }
      await reloadAllowedEndpoints();
      await getProductSettingsFx();
      // await loadTopUpInfo();
    }
    return status;
  },
});

const handleClientVerificationChange = (
  data: Record<string, unknown>
): void => {
  const typedData: ClientVerificationChangeEvent = data as ClientVerificationChangeEvent;
  updateClientVerificationState(typedData);
};

initAuthAction.done.watch(({result}) => {
  if (result) {
    updateFilterStoreDateFilter(
      SavedFiltersService.getInstance().getPeriodDateFilter()
    );

    WebSocketService.getInstance()
      .init()
      .then(() => {
        const client = CurrentClientStore.getState();
        const {isWhiteLabeled, fullyVerified} = App$.getState();
        if (client) {
          const clientId = client.id;
          if (clientId && !isWhiteLabeled && !fullyVerified) {
            WebSocketService.getInstance().subscribe(
              `clients/${clientId}/verification`,
              handleClientVerificationChange
            );
          }
        }
      });
  }
});

export const loadConfigAction = createEffect({
  name: 'loadConfigAction',
  handler: async () => {
    try {
      const appConfig = await AppService.loadAppConfig();
      log.info('Loaded app config', {appConfig});
      ObjectKeys(appConfig).forEach((key) => {
        if (appConfig[key] !== undefined) {
          // eslint-disable-next-line
          // @ts-ignore
          config[key] = appConfig[key];
        }
      });
      return {...config, ...appConfig};
    } catch (e) {
      // it is not critical error
      log.info('Failure load app config', {error: e.message});
    }
    return {...config};
  },
});

export const initAppAction = createEffect<UUID4 | undefined, void>({
  name: 'initAppAction',
  handler: async (initialProductId) => {
    log.info('initAppAction start');
    if (config.hasMissingTranslation && !config.isProd && !config.isSandbox) {
      log.warn('Current build has missing translation!!!');
    }

    try {
      await loadConfigAction();
      await loadRestrictedCountries([]);
      if (
        await initAuthAction({
          cleanLoad: true,
          productId: initialProductId,
        })
      ) {
        if (initialProductId) {
          const url = new URL(window.location.href);
          url.searchParams.delete(InitialProductKey);
          replaceRoute(url.pathname);
        }
      }

      updateAppStatus(AppStatus.initialized);
      log.info('Init app actions finished');
    } catch (err) {
      const error = err as RequestError;
      const response = error && error.getResponse();
      if (response && response.status === 403) {
        updateAppStatus(AppStatus.forbidden);
      } else {
        updateAppStatus(AppStatus.error);
      }
      if (!response || response.type !== 'common') {
        log.error('Error in initAppAction', {error: error.message});
      }
    }
  },
});

export const setDesignChanging = createEvent<boolean>('setDesignChanging');

AppInner$.on(setDesignChanging, (state, payload) => {
  return {
    ...state,
    designChanging: payload,
  };
});

sample({
  clock: loadConfigAction.done.map(({result}) => {
    return result;
  }),
  target: updateAppConfig,
});

sample({
  clock: setCurrentProduct,
  target: setIsWhiteLabeled,
  fn: (product) => {
    return AuthService.isProductWhiteLabeled(product);
  },
});
