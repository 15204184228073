import moment from 'moment-timezone';
import Demo, {DemoApiHandler} from '../demo';
import {CardLimitWithUsage, ICard, UpdateCardLimitParams} from '../../types';
import {hideCardPrivacyInfo} from './getCard';

type TLimitType =
  | 'purchase'
  | 'withdrawal'
  | 'internet_purchase'
  | 'contactless_purchase';

const updateCardLimit: DemoApiHandler = ({config, instance, url, data}) => {
  const item = instance
    .getData()
    .cards[instance.getData().productId].find(
      (a) => a.id === url?.params?.card_id
    );

  if (item) {
    const {productSettings} = instance.getData();
    const currentData = data.limits as UpdateCardLimitParams;
    const type: TLimitType = (() => {
      const field = Object.keys(currentData)[0];
      let t: TLimitType = 'purchase';

      ([
        'internet_purchase',
        'withdrawal',
        'contactless_purchase',
        'purchase',
      ] as TLimitType[]).some((a) => {
        if (field.includes(a)) {
          t = a;
          return true;
        }

        return false;
      });

      return t;
    })();
    const transaction =
      currentData[`transaction_${type}` as keyof CardLimitWithUsage];
    const daily = currentData[
      `daily_${type}` as keyof CardLimitWithUsage
    ] as number;
    const monthly = currentData[
      `monthly_${type}` as keyof CardLimitWithUsage
    ] as number;

    if (
      productSettings.max_card_daily_contactless_purchase_limit < daily ||
      productSettings.max_card_monthly_contactless_purchase_limit < monthly
    ) {
      return Demo.makeUnprocessableEntityResponse(config, 90236);
    }

    item.limits = {
      ...item.limits,
      [`transaction_${type}`]: transaction,

      [`daily_${type}`]: daily,
      [`weekly_${type}`]: monthly,
      [`monthly_${type}`]: monthly,

      [`daily_${type}_available`]: daily,
      [`weekly_${type}_available`]: monthly,
      [`monthly_${type}_available`]: monthly,
    };
    item.updated_at = moment().toISOString();

    instance.saveImpl(['cards']);

    return Demo.makeDataResponse<{card: ICard}>(config, {
      card: hideCardPrivacyInfo(item),
    });
  }

  return Demo.makeUnprocessableEntityResponse(config, 30001);
};

export default updateCardLimit;
