import * as H from 'history';
import React, {useEffect} from 'react';
import {replaceRoute} from '../../models/router';
import {AvailableLanguages, DefaultLanguage, UserLocale} from '../../types';
import {getCleanUrl} from '../../utils/language';

type Props = React.PropsWithChildren<{
  // eslint-disable-next-line
  match: any;
  location: H.Location;
  defaultLanguage?: UserLocale;
  language?: UserLocale;
}>;

const LangRedirector: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  match,
  location,
  language,
  defaultLanguage,
}: Props) => {
  useEffect(() => {
    const params = match ? match.params : {};
    const {lang = language || defaultLanguage || DefaultLanguage} = params;
    const {pathname, search} = location;
    if (pathname && !pathname.match(new RegExp(`/${lang}(?:/|$)`))) {
      replaceRoute(`/${lang}${getCleanUrl(pathname)}${search}`);
    } else if (!AvailableLanguages.includes(lang)) {
      replaceRoute(
        `/${DefaultLanguage}${getCleanUrl(pathname, [lang])}${search}`
      );
    }
  }, [defaultLanguage, language, location, match]);

  return <>{children}</>;
};

export default LangRedirector;
