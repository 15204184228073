import {UUID4} from '../types';

export const UUIDtoBytes = (id: string): number[] => {
  return (id.replace(/-/g, '').match(/.{2}/g) || []).map((b) =>
    parseInt(b, 16)
  );
};

export const isValidUUIDv4 = (id: UUID4): boolean => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    id
  );
};

export const genColorFromUUID4 = (id: UUID4, colors: string[]): string => {
  const data = UUIDtoBytes(id);
  const POLY = 0xc;
  const INIT = 0;
  const XOROUT = 0;
  let crc = INIT;
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < data.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    crc ^= data[i];
    for (let j = 0; j < 8; j += 1) {
      // eslint-disable-next-line no-bitwise
      crc = crc & 1 ? (crc >>> 1) ^ POLY : crc >>> 1;
    }
  }
  // eslint-disable-next-line no-bitwise
  const hash = (crc ^ XOROUT) & 0xf;
  const index = Math.abs((hash % colors.length) + 1);

  return colors[index];
};
