import config from './config';

export enum AppStatus {
  wait,
  initialized,
  error,
  forbidden,
}

export const LastProductKey = 'WALLESTER_LAST_PRODUCT';

export const minimalPageSize = config.tablePageSizes[0];

export default AppStatus;
