import {UUID4} from './common';
import {CountryCodes3l} from './geo';
import {CardDispatchMethod, TCardType} from './card';

export enum BlackCard3DSecurityType {
  SMSOTPAndStaticPassword = 'SMSOTPAndStaticPassword',
}

export interface BlackCard3DSecureSettings {
  mobile?: string;
  email?: string;
  password: string;
}

export type BlackCreateCardParams = {
  account_id: UUID4;
  type: TCardType;
  name?: string;
  embossing_name?: string;
  '3d_secure_settings'?: BlackCard3DSecureSettings;
  encrypted_pin?: string;
  delivery_address?:
    | {
        first_name?: string;
        last_name?: string;
        address1?: string;
        address2?: string;
        city?: string;
        company_name?: string;
        country_code?: CountryCodes3l;
        dispatch_method?: CardDispatchMethod;
        phone?: string;
        postal_code?: string;
      }
    | undefined;
};
