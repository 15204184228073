import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import {IFee, IUnresolvedFee, UUID4} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const getFee = async (id: UUID4): Promise<IFee> => {
  const {data: response} = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getFee, {fee_id: id}));
  return response.fee;
};

export const getUnresolvedFees = async (): Promise<IUnresolvedFee[]> => {
  const response: AxiosResponse<{
    fees: IUnresolvedFee[];
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getUnresolvedFees));

  return response.data.fees || [];
};

export default getFee;
