import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import logger from '../logger';
import {
  IProductBase,
  ReservedSettlementBalance,
  UUID4,
  IProductContactInfo,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import config from '../constants/config';

const log = logger.module('Products');

export const getAssignedProducts = async (): Promise<IProductBase[]> => {
  const response: AxiosResponse<{
    products: IProductBase[];
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({endpoint: Endpoints.assignedProduct});

  return (response.data.products || []).sort((a, b) =>
    a.code.localeCompare(b.code)
  );
};

export const getProductContactInfo = async (
  productId: UUID4
): Promise<IProductContactInfo> => {
  log.debug(`Load Contact Info for product ${productId}`);

  return {
    phone: config.supportPhone,
    email: config.supportEmail,
    whatsapp: config.supportWhatsAppPhone,
    telegram: config.supportTelegramPhone,
    viber: config.supportViberPhone,
  };
};

export const getReservedSettlementBalance = async (
  productId?: UUID4
): Promise<ReservedSettlementBalance> => {
  const response: AxiosResponse<ReservedSettlementBalance> = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      {endpoint: Endpoints.getReservedSettlementBalance},
      {
        cacheTags: productId ? {productId} : undefined,
      }
    );

  return response.data;
};

export default getAssignedProducts;
