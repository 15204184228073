import {
  combine,
  createEffect,
  createStore,
  Store,
  StoreWritable,
} from 'effector';
import {getAssignedProducts, getCurrentUser} from '../../api';
import AuthService from '../../services/AuthService';
import {IProductWithProfile, ProductsStoreType} from '../../types';
import attachErrorHandler from '../../utils/error';
import clearAppStores from '../clearStore';
import FeatureStore, {FeatureStoreType} from '../features';
import Features, {TreatmentStatus} from '../../constants/features';

const defaultProductsInnerStore: IProductWithProfile[] = [];

export const ProductsInnerStore = createStore<ProductsStoreType>(
  defaultProductsInnerStore
);

export const ProductsStore = combine<
  StoreWritable<ProductsStoreType>,
  Store<FeatureStoreType>,
  ProductsStoreType
>(ProductsInnerStore, FeatureStore, (innerState, featureStore) => {
  if (
    featureStore.commonIsReady &&
    (featureStore.treatments || {})[Features.EnableDebtResolutionFlow]
      ?.treatment === TreatmentStatus.Enabled
  ) {
    return innerState;
  }
  return innerState.map((product) => {
    return {
      ...product,
      product_additional_info: {
        ...product.product_additional_info,
        has_debts: false,
      },
    };
  });
});

ProductsInnerStore.reset(clearAppStores);

export const getAssignedProductsFx = createEffect<
  void,
  IProductWithProfile[],
  Error
>({
  name: 'getAssignedProductsFx',
  handler: async () => {
    const {profiles} = await getCurrentUser();
    return AuthService.getProductWithProfiles(
      await getAssignedProducts(),
      profiles
    );
  },
});

ProductsInnerStore.on(getAssignedProductsFx.doneData, (state, products) => {
  return products;
});

attachErrorHandler([getAssignedProductsFx]);
