import {Employee, EmployeeStatus} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const getEmployees: DemoApiHandler = ({config, instance, url}) => {
  let items = instance.getData().employees[instance.getData().productId];

  if (url?.queryParams?.statuses && (url?.queryParams?.statuses as []).length) {
    items = items.filter((a) =>
      (url?.queryParams?.statuses as EmployeeStatus[]).includes(a?.status)
    );
  }

  if (url?.queryParams?.employee_name) {
    items = items.filter((a) =>
      a?.name
        .toLowerCase()
        .includes((url?.queryParams?.employee_name as string).toLowerCase())
    );
  }

  items = Demo.applySorterAndPager(
    items,
    Demo.toListSortOrderParams(url.queryParams)
  );

  return Demo.makeDataResponse<{
    employees: Employee[];
    total_records_number: number;
  }>(config, {
    employees: items,
    total_records_number: items.length,
  });
};

export default getEmployees;
