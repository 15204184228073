import React from 'react';
import useIsHintsAllowed from '../../../customHooks/useIsHintsAllowed';
import useSessionStorageValue from '../../../customHooks/useSessionStorageValue';
import HintRouteItem from '../HintRouteItem';
import HintSingleItem from '../HintSingleItem';
import HintModal from '../HintModal';
import HintsShadow from '../HintsShadow';

const HintsLayer: React.FC = () => {
  const isHintsAllowed = useIsHintsAllowed();
  const initialHintsClosedForSession = useSessionStorageValue(
    'WALLESTER_INIT_HINTS_CLOSED'
  );

  if (!isHintsAllowed || initialHintsClosedForSession) {
    return null;
  }

  return (
    <>
      <HintsShadow />

      <HintModal />

      <HintSingleItem />

      <HintRouteItem />
    </>
  );
};

export default HintsLayer;
