import {message} from 'antd';
import {Effect, Event} from 'effector';
import RequestError from './RequestError';

// eslint-disable-next-line
function isEffect(
  // eslint-disable-next-line
  unit: Effect<any, any, Error> | Event<Error>
  // eslint-disable-next-line
): unit is Effect<any, any, Error> {
  // eslint-disable-next-line
  return (unit as Effect<any, any, Error>).doneData !== undefined;
}

export const attachErrorHandler = (
  // eslint-disable-next-line
  units: (Effect<any, any, Error> | Event<Error>)[]
): void => {
  units.forEach((unit) => {
    if (isEffect(unit)) {
      unit.fail.watch(({error}) => {
        if ((error as RequestError).getBusinessLogicError === undefined) {
          message.error(error.message);
        }
      });
    } else {
      unit.watch((payload) => {
        message.error(payload.message);
      });
    }
  });
};
export const withCatch = (catchFunction: () => void): void => {
  try {
    catchFunction();
  } catch (e) {
    //
  }
};

export default attachErrorHandler;
