import * as Sentry from '@sentry/react';
// import {Integrations} from '@sentry/tracing';
import logger from '..';
import config from '../../constants/config';

const environment = config.sentryEnv;
const log = logger.module('Sentry');
if (config.sentryEnable && config.sentryDSN !== '') {
  const sentryConf: Sentry.BrowserOptions = {
    environment,
    dsn: config.sentryDSN,
    release: config.version,
    autoSessionTracking: config.sentryAutoSessionTracking,
    // integrations: (integrations) => {
    //   console.log(integrations);
    //   return integrations;
    // },
    // integrations: [new Integrations.BrowserTracing()],
  };
  log.info('Sentry initialize', {sentryConf});
  Sentry.init(sentryConf);

  Sentry.configureScope((scope) => {
    scope.setTag('build', config.buildNumber);
  });
} else {
  log.info('Sentry DISABLED');
}

export default Sentry;
