import Demo, {DemoApiHandler} from '../demo';

const addCardUser: DemoApiHandler = ({config, instance, url}) => {
  const item = instance
    .getData()
    .cards[instance.getData().productId].find(
      (a) => a.id === url?.params?.card_id
    );

  // TODO

  if (item) {
    instance.saveImpl(['cards']);

    return Demo.makeDataResponse<string>(config, 'ok');
  }

  return Demo.makeUnprocessableEntityResponse(config, 30001);
};

export default addCardUser;
