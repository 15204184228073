import {AxiosResponse} from 'axios';
import Endpoints, {WLEndpoints} from '../constants/endpoints';
import {
  MoneyTransferToAccountRequestParams,
  UUID4,
  UpdateCompanyLimitsParams,
  MoneyTransferToEmployeeRequestParams,
  LimitUsageInfo,
  ICompany,
  CreateCompanyParams,
  CompanyHistoryRequestParams,
  ICompanyHistory,
  CompanyHistoryItem,
  CreateCompanyRepresentativeParams,
  CompanyRepresentative,
  CurrencyRequestParams,
  CurrencyRequestConfig,
} from '../types';
import {RequestCompanyLimitChangeParams} from '../types/request-company-limit-change';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const getCompanyRequest = async ({
  id,
  noCache,
}: {
  id: UUID4;
  noCache?: boolean;
}): Promise<ICompany> => {
  const response: AxiosResponse<{
    company: ICompany;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(Endpoints.getCompany, {company_id: id}), {
      noCache,
    });
  return response.data.company;
};

export const deactivateCompanyRequest = async (id: UUID4): Promise<boolean> => {
  const response: AxiosResponse<'ok'> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .delete({
      endpoint: WLEndpoints.deactivateCompany,
      params: {company_id: id},
    });
  return response.data === 'ok';
};

export const makeEmployeeMoneyTransfer = async (
  params: MoneyTransferToEmployeeRequestParams
): Promise<boolean> => {
  // return true;
  const response: AxiosResponse<
    'ok' | 'err'
  > = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.makeMoneyTransferToEmployee), params);
  return response.data === 'ok';
};

export const makeAccountMoneyTransfer = async (
  params: MoneyTransferToAccountRequestParams
): Promise<boolean> => {
  // return true;
  const response: AxiosResponse<
    'ok' | 'err'
  > = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.makeMoneyTransferToAccount), params);
  return response.data === 'ok';
};

export const requestCompanyLimitChange = async (
  params: RequestCompanyLimitChangeParams
): Promise<boolean> => {
  const response: AxiosResponse<'ok'> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.requestCompanyLimitChange), params);
  return response.data === 'ok';
};

export const updateCompanyLimits = async (
  params: UpdateCompanyLimitsParams
): Promise<ICompany> => {
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .patch(
      getApiPathParamsMaker(Endpoints.updateCompanyLimits, {
        company_id: params.companyId,
      }),
      {
        limits: params,
      }
    );
  return response.data.company;
};

export const getCompanyLimitsUsage = async (
  id: UUID4
): Promise<LimitUsageInfo> => {
  // return {
  //   purchase: {
  //     daily: 1,
  //     monthly: 2,
  //     weekly: 3,
  //   },
  //   withdrawal: {
  //     daily: 11,
  //     monthly: 12,
  //     weekly: 13,
  //   },
  //   contactless_purchase: {
  //     daily: 4,
  //     monthly: 5,
  //     weekly: 6,
  //   },
  //   internet_purchase: {
  //     daily: 14,
  //     monthly: 15,
  //     weekly: 16,
  //   },
  //   overall_purchase: {
  //     daily: 7,
  //     monthly: 8,
  //     weekly: 9,
  //   },
  // };
  const response: AxiosResponse<LimitUsageInfo> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(Endpoints.getCompanyLimitsUsage, {company_id: id})
    );
  return response.data;
};

export const createCompany = async (
  company: CreateCompanyParams
): Promise<ICompany> => {
  const response: AxiosResponse<{
    company: ICompany;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .post({endpoint: WLEndpoints.createCompany}, company);
  return response.data.company;
};

export const updateCompany = async (
  id: UUID4,
  company: Partial<CreateCompanyParams>
): Promise<ICompany> => {
  const response: AxiosResponse<{
    company: ICompany;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .patch(
      {endpoint: WLEndpoints.updateCompany, params: {company_id: id}},
      company
    );
  return response.data.company;
};

export const createCompanyRepresentative = async (
  companyId: UUID4,
  representative: CreateCompanyRepresentativeParams
): Promise<CompanyRepresentative> => {
  const response: AxiosResponse<{
    representatives: CompanyRepresentative[];
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .post(
      {
        endpoint: WLEndpoints.createCompanyRepresentative,
        params: {company_id: companyId},
      },
      {representatives: [representative]}
    );
  return response.data.representatives[0];
};

export const getCompanyHistoryRequest = async ({
  currentPage,
  companyId,
  pageSize,
  fromDate,
  toDate,
}: CompanyHistoryRequestParams): Promise<ICompanyHistory> => {
  const response: AxiosResponse<{
    history: CompanyHistoryItem[];
    total_records_number: number;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get({
      endpoint: WLEndpoints.getCompanyHistory,
      params: {company_id: companyId},
      queryParams: {
        from_record: (currentPage - 1) * pageSize,
        records_count: pageSize,
        from_date: fromDate.clone().startOf('day').utc().format(),
        to_date: toDate.clone().endOf('day').utc().format(),
      },
    });
  return {
    items: response.data.history || [],
    totalItems: response.data.total_records_number,
  };
};

export const getCurrencyRequestConfig = async (): Promise<CurrencyRequestConfig> => {
  const response: AxiosResponse<CurrencyRequestConfig> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({
      endpoint: Endpoints.getMultiCurrencyRequestConfig,
    });
  return response.data;
};

export const sendCurrencyRequest = async (
  currencies: CurrencyRequestParams[]
): Promise<boolean> => {
  const response: AxiosResponse<'ok'> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(
      {endpoint: Endpoints.currencyRequest},
      {multi_currency_requests: currencies}
    );
  return response.data === 'ok';
};

export default getCompanyRequest;
