import {App$} from '../models/app';
import {CurrentLocaleStore} from '../models/i18n';
import {UserLocale} from '../types';

export const getCleanUrl = (
  url: string,
  extraLocales?: UserLocale[]
): string => {
  let _url = url || '';
  if (_url[0] !== '/') {
    _url = `/${_url}`;
  }
  const pathParts = _url.split('/');
  const systemLocales: UserLocale[] = [
    ...App$.getState().config.locales,
    ...(extraLocales || []),
  ];

  for (let i = 0; i < systemLocales.length; i += 1) {
    if (pathParts[1] === systemLocales[i]) {
      pathParts.splice(1, 1);
    }
  }

  return pathParts.join('/');
};

export const getUrl = (
  url: string,
  locale: UserLocale | null = null,
  absolute?: boolean
): string => {
  const lang = CurrentLocaleStore.getState();
  let _url = getCleanUrl(url || '');
  if (_url[0] !== '/') {
    _url = `/${_url}`;
  }
  let publicPart = '';
  if (absolute) {
    publicPart = `${window.location.origin}`;
  }
  return `${publicPart}/${locale || lang.locale}${_url}`;
};

export default getUrl;
