import {createEvent, createStore} from 'effector';
import * as H from 'history';
import {createBrowserHistory, createPath, History} from 'history';
import routes from '../../constants/routes';
import {GoToLoginMode, UserLocale} from '../../types';
import getUrl, {getCleanUrl} from '../../utils/language';
import {softClearAppStores} from '../clearStore';
import {setBreadcrumbs, setHeaderParams} from '../header';
import redirect from './redirect';

interface ICurrentRoute {
  path: string;
  url: string;
  route: string;
  previousUrl: string | null;
}

export interface RouterState {
  history?: History;
  currentRoute: ICurrentRoute | null;
}

export const history = createBrowserHistory();
let isGotoLoginFired = false;
let fullURL = `${history.location.pathname}${history.location.search}${history.location.hash}`;

history.listen(({pathname, search, hash}) => {
  const currentFullURL = `${pathname}${search}${hash}`;

  if (getCleanUrl(currentFullURL) !== getCleanUrl(fullURL)) {
    setHeaderParams({});
    setBreadcrumbs();
  }

  fullURL = currentFullURL;
});

export const RouterStore$ = createStore<RouterState>({
  history,
  currentRoute: null,
});

RouterStore$.reset(softClearAppStores);

export const setCurrentRoute = createEvent<
  Omit<ICurrentRoute, 'previousUrl'>
>();
RouterStore$.on(setCurrentRoute, (state$, payload) => {
  return {
    ...state$,
    currentRoute: {
      ...payload,
      previousUrl: state$.currentRoute?.url || null,
    },
  };
});

export {redirect};

redirect.watch(
  (url: H.LocationDescriptor, locale: UserLocale | null = null) => {
    const historyState = RouterStore$.getState().history;
    if (historyState) {
      historyState.push(
        getUrl(
          typeof url === 'string' ? url : createPath(url),
          locale !== null ? locale : null
        )
      );
    }
  }
);

export const replaceRoute = createEvent<string>();

replaceRoute.watch((url: string, locale: UserLocale | null = null) => {
  const historyState = RouterStore$.getState().history;

  if (historyState) {
    historyState.replace(getUrl(url, locale !== null ? locale : null));
  }
});

export const getPreviousUrl = (): string => {
  return RouterStore$.getState().currentRoute?.previousUrl || routes.main.path;
};

export const getCurrentUrl = (): string => {
  return RouterStore$.getState().currentRoute?.url || routes.main.path;
};

export const gotoLogin = (mode?: GoToLoginMode): void => {
  if (!isGotoLoginFired) {
    isGotoLoginFired = true;
    window.location.href = getUrl(
      `${routes.signIn.path}${mode ? `?${mode}` : ''}`
    );
  }
};

export const goBack = createEvent('goBack');
goBack.watch(() => {
  const backUrl = getPreviousUrl();
  redirect(backUrl);
});
