import moment from 'moment-timezone';
import {v4} from 'uuid';
import {EAccountStatuses, IAccount} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const addAccount: DemoApiHandler = ({config, instance, data}) => {
  const {productId} = instance.getData();
  const {accounts} = instance.getData();

  if (typeof accounts[productId] === 'undefined') {
    accounts[productId] = [];
  }

  const newAccount: IAccount = {
    ...accounts[productId][0],
    id: v4(),
    created_at: moment().format(),
    available_amount: 0,
    blocked_amount: 0,
    is_main: false,
    balance: 0,
    name: data.name as string,
    status: EAccountStatuses.ACTIVE,
    credit_limit: 15000,
    used_credit: 0,
    currency_code: instance.getCurrentCurrency(),
  };
  accounts[productId].push(newAccount);

  instance.saveImpl(['accounts']);

  return Demo.makeDataResponse<{account: IAccount}>(config, {
    account: newAccount,
  });
};

export default addAccount;
