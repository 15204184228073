import {signDataImpl} from '../crypto';
import Demo, {DemoApiHandler} from '../demo';

const getCardCvvCode: DemoApiHandler = ({config, instance, url, data}) => {
  const item = instance
    .getData()
    .cards[instance.getData().productId].find(
      (a) => a.id === url?.params?.card_id
    );

  if (item && data.public_key) {
    const pem = atob(data.public_key as string);

    return Demo.makeDataResponse<{encrypted_cvv2: string}>(config, {
      encrypted_cvv2: atob(
        signDataImpl(`${item.cvv || 123}`, pem, 'CVV2') || ''
      ),
    });
  }

  return Demo.makeUnprocessableEntityResponse(config, 30001);
};

export default getCardCvvCode;
