import {
  ActivityType,
  IClient,
  TypedMap,
  VerificationStatus,
  ClientCompany,
  ClientPerson,
  CurrencyRequestCardSpendingAmount,
  CurrencyRequestExpectedTurnover,
  DocumentsVerificationFormDocs,
} from '../types';
import ObjectKeys from './object';
import {SelectGroup, SelectItem} from '../components/Select';

import {getIntl} from '../models/i18n';

export class Verification {
  readonly client: IClient;

  static isFullyVerified(client: IClient): boolean {
    return (
      client.agreement_signed_at !== undefined &&
      client.agreement_signed_at !== null
    );
  }

  static isOnBoardingVerified(client: IClient): boolean {
    return client.onboarding_verification_status === 'Confirmed';
  }

  static getVerificationStatusesLabelKeys = (): TypedMap<
    VerificationStatus,
    string
  > => {
    return {
      [VerificationStatus.NotConfirmed]: 'App-Not_confirmed',
      [VerificationStatus.PendingApproval]: 'App-Pending_approval',
      [VerificationStatus.Confirmed]: 'App-Confirmed',
      [VerificationStatus.VerificationFailed]: 'App-Verification_failed',
      [VerificationStatus.Rejected]: 'App-Rejected',
      [VerificationStatus.Required]: 'App-Identity_verification_required',
    };
  };

  static getVerificationActivityTypeLabelKeys = (): TypedMap<
    ActivityType,
    string
  > => {
    return {
      [ActivityType.ActivitiesOfExtraterritorialOrganizationsAndBodies]:
        'CompanyInformationVerification-Activities_of_extraterritorial_organizations_and_bodies',
      [ActivityType.AdministrativeActivity]:
        'CompanyInformationVerification-Administrative_activity',
      [ActivityType.AdultEntertainment]:
        'CompanyInformationVerification-Adult_entertainment',
      [ActivityType.AdvertisingMarketing]:
        'CompanyInformationVerification-Advertising__Marketing',
      [ActivityType.AgricultureForestryFisheries]:
        'CompanyInformationVerification-Agriculture__forestry__fisheries',
      [ActivityType.AlcoholicBeveragesAndTobacco]:
        'CompanyInformationVerification-Alcoholic_beverages_and_tobacco',
      [ActivityType.AlimentaryProducts]:
        'CompanyInformationVerification-Alimentary_products',
      [ActivityType.ArchitectureEngineeringAndTechnicalConsultingInTheseAreas]:
        'CompanyInformationVerification-Architecture__engineering__and_technical_consulting_in_these',
      [ActivityType.ArtEntertainmentAndRecreation]:
        'CompanyInformationVerification-Art__entertainment__and_recreation',
      [ActivityType.ArtworksCollectionItemsAntiques]:
        'CompanyInformationVerification-Artworks__collection_items__antiques',
      [ActivityType.BeverageService]:
        'CompanyInformationVerification-Beverage_service',
      [ActivityType.BoardAndLodging]:
        'CompanyInformationVerification-Board_and_lodging',
      [ActivityType.BuildingAndTerritoryMaintenance]:
        'CompanyInformationVerification-Building_and_territory_maintenance',
      [ActivityType.Charity]: 'CompanyInformationVerification-Charity',
      [ActivityType.CinemaVideoFilmsAndTvProgramsSoundRecordingAndRecordedMusic]:
        'CompanyInformationVerification-Cinema__video_films__and_TV_programs__sound_recording_and_re',
      [ActivityType.ClothingAndFootwear]:
        'CompanyInformationVerification-Clothing_and_footwear',
      [ActivityType.ComputerProgrammingConsultingAndOtherRelatedServices]:
        'CompanyInformationVerification-Computer_programming__consulting__and_other_related_services',
      [ActivityType.ConstructionActivities]:
        'CompanyInformationVerification-Construction_activities',
      [ActivityType.CreativeWorkAndArt]:
        'CompanyInformationVerification-Creative_work_and_art',
      [ActivityType.CreditsOrMonetaryIntermediation]:
        'CompanyInformationVerification-Credits_or_monetary_intermediation',
      [ActivityType.Cryptocurrencies]:
        'CompanyInformationVerification-Cryptocurrencies',
      [ActivityType.DataProcessingWebPortals]:
        'CompanyInformationVerification-Data_processing__web_portals',
      [ActivityType.Design]: 'CompanyInformationVerification-Design',
      [ActivityType.DomesticAppliancesAndElectronics]:
        'CompanyInformationVerification-Domestic_appliances_and_electronics',
      [ActivityType.Education]: 'CompanyInformationVerification-Education',
      [ActivityType.Energy]: 'CompanyInformationVerification-Energetics',
      [ActivityType.EngineeringTests]:
        'CompanyInformationVerification-Engineering_tests',
      [ActivityType.EventManagement]:
        'CompanyInformationVerification-Event_management',
      [ActivityType.FinancialAndInsuranceServices]:
        'CompanyInformationVerification-Financial_and_insurance_services',
      [ActivityType.FoodDelivery]:
        'CompanyInformationVerification-Food_delivery',
      [ActivityType.FuelAndGas]: 'CompanyInformationVerification-Fuel_and_gas',
      [ActivityType.FundManagement]:
        'CompanyInformationVerification-Fund_management',
      [ActivityType.GamblingGamesAndBetting]:
        'CompanyInformationVerification-Gambling_games_and_betting',
      [ActivityType.HealthCareAndSocialServices]:
        'CompanyInformationVerification-Health_care_and_social_services',
      [ActivityType.HighValueGoods]:
        'CompanyInformationVerification-High_value_goods',
      [ActivityType.HoldingCompaniesAndPropertyManagement]:
        'CompanyInformationVerification-Holding_companies_and_property_management',
      [ActivityType.HotelsAndRelatedAccommodation]:
        'CompanyInformationVerification-Hotels_and_related_accommodation',
      [ActivityType.HrAgencies]: 'CompanyInformationVerification-HR_agencies',
      [ActivityType.InformationAndCommunication]:
        'CompanyInformationVerification-Information_and_communication',
      [ActivityType.InformationAndReferenceServices]:
        'CompanyInformationVerification-Information_and_reference_services',
      [ActivityType.Insurance]: 'CompanyInformationVerification-Insurance',
      [ActivityType.InvestmentActivities]:
        'CompanyInformationVerification-Investment_activities',
      [ActivityType.LandscapeDesign]:
        'CompanyInformationVerification-Landscape_design',
      [ActivityType.LegalServicesAndAccounting]:
        'CompanyInformationVerification-Legal_services_and_accounting',
      [ActivityType.LibrariesArchivesMuseums]:
        'CompanyInformationVerification-Libraries__archives__museums',
      [ActivityType.LodgingAndCateringServices]:
        'CompanyInformationVerification-Lodging_and_catering_services',
      [ActivityType.ManagementConsulting]:
        'CompanyInformationVerification-Management_consulting',
      [ActivityType.Manufacturing]:
        'CompanyInformationVerification-Processing_and_manufacturing',
      [ActivityType.MedicationAndPharmaceuticals]:
        'CompanyInformationVerification-Medication_and_pharmaceuticals',
      [ActivityType.MiningIndustry]:
        'CompanyInformationVerification-Mining_industry',
      [ActivityType.MotorVehicles]:
        'CompanyInformationVerification-Motor_vehicles',
      [ActivityType.OffshoreServices]:
        'CompanyInformationVerification-Offshore_services',
      [ActivityType.Other]: 'App-Other',
      [ActivityType.OtherAccommodationTypes]:
        'CompanyInformationVerification-Other_accommodation_types',
      [ActivityType.OtherAdministrativeActivities]:
        'CompanyInformationVerification-Other_administrative_activities',
      [ActivityType.OtherFinancialServicesExcludingInsuranceAndPensionFundServices]:
        'CompanyInformationVerification-Other_financial_services__excluding_insurance_and_pension_fu',
      [ActivityType.OtherGoods]: 'CompanyInformationVerification-Other_goods',
      [ActivityType.OtherInformationServices]:
        'CompanyInformationVerification-Other_information_services',
      [ActivityType.OtherProfessionalScientificAndTechnicalActivities]:
        'CompanyInformationVerification-Other_professional__scientific__and_technical_activities',
      [ActivityType.OtherServices]:
        'CompanyInformationVerification-Other_services',
      [ActivityType.OtherServicesProvision]:
        'CompanyInformationVerification-Other_services_provision',
      [ActivityType.PensionFunds]:
        'CompanyInformationVerification-Pension_funds',
      [ActivityType.Photography]: 'CompanyInformationVerification-Photography',
      [ActivityType.PoliticalOrganization]:
        'CompanyInformationVerification-Political_organization',
      [ActivityType.PrivateBanking]:
        'CompanyInformationVerification-Private_banking',
      [ActivityType.ProfessionalMembershipOrganization]:
        'CompanyInformationVerification-Professional_membership_organization',
      [ActivityType.ProfessionalScientificAndTechnicalActivities]:
        'CompanyInformationVerification-Professional__scientific__and_technical_activities',
      [ActivityType.PublicAdministrationAndDefense]:
        'CompanyInformationVerification-Public_administration_and_defense',
      [ActivityType.PublishingActivities]:
        'CompanyInformationVerification-Publishing_activities',
      [ActivityType.RadioBroadcasting]:
        'CompanyInformationVerification-Radio_broadcasting',
      [ActivityType.RealEstateActivities]:
        'CompanyInformationVerification-Real_estate_activities',
      [ActivityType.RealEstateLeasingAndManagement]:
        'CompanyInformationVerification-Real_estate_leasing_and_management',
      [ActivityType.RealEstatePurchaseAndSale]:
        'CompanyInformationVerification-Real_estate_purchase_and_sale',
      [ActivityType.RealEstateTransactions]:
        'CompanyInformationVerification-Real_estate_transactions',
      [ActivityType.RecreationAndEntertainment]:
        'CompanyInformationVerification-Recreation_and_entertainment',
      [ActivityType.ReligiousOrganization]:
        'CompanyInformationVerification-Religious_organization',
      [ActivityType.Renovation]: 'CompanyInformationVerification-Renovation',
      [ActivityType.RentingLeasing]:
        'CompanyInformationVerification-Renting__leasing',
      [ActivityType.RepairingServices]:
        'CompanyInformationVerification-Repairing_services',
      [ActivityType.Restaurants]: 'App-Restaurants',
      [ActivityType.ScientificResearchAndDevelopment]:
        'CompanyInformationVerification-Scientific_research_and_development',
      [ActivityType.SecurityAndInvestigation]:
        'CompanyInformationVerification-Security_and_investigation',
      [ActivityType.ShortTermAccommodationServices]:
        'CompanyInformationVerification-Short_term_accommodation_services',
      [ActivityType.Software]: 'CompanyInformationVerification-Software',
      [ActivityType.Sport]: 'CompanyInformationVerification-Sport',
      [ActivityType.SupportingActivitiesRelatedToFinancialServices]:
        'CompanyInformationVerification-Supporting_activities_related_to_financial_services',
      [ActivityType.SupportingActivitiesRelatedToInsuranceAndPensionFunds]:
        'CompanyInformationVerification-Supporting_activities_related_to_insurance_and_pension_funds',
      [ActivityType.Telecommunications]:
        'CompanyInformationVerification-Telecommunications',
      [ActivityType.TourOperatorTravelAgent]:
        'CompanyInformationVerification-Tour_operator__travel_agent',
      [ActivityType.TouristCampsRecreationAndEntertainmentParks]:
        'CompanyInformationVerification-Tourist_camps__recreation_and_entertainment_parks',
      [ActivityType.TradeUnion]: 'CompanyInformationVerification-Trade_union',
      [ActivityType.TranslationServices]:
        'CompanyInformationVerification-Translation_services',
      [ActivityType.TransportationAndStorage]:
        'CompanyInformationVerification-Transportation_and_warehousing',
      [ActivityType.TrustsFundsAndOtherRelatedFinancialFacilities]:
        'CompanyInformationVerification-Trusts__funds__and_other_related_financial_facilities',
      [ActivityType.ValuablesJewelry]:
        'CompanyInformationVerification-Valuables__jewelry',
      [ActivityType.WaterSupplySewerageWasteCollectionAndDistribution]:
        'CompanyInformationVerification-Water_supply__sewerage_system__waste_collection_and_distribu',
      [ActivityType.WholesaleAndRetailTrade]:
        'CompanyInformationVerification-Wholesale_and_retail_trade',
      [ActivityType.NonProfitOrganizationFoundations]:
        'CompanyInformationVerification-Non_profit_organization_foundations',
      [ActivityType.NonProfitOrganizationOther]:
        'CompanyInformationVerification-Non_profit_organization_other',
      [ActivityType.EstonianIndustryType]:
        'CompanyInformationVerification-Estonian_industry_type',
      [ActivityType.EmbassiesConsulates]:
        'CompanyInformationVerification-Embassies__Consulates',
      [ActivityType.Crowdfunding]:
        'CompanyInformationVerification-Crowdfunding',
    };
  };

  static getVerificationActivityTypesAsSelectItems = (
    items:
      | {
          id: string;
          parent?: string;
          selectable?: boolean;
        }[]
      | undefined
  ) => {
    if (!items) {
      return [];
    }

    const intl = getIntl();
    const labels = Verification.getVerificationActivityTypeLabelKeys();
    const data: SelectGroup[] = [];

    items
      .filter((type) => !type.parent && !type.selectable)
      .forEach((type) => {
        const label = labels[type.id as ActivityType]
          ? intl.formatMessage({id: labels[type.id as ActivityType]})
          : type.id;
        const children: SelectItem[] = [];

        items
          .filter((_type) => type.id === _type?.parent)
          .forEach((_type) => {
            children.push({
              title: labels[_type.id as ActivityType]
                ? intl.formatMessage({id: labels[_type.id as ActivityType]})
                : _type.id,
              value: _type.id,
            });
          });

        data.push({
          title: label.toUpperCase(),
          items: children,
        });
      });

    data.push({
      items: items
        .filter((type) => !type.parent && type.selectable)
        .map((type) => {
          return {
            title: labels[type.id as ActivityType]
              ? intl.formatMessage({id: labels[type.id as ActivityType]})
              : type.id,
            value: type.id,
          };
        }),
    });

    return data;
  };

  static getVerificationEstimatedEmployeesNumberLabelKeys = (): TypedMap<
    string,
    string
  > => {
    return {
      EmployeesQuantity1to4: '1-4',
      EmployeesQuantity5to20: '5-20',
      EmployeesQuantity21to50: '21-50',
      EmployeesQuantity51to100: '51-100',
      EmployeesQuantity101to500: '101-500',
      EmployeesQuantity501to2500: '501-2500',
      EmployeesQuantity2500AndMore: '2500+',
    };
  };

  static getCurrencyRequestCardSpendingAmountLabelKeys = (): TypedMap<
    CurrencyRequestCardSpendingAmount,
    string
  > => {
    return {
      ExpectedCardsNumber1to20: '1-20',
      ExpectedCardsNumber21to100: '21-100',
      ExpectedCardsNumber101to300: '101-300',
      ExpectedCardsNumber301to1000: '301-1000',
      ExpectedCardsNumber1001to3000: '1001-3000',
      ExpectedCardsNumber3001to15000: '3001-15 000',
      ExpectedCardsNumber15001AndMore: '15 000+',
    };
  };

  static getCurrencyRequestExpectedTurnoverLabelKeys = (): TypedMap<
    CurrencyRequestExpectedTurnover,
    string
  > => {
    return {
      ExpectedTurnover0to2000: '0-2000',
      ExpectedTurnover2001to10000: '2001-10000',
      ExpectedTurnover10001to50000: '10001-50000',
      ExpectedTurnover50001to100000: '50001-100000',
      ExpectedTurnover100001to500000: '100001-500000',
      ExpectedTurnover500000AndMore: '500000+',
    };
  };

  static getVerificationCardSpendingAmountLabelKeys = (): TypedMap<
    string,
    string
  > => {
    return {
      CardSpendingAmount0to2000: '0-2000',
      CardSpendingAmount2001to10000: '2001-10 000',
      CardSpendingAmount10001to50000: '10 001-50 000',
      CardSpendingAmount50001to100000: '50 001-100 000',
      CardSpendingAmount100001to500000: '100 001-500 000',
      CardSpendingAmount500000AndMore: '500 000+',
    };
  };

  static getWithUnsetIdentityVerifications(
    persons: ClientPerson[]
  ): ClientPerson[] {
    if (persons.length === 0) {
      return [];
    }

    return persons.filter(
      (p) =>
        p.verification_state.status === 'Required' ||
        p.verification_state.status === 'Failed'
    );
  }

  constructor(client: IClient) {
    this.client = client;
  }

  isCanAddDocuments(): boolean {
    return (
      this.client.documents_verification_status !==
        VerificationStatus.Confirmed &&
      this.client.documents_verification_status !==
        VerificationStatus.PendingApproval
    );
  }

  isCanConfirmAllDocuments(documents: DocumentsVerificationFormDocs): boolean {
    let docsCount = 0;
    let hasEmptyRequired = false;
    ObjectKeys(documents).forEach((docType) => {
      const document = documents[docType];
      if (document) {
        document.documents.forEach((d) => {
          if (d.is_required && !d.file_name) {
            hasEmptyRequired = true;
          }
        });
        docsCount += document.documents.filter(
          (d) => !d.error && d.verification_state.status !== 'Rejected'
        ).length;
      }
    });

    return this.isCanAddDocuments() && docsCount > 0 && !hasEmptyRequired;
  }

  isCanEditClientCompany(company: ClientCompany): boolean {
    return (
      this.client !== null && company.verification_state.status === 'Failed'
    );
  }

  isCanEditClientPerson(person: ClientPerson): boolean {
    return (
      this.client !== null &&
      (person.verification_state.status === 'Required' ||
        person.verification_state.status === 'Failed')
    );
  }

  isCanDeleteClientPerson(person: ClientPerson): boolean {
    return (
      this.client !== null &&
      (person.verification_state.status === 'Required' ||
        person.verification_state.status === 'NotConfirmed' ||
        person.verification_state.status === 'Failed' ||
        person.verification_state.status === 'Rejected')
    );
  }

  isCanDeleteClientCompany(company: ClientCompany): boolean {
    return (
      this.client !== null &&
      (company.verification_state.status === 'Rejected' ||
        company.verification_state.status === 'Failed')
    );
  }

  isCanEditClientPersonPhoneEmail(person: ClientPerson): boolean {
    return (
      this.client !== null &&
      (person.verification_state.status === 'Required' ||
        person.verification_state.status === 'NotConfirmed')
    );
  }

  isCanSendClientPersonLink(person: ClientPerson): boolean {
    return (
      this.client !== null &&
      (!!person.mobile || !!person.email) &&
      (person.verification_state.status === 'Required' ||
        person.verification_state.status === 'NotConfirmed' ||
        person.verification_state.status === 'Failed')
    );
  }

  isCanFinishIdentityVerification(
    persons: ClientPerson[],
    companies: ClientCompany[]
  ): boolean {
    if (this.client === null) {
      return false;
    }

    if (persons.length === 0 && companies.length === 0) {
      return false;
    }

    return true;
  }

  isHasUnsetIdentityVerifications(
    persons: ClientPerson[],
    companies: ClientCompany[]
  ): boolean {
    if (this.client === null) {
      return true;
    }
    if (persons.length === 0) {
      return true;
    }

    return (
      !!persons.find(
        (p) =>
          p.verification_state.status === 'Required' ||
          p.verification_state.status === 'Failed'
      ) ||
      !!companies.find(
        (c) =>
          c.verification_state.status === 'Required' ||
          c.verification_state.status === 'Failed'
      )
    );
  }

  isCanAddNewClientEntity(): boolean {
    return (
      this.client !== null &&
      this.client.identity_verification_status !== 'Confirmed'
    );
  }

  isCanResendClientPerson(person: ClientPerson): boolean {
    return (
      this.client !== null &&
      person.verification_state.status !== 'PendingApproval'
    );
  }

  getVerificationBaseStatus(withoutDocs = false): VerificationStatus {
    if (
      this.client.client_information_verification_status ===
        VerificationStatus.NotConfirmed ||
      (this.client.documents_verification_status ===
        VerificationStatus.NotConfirmed &&
        !withoutDocs) ||
      this.client.identity_verification_status ===
        VerificationStatus.NotConfirmed
    ) {
      return VerificationStatus.NotConfirmed;
    }
    if (
      this.client.client_information_verification_status ===
        VerificationStatus.VerificationFailed ||
      this.client.documents_verification_status ===
        VerificationStatus.VerificationFailed ||
      this.client.identity_verification_status ===
        VerificationStatus.VerificationFailed ||
      this.client.client_information_verification_status ===
        VerificationStatus.Rejected ||
      this.client.documents_verification_status ===
        VerificationStatus.Rejected ||
      this.client.identity_verification_status === VerificationStatus.Rejected
    ) {
      return VerificationStatus.VerificationFailed;
    }
    if (Verification.isOnBoardingVerified(this.client)) {
      return VerificationStatus.Confirmed;
    }
    return VerificationStatus.PendingApproval;
  }
}

const VerificationHelper = (client: IClient): Verification => {
  return new Verification(client);
};

export default VerificationHelper;
