import {IEmployeesCardCreateParams} from '../../types';
import Demo, {DemoApiHandler} from '../demo';
import ObjectKeys from '../object';

const updateEmployeesCard: DemoApiHandler = ({config, instance, url, data}) => {
  if (url?.params?.employee_id) {
    const employee = instance
      .getData()
      .employees[instance.getData().productId].find(
        (c) => c.id === url?.params?.employee_id
      );

    if (employee) {
      const currentData = data as IEmployeesCardCreateParams;

      if (currentData) {
        ObjectKeys(currentData).forEach((dataKey) => {
          const dataValue = currentData[dataKey];

          if (dataValue) {
            employee[dataKey] = dataValue;
          }
        });

        instance.saveImpl(['employees']);

        return Demo.makeDataResponse<string>(config, 'ok');
      }
    }
  }

  return Demo.makeUnprocessableEntityResponse(config, 90721);
};

export default updateEmployeesCard;
