import {IAccount, MoneyTransferToEmployeeRequestParams} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

export const makeMoneyTransferToEmployee: DemoApiHandler = ({
  config,
  instance,
  data,
}) => {
  const findAccountById = (accountId: string, arr: IAccount[]) => {
    return arr.find((acc) => acc.id === accountId);
  };

  const currentData = data as MoneyTransferToEmployeeRequestParams;
  const accounts = instance.getData().accounts[instance.getData().productId];
  const employees = instance.getData().employees[instance.getData().productId];

  const account = findAccountById(currentData.from_account_id, accounts);
  const employee = employees.find(
    (acc) => acc.id === currentData.to_employee_id
  );

  if (currentData && account && employee) {
    const updateAccount = (acc: IAccount, amount: number) => ({
      ...acc,
      available_amount: acc.available_amount - amount,
    });

    const updatedSenderAccount = updateAccount(account, currentData.amount);

    accounts.forEach((acc, index) => {
      if (acc.id === updatedSenderAccount.id) {
        accounts[index] =
          acc.id === updatedSenderAccount.id ? updatedSenderAccount : acc;
      }
    });

    instance.saveImpl(['employees']);

    return Demo.makeDataResponse<string>(config, 'ok');
  }

  return Demo.makeUnprocessableEntityResponse(config);
};

export default makeMoneyTransferToEmployee;
