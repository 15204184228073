import QueryString from 'query-string';
import Endpoints, {WLEndpoints} from '../constants/endpoints';
import {App$} from '../models/app';
import {AxiosUrl, EndpointInfo} from '../types';

export const wlEndpoints: Record<WLEndpoints, EndpointInfo> = {
  [WLEndpoints.assignedProduct]: {path: '/assigned-products', critical: true},
  [WLEndpoints.getCurrentUser]: {path: '/user', critical: true},
  [WLEndpoints.getProductSettings]: {path: '/product-settings', critical: true},
  [WLEndpoints.getServerStorageData]: {path: '/storage', critical: true},
  [WLEndpoints.setServerStorageData]: {path: '/storage', critical: true},
  [WLEndpoints.getReservedSettlementBalance]: {
    path: '/reserved-settlement-balance',
    critical: true,
  },
  [WLEndpoints.getAccount]: {path: '/accounts/{account_id}', critical: true},
  [WLEndpoints.getAccounts]: {path: '/accounts', critical: true},
  [WLEndpoints.getClientSupportManagers]: {
    path: '/client/support-managers',
    critical: false,
  },
  [WLEndpoints.getExpiringCards]: {path: '/expiring-cards', critical: false},
  [WLEndpoints.getPendingCards]: {path: '/pending-orders', critical: false},
  [WLEndpoints.getFilesDirectories]: {path: '/extract-files', critical: false},
  [WLEndpoints.getFilesListInDirectory]: {
    path: '/extract-files/{directory_name}',
    critical: false,
  },
  [WLEndpoints.downloadFileByName]: {
    path: '/extract-files/{directory_name}/{file_name}',
    critical: false,
  },
  [WLEndpoints.getCustomers]: {path: '/persons', critical: false},
  [WLEndpoints.updateUserSelfPassword]: {
    path: '/user/update-password',
    critical: false,
  },
  [WLEndpoints.getUsers]: {path: '/users', critical: false},
  [WLEndpoints.getCurrentUser]: {path: '/user', critical: true},
  [WLEndpoints.getUser]: {path: '/users/{user_id}', critical: false},
  [WLEndpoints.lockUser]: {path: '/users/{user_id}/lock', critical: false},
  [WLEndpoints.unlockUser]: {path: '/users/{user_id}/unlock', critical: false},
  [WLEndpoints.deleteUser]: {path: '/users/{user_id}', critical: false},
  [WLEndpoints.getUserHistory]: {
    path: '/users/{user_id}/history',
    critical: false,
  },
  [WLEndpoints.updateUserImage]: {
    path: '/users/{user_id}/profile-image',
    critical: false,
  },
  [WLEndpoints.updateUserProfile]: {
    path: '/users/{user_id}/profile',
    critical: false,
  },
  [WLEndpoints.updateUserSelfProfile]: {path: '/user', critical: false},
  [WLEndpoints.getUsersInfo]: {path: '/users/info', critical: true},
  [WLEndpoints.setUserRole]: {
    path: '/users/{user_id}/profile-role',
    critical: false,
  }, // POST
  [WLEndpoints.createUser]: {path: '/users', critical: false},
  [WLEndpoints.search]: {path: '/search', critical: false},
  [WLEndpoints.getRolesList]: {path: '/roles', critical: true},
  [WLEndpoints.getProductCards]: {path: '/product-cards', critical: false},
  [WLEndpoints.getSummaryTransactions]: {
    path: '/transactions/summary',
    critical: false,
  },
  [WLEndpoints.getRoleDetails]: {path: '/roles/{role_id}', critical: false},
  [WLEndpoints.deleteRole]: {path: '/roles/{role_id}', critical: false},
  [WLEndpoints.getRolesList]: {path: '/roles', critical: true},
  [WLEndpoints.createRole]: {path: '/roles', critical: false},
  [WLEndpoints.updateRole]: {path: '/roles/{role_id}', critical: false},
  [WLEndpoints.createCompanyRepresentative]: {
    path: '/companies/{company_id}/representatives',
    critical: false,
  },
  [WLEndpoints.getCompanyRepresentatives]: {
    path: '/companies/{company_id}/representatives',
    critical: false,
  },
  [WLEndpoints.getCompanyRepresentative]: {
    path: '/companies/{company_id}/representatives/{representative_id}',
    critical: false,
  },
  [WLEndpoints.deleteCompanyRepresentative]: {
    path: '/companies/{company_id}/representatives/{representative_id}',
    critical: false,
  },
  [WLEndpoints.getCompanyVerificationConfig]: {
    path: '/companies/verification-config',
    critical: false,
  },
  [WLEndpoints.getFraudCase]: {
    path: '/fraud-cases/{fraud_case_id}',
    critical: false,
  },
  [WLEndpoints.updateFraudCase]: {
    path: '/fraud-cases/{fraud_case_id}',
    critical: false,
  },
  [WLEndpoints.getFraudCases]: {path: '/fraud-cases', critical: false},
  [WLEndpoints.getFraudCaseCardOwners]: {
    path: '/fraud-cases/card-owners',
    critical: false,
  },
  [WLEndpoints.getFraudHistory]: {
    path: '/fraud-cases/{fraud_case_id}/history',
    critical: false,
  },
  [WLEndpoints.downloadFrauds]: {
    path: '/fraud-cases/report',
    critical: false,
  },
  [WLEndpoints.customerAccounts]: {
    path: '/persons/{person_id}/accounts',
    critical: false,
  },
  [WLEndpoints.customerHistory]: {
    path: '/persons/{person_id}/history',
    critical: false,
  },
  [WLEndpoints.customerCards]: {
    path: '/persons/{person_id}/cards',
    critical: false,
  },
  [WLEndpoints.customerCardsBlockCalls]: {
    path: '/persons/{person_id}/card-blocks',
    critical: false,
  },
  [WLEndpoints.getCompany]: {path: '/companies/{company_id}', critical: false},
  [WLEndpoints.getCompanyHistory]: {
    path: '/companies/{company_id}/history',
    critical: false,
  },
  [WLEndpoints.getCompanyCards]: {
    path: '/companies/{company_id}/cards',
    critical: false,
  },
  [WLEndpoints.getCompanyAccounts]: {
    path: '/companies/{company_id}/accounts',
    critical: false,
  },
  [WLEndpoints.deactivateCompany]: {
    path: '/companies/{company_id}',
    critical: false,
  },
  [WLEndpoints.createCompany]: {path: '/companies', critical: false},
  [WLEndpoints.updateCompany]: {
    path: '/companies/{company_id}',
    critical: false,
  },
  [WLEndpoints.getCompanies]: {path: '/companies', critical: false},
  [WLEndpoints.enrolCard]: {
    path: '/cards/{card_id}/3d-secure',
    critical: false,
  },
  [WLEndpoints.unenrolCard]: {
    path: '/cards/{card_id}/3d-secure',
    critical: false,
  },
  [WLEndpoints.createCard]: {path: '/cards', critical: false},
  [WLEndpoints.send3DSOtp]: {
    path: '/test/cards/{card_id}/3ds-otp',
    critical: false,
  },
  [WLEndpoints.updateCardSecurity]: {
    path: '/cards/{card_id}/security',
    critical: false,
  },
  [WLEndpoints.getAuthorizationAdditionalInfo]: {
    path: '/authorizations/{authorization_id}/additional-info',
    critical: false,
  },
  [WLEndpoints.customer]: {path: '/persons/{person_id}', critical: false},
  [WLEndpoints.deactivateCustomer]: {
    path: '/persons/{person_id}',
    critical: false,
  },
  [WLEndpoints.updateCustomer]: {path: '/persons/{person_id}', critical: false},
  [WLEndpoints.createCustomer]: {path: '/persons', critical: false},
  [WLEndpoints.getAuthorization]: {
    path: '/authorizations/{authorization_id}',
    critical: false,
  },
  [WLEndpoints.getCard]: {path: '/cards/{card_id}', critical: false},
  [WLEndpoints.isCanRenewCard]: {
    path: '/cards/{card_id}/is-card-renewal-allowed',
    critical: false,
  },
  [WLEndpoints.updateCardName]: {
    path: '/cards/{card_id}/name',
    critical: false,
  },
  [WLEndpoints.isCanRenewCard]: {
    path: '/cards/{card_id}/is-card-renewal-allowed',
    critical: false,
  },
  [WLEndpoints.addCardUser]: {path: '/cards/{card_id}/users', critical: false},
  [WLEndpoints.getCard]: {path: '/cards/{card_id}', critical: false},
  [WLEndpoints.updateCardSecurity]: {
    path: '/cards/{card_id}/security',
    critical: false,
  },
  [WLEndpoints.updateCardLimit]: {
    path: '/cards/{card_id}/limits',
    critical: false,
  },
  [WLEndpoints.remind3DSPassword]: {
    path: '/cards/{card_id}/3ds-password',
    critical: false,
  },
  [WLEndpoints.remindCardPin]: {path: '/cards/{card_id}/pin', critical: false},
  [WLEndpoints.updateCardStatus]: {
    path: '/test/cards/{card_id}/status',
    critical: false,
  },
  [WLEndpoints.getCardNumber]: {
    path: '/cards/{card_id}/encrypted-card-number',
    critical: false,
  },
  [WLEndpoints.getCardHistory]: {
    path: '/cards/{card_id}/history',
    critical: false,
  },
  [WLEndpoints.getCardTransactions]: {
    path: '/cards/{card_id}/transactions',
    critical: false,
  },
  [WLEndpoints.getCardStatements]: {
    path: '/cards/{card_id}/statement',
    critical: false,
  },
  [WLEndpoints.getCardTokens]: {
    path: '/cards/{card_id}/tokens',
    critical: false,
  },
  [WLEndpoints.getCardAuthorizations]: {
    path: '/cards/{card_id}/authorizations',
    critical: false,
  },
  [WLEndpoints.getCardCvvCode]: {
    path: '/cards/{card_id}/encrypted-cvv2',
    critical: false,
  },
  [WLEndpoints.getCardPinCode]: {
    path: '/cards/{card_id}/encrypted-pin',
    critical: false,
  },
  [WLEndpoints.getCard3dsPassword]: {
    path: '/cards/{card_id}/encrypted-3ds-password',
    critical: false,
  },
  [WLEndpoints.updateCardName]: {
    path: '/cards/{card_id}/name',
    critical: false,
  },
  [WLEndpoints.blockCard]: {path: '/cards/{card_id}/block', critical: false},
  [WLEndpoints.unblockCard]: {
    path: '/cards/{card_id}/unblock',
    critical: false,
  },
  [WLEndpoints.closeCard]: {path: '/cards/{card_id}/close', critical: false},
  [WLEndpoints.replaceCard]: {
    path: '/cards/{card_id}/replace',
    critical: false,
  },
  [WLEndpoints.activateCard]: {
    path: '/cards/{card_id}/activate',
    critical: false,
  },
  [WLEndpoints.updateCard3dSecureSettings]: {
    path: '/cards/{card_id}/3d-secure/settings',
    critical: false,
  },
  [WLEndpoints.linkCardToAccount]: {
    path: '/cards/{card_id}/link-to-account/{account_id}',
    critical: false,
  },
  [WLEndpoints.changeCardPin]: {path: '/cards/{card_id}/pin', critical: false},
  [WLEndpoints.enrolCard]: {
    path: '/cards/{card_id}/3d-secure',
    critical: false,
  },
  [WLEndpoints.unenrolCard]: {
    path: '/cards/{card_id}/3d-secure',
    critical: false,
  },
  [WLEndpoints.deleteCardUsersBatch]: {
    path: '/cards/{card_id}/users',
    critical: false,
  },
  [WLEndpoints.updateCardExternalId]: {
    path: '/cards/{card_id}/external-id',
    critical: false,
  },
  [WLEndpoints.getCardUsers]: {path: '/cards/{card_id}/users', critical: false},
  [WLEndpoints.createTransaction]: {
    path: '/test/transactions',
    critical: false,
  },
  [WLEndpoints.getAccountCards]: {
    path: '/accounts/{account_id}/cards',
    critical: false,
  },
  [WLEndpoints.createAuthorization]: {
    path: '/test/authorizations',
    critical: false,
  },
  [WLEndpoints.unblockCardsBatch]: {
    path: '/batch/cards/unblock',
    critical: false,
  },
  [WLEndpoints.blockCardsBatch]: {path: '/batch/cards/block', critical: false},
  [WLEndpoints.closeCardsBatch]: {path: '/batch/cards/close', critical: false},
  [WLEndpoints.replaceCardsBatch]: {
    path: '/batch/cards/replace',
    critical: false,
  },
  [WLEndpoints.createAccount]: {path: '/accounts', critical: false},
  [WLEndpoints.closeAccount]: {
    path: '/accounts/{account_id}/close',
    critical: false,
  },
  [WLEndpoints.getTopUpLink]: {
    path: '/accounts/{account_id}/generate-top-up-pdf',
    critical: false,
  },
  [WLEndpoints.getAccountStatement]: {
    path: '/accounts/{account_id}/statement',
    critical: false,
  },
  [WLEndpoints.getAccountStatementFile]: {
    path: '/accounts/{account_id}/download-statement',
    critical: false,
  },
  [WLEndpoints.updateAccountName]: {
    path: '/accounts/{account_id}/name',
    critical: false,
  },
  [WLEndpoints.updateAccountLimits]: {
    path: '/accounts/{account_id}/limits',
    critical: false,
  },
  [WLEndpoints.adjustAccountBalance]: {
    path: '/accounts/{account_id}/balance',
    critical: false,
  },
  [WLEndpoints.getAccountHistory]: {
    path: '/accounts/{account_id}/history',
    critical: false,
  },
  [WLEndpoints.updateAccountTopUpDetails]: {
    path: '/accounts/{account_id}/top-up-details',
    critical: false,
  },
  [WLEndpoints.updateAccountExternalID]: {
    path: '/accounts/{account_id}/external-id',
    critical: false,
  },
  [WLEndpoints.reopenAccount]: {
    path: '/accounts/{account_id}/reopen',
    critical: false,
  },
  [WLEndpoints.getPermissions]: {path: '/permissions', critical: false},
  [WLEndpoints.orderPromoCards]: {path: '/batch/cards', critical: false},
  [WLEndpoints.getTransaction]: {
    path: '/transactions/{transaction_id}',
    critical: false,
  },
  [WLEndpoints.getCardsDispatchMethods]: {
    path: '/cards-dispatch-methods',
    critical: false,
  },
};

const endpoints: Record<Endpoints, EndpointInfo> = {
  [Endpoints.getAllowedEndpoints]: {path: '/allowed-endpoints', critical: true},
  [Endpoints.signUp]: {path: '/sign-up', critical: true},
  [Endpoints.getRestrictedCountries]: {
    path: '/access-control/restricted-countries',
    critical: true,
  },
  [Endpoints.signIn]: {path: '/sign-in', critical: true},
  [Endpoints.signInUpdateEmail]: {path: '/sign-in/email', critical: true},
  [Endpoints.signInCreateSession]: {path: '/sign-in/session', critical: true},
  [Endpoints.signUpAddCompany]: {
    path: '/clients/registration',
    critical: true,
  },
  [Endpoints.intercomIdentityHash]: {
    path: '/intercom/identity-hash',
  },
  [Endpoints.setupChallenge]: {path: '/sign-in/challenge', critical: true},
  [Endpoints.currencyRequest]: {
    path: '/send-email/multi-currency-request',
    critical: false,
  },
  [Endpoints.resolveChallenge]: {path: '/sign-in/challenge', critical: true},
  [Endpoints.assignedProduct]: {path: '/assigned-products', critical: true},
  [Endpoints.logout]: {path: '/logout', critical: false},
  [Endpoints.assignProduct]: {
    path: '/products/{product_id}/assign',
    critical: true,
  },
  [Endpoints.assignProfile]: {
    path: '/users/profiles/{profile_id}/assign',
    critical: true,
  },
  [Endpoints.refreshToken]: {path: '/refresh-token', critical: true},
  [Endpoints.getCustomers]: {path: '/persons', critical: false},
  [Endpoints.createCompany]: {path: '/companies', critical: false},
  [Endpoints.updateCompany]: {path: '/companies/{company_id}', critical: false},

  [Endpoints.createCard]: {path: '/cards', critical: false},
  [Endpoints.createEmployeesCard]: {path: '/employees', critical: false},
  [Endpoints.updateEmployeesCard]: {
    path: '/employees/{employee_id}',
    critical: false,
  },

  [Endpoints.getCardDeliveryOptions]: {
    path: '/cards-dispatch-methods',
    critical: false,
  },
  [Endpoints.orderPromoCards]: {path: '/batch/cards', critical: false},
  [Endpoints.createAccount]: {path: '/accounts', critical: false},
  [Endpoints.getAccounts]: {path: '/accounts', critical: true},
  [Endpoints.getAccountLimitsUsage]: {
    path: '/accounts/{account_id}/limits-usage',
    critical: false,
  },
  [Endpoints.ensureCompanyAccess]: {path: '/company/users', critical: false},
  [Endpoints.getCompanies]: {path: '/companies', critical: false},
  [Endpoints.getCompanyLimitsUsage]: {
    path: '/companies/{company_id}/limits-usage',
    critical: false,
  },
  [Endpoints.downloadSummaryStatements]: {
    path: '/accounts/download-summary-statement',
    critical: false,
  },
  [Endpoints.addPaymentDocumentFiles]: {
    path: '/payment-documents',
    critical: false,
  },
  [Endpoints.getPaymentDocumentThumbnails]: {
    path: '/payment-documents/thumbnails',
    critical: false,
  },
  [Endpoints.getPaymentDocumentFile]: {
    path: '/payment-documents/{payment_document_id}',
    critical: false,
  },
  [Endpoints.downloadPaymentDocumentFiles]: {
    path: '/payment-documents/download',
    critical: false,
  },
  [Endpoints.downloadFeeInvoice]: {
    path: '/fees/{fee_id}/invoice',
    critical: false,
  },
  [Endpoints.deletePaymentDocumentFile]: {
    path: '/payment-documents/{payment_document_id}',
    critical: false,
  },
  [Endpoints.createUser]: {path: '/users', critical: false},
  [Endpoints.getByMobile]: {path: '/users/by-mobile', critical: false},
  [Endpoints.updateUserSelfPassword]: {
    path: '/user/update-password',
    critical: false,
  },
  [Endpoints.getMultiCurrencyRequestConfig]: {
    path: '/multi-currency-request-config',
    critical: false,
  },
  [Endpoints.customer]: {path: '/persons/{person_id}', critical: false},
  [Endpoints.deactivateCustomer]: {
    path: '/persons/{person_id}',
    critical: false,
  },
  [Endpoints.updateCustomer]: {path: '/persons/{person_id}', critical: false},
  [Endpoints.createCustomer]: {path: '/persons', critical: false},

  [Endpoints.customerAccounts]: {
    path: '/persons/{person_id}/accounts',
    critical: false,
  },
  [Endpoints.customerHistory]: {
    path: '/persons/{person_id}/history',
    critical: false,
  },
  [Endpoints.customerCards]: {
    path: '/persons/{person_id}/cards',
    critical: false,
  },
  [Endpoints.customerCardsBlockCalls]: {
    path: '/persons/{person_id}/card-blocks',
    critical: false,
  },
  [Endpoints.setUserRole]: {
    path: '/users/{user_id}/profile-role',
    critical: false,
  }, // POST

  [Endpoints.getAccount]: {path: '/accounts/{account_id}', critical: true},
  [Endpoints.getProductCards]: {path: '/product-cards', critical: false},
  [Endpoints.deactivatePersonsBatch]: {
    path: '/batch/persons/deactivate',
    critical: false,
  },
  [Endpoints.search]: {path: '/search', critical: false},
  [Endpoints.getClientAccounts]: {path: '/client/accounts', critical: false},
  [Endpoints.getClientTickets]: {
    path: '/client/benefits-tickets',
    critical: false,
  },
  [Endpoints.createClientTicket]: {
    path: '/client/benefits-ticket',
    critical: false,
  },
  [Endpoints.getTopUpTickets]: {
    path: '/client/top-up-tickets',
    critical: false,
  },
  [Endpoints.createTopUpTicket]: {
    path: '/client/top-up-ticket',
    critical: false,
  },

  [Endpoints.deleteProfilesBatch]: {path: '/product/users', critical: false},
  [Endpoints.deleteEmployee]: {
    path: '/employees/{employee_id}',
    critical: false,
  },
  [Endpoints.reInviteEmployee]: {
    path: '/employees/{employee_id}/remind',
    critical: false,
  },
  [Endpoints.unblockCardsBatch]: {
    path: '/batch/cards/unblock',
    critical: false,
  },
  [Endpoints.blockCardsBatch]: {path: '/batch/cards/block', critical: false},
  [Endpoints.closeCardsBatch]: {path: '/batch/cards/close', critical: false},
  [Endpoints.replaceCardsBatch]: {
    path: '/batch/cards/replace',
    critical: false,
  },
  [Endpoints.getAccountCards]: {
    path: '/accounts/{account_id}/cards',
    critical: false,
  },
  [Endpoints.closeAccount]: {
    path: '/accounts/{account_id}/close',
    critical: false,
  },
  [Endpoints.getTopUpLink]: {
    path: '/accounts/{account_id}/generate-top-up-pdf',
    critical: false,
  },
  [Endpoints.getCardTopUpLink]: {
    path: '/account-adjustments/{account_adjustment_id}/top-up-confirmation',
    critical: false,
  },
  [Endpoints.getCardTopUpPreview]: {
    path:
      '/account-adjustments/{account_adjustment_id}/top-up-confirmation-preview',
    critical: false,
  },
  [Endpoints.getAccountStatement]: {
    path: '/accounts/{account_id}/statement',
    critical: false,
  },
  [Endpoints.getAccountStatementFile]: {
    path: '/accounts/{account_id}/download-statement',
    critical: false,
  },
  [Endpoints.updateAccountName]: {
    path: '/accounts/{account_id}/name',
    critical: false,
  },
  [Endpoints.updateAccountLimits]: {
    path: '/accounts/{account_id}/limits',
    critical: false,
  },
  [Endpoints.adjustAccountBalance]: {
    path: '/accounts/{account_id}/balance',
    critical: false,
  },
  [Endpoints.getAccountHistory]: {
    path: '/accounts/{account_id}/history',
    critical: false,
  },
  [Endpoints.getProductTransactions]: {
    path: '/payments/transactions',
    critical: false,
  },
  [Endpoints.getProductStatistics]: {
    path: '/statistics/product',
    critical: false,
  },
  [Endpoints.getProductSettings]: {path: '/product-settings', critical: true},
  [Endpoints.getProductAuthorizations]: {
    path: '/payments/authorizations',
    critical: false,
  },
  [Endpoints.getCompanyHistory]: {
    path: '/companies/{company_id}/history',
    critical: false,
  },
  [Endpoints.getCompanyCards]: {
    path: '/companies/{company_id}/cards',
    critical: false,
  },
  [Endpoints.getCompanyAccounts]: {
    path: '/companies/{company_id}/accounts',
    critical: false,
  },
  [Endpoints.getCompanyInformationErrors]: {
    path: '/client/information/verification/errors',
    critical: false,
  },
  [Endpoints.getCountryCodeByIp]: {path: '/geo-by-ip', critical: false},
  [Endpoints.getServerStorageData]: {path: '/storage', critical: true},
  [Endpoints.setServerStorageData]: {path: '/storage', critical: true},
  [Endpoints.updateCompanyInformation]: {
    path: '/client/information',
    critical: true,
  },
  [Endpoints.getCommunicationLanguageCodes]: {
    path: '/communication-language-codes',
    critical: true,
  },
  [Endpoints.updateClientCompanyInformation]: {
    path: '/client',
    critical: true,
  },
  [Endpoints.signAgreement]: {path: '/client/sign-agreement', critical: true},
  [Endpoints.getAgreement]: {path: '/client/agreement', critical: true},
  [Endpoints.getCurrentClient]: {path: '/client', critical: true},
  [Endpoints.getCorporateDocuments]: {
    path: '/client/corporate-documents',
    critical: false,
  },
  [Endpoints.deleteCompanyDocument]: {
    path: '/client/corporate-documents/{document_id}',
    critical: false,
  },
  [Endpoints.confirmCompanyDocuments]: {
    path: '/client/corporate-documents/confirm',
    critical: false,
  },
  [Endpoints.uploadCompanyDocument]: {
    path: '/client/corporate-documents',
    critical: false,
  },
  [Endpoints.uploadRequestedCompanyDocument]: {
    path: '/client/corporate-documents/{document_id}/upload',
    critical: false,
  },
  [Endpoints.getVerificationConfig]: {
    path: '/verification-config',
    critical: true,
  },
  [Endpoints.getCompany]: {path: '/companies/{company_id}', critical: false},
  [Endpoints.createCompanyRepresentative]: {
    path: '/companies/{company_id}/representatives',
    critical: false,
  },
  [Endpoints.getCompanyRepresentatives]: {
    path: '/companies/{company_id}/representatives',
    critical: false,
  },
  [Endpoints.getCompanyRepresentative]: {
    path: '/companies/{company_id}/representatives/{representative_id}',
    critical: false,
  },
  [Endpoints.deleteCompanyRepresentative]: {
    path: '/companies/{company_id}/representatives/{representative_id}',
    critical: false,
  },
  [Endpoints.deactivateCompany]: {
    path: '/companies/{company_id}',
    critical: false,
  },
  [Endpoints.getEmployees]: {path: '/employees', critical: false},
  [Endpoints.updateCompanyLimits]: {
    path: '/companies/{company_id}/limits',
    critical: false,
  },
  [Endpoints.makeMoneyTransferToAccount]: {
    path: '/payments/account-transfer',
    critical: false,
  },
  [Endpoints.makeMoneyTransferToEmployee]: {
    path: '/payments/employee-transfer',
    critical: false,
  },
  [Endpoints.getProfiles]: {path: '/product/users', critical: false},
  [Endpoints.getProductPricingPlan]: {
    path: '/product/pricing-plan',
    critical: true,
  },
  [Endpoints.setProductPricingPlan]: {
    path: '/product/pricing-plan',
    critical: false,
  },
  [Endpoints.isCanRenewCard]: {
    path: '/cards/{card_id}/is-card-renewal-allowed',
    critical: false,
  },
  [Endpoints.addCardUser]: {path: '/cards/{card_id}/users', critical: false},
  [Endpoints.getCard]: {path: '/cards/{card_id}', critical: false},
  [Endpoints.updateCardSecurity]: {
    path: '/cards/{card_id}/security',
    critical: false,
  },
  [Endpoints.updateCardLimit]: {
    path: '/cards/{card_id}/limits',
    critical: false,
  },
  [Endpoints.remind3DSPassword]: {
    path: '/cards/{card_id}/3ds-password',
    critical: false,
  },
  [Endpoints.remindCardPin]: {path: '/cards/{card_id}/pin', critical: false},
  [Endpoints.getCardNumber]: {
    path: '/cards/{card_id}/encrypted-card-number',
    critical: false,
  },
  [Endpoints.getCardHistory]: {
    path: '/cards/{card_id}/history',
    critical: false,
  },
  [Endpoints.getCardTransactions]: {
    path: '/cards/{card_id}/transactions',
    critical: false,
  },
  [Endpoints.getCardStatements]: {
    path: '/cards/{card_id}/statement',
    critical: false,
  },
  [Endpoints.getCardTokens]: {path: '/cards/{card_id}/tokens', critical: false},
  [Endpoints.getCardAuthorizations]: {
    path: '/cards/{card_id}/authorizations',
    critical: false,
  },
  [Endpoints.getCardCvvCode]: {
    path: '/cards/{card_id}/encrypted-cvv2',
    critical: false,
  },
  [Endpoints.getCardPinCode]: {
    path: '/cards/{card_id}/encrypted-pin',
    critical: false,
  },
  [Endpoints.getCard3dsPassword]: {
    path: '/cards/{card_id}/encrypted-3ds-password',
    critical: false,
  },
  [Endpoints.updateCardName]: {path: '/cards/{card_id}/name', critical: false},
  [Endpoints.blockCard]: {path: '/cards/{card_id}/block', critical: false},
  [Endpoints.unblockCard]: {path: '/cards/{card_id}/unblock', critical: false},
  [Endpoints.closeCard]: {path: '/cards/{card_id}/close', critical: false},
  [Endpoints.replaceCard]: {path: '/cards/{card_id}/replace', critical: false},
  [Endpoints.activateCard]: {
    path: '/cards/{card_id}/activate',
    critical: false,
  },
  [Endpoints.updateCard3dSecureSettings]: {
    path: '/cards/{card_id}/3d-secure/settings',
    critical: false,
  },
  [Endpoints.linkCardToAccount]: {
    path: '/cards/{card_id}/link-to-account/{account_id}',
    critical: false,
  },
  [Endpoints.changeCardPin]: {path: '/cards/{card_id}/pin', critical: false},
  [Endpoints.enrolCard]: {path: '/cards/{card_id}/3d-secure', critical: false},
  [Endpoints.unenrolCard]: {
    path: '/cards/{card_id}/3d-secure',
    critical: false,
  },
  [Endpoints.deleteCardUsersBatch]: {
    path: '/cards/{card_id}/users',
    critical: false,
  },
  [Endpoints.getCardNotifications]: {
    path: '/cards/{card_id}/notification-settings',
    critical: false,
  },
  [Endpoints.changeCardNotifications]: {
    path: '/cards/{card_id}/notification-settings',
    critical: false,
  },
  [Endpoints.getCardUsers]: {path: '/cards/{card_id}/users', critical: false},
  [Endpoints.cancelProductPricingPlan]: {
    path: '/product/pricing-plan/cancel',
    critical: false,
  },
  [Endpoints.getUsers]: {path: '/users', critical: false},
  [Endpoints.getCurrentUser]: {path: '/user', critical: true},
  [Endpoints.getUser]: {path: '/users/{user_id}', critical: false},
  [Endpoints.lockUser]: {path: '/users/{user_id}/lock', critical: false},
  [Endpoints.unlockUser]: {path: '/users/{user_id}/unlock', critical: false},
  [Endpoints.deleteUser]: {path: '/users/{user_id}', critical: false},
  [Endpoints.getUserHistory]: {
    path: '/users/{user_id}/history',
    critical: false,
  },
  [Endpoints.updateUserImage]: {
    path: '/users/{user_id}/profile-image',
    critical: false,
  },
  [Endpoints.updateUserProfile]: {
    path: '/users/{user_id}/profile',
    critical: false,
  },
  [Endpoints.updateUserSelfProfile]: {path: '/user', critical: false},
  [Endpoints.getUsersInfo]: {path: '/users/info', critical: true},
  [Endpoints.getRolesList]: {path: '/roles', critical: true},
  [Endpoints.createRole]: {path: '/roles', critical: false},
  [Endpoints.getTemplate]: {path: '/templates/{template_id}', critical: false},
  [Endpoints.updateProductTemplate]: {
    path: '/products/{product_id}/templates/{template_id}',
    critical: false,
  },
  [Endpoints.getRoleDetails]: {path: '/roles/{role_id}', critical: false},
  [Endpoints.deleteRole]: {path: '/roles/{role_id}', critical: false},
  [Endpoints.getPermissions]: {path: '/permissions', critical: false},
  [Endpoints.updateRole]: {path: '/roles/{role_id}', critical: false},
  [Endpoints.getFee]: {path: '/fees/{fee_id}', critical: false},
  [Endpoints.getUnresolvedFees]: {
    path: '/unresolved-fees',
    critical: false,
  },
  [Endpoints.updateAccountCreditLimit]: {
    path: '/accounts/{account_id}/credit-limit',
    critical: false,
  },
  [Endpoints.getReservedSettlementBalance]: {
    path: '/reserved-settlement-balance',
    critical: true,
  },
  [Endpoints.getTransaction]: {
    path: '/transactions/{transaction_id}',
    critical: false,
  },
  [Endpoints.getAccountAdjustment]: {
    path: '/account-adjustments/{account_adjustment_id}',
    critical: false,
  },
  [Endpoints.getAuthorization]: {
    path: '/authorizations/{authorization_id}',
    critical: false,
  },
  [Endpoints.getAuthorizationAdditionalInfo]: {
    path: '/authorizations/{authorization_id}/additional-info',
    critical: false,
  },
  [Endpoints.getSummaryTransactions]: {
    path: '/transactions/summary',
    critical: false,
  },
  [Endpoints.changeUserLocale]: {path: '/user', critical: false},
  [Endpoints.clientVerificationAddPerson]: {
    path: '/client/persons',
    critical: false,
  },
  [Endpoints.clientVerificationUpdatePerson]: {
    path: '/client/persons/{person_id}',
    critical: false,
  },
  [Endpoints.clientVerificationDeletePerson]: {
    path: '/client/persons/{person_id}',
    critical: false,
  },
  [Endpoints.clientVerificationAddCompany]: {
    path: '/client/companies',
    critical: false,
  },
  [Endpoints.clientVerificationUpdateCompany]: {
    path: '/client/companies/{company_id}',
    critical: false,
  },
  [Endpoints.clientVerificationDeleteCompany]: {
    path: '/client/companies/{company_id}',
    critical: false,
  },
  [Endpoints.clientVerificationSendClientPersonVerificationLink]: {
    path: '/client/persons/{person_id}/send-verification-link',
    critical: false,
  },
  [Endpoints.clientVerificationGetClientPersonAndCompanyList]: {
    path: '/client/persons-and-companies',
    critical: false,
  },
  [Endpoints.getPricingPlans]: {path: '/pricing-plan-types', critical: true},
  [Endpoints.createApiKey]: {path: '/product/api-keys', critical: false},
  [Endpoints.getApiKeysList]: {path: '/product/api-keys', critical: false},
  [Endpoints.deleteApiKey]: {
    path: '/product/api-keys/{api_key_id}',
    critical: false,
  },
  [Endpoints.getMassPaymentsFiles]: {path: '/payment-files/', critical: false},
  [Endpoints.getMassPaymentsFile]: {
    path: '/payment-files/{payment_file_id}/payments',
    critical: false,
  },
  [Endpoints.retryMassPaymentsFile]: {
    path: '/payment-files/{payment_file_id}/retry',
    critical: false,
  },
  [Endpoints.createMassPaymentFile]: {path: '/payment-files/', critical: false},
  [Endpoints.editMassPaymentFileRecord]: {
    path: '/payment-files/{payment_file_id}/payments/{payment_record_id}',
    critical: false,
  },
  [Endpoints.deleteMassPaymentFileRecord]: {
    path: '/payment-files/{payment_file_id}/payments/{payment_record_id}',
    critical: false,
  },
  [Endpoints.confirmMassPaymentFile]: {
    path: '/payment-files/{payment_file_id}/confirm',
    critical: false,
  },
  [Endpoints.completeMassPaymentFile]: {
    path: '/payment-files/{payment_file_id}/complete',
    critical: false,
  },
  [Endpoints.discardMassPaymentsFile]: {
    path: '/payment-files/{payment_file_id}',
    critical: false,
  },
  [Endpoints.requestCompanyLimitChange]: {
    path: '/send-email/raise-company-limits-request',
    critical: false,
  },
  [Endpoints.getEmployee]: {path: '/employees/{employee_id}', critical: false},
  [Endpoints.getStatementAuthorization]: {
    path: '/statement/authorizations/{authorization_id}',
    critical: false,
  },
  [Endpoints.getPaymentMetadata]: {
    path: '/payment-metadata',
    critical: false,
  },
  [Endpoints.addPaymentMetadataItems]: {
    path: '/payment-metadata',
    critical: false,
  },
  [Endpoints.updatePaymentMetadataItem]: {
    path: '/payment-metadata/{payment_metadata_id}',
    critical: false,
  },
  [Endpoints.deletePaymentMetadataItem]: {
    path: '/payment-metadata/{payment_metadata_id}',
    critical: false,
  },
  [Endpoints.getStatementTransaction]: {
    path: '/statement/transactions/{transaction_id}',
    critical: false,
  },
  [Endpoints.send3DSOtp]: {
    path: '/test/cards/{card_id}/3ds-otp',
    critical: false,
  },
  [Endpoints.getClientSupportManagers]: {
    path: '/client/support-managers',
    critical: false,
  },
  [Endpoints.exportStatements]: {
    path: '/statement/export',
    critical: false,
  },
  [Endpoints.topUpPreview]: {
    path: '/accounts/{account_id}/top-up-preview',
    critical: false,
  },
  [Endpoints.initCardPayment]: {
    path: '/card-payments/initiate',
    critical: false,
  },
  [Endpoints.getAllowedTopUpMethods]: {
    path: '/allowed-top-up-methods',
    critical: false,
  },
  [Endpoints.getTopUpHistory]: {
    path: '/top-up-history',
    critical: false,
  },
  [Endpoints.getCardTopUpFees]: {
    path: '/card-top-up-fees',
    critical: false,
  },
} as const;

export const getApiPathImpl = (
  route: string,
  params?: Record<string, string | number>,
  queryParams?: Record<
    string,
    string | number | string[] | number[] | boolean | undefined
  >,
  stupidArrayFormat?: boolean
): string => {
  let path = route;
  if (params) {
    Object.keys(params).forEach((key) => {
      path = path.replace(`{${key}}`, `${params[key]}`);
    });
  }
  if (queryParams) {
    path = `${path}?${QueryString.stringify(queryParams, {
      arrayFormat: stupidArrayFormat === true ? 'none' : 'bracket',
    })}`;
  }
  return path;
};

function isWLEndpoint(
  endpoint: Endpoints | WLEndpoints
): endpoint is WLEndpoints {
  const isInWbEndpoints = Object.keys(endpoints).includes(endpoint);
  const isInWlEndpoints = Object.keys(wlEndpoints).includes(endpoint);
  if (!isInWbEndpoints && isInWlEndpoints) {
    return true;
  }

  if (isInWbEndpoints && !isInWlEndpoints) {
    return false;
  }
  if (isInWbEndpoints && isInWlEndpoints) {
    return App$.getState().isWhiteLabeled;
  }
  return false;
}

function isWBEndpoint(
  endpoint: Endpoints | WLEndpoints
): endpoint is Endpoints {
  return !isWLEndpoint(endpoint);
}

export const getEndpointInfo = (
  endpoint: Endpoints | WLEndpoints
): EndpointInfo | null => {
  if (isWLEndpoint(endpoint)) {
    if (wlEndpoints[endpoint] !== undefined) {
      return wlEndpoints[endpoint];
    }
  } else if (isWBEndpoint(endpoint) && endpoints[endpoint] !== undefined) {
    return endpoints[endpoint];
  }
  return null;
};

export const getApiPath = (
  endpoint: Endpoints | WLEndpoints,
  params?: Record<string, string | number>,
  queryParams?: Record<
    string,
    string | number | string[] | number[] | boolean | undefined
  >,
  stupidArrayFormat?: boolean
): string => {
  const endpointInfo = getEndpointInfo(endpoint);
  if (endpointInfo) {
    return getApiPathImpl(
      endpointInfo.path,
      params,
      queryParams,
      stupidArrayFormat
    );
  }
  throw new Error('Unknown endpoint');
};

export const getApiPathParamsMaker = (
  endpoint: Endpoints | WLEndpoints,
  params?: Record<string, string | number>,
  queryParams?: Record<string, string | number | string[] | number[] | boolean>,
  stupidArrayFormat?: boolean
): AxiosUrl => {
  return {
    endpoint,
    params,
    queryParams,
    stupidArrayFormat,
  };
};

export default endpoints;
