import moment from 'moment-timezone';
import Demo, {DemoApiHandler} from '../demo';
import {DEMO_USER_ID} from '../../constants/demo';

const deleteEmployee: DemoApiHandler = ({config, instance, url}) => {
  if (url?.params?.employee_id) {
    const employee = instance
      .getData()
      .employees[instance.getData().productId].find(
        (a) => a.id === url?.params?.employee_id
      );

    if (employee) {
      employee.status = 'Deleted';
      employee.deleted_at = moment().toISOString();
      employee.deleted_by = DEMO_USER_ID;

      instance.saveImpl(['employees']);

      return Demo.makeDataResponse<string>(config, 'ok');
    }
  }

  return Demo.makeUnprocessableEntityResponse(config, 90721);
};

export default deleteEmployee;
