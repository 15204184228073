import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import {LoadSearchResultsParams} from '../models/search';
import {IAccount, ICard, ICustomer, LoadSearchResultResponse} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

type SearchApiResponse = {
  records: {
    paginated_accounts: IAccount[];
    paginated_cards: ICard[];
    paginated_persons: ICustomer[];
  };
  records_count: {
    accounts_count: number;
    cards_count: number;
    persons_count: number;
  };
};

export const getSearchResults = async ({
  query,
  pageSize,
  currentPage,
  orderField,
  orderDirection,
}: LoadSearchResultsParams): Promise<LoadSearchResultResponse> => {
  const queryParams: Record<string, string | number> = {
    value: query,
    from_record: (currentPage - 1) * pageSize,
    records_count: pageSize,
    // order_field: orderField || '',
    // order_direction: orderDirection || '',
  };

  if (orderField && orderDirection) {
    queryParams.order_field = orderField;
    queryParams.order_direction = orderDirection;
  }

  try {
    const response: AxiosResponse<SearchApiResponse> = await WallesterAxios.getInstance()
      .getCommon()
      .get(getApiPathParamsMaker(Endpoints.search, {}, queryParams));
    return {
      result: {
        accounts: response.data.records.paginated_accounts || [],
        cards: response.data.records.paginated_cards || [],
        persons: response.data.records.paginated_persons || [],
      },
      currentPage,
      totalItems:
        response.data.records_count.accounts_count ||
        response.data.records_count.cards_count ||
        response.data.records_count.persons_count ||
        0,
      pageSize,
      orderField,
      orderDirection,
    };
  } catch (ex) {
    return {
      result: {
        persons: [],
        cards: [],
        accounts: [],
      },
      currentPage,
      totalItems: 0,
      pageSize,
    };
  }
};

export default getSearchResults;
