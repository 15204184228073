import {createEffect, createEvent, createStore, Effect, sample} from 'effector';
import LAYOUT_TYPES from '../../constants/layout';
import {LayoutModification, LayoutState} from '../../types';
import clearAppStores from '../clearStore';
import ServerStorageStore, {
  initServerStorage,
  setLayoutDashboardType,
} from '../serverStorage';

const Layout$ = createStore<LayoutState>({
  type:
    ServerStorageStore.getState().layout_dashboard_type || LAYOUT_TYPES.BASE,
  pageClass: '',
  headerIsVisible: null,
  isSwiping: false,
  isMenuOpened: false,
  isMenuDraggable: false,
  isLoading: false,
  lockScrollPosition: null,
  scrollElement: null,
  sidebarElement: null,
  menuScrollElement: null,
  menuScrollPositionX: 0,
  menuScrollPositionY: 0,
  menuContentWidth: 0,
  menuContentHeight: 0,
  menuMobileVisible: false,
  useFlags: false,
  modification: 'default',
  scrolledToBottom: false,
  headerElement: null,
});

Layout$.reset(clearAppStores);

let currentPosition = 0;
let lastChangePosition = 0;
let isUpDirection = false;
let isScrolling = false;

export type ScrollChangeEventParams = {
  current: number;
  headerHeight: number;
};

export const setSwipingState = createEvent<boolean>('setSwipingState');

export const setLayoutScrollElement = createEvent<HTMLDivElement>();
Layout$.on(setLayoutScrollElement, (state$, scrollElement) => ({
  ...state$,
  scrollElement,
}));

export const setLayoutMenuScrollElement = createEvent<HTMLDivElement>();
Layout$.on(setLayoutMenuScrollElement, (state$, menuScrollElement) => ({
  ...state$,
  menuScrollElement,
}));

export const setHeaderElement = createEvent<HTMLDivElement>();
Layout$.on(setHeaderElement, (state$, headerElement) => ({
  ...state$,
  headerElement,
}));

export const setSidebarElement = createEvent<HTMLDivElement>();
Layout$.on(setSidebarElement, (state$, sidebarElement) => ({
  ...state$,
  sidebarElement,
}));

export const setScrollPosition = createEvent<number>();
setScrollPosition.watch((position) => {
  const layoutState = Layout$.getState();
  if (layoutState.scrollElement) {
    layoutState.scrollElement.scrollTop = position;
  }
});

export const setScrollPositionExt = createEvent<{
  position: number;
  duration?: number;
  minStep?: number;
}>();
setScrollPositionExt.watch(({position, duration = 300, minStep = 100}) => {
  if (!isScrolling) {
    const layoutState = Layout$.getState();
    const {scrollElement} = layoutState;

    if (scrollElement) {
      (() => {
        let startTimeStamp = 0;
        let previousTimeStamp = 0;
        let startPosition = scrollElement.scrollTop;
        const direction = startPosition > position ? 1 : -1;
        const diffPosition = startPosition - position;
        const step: (ts: number) => void = (ts) => {
          if (startTimeStamp === 0) {
            startTimeStamp = ts;
          }
          const elapsed = ts - startTimeStamp;
          const elapsedPercent = elapsed / duration;
          const delta = Math.abs(diffPosition * elapsedPercent);
          let newPosition = startPosition - delta * direction;
          if (delta < minStep) {
            newPosition = startPosition - minStep * direction;
            startPosition -= (minStep - delta) * direction;
          }

          if (previousTimeStamp !== ts) {
            scrollElement.scrollTop = direction
              ? Math.min(newPosition, position)
              : Math.max(newPosition, position);
          }

          if (elapsed < duration && newPosition - position * direction > 0) {
            previousTimeStamp = ts;
            window.requestAnimationFrame(step);
          } else {
            isScrolling = false;
          }
        };
        isScrolling = true;
        window.requestAnimationFrame(step);
      })();
    }
  }
});

export const setMenuScrollPosition = createEvent<number>();
setMenuScrollPosition.watch((position) => {
  const layoutState = Layout$.getState();
  if (layoutState.menuScrollElement) {
    layoutState.menuScrollElement.scrollTop = position;
  }
});

export const setLayoutState = createEvent<Partial<LayoutState>>();
Layout$.on(setLayoutState, (state$, payload) => ({...state$, ...payload}));

export const collapse = createEffect<LAYOUT_TYPES, void, Error>('collapse', {
  handler: async (): Promise<void> => {
    return new Promise((res) => {
      const sidebar = Layout$.getState().sidebarElement;

      const handler = (e: TransitionEvent) => {
        if (sidebar && e.target === sidebar && e.propertyName === 'transform') {
          sidebar.removeEventListener('transitionend', handler);
          res();
        }
      };

      if (sidebar) {
        sidebar.addEventListener('transitionend', handler);
      } else {
        res();
      }
    });
  },
});
Layout$.on(collapse, (State, payload) => ({
  ...State,
  type: payload,
  ...{
    lockScrollPosition:
      State.isMenuOpened || State.isSwiping ? currentPosition : null,
  },
}));

collapse.watch((type) => {
  setLayoutDashboardType(type);
});

initServerStorage.doneData.watch(({layout_dashboard_type}) => {
  collapse((layout_dashboard_type as LAYOUT_TYPES) || LAYOUT_TYPES.BASE);
});

export const loadFlags = createEvent<boolean>();
Layout$.on(loadFlags, (state$, payload) => ({...state$, useFlags: payload}));

export const setLayoutModification = createEvent<LayoutModification>();
Layout$.on(setLayoutModification, (state$, payload) => ({
  ...state$,
  modification: payload,
}));

export const resetLayout = createEvent('resetLayout');

Layout$.reset(resetLayout);

export const setMenuState = createEvent<boolean>('setMenuState');

Layout$.on(setSwipingState, (State, isSwiping) => {
  return {
    ...State,
    isSwiping,
    ...{
      lockScrollPosition:
        State.isMenuOpened || isSwiping ? currentPosition : null,
    },
  };
});

Layout$.on(setMenuState, (State, isMenuOpened) => {
  return {
    ...State,
    isMenuOpened,
    ...{
      lockScrollPosition:
        isMenuOpened || State.isSwiping ? currentPosition : null,
    },
  };
});

export const scrollChangeEvent = createEvent<ScrollChangeEventParams>(
  'scrollChangeEvent'
);

export const menuScrollChangeEvent = createEvent<[number, number]>(
  'menuScrollChangeEvent'
);

export const menuContentSizeChangeEvent = createEvent<[number, number]>(
  'menuContentSizeChangeEvent'
);

Layout$.on(menuScrollChangeEvent, (State, [x, y]) => {
  return {...State, menuScrollPositionX: x, menuScrollPositionY: y};
});

Layout$.on(menuContentSizeChangeEvent, (State, [width, height]) => {
  return {...State, menuContentWidth: width, menuContentHeight: height};
});

const setHeaderIsVisible = createEvent<ScrollChangeEventParams>(
  'setHeaderIsVisible'
);
Layout$.on(setHeaderIsVisible, (State, {current, headerHeight}) => {
  const params: Partial<LayoutState> = {};
  const diff = current - lastChangePosition;
  const {scrollElement} = State;

  let newScrolledToBottom = false;
  const diffHeight =
    (scrollElement?.scrollHeight || 0) - (scrollElement?.clientHeight || 0);

  if (scrollElement && diffHeight <= current) {
    newScrolledToBottom = true;
  }
  params.scrolledToBottom = newScrolledToBottom;

  if (isUpDirection && current === 0) {
    params.headerIsVisible = null;
    lastChangePosition = current;
  } else if (!isUpDirection && current <= headerHeight) {
    params.headerIsVisible = null;
    lastChangePosition = current;
  } else if (diff < -10) {
    params.headerIsVisible = true;
    lastChangePosition = current;
  } else if (diff > 10) {
    params.headerIsVisible = false;
    lastChangePosition = current;
  }

  return {...State, ...params};
});

export const setForceHeaderIsVisible = createEvent<boolean>(
  'setForceHeaderIsVisible'
);
Layout$.on(setForceHeaderIsVisible, (State, headerIsVisible: boolean) => {
  return {...State, headerIsVisible};
});

export const setMenuDragableState = createEvent<boolean>(
  'setMenuDragableState'
);

Layout$.on(setMenuDragableState, (state$, isMenuDraggable) => {
  return {
    ...state$,
    isMenuDraggable,
  };
});

export const setMenuMobileVisible = createEffect<boolean, void, Error>(
  'setMenuMobileVisible',
  {
    handler: async (): Promise<void> => {
      return new Promise((res) => {
        const sidebar = Layout$.getState().sidebarElement;

        const handler = (e: TransitionEvent) => {
          if (
            sidebar &&
            e.target === sidebar &&
            (e.propertyName === 'transform' || e.propertyName === 'width')
          ) {
            sidebar.removeEventListener('transitionend', handler);
            res();
          }
        };

        if (sidebar) {
          sidebar.addEventListener('transitionend', handler);
        } else {
          res();
        }
      });
    },
  }
);

Layout$.on(setMenuMobileVisible, (state$, menuMobileVisible) => {
  return {
    ...state$,
    menuMobileVisible,
  };
});

export const setLoading = createEvent<boolean>('setLoading');

Layout$.on(setLoading, (state$, isLoading) => {
  return {
    ...state$,
    isLoading,
  };
});

sample({
  source: scrollChangeEvent,
  target: setHeaderIsVisible,
  filter: ({current, headerHeight}) => {
    const diff = current - lastChangePosition;
    const {
      headerIsVisible,
      scrollElement,
      scrolledToBottom,
    } = Layout$.getState();
    const newDirectionIsUp = current - currentPosition <= 0;
    let newScrolledToBottom = false;
    const diffHeight =
      (scrollElement?.scrollHeight || 0) - (scrollElement?.clientHeight || 0);

    if (scrollElement && diffHeight <= current) {
      newScrolledToBottom = true;
    }

    currentPosition = current;
    if (isUpDirection !== newDirectionIsUp) {
      lastChangePosition = current;
    }
    isUpDirection = newDirectionIsUp;
    return (
      (isUpDirection && current === 0 && headerIsVisible !== null) ||
      (!isUpDirection && current <= headerHeight && headerIsVisible !== null) ||
      (current !== 0 &&
        current > headerHeight &&
        diff < -10 &&
        headerIsVisible !== true) ||
      (current !== 0 &&
        current > headerHeight &&
        diff > 10 &&
        headerIsVisible !== false) ||
      newScrolledToBottom !== scrolledToBottom
    );
  },
});

// eslint-disable-next-line
export const attachScrollTop = (units: Effect<any, any>[]): void => {
  units.forEach((unit) => {
    unit.doneData.watch(() => {
      setScrollPosition(0);
    });
  });
};

export default Layout$;
