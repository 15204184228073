import Features, {TreatmentStatus} from '../constants/features';
import useCheckFeaturesImpl from './useCheckFeaturesImpl';

export const useCheckFeatures = (
  feature?: Features,
  checkType?: TreatmentStatus | string,
  defaultState?: boolean
): boolean => {
  const treatment = useCheckFeaturesImpl(feature, defaultState);
  if (treatment) {
    return treatment.treatment === (checkType || TreatmentStatus.Enabled);
  }
  return false;
};

export default useCheckFeatures;
