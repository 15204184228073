import {useSplitTreatments} from '@splitsoftware/splitio-react';
import {useStoreMap} from 'effector-react';
import React, {useEffect, useMemo} from 'react';
import Features, {
  ClientFeatures,
  ProductFeatures,
  UserFeatures,
} from '../../../constants/features';
import CurrentClientStore from '../../../models/currentClient';
import CurrentUserStore from '../../../models/currentUser';
import {UpdateFeature} from '../../../models/features';
import {ProductStore} from '../../../models/product';
import {TreatmentsWithConfig} from '../../../types';

const commonFeaturesKeys = Object.values(Features).filter(
  (f) => !ClientFeatures[f] && !ProductFeatures[f] && !UserFeatures[f]
);

const FeatureManager: React.FC = () => {
  const currentUserId = useStoreMap({
    store: CurrentUserStore,
    keys: ['id'],
    fn: (state) => state?.id,
  });
  const currentProductId = useStoreMap({
    store: ProductStore,
    keys: ['id'],
    fn: (state) => state?.id,
  });
  const currentClientId = useStoreMap({
    store: CurrentClientStore,
    keys: ['id'],
    fn: (state) => state?.id,
  });

  const userFeaturesKeys = useMemo(() => {
    const keys = Object.keys(UserFeatures);
    return keys.length > 0 ? keys : ['_'];
  }, []);

  const clientFeaturesKeys = useMemo(() => {
    const keys = Object.keys(ClientFeatures);
    return keys.length > 0 ? keys : ['_'];
  }, []);

  const productFeaturesKeys = useMemo(() => {
    const keys = Object.keys(ProductFeatures);
    return keys.length > 0 ? keys : ['_'];
  }, []);
  const userTreatments = useSplitTreatments({
    splitKey: currentUserId,
    names: userFeaturesKeys,
    updateOnSdkReady: true,
    updateOnSdkUpdate: true,
  });

  const commonTreatments = useSplitTreatments({
    splitKey: currentUserId,
    names: commonFeaturesKeys,
    updateOnSdkReady: true,
    updateOnSdkUpdate: true,
  });

  const clientTreatments = useSplitTreatments({
    splitKey: currentClientId,
    names: clientFeaturesKeys,
    updateOnSdkReady: true,
    updateOnSdkUpdate: true,
  });

  const productTreatments = useSplitTreatments({
    splitKey: currentProductId,
    names: productFeaturesKeys,
    updateOnSdkReady: true,
    updateOnSdkUpdate: true,
  });
  useEffect(() => {
    if (
      commonTreatments.isReady ||
      clientTreatments.isReady ||
      productTreatments.isReady ||
      userTreatments.isReady
    ) {
      UpdateFeature({
        common: commonTreatments.isReady
          ? (commonTreatments.treatments as TreatmentsWithConfig)
          : undefined,
        client: clientTreatments.isReady
          ? (clientTreatments.treatments as TreatmentsWithConfig)
          : undefined,
        product: productTreatments.isReady
          ? (productTreatments.treatments as TreatmentsWithConfig)
          : undefined,
        user: userTreatments.isReady
          ? (userTreatments.treatments as TreatmentsWithConfig)
          : undefined,
      });
    }
  }, [
    clientTreatments.treatments,
    commonTreatments.treatments,
    productTreatments.treatments,
    userTreatments.treatments,
    clientTreatments.isReady,
    commonTreatments.isReady,
    productTreatments.isReady,
    userTreatments.isReady,
  ]);

  return null;
};

export default FeatureManager;
