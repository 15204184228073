import {IProfile, UUID4} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const updateUserRole: DemoApiHandler = ({config, instance, url, data}) => {
  const userId: UUID4 = url?.params?.user_id as UUID4;
  const roleId: UUID4 = data.role_id as UUID4;
  if (userId && roleId) {
    const user = instance.getData().productUsers.find((u) => u.id === userId);

    if (user) {
      const {roles} = instance.getData();
      user.role = roles.find((r) => r.id === roleId) || roles[0];
      instance.saveImpl(['productUsers']);

      return Demo.makeDataResponse<IProfile>(config, user);
    }
  }

  return Demo.makeUnprocessableEntityResponse(config, 90721);
};

export default updateUserRole;
