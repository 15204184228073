import {useMemo} from 'react';
import {ScreenSize} from '../types';
import useMedias from './useMedias';

const useCurrentScreenSize = (): ScreenSize => {
  const medias = useMedias();

  return useMemo(() => {
    let currentScreenSize: ScreenSize = 'xxxl';

    (Object.keys(medias) as ScreenSize[]).some((screenSize) => {
      if (medias[screenSize]) {
        currentScreenSize = screenSize;

        return true;
      }

      return false;
    });

    return currentScreenSize;
  }, [medias]);
};

export default useCurrentScreenSize;
