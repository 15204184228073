import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';
import {PricingPlans} from '../types';

export const getPricingPlans = async (): Promise<PricingPlans> => {
  const response: AxiosResponse<{
    pricing_plan_types: PricingPlans;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getPricingPlans));

  return {...response.data.pricing_plan_types};
};

export default getPricingPlans;
