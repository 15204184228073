import Demo, {DemoApiHandler} from '../demo';

const getCardUsers: DemoApiHandler = ({config}) => {
  return Demo.makeDataResponse<{
    users: [];
    total_records_number: number;
  }>(config, {users: [], total_records_number: 0});
};

export default getCardUsers;
