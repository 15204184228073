import {AxiosResponse} from 'axios';
import moment from 'moment-timezone';
import {WLEndpoints} from '../constants/endpoints';
import {
  ICompany,
  ICompanies,
  ICompaniesRequestParams,
  UUID4,
  ICards,
  ICompanyCardsRequestParams,
  ICompanyAccountsRequestParams,
  IAccountListResponse,
  ICompanyRepresentativesRequestParams,
  ICompanyRepresentatives,
  RequestWithSortsOrders,
  ToListApiRequestCustomFormatterData,
  ICompanyActivityType,
} from '../types';
import {toListApiRequest} from '../utils/listRequest';
import WallesterAxios from '../utils/WAxios';

export const getCompanies = async (
  params: RequestWithSortsOrders<ICompaniesRequestParams>
): Promise<ICompanies> => {
  const response: AxiosResponse<{
    company: ICompany[];
    total_records_number: number;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get({
      endpoint: WLEndpoints.getCompanies,
      queryParams: toListApiRequest(
        params,
        () => {
          const ret: ToListApiRequestCustomFormatterData = {};
          if (params.riskProfile) {
            ret.risk_profiles = [params.riskProfile];
            ret.riskProfile = null;
          }
          if (params.registrationNumber) {
            ret.registration_number = params.registrationNumber;
            ret.registrationNumber = null;
          }
          if (params.status) {
            ret.statuses = [params.status];
            ret.status = null;
          }
          return ret;
        },
        true
      ),
      stupidArrayFormat: true,
    });

  return {
    companies: response.data.company || [],
    totalItems: response.data.total_records_number,
  };
};

export const deactivateCompanies = async (
  ids: UUID4[]
): Promise<ICompanies> => {
  // const response = await WallesterAxios.getInstance().getWhiteLabelAxios().patch(
  //   getApiPath(Endpoints.deactivateCompaniesBatch),
  //   {
  //     company_ids: ids,
  //   }
  // );
  // if (response.data.company === null) {
  //   return {
  //     companies: [],
  //   };
  // }
  return {
    companies: [].filter((id) => ids.includes(id)), // add ".filter((id) => ids.includes(id))" to remove warning
  };
};

export const getCompanyCards = async (
  params: RequestWithSortsOrders<ICompanyCardsRequestParams>
): Promise<ICards> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get({
      endpoint: WLEndpoints.getCompanyCards,
      params: {company_id: params.companyId},
      queryParams: toListApiRequest(
        {...params, companyId: undefined},
        (customFormatterParams) => {
          const ret: ToListApiRequestCustomFormatterData = {};

          if (customFormatterParams.expiration_from) {
            ret.from_expiry_date = moment(
              customFormatterParams.expiration_from as moment.Moment
            )
              .startOf('day')
              .utc()
              .format();
          }
          if (customFormatterParams.expiration_to) {
            ret.to_expiry_date = moment(
              customFormatterParams.expiration_to as moment.Moment
            )
              .endOf('day')
              .utc()
              .format();
          }
          if (params.type) {
            ret.card_types = params.type;
            ret.type = null;
          }

          return ret;
        },
        true
      ),
      stupidArrayFormat: true,
    });
  return {
    cards: response.data.cards || [],
    totalItems: response.data.total_records_number,
  };
};

export const getCompanyAccounts = async (
  params: RequestWithSortsOrders<ICompanyAccountsRequestParams>
): Promise<IAccountListResponse> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get({
      endpoint: WLEndpoints.getCompanyAccounts,
      params: {company_id: params.companyId},
      queryParams: toListApiRequest(
        {
          ...params,
          ...(params.isActive ? {is_active: params.isActive} : {}),
        },
        () => {
          const ret: ToListApiRequestCustomFormatterData = {};
          ret.companyId = null;
          if (params.vban) {
            ret.viban = params.vban;
            ret.vban = null;
          }
          if (params.name) {
            ret.account_name = params.name;
            ret.name = null;
          }

          return ret;
        },
        true
      ),
      stupidArrayFormat: true,
    });
  return {
    accounts: response.data.accounts || [],
    total_records_number: response.data.total_records_number,
  };
};

export const getCompanyRepresentatives = async (
  params: RequestWithSortsOrders<ICompanyRepresentativesRequestParams>
): Promise<ICompanyRepresentatives> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get({
      endpoint: WLEndpoints.getCompanyRepresentatives,
      params: {company_id: params.companyId},
      queryParams: toListApiRequest(params),
      stupidArrayFormat: true,
    });
  return {
    representatives: response.data.representatives || [],
    totalItems: response.data.total_records_number,
  };
};

export const getCompanyVerificationConfig = async (): Promise<
  ICompanyActivityType[]
> => {
  const response = await WallesterAxios.getInstance().getWhiteLabelAxios().get({
    endpoint: WLEndpoints.getCompanyVerificationConfig,
  });

  return response.data.activity_types || [];
};

export default getCompanies;
