import * as H from 'history';
import {useMemo} from 'react';
import useLang from './useLang';

function useUrl(url: H.LocationDescriptor): H.LocationDescriptor {
  const lang = useLang();
  return useMemo(() => {
    let _url = '';
    _url = (typeof url === 'string' ? url || '' : url.pathname || '').trim();
    if (_url[0] !== '/') {
      _url = `/${_url}`;
    }
    if (_url.indexOf(`/${lang}/`) === 0) {
      return typeof url === 'object' ? {...url, pathname: _url} : _url;
    }
    return typeof url === 'object'
      ? {...url, pathname: `/${lang}${_url}`}
      : `/${lang}${_url}`;
  }, [lang, url]);
}

export default useUrl;
