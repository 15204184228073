import moment from 'moment-timezone';
import {AxiosResponse} from 'axios';
import Endpoints, {WLEndpoints} from '../constants/endpoints';
import {
  ICards,
  IBlockCardsBatchParams,
  ICloseCardsBatchParams,
  IReplaceCardsBatchParams,
  IExpiringCardsListRequestParams,
  RequestWithSortsOrders,
  UUID4,
  ICardsApiRequestParams,
  ToListApiRequestCustomFormatterData,
  CardDispatchMethodInfo,
  CountryCodes3l,
} from '../types';
import {toListApiRequest} from '../utils/listRequest';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const getCards = async (
  params: RequestWithSortsOrders<ICardsApiRequestParams>
): Promise<ICards> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(
        WLEndpoints.getProductCards,
        undefined,
        toListApiRequest(params, (customFormatterParams) => {
          const ret: ToListApiRequestCustomFormatterData = {};

          if (customFormatterParams.expiration_from) {
            ret.expiration_from_date = moment(
              customFormatterParams.expiration_from as moment.Moment
            )
              .startOf('day')
              .utc()
              .format();
            ret.expiration_from = null;
          }
          if (customFormatterParams.expiration_to) {
            ret.expiration_to_date = moment(
              customFormatterParams.expiration_to as moment.Moment
            )
              .endOf('day')
              .utc()
              .format();
            ret.expiration_to = null;
          }
          if (customFormatterParams.type) {
            ret.card_type = customFormatterParams.type;
            ret.type = null;
          }

          return ret;
        }),
        true
      )
    );

  return {
    cards: response.data.cards,
    totalItems: response.data.total_records_number,
  };
};

export const blockCards = async (
  params: IBlockCardsBatchParams
): Promise<ICards> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.blockCardsBatch), {
      block_type: params.blockType,
      card_ids: params.cardIds,
    });
  if (response.data.cards === null) {
    return {
      cards: [],
    };
  }
  return {
    cards: response.data.cards,
  };
};

export const unblockCards = async (params: UUID4[]): Promise<ICards> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.unblockCardsBatch), {
      card_ids: params,
    });
  if (response.data.cards === null) {
    return {
      cards: [],
    };
  }
  return {
    cards: response.data.cards,
  };
};

export const closeCards = async (
  params: ICloseCardsBatchParams
): Promise<ICards> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.closeCardsBatch), {
      card_ids: params.cardIds,
      close_reason: params.closeReason,
    });
  return {
    cards: response.data.cards,
  };
};

export const replaceCards = async (
  params: IReplaceCardsBatchParams
): Promise<ICards> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.replaceCardsBatch), {
      card_ids: params.cardIds,
    });
  return {
    cards: response.data.cards,
  };
};

export const getExpiringCardsList = async (
  params: IExpiringCardsListRequestParams
): Promise<ICards> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get(getApiPathParamsMaker(WLEndpoints.getExpiringCards), {
      params: {
        from_record: (params.fromRecord - 1) * params.recordCount,
        records_count: params.recordCount,
        order_field: params.orderField,
        order_direction: params.orderDirection,
      },
    });
  return {
    cards: response.data.cards,
    totalItems: response.data.total_records_number,
  };
};

export const getPendingCardsList = async (
  params: IExpiringCardsListRequestParams
): Promise<ICards> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get(getApiPathParamsMaker(WLEndpoints.getPendingCards), {
      params: {
        from_record: (params.fromRecord - 1) * params.recordCount,
        records_count: params.recordCount,
        order_field: params.orderField,
        order_direction: params.orderDirection,
      },
    });
  return {
    cards: response.data.cards === null ? [] : response.data.cards,
    totalItems: response.data.total_records_number,
  };
};

export const getCardsDispatchMethods = async ({
  countryCode,
}: {
  countryCode: CountryCodes3l;
}): Promise<CardDispatchMethodInfo[]> => {
  const response: AxiosResponse<{
    dispatch_methods: CardDispatchMethodInfo[];
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(WLEndpoints.getCardsDispatchMethods), {
      params: {
        country_code: countryCode,
      },
    });

  return response.data.dispatch_methods || [];
};

export default getCards;
