import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import {
  Employee,
  GetEmployeesResponse,
  IEmployeesListRequestParams,
  UUID4,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

// export const getEmployeesForMoneyTransfer = async (): Promise<
//   EmployeeListItem[]
// > => {
//   // return [
//   //   {
//   //     id: '419e736f-1696-41b5-9c98-3d9f56d2961c',
//   //     name: 'Company-1',
//   //   },
//   //   {
//   //     id: '14ce738d-d42d-4612-a574-7f6b81340d1b',
//   //     name: 'Company-2',
//   //   },
//   // ];
//   const response = await WallesterAxios.getInstance().getBlackAxios().get(
//     getApiPathParamsMaker(
//       Endpoints.getEmployees,
//       undefined,
//       {
//         from_record: 0,
//         records_count: 1000,
//       },
//       true
//     )
//   );
//
//   return response.data.employees;
// };

export const getEmployeesList = async ({
  currentPage,
  pageSize,
  orderField,
  orderDirection,
  name,
  statuses,
}: IEmployeesListRequestParams): Promise<GetEmployeesResponse> => {
  let orderParams: string[] | null = null;
  if (orderField === 'person_first_name') {
    orderParams = ['person_first_name', 'person_last_name'];
  } else if (orderField) {
    orderParams = [orderField];
  }

  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(
        Endpoints.getEmployees,
        undefined,
        {
          from_record: (currentPage - 1) * pageSize,
          records_count: pageSize,
          order_field: orderParams || '',
          order_direction: orderDirection || '',
          ...(typeof statuses !== 'undefined' && {
            statuses,
          }),
          ...(typeof name !== 'undefined' &&
            name.trim() !== '' && {
              employee_name: `${name.trim()}`,
            }),
        },
        true
      )
    );

  return {
    items: response.data.employees || [],
    totalItems: response.data.total_records_number,
  };
};

export const getEmployee = async (employeeId: UUID4): Promise<Employee> => {
  const response: AxiosResponse<{
    employee: Employee;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({
      params: {
        employee_id: employeeId,
      },
      endpoint: Endpoints.getEmployee,
    });
  return response.data.employee;
};

export const deleteEmployees = async (
  employeeIds: UUID4[]
): Promise<boolean> => {
  return (
    (
      await Promise.all(
        employeeIds.map((employeeId) => {
          return WallesterAxios.getInstance()
            .getBlackAxios()
            .delete(
              getApiPathParamsMaker(Endpoints.deleteEmployee, {
                employee_id: employeeId,
              })
            );
        })
      )
    ).length > 0
  );
};

export const deleteEmployee = async (employeeId: UUID4): Promise<Employee> => {
  const response: AxiosResponse<{
    employee: Employee;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .delete(
      getApiPathParamsMaker(Endpoints.deleteEmployee, {employee_id: employeeId})
    );

  return response.data.employee;
};

export const reInviteEmployee = async (employeeId: UUID4): Promise<boolean> => {
  const response: AxiosResponse<{
    success: boolean;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .put(
      getApiPathParamsMaker(Endpoints.reInviteEmployee, {
        employee_id: employeeId,
      })
    );

  return response.data.success;
};

export default getEmployeesList;
