import {useStoreMap} from 'effector-react';
import React, {useEffect} from 'react';
import {Route} from 'react-router-dom';
import useQuery from '../../customHooks/useQuery';
import {App$} from '../../models/app';
import {setLayoutState} from '../../models/layout';
import AuthService from '../../services/AuthService';
import {CustomRoutesPropsReact} from '../../types';
import PermissionRedirect from './PermissionRedirect';
import RouterWatcher from './RouterWatcher';

const GuestRoute: React.FC<React.PropsWithChildren<CustomRoutesPropsReact>> = ({
  component: Component,
  page,
  children,
  title,
  ...rest
}) => {
  const [isGuest] = useStoreMap({
    store: App$,
    keys: ['isGuest'],
    fn: (store$) => [
      store$.isGuest,
      store$?.fullyVerified || false,
      store$?.isWhiteLabeled || false,
    ],
  });
  const query = useQuery();

  useEffect(() => {
    setLayoutState({pageClass: page});
  }, [page]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isGuest) {
          return (
            <PermissionRedirect
              to={AuthService.getSuccessAuthRedirectUrl(query)}
            />
          );
        }

        return (
          <RouterWatcher
            path={props.match.path}
            url={props.match.url}
            title={title}
          >
            {Component ? <Component {...props} /> : children}
          </RouterWatcher>
        );
      }}
    />
  );
};

export default GuestRoute;
