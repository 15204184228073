import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import {
  CurrencyType,
  LBPCardPaymentInitParams,
  PaymentDocumentArchive,
  PaymentDocumentFile,
  PaymentType,
  UUID4,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const addPaymentDocumentFiles = async (
  params: FormData
): Promise<PaymentDocumentFile[]> => {
  const response: AxiosResponse<{
    payment_documents: PaymentDocumentFile[];
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.addPaymentDocumentFiles), params);

  return response.data.payment_documents;
};

export const initCardPayment = async (
  accountId: UUID4,
  amount: number,
  currency: CurrencyType
): Promise<LBPCardPaymentInitParams> => {
  const response: AxiosResponse<LBPCardPaymentInitParams> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(
      {endpoint: Endpoints.initCardPayment},
      {
        account_id: accountId,
        account_amount: amount,
        account_currency_code: currency,
      }
    );

  return response.data;
};

export const getPaymentDocumentThumbnails = async ({
  payment_id,
  payment_type,
}: {
  payment_id: UUID4;
  payment_type: PaymentType;
}): Promise<PaymentDocumentFile[]> => {
  const response: AxiosResponse<{
    payment_documents: PaymentDocumentFile[];
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({
      endpoint: Endpoints.getPaymentDocumentThumbnails,
      queryParams: {
        payment_id,
        payment_type,
        a: Math.random(),
      },
    });

  return response.data.payment_documents;
};

export const getPaymentDocumentFile = async (
  id: UUID4
): Promise<PaymentDocumentFile> => {
  const response: AxiosResponse<{
    payment_document: PaymentDocumentFile;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(Endpoints.getPaymentDocumentFile, {
        payment_document_id: id,
      })
    );

  return response.data.payment_document;
};

export const downloadPaymentDocumentFiles = async (
  id: UUID4
): Promise<PaymentDocumentArchive> => {
  const response: AxiosResponse<{
    file: PaymentDocumentArchive;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.downloadPaymentDocumentFiles), {
      params: {
        payment_id: id,
      },
    });

  return response.data.file;
};

export const downloadFeeInvoice = async (id: UUID4): Promise<string> => {
  const response: AxiosResponse<{
    link: string;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({
      endpoint: Endpoints.downloadFeeInvoice,
      params: {fee_id: id},
    });

  return response.data.link;
};

export const deletePaymentDocumentFile = async (
  id: UUID4
): Promise<AxiosResponse> => {
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .delete(
      getApiPathParamsMaker(Endpoints.deletePaymentDocumentFile, {
        payment_document_id: id,
      })
    );

  return response;
};

export default getPaymentDocumentThumbnails;
