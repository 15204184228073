import {Modal} from 'antd';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {modalWidth} from '../../../../../../constants/styles';
import useConfig from '../../../../../../customHooks/useConfig';
import useMedias from '../../../../../../customHooks/useMedias';
import Link from '../../../../../../components/Link';
import {ReactComponent as IconOperationsSuspended} from '../../../../../../images/icons/operations-suspended.svg';
import {ReactComponent as IconClose} from '../../../../../../images/icons/close.svg';
import css from './ClientFrozenModal.module.less';

interface ClientFrozenModalProps {
  onClose: (status: boolean) => void;
}

const ClientFrozenModal: React.FC<ClientFrozenModalProps> = ({onClose}) => {
  const media = useMedias();
  const intl = useIntl();
  const {supportEmail} = useConfig();
  const [visible, setVisible] = useState(true);

  return (
    <Modal
      className="ant-modal_sticky ant-modal_titless-with-close"
      visible={visible}
      afterClose={() => {
        onClose(false);
      }}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
      width={modalWidth}
      footer={false}
      destroyOnClose
      centered={!media.xs}
      maskClosable={false}
      closeIcon={<IconClose />}
    >
      <div className={css.modal}>
        <IconOperationsSuspended className={css.modal__icon} />

        <div className={css.modal__title}>
          {intl.formatMessage({
            id: 'App-Operations_are_temporarily_suspended_',
          })}
        </div>

        <div className={css.modal__desc}>
          {intl.formatMessage({
            id: 'App-Some_functions_are_currently_unavailable_',
          })}
          <br />
          {intl.formatMessage(
            {
              id: 'App-Please_contact__email__',
            },
            {
              email: supportEmail,
              link: (...chunks: React.ReactNodeArray) => {
                return (
                  <Link external to={`mailto:${supportEmail}`}>
                    {chunks}
                  </Link>
                );
              },
            }
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ClientFrozenModal;
