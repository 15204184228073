import {createEvent, createStore} from 'effector';
import moment, {Moment} from 'moment-timezone';
import SavedFiltersService from '../../services/SavedFiltersService';
import {IFilterStore} from '../../types';
import {softClearAppStores} from '../clearStore';

type UpdateFilterStoreDateFilterParams = {
  fromDate: string | Moment;
  toDate: string | Moment;
};

const defaultFilterStore = {};

export const FilterStore = createStore<IFilterStore>(defaultFilterStore);

FilterStore.reset(softClearAppStores);

export const updateFilterStore = createEvent<IFilterStore>();

FilterStore.on(updateFilterStore, (state, filter) => {
  return {
    ...state,
    ...filter,
  };
});

export const updateFilterStoreDateFilter = createEvent<UpdateFilterStoreDateFilterParams>(
  'updateFilterStoreDateFilter'
);

FilterStore.on(updateFilterStoreDateFilter, (state, {fromDate, toDate}) => {
  return {
    ...state,
    dateFilter: {
      fromDate: moment(fromDate),
      toDate: moment(toDate),
    },
  };
});

updateFilterStoreDateFilter.watch(({fromDate, toDate}) => {
  SavedFiltersService.getInstance().updatePeriodDateFilter({
    fromDate: moment(fromDate).format('YYYY-MM-DD'),
    toDate: moment(toDate).format('YYYY-MM-DD'),
  });
});
