import {useUnit} from 'effector-react';
import {defaultProduct, ProductStore} from '../models/product';
import {IProduct} from '../types';

export const useProduct = (): IProduct => {
  const product = useUnit(ProductStore);
  if (product) {
    return product;
  }

  return {...defaultProduct};
};

export default useProduct;
