import {createEffect, createEvent, createStore} from 'effector';
import React from 'react';
import {HeaderBackActionConfirm, TabLinkItem} from '../../types';
import {softClearAppStores} from '../clearStore';
import {BreadcrumbsHelperProps} from '../../modules/BreadcrumbsHelper';

type HeaderBackActionConfirmItem = {
  id: number;
  action: HeaderBackActionConfirm;
};

type HeaderBackActionConfirmItems = HeaderBackActionConfirmItem[];

export type IHeaderStore = {
  loading: boolean;
  height: number;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  tabLinks: TabLinkItem[];
  activeTabLinkItem: string;
  onActiveTabLinkItem?: (id: string) => void;
  searchQuery?: string;
  backUrl?: string;
  backUrlActionConfirmItems: HeaderBackActionConfirmItems;
  hideSearchField: boolean;
  hideMenuButton: boolean;
  hideCloseButton: boolean;
  breadcrumbs?: BreadcrumbsHelperProps;
  backIconIsArrow?: boolean;
  rightData?: React.ReactNode;
};

export type HeaderStoreParams = Partial<IHeaderStore>;

export const HeaderStore = createStore<IHeaderStore>({
  height: 0,
  hideSearchField: false,
  hideMenuButton: false,
  hideCloseButton: false,
  tabLinks: [],
  loading: false,
  activeTabLinkItem: '',
  backUrlActionConfirmItems: [],
});

HeaderStore.reset(softClearAppStores);

const ActionIdsPool: number[] = [];

export const setBreadcrumbs = createEvent<BreadcrumbsHelperProps | undefined>(
  'setBreadCrumbs'
);

HeaderStore.on(setBreadcrumbs, (Store, params) => {
  return {
    ...Store,
    breadcrumbs: params,
  };
});

export const setHeaderParams = createEvent<HeaderStoreParams>(
  'setHeaderParams'
);

HeaderStore.on(setHeaderParams, (Store, params) => {
  return {
    ...Store,
    loading: params.loading !== undefined ? params.loading : false,
    hideSearchField:
      params.hideSearchField !== undefined ? params.hideSearchField : false,
    hideMenuButton:
      params.hideMenuButton !== undefined ? params.hideMenuButton : false,
    hideCloseButton:
      params.hideCloseButton !== undefined ? params.hideCloseButton : false,
    backIconIsArrow:
      params.backIconIsArrow !== undefined ? params.backIconIsArrow : false,
    title: params.title,
    subTitle: params.subTitle,
    tabLinks: params.tabLinks !== undefined ? params.tabLinks : [],
    searchQuery: params.searchQuery,
    backUrl: params.backUrl,
    activeTabLinkItem:
      params.activeTabLinkItem !== undefined ? params.activeTabLinkItem : '',
    onActiveTabLinkItem:
      params.onActiveTabLinkItem !== undefined
        ? params.onActiveTabLinkItem
        : undefined,
    rightData: params.rightData,
  };
});

// setHeaderParams.watch(({title}) => {
//   if (typeof title === 'string' && title) {
//     document.title = title;
//   }
// });

export const setHeaderActiveTab = createEvent<string>('setHeaderActiveTab');

HeaderStore.on(setHeaderActiveTab, (Store, activeTab) => {
  return {
    ...Store,
    activeTabLinkItem: activeTab,
  };
});

export const addBackUrlActionConfirm = createEffect<
  {cb: HeaderBackActionConfirm; key: number | null},
  number
>({
  name: 'addBackUrlActionConfirm',
  handler: async ({key}): Promise<number> => {
    return key === null ? ActionIdsPool.push(0) : key;
  },
});

HeaderStore.on(
  addBackUrlActionConfirm.done,
  (Store, {params: {cb: action}, result: actionId}) => {
    const backUrlActionConfirmItems: HeaderBackActionConfirmItems = [
      ...(Store.backUrlActionConfirmItems || []).filter(
        (item) => item.id !== actionId
      ),
      {id: actionId, action},
    ];
    return {
      ...Store,
      backUrlActionConfirmItems,
    };
  }
);

export const removeBackUrlActionConfirm = createEvent<number>(
  'removeBackUrlActionConfirm'
);

HeaderStore.on(removeBackUrlActionConfirm, (Store, actionId) => {
  const actions = [...Store.backUrlActionConfirmItems].filter(
    (value) => value.id !== actionId
  );
  if (actions.length !== Store.backUrlActionConfirmItems.length) {
    return {
      ...Store,
      backUrlActionConfirmItems: actions,
    };
  }

  return Store;
});

export const setHeaderHeight = createEvent<number>('setHeaderHeight');

HeaderStore.on(setHeaderHeight, (Store, height) => {
  return {
    ...Store,
    height,
  };
});
