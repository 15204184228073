import {createEffect, createStore} from 'effector';
import {getProductSettings} from '../../api';
import {ProductSettings} from '../../types';
import {defaultAccountLimits} from '../account';
import clearAppStores from '../clearStore';
import {defaultCardLimits} from '../cards';
import {defaultCompanyLimits} from '../company';

export const defaultProductSettings: ProductSettings = {
  allowed_card_delivery_country_codes: [],
  card_types_enabled: {
    chip_and_pin: false,
    chip_and_pin_anonymous: false,
    virtual: false,
  },
  default_fee_fixed_rate_plastic_card_issuance: 0,
  default_fee_fixed_rate_virtual_card_issuance: 0,
  personalization_default_product_code: '',
  personalization_product_codes_chip_and_pin: [],
  personalization_product_codes_three_lines_embossing_name: null,
  personalization_product_codes_two_lines_embossing_name: [],
  personalization_product_codes_virtual: [],
  personalization_virtual_default_product_code: '',
  settlement_payment_details_bank_address: '',
  settlement_payment_details_bank_name: '',
  settlement_payment_details_registration_number: '',
  personalization_product_codes: [],
  settings_loaded: false,
  company_transfer_enabled: false,
  default_fee_complex_authorization_atm_withdrawal_eea_fixed_part: 0,
  default_fee_complex_authorization_atm_withdrawal_eea_min: 0,
  default_fee_complex_authorization_atm_withdrawal_eea_percentage_part: 0,
  default_fee_complex_authorization_atm_withdrawal_fixed_part: 0,
  default_fee_complex_authorization_atm_withdrawal_international_fixed_part: 0,
  default_fee_complex_authorization_atm_withdrawal_international_min: 0,
  default_fee_complex_authorization_atm_withdrawal_international_percentage_part: 0,
  default_fee_complex_authorization_atm_withdrawal_min: 0,
  default_fee_complex_authorization_atm_withdrawal_percentage_part: 0,
  default_fee_complex_payment_eea_fixed_part: 0,
  default_fee_complex_payment_eea_min: 0,
  default_fee_complex_payment_eea_percentage_part: 0,
  default_fee_complex_payment_international_fixed_part: 0,
  default_fee_complex_payment_international_min: 0,
  default_fee_complex_payment_international_percentage_part: 0,
  default_fee_fixed_rate_authorization_atm_balance_inquiry: 0,
  default_fee_fixed_rate_authorization_atm_withdrawal: 0,
  default_fee_fixed_rate_authorization_declined: 0,
  default_fee_fixed_rate_authorization_declined_atm: 0,
  default_fee_fixed_rate_card_issuance: 0,
  default_fee_fixed_rate_card_replacement: 0,
  default_fee_fixed_rate_card_usage: 0,
  default_fee_fixed_rate_pin_change: 0,
  default_fee_fixed_rate_remind_3ds_password: 0,
  default_fee_fixed_rate_remind_pin: 0,
  default_fee_payment_fixed_min: 0,
  default_fee_payment_fixed_part: 0,
  default_fee_payment_percentage_part: 0,
  default_fee_percentage_rate_authorization_atm_withdrawal: 0,
  default_fee_percentage_rate_authorization_atm_withdrawal_fixed_min: 0,
  default_fee_percentage_rate_authorization_foreign_exchange: 0,
  default_fee_percentage_rate_authorization_foreign_exchange_fixed_min: 0,
  foreign_exchange_fee_percentage: 0,
  settlement_payment_details: '',
  settlement_payment_details_iban: '',
  settlement_payment_details_receiver_name: '',
  settlement_payment_details_swift_code: '',
  employee_cards_enabled: false,
  kyb_required: false,
  program: 'Consumer',
  partner_type: 'NonRegulated',
  ...defaultCardLimits,
  ...defaultCompanyLimits,
  ...defaultAccountLimits,
};

export const ProductSettingsStore = createStore<ProductSettings>(
  defaultProductSettings
);

ProductSettingsStore.reset(clearAppStores);

export const getProductSettingsFx = createEffect<void, ProductSettings, Error>({
  handler: async () => {
    return getProductSettings();
  },
});
ProductSettingsStore.on(getProductSettingsFx.doneData, (_, settings) => {
  return {
    ...settings,
    settings_loaded: true,
  };
});
