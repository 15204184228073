import {useContext} from 'react';
import {IntlContext, IntlShape, createIntl} from 'react-intl';
import m from '../modules/i18n/compiled/en.json';

const defaultIntl = createIntl({messages: m, locale: 'en'});

export const useIntl: () => IntlShape = () => {
  const d = useContext(IntlContext);
  return d || defaultIntl;
};

export default useIntl;
