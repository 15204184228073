import clsx from 'clsx';
import React from 'react';
import {ReactComponent as IconClose} from '../../images/icons/close.svg';
import css from './ModalNotify.module.less';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
  title?: React.ReactNode;
  position?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  color?: 'blue';
  onClose?: () => void;
  showAnimation?: 'fromTop' | 'fromRight' | 'fromBottom' | 'fromLeft';
  visible?: boolean;
}

const ModalNotify: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  style,
  title = '',
  position,
  color,
  showAnimation = 'fromBottom',
  onClose = () => undefined,
  visible,
  children,
}) => {
  return (
    <div
      className={clsx(
        css.modal,
        position && css[`modal_${position}`],
        color && css[`modal_${color}`],
        showAnimation && css[`modal_${showAnimation}`],
        visible && css.modal_visible,
        className
      )}
      style={style}
    >
      <div className={css.modal__inner}>
        <div className={css.modal__header}>
          <div className={css.modal__title} title={title as string}>
            {title}
          </div>

          <button type="button" className={css.modal__close} onClick={onClose}>
            <IconClose />
          </button>
        </div>

        <div className={css.modal__content}>{children}</div>
      </div>
    </div>
  );
};

export default ModalNotify;
