import {ICard, UUID4} from '../../types';
import Demo, {DemoApiHandler} from '../demo';
import {ICardExt} from '../../types/demo';
import {DEMO_CARDS, DEMO_PRODUCT_ID} from '../../constants/demo';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const hideCardPrivacyInfo = ({cvv, ...card}: ICardExt) => {
  return {
    ...card,
    masked_card_number: card.masked_card_number.replace(
      /^(.{6})(.{6})(.*)$/,
      '$1******$3'
    ),
    ...(card['3d_secure_settings']
      ? {
          '3d_secure_settings': {
            ...card['3d_secure_settings'],
            password: '******',
          },
        }
      : {}),
  };
};

export const hideCardsPrivacyInfo = (data: ICardExt[]): ICard[] => {
  return [...data].map((dataItem) => {
    return hideCardPrivacyInfo({
      ...dataItem,
    });
  });
};

export const getMaskedCardNumberById = (id: UUID4) => {
  const cards = DEMO_CARDS[DEMO_PRODUCT_ID].filter((a) => a.id === id);
  let card: ICard | undefined;

  if (cards[0]) {
    card = hideCardPrivacyInfo(cards[0]);
  }

  return card ? card.masked_card_number : undefined;
};

const getCard: DemoApiHandler = ({config, instance, url}) => {
  const item = instance
    .getData()
    .cards[instance.getData().productId].find(
      (a) => a.id === url?.params?.card_id
    );

  if (item) {
    return Demo.makeDataResponse<{
      card: ICard;
    }>(config, {
      card: hideCardPrivacyInfo(item),
    });
  }

  return Demo.makeUnprocessableEntityResponse(config, 30001);
};

export default getCard;
