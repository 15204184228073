import {createEffect, createEvent, createStore} from 'effector';
import {getCurrentClient} from '../../api';
import {
  ClientOnboardingVerificationStatus,
  IClient,
  UUID4,
  VerificationStatus,
} from '../../types';
import {signAgreementFx} from '../acceptAgreement';
import {softClearAppStores} from '../clearStore';

export type ClientVerificationChangeEvent = {
  client_id: UUID4;
  client_information_verification_status?: VerificationStatus;
  documents_verification_status?: VerificationStatus;
  identity_verification_status?: VerificationStatus;
  onboarding_verification_status?: ClientOnboardingVerificationStatus;
};

export const defaultClient: IClient = {
  id: '',
  organizational_number: '',
  name: '',
  phone: '',
  mobile: '',
  email: '',
  contact_person: '',
  registration_country_code: undefined,
  registration_street: '',
  registration_flat: '',
  registration_city: '',
  registration_region: '',
  registration_postal_code: '',
  actual_country_code: undefined,
  actual_street: '',
  actual_flat: '',
  actual_city: '',
  actual_region: '',
  actual_postal_code: '',
  activity_type: undefined,
  incorporation_date: '',
  estimated_monthly_deposit: undefined,
  estimated_transaction_number: undefined,
  business_card_account_agreement: false,
  fee_info_agreement: false,
  contact_mobile: '',
  contact_email: '',
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  deleted_at: '',
  deleted_by: '',
  custom_logo: '',
  email_verified: false,
  email_verified_at: '',
  mobile_verified: false,
  mobile_verified_at: '',
  client_information_verification_status: VerificationStatus.Confirmed,
  documents_verification_status: VerificationStatus.Confirmed,
  identity_verification_status: VerificationStatus.Confirmed,
  white_label: undefined,
  is_got_it_verification: undefined,
};

export const ClientStore = createStore(defaultClient);

export const updateClientVerificationState = createEffect<
  ClientVerificationChangeEvent | undefined,
  ClientVerificationChangeEvent
>({
  name: 'updateClientVerificationState',
  handler: async (client): Promise<ClientVerificationChangeEvent> => {
    if (!client) {
      const serverClient = await getCurrentClient(true, true);
      return {
        ...serverClient,
        client_id: serverClient.id,
      };
    }
    return {
      ...client,
    };
  },
});

ClientStore.on(updateClientVerificationState.done, (State, {result: data}) => {
  if (State.id === data.client_id) {
    return {
      ...State,
      client_information_verification_status:
        data.client_information_verification_status ||
        State.client_information_verification_status,
      documents_verification_status:
        data.documents_verification_status ||
        State.documents_verification_status,
      identity_verification_status:
        data.identity_verification_status || State.identity_verification_status,
      onboarding_verification_status:
        data.onboarding_verification_status ||
        State.onboarding_verification_status,
    };
  }
  return State;
});

export const getClientFx = createEffect<void, IClient, Error>({
  handler: async () => {
    return getCurrentClient();
  },
});

getClientFx.doneData.watch((client) => {
  localStorage.setItem('LAST_COMPANY_NAME', client.name);
});

ClientStore.on(getClientFx.doneData, (state, client) => {
  return {
    ...state,
    ...client,
  };
});

ClientStore.on(signAgreementFx.doneData, (state, client) => {
  return {
    ...state,
    ...client,
  };
});

export const cleanClientStore = createEvent();

ClientStore.reset(cleanClientStore, softClearAppStores);
