import {limitTypes} from '../constants/cards';

export type LimitUsageInfoData = {
  daily: number;
  monthly: number;
  weekly: number;
  all_time?: number;
};

export type LimitUsageInfo = {
  contactless_purchase: LimitUsageInfoData;
  internet_purchase: LimitUsageInfoData;
  overall_purchase: LimitUsageInfoData;
  purchase: LimitUsageInfoData;
  withdrawal: LimitUsageInfoData;
};

export interface Limits {
  daily_purchase: number;
  daily_withdrawal: number;
  daily_internet_purchase: number;
  daily_contactless_purchase: number;
  daily_overall_purchase: number;

  weekly_purchase: number;
  weekly_withdrawal: number;
  weekly_internet_purchase: number;
  weekly_contactless_purchase: number;
  weekly_overall_purchase: number;

  monthly_purchase: number;
  monthly_withdrawal: number;
  monthly_internet_purchase: number;
  monthly_contactless_purchase: number;
  monthly_overall_purchase: number;

  all_time_purchase: number;
  all_time_withdrawal: number;
  all_time_internet_purchase: number;
  all_time_contactless_purchase: number;
}

export enum LimitProgressStatuses {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export type LimitPeriod = typeof limitTypes[number];
