import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import {ICustomerList, ICustomerListResponse, ICustomerRaw} from '../types';
import WallesterAxios from '../utils/WAxios';
import {transformCustomerData} from './customer';
import {getApiPathParamsMaker} from './endpoints';

export const transformCustomerList = (
  list: ICustomerListResponse
): ICustomerList => {
  const rawCustomers = list.persons || [];

  return {
    persons: rawCustomers.map((person: ICustomerRaw) =>
      transformCustomerData(person)
    ),
    totalItems: list.total_records_number || 0,
  };
};

export const getCustomers = async ({
  currentPage,
  pageSize,
  isActive,
  orderField,
  orderDirection,
  firstName,
  lastName,
  personalNumber,
}: {
  currentPage: number;
  pageSize: number;
  isActive?: boolean;
  lastName?: string;
  firstName?: string;
  personalNumber?: string;
  orderField?: string;
  orderDirection?: string;
}): Promise<ICustomerList> => {
  const params: Record<string, unknown> = {
    from_record: (currentPage - 1) * pageSize,
    records_count: pageSize,
    order_field: orderField || '',
    order_direction: orderDirection || '',
  };

  if (isActive) {
    params.is_active = isActive;
  }
  if (firstName) {
    params.first_name = firstName;
  }
  if (lastName) {
    params.last_name = lastName;
  }
  if (personalNumber) {
    params.personal_number = personalNumber;
  }

  const response: AxiosResponse<ICustomerListResponse> = await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(Endpoints.getCustomers), {
      params: {...params},
    });

  return transformCustomerList(response.data);
};

export const deactivatePersons = async (
  params: string[]
): Promise<ICustomerList> => {
  const response: AxiosResponse<ICustomerListResponse> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .patch(getApiPathParamsMaker(Endpoints.deactivatePersonsBatch), {
      person_ids: params,
    });

  if (response.data.persons == null) {
    return {persons: [], totalItems: 0} as ICustomerList;
  }

  return transformCustomerList(response.data);
};

export default getCustomers;
