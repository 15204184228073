import {
  MassPaymentsFileStatus,
  MassPaymentsPaymentStatus,
  MassPaymentsPaymentWithFileId,
  UUID4,
} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const getMassPaymentsFile: DemoApiHandler = ({config, instance, url}) => {
  const paymentsFiles = instance.getData().massPaymentsFiles[
    instance.getData().productId
  ];
  const payments = instance.getData().massPayments[
    instance.getData().productId
  ];
  const paymentFileId = url?.params?.payment_file_id;

  const paymentFile = paymentsFiles.find((a) => a.id === paymentFileId);

  let items = payments.filter((a) => a.file_id === paymentFileId);

  const total = items.length;

  if (
    url?.queryParams?.record_statuses &&
    (url?.queryParams?.record_statuses as []).length
  ) {
    items = items.filter((a) =>
      (url?.queryParams
        ?.record_statuses as MassPaymentsPaymentStatus[]).includes(a?.status)
    );
  }

  items = Demo.applySorterAndPager(
    items,
    Demo.toListSortOrderParams(url.queryParams)
  );

  const itemsCompleted = items.filter((a) => a.status === 'Completed');
  const totalAmountOfPayments = items.reduce((acc, obj) => acc + obj.amount, 0);
  const currentAmountOfPayments = itemsCompleted.reduce(
    (acc, obj) => acc + obj.amount,
    0
  );

  const paymentFileStatus = paymentFile?.status;

  return Demo.makeDataResponse<{
    records: MassPaymentsPaymentWithFileId[];
    current_number_of_payments: number;
    total_number_of_payments: number;
    current_amount_of_payments: number;
    total_amount_of_payments: number;
    payment_file_status: MassPaymentsFileStatus;
    from_account_id?: UUID4;
  }>(config, {
    records: items,
    current_number_of_payments: itemsCompleted.length,
    total_number_of_payments: total,
    current_amount_of_payments: currentAmountOfPayments,
    total_amount_of_payments: totalAmountOfPayments,
    payment_file_status: paymentFileStatus as MassPaymentsFileStatus,
  });
};

export default getMassPaymentsFile;
