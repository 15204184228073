import {useStoreMap} from 'effector-react';
import {useCallback} from 'react';
import Endpoints from '../constants/endpoints';
import {App$} from '../models/app';
import checkEndpoints from '../utils/endpoints';

const useGetCheckerEndpoints = () => {
  const {allowedEndpoints, isWhiteLabel} = useStoreMap({
    store: App$,
    keys: ['allowedEndpoints', 'isWhiteLabeled'],
    fn: (state) => ({
      allowedEndpoints: state.allowedEndpoints,
      isWhiteLabel: state.isWhiteLabeled,
    }),
  });

  return useCallback(
    (endpoints: Endpoints[], partialMode?: boolean) => {
      return checkEndpoints({
        endpoints,
        existEndpoints: allowedEndpoints,
        partialMode,
        isWhiteLabel,
      });
    },
    [allowedEndpoints, isWhiteLabel]
  );
};

export default useGetCheckerEndpoints;
