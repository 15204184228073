import {createEffect, createStore} from 'effector';
import {getCardUsersList} from '../../api';
import getCard, {
  blockCardRequest,
  closeCardRequest,
  remind3DSPasswordRequest,
  remindCardPinRequest,
  renewCardRequest,
  replaceCardRequest,
  unblockCardRequest,
  updateCardLimitRequest,
  updateCardNameRequest,
  updateCardSecurityRequest,
  updateCardStatusRequest,
  enrolCardRequest,
  unenrolCardRequest,
  activateCardRequest,
  blackUpdateCardSecurityRequest,
  blackRenewCardRequest,
  sendTest3DSRequest,
  getCardNotifications,
  updateCardNotifications,
  updateCardExternalIdRequest,
} from '../../api/card';
import {
  CardBlockType,
  CardId,
  ICard,
  Send3DSOtpParams,
  TCardNotifications,
  UpdateCardLimitParams,
  UpdateCardSecurityParams,
  UpdateCardStatusRequestParams,
  UUID4,
} from '../../types';
import CardHelper, {Card} from '../../utils/CardHelper';
import {attachErrorHandler} from '../../utils/error';
import {formatCard} from '../../utils/formatters';
import checkIsWhiteLabel from '../../utils/whiteLabel';
import {App$} from '../app';
import {softClearAppStores} from '../clearStore';
// import {createCardAction} from '../cardForm';

export const CardsStore = createStore<{
  [key: string]: ICard | null;
}>({});

CardsStore.reset(softClearAppStores);

type CardInfo = {card: ICard; hasCardUsers?: boolean};

export const loadCard = createEffect<
  {id: UUID4; withCardUsersCheck?: boolean},
  CardInfo
>('loadCard', {
  handler: async ({id, withCardUsersCheck}) => {
    const card = await getCard(id);
    const cardUsers = withCardUsersCheck
      ? await getCardUsersList(card.id, {
          currentPage: 1,
          pageSize: 1,
        })
      : undefined;

    return {
      card: card.card,
      hasCardUsers: cardUsers ? cardUsers.items.length > 0 : undefined,
    };
  },
});

interface UpdateCardSecurityEffectParams {
  id: CardId;
  params: UpdateCardSecurityParams;
}

interface UpdateCardLimitEffectParams {
  id: CardId;
  params: UpdateCardLimitParams;
}

interface RenameCardParams {
  id: CardId;
  name: string;
}

interface UpdateCardExternalIdParams {
  id: CardId;
  externalId: string;
}

interface BlockCardParams {
  card: ICard;
  type: CardBlockType;
}

export type UpdateCardStatusParams = {
  cardId: CardId;
  params: UpdateCardStatusRequestParams;
};

export const updateCardSecurity = createEffect<
  UpdateCardSecurityEffectParams,
  ICard,
  Error
>('updateCardSecurity', {
  handler: async ({id, params}): Promise<ICard> => {
    const {isWhiteLabeled} = App$.getState();
    const card = await (isWhiteLabeled
      ? updateCardSecurityRequest(id, params)
      : blackUpdateCardSecurityRequest(id, params));

    if (isWhiteLabeled) {
      return card;
    }

    return (await getCard(card.id, true)).card;
  },
});

export const updateCardLimit = createEffect<
  UpdateCardLimitEffectParams,
  ICard,
  Error
>('updateCardLimit', {
  handler: ({id, params}): Promise<ICard> => {
    return updateCardLimitRequest(id, params);
  },
});

export const remindCardPin = createEffect<CardId, void, Error>(
  'remindCardPin',
  {
    handler: async (id): Promise<void> => {
      await remindCardPinRequest(id);
    },
  }
);

export const remind3DSPassword = createEffect<CardId, void, Error>(
  'remind3DSPassword',
  {
    handler: async (id): Promise<void> => {
      await remind3DSPasswordRequest(id);
    },
  }
);

export const updateCardName = createEffect<RenameCardParams, ICard, Error>(
  'updateCardName',
  {
    handler: async ({id, name}): Promise<ICard> => {
      return updateCardNameRequest(id, name);
    },
  }
);

export const updateCardExternalId = createEffect<
  UpdateCardExternalIdParams,
  ICard,
  Error
>('updateCardExternalId', {
  handler: async (props): Promise<ICard> => {
    return updateCardExternalIdRequest(props);
  },
});

export const blockCard = createEffect<BlockCardParams, ICard, Error>(
  'blockCard',
  {
    handler: async ({card, type}): Promise<ICard> => {
      const ch = CardHelper(card);
      if (
        (Card.isSoftBlockType(type) && !ch.isCanSoftBlock()) ||
        (Card.isHardBlockType(type) && !ch.isCanHardBlock())
      ) {
        throw new Error('Unable to block card...');
      }
      return blockCardRequest(card.id, type);
    },
  }
);

export const unblockCard = createEffect<CardId, ICard, Error>('unblockCard', {
  handler: async (id): Promise<ICard> => {
    return unblockCardRequest(id);
  },
});

export const closeCard = createEffect<CardId, ICard, Error>('closeCard', {
  handler: async (id): Promise<ICard> => {
    return closeCardRequest(id);
  },
});

export const sendTest3DS = createEffect<
  Send3DSOtpParams & {id: UUID4},
  boolean,
  Error
>('sendTest3DS', {
  handler: async ({id, ...params}): Promise<boolean> => {
    return sendTest3DSRequest(id, params);
  },
});

export const replaceCard = createEffect<CardId, ICard, Error>('replaceCard', {
  handler: async (id): Promise<ICard> => {
    return replaceCardRequest(id);
  },
});

export const renewCard = createEffect<ICard, ICard, Error>('renewCard', {
  handler: async (card): Promise<ICard> => {
    const {isWhiteLabeled} = App$.getState();
    const newCard = await (isWhiteLabeled
      ? renewCardRequest(card)
      : blackRenewCardRequest(card));

    if (isWhiteLabeled) {
      return newCard;
    }
    return (await getCard(newCard.id)).card;
  },
});

export const activateCard = createEffect<ICard, ICard, Error>('activateCard', {
  handler: async (card): Promise<ICard> => {
    return activateCardRequest(card);
  },
});

export const updateCardStatus = createEffect<
  UpdateCardStatusParams,
  ICard,
  Error
>('updateCardStatus', {
  handler: async ({cardId, params}): Promise<ICard> => {
    return updateCardStatusRequest(cardId, params);
  },
});

export const enrolCard = createEffect<CardId, ICard, Error>('enrolCard', {
  handler: async (id): Promise<ICard> => {
    return enrolCardRequest(id);
  },
});

export const unenrolCard = createEffect<CardId, ICard, Error>('unenrolCard', {
  handler: async (id): Promise<ICard> => {
    return unenrolCardRequest(id);
  },
});

CardsStore.on(loadCard.doneData, (State, {card}) => {
  return {
    ...State,
    [card.id]: card,
  };
});

CardsStore.on(
  [
    blockCard.doneData,
    unblockCard.doneData,
    // createCardAction.doneData,
    closeCard.doneData,
    replaceCard.doneData,
    renewCard.doneData,
    activateCard.doneData,
    unenrolCard.doneData,
    enrolCard.doneData,
    updateCardSecurity.doneData,
    updateCardStatus.doneData,
  ],
  (State, card) => {
    const isWhiteLabel = checkIsWhiteLabel();
    const currentCurrencyCode = State[card.id]?.currency_code;

    return {
      ...State,
      [card.id]: {
        ...formatCard(card, isWhiteLabel),
        currency_code: card.currency_code || currentCurrencyCode,
      },
    };
  }
);

export const getCardNotificationsFx = createEffect<
  UUID4,
  TCardNotifications,
  Error
>({
  handler: async (id) => {
    return getCardNotifications(id);
  },
});

CardsStore.on(getCardNotificationsFx.done, (state, {params, result}) => {
  return {
    ...state,
    [params]: {
      ...state[params],
      card_notification_settings: {
        ...result,
      },
    } as ICard,
  };
});

export const updateCardNotificationsFx = createEffect<
  {
    id: UUID4;
    notifications: TCardNotifications;
  },
  TCardNotifications,
  Error
>({
  handler: async (params) => {
    return updateCardNotifications(params);
  },
});

CardsStore.on(updateCardNotificationsFx.done, (state, {params, result}) => {
  return {
    ...state,
    [params.id]: {
      ...state[params.id],
      card_notification_settings: {
        ...result,
      },
    } as ICard,
  };
});

export const defaultCardLimits = {
  default_card_daily_contactless_purchase_limit: 15000,
  default_card_daily_internet_purchase_limit: 15000,
  default_card_daily_purchase_limit: 15000,
  default_card_daily_overall_purchase_limit: 15000,
  default_card_daily_withdrawal_limit: 15000,
  default_card_weekly_contactless_purchase_limit: 15000,
  default_card_weekly_internet_purchase_limit: 15000,
  default_card_weekly_purchase_limit: 15000,
  default_card_weekly_overall_purchase_limit: 15000,
  default_card_weekly_withdrawal_limit: 15000,
  default_card_monthly_contactless_purchase_limit: 15000,
  default_card_monthly_internet_purchase_limit: 15000,
  default_card_monthly_purchase_limit: 15000,
  default_card_monthly_overall_purchase_limit: 15000,
  default_card_monthly_withdrawal_limit: 15000,
  default_card_transaction_contactless_purchase_limit: 15000,
  default_card_transaction_internet_purchase_limit: 15000,
  default_card_transaction_purchase_limit: 15000,
  default_card_transaction_withdrawal_limit: 15000,
  max_card_daily_contactless_purchase_limit: 15000,
  max_card_daily_internet_purchase_limit: 15000,
  max_card_daily_purchase_limit: 15000,
  max_card_daily_overall_purchase_limit: 15000,
  max_card_daily_withdrawal_limit: 15000,
  max_card_weekly_contactless_purchase_limit: 15000,
  max_card_weekly_internet_purchase_limit: 15000,
  max_card_weekly_purchase_limit: 15000,
  max_card_weekly_overall_purchase_limit: 15000,
  max_card_weekly_withdrawal_limit: 15000,
  max_card_monthly_contactless_purchase_limit: 15000,
  max_card_monthly_internet_purchase_limit: 15000,
  max_card_monthly_purchase_limit: 15000,
  max_card_monthly_overall_purchase_limit: 15000,
  max_card_monthly_withdrawal_limit: 15000,
  max_card_transaction_contactless_purchase_limit: 15000,
  max_card_transaction_internet_purchase_limit: 15000,
  max_card_transaction_purchase_limit: 15000,
  max_card_transaction_withdrawal_limit: 15000,
  weekly_limits_enabled: false,
};

attachErrorHandler([loadCard]);
