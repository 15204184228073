export const ucFirst = (str: string): string => {
  return str ? str[0].toUpperCase() + str.slice(1) : str;
};

export const zeroFirst = (data: string | number): string => {
  const numb = parseFloat(`${data}`);

  return numb < 10 ? `0${numb}` : `${numb}`;
};

export const sliceStringWithEllipsis = ({
  str,
  maxSymbols,
}: {
  str: string | undefined;
  maxSymbols: number;
}): string => {
  if (!str) {
    return '';
  }

  return str.length > maxSymbols ? `${str.slice(0, maxSymbols)}...` : str;
};

export default ucFirst;
