import 'core-js/features/object/entries';
import 'core-js/features/promise';
import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import Bootstrap from './Bootstrap';
import BrowserNotSupported from './components/BrowserNotSupported/BrowserNotSupported';
import config from './constants/config';
import logger from './logger';
import './logger/transports';
import {setServiceWorkerRegistration} from './models/app';
import ConfigProvider from './modules/ConfigProvider/ConfigProvider';
import BaseErrorBoundary from './modules/Error/BaseErrorBoundary';
import {notSupportedRE} from './notSupported';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (config.gtm) {
  TagManager.initialize({
    gtmId: config.gtm,
  });

  Object.defineProperty(window, 'gtag', {
    value() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    },
    writable: true,
    enumerable: true,
  });

  window.gtag('consent', 'default', {
    ad_personalization: 'denied',
    ad_user_data: 'denied',
    ad_storage: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted',
    wait_for_update: 500,
  });
}
const docRoot = document.getElementById('root');
const log = logger.module('EntryPoint');

if (docRoot) {
  const root = ReactDOM.createRoot(docRoot);

  if (notSupportedRE.test(navigator.userAgent)) {
    root.render(<BrowserNotSupported />);
  } else {
    log.info('Start app!!!!!!', {build: config.buildNumber});

    root.render(
      <BaseErrorBoundary>
        <ConfigProvider>
          <Bootstrap />
        </ConfigProvider>
      </BaseErrorBoundary>
    );
  }
}

serviceWorkerRegistration.register({
  onSuccess: (registration) => {
    log.info('SW onSuccess', registration);
  },
  onUpdate: (registration) => {
    log.info('SW onUpdate', registration);
    setServiceWorkerRegistration(registration);
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
