import {DEMO_PAYMENT_DOCUMENT_FILE} from '../../constants/demo/statement';
import Demo, {DemoApiHandler} from '../demo';

const downloadPaymentDocumentFile: DemoApiHandler = ({config}) => {
  const body = DEMO_PAYMENT_DOCUMENT_FILE;

  return Demo.makeDataResponse<{
    file: {
      name: string;
      mime_type: string;
      body: string;
    };
  }>(config, {
    file: {
      name: `receipt.jpeg`,
      mime_type: 'image/jpeg',
      body,
    },
  });
};

export default downloadPaymentDocumentFile;
