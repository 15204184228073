import React, {useMemo, useState} from 'react';
import {Modal} from 'antd';
import {useIntl} from 'react-intl';
import {useUnit} from 'effector-react';
import useMedias from '../../../../../../customHooks/useMedias';
import {modalWidth} from '../../../../../../constants/styles';
import thumbColors from '../../../../../../constants/users';
import {genColorFromUUID4} from '../../../../../../utils/uuid';
import Scrollbar from '../../../../../../components/Scrollbar';
import {ProductStore} from '../../../../../../models/product';
import {ProductsStore} from '../../../../../../models/products';
import CardThumbInfoList from '../../../../../../components/CardThumbInfoList';
import {CardThumbInfoProps} from '../../../../../../components/CardThumbInfo/CardThumbInfo';
import {ReactComponent as IconClose} from '../../../../../../images/icons/close.svg';
import css from './ProductDebtModal.module.less';

interface ProductDebtModalProps {
  onClose: (status: boolean) => void;
}

const ProductDebtModal: React.FC<ProductDebtModalProps> = ({onClose}) => {
  const media = useMedias();
  const intl = useIntl();
  const product = useUnit(ProductStore);
  const products = useUnit(ProductsStore);

  const [visible, setVisible] = useState(true);

  const processedDebtProducts = useMemo(() => {
    return products
      .filter((item) => item.product_additional_info?.has_debts)
      .sort((a, b) => {
        if (a.id === product?.id) return -1;
        if (b.id === product?.id) return 1;

        return 0;
      });
  }, [products, product?.id]);

  const cardThumbInfoItems = useMemo<CardThumbInfoProps[]>(() => {
    return processedDebtProducts.map((item, idx) => {
      const isProductDebtor = item.id === product?.id;

      return {
        key: `${idx}`,
        title:
          item.product_type === 'WhiteLabel'
            ? item.code
            : item.client_name || '',
        desc: intl.formatMessage({id: 'App-Insufficient_funds'}),
        type: 'warning',
        color: genColorFromUUID4(item.profile_id, thumbColors),
        symbol:
          item.product_type === 'WhiteLabel'
            ? item.code.substr(0, 2)
            : item.client_name?.charAt(0),
        limit: true,
        isSelected: isProductDebtor,
      };
    });
  }, [intl, processedDebtProducts, product?.id]);

  return (
    <Modal
      className="ant-modal_sticky ant-modal_titless-with-close"
      visible={visible}
      title={intl.formatMessage({
        id: 'App-Insufficient_funds',
      })}
      afterClose={() => {
        onClose(false);
      }}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
      width={modalWidth}
      footer={false}
      destroyOnClose
      centered={!media.xs}
      maskClosable={false}
      closeIcon={<IconClose />}
    >
      <div className={css.modal}>
        <div className={css.modal__title}>
          {intl.formatMessage({
            id:
              'ProductDebtNotification-To_avoid_cancellation_of_your_subscription_',
          })}
        </div>

        <div className={css.modal__desc}>
          {intl.formatMessage({
            id: 'App-Product',
          })}
          :
        </div>

        {processedDebtProducts && (
          <Scrollbar
            wrapperAttributes={{className: css.layout, style: {maxHeight: 900}}}
          >
            <CardThumbInfoList items={cardThumbInfoItems} />
          </Scrollbar>
        )}
      </div>
    </Modal>
  );
};

export default ProductDebtModal;
