import Endpoints, {WLEndpoints} from '../constants/endpoints';
import {
  GetBlackUsersResponse,
  IGetUsersInfoResponse,
  IUserListRequestParams,
  UUID4,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const getUsersList = async ({
  currentPage,
  pageSize,
  orderField,
  orderDirection,
  isActive,
}: IUserListRequestParams): Promise<GetBlackUsersResponse> => {
  let orderParams: string[] | null = null;
  if (orderField === 'last_name') {
    orderParams = ['first_name', 'last_name'];
  } else if (orderField) {
    orderParams = [orderField];
  }

  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get(
      getApiPathParamsMaker(
        WLEndpoints.getUsers,
        undefined,
        {
          from_record: (currentPage - 1) * pageSize,
          records_count: pageSize,
          order_field: orderParams || '',
          order_direction: orderDirection || '',
          ...(typeof isActive !== 'undefined' ? {is_active: isActive} : {}),
        },
        true
      )
    );

  return {
    items: response.data.users || [],
    totalItems: response.data.total_records_number,
  };
};

export const getUsersInfo = async (
  usersIds: UUID4[]
): Promise<IGetUsersInfoResponse[]> => {
  try {
    const response = await WallesterAxios.getInstance()
      .getCommon()
      .get(
        getApiPathParamsMaker(
          Endpoints.getUsersInfo,
          undefined,
          {
            user_ids: usersIds,
          },
          true
        )
      );
    return response.data.users_info || [];
  } catch (e) {
    return [];
  }
};

export default getUsersList;
