import {useStoreMap} from 'effector-react';
import {useCallback} from 'react';
import Permissions from '../constants/permissions';
import CurrentUserStore from '../models/currentUser';
import {checkPermissions} from '../utils/permissions';

export const useGetCheckerPermissions = () => {
  const existPermissions = useStoreMap({
    store: CurrentUserStore,
    keys: ['permissions'],
    fn: (state) => state?.permissions,
  });

  return useCallback(
    (
      permissions: Permissions | Permissions[],
      partialMode?: boolean
    ): boolean => {
      return checkPermissions({
        permissions,
        existPermissions,
        partialMode,
      });
    },
    [existPermissions]
  );
};

export default useGetCheckerPermissions;
