import {MediaBreakpoints, ScreenSize} from '../types/styles';

export const mediaDelimiter = '_';
export const modalWidth = '100%';

export const mediaBreakpoints: MediaBreakpoints = {
  xxxl: 'only screen and (max-width: 2560px)',
  xxl: 'only screen and (max-width: 1920px)',
  xl: 'only screen and (max-width: 1600px)',
  lg: 'only screen and (max-width: 1440px)',
  md: 'only screen and (max-width: 1280px)',
  sm: 'only screen and (max-width: 1024px)',
  xs: 'only screen and (max-width: 768px)',
  xxs: 'only screen and (max-width: 375px)',
  lg_web: 'only screen and (max-width: 477px)',
  md_web: 'only screen and (max-width: 387px)',
  sm_web: 'only screen and (max-width: 345px)',
};

export const sortedMedias = [
  'xxs',
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  'xxl',
  'xxxl',
] as ScreenSize[];
