import {UUID4} from './common';
import {LanguageCode3l} from './languageCodes';
import {MessageTypes} from './message-types';

export type TemplateId = UUID4;

export const TemplateServices = ['com', 'pci-com'] as const;

export type TTemplateService = typeof TemplateServices[number];

export enum ETemplateService {
  COM,
  PCI,
}

export const deliveryTypes = ['email', 'sms'] as const;

export type DeliveryType = typeof deliveryTypes[number];

export interface ITemplate {
  ServiceType: string;
  body: string;
  created_at: string;
  created_by: string;
  delivery_type: DeliveryType;
  id: TemplateId;
  language_code: LanguageCode3l;
  message_type: MessageTypes;
  product_id: UUID4;
  subject: string;
  updated_at: string;
  updated_by: string;
}

export interface ITemplateUpdate {
  body: string;
  delivery_type: DeliveryType;
  language_code: LanguageCode3l;
  message_type: MessageTypes;
  subject: string;
}

export interface ITemplateUpdateParams {
  productId?: UUID4;
  templateId: TemplateId;
  body: ITemplateUpdate;
}

export interface ITemplateList {
  items: ITemplate[];
  totalItems: number;
}

export interface IProductTemplateListRequestParams {
  productId: UUID4;
  currentPage: number;
  pageSize: number;
  orderField?: string;
  orderDirection?: string;
  serviceType?: TTemplateService;
}

export interface ICreateTemplateParams {
  productId: UUID4;
  template: ITemplateUpdate;
}
