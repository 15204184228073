import {v4} from 'uuid';
import {UUID4} from '../types';
import localStorageWrapper from './localStorageWrapper';

const getGuestId = (): UUID4 => {
  const key = 'w_guest_id';
  let guestId: UUID4 | null = localStorageWrapper.getItem(key) as UUID4 | null;

  if (!guestId) {
    guestId = v4();
    localStorageWrapper.setItem(key, guestId);
  }
  return guestId;
};

export default getGuestId;
