import moment from 'moment-timezone';
import {
  FraudAuthorizationRiskStatus,
  IAuthorization,
  ICustomerCardsFilters,
  IGetUsersInfoResponse,
  Limits,
  RiskZone,
  StatementStatus,
} from '.';
import {MerchantCategoriesType} from '../constants/authorization';
import {ChipAndPinCardsAuthorizationTypes, PinRules} from '../constants/cards';
import {AuthorizationType} from './authorizations';
import {DateTime, OrderBy, PagerProps, TypedMap, UUID4} from './common';
import {CurrencyType} from './currency';
import {CountryCodes3l} from './geo';

export interface CardLimit extends Limits {
  transaction_purchase: number;
  transaction_withdrawal: number;
  transaction_internet_purchase: number;
  transaction_contactless_purchase: number;
}

export interface Usage {
  daily_purchase_used: number;
  daily_withdrawal_used: number;
  daily_internet_purchase_used: number;
  daily_contactless_purchase_used: number;
  daily_overall_purchase_used: number;

  weekly_purchase_used: number;
  weekly_withdrawal_used: number;
  weekly_internet_purchase_used: number;
  weekly_contactless_purchase_used: number;
  weekly_overall_purchase_used: number;

  monthly_purchase_used: number;
  monthly_withdrawal_used: number;
  monthly_internet_purchase_used: number;
  monthly_contactless_purchase_used: number;
  monthly_overall_purchase_used: number;

  all_time_purchase_used: number;
  all_time_withdrawal_used: number;
  all_time_internet_purchase_used: number;
  all_time_contactless_purchase_used: number;
}

export interface Available {
  daily_purchase_available: number;
  daily_withdrawal_available: number;
  daily_internet_purchase_available: number;
  daily_contactless_purchase_available: number;
  daily_overall_purchase_available: number;

  weekly_purchase_available: number;
  weekly_withdrawal_available: number;
  weekly_internet_purchase_available: number;
  weekly_contactless_purchase_available: number;
  weekly_overall_purchase_available: number;

  monthly_purchase_available: number;
  monthly_withdrawal_available: number;
  monthly_internet_purchase_available: number;
  monthly_contactless_purchase_available: number;
  monthly_overall_purchase_available: number;

  all_time_purchase_available: number;
  all_time_withdrawal_available: number;
  all_time_internet_purchase_available: number;
  all_time_contactless_purchase_available: number;
}

export interface CardLimitWithUsage extends CardLimit, Usage, Available {
  //
}

export type CardSecurityKeys = keyof CardLimit;

export type UpdateCardLimitParams = {
  [key in keyof CardLimitWithUsage]?: number;
};

export enum Card3DSecurityType {
  SMSOTPAndStaticPassword = 'SMSOTPAndStaticPassword',
}

export interface Card3DSecureSettings {
  password: string;
  mobile?: string;
  email?: string;
  out_of_band_enabled?: boolean;
  language_code?: 'ENG';
  type: Card3DSecurityType;
}

export type CardDispatchMethod =
  | 'BatchEconomy'
  | 'DPDExpress'
  | 'StandardLatvianPostMail'
  | 'DHLGlobalMailTracked'
  | 'DHLGlobalMail'
  | 'DHLExpress';

export type BatchCardDispatchMethod = 'BatchEconomy';

export interface CardholderDeliveryAddress {
  first_name: string;
  last_name: string;
  company_name?: string;
  address1: string;
  address2?: string;
  postal_code: string;
  city: string;
  country_code: CountryCodes3l;
  dispatch_method: CardDispatchMethod;
  phone?: string;
  tracking_number?: string;
}

export type CardBlockType =
  | 'BlockedByCardholder'
  | 'BlockedByCardUser'
  | 'BlockedByCardholderViaPhone'
  | 'BlockedByClient'
  | 'BlockedByIssuer'
  | 'Counterfeit'
  | 'Fraudulent'
  | 'Lost'
  | 'MaxInvalidTriesCVV2'
  | 'MaxInvalidTriesPIN'
  | 'NotDelivered'
  | 'Frozen'
  | 'Stolen';

export const CardBlockLabels: Record<CardBlockType, string> = {
  BlockedByCardholder: 'App-Blocked_by_cardholder',
  BlockedByCardUser: 'App-Blocked_by_card_user',
  BlockedByCardholderViaPhone: 'App-Blocked_by_cardholder_via_phone',
  BlockedByClient: 'App-Blocked_by_client',
  BlockedByIssuer: 'App-Blocked_by_issuer',
  Counterfeit: 'App-Counterfeit',
  Fraudulent: 'App-Fraudulent',
  Lost: 'App-Lost',
  MaxInvalidTriesCVV2: 'App-Max_invalid_tries_CVV2',
  MaxInvalidTriesPIN: 'App-Max_invalid_tries_PIN',
  NotDelivered: 'App-Not_delivered',
  Stolen: 'App-Stolen',
  Frozen: 'App-Frozen',
};

export enum ECardStatusList {
  Active = 'Active',
  AwaitingRenewal = 'AwaitingRenewal',
  Blocked = 'Blocked',
  Closed = 'Closed',
  Closing = 'Closing',
  Created = 'Created',
  Dispatched = 'Dispatched',
  Expired = 'Expired',
  Ordered = 'Ordered',
  Personalized = 'Personalized',
  Deleted = 'Deleted',
  Locked = 'Locked',
}

export const CardStatusList = [
  'Active',
  'AwaitingRenewal',
  'Blocked',
  'Closed',
  'Closing',
  'Created',
  'Dispatched',
  'Expired',
  'Ordered',
  'Personalized',
  'Deleted',
  'Locked',
] as const;

export type CardStatus = typeof CardStatusList[number];

export const CardStatusLabels: Record<CardStatus, string> = {
  Active: 'App-Card_Active',
  AwaitingRenewal: 'App-Card_AwaitingRenewal',
  Blocked: 'App-Card_Blocked',
  Closed: 'App-Card_Closed',
  Closing: 'App-Card_Closing',
  Created: 'App-Card_Created',
  Dispatched: 'App-Card_Dispatched',
  Expired: 'App-Card_Expired',
  Ordered: 'App-Card_Ordered',
  Personalized: 'App-Card_Personalized',
  Deleted: 'App-Card_Deleted',
  Locked: 'App-Card_Locked',
};

export type TCardType = 'ChipAndPin' | 'ChipAndPinAnonymous' | 'Virtual';

export type PinRule = typeof PinRules[number];
export type ChipAndPinCardAllowedAuthorizationType = typeof ChipAndPinCardsAuthorizationTypes[number];

export enum ECardSecuritySettingsKeys {
  ContactlessEnabled = 'contactless_enabled',
  WithdrawalEnabled = 'withdrawal_enabled',
  InternetPurchaseEnabled = 'internet_purchase_enabled',
  OverallLimitsEnabled = 'overall_limits_enabled',
  AllTimeLimitsEnabled = 'all_time_limits_enabled',
}
export interface CardSecuritySettings {
  [ECardSecuritySettingsKeys.ContactlessEnabled]: boolean;
  [ECardSecuritySettingsKeys.WithdrawalEnabled]: boolean;
  [ECardSecuritySettingsKeys.InternetPurchaseEnabled]: boolean;
  [ECardSecuritySettingsKeys.OverallLimitsEnabled]: boolean;
  [ECardSecuritySettingsKeys.AllTimeLimitsEnabled]: boolean;
}

export type CardSecuritySettingsKeys = `${ECardSecuritySettingsKeys}`;

export type UpdateCardSecurityParams = TypedMap<
  CardSecuritySettingsKeys,
  boolean
>;

export type CardCloseReason =
  | 'ClosedByCardholder'
  | 'ClosedByClient'
  | 'ClosedByIssuer'
  | 'ClosedByReplace'
  | 'ClosedBySystem';

export type CardId = string;

export interface ICard {
  account_id: UUID4;
  activated_at?: string;
  company_id?: UUID4;
  created_at: string;
  id: UUID4;
  is_card_3d_secure_activated?: boolean;
  limits: CardLimitWithUsage;
  masked_card_number: string;
  name?: string;
  personalization_product_code: string;
  security: CardSecuritySettings;
  status: CardStatus;
  type: TCardType;
  updated_at: string;
  currency_code?: CurrencyType;
  expiry_date: string;

  '3d_secure_settings'?: Card3DSecureSettings | null;
  block_type?: CardBlockType;
  blocked_at?: string;
  blocked_by?: string;
  card_metadata_profile_id?: UUID4;
  close_reason?: CardCloseReason;
  closed_at?: string;
  closed_by?: string;
  delivery_address?: CardholderDeliveryAddress;
  dispatched_at?: string;
  embossing_company_name?: string;
  embossing_first_name?: string;
  embossing_last_name?: string;
  embossing_name?: string;
  external_id?: string;
  is_disposable?: boolean;
  is_enrolled_for_3d_secure?: boolean;
  person_id?: UUID4;
  predecessor_card_id?: UUID4;
  reference_number?: string;
  renew_automatically?: boolean;
  card_notification_settings?: TCardNotifications;
}

export interface GetCardResponse {
  card: ICard;
  id: CardId;
}

export interface GetCardHistoryResponse {
  items: CardHistoryItem[];
  totalItems: number;
}

export interface GetCardTransactionsResponse {
  items: CardTransactionItem[];
  totalItems: number;
}

export interface GetBlackCardTransactionsResponse {
  items: CardStatementItem[];
  totalItems: number;
  pendingAmount?: number;
  debitTurnover?: number;
  creditTurnover?: number;
}

export interface GetCardAuthorizationsResponse {
  authorizations: CardAuthorizationItem[];
  totalItems: number;
}

export interface CardHistoryRequestParams {
  cardId: CardId;
  currentPage: number;
  pageSize: number;
  fromDate: moment.Moment;
  toDate: moment.Moment;
}

export interface CardTransactionsRequestParams extends OrderBy {
  cardId: CardId;
  currentPage?: number;
  fromRecord?: number;
  pageSize: number;
  orderField?: string;
  orderDirection?: string;
  fromDate: moment.Moment;
  toDate: moment.Moment;
  includeAuthorizations?: boolean;
  includeTransactions?: boolean;
  includeFees?: boolean;
  excludeDeclinedAuthorizations?: boolean;
  excludeReversedAuthorizations?: boolean;
  excludeClearedAuthorizations?: boolean;
  excludePendingAuthorizations?: boolean;
  excludeStatusAuthorizations?: boolean;
  excludePendingFees?: boolean;
  excludeClearedFees?: boolean;
  excludeDeclinedFees?: boolean;
  mergeFees?: boolean;
  searchKeyword?: string;
}

export type CardAuthorizationsRequestParams = CardTransactionsRequestParams;

export interface ICardList extends OrderBy {
  items: ICard[];
  totalItems?: number;
}

export interface ICardListResponse {
  cards: ICard[];
  total_records_number?: number;
}

export interface CardHistoryItem {
  created_at: string;
  created_by: UUID4;
  created_by_info?: IGetUsersInfoResponse;
  field: string;
  new_value: string;
  old_value: string;
}

export type CardStatementGroup =
  | 'AuthorizationATMBalanceInquiryFixedFee'
  | 'AuthorizationATMDeclinedFixedFee'
  | 'AuthorizationATMWithdrawalComplexFee'
  | 'AuthorizationATMWithdrawalFixedFee'
  | 'AuthorizationATMWithdrawalPercentageFee'
  | 'AuthorizationDeclinedFixedFee'
  | 'AuthorizationForeignExchangePercentageFee'
  | 'CardIssuanceFixedFee'
  | 'CardReplacementFixedFee'
  | 'CardUsageFixedFee'
  | 'Deposit'
  | 'InternetPurchase'
  | 'Other'
  | 'PINChangeFixedFee'
  | 'PINManagement'
  | 'Purchase'
  | 'Refund'
  | 'Withdraw';

export type CardTransactionGroup =
  | 'Deposit'
  | 'InternetPurchase'
  | 'Other'
  | 'Purchase'
  | 'Withdraw';

export type CardAuthorizationGroup =
  | 'Deposit'
  | 'InternetPurchase'
  | 'Other'
  | 'Purchase'
  | 'Withdraw'
  | 'PINManagement'
  | 'Refund';

export type CardTransactionItem = {
  account_amount: number;
  account_currency_code: CurrencyType;
  acquirer_business_id: string;
  acquirer_reference_number: string;
  authorization_id: string;
  card_id: CardId;
  created_at: DateTime;
  enriched_merchant_data: {
    name: string;
    url: string;
    domain: string;
    telephone_number: string;
    icon_url: string;
  };
  foreign_exchange_fee: number;
  group: CardTransactionGroup;
  id: UUID4;
  marked_for_dispute_at: DateTime;
  marked_for_dispute_by: UUID4;
  merchant_category_code: MerchantCategoriesType;
  merchant_city: string;
  merchant_country_code: CountryCodes3l;
  merchant_id: string;
  merchant_name: string;
  merchant_postal_code: string;
  processed_at: DateTime;
  purchase_date: DateTime;
  terminal_id: string;
  transaction_amount: number;
  transaction_code: string;
  transaction_currency_code: CurrencyType;
  transaction_identifier: string;
  status?: StatementStatus;
  response?: string;
  response_code?: string;
};

export type CardStatementItem = {
  id: UUID4;
  is_cleared: boolean;
  is_declined: boolean;
  is_reversal: boolean;
  card_id: CardId;
  group: CardTransactionGroup;
  transaction_amount: number;
  transaction_currency_code: CurrencyType;
  type: 'Authorization' | 'Fee' | 'Transaction';
  account_amount: number;
  account_currency_code: CurrencyType;
  date: DateTime;
  description: string;
  purchase_date: DateTime;
  merchant_name: string;
  status: 'Canceled' | 'Completed' | 'Pending';
  response: 'Approved' | 'Declined';
  has_payment_document_files: boolean;
  sub_type: string;
  total_amount: number;

  account_external_id?: string;
  card_name?: string;
  enriched_merchant_data?: {
    name?: string;
    url?: string;
    domain?: string;
    telephone_number?: string;
    icon_url?: string;
  };
  exchange_rate?: number;
  marked_for_dispute_at?: DateTime;
  marked_for_dispute_by?: UUID4;
  masked_card_number?: string;
  merchant_category_code?: MerchantCategoriesType;
  merchant_city?: string;
  merchant_country_code?: CountryCodes3l;
  merchant_id?: string;
  original_authorization_id?: string;
  response_code?: string;
  terminal_id?: string;
};

export interface CardAuthorizationItem extends IAuthorization {
  id: UUID4;
  card_id: CardId;
  date: DateTime;
  enriched_merchant_data: {
    name: string;
    url: string;
    domain: string;
    telephone_number: string;
    icon_url: string;
  };
  expiration_date: DateTime;
  type: AuthorizationType;
  transaction_amount: number;
  transaction_currency_code: CurrencyType;
  account_amount: number;
  account_currency_code: CurrencyType;
  merchant_category_code: MerchantCategoriesType;
  merchant_id: string;
  terminal_id: string;
  merchant_name: string;
  merchant_city: string;
  merchant_country_code: CountryCodes3l;
  response_code: string;
  created_at: DateTime;
  approved_amount: number;
  approval_code: string;
  reserved_amount: number;
  threshold_amount: number;
  reversed_amount: number;
  cleared_amount: number;
  balance: number;
  foreign_exchange_fee: number;
  group: CardTransactionGroup;
  original_authorization_id: UUID4;
  retrieval_reference_number: string;
  marked_for_dispute_at: DateTime;
  marked_for_dispute_by: UUID4;
  released_at: DateTime;
  is_reversal: boolean;
  is_declined: boolean;
  is_contactless: boolean;
  is_mass_transit: boolean;
  status?: StatementStatus;
  response?: string;
  fraud_result?: {
    risk_status: FraudAuthorizationRiskStatus;
    risk_score: number;
    risk_zone: RiskZone;
  };
}
export interface ICustomerCardsRequest
  extends PagerProps,
    OrderBy,
    ICustomerCardsFilters {
  [key: string]: unknown;
  customerId: UUID4;
}

export interface IAccountCardsRequest extends PagerProps, OrderBy {
  [key: string]: unknown;
  accountId: UUID4;
  cardStatuses?: CardStatus[];
}

export type UpdateCardStatusRequestParams = {
  status: CardStatus;
  block_type?: CardBlockType;
  close_reason?: CardCloseReason;
};

export type CreateCardParams = {
  '3d_secure_settings': Card3DSecureSettings;
  account_id: UUID4;
  delivery_address?: {
    address1: string;
    address2: string;
    city: string;
    company_name?: string;
    country_code: CountryCodes3l;
    dispatch_method: CardDispatchMethod;
    phone?: string;
    postal_code: string;
  };
  encrypted_pin?: string;
  expiry_days?: number;
  expiry_days_round: boolean;
  external_id: string;
  limits: Partial<CardLimit>;
  name: string;
  person_id: UUID4;
  personalization_product_code: string;
  predecessor_card_id: UUID4;
  security: CardSecuritySettings;
  type: TCardType;
  separated_embossing_name?: {
    first_name?: string;
    last_name?: string;
  };
  embossing_first_name?: string;
  embossing_last_name?: string;
};

export type CardLimitsSettings = {
  default_card_daily_contactless_purchase_limit: number;
  default_card_daily_internet_purchase_limit: number;
  default_card_daily_purchase_limit: number;
  default_card_daily_overall_purchase_limit: number;
  default_card_daily_withdrawal_limit: number;
  default_card_weekly_contactless_purchase_limit: number;
  default_card_weekly_internet_purchase_limit: number;
  default_card_weekly_purchase_limit: number;
  default_card_weekly_overall_purchase_limit: number;
  default_card_weekly_withdrawal_limit: number;
  default_card_monthly_contactless_purchase_limit: number;
  default_card_monthly_internet_purchase_limit: number;
  default_card_monthly_purchase_limit: number;
  default_card_monthly_overall_purchase_limit: number;
  default_card_monthly_withdrawal_limit: number;
  default_card_transaction_contactless_purchase_limit: number;
  default_card_transaction_internet_purchase_limit: number;
  default_card_transaction_purchase_limit: number;
  default_card_transaction_withdrawal_limit: number;
  max_card_daily_contactless_purchase_limit: number;
  max_card_daily_internet_purchase_limit: number;
  max_card_daily_purchase_limit: number;
  max_card_daily_overall_purchase_limit: number;
  max_card_daily_withdrawal_limit: number;
  max_card_weekly_contactless_purchase_limit: number;
  max_card_weekly_internet_purchase_limit: number;
  max_card_weekly_purchase_limit: number;
  max_card_weekly_overall_purchase_limit: number;
  max_card_weekly_withdrawal_limit: number;
  max_card_monthly_contactless_purchase_limit: number;
  max_card_monthly_internet_purchase_limit: number;
  max_card_monthly_purchase_limit: number;
  max_card_monthly_overall_purchase_limit: number;
  max_card_monthly_withdrawal_limit: number;
  max_card_transaction_contactless_purchase_limit: number;
  max_card_transaction_internet_purchase_limit: number;
  max_card_transaction_purchase_limit: number;
  max_card_transaction_withdrawal_limit: number;
  weekly_limits_enabled: boolean;
};

export type IEmployeesCardCreateParams = {
  name?: string;
  email?: string;
  mobile?: string;
};

export interface CardAuthorizationRequestParams {
  cardId: CardId;
  authorizationId: UUID4;
}

export type CardDispatchMethodInfo = {
  dispatch_method: CardDispatchMethod;
  price: number;
  description?: string;
  delivery_time?: {
    min: string;
    max: string;
  };
};

export type Send3DSOtpParams = {
  amount: number;
  currency_code: CurrencyType;
  merchant_name: string;
  otp: string;
  purchase_date: DateTime;
};

export type TCardNotifications = {
  receipts_reminder_enabled?: boolean;
};
