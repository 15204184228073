import React from 'react';
import Link from '../../components/Link';
import Features from '../../constants/features';
import useCheckFeatures from '../../customHooks/useCheckFeatures';
import Demo from '../../utils/demo';

interface DemoRegistrationLinkProps {
  onClick?: () => void;
}

const DemoRegistrationLink: React.FC<
  React.PropsWithChildren<DemoRegistrationLinkProps>
> = ({onClick}) => {
  const showRegistrationForm = useCheckFeatures(
    Features.ABTestingRegistration,
    'registration_through_demo'
  );
  return (
    <Link
      to={Demo.getInstance().getRegistrationLink(showRegistrationForm)}
      external={!showRegistrationForm}
      onClick={onClick}
    />
  );
};

export default DemoRegistrationLink;
