import {useStoreMap} from 'effector-react';
import {App$} from '../models/app';

const useIsWhiteLabel = (): boolean => {
  return useStoreMap({
    store: App$,
    keys: ['isWhiteLabeled'],
    fn: (store$) => store$.isWhiteLabeled || false,
  });
};

export default useIsWhiteLabel;
