import React from 'react';
import clsx from 'clsx';
import useIsDemo from '../../customHooks/useIsDemo';

const Messages: React.FC = () => {
  const isDemo = useIsDemo();

  return (
    <div className={clsx('ant-messages', isDemo ? 'ant-messages_demo' : '')} />
  );
};

export default Messages;
