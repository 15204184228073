import {AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import statuses from 'statuses';
import moment from 'moment-timezone';
import queryString from 'query-string';
import systemConfig from '../constants/config';
import DemoInitData, {
  DEMO_CURRENCIES_PROFILES,
  DEMO_PROFILE_ID,
  DEMO_TREATMENTS,
  DEMO_USER_ID,
} from '../constants/demo';
import DEMO_AGREEMENT from '../constants/demo/agreement';
import Endpoints, {WLEndpoints} from '../constants/endpoints';
import routes, {getRoutePath} from '../constants/routes';
import logger from '../logger';
import {App$} from '../models/app';
import {
  AssignProfileResponse,
  AxiosUrl,
  CardDispatchMethodInfo,
  ClientVerificationConfig,
  CorporateDocument,
  CurrencyRequestConfig,
  CurrencyType,
  Employee,
  IAccount,
  IApiKey,
  IClient,
  IGetUsersInfoResponse,
  IProductBase,
  IRole,
  IRoleListMap,
  IUser,
  IUserUpdateSelfProfileParams,
  LimitUsageInfo,
  ListSortOrderParams,
  MassPaymentsFile,
  MassPaymentsPaymentWithFileId,
  PricingPlans,
  ProductPricingPlan,
  ProductSettings,
  ReservedSettlementBalance,
  ServerStorageData,
  SystemProfile,
  SystemUser,
  TreatmentsWithConfig,
  TClientTicket,
  TokenInfo,
  TypedMap,
  UUID4,
} from '../types';
import DemoData, {IAccountStatementExt, ICardExt} from '../types/demo';
import {TopUpHistoryItem} from '../types/statement';
import addAccount from './demo/addAccount';
import addUser from './demo/addUser';
import changeCardPin from './demo/changeCardPin';
import deleteUser from './demo/deleteUser';
import downloadPaymentDocumentFile from './demo/downloadPaymentDocumentFile';
import getAccountsHandler from './demo/getAccounts';
import getCardPinCode from './demo/getCardPinCode';
import getCardTopUpLink from './demo/getCardTopUpLink';
import getCardTopUpPreview from './demo/getCardTopUpPreview';
import getPersonsAndCompanies from './demo/getPersonsAndCompanies';
import getProfiles from './demo/getProfiles';
import getTopUpHistory from './demo/getTopUpHistory';
import updateAccountLimitsHandler from './demo/updateAccountLimitsHandler';
import updateCompanyLimitsHandler from './demo/updateCompanyLimits';
import updateUserRole from './demo/updateUserRole';
import localStorageWrapper from './localStorageWrapper';
import ObjectKeys from './object';
import RequestDemoError from './RequestDemoError';
import getEmployee from './demo/getEmplyee';
import getEmployees from './demo/getEmplyees';
import getProductCards from './demo/getProductCards';
import deleteEmployee from './demo/deleteEmployee';
import reInviteEmployee from './demo/reInviteEmployee';
import createEmployeesCard from './demo/createEmployeesCard';
import updateEmployeesCard from './demo/updateEmployeesCard';
import unblockCardsBatch from './demo/unblockCardsBatch';
import blockCardsBatch from './demo/blockCardsBatch';
import closeCardsBatch from './demo/closeCardsBatch';
import createCard from './demo/createCard';
import getCard from './demo/getCard';
import getCompany from './demo/getCompany';
import getAccount from './demo/getAccount';
import addCardUser from './demo/addCardUser';
import getCardNumber from './demo/getCardNumber';
import getCardCvvCode from './demo/getCardCvvCode';
import getCard3dsPassword from './demo/getCard3dsPassword';
import updateCard3dSecureSettings from './demo/updateCard3dSecureSettings';
import updateCardName from './demo/updateCardName';
import blockCard from './demo/blockCard';
import unblockCard from './demo/unblockCard';
import closeCard from './demo/closeCard';
import updateCardLimit from './demo/updateCardLimit';
import updateCardSecurity from './demo/updateCardSecurity';
import getAccountStatement from './demo/getAccountStatement';
import getMassPaymentsFiles from './demo/getMassPaymentsFiles';
import getMassPaymentsFile from './demo/getMassPaymentsFile';
import getApiKeysList from './demo/getApiKeysList';
import deleteApiKey from './demo/deleteApiKey';
import getCardStatements from './demo/getCardStatements';
import getAccountStatementFile from './demo/getAccountStatementFile';
import getPaymentDocumentThumbnails from './demo/getPaymentDocumentThumbnails';
import addPaymentDocumentFiles from './demo/addPaymentDocumentFiles';
import deletePaymentDocumentFile from './demo/deletePaymentDocumentFile';
import discardMassPaymentsFile from './demo/discardMassPaymentsFile';
import completeMassPaymentsFile from './demo/completeMassPaymentsFile';
import confirmMassPaymentsFile from './demo/confirmMassPaymentsFile';
import retryMassPaymentsPayment from './demo/retryMassPaymentsPayment';
import makeMoneyTransferToAccount from './demo/makeMoneyTransferToAccount';
import makeMoneyTransferToEmployee from './demo/makeEmployeeMoneyTransfer';
import getClientSupportManagers from './demo/getClientSupportManagers';
import getAllowedTopUpMethods from './demo/getAllowedTopUpMethods';
import getCardTopUpFees from './demo/getCardTopUpFees';
import getTopUpLink from './demo/getTopUpLink';
import getTopUpPreview from './demo/getTopUpPreview';
import getCardUsers from './demo/getCardUsers';

const logDemo = logger.module('Demo');
const DEMO_STORAGE_KEY = 'WB_DEMO_DATA';
type DemoApiHandlerParams = {
  config: InternalAxiosRequestConfig;
  instance: Demo;
  data: Record<string, unknown>;
  url: AxiosUrl;
};
export type DemoApiHandler = (params: DemoApiHandlerParams) => AxiosResponse;

class Demo {
  private static instance: Demo | null = null;

  private _demoData: DemoData | null = null;

  public static makeDataResponse = <T>(
    config: InternalAxiosRequestConfig,
    data: T,
    code = 200
  ): AxiosResponse => {
    return {
      config,
      headers: {},
      data,
      status: code,
      statusText: `${code} ${statuses(code)}`,
    };
  };

  public static makeUnprocessableEntityResponse = (
    config: InternalAxiosRequestConfig,
    code?: number
  ): AxiosResponse => {
    return Demo.makeDataResponse(
      config,
      {
        message: 'Unprocessable Entity',
        error_code: code || 30001,
        error_text: 'no card found',
      },
      422
    );
  };

  public static makeNotImplementedResponse(
    config: InternalAxiosRequestConfig
  ): AxiosResponse {
    return {
      config,
      headers: {},
      data: {error: 'Not implemented'},
      status: 501,
      statusText: '501 Not implemented',
    };
  }

  private static apis: TypedMap<Endpoints | WLEndpoints, DemoApiHandler> = {
    // KUSH
    [Endpoints.getCurrentUser]: ({config, instance}) => {
      return Demo.makeDataResponse<{user: SystemUser}>(config, {
        user: instance.getData().user,
      });
    },
    [Endpoints.assignedProduct]: ({config, instance}) => {
      return Demo.makeDataResponse<{products: IProductBase[]}>(config, {
        products: instance.getData().assignedProduct,
      });
    },
    [Endpoints.getServerStorageData]: ({config, instance}) => {
      return Demo.makeDataResponse<ServerStorageData>(config, {
        ...instance.getData().serverStorage,
      });
    },
    [Endpoints.setServerStorageData]: ({config, instance, data}) => {
      if (data && ObjectKeys(data).length > 0) {
        instance.updateServerStorageValue(data);
      }
      return Demo.makeDataResponse<'ok'>(config, 'ok');
    },
    [Endpoints.updateUserSelfProfile]: ({config, instance, data}) => {
      if (data && ObjectKeys(data).length > 0) {
        instance.updateCurrentUser(data);
      }
      return Demo.makeDataResponse<{user: IUser}>(config, {
        user: {
          ...instance.getData().user,
          password: '',
          client_id: instance.getData().user.client_id || '',
        },
      });
    },
    [Endpoints.updateAccountName]: ({
      config,
      instance,
      data,
      url: {params},
    }) => {
      const account = instance
        .getData()
        .accounts[instance.getData().productId].find(
          (a) => a.id === params?.account_id
        );
      if (account && typeof data.name === 'string') {
        account.name = data.name;
        instance.saveImpl(['accounts']);
        return Demo.makeDataResponse<{
          account: IAccount;
        }>(config, {
          account: {...account},
        });
      }

      return Demo.makeUnprocessableEntityResponse(config);
    },
    [Endpoints.assignProfile]: ({config, instance, url}) => {
      if (url.params) {
        instance.assignProduct(url.params);
      }
      return Demo.makeDataResponse<AssignProfileResponse>(config, {
        profile_permission_ids: instance.getData().defaultProfilePermissions,
      });
    },
    [Endpoints.getAllowedEndpoints]: ({config, instance}) => {
      return Demo.makeDataResponse<Endpoints[]>(
        config,
        instance._demoData?.allowedEndpoints || []
      );
    },
    [Endpoints.getProductSettings]: ({config, instance}) => {
      return Demo.makeDataResponse<{
        product_settings: ProductSettings;
      }>(config, {product_settings: instance.getData().productSettings});
    },
    [Endpoints.getReservedSettlementBalance]: ({config, instance}) => {
      const {productId} = instance.getData();
      return Demo.makeDataResponse<ReservedSettlementBalance>(config, {
        ...instance.getData().reservedSettlementBalance,
        amount: (instance.getData().accounts[productId] || []).reduce(
          (prev, a) => prev + a.available_amount,
          0
        ),
      });
    },

    [Endpoints.getCardDeliveryOptions]: ({config, instance}) => {
      return Demo.makeDataResponse<{
        dispatch_methods: CardDispatchMethodInfo[];
      }>(config, {
        dispatch_methods: instance.getData().cardDispatchMethods,
      });
    },

    [Endpoints.getMultiCurrencyRequestConfig]: ({config, instance}) => {
      return Demo.makeDataResponse<CurrencyRequestConfig>(
        config,
        instance.getData().currencyRequestConfig
      );
    },
    [Endpoints.getClientTickets]: ({config}) => {
      return Demo.makeDataResponse<{benefits_tickets: TClientTicket[]}>(
        config,
        {benefits_tickets: []}
      );
    },
    [Endpoints.getCompanyLimitsUsage]: ({config, instance, url}) => {
      if (url?.params?.company_id) {
        const company = instance
          .getData()
          .companies.find((c) => c.id === url?.params?.company_id);
        if (company) {
          const usage = instance.getData().companyLimitsUsage[
            url?.params?.company_id
          ];
          if (usage) {
            return Demo.makeDataResponse<LimitUsageInfo>(config, usage);
          }
        }
      }
      return Demo.makeUnprocessableEntityResponse(config);
    },
    [Endpoints.getAccountLimitsUsage]: ({config, instance}) => {
      return Demo.makeDataResponse<LimitUsageInfo>(
        config,
        instance.getData().accountLimitsUsage
      );
    },
    [Endpoints.updateCompanyLimits]: updateCompanyLimitsHandler,
    [Endpoints.updateAccountLimits]: updateAccountLimitsHandler,
    [Endpoints.getProductPricingPlan]: ({config, instance}) => {
      return Demo.makeDataResponse<ProductPricingPlan>(
        config,
        instance.getData().pricingPlan[instance.getData().productId]
      );
    },
    [Endpoints.getUsersInfo]: ({config, instance, url}) => {
      const userData = instance.getData().user;
      const {productUsers} = instance.getData();
      return Demo.makeDataResponse<{users_info: IGetUsersInfoResponse[]}>(
        config,
        {
          users_info: (
            (url.queryParams?.user_ids as UUID4[]) || []
          ).map<IGetUsersInfoResponse>((uid) => {
            const user = productUsers.find((pu) => pu.id === uid) || userData;
            return {
              id: uid,
              first_name: user.first_name,
              last_name: user.last_name,
            };
          }),
        }
      );
    },
    [Endpoints.customer]: ({config, instance, url}) => {
      const userData = instance.getData().user;
      const {productUsers} = instance.getData();
      return Demo.makeDataResponse<{users_info: IGetUsersInfoResponse[]}>(
        config,
        {
          users_info: (
            (url.queryParams?.user_ids as UUID4[]) || []
          ).map<IGetUsersInfoResponse>((uid) => {
            const user = productUsers.find((pu) => pu.id === uid) || userData;
            return {
              id: uid,
              first_name: user.first_name,
              last_name: user.last_name,
            };
          }),
        }
      );
    },
    [Endpoints.getPricingPlans]: ({config, instance}) => {
      return Demo.makeDataResponse<{pricing_plan_types: PricingPlans}>(config, {
        pricing_plan_types: instance.getData().pricingPlans,
      });
    },
    [Endpoints.getAgreement]: ({config}) => {
      return Demo.makeDataResponse<{agreement: string}>(config, {
        agreement: DEMO_AGREEMENT,
      });
    },
    [Endpoints.getCorporateDocuments]: ({config, instance}) => {
      return Demo.makeDataResponse<{corporate_documents: CorporateDocument[]}>(
        config,
        {
          corporate_documents: instance.getData().corporateDocuments,
        }
      );
    },
    [Endpoints.getCurrentClient]: ({config, instance}) => {
      let client = instance._demoData?.clients[instance._demoData?.productId];
      if (!client) {
        const clientProductId = ObjectKeys(
          instance._demoData?.clients || {}
        )[0];
        client = (instance._demoData?.clients || {})[clientProductId];
      }
      return Demo.makeDataResponse<{client: IClient}>(config, {client});
    },
    [Endpoints.getAccounts]: getAccountsHandler,
    [Endpoints.closeAccount]: ({config, instance, url: {params}}) => {
      const account = instance
        .getData()
        .accounts[instance.getData().productId].find(
          (a) => a.id === params?.account_id
        );
      if (account) {
        account.status = 'Closed';
        account.close_reason = 'ClosedByClient';
        account.closed_at = moment().format();
        account.closed_by = DEMO_USER_ID;
        instance.saveImpl(['accounts']);
        return Demo.makeDataResponse<{
          account: IAccount;
        }>(config, {
          account,
        });
      }

      return Demo.makeUnprocessableEntityResponse(config);
    },
    [Endpoints.clientVerificationGetClientPersonAndCompanyList]: getPersonsAndCompanies,
    [Endpoints.getProfiles]: getProfiles,
    [Endpoints.getRolesList]: ({config, instance}) => {
      return Demo.makeDataResponse<{
        roles: IRole[];
        total_record_numbers: number;
      }>(config, {
        roles: instance.getData().roles,
        total_record_numbers: instance.getData().roles.length,
      });
    },
    [Endpoints.createAccount]: addAccount,
    // EGOR R
    // EGOR O
    [Endpoints.getAccountStatement]: getAccountStatement,
    [Endpoints.getAccountStatementFile]: getAccountStatementFile,
    [Endpoints.downloadSummaryStatements]: ({config, url}) => {
      let link = '/files/summary_statement_demo.pdf';
      switch (url.queryParams?.format) {
        case 'excel':
          link = '/files/summary_statement_demo_xlsx.pdf';
          break;
        case 'csv':
          link = '/files/summary_statement_demo_csv.pdf';
          break;
        default:
          break;
      }
      return Demo.makeDataResponse<{link: string}>(config, {
        link,
      });
    },
    [Endpoints.downloadFeeInvoice]: ({config}) => {
      return Demo.makeDataResponse<{link: string}>(config, {
        link: '/files/fee_invoice_demo.pdf',
      });
    },
    [Endpoints.getVerificationConfig]: ({config}) => {
      return Demo.makeDataResponse<ClientVerificationConfig>(config, {
        activity_types: [],
        employees_quantities: [],
        activity_type_linked_list: [],
        card_spending_amounts: [],
      });
    },
    [Endpoints.downloadPaymentDocumentFiles]: downloadPaymentDocumentFile,

    [Endpoints.getPaymentDocumentThumbnails]: getPaymentDocumentThumbnails,
    [Endpoints.addPaymentDocumentFiles]: addPaymentDocumentFiles,
    [Endpoints.deletePaymentDocumentFile]: deletePaymentDocumentFile,

    [Endpoints.getAccount]: getAccount,

    [Endpoints.getCompany]: getCompany,

    [Endpoints.getProductCards]: getProductCards,
    [Endpoints.blockCardsBatch]: blockCardsBatch,
    [Endpoints.unblockCardsBatch]: unblockCardsBatch,
    [Endpoints.closeCardsBatch]: closeCardsBatch,

    [Endpoints.getCardStatements]: getCardStatements,
    [Endpoints.createCard]: createCard,
    [Endpoints.getCard]: getCard,
    [Endpoints.addCardUser]: addCardUser,
    [Endpoints.setUserRole]: updateUserRole,
    [Endpoints.ensureCompanyAccess]: addUser,
    [Endpoints.deleteProfilesBatch]: deleteUser,
    [Endpoints.getCardNumber]: getCardNumber,
    [Endpoints.getCardCvvCode]: getCardCvvCode,
    [Endpoints.getCard3dsPassword]: getCard3dsPassword,
    [Endpoints.getCardPinCode]: getCardPinCode,
    [Endpoints.updateCard3dSecureSettings]: updateCard3dSecureSettings,
    [Endpoints.updateCardName]: updateCardName,
    [Endpoints.changeCardPin]: changeCardPin,
    [Endpoints.blockCard]: blockCard,
    [Endpoints.unblockCard]: unblockCard,
    [Endpoints.closeCard]: closeCard,
    [Endpoints.updateCardLimit]: updateCardLimit,
    [Endpoints.updateCardSecurity]: updateCardSecurity,

    [Endpoints.getEmployees]: getEmployees,

    [Endpoints.createEmployeesCard]: createEmployeesCard,
    [Endpoints.reInviteEmployee]: reInviteEmployee,
    [Endpoints.getEmployee]: getEmployee,
    [Endpoints.updateEmployeesCard]: updateEmployeesCard,
    [Endpoints.deleteEmployee]: deleteEmployee,
    [Endpoints.getClientSupportManagers]: getClientSupportManagers,

    [Endpoints.getAllowedTopUpMethods]: getAllowedTopUpMethods,
    [Endpoints.getCardTopUpFees]: getCardTopUpFees,
    [Endpoints.getTopUpLink]: getTopUpLink,
    [Endpoints.getCardTopUpLink]: getCardTopUpLink,
    [Endpoints.topUpPreview]: getTopUpPreview,
    [Endpoints.getCardTopUpPreview]: getCardTopUpPreview,

    // RUS
    [Endpoints.makeMoneyTransferToAccount]: makeMoneyTransferToAccount,
    [Endpoints.makeMoneyTransferToEmployee]: makeMoneyTransferToEmployee,
    [Endpoints.getMassPaymentsFiles]: getMassPaymentsFiles,
    [Endpoints.getTopUpHistory]: getTopUpHistory,
    [Endpoints.getMassPaymentsFile]: getMassPaymentsFile,
    [Endpoints.completeMassPaymentFile]: completeMassPaymentsFile,
    [Endpoints.discardMassPaymentsFile]: discardMassPaymentsFile,
    [Endpoints.confirmMassPaymentFile]: confirmMassPaymentsFile,
    [Endpoints.retryMassPaymentsFile]: retryMassPaymentsPayment,

    [Endpoints.getApiKeysList]: getApiKeysList,
    [Endpoints.deleteApiKey]: deleteApiKey,

    [Endpoints.getCardUsers]: getCardUsers,
  };

  public static getTreatments(): TreatmentsWithConfig {
    return DEMO_TREATMENTS;
  }

  public static isDemoMode(): boolean {
    return App$.getState().config.demo;
  }

  public static toListSortOrderParams(
    params:
      | Record<
          string,
          string | number | string[] | number[] | boolean | undefined
        >
      | undefined
  ): ListSortOrderParams | undefined {
    if (params === undefined) {
      return undefined;
    }

    const orderDirection = (() => {
      if (typeof params.order_direction === 'string') {
        return params.order_direction;
      }

      if (
        typeof params.order_direction === 'object' &&
        params.order_direction[0]
      ) {
        return params.order_direction[0] as string;
      }

      return undefined;
    })();

    const orderField = (() => {
      if (typeof params.order_field === 'string') {
        return params.order_field;
      }

      if (typeof params.order_field === 'object' && params.order_field[0]) {
        return params.order_field[0] as string;
      }

      return undefined;
    })();

    return {
      order_direction: orderDirection,
      order_field: orderField,
      records_count:
        typeof params.records_count === 'number'
          ? params.records_count
          : undefined,
      from_record:
        typeof params.from_record === 'number' ? params.from_record : undefined,
    };
  }

  public static applySorterAndPager<T extends object>(
    list: T[],
    params?: ListSortOrderParams
  ): T[] {
    if (!params) {
      return [...list];
    }

    let ret: T[] = [...list];

    if (params.order_field) {
      const key = params.order_field as keyof T;
      const isSortDesc =
        (params.order_direction || '').toLowerCase() === 'desc';

      ret.sort((a, b) => {
        const valueA = a[key] as unknown;
        const valueB = b[key] as unknown;

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return !isSortDesc
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return !isSortDesc ? valueA - valueB : valueB - valueA;
        }

        if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
          return !isSortDesc
            ? (valueA ? 0 : 1) - (valueB ? 0 : 1)
            : (valueB ? 0 : 1) - (valueA ? 0 : 1);
        }

        if (typeof valueA === 'undefined' && typeof valueB === 'undefined') {
          return 0;
        }

        if (typeof valueA === 'undefined') {
          return isSortDesc ? -1 : 1;
        }

        if (typeof valueB === 'undefined') {
          return isSortDesc ? 1 : -1;
        }

        return 0;
      });
    }

    if (
      params.records_count !== undefined ||
      params.from_record !== undefined
    ) {
      const start = params.from_record || 0;

      ret = ret.slice(start, start + (params.records_count || ret.length));
    }

    return ret;
  }

  private static loadDemoDataFromStorage: () => Partial<DemoData> = () => {
    const data = localStorageWrapper.getItem(DEMO_STORAGE_KEY);
    if (data) {
      try {
        const decodedData = JSON.parse(data);
        if (decodedData) {
          return decodedData;
        }
      } catch (e) {
        return {};
      }
    }
    return {};
  };

  private static clearDemoData: () => void = () => {
    localStorageWrapper.removeItem(DEMO_STORAGE_KEY);
  };

  private static formatStatements(
    statements: IAccountStatementExt[]
  ): IAccountStatementExt[] {
    const dateHash: Record<UUID4, moment.Moment[]> = {};

    return statements
      .reverse()
      .map((statement, index) => {
        if (dateHash[statement.account_id] === undefined) {
          dateHash[statement.account_id] = [];
          const sCount = statements.filter(
            (s) => s.account_id === statement.account_id
          ).length;
          const perDate = Math.max(1, Math.floor(sCount / 30));
          const m = moment();
          for (let i = 0; i < 30; i += 1) {
            for (let j = 0; j < perDate; j += 1) {
              dateHash[statement.account_id].push(m.clone());
            }
            m.subtract(1, 'day');
          }
        }
        // console.log('0', index);
        // console.log('1', dateHash[statement.account_id].length);
        // console.log(
        //   '2',
        //   (dateHash[statement.account_id].length - 1) % (index + 1)
        // );
        const momentData =
          dateHash[statement.account_id][
            (index + 1) % (dateHash[statement.account_id].length - 1)
          ];
        return {
          ...statement,
          date: momentData.format(),
        };
      })
      .sort((a, b) => {
        return moment(b.date).diff(moment(a.date));
      });
  }

  private static applyProductToData(data: DemoData): DemoData {
    const defaultProfile =
      data.user.profiles.find((p) => p.product_id === data.productId) ||
      data.user.profiles[0];
    const profiles: SystemProfile[] = [];
    const userProductRoles: IRoleListMap = {};
    let currency: CurrencyType = 'EUR';

    if (defaultProfile) {
      data.assignedProduct.forEach((ap) => {
        if (ap.id === data.productId) {
          currency = ap.currency_code || 'EUR';
        }
        userProductRoles[ap.id] = [
          ...(data.user.roles.product[defaultProfile.product_id] || []),
        ];
        profiles.push({
          ...defaultProfile,
          permissions: [...defaultProfile.permissions],
          roles: [...defaultProfile.roles],
          product_id: ap.id,
          target_entity_id: ap.id,
          id:
            DEMO_CURRENCIES_PROFILES[ap.currency_code || 'EUR'] ||
            DEMO_PROFILE_ID,
        });
      });
    }
    const accounts: Record<UUID4, IAccount[]> = {[data.productId]: []};
    ObjectKeys(data.accounts).forEach((productId) => {
      accounts[data.productId] = data.accounts[productId].map((a) => ({
        ...a,
        currency_code: currency,
      }));
    });

    const pricingPlan: Record<UUID4, ProductPricingPlan> = {};
    ObjectKeys(data.pricingPlan).forEach((productId) => {
      pricingPlan[data.productId] = {...data.pricingPlan[productId]};
    });

    const employees: Record<UUID4, Employee[]> = {};
    ObjectKeys(data.employees).forEach((productId) => {
      employees[data.productId] = [...data.employees[productId]];
    });

    const massPaymentsFiles: Record<UUID4, MassPaymentsFile[]> = {};
    ObjectKeys(data.massPaymentsFiles).forEach((productId) => {
      massPaymentsFiles[data.productId] = [
        ...data.massPaymentsFiles[productId],
      ];
    });

    const massPayments: Record<UUID4, MassPaymentsPaymentWithFileId[]> = {};
    ObjectKeys(data.massPayments).forEach((productId) => {
      massPayments[data.productId] = [...data.massPayments[productId]];
    });

    const apiKeys: Record<UUID4, IApiKey[]> = {};
    ObjectKeys(data.apiKeys).forEach((productId) => {
      apiKeys[data.productId] = [...data.apiKeys[productId]];
    });

    const cards: Record<UUID4, ICardExt[]> = {};
    ObjectKeys(data.cards).forEach((productId) => {
      cards[data.productId] = data.cards[productId].map((c) => ({
        ...c,
        currency_code: currency,
      }));
    });

    const topUpHistory: Record<UUID4, TopUpHistoryItem[]> = {};
    ObjectKeys(data.topUpHistory).forEach((productId) => {
      topUpHistory[data.productId] = data.topUpHistory[productId].map((c) => ({
        ...c,
      }));
    });

    return {
      ...data,
      user: {
        ...data.user,
        profiles,
        roles: {global: [...data.user.roles.global], product: userProductRoles},
      },
      productSettings: {...data.productSettings, currency_code: currency},
      reservedSettlementBalance: {
        ...data.reservedSettlementBalance,
        currency_code: currency,
      },
      accounts,
      pricingPlan,
      statements: data.statements.map((s) => ({
        ...s,
        transaction_currency_code: currency,
        account_currency_code: currency,
      })),
      cards,
      employees,
      massPaymentsFiles,
      massPayments,
      apiKeys,
      topUpHistory,
    };
  }

  private static getDemoData(): DemoData {
    let savedDemoData = Demo.loadDemoDataFromStorage();
    const initDemoData = DemoInitData;
    if (savedDemoData.version !== initDemoData.version) {
      savedDemoData = {};
      Demo.clearDemoData();
    }

    return Demo.applyProductToData({
      h: savedDemoData.h,
      version: savedDemoData.version || initDemoData.version,
      productId: savedDemoData.productId || initDemoData.productId,
      user: savedDemoData.user || initDemoData.user,
      defaultProfilePermissions: initDemoData.defaultProfilePermissions,
      tokenInfo: {
        ...(savedDemoData.tokenInfo || initDemoData.tokenInfo),
        sessionStorage: {},
      },
      currencyRequestConfig: initDemoData.currencyRequestConfig,
      assignedProduct:
        savedDemoData.assignedProduct || initDemoData.assignedProduct,
      serverStorage: savedDemoData.serverStorage || initDemoData.serverStorage,
      allowedEndpoints:
        savedDemoData.allowedEndpoints || initDemoData.allowedEndpoints,
      clients: savedDemoData.clients || initDemoData.clients,
      productSettings:
        savedDemoData.productSettings || initDemoData.productSettings,
      reservedSettlementBalance:
        savedDemoData.reservedSettlementBalance ||
        initDemoData.reservedSettlementBalance,
      settlementPaymentDetails:
        savedDemoData.settlementPaymentDetails ||
        initDemoData.settlementPaymentDetails,
      accounts: savedDemoData.accounts || initDemoData.accounts,
      pricingPlans: savedDemoData.pricingPlans || initDemoData.pricingPlans,
      pricingPlan: savedDemoData.pricingPlan || initDemoData.pricingPlan,
      cardDispatchMethods:
        savedDemoData.cardDispatchMethods || initDemoData.cardDispatchMethods,
      companies: savedDemoData.companies || initDemoData.companies,
      corporateDocuments:
        savedDemoData.corporateDocuments || initDemoData.corporateDocuments,
      companyLimitsUsage:
        savedDemoData.companyLimitsUsage || initDemoData.companyLimitsUsage,
      verificationPersonsAndCompanies:
        initDemoData.verificationPersonsAndCompanies,
      productUsers: savedDemoData.productUsers || initDemoData.productUsers,
      roles: initDemoData.roles,
      accountLimitsUsage: initDemoData.accountLimitsUsage,
      statements: Demo.formatStatements(
        savedDemoData.statements || initDemoData.statements
      ),
      cards: savedDemoData.cards || initDemoData.cards,
      employees: savedDemoData.employees || initDemoData.employees,
      paymentDocuments:
        savedDemoData.paymentDocuments || initDemoData.paymentDocuments,
      massPaymentsFiles:
        savedDemoData.massPaymentsFiles || initDemoData.massPaymentsFiles,
      massPayments: savedDemoData.massPayments || initDemoData.massPayments,
      apiKeys: savedDemoData.apiKeys || initDemoData.apiKeys,
      topUpHistory: savedDemoData.topUpHistory || initDemoData.topUpHistory,
    });
  }

  public static getInstance(): Demo {
    if (!Demo.instance) {
      Demo.instance = new Demo();
    }
    return Demo.instance;
  }

  constructor() {
    this.init();
  }

  public makeDemoResponse(
    config: InternalAxiosRequestConfig
  ): RequestDemoError {
    const demoXHRError = new RequestDemoError('demo');
    demoXHRError.isDemo = true;
    demoXHRError.request = config;

    const {isProd} = App$.getState().config;
    const {wUrl} = config;
    if (wUrl && wUrl.endpoint && this._demoData) {
      const apiHandler = Demo.apis[wUrl.endpoint];
      if (apiHandler) {
        demoXHRError.response = apiHandler({
          config,
          data: config.data,
          instance: this,
          url: wUrl,
        });
        if (!isProd) {
          logDemo.info(
            `Send demo request: ${config.wUrl?.endpoint}${
              config.wUrl?.queryParams
                ? `?${queryString.stringify(config.wUrl?.queryParams)}`
                : ''
            }`,
            {
              request: {
                post: config.data,
                query: config.wUrl?.queryParams,
                params: config.wUrl?.params,
              },
              response: demoXHRError.response,
            }
          );
        }
      } else {
        if (!isProd) {
          logDemo.warn(
            `Send demo request fail(not implemented): ${config.wUrl?.endpoint}`,
            {
              request: {
                post: config.data,
                query: config.wUrl?.queryParams,
                params: config.wUrl?.params,
              },
              response: demoXHRError.response,
            }
          );
        }
        demoXHRError.response = Demo.makeNotImplementedResponse(config);
      }
    }

    return demoXHRError;
  }

  private init() {
    this._demoData = Demo.getDemoData();
    const qs = queryString.parse(window.location.search);
    if (typeof qs.h === 'string') {
      this._demoData.h = {value: qs.h, ttl: moment().add(60, 'day').unix()};
      this.saveImpl(['h']);
    }
  }

  public getRegistrationLink(showRegistrationForm: boolean): string {
    if (showRegistrationForm) {
      return getRoutePath(routes.demoSignUp);
    }
    if (this._demoData?.h && this._demoData?.h.ttl >= moment().unix()) {
      return queryString.stringifyUrl({
        url: systemConfig.registrationLink,
        query: {
          h: this._demoData?.h.value,
          origin: 'demo',
        },
      });
    }
    return systemConfig.registrationCommonLink;
  }

  public saveImpl(keys: (keyof DemoData)[]): boolean {
    if (this._demoData) {
      const toSave: Record<string, unknown> = Demo.loadDemoDataFromStorage();
      ObjectKeys(this._demoData).forEach((key) => {
        if (this._demoData && keys.includes(key) && this._demoData[key]) {
          toSave[key] = this._demoData[key];
        }
      });

      toSave.version = this._demoData.version;

      const encodeData = JSON.stringify(toSave);
      logDemo.info(`Save local data [${encodeData.length}]`);
      localStorageWrapper.setItem(DEMO_STORAGE_KEY, encodeData);
    }
    return false;
  }

  public getCurrentCurrency(): CurrencyType {
    const {productId} = this.getData();
    const product = this.getData().assignedProduct.find(
      (ap) => ap.id === productId
    );
    if (product) {
      return product.currency_code || 'EUR';
    }
    return 'EUR';
  }

  public getData(): DemoData {
    if (this._demoData) {
      return this._demoData;
    }
    throw new Error('not loaded');
  }

  public getTokenInfo(): TokenInfo {
    return {
      token: this._demoData?.tokenInfo.token || '',
      refresh_token_expire_ts: moment().add(30, 'minute').unix(),
      sessionStorage: this._demoData?.tokenInfo.sessionStorage || {},
      refresh_token: this._demoData?.tokenInfo.refresh_token || '',
      product_id: this._demoData?.tokenInfo.product_id,
      profile_id: this._demoData?.tokenInfo.profile_id,
    };
  }

  public setTokenInfo(tokenInfo: TokenInfo): boolean {
    if (this._demoData) {
      this._demoData.tokenInfo = {
        ...this._demoData.tokenInfo,
        ...tokenInfo,
      };
      return this.saveImpl(['tokenInfo']);
    }

    return false;
  }

  public updateServerStorageValue(
    serverStorageData: ServerStorageData
  ): boolean {
    if (this._demoData) {
      this._demoData.serverStorage = {
        ...this._demoData.serverStorage,
        ...serverStorageData,
      };
      return this.saveImpl(['serverStorage']);
    }

    return false;
  }

  public updateCurrentUser(params: IUserUpdateSelfProfileParams): boolean {
    if (this._demoData) {
      this._demoData.user = {...this._demoData.user, ...params};
      return this.saveImpl(['user']);
    }
    return false;
  }

  public assignProduct(params: Record<string, unknown>) {
    if (params && params.profile_id && this._demoData?.user) {
      const profile = this._demoData?.user.profiles.find(
        (p) => p.id === params.profile_id
      );
      if (profile) {
        this._demoData.productId = profile.target_entity_id;
        this._demoData = Demo.applyProductToData(this._demoData);
        this.saveImpl(['productId']);
      }
    }
  }
}

export default Demo;
