import {createEffect, createEvent, createStore} from 'effector';
import {signAgreement, getAgreement, getCurrentClient} from '../../api';
import AuthService from '../../services/AuthService';
import {IClient, LanguageCode2l, languageCode2lTo3l} from '../../types';
import {softClearAppStores} from '../clearStore';
import {CurrentLocaleStore} from '../i18n';
import {base64ToString} from '../../utils/blobs';

export interface AcceptAgreementProps {
  client?: IClient;
  agreement?: string;
}

export interface AcceptAgreementState extends AcceptAgreementProps {
  loading: boolean;
  client?: IClient;
  agreement?: string;
}

const AcceptAgreementStore = createStore<AcceptAgreementState>({
  loading: true,
});

export const resetAcceptAgreement = createEvent('resetAcceptAgreement');

export const initAcceptAgreementFx = createEffect<
  void,
  AcceptAgreementProps,
  Error
>({
  handler: async () => {
    const {locale} = CurrentLocaleStore.getState();

    return {
      client: await getCurrentClient(),
      agreement: base64ToString(
        (
          await getAgreement({
            format: 'html',
            languageCode:
              languageCode2lTo3l[locale.toUpperCase() as LanguageCode2l],
          })
        ).data.agreement
      ),
    };
  },
});

export const signAgreementFx = createEffect<void, IClient, Error>({
  handler: async () => {
    const {client: newClient, user} = await signAgreement();
    if (newClient.agreement_signed_at) {
      await AuthService.getInstance().updateAuthInfo(user, newClient);
    }
    return newClient;
  },
});

AcceptAgreementStore.reset(resetAcceptAgreement, softClearAppStores);

AcceptAgreementStore.on(signAgreementFx, (state) => {
  return {
    ...state,
    loading: true,
  };
});

AcceptAgreementStore.on(initAcceptAgreementFx, (state) => {
  return {
    ...state,
    loading: true,
  };
});

AcceptAgreementStore.on(signAgreementFx.finally, (state) => {
  return {
    ...state,
    loading: false,
  };
});

AcceptAgreementStore.on(initAcceptAgreementFx.finally, (state) => {
  return {
    ...state,
    loading: false,
  };
});

AcceptAgreementStore.on(initAcceptAgreementFx.failData, (state) => {
  return {
    ...state,
    loading: false,
  };
});

AcceptAgreementStore.on(
  initAcceptAgreementFx.doneData,
  (state, {client, agreement}) => {
    return {
      ...state,
      loading: false,
      client,
      agreement,
    };
  }
);

export default AcceptAgreementStore;
