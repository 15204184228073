import clsx from 'clsx';
import {Modal} from 'antd';
import React, {useCallback, useState, useMemo} from 'react';
import {IntlShape} from 'react-intl';
import Button from '../Button';
import {modalWidth} from '../../constants/styles';
import useMedias from '../../customHooks/useMedias';
import {ReactComponent as IconClose} from '../../images/icons/close.svg';
import gcss from '../../utils/styles';
import css from './ModalExt.module.less';

interface ActionProps {
  content?: React.ReactNode;
  props?: React.HTMLAttributes<HTMLElement>;
}

export interface ModalExtProps {
  title?: React.ReactNode;
  content?: React.ReactNode;

  submit?: React.ReactNode | ActionProps | false;
  cancel?: React.ReactNode | ActionProps | false;

  onSubmit?: () => Promise<unknown>;
  onCancel?: () => void;
  afterClose?: () => void;
  onClose?: (status: boolean) => void;
}

const ModalExt: React.FC<
  React.PropsWithChildren<ModalExtProps & {intl: IntlShape}>
> = ({
  title,
  content,

  submit,
  cancel,

  onSubmit,
  onCancel,
  onClose,
  afterClose,

  intl,
  children,
}) => {
  const media = useMedias();
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    if (onSubmit) {
      await onSubmit();
    }

    setIsLoading(false);

    if (onClose) {
      onClose(true);
    }

    setIsVisible(false);
  }, [onClose, onSubmit]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      onCancel();
    }
    if (onClose) {
      onClose(false);
    }
    setIsVisible(false);
  }, [onCancel, onClose]);

  const submitAction = useMemo(() => {
    if (submit || typeof submit === 'undefined') {
      if (React.isValidElement(submit)) {
        return React.cloneElement(submit, {
          onClick: async (e: MouseEvent) => {
            if (submit.props.onClick) {
              await submit.props.onClick(e);
            }

            await handleSubmit();
          },
          loading: isLoading,
        });
      }

      let actionTitle;
      let actionProps: React.HTMLAttributes<HTMLElement> | undefined;

      if (typeof submit === 'object') {
        if ('content' in submit) {
          actionTitle = submit.content;
        }

        if ('props' in submit) {
          actionProps = submit.props;
        }
      }

      return (
        <Button
          type="primary"
          className={clsx(
            'ant-btn_green-emerald ant-btn_middle-extra',
            gcss('t_font-weight_700'),
            gcss('t_transform_uppercase')
          )}
          {...actionProps}
          onClick={(e) => {
            if (actionProps?.onClick) {
              actionProps?.onClick(e);
            }

            handleSubmit();
          }}
          loading={isLoading}
        >
          {typeof actionTitle !== 'undefined'
            ? actionTitle
            : intl.formatMessage({id: 'App-Confirm'})}
        </Button>
      );
    }

    return null;
  }, [handleSubmit, intl, isLoading, submit]);

  const cancelAction = useMemo(() => {
    if (cancel || typeof cancel === 'undefined') {
      if (React.isValidElement(cancel)) {
        return React.cloneElement(cancel, {
          onClick: async (e: MouseEvent) => {
            if (cancel.props.onClick) {
              await cancel.props.onClick(e);
            }

            await handleCancel();
          },
          loading: isLoading,
        });
      }

      let actionTitle;
      let actionProps: React.HTMLAttributes<HTMLElement> | undefined;

      if (typeof cancel === 'object') {
        if ('content' in cancel) {
          actionTitle = cancel.content;
        }

        if ('props' in cancel) {
          actionProps = cancel.props;
        }
      }

      return (
        <Button
          className={clsx(
            'ant-btn_default-blue ant-btn_transparent ant-btn_middle-extra',
            gcss('t_font-weight_700'),
            gcss('t_transform_uppercase')
          )}
          {...actionProps}
          onClick={(e) => {
            if (actionProps?.onClick) {
              actionProps?.onClick(e);
            }

            handleCancel();
          }}
          loading={isLoading}
        >
          {typeof actionTitle !== 'undefined'
            ? actionTitle
            : intl.formatMessage({id: 'App-Cancel'})}
        </Button>
      );
    }

    return null;
  }, [cancel, handleCancel, intl, isLoading]);

  return (
    <Modal
      className={clsx(
        'ant-modal_sticky ant-modal_titless-with-close',
        css.modal
      )}
      title={title}
      onCancel={handleCancel}
      centered={!media.xs}
      width={modalWidth}
      closeIcon={<IconClose />}
      footer={false}
      maskClosable={false}
      visible={isVisible}
      afterClose={afterClose}
      destroyOnClose
    >
      {content || children}

      {(submitAction || cancelAction) && (
        <div
          className={clsx(
            css.actions,
            !submitAction || (!cancelAction && css.actions_defective)
          )}
        >
          {cancelAction}

          {submitAction}
        </div>
      )}
    </Modal>
  );
};

export default ModalExt;
