import {useStoreMap} from 'effector-react';
import React, {useCallback, useEffect, Suspense, useMemo, lazy} from 'react';
import {matchPath, useLocation} from 'react-router';
import componentLoader from '../../utils/componentLoader';
import Loader from '../../components/Loader';
import routes from '../../constants/routes';
import useIsGuest from '../../customHooks/useIsGuest';
import usePageClass from '../../customHooks/usePageClass';
import {useProduct} from '../../customHooks/useProduct';
import {HeaderStore} from '../../models/header';
import Layout$, {
  menuContentSizeChangeEvent,
  menuScrollChangeEvent,
  scrollChangeEvent,
  setLayoutMenuScrollElement,
  setLayoutScrollElement,
  setLayoutModification,
} from '../../models/layout';
import {
  LayoutScrollAreaListener,
  MenuScrollListener,
  RouteInfo,
  ScrollListener,
} from '../../types';
import {getCleanUrl} from '../../utils/language';
import WBNewFeatures from '../WBNewFeatures';
import Messages from '../Messages';
import useIsWhiteLabel from '../../customHooks/useIsWhiteLabel';

const LayoutDashboard = lazy(() =>
  componentLoader(
    () => import('../LayoutDashboard' /* webpackChunkName: "LayoutDashboard" */)
  )
);

const LayoutDefault = lazy(() =>
  componentLoader(
    () => import('../LayoutDefault' /* webpackChunkName: "LayoutDefault" */)
  )
);

const LayoutNarrow = lazy(() =>
  componentLoader(
    () => import('../LayoutNarrow' /* webpackChunkName: "LayoutNarrow" */)
  )
);

const LayoutSimple = lazy(() =>
  componentLoader(
    () => import('../LayoutSimple' /* webpackChunkName: "LayoutSimple" */)
  )
);

const LayoutClear = lazy(() =>
  componentLoader(
    () => import('../LayoutClear' /* webpackChunkName: "LayoutClear" */)
  )
);

const FlagsSprite = lazy(
  () => import('../FlagsSprite' /* webpackChunkName: "FlagsSprite" */)
);

interface Props {
  children: React.ReactNode;
}

const LayoutController: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const pageClass = usePageClass();
  const isWhiteLabel = useIsWhiteLabel();
  const isGuest = useIsGuest();
  const headerHeight = useStoreMap({
    store: HeaderStore,
    keys: ['height'],
    fn: (state) => state.height,
  });
  const [useFlags] = useStoreMap({
    store: Layout$,
    keys: ['useFlags'],
    fn: (state) => [state.useFlags],
  });
  const product = useProduct();
  const loc = useLocation();

  const narrowLayoutRoutes: Record<string, RouteInfo> = useMemo(() => {
    return {
      [routes.createCard.path]: routes.createCard,
      [routes.demoSignUp.path]: routes.demoSignUp,
      [routes.createCompany.path]: routes.createCompany,
      [routes.fraudCaseDetailEdit.path]: routes.fraudCaseDetailEdit,
      [routes.createCardCompany.path]: routes.createCardCompany,
      [routes.createAccountCompany.path]: routes.createAccountCompany,
      [routes.addCompanyRepresentative.path]: routes.addCompanyRepresentative,
      [routes.createAccount.path]: routes.createAccount,
      [routes.createCardholder.path]: routes.createCardholder,
      [routes.orderPromoCards.path]: routes.orderPromoCards,
      [routes.createRole.path]: routes.createRole,
      [routes.createAuthorization.path]: routes.createAuthorization,
      [routes.createCardDetailTransactions.path]:
        routes.createCardDetailTransactions,
      [routes.currencyRequest.path]: routes.currencyRequest,
      [routes.companyInfoVerification.path]: routes.companyInfoVerification,
      [routes.corporateDocumentsVerification.path]:
        routes.corporateDocumentsVerification,
      // [routes.boardMembersVerification.path]: routes.boardMembersVerification,
      // [routes.boardMemberAdd.path]: routes.boardMemberAdd,
      // [routes.boardMemberEdit.path]: routes.boardMemberEdit,
      // [routes.shareHoldersVerification.path]: routes.shareHoldersVerification,
      // [routes.shareHolderAdd.path]: routes.shareHolderAdd,
      // [routes.shareHolderEdit.path]: routes.shareHolderEdit,
      [routes.identityVerification.path]: routes.identityVerification,
      [routes.identityVerificationCreatePerson.path]:
        routes.identityVerificationCreatePerson,
      [routes.createCardTypes.path]: routes.createCardTypes,
      [routes.createExpensesCard.path]: routes.createExpensesCard,
      [routes.acceptAgreement.path]: routes.acceptAgreement,
      [routes.blackAddUser.path]: routes.blackAddUser,
      [routes.createEmployeesCard.path]: routes.createEmployeesCard,
      [routes.updateEmployeesCard.path]: routes.updateEmployeesCard,
      [routes.orderPackage.path]: routes.orderPackage,
      [routes.pricingPlanList.path]: routes.pricingPlanList,
      [routes.wbCreateApiKeyIntroduction.path]:
        routes.wbCreateApiKeyIntroduction,
      [routes.wbCreateApiKeyCreation.path]: routes.wbCreateApiKeyCreation,
      [routes.wbCreateApiKeyGeneration.path]: routes.wbCreateApiKeyGeneration,

      ...(!isWhiteLabel ? {[routes.mainTopUp.path]: routes.mainTopUp} : {}),
    };
  }, [isWhiteLabel]);

  const simpleLayoutRoutes: Record<string, RouteInfo> = useMemo(() => {
    return {
      [routes.notFound.path]: routes.notFound,
      [routes.forbidden.path]: routes.forbidden,
    };
  }, []);

  const defaultLayoutRoutes: Record<string, RouteInfo> = useMemo(() => {
    return {
      [routes.signIn.path]: routes.signIn,
      [routes.signUp.path]: routes.signUp,
    };
  }, []);

  const handleScroll = useCallback<ScrollListener>(
    (position: number, lockScroll?: number | null) => {
      if (typeof lockScroll === 'number') {
        return lockScroll;
      }
      scrollChangeEvent({current: position, headerHeight: headerHeight + 24});
      return undefined;
    },
    [headerHeight]
  );

  const handleMenuScroll = useCallback<MenuScrollListener>(
    (scrollPositionX: number, scrollPositionY: number) => {
      menuScrollChangeEvent([scrollPositionX, scrollPositionY]);
      return undefined;
    },
    []
  );

  const handleMenuContentSizeChange = useCallback<
    (width: number, height: number) => void
  >((width: number, height: number) => {
    menuContentSizeChangeEvent([width, height]);
  }, []);

  const handleScrollElementListener = useCallback<LayoutScrollAreaListener>(
    (el) => {
      setLayoutScrollElement(el);
    },
    []
  );

  const handleMenuScrollElementListener = useCallback<LayoutScrollAreaListener>(
    (el) => {
      setLayoutMenuScrollElement(el);
    },
    []
  );

  const Layout = useMemo(() => {
    const locPathName = getCleanUrl(loc.pathname);
    let LayoutForSelect;

    if (isGuest) {
      LayoutForSelect = LayoutDefault;
    } else {
      LayoutForSelect = LayoutDashboard;
    }

    Object.keys(defaultLayoutRoutes).forEach((route) => {
      if (
        matchPath(locPathName, {
          path: getCleanUrl(route),
          exact: true,
        })
      ) {
        LayoutForSelect = LayoutDefault;
      }
    });

    Object.keys(narrowLayoutRoutes).forEach((route) => {
      if (
        matchPath(locPathName, {
          path: getCleanUrl(route),
          exact: true,
        })
      ) {
        LayoutForSelect = LayoutNarrow;
      }
    });

    Object.keys(simpleLayoutRoutes).forEach((route) => {
      if (
        matchPath(locPathName, {
          path: getCleanUrl(route),
          exact: true,
        })
      ) {
        LayoutForSelect = LayoutSimple;
      }
    });

    return LayoutForSelect;
  }, [
    loc.pathname,
    isGuest,
    defaultLayoutRoutes,
    narrowLayoutRoutes,
    simpleLayoutRoutes,
  ]);

  useEffect(() => {
    if (Layout === LayoutSimple) {
      setLayoutModification('simple');
    }

    if (Layout === LayoutNarrow) {
      setLayoutModification('narrow');
    }

    if (Layout === LayoutDefault) {
      setLayoutModification('default');
    }

    if (Layout === LayoutDashboard) {
      setLayoutModification('dashboard');
    }

    if (Layout === LayoutClear) {
      setLayoutModification('clear');
    }
  }, [Layout]);

  return (
    <>
      {useFlags && (
        <Suspense fallback={<div />}>
          <FlagsSprite />
        </Suspense>
      )}

      <Suspense fallback={<Loader size="large" transparent={false} />}>
        <Layout
          className={pageClass || ''}
          onScroll={handleScroll}
          onMenuScroll={handleMenuScroll}
          onMenuContentSizeChange={handleMenuContentSizeChange}
          product={product}
          onScrollElementListener={handleScrollElementListener}
          onMenuScrollElementListener={handleMenuScrollElementListener}
          NewFeatures={WBNewFeatures}
        >
          <Suspense fallback={<Loader size="large" transparent={false} />}>
            {children}
          </Suspense>
        </Layout>
      </Suspense>

      <Messages />
    </>
  );
};

export default LayoutController;
