import Demo, {DemoApiHandler} from '../demo';

const confirmMassPaymentsFile: DemoApiHandler = ({config, instance, url}) => {
  const items = instance.getData().massPaymentsFiles[
    instance.getData().productId
  ];

  const payments = instance
    .getData()
    .massPayments[instance.getData().productId].filter(
      (a) => a.file_id === url?.params?.payment_file_id
    );

  const currentItem = items.find(
    (item) => item.id === url?.params?.payment_file_id
  );

  if (currentItem) {
    const index = items.indexOf(currentItem);

    if (index !== -1) {
      payments.forEach((__, i) => {
        payments[i].status = 'Pending';
        currentItem.status = 'Pending';
      });

      setTimeout(() => {
        payments.forEach((_, idx) => {
          switch (idx) {
            case 0:
              payments[0].status = 'Failed';
              break;
            case 1:
              payments[1].status = 'Completed';
              break;
            default:
              payments[idx].status = 'Rejected';
              payments[idx].reject_reason = 'Other';
              break;
          }
        });

        currentItem.status = 'Review';
      }, 5000);

      instance.saveImpl(['massPayments', 'massPaymentsFiles']);
      return Demo.makeDataResponse<string>(config, 'ok');
    }
  }

  return Demo.makeUnprocessableEntityResponse(config, 90559);
};

export default confirmMassPaymentsFile;
