import moment from 'moment-timezone';
import {
  AccountStatementSubType,
  EAccountStatementTypes,
  IAccountStatement,
  MerchantFilterType,
  StatementStatus,
} from '../../types';
import Demo, {DemoApiHandler} from '../demo';
import MerchantTypeCategory from '../../constants/merchants';
import {MerchantCategoriesType} from '../../constants/authorization';

const getAccountStatement: DemoApiHandler = ({config, instance, url}) => {
  const params = url.queryParams as {
    from_record: string;
    records_count: string;
    from_date: string;
    to_date: string;
    statuses?: StatementStatus[];
    merchant_category_types?: MerchantFilterType[];
    adjustment_type?: AccountStatementSubType[];
    include_transactions?: boolean;
    include_authorizations?: boolean;
    include_account_adjustments?: boolean;
    include_fees?: boolean;
    exclude_declined_authorizations?: boolean;
    exclude_reversed_authorizations?: boolean;
    exclude_cleared_authorizations?: boolean;
    has_payment_document_files?: boolean;
  };
  let items = instance
    .getData()
    .statements.filter((a) => a.account_id === url?.params?.account_id);
  let merchantCodes: MerchantCategoriesType[] = [];
  const statuses: StatementStatus[] = params.statuses || [];
  const adjustmentTypes: AccountStatementSubType[] =
    params.adjustment_type || [];
  let pendingAmount = 0;
  let debitTurnover = 0;
  let creditTurnover = 0;

  if (params?.merchant_category_types) {
    params.merchant_category_types.forEach((merchantName) => {
      merchantCodes = [
        ...merchantCodes,
        ...MerchantTypeCategory[merchantName].categories,
      ];
    });
  }

  items = items.filter((item) => {
    return !!(
      moment(item?.date) > moment(params?.from_date) &&
      moment(item?.date) < moment(params?.to_date) &&
      (!merchantCodes.length ||
        (item?.merchant_category_code &&
          merchantCodes.includes(item?.merchant_category_code))) &&
      (!statuses.length || statuses.includes(item.status)) &&
      (!params?.exclude_reversed_authorizations ||
        !item.is_reversal ||
        item.type !== 'Authorization') &&
      (!params?.exclude_declined_authorizations ||
        !item.is_declined ||
        item.type !== 'Authorization') &&
      (!params?.exclude_cleared_authorizations ||
        !item.is_cleared ||
        item.type !== 'Authorization') &&
      (params.has_payment_document_files === undefined ||
        item.has_payment_document_files === params.has_payment_document_files ||
        item.type === EAccountStatementTypes.Fee) &&
      (item.type !== EAccountStatementTypes.Fee || params?.include_fees) &&
      (item.type !== EAccountStatementTypes.Authorization ||
        params?.include_authorizations) &&
      (item.type !== EAccountStatementTypes.Transaction ||
        params?.include_transactions) &&
      (item.type !== EAccountStatementTypes.AccountAdjustment ||
        (params?.include_account_adjustments &&
          (!adjustmentTypes.length ||
            adjustmentTypes.includes(
              item.sub_type as AccountStatementSubType
            ))))
    );
  });

  const sorted = Demo.applySorterAndPager(
    items,
    Demo.toListSortOrderParams(url.queryParams)
  );

  items.forEach((item) => {
    if (item.status === 'Pending') {
      pendingAmount += item?.account_amount || 0;
    }

    if (item.status === 'Completed') {
      if (item.account_amount > 0) {
        creditTurnover += item.account_amount;
      } else {
        debitTurnover += item.account_amount;
      }
    }
  });

  return Demo.makeDataResponse<{
    records: IAccountStatement[];
    total_records_number: number;
    pending_amount?: number;
    debit_turnover?: number;
    credit_turnover?: number;
  }>(config, {
    records: sorted,
    total_records_number: items.length,
    pending_amount: pendingAmount,
    debit_turnover: debitTurnover,
    credit_turnover: creditTurnover,
  });
};

export default getAccountStatement;
