import {createEffect, createEvent, createStore} from 'effector';
import {getProductSettings} from '../../api';
import getAccount from '../../api/account';
import {AccountLimitsSettings, IAccount, IAccounts, UUID4} from '../../types';
import {attachErrorHandler} from '../../utils/error';
import {softClearAppStores} from '../clearStore';

export const AccountStore = createStore<IAccounts>({});

AccountStore.reset(softClearAppStores);

export const getAccountFx = createEffect<UUID4, IAccount, Error>({
  handler: async (accountId) => {
    return getAccount(accountId);
  },
});

AccountStore.on(getAccountFx.doneData, (State, account) => {
  return {
    ...State,
    [account.id]: account,
  };
});

attachErrorHandler([getAccountFx]);

interface IAccountLimitsSettingsStore extends AccountLimitsSettings {
  loading?: boolean;
  loaded?: boolean;
}

export const defaultAccountLimits = {
  credit_limit_max_amount: 0,
  default_account_daily_contactless_purchase_limit: 15000,
  default_account_daily_internet_purchase_limit: 15000,
  default_account_daily_purchase_limit: 15000,
  default_account_daily_withdrawal_limit: 15000,
  default_account_monthly_contactless_purchase_limit: 15000,
  default_account_monthly_internet_purchase_limit: 15000,
  default_account_monthly_purchase_limit: 15000,
  default_account_monthly_withdrawal_limit: 15000,
  max_account_daily_contactless_purchase_limit: 15000,
  max_account_daily_internet_purchase_limit: 15000,
  max_account_daily_purchase_limit: 15000,
  max_account_daily_withdrawal_limit: 15000,
  max_account_monthly_contactless_purchase_limit: 15000,
  max_account_monthly_internet_purchase_limit: 15000,
  max_account_monthly_purchase_limit: 15000,
  max_account_monthly_withdrawal_limit: 15000,
  program_type: 'Credit',
} as IAccountLimitsSettingsStore;

export const AccountLimitsSettingsStore = createStore<IAccountLimitsSettingsStore>(
  defaultAccountLimits
);

AccountLimitsSettingsStore.reset(softClearAppStores);

export const getAccountLimitsSettingsFx = createEffect<
  void,
  IAccountLimitsSettingsStore,
  Error
>({
  handler: async () => {
    return getProductSettings();
  },
});

AccountLimitsSettingsStore.on(getAccountLimitsSettingsFx, (store) => {
  return {
    ...store,
    loading: true,
  };
});

AccountLimitsSettingsStore.on(
  getAccountLimitsSettingsFx.doneData,
  (store, limits) => {
    return {
      ...store,
      ...limits,
    };
  }
);

AccountLimitsSettingsStore.on(getAccountLimitsSettingsFx.finally, (store) => {
  return {
    ...store,
    loading: false,
    loaded: true,
  };
});

export const resetAccountLimitsSettingsStore = createEvent(
  'resetAccountLimitsSettingsStore'
);

AccountLimitsSettingsStore.reset(resetAccountLimitsSettingsStore);
