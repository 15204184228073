import Demo, {DemoApiHandler} from '../demo';

const discardMassPaymentsFile: DemoApiHandler = ({config, instance, url}) => {
  const items = instance.getData().massPaymentsFiles[
    instance.getData().productId
  ];

  const payments = instance.getData().massPayments[
    instance.getData().productId
  ];

  const currentItem = items.find(
    (item) => item.id === url?.params?.payment_file_id
  );

  if (currentItem) {
    const index = items.indexOf(currentItem);

    if (index !== -1) {
      currentItem.status = 'Completed';

      payments.forEach((__, i) => {
        payments[0].status = 'Failed';
        payments[i].status = 'Rejected';
        payments[i].reject_reason = 'Other';
      });

      instance.saveImpl(['massPayments', 'massPaymentsFiles']);

      return Demo.makeDataResponse<string>(config, 'ok');
    }
  }

  return Demo.makeUnprocessableEntityResponse(config, 90559);
};

export default discardMassPaymentsFile;
