import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import {
  AgreementFormat,
  ClientVerificationConfig,
  IAccount,
  IClient,
  LanguageCode3l,
  SystemUser,
  UUID4,
  CorporateDocument,
  CorporateDocumentListItem,
  CorporateDocumentType,
  CompanyInformationVerificationParams,
  CompanyInformationVerificationErrors,
  RequestWithSortsOrders,
  IClientAccountRequestParams,
  IClientAccounts,
  CountryCodes3l,
  TClientSupportManagersResponse,
  TClientTicket,
  IClientGetTicketsRequestParams,
  IClientCreateTicketRequestParams,
  TTopUpTicket,
  ITopUpCreateTicketRequestParams,
  ITopUpGetTicketsRequestParams,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {toListApiRequest} from '../utils/listRequest';
import {getApiPathParamsMaker} from './endpoints';
import {TSupportManagers} from '../types/supportManagers';

export const getVerificationConfig = async (): Promise<ClientVerificationConfig> => {
  const response: AxiosResponse<ClientVerificationConfig> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getVerificationConfig), {
      noCache: true,
    });
  return {
    ...response.data,
  };
};

export const getCommunicationPreferredLanguageCodes = async (): Promise<
  LanguageCode3l[]
> => {
  const response: AxiosResponse<{
    language_codes: LanguageCode3l[];
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getCommunicationLanguageCodes));
  return response.data.language_codes;
};

export const getCurrentClient = async (
  noCache?: boolean,
  doNotExtendSession?: boolean
): Promise<IClient> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(Endpoints.getCurrentClient), {
      cacheTags: noCache === true ? {_: `${+new Date()}`} : {},
      doNotExtendSession,
    });
  return response.data.client;
};

export const verifyCompanyInfo = async (
  params: CompanyInformationVerificationParams
): Promise<IClient> => {
  const response: AxiosResponse<{
    client: IClient;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .put(getApiPathParamsMaker(Endpoints.updateCompanyInformation), {
      ...params,
      business_card_account_agreement: true,
      fee_info_agreement: true,
    });

  return response.data.client;
};

export const updateClientCompanyInfo = async (params: {
  registration_country_code: CountryCodes3l;
}): Promise<IClient> => {
  const response: AxiosResponse<{
    client: IClient;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .patch(getApiPathParamsMaker(Endpoints.updateClientCompanyInformation), {
      ...params,
    });

  return response.data.client;
};

export const getAgreement = async ({
  format,
  languageCode,
}: {
  format: AgreementFormat;
  languageCode: LanguageCode3l;
}): Promise<AxiosResponse> => {
  return WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getAgreement), {
      params: {
        format,
        language_code: languageCode,
      },
    });
};

export const signAgreement = async (): Promise<{
  client: IClient;
  user: SystemUser;
}> => {
  const response: AxiosResponse<{
    client: IClient;
    user: SystemUser;
    account: IAccount;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.signAgreement));

  return response.data;
};

export const getCompanyInfoErrors = async (): Promise<CompanyInformationVerificationErrors> => {
  const response: AxiosResponse<{
    client: CompanyInformationVerificationErrors;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getCompanyInformationErrors));

  return response.data.client || {};
};

export const getCorporateDocuments = async (): Promise<CorporateDocument[]> => {
  const response: AxiosResponse<{
    corporate_documents: CorporateDocument[];
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getCorporateDocuments));

  return response.data.corporate_documents || [];
};

export const confirmCompanyDocuments = async (): Promise<boolean> => {
  const response: AxiosResponse<
    'ok' | 'err'
  > = await WallesterAxios.getInstance()
    .getBlackAxios()
    .patch(getApiPathParamsMaker(Endpoints.confirmCompanyDocuments));

  return response.data === 'ok';
};

export const uploadCompanyDocument = async (
  type: CorporateDocumentType,
  file: File,
  progressCallback: (progress: number) => void,
  abortSignal?: AbortSignal
): Promise<CorporateDocument> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  let maxProgressUploaded = 0;
  const response: AxiosResponse<{
    corporate_document: CorporateDocumentListItem;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.uploadCompanyDocument), formData, {
      signal: abortSignal,
      timeout: 60000,
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0xffffffff)
        );
        if (maxProgressUploaded < progress) {
          maxProgressUploaded = progress;
        }
        progressCallback(Math.min(100, maxProgressUploaded));
      },
    });
  return response.data.corporate_document;
};

export const uploadRequestedCompanyDocument = async (
  id: UUID4,
  file: File,
  progressCallback: (progress: number) => void,
  abortSignal?: AbortSignal
): Promise<CorporateDocument> => {
  const formData = new FormData();
  formData.append('file', file);
  let maxProgressUploaded = 0;
  const response: AxiosResponse<{
    corporate_document: CorporateDocumentListItem;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .put(
      getApiPathParamsMaker(Endpoints.uploadRequestedCompanyDocument, {
        document_id: id,
      }),
      formData,
      {
        signal: abortSignal,
        timeout: 60000,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 0xffffffff)
          );
          if (maxProgressUploaded < progress) {
            maxProgressUploaded = progress;
          }
          progressCallback(Math.min(100, maxProgressUploaded));
        },
      }
    );
  return response.data.corporate_document;
};

export const deleteCompanyDocument = async (
  file: CorporateDocument
): Promise<boolean> => {
  if (file.id.substr(0, 3) === '___') {
    return true;
  }
  const response: AxiosResponse<
    'ok' | 'err'
  > = await WallesterAxios.getInstance()
    .getBlackAxios()
    .delete(
      getApiPathParamsMaker(Endpoints.deleteCompanyDocument, {
        document_id: file.id,
      })
    );

  return response.data === 'ok';
};

export const deleteCompanyDocuments = async (
  files: CorporateDocument[]
): Promise<boolean> => {
  const filteredFiles = files.filter((file) => {
    if (file.id.substr(0, 3) === '___') {
      return false;
    }
    return true;
  });

  await Promise.all(
    filteredFiles.map(async (file) => {
      const response: AxiosResponse<
        'ok' | 'err'
      > = await WallesterAxios.getInstance()
        .getBlackAxios()
        .delete(
          getApiPathParamsMaker(Endpoints.deleteCompanyDocument, {
            document_id: file.id,
          })
        );
      return response.data === 'ok';
    })
  );

  return true;
};

export const getClientAccounts = async (
  params: RequestWithSortsOrders<IClientAccountRequestParams>
): Promise<IClientAccounts> => {
  const response: AxiosResponse<{
    accounts: IAccount[];
    total_records_number: number;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({
      endpoint: Endpoints.getClientAccounts,
      queryParams: toListApiRequest(params, undefined, true),
      stupidArrayFormat: true,
    });

  return {
    accounts: response.data.accounts || [],
    totalItems: response.data.total_records_number,
  };
};

export const getClientSupportManagers = async (): Promise<TSupportManagers> => {
  const response: AxiosResponse<TClientSupportManagersResponse> = await WallesterAxios.getInstance()
    .getCommon()
    .get({
      endpoint: Endpoints.getClientSupportManagers,
    });

  return response.data.support_managers || {};
};

export const getClientTickets = async (
  params: IClientGetTicketsRequestParams
): Promise<TClientTicket[]> => {
  const response: AxiosResponse<{
    benefits_tickets: TClientTicket[];
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({
      endpoint: Endpoints.getClientTickets,
      queryParams: {
        ticket_statuses: params.ticket_statuses as string[],
      },
      stupidArrayFormat: true,
    });

  return response.data.benefits_tickets;
};

export const createClientTicket = async ({
  exclusiveDedicatedBin,
  exclusiveSharedBin,
  dedicatedBinRange,
  sharedBinRange,
  whiteList3DS,
}: IClientCreateTicketRequestParams): Promise<TClientTicket> => {
  const response: AxiosResponse<{
    benefits_ticket: TClientTicket;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(
      {
        endpoint: Endpoints.createClientTicket,
      },
      {
        benefits_ticket: {
          exclusive_dedicated_bin: !!exclusiveDedicatedBin,
          exclusive_shared_bin: !!exclusiveSharedBin,
          dedicated_bin_range: !!dedicatedBinRange,
          shared_bin_range: !!sharedBinRange,
          whitelist_3ds: !!whiteList3DS,
        },
      }
    );

  return response.data.benefits_ticket;
};

export const getTopUpTickets = async (
  params: ITopUpGetTicketsRequestParams
): Promise<TTopUpTicket[]> => {
  const response: AxiosResponse<{
    top_up_tickets: TTopUpTicket[];
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({
      endpoint: Endpoints.getTopUpTickets,
      queryParams: {
        ticket_statuses: params.ticket_statuses as string[],
      },
      stupidArrayFormat: true,
    });

  return response.data.top_up_tickets;
};

export const createTopUpTicket = async ({
  top_up_type,
  minimum_amount,
  maximum_amount,
}: ITopUpCreateTicketRequestParams): Promise<TTopUpTicket> => {
  const response: AxiosResponse<{
    top_up_ticket: TTopUpTicket;
  }> = await WallesterAxios.getInstance().getBlackAxios().post(
    {
      endpoint: Endpoints.createTopUpTicket,
    },
    {
      top_up_ticket: {
        top_up_type,
        minimum_amount,
        maximum_amount,
      },
    }
  );

  return response.data.top_up_ticket;
};

// export const getClientAccounts = ()
