import {DEMO_TOP_UP_ADJUSTMENT_PREVIEW} from '../../constants/demo/topup';
import Demo, {DemoApiHandler} from '../demo';

const getTopUpPreview: DemoApiHandler = ({config}) => {
  return Demo.makeDataResponse<{file: {body: string}}>(config, {
    file: {
      body: DEMO_TOP_UP_ADJUSTMENT_PREVIEW,
    },
  });
};

export default getTopUpPreview;
