import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useStoreMap} from 'effector-react';
import clsx from 'clsx';
import HintsStore, {THintsModals, THintsTargets} from '../../../models/hints';
import HINTS_TYPES from '../../../types/hints';
import css from './HintModal.module.less';
import {TypedMap} from '../../../types';
import {setHintState} from '../../../models/serverStorage';

const getHintsTargetsParams = (targets: THintsTargets | undefined) => {
  if (!targets) {
    return undefined;
  }

  const data: TypedMap<
    HINTS_TYPES,
    {
      width: number;
      height: number;
      top: number;
      left: number;
    }
  > = {};

  (Object.keys(targets) as HINTS_TYPES[]).forEach((id) => {
    const el = targets[id];
    const {offsetWidth: width, offsetHeight: height} = el || {};
    const {top, left} = el?.getBoundingClientRect() || {};

    data[id] = {
      width: width || 0,
      height: height || 0,
      top: top || 0,
      left: left || 0,
    };
  });

  return data;
};

const HintModal: React.FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [modals, hints, targets] = useStoreMap({
    store: HintsStore,
    keys: [
      'activeSingleHint',
      'activeInlineHints',
      'activeRouteHint',
      'modals',
      'targets',
    ],
    fn: (store$) => {
      let activeHintsModals: THintsModals | undefined;
      let activeHintsTargets: THintsTargets | undefined;
      const activeHints = [
        ...(store$.activeInlineHints ? store$.activeInlineHints : []),
        ...(store$.activeSingleHint ? [store$.activeSingleHint] : []),
        ...(store$.activeRouteHint ? [store$.activeRouteHint] : []),
      ];
      const activeHintsIds = activeHints.map((a) => a.id);

      activeHintsIds.forEach((id) => {
        if (store$.modals[id]) {
          if (!activeHintsModals) {
            activeHintsModals = {};
          }

          activeHintsModals[id] = store$.modals[id];
        }

        if (store$.targets[id]) {
          if (!activeHintsTargets) {
            activeHintsTargets = {};
          }

          activeHintsTargets[id] = store$.targets[id];
        }
      });

      return [activeHintsModals, activeHints, activeHintsTargets];
    },
  });
  const [targetsParams, setTargetsParams] = useState(
    getHintsTargetsParams(targets)
  );

  const recalculateTargetsParams = useCallback(() => {
    setTargetsParams(getHintsTargetsParams(targets));
  }, [targets]);

  useEffect(() => {
    window.addEventListener('resize', recalculateTargetsParams);

    const observer = new MutationObserver(recalculateTargetsParams);

    if (observer) {
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener('resize', recalculateTargetsParams);

      if (observer) {
        observer.disconnect();
      }
    };
  }, [recalculateTargetsParams]);

  useEffect(() => {
    setTargetsParams(getHintsTargetsParams(targets));
  }, [targets]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return useMemo(() => {
    if (modals) {
      return (
        <div className={clsx(css.modals, loaded ? css.modals_visible : '')}>
          {(Object.keys(modals) as HINTS_TYPES[]).map((id, a) => {
            const key = `${a}`;
            const modal = modals[id];
            const target = (targets || {})[id];
            const {top, left, width, height} = (targetsParams || {})[id] || {};
            const hint = hints.filter((b) => b.id === id)[0];

            return (
              <div
                className={css.modal}
                style={{
                  top,
                  left,
                  width,
                  height,
                }}
                key={key}
              >
                {modal &&
                  modal({
                    hint,
                    target,
                    close: () => {
                      setHintState({
                        hint: hint.id,
                        state: true,
                      });
                    },
                  })}
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  }, [hints, loaded, modals, targets, targetsParams]);
};

export default HintModal;
