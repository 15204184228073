import clsx from 'clsx';
import React from 'react';
import css from './Loader.module.less';

export interface Props {
  className?: string;
  size?: 'large' | 'small';
  transparent?: boolean;
  stable?: boolean;
  loading?: boolean;
}

const Loader: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  size,
  transparent = true,
  loading = true,
  children,
  stable,
  ...props
}) => {
  return (
    <div
      className={clsx(
        css.loader,
        typeof children !== 'undefined' || stable ? css.loader_stable : '',
        className
      )}
      {...props}
    >
      {children}

      {loading && (
        <div
          className={clsx(
            css.loaderInner,
            size ? css[`loaderInner_${size}`] : '',
            transparent ? css.loaderInner_transparent : ''
          )}
        />
      )}
    </div>
  );
};

export default Loader;
