import {useStoreMap} from 'effector-react';
import Layout$ from '../models/layout';

const usePageClass = (): string | null => {
  return useStoreMap({
    store: Layout$,
    keys: ['pageClass'],
    fn: (store$) => store$.pageClass || null,
  });
};

export default usePageClass;
