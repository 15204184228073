import {
  AuthenticationError,
  AxiosResponseCustom,
  BusinessLogicError,
  InternalError,
  ValidationError,
} from '../types';

interface Props {
  message: string;
  stack?: string;
  response?: AxiosResponseCustom;
}

class RequestError extends Error {
  private readonly response?: AxiosResponseCustom;

  constructor({message, stack = '', response}: Props) {
    super(message);
    this.response = response;
    this.stack = stack;
  }

  getResponse(): AxiosResponseCustom | undefined {
    return this.response;
  }

  getValidationError<T>(): ValidationError<T> | null {
    if (this.response && this.response.status === 400) {
      return this.response.data as ValidationError<T>;
    }
    return null;
  }

  getAuthenticationError(): AuthenticationError | null {
    if (this.response && this.response.status === 401) {
      return this.response.data as AuthenticationError;
    }
    return null;
  }

  getBusinessLogicError(): BusinessLogicError | null {
    if (this.response && this.response.status === 422) {
      return this.response.data as BusinessLogicError;
    }
    return null;
  }

  getInternalError(): InternalError | null {
    if (this.response && this.response.status === 500) {
      return this.response.data as InternalError;
    }
    return null;
  }
}

export default RequestError;
