import clsx from 'clsx';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import EllipsisTooltip from '../EllipsisTooltip';
import css from './Thumb.module.less';
import ucFirst from '../../utils/string';
import useDataTestId from '../../customHooks/useDataTestId';

export type ThumbSize = 'responsive';

export interface PropsThumb {
  className?: string;
  symbol?: string;
  color?: string;
  src?: string;
  srcAnimate?: string;
  title?: string;
  desc?: string | React.ReactNode;
  icon?: React.ReactElement;
  size?: ThumbSize;
  limit?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  hint?: React.ReactNode;
}

const Thumb: React.FC<React.PropsWithChildren<PropsThumb>> = ({
  className,
  symbol = '',
  color,
  src,
  srcAnimate,
  title,
  desc,
  icon,
  size,
  limit,
  hint,
  ...props
}) => {
  const addDataTestId = useDataTestId();

  const [isAnimateImageVisible, setIsAnimateImageVisible] = useState(false);

  const thumbTitle = useMemo(() => {
    if (title) {
      return (
        <div className={css.thumb__title}>
          {limit ? <EllipsisTooltip>{title}</EllipsisTooltip> : title}
        </div>
      );
    }

    return undefined;
  }, [title, limit]);

  const thumbDesc = useMemo(() => {
    if (desc) {
      return (
        <div className={css.thumb__desc}>
          {limit ? <EllipsisTooltip>{desc}</EllipsisTooltip> : desc}
        </div>
      );
    }

    return undefined;
  }, [desc, limit]);

  const handleAnimateImageLoad = useCallback(() => {
    if (srcAnimate) {
      setIsAnimateImageVisible(true);
    }
  }, [srcAnimate]);

  useEffect(() => {
    if (!srcAnimate) {
      setIsAnimateImageVisible(false);
    }
  }, [srcAnimate]);

  return (
    <div
      className={clsx(
        css.thumb,
        size ? css[`thumb_size${ucFirst(size)}`] : '',
        !src ? css.thumb_symboled : '',
        className
      )}
      {...addDataTestId('Thumb')}
      {...props}
    >
      <div className={css.thumb__imageWrapper}>
        {src ? (
          <div className={css.thumb__imageInner}>
            <img
              src={src}
              alt=""
              className={clsx(
                css.thumb__image,
                isAnimateImageVisible ? css.thumb__image_hidden : ''
              )}
            />

            {srcAnimate && (
              <img
                src={srcAnimate}
                alt=""
                className={clsx(
                  css.thumb__image,
                  css.thumb__image_animated,
                  !isAnimateImageVisible ? css.thumb__image_hidden : ''
                )}
                onLoad={handleAnimateImageLoad}
              />
            )}
          </div>
        ) : (
          <div
            className={css.thumb__image}
            style={
              color
                ? {
                    backgroundColor: color,
                  }
                : {}
            }
          >
            {icon || symbol}
          </div>
        )}
      </div>

      {(thumbTitle || thumbDesc) && (
        <div className={css.thumb__info}>
          {thumbTitle}

          {thumbDesc}
        </div>
      )}

      {!!hint && <div className={css.thumb__hint}>{hint}</div>}
    </div>
  );
};
export default Thumb;
