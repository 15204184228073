import {v4} from 'uuid';
import moment from 'moment-timezone';
import {
  BlackCreateCardParams,
  Card3DSecureSettings,
  CardholderDeliveryAddress,
  ICard,
} from '../../types';
import Demo, {DemoApiHandler} from '../demo';
import {DEMO_COMPANY_ID} from '../../constants/demo';
import generateCreditCard from '../creditCardNumber';
import {hideCardPrivacyInfo} from './getCard';
import {ICardExt} from '../../types/demo';

const createCard: DemoApiHandler = ({config, instance, data}) => {
  const items = instance.getData().cards[instance.getData().productId];
  const {
    '3d_secure_settings': dSecureSettings,
    delivery_address,
    ...currentData
  } = data as BlackCreateCardParams;
  const {productSettings} = instance.getData();
  const card: ICardExt = {
    // person_id: DEMO_PERSON_ID,
    company_id: DEMO_COMPANY_ID,
    name: 'Rupert Hobbs',
    expiry_date: moment().add(3, 'years').toISOString(),
    status: 'Active',
    embossing_name: 'Rupert Hobbs',
    '3d_secure_settings': dSecureSettings as Card3DSecureSettings,
    delivery_address: delivery_address as CardholderDeliveryAddress,
    is_enrolled_for_3d_secure: true,
    is_card_3d_secure_activated: true,
    pin: '1234',
    security: {
      contactless_enabled: true,
      withdrawal_enabled: true,
      internet_purchase_enabled: true,
      overall_limits_enabled: false,
      all_time_limits_enabled: false,
    },
    personalization_product_code: '9999-0038',
    activated_at: moment().add(-1, 'day').toISOString(),
    created_at: moment().add(-1, 'day').toISOString(),
    updated_at: moment().add(-1, 'day').toISOString(),
    currency_code: instance.getCurrentCurrency(),
    ...currentData,
    id: v4(),
    masked_card_number: generateCreditCard(),
    cvv: 123,
    limits: {
      transaction_purchase:
        productSettings.default_card_transaction_purchase_limit,
      transaction_withdrawal:
        productSettings.default_card_transaction_withdrawal_limit,
      transaction_internet_purchase:
        productSettings.default_card_transaction_internet_purchase_limit,
      transaction_contactless_purchase:
        productSettings.default_card_transaction_contactless_purchase_limit,

      daily_purchase: productSettings.default_card_daily_purchase_limit,
      daily_withdrawal: productSettings.default_card_daily_withdrawal_limit,
      daily_internet_purchase:
        productSettings.default_card_daily_internet_purchase_limit,
      daily_contactless_purchase:
        productSettings.default_card_daily_contactless_purchase_limit,
      weekly_purchase: productSettings.default_card_weekly_purchase_limit,
      weekly_withdrawal: productSettings.default_card_weekly_withdrawal_limit,
      weekly_internet_purchase:
        productSettings.default_card_weekly_internet_purchase_limit,
      weekly_contactless_purchase:
        productSettings.default_card_weekly_contactless_purchase_limit,
      monthly_purchase: productSettings.default_card_monthly_purchase_limit,
      monthly_withdrawal: productSettings.default_card_monthly_withdrawal_limit,
      monthly_internet_purchase:
        productSettings.default_card_monthly_internet_purchase_limit,
      monthly_contactless_purchase:
        productSettings.default_card_monthly_contactless_purchase_limit,
      all_time_purchase: 0,
      all_time_withdrawal: 0,
      all_time_internet_purchase: 0,
      all_time_contactless_purchase: 0,

      daily_purchase_available:
        productSettings.default_card_daily_purchase_limit,
      daily_withdrawal_available:
        productSettings.default_card_daily_withdrawal_limit,
      daily_internet_purchase_available:
        productSettings.default_card_daily_internet_purchase_limit,
      daily_contactless_purchase_available:
        productSettings.default_card_daily_contactless_purchase_limit,
      weekly_purchase_available:
        productSettings.default_card_weekly_purchase_limit,
      weekly_withdrawal_available:
        productSettings.default_card_weekly_withdrawal_limit,
      weekly_internet_purchase_available:
        productSettings.default_card_weekly_internet_purchase_limit,
      weekly_contactless_purchase_available:
        productSettings.default_card_weekly_contactless_purchase_limit,
      monthly_purchase_available:
        productSettings.default_card_monthly_purchase_limit,
      monthly_withdrawal_available:
        productSettings.default_card_monthly_withdrawal_limit,
      monthly_internet_purchase_available:
        productSettings.default_card_monthly_internet_purchase_limit,
      monthly_contactless_purchase_available:
        productSettings.default_card_monthly_contactless_purchase_limit,
      all_time_purchase_available: 0,
      all_time_withdrawal_available: 0,
      all_time_internet_purchase_available: 0,
      all_time_contactless_purchase_available: 0,

      daily_purchase_used: 0,
      daily_withdrawal_used: 0,
      daily_internet_purchase_used: 0,
      daily_contactless_purchase_used: 0,
      weekly_purchase_used: 0,
      weekly_withdrawal_used: 0,
      weekly_internet_purchase_used: 0,
      weekly_contactless_purchase_used: 0,
      monthly_purchase_used: 0,
      monthly_withdrawal_used: 0,
      monthly_internet_purchase_used: 0,
      monthly_contactless_purchase_used: 0,
      all_time_purchase_used: 0,
      all_time_withdrawal_used: 0,
      all_time_internet_purchase_used: 0,
      all_time_contactless_purchase_used: 0,

      daily_overall_purchase: 0,
      weekly_overall_purchase: 0,
      monthly_overall_purchase: 0,

      daily_overall_purchase_used: 0,
      weekly_overall_purchase_used: 0,
      monthly_overall_purchase_used: 0,

      daily_overall_purchase_available: 0,
      weekly_overall_purchase_available: 0,
      monthly_overall_purchase_available: 0,
    },
  };

  items.push(card);

  instance.saveImpl(['cards']);

  return Demo.makeDataResponse<{card: ICard}>(config, {
    card: hideCardPrivacyInfo(card),
  });
};

export default createCard;
