import config from '../constants/config';
import logger from '../logger';
import CurrentClientStore from '../models/currentClient';
import ObjectKeys from '../utils/object';
import AuthService from './AuthService';

const log = logger.module('WebSocketService');
const PING_INTERVAL = 15000;

type WebsocketMsgOutTypeType = 'subscribe' | 'unsubscribe' | 'ping';
type WebsocketMsgInTypeType = 'event' | 'error';

interface WebSocketOutMessage<T> {
  type: WebsocketMsgOutTypeType;
  payload?: T;
}

interface WebSocketInMessage<T> {
  type: WebsocketMsgInTypeType;
  payload?: T;
}

type EventMsgPayload<T> = {
  routing_key: string;
  type:
    | 'ClientVerificationStateChangedType'
    | 'ClientPersonVerificationStateChangedType'
    | 'ClientCompanyVerificationStateChangedType';
  payload: T;
};

type SubscribeCallback<T> = (data: T) => void;

class WebSocketService {
  private static instance: WebSocketService | null = null;

  private ws: WebSocket | null = null;

  private enabled = false;

  private events: Record<
    string,
    SubscribeCallback<Record<string, unknown>>[]
  > = {};

  private pingTimer: number | null = null;

  public static getInstance(): WebSocketService {
    if (WebSocketService.instance === null) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  private static async getWsUrl(): Promise<string | null> {
    if (!(await AuthService.getInstance().checkToken(true, undefined, true))) {
      return null;
    }
    let url = config.apiUrl;
    if (url && url.endsWith('/')) {
      url = url.substr(0, url.length - 1);
    }
    return `${url}/ws/events?token=${AuthService.getInstance().getClearToken()}`.replace(
      'https://',
      'wss://'
    );
  }

  async init(): Promise<void> {
    if (this.enabled) {
      this.disconnect(true);
      const wsUrl = await WebSocketService.getWsUrl();
      if (wsUrl === null) {
        this.disconnect();
        return Promise.resolve();
      }
      return new Promise<void>((resolve) => {
        this.ws = new WebSocket(wsUrl);
        this.ws.addEventListener('open', () => {
          log.info('Connection established.');
          this.startPingRoutine();
          ObjectKeys(this.events).forEach((routingKey) => {
            (this.events[routingKey] || []).forEach((callback) => {
              this.subscribe(routingKey, callback);
            });
          });
          resolve();
        });

        this.ws.addEventListener('error', () => {
          // log.warn('WS error', {e});
        });

        this.ws.addEventListener('close', (ev) => {
          log.info(`ConnectionClosed`, ev);
          if (ev.code !== 1000) {
            this.reconnect();
          }
        });

        this.ws.addEventListener('message', (e) => {
          let msg: WebSocketInMessage<unknown> | null = null;
          try {
            msg = JSON.parse(e.data);
          } catch (ex) {
            log.error('Cant decode msg', {ex});
          }
          if (msg) {
            this.handleMessage(msg);
          }
        });
      });
    }
    return Promise.resolve();
  }

  private reconnect() {
    if (this.enabled) {
      this.ws = null;
      if (this.pingTimer) {
        window.clearInterval(this.pingTimer);
      }

      log.info('Try reconnect...');
      window.setTimeout(() => {
        this.init();
      }, 5000);
    }
  }

  private startPingRoutine() {
    if (this.enabled) {
      if (this.pingTimer !== null) {
        clearInterval(this.pingTimer);
      }

      this.pingTimer = window.setInterval(() => {
        const token = AuthService.getInstance().getClearToken(true);
        if (!token) {
          AuthService.getInstance().logout();
        } else {
          log.info('WebSocket ping request...');
          this.sendMsg({
            type: 'ping',
          });
        }
      }, PING_INTERVAL);
    }
  }

  private sendMsg<T>(msg: WebSocketOutMessage<T>) {
    if (this.ws && this.ws.OPEN === this.ws.readyState) {
      this.ws.send(JSON.stringify(msg));
    }
  }

  private handleMessage(msg: WebSocketInMessage<unknown>) {
    log.info(
      `Receive new msg: [${JSON.stringify(msg)}] ${this.ws?.readyState}`
    );
    if (msg.type === 'event') {
      this.handleEventMsg(msg.payload as EventMsgPayload<unknown>);
    }

    if (msg.type === 'error') {
      const client = CurrentClientStore.getState();
      log.error(`Error msg from web socket server`, {
        msg,
        client_id: client?.id,
      });
    }
  }

  private handleEventMsg(msg: EventMsgPayload<unknown>) {
    if (msg.type === 'ClientVerificationStateChangedType') {
      if (this.events[msg.routing_key]) {
        this.events[msg.routing_key].forEach((callback) => {
          callback(msg.payload as Record<string, unknown>);
        });
      }
    }
    if (
      msg.type === 'ClientCompanyVerificationStateChangedType' ||
      msg.type === 'ClientPersonVerificationStateChangedType'
    ) {
      ObjectKeys(this.events).forEach((routingKey) => {
        if (msg.routing_key.startsWith(routingKey)) {
          this.events[routingKey].forEach((callback) => {
            callback(msg.payload as Record<string, unknown>);
          });
        }
      });
    }
  }

  disconnect(soft = false): void {
    if (this.enabled) {
      if (this.ws) {
        this.ws.close(1000);
      }
      if (!soft) {
        if (this.pingTimer) {
          window.clearInterval(this.pingTimer);
        }
        this.events = {};
      }
    }
  }

  public subscribe(
    routingKey: string,
    callback: SubscribeCallback<Record<string, unknown>>
  ): void {
    if (this.enabled) {
      if (this.events[routingKey] === undefined) {
        this.events[routingKey] = [];
      }
      if (this.ws?.readyState === 1) {
        this.sendMsg({
          type: 'subscribe',
          payload: routingKey,
        });
      }

      if (
        this.events[routingKey] &&
        !this.events[routingKey].find((cb) => cb === callback)
      ) {
        log.info(`Add callback ${routingKey}`);
        this.events[routingKey].push(callback);
      }
    }
  }

  public unsubscribe(routingKey: string): void {
    if (this.enabled) {
      this.sendMsg({
        type: 'unsubscribe',
        payload: routingKey,
      });
      delete this.events[routingKey];
    }
  }
}

export default WebSocketService;
