export const generateCreditCard = (): string => {
  const prefix = 4;
  const length = 16;
  const reversedCCnumber = [];
  let sum = 0;
  let pos = 0;
  let ccnumber = prefix.toString();

  while (ccnumber.length < length - 1) {
    ccnumber += Math.floor(Math.random() * 10);
  }

  const reversedCCnumberString = ccnumber.split('').reverse().join('');

  for (let i = 0; i < reversedCCnumberString.length; i += 1) {
    reversedCCnumber[i] = parseInt(reversedCCnumberString.charAt(i), 10);
  }

  while (pos < length - 1) {
    let odd = reversedCCnumber[pos] * 2;
    if (odd > 9) {
      odd -= 9;
    }
    sum += odd;

    if (pos !== length - 2) {
      sum += reversedCCnumber[pos + 1];
    }
    pos += 2;
  }

  const checkdigit = ((Math.floor(sum / 10) + 1) * 10 - sum) % 10;

  ccnumber += checkdigit;

  return ccnumber;
};

export default generateCreditCard;
