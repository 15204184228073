import {
  combine,
  createEvent,
  createStore,
  sample,
  Store,
  StoreWritable,
} from 'effector';
import {
  DemoEventHints,
  DemoFeatureHints,
  DemoInitHints,
  DemoRouteHints,
  EventHints,
  FeatureHints,
  InitHints,
  RouteHints,
} from '../../constants/hints';
import {
  AppState,
  HINT_ROUTE_MODIFICATION,
  HintDisplayConfig,
  HintModal,
  ScreenSize,
  TypedMap,
} from '../../types';
// import logger from '../../logger';
import HINTS_TYPES, {HINT_EVENT_TYPE, HintConfig} from '../../types/hints';
import {App$} from '../app';
import {softClearAppStores} from '../clearStore';
import {hideSingleHint} from '../hintsLayer';
import {mediaBreakpoints$} from '../medias';
import {RouterState, RouterStore$} from '../router';
import ServerStorageStore, {ServerStorage} from '../serverStorage';
import unloadHints from './unload';
import {getCurrentMediaData} from '../../utils/media';
import ObjectKeys from '../../utils/object';
import clone from '../../utils/clone';

export type THintsModals = TypedMap<HINTS_TYPES, HintModal>;
export type THintsTargets = TypedMap<HINTS_TYPES, HTMLElement>;

export type HintsState = {
  visibleHintTargets: HINTS_TYPES[];
  existingHintTargets: HINTS_TYPES[];
  routeHintModification?: string;
  modals: THintsModals;
  targets: THintsTargets;
};

type HintsStoreType = HintsState & {
  hintsState?: Record<HINTS_TYPES, boolean>;
  eventsState?: Record<HINT_EVENT_TYPE, boolean>;
  activeInlineHints: HintConfig[];
  activeSingleHint?: HintConfig;
  activeRouteHint?: HintConfig;
  prevActiveSingleHint?: HintConfig;
  activeSingleHintDisplayConfig?: HintDisplayConfig;
};

type HintsConfigsStoreType = {
  EventHints: HintConfig[];
  InitHints: HintConfig[];
  FeatureHints: HintConfig[];
  RouteHints: HintConfig[];
  DemoEventHints: HintConfig[];
  DemoInitHints: HintConfig[];
  DemoFeatureHints: HintConfig[];
  DemoRouteHints: HintConfig[];
};

// const log = logger.module('HintsStorage');
let timer: number | null = null;
let previousHintsState: Store<HintsStoreType> | null = null;

const HintsConfigsStore = combine<
  Store<{[key in ScreenSize]: boolean}>,
  HintsConfigsStoreType
>(mediaBreakpoints$, (medias) => {
  const config = {
    EventHints: clone(EventHints),
    InitHints: clone(InitHints),
    FeatureHints: clone(FeatureHints),
    RouteHints: clone(RouteHints),
    DemoEventHints: clone(DemoEventHints),
    DemoInitHints: clone(DemoInitHints),
    DemoFeatureHints: clone(DemoFeatureHints),
    DemoRouteHints: clone(DemoRouteHints),
  };

  ObjectKeys(config).forEach((key) => {
    config[key].forEach((_, a) => {
      const mediaData = getCurrentMediaData({
        data: config[key][a].mediaData,
        medias: (medias as unknown) as Record<ScreenSize, boolean>,
      });

      config[key][a] = {
        ...config[key][a],
        ...(mediaData || {}),
      };
    });
  });

  return config;
});

export const HintsStateStore = createStore<HintsState>({
  existingHintTargets: [],
  visibleHintTargets: [],
  modals: {},
  targets: {},
});

HintsStateStore.reset(softClearAppStores, softClearAppStores);

const HintsStore = combine<
  StoreWritable<HintsState>,
  StoreWritable<ServerStorage>,
  Store<AppState>,
  Store<RouterState>,
  Store<HintsConfigsStoreType>,
  HintsStoreType
>(
  HintsStateStore,
  ServerStorageStore,
  App$,
  RouterStore$,
  HintsConfigsStore,
  (hintsState, serverStorageState, appStore, router, config) => {
    const activeInlineHints: HintConfig[] = [];
    let activeSingleHint: HintConfig | undefined;
    let activeRouteHint: HintConfig | undefined;
    let activeSingleHintDisplayConfig: HintDisplayConfig | undefined;
    let prevState: HintsStoreType | null = null;
    const prevActiveHints: HintConfig[] = [];
    let lastActive: HINTS_TYPES | undefined;
    const isDemo = appStore.config.demo;
    const eventHints = isDemo ? config.DemoEventHints : config.EventHints;
    const initHints = isDemo ? config.DemoInitHints : config.InitHints;
    const featureHints = isDemo ? config.DemoFeatureHints : config.FeatureHints;
    const routeHints = isDemo ? config.DemoRouteHints : config.RouteHints;

    if (previousHintsState) {
      prevState = previousHintsState.getState();
    }

    routeHints.forEach((hint) => {
      if (
        router.currentRoute &&
        hint.hintRoute?.path === router.currentRoute?.route &&
        (!hint.hintRouteModification ||
          hint.hintRouteModification === hintsState.routeHintModification) &&
        (!serverStorageState.hints || !serverStorageState.hints[hint.id]) &&
        !hint?.hidden
      ) {
        activeRouteHint = hint;
      }
    });

    eventHints.forEach((hint) => {
      let isPreviouslyDisplayed = false;

      if (prevState) {
        isPreviouslyDisplayed = Boolean(
          prevState.activeInlineHints.find((ih) => ih.id === hint.id)
        );
      }

      if (
        (!serverStorageState.hints || !serverStorageState.hints[hint.id]) &&
        ((hint.outOfScreenVisible &&
          hintsState.existingHintTargets.includes(hint.id)) ||
          hintsState.visibleHintTargets.includes(hint.id) ||
          isPreviouslyDisplayed) &&
        hint.eventType &&
        serverStorageState.events &&
        serverStorageState.events[hint.eventType] &&
        !hint?.hidden
      ) {
        activeInlineHints.push(hint);
      }
    });

    const singleHints = [...initHints, ...featureHints].reverse();

    for (let i = 0; i < singleHints.length; i += 1) {
      const hint = singleHints[i];

      if (serverStorageState.hints && serverStorageState.hints[hint.id]) {
        lastActive = hint.id;
        break;
      } else if (
        ((hint.outOfScreenVisible &&
          hintsState.existingHintTargets.includes(hint.id)) ||
          hintsState.visibleHintTargets.includes(hint.id)) &&
        !hint?.hidden
      ) {
        prevActiveHints.push(hint);
      }
    }

    if (prevActiveHints.length > 0) {
      activeSingleHint = prevActiveHints[prevActiveHints.length - 1];

      if (
        prevState &&
        prevState.activeSingleHint?.id === activeSingleHint.id &&
        prevState.activeSingleHintDisplayConfig &&
        prevState.activeSingleHintDisplayConfig.nextHint &&
        prevState.activeSingleHintDisplayConfig.previousHint
      ) {
        activeSingleHintDisplayConfig = prevState.activeSingleHintDisplayConfig;
      } else {
        activeSingleHintDisplayConfig = {
          nextHint:
            prevActiveHints.length > 1
              ? prevActiveHints[prevActiveHints.length - 2].id
              : undefined,
          previousHint: lastActive,
        };
      }
    }

    // if (prevState && prevState.activeSingleHint && !activeSingleHint) {
    //   hideSingleHint();
    // }

    return {
      hintsState: serverStorageState.hints,
      eventsState: serverStorageState.events,
      ...hintsState,
      activeInlineHints,
      activeSingleHint,
      activeRouteHint,
      prevActiveSingleHint: prevState?.activeSingleHint,
      activeSingleHintDisplayConfig,
    };
  }
);

unloadHints.watch(() => {
  setTimeout(() => {
    previousHintsState = HintsStore;
  }, 100);
});

sample({
  source: HintsStore,
  filter: (store) => {
    return !!(
      (!store.activeSingleHint || store.activeSingleHint.type === 'inline') &&
      store.prevActiveSingleHint
    );
  },
  target: hideSingleHint,
});

previousHintsState = HintsStore;

export type HintTargetExistingState = {
  event: HINTS_TYPES;
  state: boolean;
};

export const setHintTargetExistingState = createEvent<HintTargetExistingState>(
  'setHintTargetExisting'
);
HintsStateStore.on(setHintTargetExistingState, (State, {state, event}) => {
  const newState: HINTS_TYPES[] = [...State.existingHintTargets].filter(
    (s) => s !== event
  );
  if (state) {
    newState.push(event);
  }

  return {
    ...State,
    existingHintTargets: newState,
  };
});

export const setHintDestroyed = createEvent<HINTS_TYPES>('setHintDestroyed');
HintsStateStore.on(setHintDestroyed, (State, event) => {
  return {
    ...State,
    existingHintTargets: [...State.existingHintTargets].filter(
      (s) => s !== event
    ),
    visibleHintTargets: [...State.visibleHintTargets].filter(
      (s) => s !== event
    ),
  };
});

export const setRouteHintModification = createEvent<
  HINT_ROUTE_MODIFICATION | undefined
>('setRouteHintModification');
HintsStateStore.on(setRouteHintModification, (State, routeHintModification) => {
  return {
    ...State,
    routeHintModification,
  };
});

export const setHintTargetVisibleState = createEvent<HintTargetExistingState>(
  'setHintTargetVisibleState'
);
HintsStateStore.on(setHintTargetVisibleState, (State, {state, event}) => {
  const newState: HINTS_TYPES[] = [...State.visibleHintTargets].filter(
    (s) => s !== event
  );
  if (state) {
    newState.push(event);
  }

  return {
    ...State,
    visibleHintTargets: newState,
  };
});

export const updateEventStore = createEvent<void>('setHintTargetVisibleState');

HintsStateStore.on(updateEventStore, (State) => {
  // const newState = {...State};
  // const hintStore = HintsStore.getState();
  // let hasChange = false;
  //
  // EventHints.forEach((eventHint) => {
  //   if (
  //     (!hintStore.hintsState || !hintStore.hintsState[eventHint.id]) &&
  //     newState.visibleHintTargets.includes(eventHint.id) && // is visible
  //     !newState.activeInlineHints.find((h) => h.id === eventHint.id) && // not active
  //     eventHint.eventType &&
  //     hintStore.eventsState &&
  //     hintStore.eventsState[eventHint.eventType]
  //   ) {
  //     newState.activeInlineHints.push({...eventHint});
  //     hasChange = true;
  //   }
  // });
  //
  // if (hasChange) {
  //   return newState;
  // }
  return State;
});

export const setHintsModals = createEvent<THintsModals | undefined>(
  'setHintsModals'
);

HintsStateStore.on(setHintsModals, (state, modals) => {
  return {
    ...state,
    modals: {
      ...state.modals,
      ...modals,
    },
  };
});

export const setHintsTargets = createEvent<THintsTargets>('setHintsTargets');

HintsStateStore.on(setHintsTargets, (state, targets) => {
  return {
    ...state,
    targets: {
      ...state.targets,
      ...targets,
    },
  };
});

HintsStore.watch(() => {
  if (timer) {
    window.clearTimeout(timer);
  }
  timer = window.setTimeout(() => {
    updateEventStore();
    // log.info('HintsStore state is => ', state);
  }, 100);
});

export default HintsStore;
