import moment from 'moment-timezone';
import logger from '../logger';
import {App$} from '../models/app';
import {
  OrderBy,
  SessionTableSettings,
  UUID4,
  Pages,
  SavedFiltersBlackDashboard,
  SavedFiltersBlackStatements,
  SavedFiltersCards,
  SavedFiltersCustomerAccounts,
  SavedFiltersCustomerCards,
  SavedFiltersCustomers,
  SessionFilterPeriodDates,
  SessionFilters,
  SavedFiltersCompanies,
  SavedFiltersAccountHistory,
  SavedFiltersAccountCards,
  SavedFiltersAccountStatements,
  SavedFiltersUsers,
  SavedFiltersFraudCases,
  SavedFiltersEmployees,
  SavedFiltersCustomerAlertsHistory,
  SavedFiltersCompanyAlertsHistory,
  TColumns,
  SavedFiltersBlackCardTransactions,
  SavedFiltersMultiplePaymentsFile,
  SavedFiltersCompanyAccounts,
} from '../types';
import AuthService from './AuthService';
import getDatePresets from '../constants/datePresets';
import {RangeValue} from '../components/DateRangePanel/DateRangePanel';

const log = logger.module('SavedFiltersService');

class SavedFiltersService {
  private static instance: SavedFiltersService | null = null;

  private isInited = false;

  private localState: SessionFilters;

  private authServiceInstance: AuthService;

  public static getDefaultState(): SessionFilters {
    const isDemo = App$.getState().config.demo;
    return {
      pages: {},
      periodDate: {
        fromDate: moment()
          .startOf(isDemo ? 'year' : 'month')
          .format('YYYY-MM-DD'),
        toDate: moment()
          .endOf(isDemo ? 'year' : 'month')
          .format('YYYY-MM-DD'),
      },
      singleDate: moment().format('YYYY-MM-DD'),
      tableSettings: {},
    };
  }

  public static getInstance(): SavedFiltersService {
    if (SavedFiltersService.instance === null) {
      SavedFiltersService.instance = new SavedFiltersService();
      log.info('Init');
    }
    return SavedFiltersService.instance;
  }

  public static destroy(): void {
    if (SavedFiltersService.instance !== null) {
      log.info('Destroy');
      SavedFiltersService.instance = null;
    }
  }

  constructor() {
    this.authServiceInstance = AuthService.getInstance();
    this.localState = this.reloadLocalState();
  }

  public reloadLocalState(productId?: UUID4): SessionFilters {
    this.localState = this.getSavedFilters(undefined, productId);
    return this.localState;
  }

  public isServiceInitiated(): boolean {
    return this.isInited;
  }

  private getSavedFilters(userId?: UUID4, productId?: UUID4): SessionFilters {
    try {
      const savedFilters = this.authServiceInstance.getSavedFilters(
        userId,
        productId
      );
      this.isInited = true;
      return savedFilters || SavedFiltersService.getDefaultState();
    } catch (e) {
      this.isInited = false;
    }
    return SavedFiltersService.getDefaultState();
  }

  private save(): boolean {
    if (this.isServiceInitiated()) {
      return this.authServiceInstance.updateSavedFilters(this.localState);
    }
    return false;
  }

  public getSingleDateFilter(): moment.Moment {
    return this.localState.singleDate
      ? moment(this.localState.singleDate)
      : moment();
  }

  public updateSingleDateFilter(date: moment.Moment): boolean {
    this.localState.singleDate = date.clone().format('YYYY-MM-DD');
    return this.save();
  }

  static getPeriodDefaultDate = (): RangeValue => {
    if (App$.getState().config.demo) {
      return [moment().startOf('year'), moment().endOf('year')];
    }
    return [moment().startOf('month'), moment().endOf('month')];
  };

  public getPeriodDateFilter(): SessionFilterPeriodDates {
    const presets = getDatePresets();
    const periodDate = this.localState?.periodDate;
    let fromDate;
    let toDate;

    if (periodDate) {
      if (periodDate.preset) {
        const {period} = presets.filter(
          (presetItem) => presetItem.key === periodDate?.preset
        )[0];

        [fromDate, toDate] = period;
      } else {
        fromDate = periodDate.fromDate;
        toDate = periodDate.toDate !== 'today' ? periodDate.toDate : undefined;
      }

      fromDate = moment(fromDate);
      toDate = moment(toDate);
    } else {
      [fromDate, toDate] = SavedFiltersService.getPeriodDefaultDate();
    }

    return {
      fromDate: fromDate.format('YYYY-MM-DD'),
      toDate: toDate.format('YYYY-MM-DD'),
    };
  }

  public updatePeriodDateFilter(
    period: SessionFilterPeriodDates,
    initMode?: boolean
  ): boolean {
    const presets = getDatePresets();
    let fromDate = '';
    let toDate = '';
    let preset;

    presets.forEach((presetItem) => {
      if (
        presetItem.key !== 'custom' &&
        presetItem.period[0].isSame(period.fromDate, 'day') &&
        presetItem.period[1].isSame(period.toDate, 'day')
      ) {
        preset = presetItem.key;
      }
    });

    if (!preset) {
      fromDate = moment(period.fromDate).format('YYYY-MM-DD');
      toDate = moment().isSame(moment(period.toDate), 'day')
        ? 'today'
        : moment(period.toDate).format('YYYY-MM-DD');
    }

    this.localState.periodDate = {
      fromDate,
      toDate,
      preset,
    };

    if (initMode === true) {
      return true;
    }

    return this.save();
  }

  public getBlackDashboardFilters(): SavedFiltersBlackDashboard {
    const savedData = this.localState.pages[Pages.BlackDashboard];

    return {
      collapseState: savedData ? savedData.collapseState : true,
      accountIds: savedData?.accountIds || [],
    };
  }

  public updateBlackDashboardFilters(
    partialData: Partial<SavedFiltersBlackDashboard>
  ): boolean {
    const savedData = this.getBlackDashboardFilters();

    this.localState.pages[Pages.BlackDashboard] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getCompaniesFilters(): SavedFiltersCompanies {
    const savedData = this.localState.pages[Pages.Companies];
    return {
      name: savedData ? savedData.name : undefined,
      status: savedData ? savedData.status : undefined,
      riskProfile: savedData ? savedData.riskProfile : undefined,
      registrationNumber: savedData ? savedData.registrationNumber : undefined,
      email: savedData ? savedData.email : undefined,
      mobile: savedData ? savedData.mobile : undefined,
    };
  }

  public updateCompaniesFilters(
    partialData: Partial<SavedFiltersCompanies>
  ): boolean {
    const savedData = this.getCompaniesFilters();

    this.localState.pages[Pages.Companies] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getCompanyAccountsFilters(): SavedFiltersCompanyAccounts {
    const savedData = this.localState.pages[Pages.CompanyAccounts];
    return {
      name: savedData ? savedData.name : undefined,
      vban: savedData ? savedData.vban : undefined,
      isActive: savedData ? savedData.isActive : undefined,
    };
  }

  public updateCompanyAccountsFilters(
    partialData: Partial<SavedFiltersCompanyAccounts>
  ): boolean {
    const savedData = this.getCompanyAccountsFilters();

    this.localState.pages[Pages.CompanyAccounts] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getFraudCasesFilters(): SavedFiltersFraudCases {
    const savedData = this.localState.pages[Pages.FraudCases];
    return {
      riskStatuses: savedData ? savedData.riskStatuses : undefined,
      riskZones: savedData ? savedData.riskZones : undefined,
      caseStatuses: savedData ? savedData.caseStatuses : undefined,
      fromRiskScore: savedData ? savedData.fromRiskScore : undefined,
      toRiskScore: savedData ? savedData.toRiskScore : undefined,
      cardOwnerIds: savedData ? savedData.cardOwnerIds : undefined,
      assignedToName: savedData ? savedData.assignedToName : undefined,
      merchantCountryCode: savedData
        ? savedData.merchantCountryCode
        : undefined,
      cardOwnerCountryCode: savedData
        ? savedData.cardOwnerCountryCode
        : undefined,
      scoring: savedData ? savedData.scoring : undefined,
      // searchString: savedData ? savedData.searchString : undefined,
    };
  }

  public updateFraudCasesFilters(
    partialData: Partial<SavedFiltersFraudCases>
  ): boolean {
    const savedData = this.getFraudCasesFilters();

    this.localState.pages[Pages.FraudCases] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getCustomerAlertsHistoryFilters(): SavedFiltersCustomerAlertsHistory {
    const savedData = this.localState.pages[Pages.CustomerAlertsHistory];
    return {
      riskStatuses: savedData ? savedData.riskStatuses : undefined,
      riskZones: savedData ? savedData.riskZones : undefined,
      caseStatuses: savedData ? savedData.caseStatuses : undefined,
      fromRiskScore: savedData ? savedData.fromRiskScore : undefined,
      toRiskScore: savedData ? savedData.toRiskScore : undefined,
      assignedToName: savedData ? savedData.assignedToName : undefined,
      merchantCountryCode: savedData
        ? savedData.merchantCountryCode
        : undefined,
      cardOwnerCountryCode: savedData
        ? savedData.cardOwnerCountryCode
        : undefined,
      scoring: savedData ? savedData.scoring : undefined,
    };
  }

  public updateCustomerAlertsHistoryFilters(
    partialData: Partial<SavedFiltersCustomerAlertsHistory>
  ): boolean {
    const savedData = this.getCustomerAlertsHistoryFilters();

    this.localState.pages[Pages.CustomerAlertsHistory] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getCompanyAlertsHistoryFilters(): SavedFiltersCompanyAlertsHistory {
    const savedData = this.localState.pages[Pages.CompanyAlertsHistory];
    return {
      riskStatuses: savedData ? savedData.riskStatuses : undefined,
      riskZones: savedData ? savedData.riskZones : undefined,
      caseStatuses: savedData ? savedData.caseStatuses : undefined,
      fromRiskScore: savedData ? savedData.fromRiskScore : undefined,
      toRiskScore: savedData ? savedData.toRiskScore : undefined,
      assignedToName: savedData ? savedData.assignedToName : undefined,
      merchantCountryCode: savedData
        ? savedData.merchantCountryCode
        : undefined,
      cardOwnerCountryCode: savedData
        ? savedData.cardOwnerCountryCode
        : undefined,
      scoring: savedData ? savedData.scoring : undefined,
    };
  }

  public updateCompanyAlertsHistoryFilters(
    partialData: Partial<SavedFiltersCompanyAlertsHistory>
  ): boolean {
    const savedData = this.getCompanyAlertsHistoryFilters();

    this.localState.pages[Pages.CompanyAlertsHistory] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getCardsFilters(): SavedFiltersCards {
    const savedData = this.localState.pages[Pages.Cards];
    return {
      accountIds: savedData ? savedData.accountIds : [],
      blockTypes: savedData ? savedData.blockTypes : [],
      statuses: savedData ? savedData.statuses : [],
      isActive: savedData ? savedData.isActive : undefined,
      companyId: savedData ? savedData.companyId : undefined,
      maskedCardNumber: savedData ? savedData.maskedCardNumber : undefined,
      type: savedData ? savedData.type : undefined,
      name: savedData ? savedData.name : undefined,
      expirationFrom: savedData ? savedData.expirationFrom : undefined,
      expirationTo: savedData ? savedData.expirationTo : undefined,
      isReceiptsReminderEnabled: savedData
        ? savedData.isReceiptsReminderEnabled
        : undefined,
    };
  }

  public getMultiplePaymentsFileFilters(): SavedFiltersMultiplePaymentsFile {
    const savedData = this.localState.pages[Pages.MultiplePaymentsFile];
    return {
      statuses: savedData ? savedData.statuses : [],
    };
  }

  public updateMultiplePaymentsFileFilters(
    partialData: Partial<SavedFiltersMultiplePaymentsFile>
  ): boolean {
    const savedData = this.getMultiplePaymentsFileFilters();

    this.localState.pages[Pages.MultiplePaymentsFile] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public updateCardsFilters(partialData: Partial<SavedFiltersCards>): boolean {
    const savedData = this.getCardsFilters();

    this.localState.pages[Pages.Cards] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getBlackStatementsFilters(): SavedFiltersBlackStatements {
    const savedData = this.localState.pages[Pages.BlackStatements];

    return {
      merchantTypes: savedData?.merchantTypes,
      statuses: savedData?.statuses,
      selectedAccountId: savedData?.selectedAccountId,
      myEmployees: savedData?.myEmployees,
      includeTransactions: savedData?.includeTransactions,
      includeAuthorizations: savedData?.includeAuthorizations,
      includeAccountAdjustments: savedData?.includeAccountAdjustments,
      includeFees: savedData?.includeFees,
      includeTopUps: savedData?.includeTopUps,
      hasPaymentDocumentFiles: savedData?.hasPaymentDocumentFiles,
      hasPaymentNotes: savedData?.hasPaymentNotes,
    };
  }

  public updateBlackStatementsFilters(
    partialData: Partial<SavedFiltersBlackStatements>
  ): boolean {
    const savedData = this.getBlackStatementsFilters();

    this.localState.pages[Pages.BlackStatements] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getBlackCardTransactionsFilters(): SavedFiltersBlackCardTransactions {
    const savedData = this.localState.pages[Pages.BlackCardTransactions];

    return {
      includeTransactions: savedData?.includeTransactions,
      includeAuthorizations: savedData?.includeAuthorizations,
      includeFees: savedData?.includeFees,
    };
  }

  public updateBlackCardTransactionsFilters(
    partialData: Partial<SavedFiltersBlackCardTransactions>
  ): boolean {
    const savedData = this.getBlackCardTransactionsFilters();

    this.localState.pages[Pages.BlackCardTransactions] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getAccountStatementsFilters(): SavedFiltersAccountStatements {
    const savedData = this.localState.pages[Pages.AccountStatements];

    return {
      merchantTypes: savedData?.merchantTypes,
      statuses: savedData?.statuses,
      selectedAccountId: savedData?.selectedAccountId,
      includeTransactions: savedData?.includeTransactions,
      includeAuthorizations: savedData?.includeAuthorizations,
      includeAccountAdjustments: savedData?.includeAccountAdjustments,
      includeFees: savedData?.includeFees,
    };
  }

  public updateAccountStatementsFilters(
    partialData: Partial<SavedFiltersAccountStatements>
  ): boolean {
    const savedData = this.getBlackStatementsFilters();

    this.localState.pages[Pages.AccountStatements] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getCustomerListFilters(): SavedFiltersCustomers {
    const savedData = this.localState.pages[Pages.Customers];

    return {
      isActive: savedData ? savedData.isActive : undefined,
      lastName: savedData ? savedData.lastName : undefined,
      firstName: savedData ? savedData.firstName : undefined,
      personalNumber: savedData ? savedData.personalNumber : undefined,
    };
  }

  public updateCustomerListFilters(
    partialData: Partial<SavedFiltersCustomers>
  ): boolean {
    const savedData = this.getCustomerListFilters();

    this.localState.pages[Pages.Customers] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getCustomerCardsFilters(): SavedFiltersCustomerCards {
    const savedData = this.localState.pages[Pages.CustomerCards];

    return {
      statuses: savedData ? savedData.statuses : undefined,
    };
  }

  public updateCustomerCardsFilters(
    partialData: Partial<SavedFiltersCustomerCards>
  ): boolean {
    const savedData = this.getCustomerCardsFilters();

    this.localState.pages[Pages.CustomerCards] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getCustomerAccountsFilters(): SavedFiltersCustomerAccounts {
    const savedData = this.localState.pages[Pages.CustomerAccounts];

    return {
      statuses: savedData ? savedData.statuses : undefined,
      name: savedData ? savedData.name : undefined,
      vban: savedData ? savedData.vban : undefined,
    };
  }

  public updateCustomerAccountsFilters(
    partialData: Partial<SavedFiltersCustomerAccounts>
  ): boolean {
    const savedData = this.getCustomerAccountsFilters();

    this.localState.pages[Pages.CustomerAccounts] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getAccountCardsFilters(): SavedFiltersAccountCards {
    const savedData = this.localState.pages[Pages.AccountCards];

    return {
      cardStatuses: savedData ? savedData.cardStatuses : undefined,
    };
  }

  public getAccountHistoryFilters(): SavedFiltersAccountHistory {
    const savedData = this.localState.pages[Pages.AccountHistory];

    return {
      isNotSystem: savedData ? savedData.isNotSystem : undefined,
    };
  }

  public updateAccountCardsFilters(
    partialData: Partial<SavedFiltersAccountCards>
  ): boolean {
    const savedData = this.getAccountCardsFilters();

    this.localState.pages[Pages.AccountCards] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public updateAccountHistoryFilters(
    partialData: Partial<SavedFiltersAccountHistory>
  ): boolean {
    const savedData = this.getAccountHistoryFilters();

    this.localState.pages[Pages.AccountHistory] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getUsersFilters(): SavedFiltersUsers {
    const savedData = this.localState.pages[Pages.Users];

    return {
      isActive: savedData ? savedData.isActive : undefined,
    };
  }

  public updateUsersFilters(partialData: Partial<SavedFiltersUsers>): boolean {
    const savedData = this.getUsersFilters();

    this.localState.pages[Pages.Users] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getEmployeesFilters(): SavedFiltersEmployees {
    const savedData = this.localState.pages[Pages.Employees];

    return {
      name: savedData ? savedData.name : undefined,
      statuses: savedData ? savedData.statuses : undefined,
    };
  }

  public updateEmployeesFilters(
    partialData: Partial<SavedFiltersEmployees>
  ): boolean {
    const savedData = this.getEmployeesFilters();

    this.localState.pages[Pages.Employees] = {
      ...savedData,
      ...partialData,
    };

    return this.save();
  }

  public getTableSettings(page: Pages): SessionTableSettings | null {
    return (this.localState.tableSettings || {})[page] || null;
  }

  public updateTableSettings(
    page: Pages,
    sort?: OrderBy,
    pageSize?: number,
    columns?: TColumns
  ): boolean {
    const savedData = this.getTableSettings(page);
    const data: SessionTableSettings = {
      ...(savedData || {}),
    };

    if (sort) {
      data.sort = sort;
    }

    if (pageSize !== undefined) {
      data.pageSize = pageSize;
    }

    if (columns !== undefined) {
      data.columns = columns;
    }

    if (!this.localState.tableSettings) {
      this.localState.tableSettings = {};
    }

    this.localState.tableSettings[page] = data;

    return this.save();
  }
}

export default SavedFiltersService;
