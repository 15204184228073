import React, {useCallback, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import {Modal} from 'antd';
import {FormattedMessage, useIntl} from 'react-intl';
import {useStoreMap} from 'effector-react';
import Button from '../../components/Button';
import useMedias from '../../customHooks/useMedias';
import {modalWidth} from '../../constants/styles';
import ModalNotify from '../../components/ModalNotify';
import MarkList from '../../components/MarkList';
import useProductPricingPlan from '../../customHooks/useProductPricingPlan';
import ServerStorageStore, {setNewFeatures} from '../../models/serverStorage';
import Bar from '../../components/Bar';
import {ReactComponent as IconClose} from '../../images/icons/close.svg';
import css from './WBNewFeatures.module.less';

const DATA = {
  id: '2.3.2', // Release version !!!
  items: [
    'WBNewFeatures-Totals_are_now_included_in_all_statements',
    'WBNewFeatures-You_can_now_filter_transactions_by_type',
    'WBNewFeatures-Updated_PDF_designs',
  ],
};

export interface WBNewFeaturesProps {
  barClassName?: string;
  modalClassName?: string;
  barStyle?: React.CSSProperties;
  modalStyle?: React.CSSProperties;
}

const WBNewFeatures: React.FC<React.PropsWithChildren<WBNewFeaturesProps>> = ({
  barClassName,
  modalClassName,
  barStyle,
  modalStyle,
}) => {
  const intl = useIntl();
  const media = useMedias();
  const [newFeatures] = useStoreMap({
    store: ServerStorageStore,
    keys: ['new_features'],
    fn: (state$) => [state$.new_features],
  });
  const [showFeatures, setShowFeatures] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {plan} = useProductPricingPlan();

  const content = useMemo(() => {
    return (
      <MarkList
        className={css.list}
        listStyle="checkmarkRounded"
        listStyleColor="blue"
        listStyleAlign="top"
        items={DATA.items.map((item) => {
          return {
            content: intl.formatMessage({
              id: item,
            }),
          };
        })}
      />
    );
  }, [intl]);

  const handleClose = useCallback(async () => {
    setIsModalVisible(false);
    setShowFeatures(false);
    await setNewFeatures(DATA.id);
  }, []);

  useEffect(() => {
    if (typeof newFeatures !== 'undefined' && newFeatures !== DATA.id) {
      setShowFeatures(true);

      if (!media.xs) {
        setIsModalVisible(true);
      }
    }
  }, [media.xs, newFeatures]);

  return null;

  return (
    <>
      {media.xs && showFeatures && !isModalVisible && plan !== undefined && (
        <Bar
          title={intl.formatMessage({id: 'WBNewFeatures-New_features_'})}
          action={
            <Button
              type="primary"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              <FormattedMessage id="WBNewFeatures-Tell_me_more" />
            </Button>
          }
          color="blue"
          position="top"
          className={barClassName}
          style={barStyle}
        />
      )}

      {media.xs ? (
        <Modal
          title={intl.formatMessage({id: 'WBNewFeatures-New_features_'})}
          visible={showFeatures && isModalVisible && plan !== undefined}
          onOk={handleClose}
          onCancel={handleClose}
          closable
          closeIcon={<IconClose />}
          className={clsx(
            css.modalMobile,
            'ant-modal_sticky ant-modal_blue',
            modalClassName
          )}
          width={modalWidth}
          footer={false}
          style={modalStyle}
          destroyOnClose
          maskClosable={false}
        >
          {content}
        </Modal>
      ) : (
        <ModalNotify
          visible={showFeatures && isModalVisible && plan !== undefined}
          title={intl.formatMessage({id: 'WBNewFeatures-New_features_'})}
          color="blue"
          position="bottomRight"
          showAnimation="fromTop"
          onClose={handleClose}
          className={clsx(css.modalDesktop, modalClassName)}
          style={modalStyle}
        >
          {content}
        </ModalNotify>
      )}
    </>
  );
};

export default WBNewFeatures;
