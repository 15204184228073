import {UUID4} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const deleteUser: DemoApiHandler = ({config, instance, url}) => {
  const userIds: UUID4[] = url?.queryParams?.user_ids as UUID4[];
  if (userIds && userIds.length > 0) {
    const users = instance.getData().productUsers;

    if (users) {
      const data = instance.getData();
      data.productUsers = data.productUsers.filter(
        (pu) => !userIds.includes(pu.id)
      );
      instance.saveImpl(['productUsers']);

      return Demo.makeDataResponse<string>(config, 'ok');
    }
  }

  return Demo.makeUnprocessableEntityResponse(config, 90721);
};

export default deleteUser;
