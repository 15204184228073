import {SplitSdk} from '@splitsoftware/splitio-react';
import {createEvent, createStore} from 'effector';
import config from '../../constants/config';
import {TreatmentsWithConfig, TypedMap} from '../../types';
import getGuestId from '../../utils/guest';

export type WFactory = ReturnType<typeof SplitSdk> | null;
const key = config.splitIOApiKey;

type SplitKeys = 'common' | 'user' | 'product' | 'client';

const sdk: WFactory = key
  ? SplitSdk({
      core: {
        authorizationKey: key,
        key: getGuestId(),
      },
    })
  : null;

export type FeatureStoreType = {
  sdk: WFactory;
  commonIsReady: boolean;
  userIsReady: boolean;
  clientIsReady: boolean;
  productIsReady: boolean;
  treatments?: TreatmentsWithConfig;
};

const FeatureStore = createStore<FeatureStoreType>({
  sdk,
  commonIsReady: false,
  productIsReady: false,
  clientIsReady: false,
  userIsReady: false,
});

export const UpdateFeature = createEvent<
  TypedMap<SplitKeys, TreatmentsWithConfig>
>('UpdateFeature');

FeatureStore.on(UpdateFeature, (state, features) => {
  return {
    ...state,
    // commonTreatments: features.common || state.commonTreatments,
    // productTreatments: features.product || state.productTreatments,
    // clientTreatments: features.client || state.clientTreatments,
    treatments: {
      ...(state.treatments || {}),
      ...(features.product !== undefined ? features.product : {}),
      ...(features.client !== undefined ? features.client : {}),
      ...(features.user !== undefined ? features.user : {}),
      ...(features.common !== undefined ? features.common : {}),
    },
    userIsReady: features.user !== undefined,
    clientIsReady: features.client !== undefined,
    productIsReady: features.product !== undefined,
    commonIsReady: features.common !== undefined,
  };
});

export default FeatureStore;
