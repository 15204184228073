import clsx from 'clsx';
import React from 'react';
import css from './Bar.module.less';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  title?: React.ReactNode;
  action?: React.ReactNode;
  color?: 'blue';
  position?: 'top';
  reverse?: boolean;
}

const Bar: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  style,
  title,
  action,
  color,
  position,
  reverse,
}) => {
  return (
    <div
      className={clsx(
        css.bar,
        color && css[`bar_${color}`],
        position && css[`bar_${position}`],
        reverse && css.bar_reverse,
        className
      )}
      style={style}
    >
      {title && <div className={css.bar__title}>{title}</div>}

      {action && <div className={css.bar__action}>{action}</div>}
    </div>
  );
};

export default Bar;
