import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import queryString, {ParsedQuery} from 'query-string';

const useQuery = (): ParsedQuery => {
  const location = useLocation();

  return useMemo(() => queryString.parse(location.search), [location]);
};

export default useQuery;
