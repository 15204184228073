import {TBadgeColors, TypedMap, UserStatus} from '../types';

export const thumbColors: string[] = [
  '#E84040',
  '#FA7317',
  '#F0A525',
  '#85CC16',
  '#14B810',
  '#23C55E',
  '#15B8A7',
  '#0EA5EA',
  '#356BE8',
  '#6366F1',
  '#8B5CF6',
  '#CA44E0',
  '#EB4899',
  '#F43F5E',
];

export const UserStatusColor: TypedMap<UserStatus, TBadgeColors> = {
  Active: 'green',
  Deleted: 'gray',
  Locked: 'yellow',
};

export const UserStatusLabel: TypedMap<UserStatus, string> = {
  Active: 'App-User_Active',
  Deleted: 'App-User_Deleted',
  Locked: 'App-User_Locked',
};

export const WBUserRoles = ['Admin', 'CFO', 'Accountant', 'Developer'] as const;

export default thumbColors;
