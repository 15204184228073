import React, {ReactNode, useMemo} from 'react';
import clsx from 'clsx';
import Thumb, {PropsThumb} from '../Thumb/Thumb';
import useDataTestId from '../../customHooks/useDataTestId';
import EllipsisTooltip from '../EllipsisTooltip';
import TestProvider from '../../modules/TestProvider/TestProvider';
import {ReactComponent as IconWarningFilled} from '../../images/icons/warning-fill.svg';
import {ReactComponent as IconSelected} from '../../images/icons/success-fill.svg';
import css from './CardThumbInfo.module.less';

export interface CardThumbInfoProps extends Omit<PropsThumb, 'hint'> {
  info?: ReactNode;
  type?: 'warning';
  isSelected?: boolean;
}

const CardThumbInfo: React.FC<CardThumbInfoProps> = ({
  className,
  symbol = '',
  color,
  src,
  srcAnimate,
  title,
  desc,
  icon,
  size,
  limit = false,
  info,
  type,
  isSelected,
  onClick,
  ...props
}) => {
  const addDataTestId = useDataTestId();

  const processedTitle = useMemo(() => {
    if (title) {
      return (
        <div className={css.cardThumbInfo__title}>
          {limit ? <EllipsisTooltip>{title}</EllipsisTooltip> : title}
        </div>
      );
    }

    return undefined;
  }, [limit, title]);

  const processedDesc = useMemo(() => {
    if (desc) {
      return (
        <div className={css.cardThumbInfo__desc}>
          {limit ? <EllipsisTooltip>{desc}</EllipsisTooltip> : desc}
        </div>
      );
    }

    return undefined;
  }, [desc, limit]);

  const IconType = useMemo(() => {
    let NewIcon;

    switch (type) {
      case 'warning':
        NewIcon = IconWarningFilled;
        break;
      default:
        break;
    }

    return NewIcon;
  }, [type]);

  return (
    <TestProvider testId="CardThumbInfo">
      <div
        className={clsx(
          css.cardThumbInfo,
          type ? css[`cardThumbInfo_${type}`] : '',
          isSelected ? css.cardThumbInfo_selected : '',
          className
        )}
        onClick={onClick}
        role="presentation"
        {...addDataTestId('CardThumbInfo')}
        {...props}
      >
        <div className={css.cardThumbInfo__thumbWrapper}>
          <Thumb
            className={css.cardThumbInfo__thumb}
            symbol={symbol}
            color={color}
            src={src}
            srcAnimate={srcAnimate}
            icon={icon}
            size={size}
            limit={limit}
          />

          {type && IconType && (
            <IconType className={css.cardThumbInfo__thumbIcon} />
          )}
        </div>

        {(processedTitle || processedDesc) && (
          <div className={css.cardThumbInfo__data}>
            {processedTitle}

            {processedDesc}
          </div>
        )}

        {info && <div className={css.cardThumbInfo__info}>{info}</div>}

        {isSelected && (
          <div className={css.cardThumbInfo__postInfo}>
            <IconSelected className={css.cardThumbInfo__selectedIcon} />
          </div>
        )}
      </div>
    </TestProvider>
  );
};

export default CardThumbInfo;
