import {createEffect, createEvent, createStore} from 'effector';
import clearAppStores from '../clearStore';
import {IUnresolvedFee} from '../../types';
import {getUnresolvedFees} from '../../api';

export interface IUnresolvedFeesStore {
  fees?: IUnresolvedFee[];
  loading?: boolean;
  loaded?: boolean;
}

export const UnresolvedFeesStore = createStore<IUnresolvedFeesStore>({});

export const getUnresolvedFeesFx = createEffect<void, IUnresolvedFee[], Error>({
  name: 'getUnresolvedFeesFx',
  handler: async () => {
    return getUnresolvedFees();
  },
});

UnresolvedFeesStore.on(getUnresolvedFeesFx, (store) => {
  return {
    ...store,
    loading: true,
  };
});

UnresolvedFeesStore.on(getUnresolvedFeesFx.doneData, (store, fees) => {
  return {
    ...store,
    fees,
  };
});

UnresolvedFeesStore.on(getUnresolvedFeesFx.finally, (store) => {
  return {
    ...store,
    loading: false,
    loaded: true,
  };
});

export const clearUnresolvedFees = createEvent();

UnresolvedFeesStore.on(clearUnresolvedFees, (store) => {
  return {
    ...store,
    fees: [],
  };
});

export const cleanUnresolvedFeesStore = createEvent();

UnresolvedFeesStore.reset(cleanUnresolvedFeesStore, clearAppStores);
