import Demo, {DemoApiHandler} from '../demo';

const changeCardPin: DemoApiHandler = ({config, instance, url, data}) => {
  const item = instance
    .getData()
    .cards[instance.getData().productId].find(
      (a) => a.id === url?.params?.card_id
    );

  if (item) {
    item.pin = atob(data.encrypted_pin as string);

    instance.saveImpl(['cards']);

    return Demo.makeDataResponse<'ok'>(config, 'ok');
  }

  return Demo.makeUnprocessableEntityResponse(config, 30001);
};

export default changeCardPin;
