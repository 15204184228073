import React from 'react';
import {RouteInfo} from './route';
import {TooltipProps} from '../components/Tooltip';
import {ScreenSize} from './styles';
import {TypedMap} from './common';

enum HINTS_TYPES {
  INIT_TOP_UP = 'hint_init_top_up',
  INIT_CREATE_CARD = 'hint_init_create_cards',
  INIT_SWITCH_PRODUCTS = 'hint_init_switch_products',
  INIT_CREATE_USERS = 'hint_init_create_users',
  ROUTE_TOP_UP = 'hint_route_top_up',
  ROUTE_ACCOUNTS = 'hint_route_accounts',
  ROUTE_COMPANY_LIMITS = 'hint_route_company_limits',
  ROUTE_CREATE_CARD = 'hint_route_create_card',
  ROUTE_CREATE_CARD_VIRTUAL = 'hint_route_create_card_virtual',
  ROUTE_CREATE_CARD_PHYS = 'hint_route_create_card_phys',
  ROUTE_PAYROLL_CARD = 'hint_route_payroll_card',
  ROUTE_CARD_DETAILS = 'hint_route_card_details',
  ROUTE_ACCOUNT_DETAILS = 'hint_route_account_details',
  ROUTE_EXPENSE_PAYMENTS = 'hint_route_expense_payments',
  ROUTE_PAYROLL_PAYMENTS = 'hint_route_payroll_payments',
  ROUTE_MASS_PAYMENTS = 'hint_route_mass_payments',
  ROUTE_STATEMENTS = 'hint_route_statements',
  ROUTE_API = 'hint_route_api',
  ROUTE_PRICING_PLAN = 'hint_route_pricing_plan',
  ROUTE_MY_PROFILE = 'hint_route_my_profile',
  ROUTE_COMPANY_PROFILE = 'hint_route_company_profile',
  ROUTE_CURRENCY_REQUEST = 'hint_route_currency',
  ROUTE_3DS_BIN = 'hint_3ds_bin',
  INIT_MAKE_PAYMENTS = 'hint_init_make_payments',
  EVENT_CREATE_CARD = 'hint_event_create_card',
  EVENT_RECEIPT_REMINDERS = 'hint_event_receipt_reminders',
  FEATURE_EXPORT_STATEMENTS = 'hint_feature_export_statements',
}

export enum HINT_ROUTE_MODIFICATION {
  CREATE_CARD_PHYSICAL = 'create_card_physical',
  CREATE_CARD_VIRTUAL = 'create_card_virtual',
}

export enum HINT_EVENT_TYPE {
  EVENT_CREATE_CARD = 'event_create_card',
  EVENT_RECEIPT_REMINDERS = 'event_receipt_reminders',
}

export type HintConfigMedia = Partial<Omit<HintConfig, 'id' | 'mediaData'>>;
export type HintConfigMediaData = TypedMap<ScreenSize, HintConfigMedia>;

export type HintConfig = {
  id: HINTS_TYPES;
  type: 'inline' | 'single' | 'route';
  shadowed?: boolean | TypedMap<ScreenSize, boolean>;

  eventType?: HINT_EVENT_TYPE;

  hintRoute?: RouteInfo;
  hintRouteModification?: HINT_ROUTE_MODIFICATION;

  title_key?: string;
  content_key?: string;

  tooltipProps?: TooltipProps;
  modalPlacement?: TooltipProps['placement'];

  outOfScreenVisible?: boolean;
  hidden?: boolean;

  mediaData?: HintConfigMediaData;
};

export type HintDisplayConfig = {
  nextHint?: HINTS_TYPES;
  previousHint?: HINTS_TYPES;
};

export type HintModal = (data: {
  hint: HintConfig;
  target?: HTMLElement;
  close: () => void;
}) => React.ReactNode;

export default HINTS_TYPES;
