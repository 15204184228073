import Demo, {DemoApiHandler} from '../demo';

const getCardTopUpFees: DemoApiHandler = ({config}) => {
  return Demo.makeDataResponse<{
    fees: {fee_type: string; amount: number}[];
    total_amount: number;
  }>(config, {fees: [], total_amount: 0});
};

export default getCardTopUpFees;
