import Demo, {DemoApiHandler} from '../demo';
import {TSupportManagers} from '../../types/supportManagers';
import config from '../../constants/config';

const getClientSupportManagers: DemoApiHandler = ({config: localConfig}) => {
  return Demo.makeDataResponse<{
    support_managers: TSupportManagers;
  }>(localConfig, {
    support_managers: {
      crm: {
        id: 'aff7d2b3-81b2-47c3-8e63-dc56092f43b3',
        visible: true,
        first_name: 'John',
        last_name: 'Doe',
        email: config.supportEmail,
        phone: config.supportPhone,
        image: '/images/support-manager.png',
        telegram: config.supportPhone,
        viber: config.supportPhone,
        whats_app: config.supportPhone,
        language_codes: ['ENG', 'FRA', 'SPA', 'ITA'],
      },
    },
  });
};

export default getClientSupportManagers;
