import Endpoints from '../constants/endpoints';
import {IPermission} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const getPermissions = async (): Promise<IPermission[]> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(Endpoints.getPermissions));
  return response.data.permissions;
};

export default getPermissions;
