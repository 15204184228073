import moment from 'moment-timezone';
import React, {lazy, useCallback, useMemo, useState, Suspense} from 'react';
import {IntlProvider} from 'react-intl';
import Loader from '../../components/Loader';
import {setLocaleMessages, setMomentLocale} from '../../models/i18n';
import {LocaleInfo, LocaleMessages, UserLocale} from '../../types';

type Props = React.PropsWithChildren<{
  lang: UserLocale;
}>;

const EnProvider = lazy(
  () => import(/* webpackChunkName: 'en_lang' */ './providers/EnProvider')
);

const EtProvider = lazy(
  () => import(/* webpackChunkName: 'et_lang' */ './providers/EtProvider')
);

const EsProvider = lazy(
  () => import(/* webpackChunkName: 'es_lang' */ './providers/EsProvider')
);

const FrProvider = lazy(
  () => import(/* webpackChunkName: 'fr_lang' */ './providers/FrProvider')
);

const DeProvider = lazy(
  () => import(/* webpackChunkName: 'de_lang' */ './providers/DeProvider')
);

const PtProvider = lazy(
  () => import(/* webpackChunkName: 'pt_lang' */ './providers/PtProvider')
);

const ItProvider = lazy(
  () => import(/* webpackChunkName: 'it_lang' */ './providers/ItProvider')
);

const RuProvider = lazy(
  () => import(/* webpackChunkName: 'ru_lang' */ './providers/RuProvider')
);

const PlProvider = lazy(
  () => import(/* webpackChunkName: 'pl_lang' */ './providers/PlProvider')
);

const NlProvider = lazy(
  () => import(/* webpackChunkName: 'nl_lang' */ './providers/NlProvider')
);

const CsProvider = lazy(
  () => import(/* webpackChunkName: 'cs_lang' */ './providers/CsProvider')
);

const SvProvider = lazy(
  () => import(/* webpackChunkName: 'sv_lang' */ './providers/SvProvider')
);

const DaProvider = lazy(
  () => import(/* webpackChunkName: 'da_lang' */ './providers/DaProvider')
);

const FiProvider = lazy(
  () => import(/* webpackChunkName: 'fi_lang' */ './providers/FiProvider')
);

const LvProvider = lazy(
  () => import(/* webpackChunkName: 'lv_lang' */ './providers/LvProvider')
);

const LtProvider = lazy(
  () => import(/* webpackChunkName: 'lt_lang' */ './providers/LtProvider')
);

const LangProvider: React.FC<React.PropsWithChildren<Props>> = ({
  lang,
  children,
}: Props) => {
  const [currentLocale, setCurrentLocal] = useState(lang);
  const [currentMessages, setCurrentMessages] = useState<LocaleMessages | null>(
    null
  );
  const Provider = useMemo(() => {
    switch (lang) {
      case 'es':
        return EsProvider;
      case 'fr':
        return FrProvider;
      case 'de':
        return DeProvider;
      case 'pt':
        return PtProvider;
      case 'it':
        return ItProvider;
      case 'ru':
        return RuProvider;
      case 'pl':
        return PlProvider;
      case 'nl':
        return NlProvider;
      case 'cs':
        return CsProvider;
      case 'sv':
        return SvProvider;
      case 'da':
        return DaProvider;
      case 'fi':
        return FiProvider;
      case 'lv':
        return LvProvider;
      case 'et':
        return EtProvider;
      case 'lt':
        return LtProvider;
      default:
        return EnProvider;
    }
  }, [lang]);

  const handleLoad = useCallback((localeInfo: LocaleInfo) => {
    setLocaleMessages(localeInfo.messages);
    setMomentLocale(localeInfo.momentPickerLocale);
    moment.locale(localeInfo.momentLocale);
    setCurrentLocal(localeInfo.local);
    setCurrentMessages(localeInfo.messages);
  }, []);

  return (
    <>
      <Suspense fallback={<Loader size="large" transparent={false} />}>
        {Provider && <Provider onLoad={handleLoad} />}
      </Suspense>
      {currentMessages && (
        <IntlProvider
          locale={currentLocale}
          defaultLocale="en"
          messages={currentMessages}
        >
          {children}
        </IntlProvider>
      )}
    </>
  );
};

export default LangProvider;
