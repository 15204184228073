import React, {ErrorInfo, PureComponent} from 'react';

import logger from '../../logger';
import RequestError from '../../utils/RequestError';
import MaintenanceBoundary from './Maintenance/MaintenanceBoundary';

const log = logger.module('BaseErrorBoundary');

class BaseErrorBoundary extends PureComponent<React.PropsWithChildren> {
  public state: {error: RequestError | null} = {
    error: null,
  };

  public componentDidCatch(error: RequestError, errorInfo: ErrorInfo): void {
    log.error('Maintenance: Catch error', {error, errorInfo});
    this.setState({error});
  }

  public render(): React.ReactNode {
    const {error} = this.state;
    const {children} = this.props;

    if (error) {
      return <MaintenanceBoundary />;
    }

    return children;
  }
}

export default BaseErrorBoundary;
