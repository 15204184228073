import {AxiosResponse} from 'axios';
import Endpoints, {WLEndpoints} from '../constants/endpoints';
import {
  TopUpHistoryItem,
  TopUpTransactionsHistory,
  TopUpTransactionsHistoryRequestParams,
} from '../types/statement';
import {toListApiRequest} from '../utils/listRequest';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';
import {
  UUID4,
  ITransaction,
  INewTransactionRequest,
  ISummaryTransactionsRequest,
  ISummaryTransactions,
  RequestWithSortsOrders,
} from '../types';

export const getTransaction = async (id: UUID4): Promise<ITransaction> => {
  const response = (await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(Endpoints.getTransaction, {transaction_id: id})
    )) as AxiosResponse<{transaction: ITransaction}>;

  return response.data.transaction;
};

export const createTransaction = async (
  params: INewTransactionRequest
): Promise<ITransaction> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .post(getApiPathParamsMaker(WLEndpoints.createTransaction), {
      ...params,
      processed_at: params.processed_at.utc().format(),
      purchase_date: params.purchase_date.utc().format(),
    });
  return response.data.transaction;
};

export const getSummaryTransactions = async (
  params: ISummaryTransactionsRequest
): Promise<ISummaryTransactions> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get({
      endpoint: WLEndpoints.getSummaryTransactions,
      queryParams: {
        from_date: params.fromDate.utc().format(),
        to_date: params.toDate.utc().format(),
      },
    });

  return response.data;
};

export const getTopUpHistory = async (
  params: RequestWithSortsOrders<TopUpTransactionsHistoryRequestParams>
): Promise<TopUpTransactionsHistory> => {
  const response: AxiosResponse<{
    top_up_history: TopUpHistoryItem[];
    total_records_number: number;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({
      endpoint: Endpoints.getTopUpHistory,
      queryParams: toListApiRequest(params, undefined, true),
      stupidArrayFormat: true,
    });

  return {
    items: response.data.top_up_history || [],
    totalItems: response.data.total_records_number,
  };
};

export default getTransaction;
