import Demo, {DemoApiHandler} from '../demo';

const deleteApiKey: DemoApiHandler = ({config, instance, url}) => {
  if (url?.params?.api_key_id) {
    const apiKeys = instance.getData().apiKeys[instance.getData().productId];
    const apiKey = apiKeys.find((a) => a.id === url?.params?.api_key_id);

    if (apiKey) {
      const index = apiKeys.indexOf(apiKey);

      if (index !== -1) {
        apiKeys.splice(index, 1);
        instance.saveImpl(['apiKeys']);

        return Demo.makeDataResponse<string>(config, 'ok');
      }
    }
  }

  return Demo.makeUnprocessableEntityResponse(config, 90721);
};

export default deleteApiKey;
