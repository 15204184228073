import React from 'react';
import {FormattedMessage} from 'react-intl';
import clsx from 'clsx';
import Button from '../../../components/Button';
import AuthService from '../../../services/AuthService';
import useMedias from '../../../customHooks/useMedias';
import {gotoLogin} from '../../../models/router';
import css from './Forbidden.module.less';
import gcss from '../../../utils/styles';

const Forbidden: React.FC = () => {
  const media = useMedias();

  const handleHomeClick = async () => {
    await AuthService.getInstance().logout();
    gotoLogin();
  };

  return (
    <div className={css.notFound}>
      <div className={css.notFoundInner}>
        <div className={css.notFoundTitle}>403</div>

        <div className={css.notFoundDesc}>
          <FormattedMessage id="App-You_don_t_have_access_to_this_functionality_" />
        </div>

        <Button
          onClick={handleHomeClick}
          type="primary"
          shape="round"
          className={clsx(
            'ant-btn_wide ant-btn_green-emerald',
            gcss('t_font-weight_700'),
            gcss('t_transform_uppercase'),
            media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger'
          )}
        >
          <FormattedMessage id="App-Go_to_home_page" />
        </Button>
      </div>
    </div>
  );
};

export default Forbidden;
