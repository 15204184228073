import {createEvent, createStore} from 'effector';
import AuthService from '../../services/AuthService';
import {HintConfig, HintDisplayConfig} from '../../types';
import {softClearAppStores} from '../clearStore';

type HintsLayer = {
  activeSingleHint?: HintConfig;
  activeSingleHintTarget?: HTMLDivElement;
  activeSingleHintDisplayConfig?: HintDisplayConfig;
};

export type ShowSingleHintParams = {
  hint: HintConfig;
  displayConfig: HintDisplayConfig;
  target: HTMLDivElement;
};

export const showSingleHint = createEvent<ShowSingleHintParams>(
  'showSingleHint'
);
export const hideSingleHint = createEvent<void>('hideSingleHint');

const HintsLayerStore = createStore<HintsLayer>({});

HintsLayerStore.reset(softClearAppStores);

HintsLayerStore.on(showSingleHint, (State, {hint, displayConfig, target}) => {
  return {
    ...State,
    activeSingleHint: hint,
    activeSingleHintDisplayConfig: displayConfig,
    activeSingleHintTarget: target,
  };
});

HintsLayerStore.on(hideSingleHint, (State) => {
  return {
    ...State,
    activeSingleHint: undefined,
    activeSingleHintDisplayConfig: undefined,
    activeSingleHintTarget: undefined,
  };
});

export const closeSingleHintForSession = createEvent(
  'closeSingleHintForSession'
);
closeSingleHintForSession.watch(() => {
  AuthService.getInstance().setSessionStorageValue(
    'WALLESTER_INIT_HINTS_CLOSED',
    '1'
  );
});

export default HintsLayerStore;
