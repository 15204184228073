import {MassPaymentsFile} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const getMassPaymentsFiles: DemoApiHandler = ({config, instance, url}) => {
  let items = instance.getData().massPaymentsFiles[
    instance.getData().productId
  ];
  const total = items.length;

  items = Demo.applySorterAndPager(
    items,
    Demo.toListSortOrderParams(url.queryParams)
  );

  return Demo.makeDataResponse<{
    payment_files: MassPaymentsFile[];
    total_records_number: number;
  }>(config, {
    payment_files: items,
    total_records_number: total,
  });
};

export default getMassPaymentsFiles;
