import {TopUpHistoryItem} from '../../types/statement';
import Demo, {DemoApiHandler} from '../demo';

const getTopUpHistory: DemoApiHandler = ({config, instance, url}) => {
  let items = instance.getData().topUpHistory[instance.getData().productId];
  const total = items.length;

  items = Demo.applySorterAndPager(
    items,
    Demo.toListSortOrderParams(url.queryParams)
  );

  return Demo.makeDataResponse<{
    top_up_history: TopUpHistoryItem[];
    total_records_number: number;
  }>(config, {
    top_up_history: items,
    total_records_number: total,
  });
};

export default getTopUpHistory;
