import moment from 'moment-timezone';
import {ListPeriodFilterPreset} from '../types';

export const getDatePresets = (): ListPeriodFilterPreset[] => {
  return [
    {
      key: 'today',
      label: 'App-Today',
      period: [moment(), moment()],
    },
    {
      key: 'yesterday',
      label: 'App-Yesterday',
      period: [
        moment().startOf('day').subtract(1, 'day'),
        moment().subtract(1, 'day'),
      ],
    },
    {
      key: 'this_week',
      label: 'App-This_Week',
      period: [moment().startOf('week'), moment().endOf('week')],
    },
    {
      key: 'last_week',
      label: 'App-Last_Week',
      period: [
        moment().subtract(1, 'week').startOf('week'),
        moment().subtract(1, 'week').endOf('week'),
      ],
    },
    {
      key: 'this_month',
      label: 'App-This_Month',
      period: [moment().startOf('month'), moment().endOf('month')],
    },
    {
      key: 'last_month',
      label: 'App-Last_Month',
      period: [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    },
    {
      key: 'this_year',
      label: 'App-This_Year',
      period: [moment().startOf('year'), moment().endOf('year')],
    },
    {
      key: 'custom',
      label: 'App-Custom',
      period: [moment(), moment()],
      inf: true,
    },
  ];
};

export default getDatePresets;
