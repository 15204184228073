import {AuthChallenge} from './auth';
import {FormErrors, FormStore} from './forms';
import {CountryCodes3l} from './geo';
import {IPhone} from './phone';

export type AuthParams = {
  mobile: IPhone;
  email: string;
  code: string;
  affiliate_hash?: string;
  company_name?: string;
  country_code?: CountryCodes3l;
  ga_id?: string;
  captcha_token?: string;
};

export type AuthFormErrors = FormErrors<AuthParams>;
export const AuthFormModes = [
  'init',
  'phone',
  'phone_code',
  'email',
  'email_code',
] as const;
export type AuthFormMode = typeof AuthFormModes[number];
export type AuthFormErrorType =
  | 'requests_exceeded'
  | 'to_frequent_requests'
  | 'need_signup'
  | 'email_cant_change'
  | 'invalid_token';
export type AuthState = FormStore<AuthParams, AuthFormErrors> & {
  mode: AuthFormMode;
  challenges: AuthChallenge[];
  currentChallenge?: AuthChallenge;
  authToken?: string;
  maskedEmail?: string;
  maskedMobile?: string;
  attemptsLeft?: number;
  challengesAttemptsLeft?: number;
  nextAttemptAt?: number;
  loading?: boolean;
  registrationMode?: boolean;
  canEditEmail: boolean;
  affiliateHash?: string;
  seoSource?: string;
  errorType?: AuthFormErrorType;
  accessToken?: string;
  refreshToken?: string;
  companyName?: string;
  countryCode?: CountryCodes3l;
  gaId?: string;
  gcId?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
};

export type AffiliateDeviceType = 'mobile' | 'regular';

export type PhoneVerifyParams = {
  phone: IPhone;
  code: string;
};
