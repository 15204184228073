import moment from 'moment-timezone';
import {StatementMerchantTypes, StatementStatuses} from '../constants/accounts';
import {MerchantCategoriesType} from '../constants/authorization';
import {CountryCodes3l} from './geo';
import {IAccountStatementFilters, OrderBy, PagerProps, UUID4} from './common';
import {CurrencyType} from './currency';
import {Available, ECardSecuritySettingsKeys, Usage} from './card';
import {Limits} from './limits';
import {IGetUsersInfoResponse} from './users';
import {ICustomerAccountsFilters} from './customer';
import {IStatementAuthorization, IStatementTransaction} from './statement';

export type TAccountCloseReason =
  | 'ClosedByClient'
  | 'ClosedByIssuer'
  | 'ClosedBySystem';

export enum EAccountStatuses {
  ACTIVE = 'Active',
  CLOSED = 'Closed',
  CLOSING = 'Closing',
  BLOCKED = 'Blocked',
}

export type TAccountStatus = `${EAccountStatuses}`;

export const AccountStatusLabels: Record<TAccountStatus, string> = {
  Active: 'App-Account_Active',
  Closed: 'App-Account_Closed',
  Closing: 'App-Account_Closing',
  Blocked: 'App-Account_Blocked',
};

export type TAccountAdjustment = '';

export type AccountLimits = Limits;

export interface AccountLimitsWithUsage
  extends AccountLimits,
    Usage,
    Available {
  //
}

export type AccountSecurityKeys = keyof AccountLimits;

export interface AccountSecuritySettings {
  [ECardSecuritySettingsKeys.ContactlessEnabled]: boolean;
  [ECardSecuritySettingsKeys.WithdrawalEnabled]: boolean;
  [ECardSecuritySettingsKeys.InternetPurchaseEnabled]: boolean;
}

export type AccountSecuritySettingsKeys = keyof AccountSecuritySettings;

export type TopUpDetails = {
  bank_address: string;
  bank_name: string;
  iban: string;
  payment_details: string;
  receiver_name: string;
  registration_number: string;
  swift_code: string;
};

export interface IAccount {
  available_amount: number;
  balance: number;
  blocked_amount: number;
  close_reason?: TAccountCloseReason;
  closed_at?: string;
  closed_by?: UUID4;
  created_at: string;
  company_id?: UUID4;
  credit_limit: number;
  currency_code: CurrencyType;
  external_id?: string;
  id: UUID4;
  limits: AccountLimitsWithUsage;
  name: string;
  product_id?: string;
  product_name?: string;
  person_id?: UUID4;
  status: TAccountStatus;
  updated_at?: string;
  used_credit: number;
  is_main: boolean;
  cards_count?: number;
  iban?: string;
  top_up_details?: TopUpDetails;
  viban?: string;
  reference_number?: number;
}

export interface IAccounts {
  [key: string]: IAccount | null;
}

export interface IAccountList extends PagerProps, OrderBy {
  items: IAccount[];
  totalItems: number;
}

export interface IAccountListResponse {
  accounts: IAccount[];
  total_records_number?: number;
}

export interface IAccountResponse {
  account: IAccount;
}

export interface ICustomerAccountsRequest
  extends PagerProps,
    ICustomerAccountsFilters {
  customerId: UUID4;
  isActive?: boolean;
}

export interface IAccountDetailsConfigItem {
  key: string;
  value: string | number | boolean;
}

export interface IAccountsInfoDetailsConfig {
  dataMain: IAccountDetailsConfigItem[];
  dataDetails: IAccountDetailsConfigItem[];
}

export enum EAccountStatementTypes {
  AccountAdjustment = 'AccountAdjustment',
  Authorization = 'Authorization',
  Fee = 'Fee',
  Transaction = 'Transaction',
}

export type TAccountStatementTypes = `${EAccountStatementTypes}`;

export type MerchantFilterType = typeof StatementMerchantTypes[number];

export type AccountStatementGroup =
  | 'AccountTransferFee'
  | 'AdditionalBINConfigurationFee'
  | 'AdditionalBINMaintenanceFee'
  | 'AdditionalVirtualCardsFee'
  | 'AuthorizationATMBalanceInquiryFixedFee'
  | 'AuthorizationATMDeclinedFixedFee'
  | 'AuthorizationATMWithdrawalComplexFee'
  | 'AuthorizationATMWithdrawalEEAComplexFee'
  | 'AuthorizationATMWithdrawalFixedFee'
  | 'AuthorizationATMWithdrawalInternationalComplexFee'
  | 'AuthorizationATMWithdrawalPercentageFee'
  | 'AuthorizationDeclinedFixedFee'
  | 'AuthorizationEEAFixedFee'
  | 'AuthorizationForeignExchangePercentageFee'
  | 'AuthorizationInternationalFixedFee'
  | 'AvailableFundsRefundFee'
  | 'CardDeliveryFixedFee'
  | 'CardExpressDeliveryFixedFee'
  | 'CardIssuanceFixedFee'
  | 'CardRenewalFixedFee'
  | 'CardReplacementFixedFee'
  | 'CardUsageFixedFee'
  | 'ChargebackProcessingFee'
  | 'DedicatedBINRangeFee'
  | 'Deposit'
  | 'ExclusiveBINFee'
  | 'ExclusiveSharedBINFee'
  | 'IncomingSEPAPaymentFixedFee'
  | 'IncomingSEPAPaymentPercentageFee'
  | 'IndustryFee'
  | 'InternetPurchase'
  | 'MerchantWhitelistFee'
  | 'MonthlyFeePerEmployee'
  | 'MonthlyServiceFee'
  | 'NewEmployeeAdditionFee'
  | 'Other'
  | 'OtherAdministrativeRequestFee'
  | 'PINChangeFixedFee'
  | 'PINManagement'
  | 'PaymentEEAComplexFee'
  | 'PaymentEEAFixedFee'
  | 'PaymentInternationalComplexFee'
  | 'PaymentInternationalFixedFee'
  | 'PaymentPercentageFee'
  | 'PricingPlanFee'
  | 'Purchase'
  | 'Refund'
  | 'Remind3DSPasswordFixedFee'
  | 'RemindPINFixedFee'
  | 'SharedBINRangeFee'
  | 'Withdraw'
  | 'ZeroAuthorizationEEAFixedFee'
  | 'ZeroAuthorizationInternationalFixedFee';

export type MerchantTypeCategoryInfo = {
  categories: MerchantCategoriesType[];
  title_code: string;
};

export type StatementStatus = typeof StatementStatuses[number];
export type AccountStatementSubType =
  | 'AccountClosureFee'
  | 'AccountToAccountTransfer'
  | 'AccountTransferFee'
  | 'AdditionalBINConfigurationFee'
  | 'AdditionalBINMaintenanceFee'
  | 'AdditionalVirtualCardsFee'
  | 'Adjustment'
  | 'AuthorizationATMBalanceInquiryFixedFee'
  | 'AuthorizationATMDeclinedFixedFee'
  | 'AuthorizationATMWithdrawalComplexFee'
  | 'AuthorizationATMWithdrawalEEAComplexFee'
  | 'AuthorizationATMWithdrawalFixedFee'
  | 'AuthorizationATMWithdrawalInternationalComplexFee'
  | 'AuthorizationATMWithdrawalPercentageFee'
  | 'AuthorizationDeclinedFixedFee'
  | 'AuthorizationEEAFixedFee'
  | 'AuthorizationForeignExchangePercentageFee'
  | 'AuthorizationInternationalFixedFee'
  | 'AvailableFundsRefundFee'
  | 'CardDeliveryFixedFee'
  | 'CardExpressDeliveryFixedFee'
  | 'CardIssuanceFixedFee'
  | 'CardRenewalFixedFee'
  | 'CardReplacementFixedFee'
  | 'CardTopUp'
  | 'CardUsageFixedFee'
  | 'Cashback'
  | 'ChargebackProcessingFee'
  | 'DedicatedBINRangeFee'
  | 'ExclusiveBINFee'
  | 'ExclusiveSharedBINFee'
  | 'IncomingSEPAPayment'
  | 'IncomingSEPAPaymentFixedFee'
  | 'IncomingSEPAPaymentPercentageFee'
  | 'IndustryFee'
  | 'MerchantWhitelistFee'
  | 'MonthlyFeePerEmployee'
  | 'MonthlyServiceFee'
  | 'NewEmployeeAdditionFee'
  | 'OtherAdministrativeRequestFee'
  | 'PINChangeFixedFee'
  | 'PaymentEEAComplexFee'
  | 'PaymentEEAFixedFee'
  | 'PaymentInternationalComplexFee'
  | 'PaymentInternationalFixedFee'
  | 'PaymentPercentageFee'
  | 'PricingPlanFee'
  | 'ReceiveEmployeeTransfer'
  | 'ReceiveMobileTransfer'
  | 'Remind3DSPasswordFixedFee'
  | 'RemindPINFixedFee'
  | 'SendEmployeeTransfer'
  | 'SendMobileTransfer'
  | 'SharedBINRangeFee'
  | 'ZeroAuthorizationEEAFixedFee'
  | 'ZeroAuthorizationInternationalFixedFee';
export type AccountStatementResponse = 'Approved' | 'Declined';
export interface IAccountStatement {
  id: UUID4;
  type: TAccountStatementTypes;
  group: AccountStatementGroup;
  date: string;
  transaction_amount: number;
  transaction_currency_code: CurrencyType;
  account_amount: number;
  account_currency_code: CurrencyType;
  has_payment_document_files: boolean;
  has_payment_notes?: boolean;
  response: AccountStatementResponse;
  is_reversal: boolean;
  is_declined: boolean;
  is_cleared: boolean;
  merchant_name: string;
  purchase_date: string;
  total_amount: number;
  status: StatementStatus;

  card_id?: UUID4;
  card_name?: string;
  sub_type?: AccountStatementSubType;
  masked_card_number?: string;
  embossing_company_name?: string;
  embossing_first_name?: string;
  embossing_last_name?: string;
  embossing_name?: string;
  account_external_id?: string;
  merchant_category_code?: MerchantCategoriesType;
  merchant_id?: string;
  terminal_id?: string;
  merchant_city?: string;
  merchant_country_code?: CountryCodes3l;
  description?: string;
  original_authorization_id?: UUID4;
  marked_for_dispute_at?: string;
  marked_for_dispute_by?: string;
  response_code?: string;
  exchange_rate?: number;
  enriched_merchant_data?: {
    name?: string;
    url?: string;
    domain?: string;
    telephone_number?: string;
    icon_url?: string;
  };
}

export type IAccountStatementExt =
  | (IAccountStatement & IStatementAuthorization)
  | (IAccountStatement & IStatementTransaction);

export interface IAccountStatementList {
  items: IAccountStatement[];
  totalItems: number;
  creditTurnover?: number;
  debitTurnover?: number;
  pendingAmount?: number;
}

export interface AccountTransactionsRequestParams extends OrderBy {
  accountId: UUID4;
  currentPage?: number;
  fromRecord?: number;
  pageSize: number;
  fromDate: moment.Moment;
  toDate: moment.Moment;
  statuses?: StatementStatus[];
  merchantTypes?: MerchantFilterType[];
  includeTransactions?: boolean;
  includeAuthorizations?: boolean;
  includeAccountAdjustments?: boolean;
  includeFees?: boolean;
  excludeDeclinedAuthorizations?: boolean;
  excludeReversedAuthorizations?: boolean;
  excludeClearedAuthorizations?: boolean;
  adjustmentType?: AccountStatementSubType[];
  hasPaymentDocumentFiles?: boolean;
  hasPaymentNotes?: boolean;
}

export interface GetAccountTransactionsResponse {
  items: IAccountStatement[];
  totalItems: number;
  pendingAmount?: number;
  openingBalance?: number;
  debitTurnover?: number;
  creditTurnover?: number;
  hasPaymentDocumentFiles?: boolean;
}

export interface IAccountStatementRequestParams
  extends IAccountStatementFilters {
  accountId: UUID4;
  currentPage: number;
  pageSize: number;
  fromDate?: moment.Moment;
  toDate?: moment.Moment;
  orderField?: string;
  orderDirection?: string;
}

export interface IAccountStatementResponse {
  items: IAccountStatement[];
  totalItems?: number;
}

export interface IAccountHistoryItem {
  created_at: string;
  created_by: UUID4;
  created_by_info?: IGetUsersInfoResponse;
  field: string;
  new_value: string;
  old_value: string;
}

export interface IAccountHistoryItems {
  items: IAccountHistoryItem[];
  totalItems?: number;
}

export interface IAccountHistoryRequestParams {
  accountId: string;
  currentPage: number;
  pageSize: number;
  fromDate?: moment.Moment;
  toDate?: moment.Moment;
  orderField?: string;
  orderDirection?: string;
  isNotSystem?: boolean;
}

export interface IAccountDetailsStore {
  account: IAccount | null;
  loading: boolean;
  loaded?: boolean;
}

export interface IChangeAccountNameRequestParams {
  accountId: UUID4;
  name: string;
}

export interface IChangeAccountExternalIdRequestParams {
  accountId: UUID4;
  externalId: string;
}

export type AccountLimitsKeys = keyof AccountLimits;

export type UpdateAccountLimitsParams = {
  [key in keyof AccountLimits]?: number;
};

export interface IAdjustAccountBalanceReqParams {
  accountId: UUID4;
  amount: number;
  description: string;
  externalId?: string;
  senderName?: string;
}

export type CreateAccountParams = {
  credit_limit?: number;
  currency_code: CurrencyType;
  external_id: string;
  limits?: AccountLimits;
  name: string;
  person_id?: UUID4;
  company_id?: UUID4;
  used_credit?: number;
  top_up_details?: TopUpDetails;
};

export type AccountProgramType = 'Prepaid' | 'Credit' | 'Debit';

export type ProgramType = 'Business' | 'Consumer';
export type PartnerType = 'Regulated' | 'RegulatedVASP' | 'NonRegulated';

export type AccountLimitsSettings = {
  credit_limit_max_amount: number;
  default_account_daily_contactless_purchase_limit: number;
  default_account_daily_internet_purchase_limit: number;
  default_account_daily_purchase_limit: number;
  default_account_daily_withdrawal_limit: number;
  default_account_weekly_contactless_purchase_limit: number;
  default_account_weekly_internet_purchase_limit: number;
  default_account_weekly_purchase_limit: number;
  default_account_weekly_withdrawal_limit: number;
  default_account_monthly_contactless_purchase_limit: number;
  default_account_monthly_internet_purchase_limit: number;
  default_account_monthly_purchase_limit: number;
  default_account_monthly_withdrawal_limit: number;
  max_account_daily_contactless_purchase_limit: number;
  max_account_daily_internet_purchase_limit: number;
  max_account_daily_purchase_limit: number;
  max_account_daily_withdrawal_limit: number;
  max_account_weekly_contactless_purchase_limit: number;
  max_account_weekly_internet_purchase_limit: number;
  max_account_weekly_purchase_limit: number;
  max_account_weekly_withdrawal_limit: number;
  max_account_monthly_contactless_purchase_limit: number;
  max_account_monthly_internet_purchase_limit: number;
  max_account_monthly_purchase_limit: number;
  max_account_monthly_withdrawal_limit: number;
  program_type: AccountProgramType;
  weekly_limits_enabled: boolean;
};

export interface IUpdateAccountCreditParams {
  accountId: UUID4;
  creditLimit: number;
  description: string;
}

export interface IUpdateAccountTopUpDetailsParams {
  accountId: UUID4;
  params: {
    iban: string;
    swift_code: string;
    receiver_name: string;
    payment_details: string;
    bank_name: string;
    bank_address: string;
    registration_number: string;
  };
}

export type AccountCloseReason =
  | 'ClosedByCardholder'
  | 'ClosedByClient'
  | 'ClosedByIssuer'
  | 'ClosedBySystem';

export type AllowedTopUpMethods = 'Card' | 'WireTransfer';

export type AllowedTopUpMethodsInfo = {
  card: {
    enabled: boolean;
    allowed: boolean;
    is_bank_confirmed: boolean;
  };
  wire_transfer: {
    enabled: boolean;
    allowed: boolean;
  };
};
