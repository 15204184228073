import {useStoreMap} from 'effector-react';
import {useMemo} from 'react';
import Features, {
  ClientFeatures,
  ProductFeatures,
  TreatmentStatus,
  UserFeatures,
} from '../constants/features';
import FeatureStore from '../models/features';
import {TreatmentsWithConfig, TreatmentWithConfig} from '../types';
import Demo from '../utils/demo';
import useConfig from './useConfig';

const demoTreatments = Demo.getTreatments();

export const useCheckFeatures = (
  feature?: Features,
  defaultState?: boolean
): TreatmentWithConfig | undefined => {
  const {splitIOApiKey, demo} = useConfig();

  const {
    common,
    clientIsReady,
    commonIsReady,
    productIsReady,
    userIsReady,
  } = useStoreMap({
    store: FeatureStore,
    keys: [
      'treatments',
      'userIsReady',
      'clientIsReady',
      'productIsReady',
      'commonIsReady',
    ],
    fn: (state) => {
      return {
        common: state.treatments,
        userIsReady: state.userIsReady,
        clientIsReady: state.clientIsReady,
        productIsReady: state.productIsReady,
        commonIsReady: state.commonIsReady,
      };
    },
  });

  const treatmentsSource = useMemo<TreatmentsWithConfig>(() => {
    if (!feature) {
      return {};
    }

    return common || {};
  }, [common, feature]);

  const treatmentsIsReady = useMemo<boolean>(() => {
    if (!feature) {
      return true;
    }
    if (ProductFeatures[feature]) {
      return productIsReady;
    }
    if (ClientFeatures[feature]) {
      return clientIsReady;
    }
    if (UserFeatures[feature]) {
      return userIsReady;
    }
    return commonIsReady;
  }, [feature, commonIsReady, productIsReady, clientIsReady, userIsReady]);

  const treatments = useMemo(() => {
    if (demo) {
      return {
        ...demoTreatments,
        [Features.ABTestingRegistration]:
          treatmentsSource[Features.ABTestingRegistration],
        [Features.CaptchaOnSignIn]: treatmentsSource[Features.CaptchaOnSignIn],
      };
    }
    return treatmentsSource;
  }, [demo, treatmentsSource]);

  return useMemo<TreatmentWithConfig | undefined>(() => {
    if (!feature) {
      return {
        treatment: TreatmentStatus.Enabled,
        config: null,
      };
    }
    if (!treatmentsIsReady && splitIOApiKey) {
      return {
        treatment: defaultState
          ? TreatmentStatus.Enabled
          : TreatmentStatus.Disabled,
        config: null,
      };
    }

    if (treatments[feature]) {
      return treatments[feature];
    }

    return undefined;
  }, [feature, treatmentsIsReady, splitIOApiKey, treatments, defaultState]);
};

export default useCheckFeatures;
