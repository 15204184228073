import {createEvent, createStore} from 'effector';

type MessengerStoreType = {
  init: boolean;
  visible: boolean;
  active: boolean;
  unreadMessages: number;
};

export const MessengerStore = createStore<MessengerStoreType>({
  visible: false,
  init: false,
  active: true,
  unreadMessages: 0,
});

export const setMessengerInitState = createEvent<boolean>(
  'setMessengerInitState'
);

MessengerStore.on(setMessengerInitState, (state, init) => {
  return {
    ...state,
    init,
  };
});

export const setMessengerVisibleState = createEvent<boolean>(
  'setMessengerVisibleState'
);

MessengerStore.on(setMessengerVisibleState, (state, visible) => {
  return {
    ...state,
    visible,
  };
});

export const setMessengerActiveState = createEvent<boolean>(
  'setMessengerActiveState'
);

MessengerStore.on(setMessengerActiveState, (state, active) => {
  return {
    ...state,
    active,
  };
});

export const setMessengerUnreadMessages = createEvent<number>(
  'setMessengerUnreadMessages'
);

MessengerStore.on(setMessengerUnreadMessages, (state, unreadMessages) => {
  return {
    ...state,
    unreadMessages,
  };
});

export const resetMessenger = createEvent('resetMessenger');

MessengerStore.reset(resetMessenger);

export default MessengerStore;
