import packageJson from '../../package.json';
import {AppConfig} from '../types';
import {toNumber} from '../utils/formatters';
import {ONE_SECOND} from './dateTime';

const isProdBuild = process.env.NODE_ENV === 'production';
const isDevBuild = process.env.NODE_ENV === 'development';
const isProd = process.env.REACT_APP_ENV === 'production';
const isSandbox = process.env.REACT_APP_ENV === 'sandbox';
const isStaging = process.env.REACT_APP_ENV === 'staging';
const isDev = process.env.REACT_APP_ENV === 'development';
const parseEnvToBoolean = (value?: string) =>
  value !== undefined ? Boolean(parseInt(value, 10)) : false;

const buildNumber: string = process.env.REACT_APP_BUILD_COMMIT || '1';

const CURRENCY_REQUEST_TTL = 60 * 60 * 24;

const config: AppConfig = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  apiAuthUrl: process.env.REACT_APP_API_AUTH_URL || '',
  apiWhiteLabelUrl: process.env.REACT_APP_API_WHITE_LABEL_URL || '',
  httpTimeout: +(process.env.REACT_APP_HTTP_TIMEOUT || ONE_SECOND * 30),
  locales: [
    'en',
    'es',
    'fr',
    'de',
    'pt',
    'it',
    'ru',
    'pl',
    'nl',
    'cs',
    'sv',
    'da',
    'fi',
    'lv',
    'et',
    'lt',
  ],
  requestCacheTTL: toNumber(process.env.REACT_APP_REQUEST_CACHE_TTL || 3),
  version: packageJson.version,
  buildNumber,
  commit: buildNumber,
  buildDate: '',
  branch: process.env.REACT_APP_BUILD_BRANCH || '',
  isProdBuild,
  isDevBuild,
  isDev,
  isSandbox,
  isStaging,
  isProd,
  appEnv: process.env.REACT_APP_ENV || 'development',
  apiTimeout: 60000,
  maxChunkRetryAttempts: 10,
  chunkRetryTimeout: 1000,
  requestInterval: 200,
  siteDomain: process.env.REACT_APP_SITE_DOMAIN || 'sb.loc',
  rollbarToken: process.env.REACT_APP_ROLLBAR_TOKEN || null,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN || '',
  sentryEnv: process.env.REACT_APP_SENTRY_ENV || 'unspecified',
  secretPublicKeyUpdate: process.env.REACT_APP_UPDATE_PIN_PUB_KEY || '',
  secretPublicKeyCreate: process.env.REACT_APP_CREATE_PIN_PUB_KEY || '',
  rollbarEnable:
    parseEnvToBoolean(process.env.REACT_APP_ROLLBAR_ENABLED) || false,
  sentryEnable:
    parseEnvToBoolean(process.env.REACT_APP_SENTRY_ENABLED) || false,
  sentryAutoSessionTracking:
    parseEnvToBoolean(process.env.REACT_APP_SENTRY_SESSION_TRACKING) || false,
  hasMissingTranslation:
    parseEnvToBoolean(process.env.REACT_APP_HAS_MISSING_TRANSLATIONS) || false,
  developersUrl:
    process.env.REACT_APP_DEV_URL ||
    'https://api-frontend.wallester.com/v1/doc/',
  supportEmail: 'support@wallester.com',
  businessEmail: 'business@wallester.com',
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '',
  googleAnalyticsV4Id: process.env.REACT_APP_GOOGLE_ANALYTICS_V4_ID || '',
  gtm: process.env.REACT_APP_GTM || '',
  linkedInId: process.env.REACT_APP_LINKEDIN_ID || '',
  wallesterTariffPlans:
    'https://business.wallester.com/pricing?popup=price_list',
  allowDowngradePricingPlan: true,
  tablePageSizes: [10, 20, 50, 100],
  apiDocLink: 'https://api-frontend.wallester.com/v1/doc/#section/Introduction',
  jwtDocLink: 'https://jwt.io/introduction/',
  trackingLinkDHL: 'http://www.dhl.com/en/express/tracking.html?AWB={link}',
  wallesterFrontUrl:
    process.env.REACT_APP_FRONT_URL || 'https://business.wallester.biz',
  authCookieDomain:
    process.env.REACT_APP_AUTH_COOKIE_DOMAIN || '.wallester.biz',
  reCaptchaKey: process.env.REACT_APP_RE_CAPTCHA_KEY || '',
  legalNoticeLink: 'https://business.wallester.com/legal-notice',
  privacyLink: 'https://business.wallester.com/privacy',
  cardAgreementLink:
    'https://business.wallester.com/business-account-card-agreement',
  supportPhone: '+37254280990',
  supportWhatsAppPhone: '+37254280990',
  supportTelegramPhone: '+37254280990',
  supportViberPhone: '+37254280990',
  affiliateProgramUrl: 'https://wallester.com/affiliate-program',
  splitIOApiKey: process.env.REACT_APP_SPLIT_IO_API_KEY || '',
  currencyRequestTTL: toNumber(
    process.env.REACT_APP_CURRENCY_REQUEST_TTL || CURRENCY_REQUEST_TTL
  ),
  designChangingDuration: 5000,
  messengerDisabled:
    parseEnvToBoolean(process.env.REACT_APP_MESSENGER_DISABLED) || false,
  messengerConfig: {},
  messengerKeyId: process.env.REACT_APP_MESSENGER_KEY || '',
  isUseTestId: parseEnvToBoolean(process.env.REACT_APP_USE_TEST_ID) || false,
  demo: parseEnvToBoolean(process.env.REACT_APP_DEMO_MODE),
  signInLink: `//${process.env.REACT_APP_SITE_DOMAIN}/sign-in`,
  registrationLink: `//${process.env.REACT_APP_SITE_DOMAIN}/sign-up`,
  registrationCommonLink:
    process.env.REACT_APP_DEMO_REGISTRATION_COMMON_LINK ||
    'https://business.wallester.com/atrk?c=5fa4174f-a4ba-403f-8644-208e0942cd47&promo=direct_link',
  intercomWhiteListArticleID: '123789',
  friendlyCaptchaApiKey:
    process.env.REACT_APP_FRIENDLY_CAPTCHA_SITE_KEY || '___',
};

export default config;
