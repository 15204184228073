import {useUnit} from 'effector-react';
import React, {useCallback, useMemo} from 'react';
import Permissions from '../../../../constants/permissions';
import useCheckPermissions from '../../../../customHooks/useCheckPermissions';
import SystemDialogNotificationsStateStore, {
  closeSystemNotificationDialog,
} from '../../../../models/systemDialogNotifications';
import SYSTEM_NOTIFICATION_DIALOG_TYPES from '../../../../types/systemDialogNotifications';
import ClientFrozenModal from './NotificationModals/ClientFrozenModal';
import SalaryProgramModal from './NotificationModals/SalaryProgramModal';
import TutorialWelcomeModal from './NotificationModals/TutorialWelcomeModal';
import Verification3DSModal from './NotificationModals/Verification3DSModal';
import ProductDebtModal from './NotificationModals/ProductDebtModal';

const DialogNotificationsManager: React.FC = () => {
  const isAccountsViewAllowed = useCheckPermissions(
    Permissions.PermissionViewAccounts
  ); // TODO: Remove crutch
  const {activeDialog} = useUnit(SystemDialogNotificationsStateStore);

  const handleClose = useCallback(
    (
      dialog: SYSTEM_NOTIFICATION_DIALOG_TYPES,
      params?: Record<string, unknown>
    ) => {
      closeSystemNotificationDialog({dialog, params});
    },
    []
  );

  const frozenModal = useMemo(() => {
    return (
      <ClientFrozenModal
        onClose={() => {
          handleClose(SYSTEM_NOTIFICATION_DIALOG_TYPES.FROZEN_CLIENT);
        }}
      />
    );
  }, [handleClose]);

  const tutorialWelcomeModal = useMemo(() => {
    return (
      <TutorialWelcomeModal
        onClose={(status) => {
          handleClose(SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL, {
            close: status,
          });
        }}
      />
    );
  }, [handleClose]);

  const salaryProgramModal = useMemo(() => {
    return (
      <SalaryProgramModal
        onClose={(status) => {
          handleClose(SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM, {
            full_show: status ? '1' : '0',
          });
        }}
      />
    );
  }, [handleClose]);

  const verification3DSModal = useMemo(() => {
    return (
      <Verification3DSModal
        onClose={() => {
          handleClose(SYSTEM_NOTIFICATION_DIALOG_TYPES.VERIFICATION_3DS);
        }}
      />
    );
  }, [handleClose]);

  const productDebtModal = useMemo(() => {
    return (
      <ProductDebtModal
        onClose={() => {
          handleClose(SYSTEM_NOTIFICATION_DIALOG_TYPES.PRODUCT_DEBTOR);
        }}
      />
    );
  }, [handleClose]);

  if (
    !isAccountsViewAllowed &&
    activeDialog !== SYSTEM_NOTIFICATION_DIALOG_TYPES.FROZEN_CLIENT
  ) {
    return null;
  }

  return (
    <>
      {activeDialog === SYSTEM_NOTIFICATION_DIALOG_TYPES.FROZEN_CLIENT &&
        frozenModal}
      {activeDialog === SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM &&
        salaryProgramModal}
      {activeDialog === SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL &&
        tutorialWelcomeModal}
      {activeDialog === SYSTEM_NOTIFICATION_DIALOG_TYPES.VERIFICATION_3DS &&
        verification3DSModal}
      {activeDialog === SYSTEM_NOTIFICATION_DIALOG_TYPES.PRODUCT_DEBTOR &&
        productDebtModal}
    </>
  );
};

export default DialogNotificationsManager;
