import React from 'react';
import {RouteProps, Switch} from 'react-router';
import {useParams} from 'react-router-dom';
import {AvailableLanguages, DefaultLanguage, UserLocale} from '../../types';

const LocalizedSwitch: React.FC<React.PropsWithChildren> = ({children}) => {
  let {lang} = useParams<{lang: string}>();
  if (!AvailableLanguages.includes(lang as UserLocale)) {
    lang = DefaultLanguage;
  }
  return (
    <Switch>
      {React.Children.map(children, (child) => {
        if (React.isValidElement<RouteProps>(child)) {
          return React.cloneElement(child, {
            ...child.props,
            path: `/${lang}${child.props.path}`,
          });
        }
        return child;
      })}
    </Switch>
  );
};

export default LocalizedSwitch;
