export enum WLEndpoints {
  assignedProduct = 'GET:/v1/assigned-products',
  getCurrentUser = 'GET:/v1/user',
  getProductSettings = 'GET:/v1/product-settings',
  getServerStorageData = 'GET:/v1/storage',
  setServerStorageData = 'POST:/v1/storage',
  getReservedSettlementBalance = 'GET:/v1/reserved-settlement-balance',
  getAccount = 'GET:/v1/accounts/{account_id}',
  getAccounts = 'GET:/v1/accounts',
  getClientSupportManagers = 'GET:/v1/client/support-managers',
  getExpiringCards = 'GET:/v1/expiring-cards',
  getPendingCards = 'GET:/v1/pending-orders',
  getFilesDirectories = 'GET:/v1/extract-files',
  getFilesListInDirectory = 'GET:/v1/extract-files/{directory_name}',
  downloadFileByName = 'GET:/v1/extract-files/{directory_name}/{file_name}',
  getCustomers = 'GET:/v1/persons',
  updateUserSelfPassword = 'PATCH:/v1/user/update-password',
  getUser = 'GET:/v1/users/{user_id}',
  lockUser = 'PATCH:/v1/users/{user_id}/lock',
  unlockUser = 'PATCH:/v1/users/{user_id}/unlock',
  deleteUser = 'DELETE:/v1/users/{user_id}',
  setUserRole = 'POST:/v1/users/{user_id}/profile-role',
  getUserHistory = 'GET:/v1/users/{user_id}/history',
  updateUserImage = 'PATCH:/v1/users/{user_id}/profile-image',
  updateUserProfile = 'PATCH:/v1/users/{user_id}/profile',
  updateUserSelfProfile = 'PATCH:/v1/user',
  getUsersInfo = 'GET:/v1/users/info',
  getUsers = 'GET:/v1/users',
  createUser = 'POST:/v1/users',
  search = 'GET:/v1/search',
  getRolesList = 'GET:/v1/roles',
  getProductCards = 'GET:/v1/product-cards',
  getSummaryTransactions = 'GET:/transactions/summary',
  createRole = 'POST:/v1/roles',
  getRoleDetails = 'GET:/v1/roles/{role_id}',
  deleteRole = 'DELETE:/v1/roles/{role_id}',
  updateRole = 'PATCH:/v1/roles/{role_id}',
  getCompanyRepresentative = 'GET:/v1/companies/{company_id}/representatives/{representative_id}',
  deleteCompanyRepresentative = 'DELETE:/v1/companies/{company_id}/representatives/{representative_id}',
  getCompanyRepresentatives = 'GET:/v1/companies/{company_id}/representatives',
  createCompanyRepresentative = 'POST:/v1/companies/{company_id}/representatives',
  getCompanyVerificationConfig = 'GET:/v1/companies/verification-config',
  getFraudCase = 'GET:/v1/fraud-cases/{fraud_case_id}',
  updateFraudCase = 'PUT:/v1/fraud-cases/{fraud_case_id}',
  getFraudCases = 'GET:/v1/fraud-cases',
  getFraudCaseCardOwners = 'GET:/v1/fraud-cases/card-owners',
  getFraudHistory = 'GET:/v1/fraud-cases/{fraud_case_id}/history',
  downloadFrauds = 'GET:/v1/fraud-cases/report',
  customerAccounts = 'GET:/v1/persons/{person_id}/accounts',
  customerHistory = 'GET:/v1/persons/{person_id}/history',
  customerCards = 'GET:/v1/persons/{person_id}/cards',
  customerCardsBlockCalls = 'GET:/v1/persons/{person_id}/card-blocks',
  getCompany = 'GET:/v1/companies/{company_id}',
  getCompanyHistory = 'GET:/v1/companies/{company_id}/history',
  getCompanyCards = 'GET:/v1/companies/{company_id}/cards',
  getCompanyAccounts = 'GET:/v1/companies/{company_id}/accounts',
  createCompany = 'GET:/v1/companies',
  updateCompany = 'PATCH:/v1/companies/{company_id}',
  deactivateCompany = 'DELETE:/v1/companies/{company_id}',
  getCompanies = 'GET:/v1/companies',
  enrolCard = 'PATCH:/v1/cards/{card_id}/3d-secure',
  unenrolCard = 'DELETE:/v1/cards/{card_id}/3d-secure',
  createCard = 'POST:/v1/cards',
  send3DSOtp = 'POST:/v1/test/cards/{card_id}/3ds-otp',
  updateCardSecurity = 'PATCH:/v1/cards/{card_id}/security',
  getAuthorizationAdditionalInfo = 'GET:/v1/authorizations/{authorization_id}/additional-info',
  customer = 'GET:/v1/persons/{person_id}',
  deactivateCustomer = 'DELETE:/v1/persons/{person_id}',
  updateCustomer = 'PATCH:/v1/persons/{person_id}',
  createCustomer = 'POST:/v1/persons',
  getAuthorization = 'GET:/v1/authorizations/{authorization_id}',
  getCard = 'GET:/v1/cards/{card_id}',
  isCanRenewCard = 'GET:/v1/cards/{card_id}/is-card-renewal-allowed',
  updateCardName = 'PATCH:/v1/cards/{card_id}/name',
  addCardUser = 'POST:/v1/cards/{card_id}/users',
  getCardNumber = 'POST:/v1/cards/{card_id}/encrypted-card-number',
  getCardCvvCode = 'POST:/v1/cards/{card_id}/encrypted-cvv2',
  getCardPinCode = 'POST:/v1/cards/{card_id}/encrypted-pin',
  getCard3dsPassword = 'POST:/v1/cards/{card_id}/encrypted-3ds-password',
  updateCardLimit = 'PATCH:/v1/cards/{card_id}/limits',
  remind3DSPassword = 'PUT:/v1/cards/{card_id}/3ds-password',
  remindCardPin = 'PUT:/v1/cards/{card_id}/pin',
  updateCardStatus = 'PATCH:/v1/cards/{card_id}/status',
  updateCardExternalId = 'PATCH:/v1/cards/{card_id}/external-id',
  getCardHistory = 'GET:/v1/cards/{card_id}/history',
  getCardTransactions = 'GET:/v1/cards/{card_id}/transactions',
  getCardStatements = 'GET:/v1/cards/{card_id}/statement',
  getCardTokens = 'GET:/v1/cards/{card_id}/tokens',
  getCardAuthorizations = 'GET:/v1/cards/{card_id}/authorizations',
  blockCard = 'PATCH:/v1/cards/{card_id}/block',
  unblockCard = 'PATCH:/v1/cards/{card_id}/unblock',
  closeCard = 'PATCH:/v1/cards/{card_id}/close',
  replaceCard = 'PATCH:/v1/cards/{card_id}/replace',
  activateCard = 'PATCH:/v1/cards/{card_id}/activate',
  updateCard3dSecureSettings = 'PATCH:/v1/cards/{card_id}/3d-secure/settings',
  linkCardToAccount = 'PATCH:/v1/cards/{card_id}/link-to-account/{account_id}',
  changeCardPin = 'PATCH:/v1/cards/{card_id}/pin',
  deleteCardUsersBatch = 'DELETE:/v1/cards/{card_id}/users',
  getCardUsers = 'GET:/v1/cards/{card_id}/users',
  createTransaction = 'POST:/v1/transactions',
  getAccountCards = 'GET:/v1/accounts/{account_id}/cards',
  createAuthorization = 'POST:/v1/test/authorizations',
  blockCardsBatch = 'PATCH:/v1/batch/cards/block',
  unblockCardsBatch = 'PATCH:/v1/batch/cards/unblock',
  closeCardsBatch = 'PATCH:/v1/batch/cards/close',
  replaceCardsBatch = 'PATCH:/v1/batch/cards/replace',
  createAccount = 'POST:/v1/accounts',
  closeAccount = 'DELETE:/v1/accounts/{account_id}/close',
  getTopUpLink = 'POST:/v1/accounts/{account_id}/generate-top-up-pdf',
  getAccountStatement = 'GET:/v1/accounts/{account_id}/statement',
  getAccountStatementFile = 'GET:/v1/accounts/{account_id}/download-statement',
  updateAccountName = 'PATCH:/v1/accounts/{account_id}/name',
  updateAccountLimits = 'PATCH:/v1/accounts/{account_id}/limits',
  adjustAccountBalance = 'PATCH:/v1/accounts/{account_id}/balance',
  updateAccountTopUpDetails = 'PATCH:/v1/accounts/{account_id}/top-up-details',
  getAccountHistory = 'GET:/v1/accounts/{account_id}/history',
  updateAccountExternalID = 'PATCH:/v1/accounts/{account_id}/external-id',
  reopenAccount = 'PATCH:/v1/accounts/{account_id}/reopen',
  getPermissions = 'GET:/v1/permissions',
  orderPromoCards = 'POST:/v1/batch/cards',
  getTransaction = 'GET:/v1/transactions/{transaction_id}',
  getCardsDispatchMethods = 'GET:/v1/cards-dispatch-methods',
}

const enum Endpoints {
  getAllowedEndpoints = 'GET:/v1/allowed-endpoints',
  getRestrictedCountries = 'GET:/v1/access-control/restricted-countries',
  signUp = '/sign-up',
  signIn = '/sign-in',
  intercomIdentityHash = 'GET:/v1/intercom/identity-hash',
  signInUpdateEmail = '/sign-in/email',
  signInCreateSession = '/sign-in/session',
  signUpAddCompany = 'POST:/v1/clients/registration',
  setupChallenge = 'GET:/v1/sign-in/challenge',
  resolveChallenge = 'POST:/v1/sign-in/challenge',
  assignedProduct = 'GET:/v1/assigned-products',
  logout = 'GET:/v1/logout',
  assignProduct = 'POST:/v1/products/{product_id}/assign',
  assignProfile = 'POST:/v1/users/profiles/{profile_id}/assign',
  refreshToken = 'POST:/v1/refresh-token',
  getCustomers = 'GET:/v1/persons',
  createCompany = 'GET:/v1/companies',
  updateCompany = 'PATCH:/v1/companies/{company_id}',
  createCard = 'POST:/v1/cards',
  createEmployeesCard = 'POST:/v1/employees',
  updateEmployeesCard = 'PATCH:/v1/employees/{employee_id}',
  isCanRenewCard = 'GET:/v1/cards/{card_id}/is-card-renewal-allowed',
  getCardDeliveryOptions = 'GET:/v1/cards-dispatch-methods',
  orderPromoCards = 'POST:/v1/batch/cards',
  createAccount = 'POST:/v1/accounts',
  getAccounts = 'GET:/v1/accounts',
  getAccountLimitsUsage = 'GET:/v1/accounts/{account_id}/limits-usage',
  getCompanies = 'GET:/v1/companies',
  getClientAccounts = 'GET:/v1/client/accounts',
  getClientTickets = 'GET:/v1/client/benefits-tickets',
  createClientTicket = 'POST:/v1/client/benefits-ticket',
  getTopUpTickets = 'GET:/v1/client/top-up-tickets',
  createTopUpTicket = 'POST:/v1/client/top-up-ticket',
  getCompanyLimitsUsage = 'GET:/v1/companies/{company_id}/limits-usage',
  downloadSummaryStatements = 'GET:/v1/accounts/download-summary-statement',
  addPaymentDocumentFiles = 'POST:/v1/payment-documents',
  getPaymentDocumentThumbnails = 'GET:/v1/payment-documents/thumbnails',
  getPaymentDocumentFile = 'GET:/v1/payment-documents/{payment_document_id}',
  downloadPaymentDocumentFiles = 'GET:/v1/payment-documents/download',
  downloadFeeInvoice = 'GET:/v1/fees/{fee_id}/invoice',
  deletePaymentDocumentFile = 'DELETE:/v1/payment-documents/{payment_document_id}',
  createUser = 'POST:/v1/users',
  ensureCompanyAccess = 'POST:/v1/company/users',
  getByMobile = 'GET:/v1/users/by-mobile',
  updateUserSelfPassword = 'PATCH:/v1/user/update-password',
  getMultiCurrencyRequestConfig = 'GET:/v1/multi-currency-request-config',
  getCard = 'GET:/v1/cards/{card_id}',
  customer = 'GET:/v1/persons/{person_id}',
  deactivateCustomer = 'DELETE:/v1/persons/{person_id}',
  updateCustomer = 'PATCH:/v1/persons/{person_id}',
  createCustomer = 'POST:/v1/persons',
  customerAccounts = 'GET:/v1/persons/{person_id}/accounts',
  customerHistory = 'GET:/v1/persons/{person_id}/history',
  customerCards = 'GET:/v1/persons/{person_id}/cards',
  customerCardsBlockCalls = 'GET:/v1/persons/{person_id}/card-blocks',
  updateCardSecurity = 'PATCH:/v1/cards/{card_id}/security',
  updateCardLimit = 'PATCH:/v1/cards/{card_id}/limits',
  remind3DSPassword = 'PUT:/v1/cards/{card_id}/3ds-password',
  remindCardPin = 'PUT:/v1/cards/{card_id}/pin',
  getCardHistory = 'GET:/v1/cards/{card_id}/history',
  getCardTransactions = 'GET:/v1/cards/{card_id}/transactions',
  getCardStatements = 'GET:/v1/cards/{card_id}/statement',
  getCardTokens = 'GET:/v1/cards/{card_id}/tokens',
  getCardAuthorizations = 'GET:/v1/cards/{card_id}/authorizations',
  getCardNotifications = 'GET:/v1/cards/{card_id}/notification-settings',
  addCardUser = 'POST:/v1/cards/{card_id}/users',
  getCardNumber = 'POST:/v1/cards/{card_id}/encrypted-card-number',
  getCardCvvCode = 'POST:/v1/cards/{card_id}/encrypted-cvv2',
  getCardPinCode = 'POST:/v1/cards/{card_id}/encrypted-pin',
  getCard3dsPassword = 'POST:/v1/cards/{card_id}/encrypted-3ds-password',
  updateCardName = 'PATCH:/v1/cards/{card_id}/name',
  blockCard = 'PATCH:/v1/cards/{card_id}/block',
  unblockCard = 'PATCH:/v1/cards/{card_id}/unblock',
  closeCard = 'PATCH:/v1/cards/{card_id}/close',
  replaceCard = 'PATCH:/v1/cards/{card_id}/replace',
  activateCard = 'PATCH:/v1/cards/{card_id}/activate',
  updateCard3dSecureSettings = 'PATCH:/v1/cards/{card_id}/3d-secure/settings',
  linkCardToAccount = 'PATCH:/v1/cards/{card_id}/link-to-account/{account_id}',
  changeCardPin = 'PATCH:/v1/cards/{card_id}/pin',
  enrolCard = 'PATCH:/v1/cards/{card_id}/3d-secure',
  changeCardNotifications = 'PATCH:/v1/cards/{card_id}/notification-settings',
  unenrolCard = 'DELETE:/v1/cards/{card_id}/3d-secure',
  getAccount = 'GET:/v1/accounts/{account_id}',
  getProductCards = 'GET:/v1/product-cards',
  deactivatePersonsBatch = 'PATCH:/v1/batch/persons/deactivate',
  search = 'GET:/v1/search',
  deleteCardUsersBatch = 'DELETE:/v1/cards/{card_id}/users',
  deleteProfilesBatch = 'DELETE:/v1/product/users',
  getEmployee = 'GET:/v1/employees/{employee_id}',
  deleteEmployee = 'DELETE:/v1/employees/{employee_id}',
  reInviteEmployee = 'PUT:/v1/employees/{employee_id}/remind',
  blockCardsBatch = 'PATCH:/v1/batch/cards/block',
  unblockCardsBatch = 'PATCH:/v1/batch/cards/unblock',
  closeCardsBatch = 'PATCH:/v1/batch/cards/close',
  replaceCardsBatch = 'PATCH:/v1/batch/cards/replace',
  getAccountCards = 'GET:/v1/accounts/{account_id}/cards',
  closeAccount = 'DELETE:/v1/accounts/{account_id}/close',
  getTopUpLink = 'POST:/v1/accounts/{account_id}/generate-top-up-pdf',
  getCardTopUpLink = 'GET:/v1/account-adjustments/{account_adjustment_id}/top-up-confirmation',
  getCardTopUpPreview = 'GET:/v1/account-adjustments/{account_adjustment_id}/top-up-confirmation-preview',
  getAccountStatement = 'GET:/v1/accounts/{account_id}/statement',
  getAccountStatementFile = 'GET:/v1/accounts/{account_id}/download-statement',
  updateAccountName = 'PATCH:/v1/accounts/{account_id}/name',
  updateAccountLimits = 'PATCH:/v1/accounts/{account_id}/limits',
  adjustAccountBalance = 'PATCH:/v1/accounts/{account_id}/balance',
  getAccountHistory = 'GET:/v1/accounts/{account_id}/history',
  getProductTransactions = 'GET:/v1/payments/transactions',
  getProductStatistics = 'GET:/v1/statistics/product',
  getProductSettings = 'GET:/v1/product-settings',
  getProductAuthorizations = 'GET:/v1/payments/authorizations',
  getCompanyHistory = 'GET:/v1/companies/{company_id}/history',
  getCompanyCards = 'GET:/v1/companies/{company_id}/cards',
  getCompanyAccounts = 'GET:/v1/companies/{company_id}/accounts',
  getCompanyInformationErrors = 'GET:/v1/client/information/verification/errors',
  getCountryCodeByIp = 'GET:/v1/geo-by-ip',
  getServerStorageData = 'GET:/v1/storage',
  setServerStorageData = 'POST:/v1/storage',
  getCommunicationLanguageCodes = 'GET:/v1/communication-language-codes',
  updateCompanyInformation = 'PUT:/v1/client/information',
  updateClientCompanyInformation = 'PATCH:/v1/client',
  signAgreement = 'POST:/v1/client/sign-agreement',
  getAgreement = 'GET:/v1/client/agreement',
  getCurrentClient = 'GET:/v1/client',
  getCorporateDocuments = 'GET:/v1/client/corporate-documents',
  deleteCompanyDocument = 'DELETE:/v1/client/corporate-documents/{document_id}',
  confirmCompanyDocuments = 'PATCH:/v1/client/corporate-documents/confirm',
  uploadCompanyDocument = 'POST:/v1/client/corporate-documents',
  uploadRequestedCompanyDocument = 'PUT:/v1/client/corporate-documents/{document_id}/upload',
  getVerificationConfig = 'GET:/v1/verification-config',
  getCompany = 'GET:/v1/companies/{company_id}',
  getCompanyRepresentative = 'GET:/v1/companies/{company_id}/representatives/{representative_id}',
  deleteCompanyRepresentative = 'DELETE:/v1/companies/{company_id}/representatives/{representative_id}',
  getCompanyRepresentatives = 'GET:/v1/companies/{company_id}/representatives',
  createCompanyRepresentative = 'POST:/v1/companies/{company_id}/representatives',
  deactivateCompany = 'DELETE:/v1/companies/{company_id}',
  getEmployees = 'GET:/v1/employees',
  updateCompanyLimits = 'PATCH:/v1/companies/{company_id}/limits',
  requestCompanyLimitChange = 'POST:/v1/send-email/raise-company-limits-request',
  send3DSOtp = 'POST:/v1/test/cards/{card_id}/3ds-otp',
  exportStatements = 'POST:/v1/statement/export',
  makeMoneyTransferToAccount = 'POST:/v1/payments/account-transfer',
  makeMoneyTransferToEmployee = 'POST:/v1/payments/employee-transfer',
  getProfiles = 'GET:/v1/product/users',
  getProductPricingPlan = 'GET:/v1/product/pricing-plan',
  setProductPricingPlan = 'POST:/v1/product/pricing-plan',
  getCardUsers = 'GET:/v1/cards/{card_id}/users',
  cancelProductPricingPlan = 'PATCH:/v1/product/pricing-plan/cancel',
  getUsers = 'GET:/v1/users',
  getCurrentUser = 'GET:/v1/user',
  getUser = 'GET:/v1/users/{user_id}',
  lockUser = 'PATCH:/v1/users/{user_id}/lock',
  unlockUser = 'PATCH:/v1/users/{user_id}/unlock',
  deleteUser = 'DELETE:/v1/users/{user_id}',
  setUserRole = 'POST:/v1/users/{user_id}/profile-role',
  getUserHistory = 'GET:/v1/users/{user_id}/history',
  updateUserImage = 'PATCH:/v1/users/{user_id}/profile-image',
  updateUserProfile = 'PATCH:/v1/users/{user_id}/profile',
  updateUserSelfProfile = 'PATCH:/v1/user',
  getUsersInfo = 'GET:/v1/users/info',
  getRolesList = 'GET:/v1/roles',
  createRole = 'POST:/v1/roles',
  getTemplate = 'GET:/v1/templates/{template_id}',
  updateProductTemplate = 'PATCH:/v1/templates/{template_id}',
  getRoleDetails = 'GET:/v1/roles/{role_id}',
  deleteRole = 'DELETE:/v1/roles/{role_id}',
  getPermissions = 'GET:/v1/permissions',
  updateRole = 'PATCH:/v1/roles/{role_id}',
  getFee = 'GET:/v1/fees/{fee_id}',
  getUnresolvedFees = 'GET:/v1/unresolved-fees',
  updateAccountCreditLimit = 'PATCH:/v1/accounts/{account_id}/credit-limit',
  getReservedSettlementBalance = 'GET:/v1/reserved-settlement-balance',
  getTransaction = 'GET:/v1/transactions/{transaction_id}',
  getAccountAdjustment = 'GET:/v1/account-adjustments/{account_adjustment_id}',
  getAuthorization = 'GET:/v1/authorizations/{authorization_id}',
  getAuthorizationAdditionalInfo = 'GET:/v1/authorizations/{authorization_id}/additional-info',
  getSummaryTransactions = 'GET:/transactions/summary',
  changeUserLocale = 'PATCH:/v1/user',
  clientVerificationAddPerson = 'POST:/v1/client/persons',
  clientVerificationUpdatePerson = 'PATCH:/v1/client/persons/{person_id}',
  clientVerificationDeletePerson = 'DELETE:/v1/client/persons/{person_id}',
  clientVerificationAddCompany = 'POST:/v1/client/companies',
  clientVerificationUpdateCompany = 'PATCH:/v1/client/companies/{company_id}',
  clientVerificationDeleteCompany = 'DELETE:/v1/client/companies/{company_id}',
  clientVerificationSendClientPersonVerificationLink = 'POST:/v1/client/persons/{person_id}/send-verification-link',
  clientVerificationGetClientPersonAndCompanyList = 'GET:/v1/client/persons-and-companies',
  getPricingPlans = 'GET:/v1/pricing-plan-types',
  createApiKey = 'POST:/v1/product/api-keys',
  getApiKeysList = 'GET:/v1/product/api-keys',
  deleteApiKey = 'DELETE:/v1/product/api-keys/{api_key_id}',
  getMassPaymentsFiles = 'GET:/v1/payment-files',
  getMassPaymentsFile = 'GET:/v1/payment-files/{payment_file_id}/payments',
  retryMassPaymentsFile = 'PUT:/v1/payment-files/{payment_file_id}/retry',
  createMassPaymentFile = 'POST:/v1/payment-files',
  editMassPaymentFileRecord = 'PATCH:/v1/payment-files/{payment_file_id}/payments/{payment_record_id}',
  deleteMassPaymentFileRecord = 'DELETE:/v1/payment-files/{payment_file_id}/payments/{payment_record_id}',
  confirmMassPaymentFile = 'PATCH:/v1/payment-files/{payment_file_id}/confirm',
  completeMassPaymentFile = 'PATCH:/v1/payment-files/{payment_file_id}/complete',
  discardMassPaymentsFile = 'DELETE:/v1/payment-files/{payment_file_id}',
  currencyRequest = 'POST:/v1/send-email/multi-currency-request',
  getStatementAuthorization = 'GET:/v1/statement/authorizations/{authorization_id}',
  getPaymentMetadata = 'GET:/v1/payment-metadata',
  addPaymentMetadataItems = 'POST:/v1/payment-metadata',
  updatePaymentMetadataItem = 'PATCH:/v1/payment-metadata/{payment_metadata_id}',
  deletePaymentMetadataItem = 'PATCH:/v1/payment-metadata/{payment_metadata_id}',
  getStatementTransaction = 'GET:/v1/statement/transactions/{transaction_id}',
  getClientSupportManagers = 'GET:/v1/client/support-managers',
  topUpPreview = 'GET:/v1/accounts/{account_id}/top-up-preview',
  initCardPayment = 'POST:/v1/card-payments/initiate',
  getAllowedTopUpMethods = 'GET:/v1/allowed-top-up-methods',
  getTopUpHistory = 'GET:/v1/top-up-history',
  getCardTopUpFees = 'GET:/v1/card-top-up-fees',
}

export default Endpoints;
