import Bowser from 'bowser';

export default class BrowserDetect {
  static browser = Bowser.parse(window.navigator.userAgent);

  static getBrowser(): Bowser.Parser.ParsedResult {
    window.Bowser = Bowser;
    return this.browser;
  }

  static isMobile(): boolean {
    return BrowserDetect.browser.platform.type === 'mobile';
  }

  static isTablet(): boolean {
    return (
      BrowserDetect.browser.platform.type === 'tablet' ||
      (this.isSafari() && navigator.maxTouchPoints > 1)
    );
  }

  static isIOS(): boolean {
    return BrowserDetect.browser.os.name === 'iOS';
  }

  static isMac(): boolean {
    return BrowserDetect.browser.os.name === 'macOS';
  }

  static isAndroid(): boolean {
    return BrowserDetect.browser.os.name === 'Android';
  }

  static isChrome(): boolean {
    return BrowserDetect.browser.browser.name === 'Chrome';
  }

  static isEdge(): boolean {
    return BrowserDetect.browser.browser.name === 'Microsoft Edge';
  }

  static isFirefox(): boolean {
    return BrowserDetect.browser.browser.name === 'Firefox';
  }

  static isOpera(): boolean {
    return BrowserDetect.browser.browser.name === 'Opera';
  }

  static isSafari(): boolean {
    return BrowserDetect.browser.browser.name === 'Safari';
  }

  static isTouch(): boolean {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }
}
