import axios from 'axios';
import {ComponentType} from 'react';
import config from '../constants/config';
import logger from '../logger';

const log = logger.module('componentLoader');

const checkConfig = async (): Promise<void> => {
  const serverConfig = await axios
    .get('/config.json')
    .then(({data}) => {
      if (typeof data === 'string') {
        log.info('load invalid config', {data});
        return {};
      }

      return data;
    })
    .catch(() => {
      return {};
    });

  if (
    typeof serverConfig.version !== 'undefined' &&
    config.version !== serverConfig.version
  ) {
    throw new Error('build version error');
  }

  // eslint-disable-next-line
  console.log('serverConfig', serverConfig);
};

// eslint-disable-next-line
export default function componentLoader<T = {}>(
  lazyComponent: () => Promise<{default: ComponentType<T>}>,
  attempts: number = config.maxChunkRetryAttempts,
  firstTry = true
): Promise<{default: ComponentType<T>}> {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();
    lazyComponent()
      .then(resolve)
      .catch((error: Error) => {
        setTimeout(() => {
          Promise.resolve()
            .then(() => {
              if (firstTry) {
                return checkConfig().catch(() => {
                  // reject(new Error('Update by config value...'));
                  log.warn('Update page by config value');
                  window.location.reload();
                });
              }
              return Promise.resolve();
            })
            .then(() => {
              if (attempts === 1) {
                // reject(error);
                const errorChunkRegexp = new RegExp(/Loading (.*) failed/);
                const errorChunkName = error.message.match(errorChunkRegexp);

                const errorObj = (error as unknown) as Record<string, unknown>;

                log.warn('Chunk load error', {
                  error: errorChunkName
                    ? errorChunkName[0]
                    : error.message || 'some error',
                  requestUri: errorObj.request as string,
                  downloadMs: Date.now() - startTime,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
                return;
              }
              componentLoader(lazyComponent, attempts - 1, false).then(
                resolve,
                reject
              );
            });
        }, config.chunkRetryTimeout);
      });
  });
}
