export enum TUTORIAL_STEP {
  DEMO_GENERAL_VIEW = 'demo_general_view',
  DEMO_TOP_UP = 'demo_top_up',
  DEMO_ACCOUNTS = 'demo_accounts',
  DEMO_CREATE_CARD = 'demo_create_card',
  DEMO_PAYMENTS = 'demo_payments',
  DEMO_STATEMENTS = 'demo_statements',
  DEMO_COMPANY_LIMITS = 'demo_company_limits',
  DEMO_ADD_CURRENCY = 'demo_add_currency',
  DEMO_PRICING_PLAN = 'demo_pricing_plan',
  DEMO_API = 'demo_api',
  DEMO_AFFILIATE_PROGRAM = 'demo_affiliate_program',
  DEMO_PROFILE_SECTION = 'demo_profile_section',
  DEMO_SEARCH = 'demo_search',
  DEMO_3DS_BIN = 'demo_3ds_bin',
  DEMO_CONTACT_SUPPORT = 'demo_contact_support',
}

type TutorialStep = {
  id: TUTORIAL_STEP;
  title_key: string;
  text_key?: string;
  component?: string;
};

export default TutorialStep;
