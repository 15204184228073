import React from 'react';
import {Modal} from 'antd';
import type {ModalFuncProps} from 'antd/lib/modal';
import clsx from 'clsx';
import {modalWidth} from '../constants/styles';
import {getMedia} from '../models/medias';
import {getIntl} from '../models/i18n';
import {ReactComponent as IconClose} from '../images/icons/close.svg';
import gcss from './styles';
import {
  DataTestContext,
  getContextTestId,
} from '../customHooks/useContextTestId';
import {getDataTestId} from '../customHooks/useDataTestId';

export const confirm = ({
  onOk,
  contextTestId,
  okButtonProps,
  cancelButtonProps,
  modalRender,
  ...restProps
}: ModalFuncProps & {contextTestId?: DataTestContext}) => {
  const modalContextTestId = getContextTestId('Modal', contextTestId);
  const modalDataTestId = getDataTestId(undefined, modalContextTestId);
  const okButtonDataTestId = getDataTestId('OkButton', modalContextTestId);
  const cancelButtonDataTestId = getDataTestId(
    'CancelButton',
    modalContextTestId
  );

  const intl = getIntl();
  const media = getMedia();
  const modalRet = Modal.confirm({
    okText: intl.formatMessage({
      id: 'App-Confirm',
    }),
    cancelText: intl.formatMessage({
      id: 'App-No__thank_you',
    }),
    okButtonProps: {
      className: clsx(
        gcss('t_font-weight_700'),
        gcss('t_transform_uppercase'),
        'ant-btn_wide ant-btn_green-emerald',
        media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger'
      ),
      ...okButtonDataTestId,
    },
    cancelButtonProps: {
      className: clsx(
        gcss('t_font-weight_700'),
        gcss('t_transform_uppercase'),
        'ant-btn_wide ant-btn_confirm-cancel',
        media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger'
      ),
      ...cancelButtonDataTestId,
    },
    closable: true,
    closeIcon: <IconClose />,
    centered: !media.xs,
    width: modalWidth,
    icon: <></>,
    autoFocusButton: null,
    className: 'ant-modal-confirm_sticky',
    modalRender: (node) => <div {...modalDataTestId}>{node}</div>,
    ...restProps,
    ...(okButtonProps
      ? {
          okButtonProps: {...okButtonProps, ...okButtonDataTestId},
        }
      : {}),
    ...(cancelButtonProps
      ? {
          cancelButtonProps: {...cancelButtonProps, ...cancelButtonDataTestId},
        }
      : {}),
    ...(modalRender
      ? {modalRender: (node) => <div {...modalDataTestId}>{node}</div>}
      : {}),
    onOk: async () => {
      modalRet.update((prevConfig) => {
        return {
          ...prevConfig,
          cancelButtonProps: {
            ...prevConfig.cancelButtonProps,
            disabled: true,
          },
          closable: false,
        };
      });
      if (onOk) {
        await onOk();
      }
      modalRet.update((prevConfig) => {
        return {
          ...prevConfig,
          cancelButtonProps: {
            ...prevConfig.cancelButtonProps,
            disabled: false,
          },
          closable: true,
        };
      });
    },
  });

  return modalRet;
};

export default confirm;
