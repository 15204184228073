import {useCallback} from 'react';
import useContextTestId, {getContextTestId} from './useContextTestId';
import config from '../constants/config';

export const getDataTestId = (
  testId?: string,
  context?: string[]
): Record<string, string> | undefined => {
  const {isProd, isUseTestId} = config;
  const contextTestId = getContextTestId(testId, context);

  if (isProd || !isUseTestId || !contextTestId || !contextTestId.length) {
    return undefined;
  }

  return {
    'data-testid': contextTestId.join('-'),
  };
};

const useDataTestId = (
  testId?: string
): ((testId?: string) => Record<string, string> | undefined) => {
  const context = useContextTestId(testId);

  return useCallback(
    (dataTestId?: string): Record<string, string> | undefined => {
      return getDataTestId(dataTestId, context);
    },
    [context]
  );
};

export default useDataTestId;
