import {ICompany} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const getCompany: DemoApiHandler = ({config, instance, url}) => {
  const item = instance
    .getData()
    .companies.find((a) => a.id === url?.params?.company_id);

  if (item) {
    return Demo.makeDataResponse<{
      company: ICompany;
    }>(config, {
      company: item,
    });
  }

  return Demo.makeUnprocessableEntityResponse(config, 90403);
};

export default getCompany;
