import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import {App$} from '../models/app';
import CurrentClientStore from '../models/currentClient';
import CurrentUserStore from '../models/currentUser';
import {CurrentLocaleStore} from '../models/i18n';
import {
  resetMessenger,
  setMessengerActiveState,
  setMessengerInitState,
  setMessengerVisibleState,
} from '../models/messenger';
import {ProductStore} from '../models/product';
import AuthService from '../services/AuthService';
import {
  IClient,
  IntercomConfig,
  IProductBase,
  MessengerConfig,
  SystemUser,
  UserLocale,
} from '../types';
import ObjectKeys from './object';
import WallesterAxios from './WAxios';

const MessengerId = 'w_messenger_id';
const MessengerConfigId = 'w_messenger_config_id';
let ScripLoaded = false;
let eventsCache: MessengerEvents = {};

const getHmacHash = async (): Promise<string> => {
  const as = AuthService.getInstance();
  if (as.hasToken()) {
    const isValid = await as.checkToken();
    if (isValid) {
      const response: AxiosResponse<{
        hash: string;
      }> = await WallesterAxios.getInstance()
        .getCommon()
        .get(
          {
            endpoint: Endpoints.intercomIdentityHash,
          },
          {
            headers: {
              Authorization: as.getAuthBearerString() || '',
            },
          }
        );

      return response.data.hash;
    }
  }

  return '';
};

type MessengerEvents = {
  onUnreadCountChange?: (data: number) => void;
  onHide?: () => void;
  onShow?: () => void;
};

export type InitMessengerParams = {
  config?: MessengerConfig;
  onLoad?: () => void;
  onHide?: () => void;
  onShow?: () => void;
  onMessagesChange?: (data: number) => void;
  events?: MessengerEvents;
};

export const setMessengerIdentity = async (
  user: SystemUser,
  client: IClient,
  product: IProductBase,
  isWhiteLabeled: boolean
) => {
  if (window.Intercom !== undefined && ScripLoaded && user && client) {
    setMessengerActiveState(false);
    const params = {
      user_hash: await getHmacHash(),
      email: user.email,
      user_id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      company: {
        company_id: client.id,
        name: isWhiteLabeled
          ? product.code || `company-${client.id}`
          : client.name,
      },
    };
    window.Intercom('update', params);
    setTimeout(() => {
      setMessengerActiveState(true);
    }, 5000);
  }
};

export const setMessengerClientInfo = async () => {
  const {isGuest, isWhiteLabeled} = App$.getState();
  const user = CurrentUserStore.getState();
  const client = CurrentClientStore.getState();
  const product = ProductStore.getState();

  if (
    window.Intercom !== undefined &&
    ScripLoaded &&
    !isGuest &&
    user &&
    product &&
    client
  ) {
    const params = {
      name: `${user.first_name} ${user.last_name}`,
      company: {
        company_id: client.id,
        name: isWhiteLabeled
          ? product.code || `company-${client.id}`
          : client.name,
      },
    };
    window.Intercom('update', params);
  }
};

export const setMessengerLocale = async (locale: UserLocale) => {
  if (window.Intercom !== undefined && ScripLoaded) {
    window.Intercom('update', {language_override: locale});
  }
};

const getMessengerUserConfig = async (): Promise<Partial<IntercomConfig>> => {
  const {isGuest, isWhiteLabeled} = App$.getState();
  const user = CurrentUserStore.getState();
  const client = CurrentClientStore.getState();
  const product = ProductStore.getState();
  const {locale} = CurrentLocaleStore.getState();

  const userConfig: Partial<IntercomConfig> = {
    api_base: 'https://api-iam.eu.intercom.io',
    language_override: locale,
  };
  if (!isGuest && user && client && product) {
    userConfig.user_id = user.id;
    userConfig.email = user.email;
    userConfig.user_hash = await getHmacHash();
    userConfig.name = `${user.first_name} ${user.last_name}`;
    userConfig.company = {
      company_id: client.id,
      name: isWhiteLabeled
        ? product.code || `company-${client.id}`
        : client.name,
    };
  }
  // } else {
  //   userConfig.email = `${getGuestId()}@wallester.com`;
  // }
  return Promise.resolve(userConfig);
};

export const reInitMessenger = async ({
  events,
  config: messengerConfig,
}: InitMessengerParams): Promise<void> => {
  const {config} = App$.getState();
  const bootParams = {
    app_id: config.messengerKeyId,
    ...(messengerConfig || {}),
    ...(await getMessengerUserConfig()),
  };
  window.Intercom('boot', bootParams);
  window.Intercom('hide');

  const mergedEvents: MessengerEvents = {...eventsCache, ...(events || {})};

  ObjectKeys(mergedEvents).forEach((key) => {
    const event = mergedEvents[key as keyof MessengerEvents];

    if (event) {
      window.Intercom(key, event);
    }
  });
  setMessengerInitState(true);
};

export const addMessengerScript = async ({
  config: messengerConfig,
  onLoad,
  onHide,
  onShow,
  onMessagesChange,
}: InitMessengerParams): Promise<void> => {
  const {config} = App$.getState();

  const appId = config.messengerKeyId;

  const inner = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${appId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};w.Intercom.init = l;if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
  let scriptTag = document.querySelector(`body>script[id="${MessengerId}"]`);

  if (!scriptTag) {
    scriptTag = document.createElement('script');
    scriptTag.setAttribute('id', MessengerId);
    scriptTag.innerHTML = `${inner};`;

    document.body.appendChild(scriptTag);
  }

  if (messengerConfig) {
    let configScriptTag = document.querySelector(
      `body>script[id="${MessengerConfigId}"]`
    );

    if (!configScriptTag) {
      configScriptTag = document.createElement('script');
      configScriptTag.setAttribute('id', MessengerConfigId);
      configScriptTag.innerHTML = `
        window.intercomSettings = ${JSON.stringify({
          ...config.messengerConfig,
          ...messengerConfig,
        })};
      `;
      document.body.appendChild(configScriptTag);
    }
  }

  window.Intercom.init();
  ScripLoaded = true;

  const events: MessengerEvents = {
    onHide,
    onShow,
    onUnreadCountChange: onMessagesChange,
  };

  eventsCache = {...events};

  reInitMessenger({
    onMessagesChange,
    onHide,
    onShow,
    config: messengerConfig,
    events,
  }).then(() => {
    if (onLoad) {
      onLoad();
    }
  });
};

export const showMessengerWindow = (): void => {
  if (window.Intercom) {
    // window.Intercom('showMessages');
    window.Intercom('show');
  }
};

export const hideMessengerWindow = (): void => {
  if (window.Intercom) {
    window.Intercom('hide');
  }
};

export const removeMessengerScript = (): void => {
  resetMessenger();

  eventsCache = {};

  document.querySelectorAll('#w-messenger').forEach((el) => {
    if (el) {
      document.body.removeChild(el);
    }
  });

  // document.querySelectorAll('.kykm__sandbox').forEach((el) => {
  //   if (el) {
  //     document.body.removeChild(el);
  //   }
  // });

  const scriptTag = document.querySelector(`body>script[id="${MessengerId}"]`);

  if (scriptTag) {
    document.body.removeChild(scriptTag);
  }

  const configScriptTag = document.querySelector(
    `body>script[id="${MessengerConfigId}"]`
  );

  if (configScriptTag) {
    document.body.removeChild(configScriptTag);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.Intercom = undefined;
};

export const messengerLogout = () => {
  if (window.Intercom) {
    hideMessengerWindow();
    setMessengerVisibleState(false);
    setMessengerInitState(false);
    window.Intercom('shutdown');
    setTimeout(() => {
      reInitMessenger({});
    }, 1000);
  }
};

export const showMessengerTicket = (id: string) => {
  if (window.Intercom) {
    window.Intercom('showTicket', id);
  }
};

export const showMessengerArticle = (id: string) => {
  if (window.Intercom) {
    window.Intercom('showArticle', id);
  }
};

export default addMessengerScript;
