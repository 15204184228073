import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useStoreMap} from 'effector-react';
import clsx from 'clsx';
import HintsStore, {THintsTargets} from '../../../models/hints';
import css from './HintsShadow.module.less';
import {ScreenSize, TypedMap} from '../../../types';
import HINTS_TYPES from '../../../types/hints';
import useCurrentScreenSize from '../../../customHooks/useCurrentScreenSize';

const getHintsTargetsParams = (targets: THintsTargets | undefined) => {
  if (!targets) {
    return undefined;
  }

  const data: TypedMap<
    HINTS_TYPES,
    {
      width: number;
      height: number;
      top: number;
      left: number;
    }
  > = {};

  (Object.keys(targets) as HINTS_TYPES[]).forEach((id) => {
    const el = targets[id];
    const {offsetWidth: width, offsetHeight: height} = el || {};
    const {top, left} = el?.getBoundingClientRect() || {};

    data[id] = {
      width: width || 0,
      height: height || 0,
      top: top || 0,
      left: left || 0,
    };
  });

  return data;
};

const HintsShadow: React.FC = () => {
  const currentScreenSize = useCurrentScreenSize();
  const [loaded, setLoaded] = useState(false);
  const [targets] = useStoreMap({
    store: HintsStore,
    keys: [
      'activeSingleHint',
      'activeInlineHints',
      'activeRouteHint',
      'targets',
    ],
    fn: (store$) => {
      let activeHintsTargets: THintsTargets | undefined;
      const activeHintsIds = [
        ...(store$.activeInlineHints ? store$.activeInlineHints : []),
        ...(store$.activeSingleHint ? [store$.activeSingleHint] : []),
        ...(store$.activeRouteHint ? [store$.activeRouteHint] : []),
      ]
        .filter((a) => {
          if (typeof a.shadowed === 'object') {
            return (a.shadowed as TypedMap<ScreenSize, boolean>)[
              currentScreenSize
            ];
          }

          return a.shadowed;
        })
        .map((a) => a.id);

      activeHintsIds.forEach((id) => {
        if (store$.targets[id]) {
          if (!activeHintsTargets) {
            activeHintsTargets = {};
          }

          activeHintsTargets[id] = store$.targets[id];
        }
      });

      return [activeHintsTargets, activeHintsIds];
    },
  });
  const [targetsParams, setTargetsParams] = useState(
    getHintsTargetsParams(targets)
  );

  const recalculateTargetsParams = useCallback(() => {
    setTargetsParams(getHintsTargetsParams(targets));
  }, [targets]);

  useEffect(() => {
    window.addEventListener('resize', recalculateTargetsParams);

    const observer = new MutationObserver(recalculateTargetsParams);

    if (observer) {
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener('resize', recalculateTargetsParams);

      if (observer) {
        observer.disconnect();
      }
    };
  }, [recalculateTargetsParams]);

  useEffect(() => {
    setTargetsParams(getHintsTargetsParams(targets));
  }, [targets]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return useMemo(() => {
    return (
      <div
        className={clsx(css.layer, loaded && targets ? css.layer_visible : '')}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          className={css.layer__targetOverlay}
        >
          <defs>
            <mask id="hintTargetOverlayMask">
              <rect
                width="300%"
                height="300%"
                x="-100%"
                y="-100%"
                fill="rgba(255,255,255,.6)"
              />

              {!!targetsParams &&
                (Object.keys(targetsParams) as HINTS_TYPES[]).map((id, a) => {
                  const key = `${a}`;
                  const {width, height, top, left} = targetsParams[id] || {};

                  return (
                    <rect
                      key={key}
                      width={width}
                      height={height}
                      x={left}
                      y={top}
                      rx="18px"
                      fill="#000"
                      className={css.layer__targetOverlayRect}
                    />
                  );
                })}
            </mask>
          </defs>

          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="rgb(11, 21, 46)"
            mask="url(#hintTargetOverlayMask)"
          />
        </svg>
      </div>
    );
  }, [loaded, targets, targetsParams]);
};

export default HintsShadow;
