import React, {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import {Modal} from 'antd';
import {useIntl} from 'react-intl';
import Button from '../../../../../../components/Button';
import routes, {getRoutePath} from '../../../../../../constants/routes';
import useMedias from '../../../../../../customHooks/useMedias';
import {redirect} from '../../../../../../models/router';
import {startTutorial} from '../../../../../../models/tutorial';
import {modalWidth} from '../../../../../../constants/styles';
import gcss from '../../../../../../utils/styles';
import css from './TutorialWelcomeModal.module.less';

interface Props {
  onClose: (status: boolean) => void;
}

const TutorialWelcomeModal: React.FC<Props> = ({onClose}) => {
  const media = useMedias();
  const intl = useIntl();
  const [visible, setVisible] = useState(true);

  const handleCancel = useCallback(() => {
    setVisible(false);

    if (onClose) {
      onClose(false);
    }
  }, [onClose]);

  useEffect(() => {
    redirect(getRoutePath(routes.blackDashboard));
  }, []);

  return (
    <Modal
      className={clsx(css.modal, 'ant-modal_sticky')}
      visible={visible}
      width={modalWidth}
      footer={false}
      destroyOnClose
      centered={!media.xs}
      maskClosable={false}
      onCancel={handleCancel}
      afterClose={() => {
        onClose(false);
      }}
    >
      <div className={css.modal__image} />

      <div className={css.modal__title}>
        {intl.formatMessage({
          id:
            'TutorialWelcomeModal-Welcome_to_the_Wallester_Business_Client_Portal_Demo_',
        })}
      </div>

      <div className={css.modal__desc}>
        {intl.formatMessage({
          id: 'TutorialWelcomeModal-It_s_great_to_have_you_here_',
        })}
      </div>

      <Button
        className={clsx(
          'ant-btn_wide ant-btn_green-emerald',
          gcss('t_font-weight_700'),
          gcss('t_transform_uppercase'),
          media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger'
        )}
        onClick={() => {
          setVisible(false);
          startTutorial();
        }}
      >
        {intl.formatMessage({id: 'SalaryProgramModal-Show_me_around'})}
      </Button>
    </Modal>
  );
};

export default TutorialWelcomeModal;
