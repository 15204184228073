import {useMemo} from 'react';
import Features from '../constants/features';
import useCheckFeaturesImpl from './useCheckFeaturesImpl';

export const useCheckFeatureConfig = <
  T extends Record<string, unknown> = Record<string, unknown>
>(
  feature: Features
): T | null => {
  const treatment = useCheckFeaturesImpl(feature);

  return useMemo<T | null>(() => {
    if (treatment) {
      const rawFeatureConfig = treatment.config;
      if (rawFeatureConfig) {
        try {
          return JSON.parse(rawFeatureConfig);
        } catch (e) {
          return null;
        }
      }
    }
    return null;
  }, [treatment]);
};

export default useCheckFeatureConfig;
