import {v4} from 'uuid';
import moment from 'moment-timezone';
import Demo, {DemoApiHandler} from '../demo';
import PaymentDocumentFile from '../../types/payment';
import {DEMO_USER_ID} from '../../constants/demo';

const addPaymentDocumentFiles: DemoApiHandler = ({config, instance, data}) => {
  const params = (data as unknown) as FormData;
  const paymentId = params.get('payment_id');
  const paymentType = params.get('payment_type');
  const statement = instance
    .getData()
    .statements.filter((a) => a.id === paymentId)[0];

  const items = instance.getData().paymentDocuments;
  const item = {
    id: v4(),
    payment_id: paymentId,
    payment_type: paymentType,
    file_name: 'demo.pdf',
    mime_type: 'application/pdf',
    file_body: 'a',
    created_at: moment().toISOString(),
    created_by: DEMO_USER_ID,
  } as PaymentDocumentFile;

  items.push(item);

  statement.has_payment_document_files = true;

  instance.saveImpl(['statements', 'paymentDocuments']);

  return Demo.makeDataResponse<{
    payment_documents: PaymentDocumentFile[];
  }>(config, {
    payment_documents: [item],
  });
};

export default addPaymentDocumentFiles;
