enum Permissions {
  PermissionViewAdminPanel = 1000,
  PermissionManageAllUsers = 1002,
  PermissionManageAllRoles = 1005,
  PermissionManageUserClientSpace = 1006,
  PermissionViewAccountAuthorizations = 1303,
  PermissionViewAccountTransactions = 1304,
  PermissionLinkSticker = 1311,
  PermissionRenewCard = 1420,
  PermissionOrderPromoCardsBatch = 1424,
  PermissionViewCardsStatistics = 1428,
  PermissionViewCardMetadataProfile = 1432,
  PermissionEditCardMetadataProfile = 1436,
  PermissionCreateCardMetadataProfile = 1439,
  PermissionViewDashboard = 1600,
  PermissionViewReports = 1700,
  PermissionViewTotalTransactionsReport = 1701,
  PermissionViewTotalCardsReport = 1702,
  PermissionViewTransactionDetails = 1800,
  PermissionMarkTransactionForDispute = 1801,
  PermissionUnmarkTransactionForDispute = 1802,
  PermissionViewAccountAdjustmentDetails = 1900,
  PermissionViewAuthorizationDetails = 2000,
  PermissionMarkAuthorizationForDispute = 2002,
  PermissionUnmarkAuthorizationForDispute = 2003,
  PermissionAdjustSettlementRiskLimit = 4002,
  PermissionDownloadExtractFiles = 4003,
  PermissionViewOperationsPanel = 4004,
  PermissionManageAccounts = 4435,
  PermissionManageUsers = 1001,
  PermissionCreateUser = 1003,
  PermissionManageRoles = 1004,
  PermissionSearchPersons = 1100,
  PermissionViewPersonDetails = 1101,
  PermissionEditPerson = 1102,
  PermissionDeactivatePerson = 1103,
  PermissionCreatePerson = 1104,
  PermissionViewCardBlockCalls = 1105,
  PermissionViewAccounts = 1300,
  PermissionViewAccountDetails = 1301,
  PermissionViewAccountCards = 1302,
  PermissionCloseAccount = 1307,
  PermissionAdjustAccountBalance = 1308,
  PermissionChangeAccountLimits = 1309,
  PermissionCreateAccount = 1310,
  PermissionCreateCard = 1312,
  PermissionUpdateCreditLimit = 1313,
  PermissionUpdateAccountName = 1314,
  PermissionUpdateAccountTopUpDetails = 1317,
  PermissionUpdateAccountExternalID = 4431,
  PermissionViewAccountStatementRecords = 1315,
  PermissionViewCards = 1400,
  PermissionViewCardDetails = 1401,
  PermissionViewCardAuthorizations = 1402,
  PermissionViewCardTransactions = 1403,
  PermissionActivateCard = 1404,
  PermissionBlockCard = 1405,
  PermissionChangeCardLimits = 1407,
  PermissionCloseCard = 1408,
  PermissionOrderCardsBatch = 1409,
  PermissionUnblockCard = 1410,
  PermissionSendPINToCardholder = 1411,
  PermissionSendCVV2ToCardholder = 1412,
  PermissionUpdateCard3DSecureSettings = 1414,
  PermissionEnrolCardFor3DSecure = 1415,
  PermissionUnenrolCardFrom3DSecure = 1416,
  PermissionUpdateCardSecurity = 1421,
  PermissionUpdateCardName = 1422,
  PermissionUpdateCardExternalID = 4433,
  PermissionUpdateCardStatus = 1423,
  PermissionViewExpiringCards = 1425,
  PermissionViewPendingOrders = 1426,
  PermissionViewSettlement = 1427,
  PermissionReplaceCard = 1429,
  PermissionViewProductTemplates = 1430,
  PermissionEditProductTemplates = 1431,
  PermissionViewTokens = 1433,
  PermissionSend3DSPasswordToCardholder = 1434,
  PermissionCreatePayment = 1435,
  PermissionViewEventServiceSettings = 1437,
  PermissionEditEventServiceSettings = 1438,
  PermissionReleaseAuthorizations = 2001,
  PermissionManageFailedTransactions = 3000,
  PermissionViewFeeDetails = 3100,
  PermissionViewAccountHistory = 3102,
  PermissionViewPersonHistory = 3101,
  PermissionViewCardHistory = 3103,
  PermissionViewSettlementRiskLimits = 4000,
  PermissionViewPayments = 4100,
  PermissionSendCardNumberToCardholder = 4200,
  PermissionSearch = 4201,
  PermissionActivatePerson = 4202,
  PermissionReopenAccount = 4203,
  PermissionChangeCardPIN = 4204,
  PermissionLinkCardToAccount = 4205,
  PermissionManageTokens = 4206,
  PermissionViewSettlementBalance = 4001,
  PermissionViewCompanies = 4304,
  PermissionEditCompanies = 4419,
  PermissionGetAllProductUserProfiles = 4406,
  PermissionDeleteBatchProductUserProfiles = 4407,
  PermissionChangeCompanyLimits = 4408,
  PermissionManageEmployees = 4409,
  PermissionUpdateProductPricingPlan = 4410,
  PermissionCreateApiKey = 4414,
  PermissionViewApiKeys = 4416,
  PermissionDeleteApiKey = 4415,
  PermissionViewFrauds = 4422,
  PermissionEditFrauds = 4423,
  PermissionManageCardUsers = 4436,
}

export default Permissions;
