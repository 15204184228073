import {AxiosError} from 'axios';
import {Location} from 'history';
import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import {ParsedQuery} from 'query-string';
import {matchPath} from 'react-router';
import {
  getAssignedProducts,
  getCurrentUser,
  logoutRequest,
  refreshTokenRequest,
  updateUserLang,
} from '../api';
import config from '../constants/config';
import Endpoints from '../constants/endpoints';
import Features, {
  ClientFeatures,
  ProductFeatures,
  TreatmentStatus,
  UserFeatures,
} from '../constants/features';
import Permissions from '../constants/permissions';
import routes, {getRoutePath, InitialProductKey} from '../constants/routes';
import logger from '../logger';
import {
  App$,
  initAuthAction,
  loadProfile,
  reloadAllowedEndpoints,
  setIsGuest,
  setIsWhiteLabeled,
  updateAuthData,
} from '../models/app';
import clearAppStores, {softClearAppStores} from '../models/clearStore';
import {reloadCurrentClient, setCurrentClient} from '../models/currentClient';
import CurrentUserStore, {
  setCurrentUser,
  updatePermissions,
} from '../models/currentUser';
import FeatureStore from '../models/features';
import {CurrentLocaleStore, getIntl} from '../models/i18n';
import {resetLayout} from '../models/layout';
import {setMessengerVisibleState} from '../models/messenger';
import {
  assignProfileFx,
  loadTopUpInfo,
  ProductStore,
  setCurrentProduct,
} from '../models/product';
import {getAssignedProductsFx} from '../models/products';
import {getProductSettingsFx} from '../models/productSettings';
import {gotoLogin} from '../models/router';
import {initServerStorage} from '../models/serverStorage';
import {reloadSystemAccount} from '../models/systemAccount';
import {
  AuthState,
  AvailableLanguages,
  DefaultLanguage,
  IClient,
  IProductBase,
  IProductWithProfile,
  JwtStructure,
  SessionFilters,
  SystemProfile,
  SystemUser,
  TokenInfo,
  UserLocale,
  UUID4,
} from '../types';
import BroadcastHelper from '../utils/broadcastHelper';
import Demo from '../utils/demo';
import isAllowedEndpoint from '../utils/freeze';
import getUrl, {getCleanUrl} from '../utils/language';
import localStorageWrapper from '../utils/localStorageWrapper';
import {messengerLogout, setMessengerIdentity} from '../utils/messenger';
import {error as errorNotification} from '../utils/notifications';
import ObjectKeys from '../utils/object';
import {makePermissions, routesByPath} from '../utils/permissions';
import {isValidUUIDv4} from '../utils/uuid';
import SavedFiltersService from './SavedFiltersService';
import WebSocketService from './WebSocketService';
import {
  clearUnresolvedFees,
  getUnresolvedFeesFx,
} from '../models/unresolvedFees';

const log = logger.module('AuthService');

const defaultTokenCookieName = 'auth_token';
const lastUserLocaleKeyName = 'WA_DEFAULT_LOCALE';
// const cookieDomain = `.${config.siteDomain}`;

class AuthService {
  static readonly AUTH_FORM_KEY = 'wb_auth_form';

  static readonly AUTH_COOKIE_NAME = 'wb_ot_auth';

  static readonly INIT_AUTH_COOKIE_NAME = 'wb_init_auth';

  private static instance: AuthService | null = null;

  private readonly tokenKey: string;

  private tokenInfo: TokenInfo | null = null;

  private refreshTokenPromise: Promise<boolean> | null = null;

  public static getProductWithProfiles(
    products: IProductBase[],
    profiles: SystemProfile[]
  ): IProductWithProfile[] {
    return profiles
      .map<IProductWithProfile | null>((profile) => {
        if (profile.target_entity_name === 'product') {
          const product = products.find(
            (p) => p.id === profile.target_entity_id
          );
          if (product) {
            return {...product, profile_id: profile.id};
          }
        }
        return null;
      })
      .filter<IProductWithProfile>((d): d is IProductWithProfile => {
        return d !== null;
      });
  }

  public static getGuestRedirectUrl(
    loc: Location,
    lastLogoutAt?: string
  ): string {
    let extraRedirect = '';
    if (getCleanUrl(loc.pathname) !== '/' && !lastLogoutAt) {
      extraRedirect = `?b=${loc.pathname}`;
      const urlSearch = new URLSearchParams(loc.search);
      const initialProductId = urlSearch.get(InitialProductKey);
      if (initialProductId && isValidUUIDv4(initialProductId)) {
        extraRedirect += `&${InitialProductKey}=${initialProductId}`;
      }
    }
    return getUrl(`${routes.signIn.path}${extraRedirect}`);
  }

  public static getSuccessAuthRedirectUrl(query: ParsedQuery): string {
    const {b: backParam} = query;
    const {isWhiteLabeled, fullyVerified} = App$.getState();

    let backUrl = '';
    if (backParam !== undefined && backParam !== null) {
      if (Array.isArray(backParam)) {
        backUrl = backParam[0] || '';
      } else {
        backUrl = backParam;
      }

      if (backUrl /* && !routesByPath[getCleanUrl(backUrl as string)] */) {
        const cleanUrl = getCleanUrl(backUrl as string);
        const route = ObjectKeys(routesByPath).find((routeItemKey) => {
          const routeItem = routesByPath[routeItemKey];
          return matchPath(cleanUrl, {
            path: routeItem.path,
            exact: true,
          });
        });
        if (!route) {
          backUrl = '';
        }
      }
    }
    if (!backUrl) {
      backUrl = getUrl(
        getRoutePath(
          !isWhiteLabeled && !fullyVerified
            ? routes.verificationStatus
            : routes.main
        )
      );
    }
    return backUrl;
  }

  private static saveOtAuthCookieImpl(
    data: string,
    expiresSeconds: number
  ): boolean {
    const expires = new Date(Date.now() + expiresSeconds * 1000);
    const {authCookieDomain} = config;

    return (
      Cookie.set(AuthService.AUTH_COOKIE_NAME, data, {
        expires,
        domain: authCookieDomain,
      }) !== undefined
    );
  }

  public static saveInitAuthCookie(
    accessToken: string,
    refreshToken: string,
    expiresSeconds: number
  ): boolean {
    const expires = new Date(Date.now() + expiresSeconds * 1000);
    const {authCookieDomain} = config;

    const cookie = Cookie.set(
      AuthService.INIT_AUTH_COOKIE_NAME,
      JSON.stringify({
        access_token: accessToken,
        refresh_token: refreshToken,
      }),
      {
        expires,
        domain: authCookieDomain,
      }
    );
    return cookie !== undefined;
  }

  private static loadOtAuthCookieImpl(): string | undefined {
    return Cookie.get(AuthService.AUTH_COOKIE_NAME);
  }

  private static deleteOtAuthCookieImpl() {
    const {authCookieDomain} = config;
    Cookie.remove(AuthService.AUTH_COOKIE_NAME, {domain: authCookieDomain});
  }

  private static loadInitAuthCookieImpl():
    | {access_token: string; refresh_token: string}
    | undefined {
    const initToken = Cookie.get(AuthService.INIT_AUTH_COOKIE_NAME);
    if (initToken) {
      let decodedData: {
        access_token: string;
        refresh_token: string;
      } | null = null;
      try {
        decodedData = JSON.parse(initToken);
      } catch (e) {
        decodedData = null;
      }
      if (
        decodedData &&
        decodedData.access_token &&
        decodedData.refresh_token
      ) {
        return decodedData;
      }
    }
    return undefined;
  }

  private static deleteInitAuthCookieImpl() {
    const {authCookieDomain} = config;

    Cookie.remove(AuthService.INIT_AUTH_COOKIE_NAME, {
      domain: authCookieDomain,
    });
  }

  public static getInstance(
    tokenCookieName: string = defaultTokenCookieName
  ): AuthService {
    if (AuthService.instance === null) {
      AuthService.instance = new AuthService(tokenCookieName);
    }
    return AuthService.instance;
  }

  static updateLastUserLocale(locale: UserLocale): void {
    localStorageWrapper.setItem(lastUserLocaleKeyName, locale);
  }

  static getLastUserLocale(): UserLocale {
    const lastLocale: UserLocale | null = localStorageWrapper.getItem(
      lastUserLocaleKeyName
    ) as UserLocale | null;

    if (lastLocale && AvailableLanguages.includes(lastLocale)) {
      return lastLocale;
    }

    return DefaultLanguage;
  }

  public static getAuthInitState(): Partial<AuthState> {
    const rawFormAuthState = localStorageWrapper.getItem(
      AuthService.AUTH_FORM_KEY
    );
    if (rawFormAuthState) {
      try {
        const formAuthState = JSON.parse(
          rawFormAuthState
        ) as Partial<AuthState>;
        if (formAuthState.errorType === undefined) {
          const token = formAuthState.authToken;
          if (token) {
            const decodedToken = jwtDecode<JwtStructure>(token);
            if (decodedToken.exp * 1000 > +new Date()) {
              return {...formAuthState};
            }
          }
        }
        return {mode: 'phone'};
      } catch (e) {
        return {mode: 'phone'};
      }
    }

    return {mode: 'phone'};
  }

  public static saveAuthInitState(state: Partial<AuthState>): void {
    if (state.mode === 'init') {
      localStorageWrapper.removeItem(AuthService.AUTH_FORM_KEY);
    } else {
      localStorageWrapper.setItem(
        AuthService.AUTH_FORM_KEY,
        JSON.stringify({...state})
      );
    }
  }

  public static destroy(): void {
    if (AuthService.instance !== null) {
      AuthService.instance = null;
    }
  }

  public static isProductWhiteLabeled(product: IProductBase): boolean {
    return product.product_type === 'WhiteLabel';
  }

  public static encodeToken(token: string): string {
    return btoa(unescape(encodeURIComponent(token)));
  }

  public static decodeToken(token: string): string {
    return decodeURIComponent(escape(atob(token)));
  }

  public static savedFiltersKey(userId: UUID4, productId: UUID4): string {
    return `SAVED_FILTERS_${userId}_${productId}`;
  }

  constructor(tokenKey: string = defaultTokenCookieName) {
    this.tokenKey = tokenKey;
  }

  async init(): Promise<boolean> {
    const otAuthToken = AuthService.loadOtAuthCookieImpl();
    const initAuthToken = AuthService.loadInitAuthCookieImpl();

    if (otAuthToken !== undefined) {
      this.saveImpl(otAuthToken);
      AuthService.deleteOtAuthCookieImpl();
    } else if (initAuthToken !== undefined) {
      this.setTokenInfo(
        initAuthToken.access_token,
        initAuthToken.refresh_token
      );
      AuthService.deleteInitAuthCookieImpl();
    }
    if (this.hasToken()) {
      try {
        return (await loadProfile()) !== null;
      } catch (e) {
        log.warn('Error init auth');
      }
    }
    return false;
  }

  async signUp2(
    accessToken: string,
    refreshToken: string,
    user: SystemUser,
    registrationMode?: boolean,
    initialProfileId?: UUID4,
    initialProductId?: UUID4
  ): Promise<SystemUser> {
    try {
      const userLocale = CurrentLocaleStore.getState().locale;
      await this.signInImpl(
        accessToken,
        refreshToken,
        {
          ...user,
          locale: userLocale,
        },
        undefined,
        registrationMode,
        initialProfileId,
        initialProductId
      );

      await updateUserLang(userLocale);
      return user;
      throw new Error('token not found in response');
    } catch (error) {
      log.error('Error during registration', {
        error,
      });
      throw error;
    }
  }

  public async updateAuthInfo(
    user: SystemUser,
    client: IClient
  ): Promise<boolean> {
    return this.signInImpl(
      this.tokenInfo?.token || '',
      this.tokenInfo?.refresh_token || '',
      user,
      client
    );
  }

  private async signInImpl(
    accessToken: string,
    refreshToken: string,
    userFromLogin?: SystemUser,
    client?: IClient,
    registrationMode?: boolean,
    initialProfileId?: UUID4,
    initialProductId?: UUID4
  ): Promise<boolean> {
    const uniqProductIds: Record<string, boolean> = {};
    let user = userFromLogin;
    const intl = getIntl();

    if (!user) {
      user = await getCurrentUser();
    }

    const productProfiles = (user.profiles || []).filter((up) => {
      if (
        up.target_entity_name === 'product' &&
        typeof uniqProductIds[up.product_id] === 'undefined'
      ) {
        uniqProductIds[up.product_id] = true;
        return true;
      }
      return false;
    });

    if (productProfiles.length === 0) {
      throw new Error('User does not have any products');
    }

    let isWhiteLabeled = true;

    this.setTokenInfo(accessToken, refreshToken);

    const assignedProducts = AuthService.getProductWithProfiles(
      await getAssignedProducts(),
      user.profiles
    );

    if (assignedProducts.length === 0) {
      throw new Error(intl.formatMessage({id: 'Error-no_user_found'}));
    }

    const {last_profile_id} = await initServerStorage();

    let activeProductProfile = assignedProducts.find((productProfile) =>
      initialProfileId
        ? productProfile.profile_id === initialProfileId
        : productProfile.id === initialProductId
    );

    if (!activeProductProfile) {
      if (initialProfileId || initialProductId) {
        errorNotification(
          intl.formatMessage({
            id: 'App-You_don_t_have_access_to_the_requested_product_',
          })
        );
      }

      activeProductProfile = assignedProducts.find(
        (productProfile) => productProfile.profile_id === last_profile_id
      );
      if (!activeProductProfile) {
        [activeProductProfile] = assignedProducts;
      }
    }

    if (registrationMode) {
      assignedProducts.sort((a, b) => {
        return moment(b.created_at).unix() - moment(a.created_at).unix();
      });
      [activeProductProfile] = assignedProducts;
    }

    const productPermissions = await assignProfileFx(
      activeProductProfile.profile_id
    );
    setIsWhiteLabeled(AuthService.isProductWhiteLabeled(activeProductProfile));
    await reloadAllowedEndpoints();
    await getProductSettingsFx();
    setCurrentProduct(activeProductProfile);

    const activeProductProfilePermissions =
      productPermissions.profile_permission_ids;

    setCurrentUser({
      user,
      profilePermissions: activeProductProfilePermissions,
    });

    let activeClient: IClient;
    if (client !== undefined) {
      activeClient = client;
      setCurrentClient(client);
      isWhiteLabeled = client.white_label === true; // client.white_label;
    } else {
      activeClient = await reloadCurrentClient();
      isWhiteLabeled = AuthService.isProductWhiteLabeled(activeProductProfile);
    }

    await AuthService.initMessenger(
      user,
      activeClient,
      activeProductProfile,
      isWhiteLabeled
    );

    if (!isWhiteLabeled) {
      if (isAllowedEndpoint(Endpoints.getAccounts)) {
        await reloadSystemAccount();
      }

      if (
        isAllowedEndpoint(Endpoints.getUnresolvedFees) &&
        activeProductProfile.product_additional_info?.has_debts
      ) {
        await getUnresolvedFeesFx();
      } else {
        clearUnresolvedFees();
      }
    }
    await this.updateTokenProductId(
      activeProductProfile.id,
      activeProductProfile.profile_id
    );
    return true;
  }

  private static async initMessenger(
    user: SystemUser,
    client: IClient,
    product: IProductBase,
    isWhiteLabeled: boolean
  ) {
    if (user.email) {
      // hideMessengerWindow();
      await setMessengerIdentity(user, client, product, isWhiteLabeled);
      setMessengerVisibleState(false);
    }
  }

  async reAssignProfile(
    initialProfileId: UUID4 | null,
    silentMode?: boolean
  ): Promise<void> {
    const tokenInfo = this.getTokenInfo();

    if (tokenInfo) {
      const products = await getAssignedProductsFx();
      if (products.length > 0) {
        let productToSelect: IProductWithProfile | undefined;

        const lastSelectedProfileId = (await initServerStorage())
          ?.last_profile_id;
        productToSelect = products.find(
          (product) => product.profile_id === initialProfileId
        );
        if (!productToSelect) {
          if (initialProfileId) {
            const intl = getIntl();
            errorNotification(
              intl.formatMessage({
                id: 'App-You_don_t_have_access_to_the_requested_product_',
              })
            );
          }

          productToSelect = products.find(
            (product) => product.profile_id === lastSelectedProfileId
          );
          if (!productToSelect) {
            [productToSelect] = products;
          }
        }

        if (productToSelect) {
          await assignProfileFx(productToSelect.profile_id);
          setIsWhiteLabeled(AuthService.isProductWhiteLabeled(productToSelect));

          await reloadAllowedEndpoints();
          const client = await reloadCurrentClient();
          setCurrentProduct(productToSelect);
          await loadTopUpInfo(productToSelect.id);
          const isWhiteLabeled = client.white_label === true;

          if (
            !isWhiteLabeled &&
            isAllowedEndpoint(Endpoints.getUnresolvedFees) &&
            productToSelect.product_additional_info?.has_debts
          ) {
            await getUnresolvedFeesFx();
          } else {
            clearUnresolvedFees();
          }

          if (!silentMode) {
            BroadcastHelper.getInstance().sendProfileChangeEvent(
              productToSelect.profile_id
            );
          }
        }
      }
      await reloadCurrentClient();

      if (isAllowedEndpoint(Endpoints.getAccounts)) {
        await reloadSystemAccount();
      }
    }
    if (initialProfileId) {
      WebSocketService.getInstance().disconnect();
      softClearAppStores();
      await initAuthAction({cleanLoad: false, silentMode});
    }
  }

  refreshTokenImpl(doNotExtendSession?: boolean): Promise<boolean> {
    if (this.refreshTokenPromise !== null) {
      return this.refreshTokenPromise;
    }

    const tokenInfo = this.getTokenInfo(true);
    if (tokenInfo && tokenInfo.refresh_token) {
      this.refreshTokenPromise = new Promise<boolean>((resolve) => {
        const {exp: tokenExpirationDate} = jwtDecode<JwtStructure>(
          tokenInfo.refresh_token
        );
        if (Date.now() > tokenExpirationDate * 1000) {
          this.refreshTokenPromise = null;
          this.removeToken();

          gotoLogin();
          resolve(false);
        } else {
          refreshTokenRequest(tokenInfo.refresh_token, doNotExtendSession)
            .then((response) => {
              // log.info('Refresh token success', response);
              this.refreshTokenPromise = null;

              if (response) {
                const {
                  access_token,
                  refresh_token,
                  permissions: {
                    global: globalPermissions,
                    product: profilePermissions,
                  },
                } = response;
                if (access_token && refresh_token) {
                  updatePermissions({
                    user: makePermissions([...(globalPermissions || [])]),
                    profile: makePermissions([...(profilePermissions || [])]),
                  });
                  return resolve(this.updateToken(access_token, refresh_token));
                }
              }
              this.removeToken();
              return resolve(false);
            })
            .catch((error) => {
              this.removeToken();
              const {data} = error.response || {data: 'unk'};

              log.warn('Error during refresh token', {
                error,
                responseData: JSON.stringify(data),
              });
              this.refreshTokenPromise = null;
              resolve(false);
            });
        }
      }).catch(() => {
        return false;
      });

      return this.refreshTokenPromise || Promise.resolve(false);
    }
    return Promise.resolve(false);
  }

  async refreshToken(doNotExtendSession?: boolean): Promise<boolean> {
    const tokenInfo = this.getTokenInfo();
    if (tokenInfo && tokenInfo.refresh_token) {
      try {
        return await this.refreshTokenImpl(doNotExtendSession);
      } catch (e) {
        if (e) {
          const {response} = e as AxiosError;
          if (response && response.status < 500) {
            log.warn('Error refresh token');
          } else {
            log.error('refreshToken error', {e: e.message});
          }
        }
      }
    }
    return false;
  }

  async logout(): Promise<boolean> {
    try {
      await logoutRequest();
    } catch (e) {
      log.info('Cant send logout request!');
    }

    setIsGuest();
    resetLayout();
    WebSocketService.getInstance().disconnect();
    SavedFiltersService.destroy();
    clearAppStores();
    localStorageWrapper.removeItem(AuthService.AUTH_FORM_KEY);
    AuthService.deleteOtAuthCookieImpl();
    AuthService.deleteInitAuthCookieImpl();
    messengerLogout();
    return this.removeToken();
  }

  hasToken(): boolean {
    return !!this.getTokenInfo();
  }

  getClearToken(reloadToken?: boolean): string | null {
    const tokenInfo = this.getTokenInfo(reloadToken);
    if (tokenInfo) {
      return tokenInfo.token;
    }
    return null;
  }

  getRawToken(): string | undefined {
    return this.loadImpl();
  }

  getAuthBearerString(): string | null {
    const tokenInfo = this.getTokenInfo();
    if (tokenInfo) {
      return `Bearer ${tokenInfo.token}`;
    }
    return null;
  }

  removeToken(): boolean {
    this.deleteImpl();
    this.tokenInfo = null;
    return true;
  }

  setTokenInfo(token: string, refreshToken: string): boolean {
    if (!token) {
      return this.removeToken();
    }

    const {exp: tokenExpirationDate} = jwtDecode<JwtStructure>(refreshToken);
    const tokenInfo: TokenInfo = {
      token,
      refresh_token: refreshToken,
      refresh_token_expire_ts: tokenExpirationDate,
    };
    return this.saveTokenInfo(tokenInfo);
  }

  getTokenInfo(reload?: boolean): TokenInfo | null {
    if (Demo.isDemoMode()) {
      this.tokenInfo = Demo.getInstance().getTokenInfo();
      return this.tokenInfo;
    }

    if (this.tokenInfo !== null && !reload) {
      return this.tokenInfo;
    }

    const rawToken = this.getRawToken();
    if (rawToken) {
      try {
        const tokenInfo: TokenInfo = JSON.parse(
          AuthService.decodeToken(
            rawToken
          ) /* ,
          (key, value) => {
            if (
              key === 'permissions' &&
              value &&
              Array.isArray(value.user) &&
              Array.isArray(value.profile)
            ) {
              return {
                user: makePermissions(value.user),
                profile: makePermissions(value.profile),
              };
            }
            return value;
          } */
        );
        if (tokenInfo && tokenInfo.token) {
          // tokenInfo.user.permissions = new Set<Permissions>([]);
          // tokenInfo.user.permissions.delete(Permissions.PermissionViewCards);

          this.tokenInfo = tokenInfo;
          return tokenInfo;
        }
      } catch (e) {
        log.error('getTokenInfo error', {e: e.message});
        this.removeToken();
        return null;
      }
    }
    return null;
  }

  setSessionStorageValue(key: string, value: string): boolean {
    const tokenInfo = this.getTokenInfo();
    if (tokenInfo) {
      if (!tokenInfo.sessionStorage) {
        tokenInfo.sessionStorage = {};
      }
      tokenInfo.sessionStorage = {
        ...tokenInfo.sessionStorage,
        [key]: value,
      };
      return this.saveTokenInfo(tokenInfo);
    }

    return false;
  }

  private getCurrentSavedFilterKey = (
    userId?: UUID4,
    productId?: UUID4
  ): string | undefined => {
    const tokenInfo = this.getTokenInfo();
    const filterProductId =
      productId || ProductStore.getState()?.id || tokenInfo?.product_id;
    const filterUserId = userId || CurrentUserStore.getState()?.id;

    if (filterUserId && filterProductId) {
      return AuthService.savedFiltersKey(filterUserId, filterProductId);
    }
    return undefined;
  };

  getSavedFilters(
    userId?: UUID4,
    productId?: UUID4
  ): SessionFilters | undefined {
    const key = this.getCurrentSavedFilterKey(userId, productId);
    if (!key) {
      throw new Error('no key');
    }
    const savedRawData = localStorageWrapper.getItem(key);
    if (savedRawData) {
      try {
        const savedData: SessionFilters = JSON.parse(
          AuthService.decodeToken(savedRawData)
        );
        if (savedData) {
          return savedData;
        }
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }

  updateSavedFilters(
    partialFilters: Partial<SessionFilters>,
    userId?: UUID4,
    productId?: UUID4
  ): boolean {
    const key = this.getCurrentSavedFilterKey(userId, productId);

    if (key) {
      const savedFilter = this.getSavedFilters(userId, productId);

      const filters: SessionFilters = {
        ...(savedFilter || {pages: {}, tableSettings: {}}),
        ...partialFilters,
      };
      localStorageWrapper.setItem(
        key,
        AuthService.encodeToken(JSON.stringify(filters))
      );
      return true;
    }

    return false;
  }

  getSessionStorageValue(key: string): string | undefined {
    const tokenInfo = this.getTokenInfo();
    if (
      tokenInfo &&
      tokenInfo.sessionStorage &&
      tokenInfo.sessionStorage[key]
    ) {
      return tokenInfo.sessionStorage[key];
    }
    return undefined;
  }

  updateToken(token: string, refreshToken: string | null): boolean {
    const tokenInfo = this.getTokenInfo();
    if (tokenInfo) {
      tokenInfo.token = token;
      if (refreshToken !== null) {
        tokenInfo.refresh_token = refreshToken;
        const {exp: tokenExpirationDate} = jwtDecode<JwtStructure>(
          refreshToken
        );
        tokenInfo.refresh_token_expire_ts = tokenExpirationDate;
      }
      return this.saveTokenInfo(tokenInfo);
    }

    return false;
  }

  private saveTokenInfo(tokenInfo: TokenInfo): boolean {
    const cookieString = JSON.stringify(tokenInfo);

    this.tokenInfo = tokenInfo;
    updateAuthData({
      refreshTokenExpiredTs: this.tokenInfo.refresh_token_expire_ts,
      sessionStorage: tokenInfo.sessionStorage,
    });
    if (Demo.isDemoMode()) {
      return Demo.getInstance().setTokenInfo(tokenInfo);
    }
    return this.saveImpl(AuthService.encodeToken(cookieString));
  }

  private saveImpl(data: string): boolean {
    localStorageWrapper.setItem(this.tokenKey, data);

    return true;
  }

  private loadImpl(): string | undefined {
    const result = localStorageWrapper.getItem(this.tokenKey);
    if (result === null) {
      return undefined;
    }

    return result;
  }

  private deleteImpl() {
    localStorageWrapper.removeItem(this.tokenKey);
  }

  async checkToken(
    reloadToken?: boolean,
    forceReload?: boolean,
    doNotExtendSession?: boolean
  ): Promise<boolean> {
    if (Demo.isDemoMode()) {
      return true;
    }
    const token = this.getClearToken(reloadToken);
    if (!token) {
      if (reloadToken) {
        await this.logout();
      }
      return false;
    }
    const {exp: tokenExpirationDate} = jwtDecode<JwtStructure>(token);

    const isTokenExpired =
      Date.now() >
      tokenExpirationDate *
        1000; /* &&
      (window as Record<string, any>).xxx !== true */
    if (isTokenExpired || forceReload) {
      log.info('Token is expired');
      try {
        const result = await this.refreshToken(doNotExtendSession);
        if (!result) {
          await this.logout();
          return false;
        }
      } catch (e) {
        log.warn('Error during pre-check token', {
          error: e,
        });
        await this.logout();
        return false;
      }
    }
    return true;
  }

  checkPermission(permission: Permissions): boolean {
    const tokenInfo = this.getTokenInfo();
    const user = CurrentUserStore.getState();
    if (!user || !tokenInfo) {
      return false;
    }
    return (
      user.permissions.user.has(permission) ||
      user.permissions.profile.has(permission)
    );
  }

  checkFeature(feature: Features): boolean {
    const tokenInfo = this.getTokenInfo();
    const user = CurrentUserStore.getState();
    const store = FeatureStore.getState();
    if (!user || !tokenInfo || !store) {
      return false;
    }

    if (store.treatments) {
      if (UserFeatures[feature]) {
        if (store.userIsReady) {
          return (
            store.treatments[feature]?.treatment === TreatmentStatus.Enabled
          );
        }
        return false;
      }

      if (ClientFeatures[feature]) {
        if (store.clientIsReady) {
          return (
            store.treatments[feature]?.treatment === TreatmentStatus.Enabled
          );
        }
        return false;
      }

      if (ProductFeatures[feature]) {
        if (store.productIsReady) {
          return (
            store.treatments[feature]?.treatment === TreatmentStatus.Enabled
          );
        }
        return false;
      }

      if (store.commonIsReady) {
        return store.treatments[feature]?.treatment === TreatmentStatus.Enabled;
      }
    }
    return false;
  }

  checkPermissions(permissions: Permissions[]): boolean {
    const tokenInfo = this.getTokenInfo();
    const currentUser = CurrentUserStore.getState();
    if (!currentUser || !tokenInfo) {
      return false;
    }
    const {user, profile} = currentUser.permissions;
    return !permissions.some((permission) => {
      return !(user.has(permission) || profile.has(permission));
    });
  }

  async updateTokenProductId(
    productId: UUID4,
    profileId: UUID4
  ): Promise<boolean> {
    const tokenInfo = this.getTokenInfo();

    const availableProducts = await getAssignedProductsFx();
    if (tokenInfo) {
      if (!availableProducts.some(({id}) => id === productId)) {
        throw new Error(
          'This product does not exist in the list of available products.'
        );
      }

      tokenInfo.product_id = productId;
      tokenInfo.profile_id = profileId;
      return this.saveTokenInfo(tokenInfo);
    }

    return false;
  }

  getCurrentLocale(): UserLocale | null {
    const tokenInfo = this.getTokenInfo();
    const user = CurrentUserStore.getState();
    if (user && tokenInfo) {
      return user.locale;
    }

    return null;
  }

  isFullyVerified(): boolean {
    const tokenInfo = this.getTokenInfo();
    if (tokenInfo) {
      return App$.getState().fullyVerified;
    }

    return false;
  }

  saveOtAuthToken(expires: number): boolean {
    const tokenInfo = this.getTokenInfo();
    if (tokenInfo) {
      const rawTokenInfo = this.getRawToken();
      if (rawTokenInfo) {
        return AuthService.saveOtAuthCookieImpl(rawTokenInfo, expires);
      }
    }
    return false;
  }
}

export default AuthService;
