import {createEffect, createStore} from 'effector';
import {getSystemAccount} from '../../api';
import {IAccount} from '../../types';
import clearAppStores from '../clearStore';

export const SystemAccountStore = createStore<IAccount | null>(null);

SystemAccountStore.reset(clearAppStores);

export const reloadSystemAccount = createEffect<void, IAccount | null, Error>({
  name: 'reloadSystemAccount',
  handler: async (): Promise<IAccount | null> => {
    return getSystemAccount();
  },
});

SystemAccountStore.on(reloadSystemAccount.doneData, (Store, account) => {
  return account || null;
});

export default SystemAccountStore;
