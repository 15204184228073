import React, {useEffect, useRef} from 'react';
import usePrevious from '../../customHooks/usePreviuos';
import {UserLocale} from '../../types';
import addMessengerScript, {removeMessengerScript} from '../../utils/messenger';
import './Messenger.less';

interface MessengerProps {
  lang?: UserLocale;
  onLoad?: () => void;
  onHide?: () => void;
  onShow?: () => void;
  onMessagesChange?: (data: number) => void;
}

const Messenger: React.FC<React.PropsWithChildren<MessengerProps>> = ({
  lang,
  onLoad,
  onHide,
  onShow,
  onMessagesChange,
}) => {
  const timer = useRef<number | null>(null);
  const previousLang = usePrevious(lang);
  const previousLangRef = useRef(previousLang);
  const onLoadRef = useRef(onLoad);
  const onHideRef = useRef(onHide);
  const onShowRef = useRef(onShow);
  const onMessagesChangeRef = useRef(onMessagesChange);

  useEffect(() => {
    onLoadRef.current = onLoad;
  }, [onLoad, onLoadRef]);

  useEffect(() => {
    onHideRef.current = onHide;
  }, [onHide, onHideRef]);

  useEffect(() => {
    onShowRef.current = onShow;
  }, [onShow, onShowRef]);

  useEffect(() => {
    onMessagesChangeRef.current = onMessagesChange;
  }, [onMessagesChange, onMessagesChangeRef]);

  useEffect(() => {
    previousLangRef.current = previousLang;
  }, [previousLang, previousLangRef]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }

      removeMessengerScript();
    };
  }, [lang, timer]);

  useEffect(() => {
    timer.current = window.setTimeout(() => {
      addMessengerScript({
        config: {
          // hide_default_launcher: true,
        },
        onLoad: onLoadRef.current,
        onHide: onHideRef.current,
        onShow: onShowRef.current,
        onMessagesChange: onMessagesChangeRef.current,
      });
    }, 500);
  }, [
    lang,
    timer,
    previousLangRef,
    onLoadRef,
    onHideRef,
    onShowRef,
    onMessagesChangeRef,
  ]);

  return null;
};

export default Messenger;
