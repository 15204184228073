import {UUID4} from './common';
import {CurrencyType} from './currency';
import {TopUpHistoryFeeType} from './statement';

export type TFeeStatus = 'Canceled' | 'Completed' | 'WaitingForClearing';

export enum EFeeStatus {
  Canceled = 'Canceled',
  Completed = 'Completed',
  WaitingForClearing = 'WaitingForClearing',
}

export type TFeeType =
  | 'AccountTransferFee'
  | 'AdditionalBINConfigurationFee'
  | 'AdditionalBINMaintenanceFee'
  | 'AdditionalVirtualCardsFee'
  | 'AuthorizationATMBalanceInquiryFixedFee'
  | 'AuthorizationATMDeclinedFixedFee'
  | 'AuthorizationATMWithdrawalComplexFee'
  | 'AuthorizationATMWithdrawalEEAComplexFee'
  | 'AuthorizationATMWithdrawalFixedFee'
  | 'AuthorizationATMWithdrawalInternationalComplexFee'
  | 'AuthorizationATMWithdrawalPercentageFee'
  | 'AuthorizationDeclinedFixedFee'
  | 'AuthorizationEEAFixedFee'
  | 'AuthorizationForeignExchangePercentageFee'
  | 'AuthorizationInternationalFixedFee'
  | 'AvailableFundsRefundFee'
  | 'CardDeliveryFixedFee'
  | 'CardExpressDeliveryFixedFee'
  | 'CardIssuanceFixedFee'
  | 'CardRenewalFixedFee'
  | 'CardReplacementFixedFee'
  | 'CardUsageFixedFee'
  | 'ChargebackProcessingFee'
  | 'DedicatedBINRangeFee'
  | 'ExclusiveBINFee'
  | 'ExclusiveSharedBINFee'
  | 'IncomingSEPAPaymentFixedFee'
  | 'IncomingSEPAPaymentPercentageFee'
  | 'IndustryFee'
  | 'MerchantWhitelistFee'
  | 'MonthlyFeePerEmployee'
  | 'MonthlyServiceFee'
  | 'NewEmployeeAdditionFee'
  | 'OtherAdministrativeRequestFee'
  | 'PINChangeFixedFee'
  | 'PaymentEEAComplexFee'
  | 'PaymentEEAFixedFee'
  | 'PaymentInternationalComplexFee'
  | 'PaymentInternationalFixedFee'
  | 'PaymentPercentageFee'
  | 'PricingPlanFee'
  | 'Remind3DSPasswordFixedFee'
  | 'RemindPINFixedFee'
  | 'SharedBINRangeFee'
  | 'ZeroAuthorizationEEAFixedFee'
  | 'ZeroAuthorizationInternationalFixedFee';

export enum EFeeTypes {
  AccountTransferFee = 'AccountTransferFee',
  AdditionalBINConfigurationFee = 'AdditionalBINConfigurationFee',
  AdditionalBINMaintenanceFee = 'AdditionalBINMaintenanceFee',
  AdditionalVirtualCardsFee = 'AdditionalVirtualCardsFee',
  AuthorizationATMBalanceInquiryFixedFee = 'AuthorizationATMBalanceInquiryFixedFee',
  AuthorizationATMDeclinedFixedFee = 'AuthorizationATMDeclinedFixedFee',
  AuthorizationATMWithdrawalComplexFee = 'AuthorizationATMWithdrawalComplexFee',
  AuthorizationATMWithdrawalEEAComplexFee = 'AuthorizationATMWithdrawalEEAComplexFee',
  AuthorizationATMWithdrawalFixedFee = 'AuthorizationATMWithdrawalFixedFee',
  AuthorizationATMWithdrawalInternationalComplexFee = 'AuthorizationATMWithdrawalInternationalComplexFee',
  AuthorizationATMWithdrawalPercentageFee = 'AuthorizationATMWithdrawalPercentageFee',
  AuthorizationDeclinedFixedFee = 'AuthorizationDeclinedFixedFee',
  AuthorizationEEAFixedFee = 'AuthorizationEEAFixedFee',
  AuthorizationForeignExchangePercentageFee = 'AuthorizationForeignExchangePercentageFee',
  AuthorizationInternationalFixedFee = 'AuthorizationInternationalFixedFee',
  AvailableFundsRefundFee = 'AvailableFundsRefundFee',
  CardDeliveryFixedFee = 'CardDeliveryFixedFee',
  CardExpressDeliveryFixedFee = 'CardExpressDeliveryFixedFee',
  CardIssuanceFixedFee = 'CardIssuanceFixedFee',
  CardRenewalFixedFee = 'CardRenewalFixedFee',
  CardReplacementFixedFee = 'CardReplacementFixedFee',
  CardUsageFixedFee = 'CardUsageFixedFee',
  ChargebackProcessingFee = 'ChargebackProcessingFee',
  DedicatedBINRangeFee = 'DedicatedBINRangeFee',
  ExclusiveBINFee = 'ExclusiveBINFee',
  ExclusiveSharedBINFee = 'ExclusiveSharedBINFee',
  IncomingSEPAPaymentFixedFee = 'IncomingSEPAPaymentFixedFee',
  IncomingSEPAPaymentPercentageFee = 'IncomingSEPAPaymentPercentageFee',
  IndustryFee = 'IndustryFee',
  MerchantWhitelistFee = 'MerchantWhitelistFee',
  MonthlyFeePerEmployee = 'MonthlyFeePerEmployee',
  MonthlyServiceFee = 'MonthlyServiceFee',
  NewEmployeeAdditionFee = 'NewEmployeeAdditionFee',
  OtherAdministrativeRequestFee = 'OtherAdministrativeRequestFee',
  PINChangeFixedFee = 'PINChangeFixedFee',
  PaymentEEAComplexFee = 'PaymentEEAComplexFee',
  PaymentEEAFixedFee = 'PaymentEEAFixedFee',
  PaymentInternationalComplexFee = 'PaymentInternationalComplexFee',
  PaymentInternationalFixedFee = 'PaymentInternationalFixedFee',
  PaymentPercentageFee = 'PaymentPercentageFee',
  PricingPlanFee = 'PricingPlanFee',
  Remind3DSPasswordFixedFee = 'Remind3DSPasswordFixedFee',
  RemindPINFixedFee = 'RemindPINFixedFee',
  SharedBINRangeFee = 'SharedBINRangeFee',
  ZeroAuthorizationEEAFixedFee = 'ZeroAuthorizationEEAFixedFee',
  ZeroAuthorizationInternationalFixedFee = 'ZeroAuthorizationInternationalFixedFee',
}

export interface IFee {
  account_id: UUID4;
  amount: number;
  authorization_id: UUID4;
  canceled_at?: string;
  canceled_by?: UUID4;
  canceled_reason?: string;
  card_id: string;
  completed_at: string;
  completed_by: UUID4;
  created_at: string;
  created_by: string;
  currency_code: CurrencyType;
  id?: UUID4;
  person_id: UUID4;
  status: TFeeStatus;
  transaction_id: string;
  type: TFeeType;
  update_at?: string;
  update_by?: UUID4;
}

export interface IUnresolvedFee {
  id: string;
  type: TopUpHistoryFeeType;
  amount: number;
  currency_code: CurrencyType;
  completed_at: string;
  created_at: string;
}
