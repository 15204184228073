import {createEffect, createEvent, createStore} from 'effector';
import {getCurrentUser} from '../../api';
import SavedFiltersService from '../../services/SavedFiltersService';
import {StoredPermissions, StoredSystemUser, SystemUser} from '../../types';
import {makePermissions} from '../../utils/permissions';
import clearAppStores from '../clearStore';

export const CurrentUserStore = createStore<StoredSystemUser | null>(null);

CurrentUserStore.reset(clearAppStores);

export const reloadCurrentUser = createEffect<void, SystemUser, Error>({
  name: 'reloadCurrentUser',
  handler: async (): Promise<SystemUser> => {
    return getCurrentUser();
  },
});

CurrentUserStore.on([reloadCurrentUser.doneData], (Store, user) => {
  return {
    ...user,
    permissions: {
      user: makePermissions([...(user.permissions || [])]),
      profile: Store?.permissions?.profile || makePermissions([]),
    },
  };
});

reloadCurrentUser.done.watch(({result}) => {
  if (result) {
    SavedFiltersService.getInstance().reloadLocalState();
  }
});

export const serProductPermissions = createEvent<number[]>(
  'serProductPermissions'
);
CurrentUserStore.on(serProductPermissions, (Store, permissions) => {
  if (Store) {
    return {
      ...Store,
      permissions: {
        ...Store.permissions,
        product: makePermissions(permissions),
      },
    };
  }
  return Store;
});

export const updatePermissions = createEvent<StoredPermissions>(
  'updatePermissions'
);
CurrentUserStore.on(updatePermissions, (Store, permissions) => {
  if (Store) {
    return {
      ...Store,
      permissions: {
        ...Store.permissions,
        ...permissions,
      },
    };
  }
  return Store;
});

export type SetCurrentUserProps = {
  user: SystemUser;
  profilePermissions: number[];
};

export const setCurrentUser = createEvent<SetCurrentUserProps>(
  'setCurrentUser'
);

setCurrentUser.watch(({user}) => {
  if (user) {
    SavedFiltersService.getInstance().reloadLocalState();
  }
});

CurrentUserStore.on(setCurrentUser, (Store, {user, profilePermissions}) => {
  return {
    ...user,
    permissions: {
      user: makePermissions(user.permissions),
      profile: makePermissions(profilePermissions),
    },
  };
});

export default CurrentUserStore;
