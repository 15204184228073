import {IAccount, UpdateAccountLimitsParams} from '../../types';
import Demo, {DemoApiHandler} from '../demo';
import ObjectKeys from '../object';

const updateAccountLimitsHandler: DemoApiHandler = ({
  config,
  instance,
  url,
  data,
}) => {
  const {productId} = instance.getData();
  if (url?.params?.account_id) {
    const account = instance
      .getData()
      .accounts[productId].find((c) => c.id === url?.params?.account_id);
    if (account) {
      const limitData = data as {
        limits: Omit<UpdateAccountLimitsParams, 'accountId'>;
      };
      if (limitData && limitData.limits) {
        ObjectKeys(limitData.limits).forEach((limitKey) => {
          const limitValue = limitData.limits[limitKey];
          if (limitValue !== undefined) {
            account.limits[limitKey] = limitValue;
          }
        });
        instance.saveImpl(['accounts']);
        return Demo.makeDataResponse<{account: IAccount}>(config, {
          account,
        });
      }
    }
  }
  return Demo.makeUnprocessableEntityResponse(config);
};

export default updateAccountLimitsHandler;
