import {useCallback, useEffect, useRef} from 'react';

const useInterval = (
  callback: (stop: () => void) => void,
  interval: number
): [() => void, () => void] => {
  const callbackRef = useRef(callback);
  const intervalRef = useRef<number>();

  const stopInterval = useCallback(() => {
    window.clearInterval(intervalRef.current);
  }, []);

  const startInterval = useCallback(() => {
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current);
    }
    intervalRef.current = window.setInterval(() => {
      callbackRef.current(stopInterval);
    }, interval);
  }, [interval, stopInterval]);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    startInterval();

    return () => {
      stopInterval();
    };
  }, [startInterval, stopInterval]);

  return [stopInterval, startInterval];
};

export default useInterval;
