import {
  combine,
  createEffect,
  createEvent,
  createStore,
  Store,
  StoreWritable,
} from 'effector';
import moment from 'moment-timezone';
import AuthService from '../../services/AuthService';
import {AppState, IClient, IProduct, ProductsStoreType} from '../../types';
// import logger from '../../logger';
import SYSTEM_NOTIFICATION_DIALOG_TYPES from '../../types/systemDialogNotifications';
import {App$} from '../app';
import {clearAppStores} from '../clearStore';
import CurrentClientStore from '../currentClient';
import {
  ProductPricingPlanStore,
  ProductPricingPlanStoreType,
} from '../productPricingPlan';
import ServerStorageStore, {
  getSystemNotificationsDialogData,
  ServerStorage,
  setSystemNotificationsDialogState,
} from '../serverStorage';
import FeatureStore, {FeatureStoreType} from '../features';
import Features, {TreatmentStatus} from '../../constants/features';
import {ProductsStore} from '../products';
import {ProductStore} from '../product';

// const log = logger.module('HintsStorage');
const TimeBetweenShow = 10;
export type SystemDialogNotificationsInnerState = {
  canShowFrozenClientDialog: boolean;
  ts: number;
  running: boolean;
  started: boolean;
  lastClosed: number;
  loading: boolean;
  finished: boolean;
};

const getDefaultState = (): SystemDialogNotificationsInnerState => {
  return {
    canShowFrozenClientDialog: true,
    ts: moment().unix(),
    running: false,
    started: false,
    lastClosed: 0,
    loading: false,
    finished: false,
  };
};

const SystemDialogNotificationsInnerStateStore = createStore<SystemDialogNotificationsInnerState>(
  getDefaultState()
);

export const resetSystemDialogNotificationStore = createEvent(
  'resetSystemDialogNotificationStore'
);

SystemDialogNotificationsInnerStateStore.on(
  resetSystemDialogNotificationStore,
  () => {
    return {...getDefaultState(), started: true};
  }
);

SystemDialogNotificationsInnerStateStore.reset(clearAppStores);

type SystemDialogNotificationsState = SystemDialogNotificationsInnerState & {
  activeDialog: SYSTEM_NOTIFICATION_DIALOG_TYPES | null;
};

interface IDialog {
  id: SYSTEM_NOTIFICATION_DIALOG_TYPES;
  isAvailable: (dialogs: IDialog[]) => boolean;
  isVisible: (dialogs: IDialog[]) => boolean;
}

const SystemDialogNotificationsStateStore = combine<
  StoreWritable<SystemDialogNotificationsInnerState>,
  StoreWritable<ServerStorage>,
  Store<AppState>,
  Store<ProductPricingPlanStoreType>,
  StoreWritable<IClient | null>,
  Store<IProduct | null>,
  Store<ProductsStoreType>,
  Store<FeatureStoreType>,
  SystemDialogNotificationsState
>(
  SystemDialogNotificationsInnerStateStore,
  ServerStorageStore,
  App$,
  ProductPricingPlanStore,
  CurrentClientStore,
  ProductStore,
  ProductsStore,
  FeatureStore,
  (
    innerState,
    serverStorageState,
    appState,
    pricingPlan,
    clientStore,
    productStore,
    productsStore,
    featureStore
  ) => {
    const isDemo = appState.config.demo;
    const isReady = Boolean(
      innerState.started &&
        clientStore &&
        !appState.browserCompatibilityModalVisible
    );
    const isWaiting =
      innerState.loading ||
      (innerState.lastClosed !== 0 &&
        innerState.ts - innerState.lastClosed < TimeBetweenShow);
    const running = Boolean(
      isReady && serverStorageState.loaded && pricingPlan.plan !== undefined
    );
    const systemNotificationDialogsConfig =
      serverStorageState.system_notification_dialogs_config || {};
    // const clientConfig = clientStore
    //   ? systemNotificationDialogsConfig[clientStore.id] || {}
    //   : {};
    const clientCommonConfig = systemNotificationDialogsConfig._ || {};
    let activeDialog: SYSTEM_NOTIFICATION_DIALOG_TYPES | null = null;
    let isAtLeastOneAvailableDialog = false;

    const dialogs: IDialog[] = [
      {
        id: SYSTEM_NOTIFICATION_DIALOG_TYPES.FROZEN_CLIENT,
        isAvailable: () => {
          return (
            isReady &&
            appState.isClientFrozen &&
            innerState.canShowFrozenClientDialog
          );
        },
        isVisible: () => {
          return !isWaiting;
        },
      },
      {
        id: SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL,
        isAvailable: () => {
          const {show} = clientCommonConfig[
            SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL
          ] || {
            show: false,
          };

          return (
            running &&
            !show &&
            isDemo &&
            (!appState.sessionStorage ||
              !appState.sessionStorage[
                SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL
              ])
          );
        },
        isVisible: () => {
          return !isWaiting;
        },
      },
      {
        id: SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM,
        isAvailable: () => {
          const {
            show_count: showCount,
            full_show: fullShow,
          } = clientCommonConfig[
            SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM
          ] || {
            show_count: 0,
            full_show: 0,
            last_show: 0,
          };

          return (
            running &&
            !fullShow &&
            showCount < 4 &&
            (!appState.sessionStorage ||
              appState.sessionStorage[
                SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM
              ] === undefined)
          );
        },
        isVisible: () => {
          return !isWaiting;
        },
      },
      {
        id: SYSTEM_NOTIFICATION_DIALOG_TYPES.VERIFICATION_3DS,
        isAvailable: () => {
          const {show} = clientCommonConfig[
            SYSTEM_NOTIFICATION_DIALOG_TYPES.VERIFICATION_3DS
          ] || {
            show: false,
          };

          return Boolean(
            !isDemo &&
              running &&
              !show &&
              (!appState.sessionStorage ||
                appState.sessionStorage[
                  SYSTEM_NOTIFICATION_DIALOG_TYPES.VERIFICATION_3DS
                ] === undefined) &&
              featureStore.commonIsReady &&
              featureStore?.treatments &&
              featureStore.treatments[Features.Enable3DSOOB]?.treatment ===
                TreatmentStatus.Enabled
          );
        },
        isVisible: () => {
          return !isWaiting;
        },
      },
      {
        id: SYSTEM_NOTIFICATION_DIALOG_TYPES.PRODUCT_DEBTOR,
        isAvailable: () => {
          const productsWithDebt = productsStore.filter(
            (item) => item.product_additional_info?.has_debts
          );

          const isNotCurrentProductHasDebt = productsWithDebt.some(
            (item) =>
              item.product_additional_info?.has_debts &&
              item.id !== productStore?.id
          );

          return Boolean(
            !isDemo &&
              running &&
              isNotCurrentProductHasDebt &&
              productsWithDebt.length &&
              (!appState.sessionStorage ||
                appState.sessionStorage[
                  SYSTEM_NOTIFICATION_DIALOG_TYPES.PRODUCT_DEBTOR
                ] !== productStore?.id)
          );
        },
        isVisible: () => {
          return !isWaiting;
        },
      },
    ];

    dialogs.some((dialog) => {
      if (dialog.isAvailable(dialogs)) {
        isAtLeastOneAvailableDialog = true;

        if (dialog.isVisible(dialogs)) {
          activeDialog = dialog.id;

          return true;
        }
      }

      return false;
    });

    const finished = Boolean(
      appState.isGuest || (running && !isAtLeastOneAvailableDialog)
    );

    return {
      ...innerState,
      activeDialog,
      running,
      finished,
    };
  }
);

type CloseSystemNotificationDialogType = {
  dialog: SYSTEM_NOTIFICATION_DIALOG_TYPES;
  params?: Record<string, unknown>;
};

export const closeSystemNotificationDialog = createEffect<
  CloseSystemNotificationDialogType,
  void
>({
  name: 'closeSystemNotificationDialog',
  handler: async ({dialog, params}): Promise<void> => {
    if (dialog === SYSTEM_NOTIFICATION_DIALOG_TYPES.FROZEN_CLIENT) {
      //
    }

    if (dialog === SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM) {
      const currentConfig = await getSystemNotificationsDialogData(true);
      const salaryProgramConfig =
        currentConfig[SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM];
      const showCount = salaryProgramConfig
        ? salaryProgramConfig.show_count
        : 0;
      let fullShow = salaryProgramConfig
        ? salaryProgramConfig.full_show
        : false;

      AuthService.getInstance().setSessionStorageValue(
        SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM,
        '1'
      );

      if (params && (params.full_show as string) === '1') {
        fullShow = true;
      }

      await setSystemNotificationsDialogState({
        config: {
          [SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM]: {
            show_count: showCount + 1,
            last_show: moment().unix(),
            full_show: fullShow,
          },
        },
        isCommon: true,
      });
    }

    if (dialog === SYSTEM_NOTIFICATION_DIALOG_TYPES.WELCOME_SCREEN) {
      await setSystemNotificationsDialogState({
        config: {
          [SYSTEM_NOTIFICATION_DIALOG_TYPES.WELCOME_SCREEN]: {
            show: true,
          },
        },
      });
    }

    if (dialog === SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL) {
      if (!params || !params.close) {
        await setSystemNotificationsDialogState({
          config: {
            [SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL]: {
              show: true,
            },
          },
          isCommon: true,
        });
      } else {
        AuthService.getInstance().setSessionStorageValue(
          SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL,
          '1'
        );
      }
    }

    if (dialog === SYSTEM_NOTIFICATION_DIALOG_TYPES.VERIFICATION_3DS) {
      await setSystemNotificationsDialogState({
        config: {
          [SYSTEM_NOTIFICATION_DIALOG_TYPES.VERIFICATION_3DS]: {
            show: true,
          },
        },
        isCommon: true,
      });
    }

    if (dialog === SYSTEM_NOTIFICATION_DIALOG_TYPES.PRODUCT_DEBTOR) {
      const productId = ProductStore.getState()?.id ?? '';

      AuthService.getInstance().setSessionStorageValue(
        SYSTEM_NOTIFICATION_DIALOG_TYPES.PRODUCT_DEBTOR,
        productId
      );
    }

    return Promise.resolve();
  },
});

SystemDialogNotificationsInnerStateStore.on(
  closeSystemNotificationDialog,
  (state) => {
    return {
      ...state,
      loading: true,
    };
  }
);

SystemDialogNotificationsInnerStateStore.on(
  closeSystemNotificationDialog.done,
  (state, {params}) => {
    const ret: SystemDialogNotificationsInnerState = {
      ...state,
      lastClosed: moment().unix(),
    };

    if (params.dialog === SYSTEM_NOTIFICATION_DIALOG_TYPES.FROZEN_CLIENT) {
      ret.canShowFrozenClientDialog = false;
    }

    return ret;
  }
);

SystemDialogNotificationsInnerStateStore.on(
  closeSystemNotificationDialog.finally,
  (state) => {
    return {
      ...state,
      loading: false,
    };
  }
);

const updateTs = createEvent('updateTs');
SystemDialogNotificationsInnerStateStore.on(updateTs, (state) => {
  return {...state, ts: moment().unix()};
});

setInterval(() => {
  const {isGuest, fullyVerified} = App$.getState();

  if (!isGuest && fullyVerified) {
    updateTs();
  }
}, 1000);

export default SystemDialogNotificationsStateStore;
