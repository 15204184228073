import moment from 'moment-timezone';
import React, {useEffect} from 'react';
import useUser from '../../../customHooks/useUser';
import logger from '../../../logger';
import {updateFilterStoreDateFilter} from '../../../models/filterStore';
import SavedFiltersService from '../../../services/SavedFiltersService';

const log = logger.module('TimezoneManager');

const TimezoneManager: React.FC = () => {
  const user = useUser();
  useEffect(() => {
    if (user?.timezone_name) {
      log.info(`Set default timezone ${user?.timezone_name}`);
      moment.tz.setDefault(user?.timezone_name);
      updateFilterStoreDateFilter(
        SavedFiltersService.getInstance().getPeriodDateFilter()
      );
    }
  }, [user?.timezone_name]);

  return null;
};

export default TimezoneManager;
