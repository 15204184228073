import React from 'react';
import clsx from 'clsx';
import {Modal} from 'antd';
import {useIntl} from 'react-intl';
import Button from '../../../components/Button';
import useMedias from '../../../customHooks/useMedias';
import {modalWidth} from '../../../constants/styles';
import gcss from '../../../utils/styles';
import DemoRegistrationLink from '../../DemoRegistrationLink/DemoRegistrationLink';
import css from './TutorialCongratulationsModal.module.less';

interface Props {
  visible?: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const TutorialCongratulationsModal: React.FC<Props> = ({
  visible,
  onOk,
  onCancel,
}) => {
  const media = useMedias();
  const intl = useIntl();

  return (
    <Modal
      className={clsx(css.modal, 'ant-modal_sticky')}
      visible={visible}
      width={modalWidth}
      footer={false}
      destroyOnClose
      centered={!media.xs}
      onCancel={onCancel}
      afterClose={onCancel}
      maskClosable={false}
    >
      <div className={css.modal__image} />

      <div className={css.modal__title}>
        {intl.formatMessage({
          id: 'TutorialCongratulationsModal-Congratulations_',
        })}
      </div>

      <div className={css.modal__desc}>
        {intl.formatMessage({
          id:
            'TutorialCongratulationsModal-You_ve_completed_the_guide_tour__You_are_welcome_to_explore_t',
        })}
      </div>

      <Button
        className={clsx(
          'ant-btn_wide ant-btn_green-emerald',
          gcss('t_font-weight_700'),
          gcss('t_transform_uppercase'),
          media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger'
        )}
      >
        <span>{intl.formatMessage({id: 'App-Create_Free_account'})}</span>

        <DemoRegistrationLink onClick={onOk} />
      </Button>
    </Modal>
  );
};

export default TutorialCongratulationsModal;
