import Endpoints, {WLEndpoints} from '../constants/endpoints';
import {
  UUID4,
  IRole,
  IRoleList,
  IRolesListRequest,
  IUpdateRole,
  IFormEditRoleRequest,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const getRolesList = async (
  params: IRolesListRequest
): Promise<IRoleList> => {
  const queryParams: Record<string, string | number> = {};
  if (params.role_type) {
    queryParams.role_type = params.role_type;
  }
  if (params.orderField) {
    queryParams.order_field = params.orderField;
  }
  if (params.orderDirection) {
    queryParams.order_direction = params.orderDirection;
  }
  if (params.product_type) {
    queryParams.role_product_type = params.product_type;
  }
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(
        Endpoints.getRolesList,
        {},
        {
          ...queryParams,
          from_record: (params.currentPage - 1) * params.pageSize,
          records_count: params.pageSize,
        }
      )
    );

  return {
    items: response.data.roles,
    totalItems: response.data.total_records_number || undefined,
    pageSize: params.pageSize,
    currentPage: params.currentPage,
    orderField: params.orderField,
    orderDirection: params.orderDirection,
  };
};

export const getRolesListWl = async (
  params: IRolesListRequest
): Promise<IRoleList> => {
  const queryParams: Record<string, string | number> = {};
  if (params.role_type) {
    queryParams.role_type = params.role_type;
  }
  if (params.orderField) {
    queryParams.order_field = params.orderField;
  }
  if (params.orderDirection) {
    queryParams.order_direction = params.orderDirection;
  }
  if (params.product_type) {
    queryParams.role_product_type = params.product_type;
  }
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get({
      endpoint: WLEndpoints.getRolesList,
      queryParams: {
        ...queryParams,
        from_record: (params.currentPage - 1) * params.pageSize,
        records_count: params.pageSize,
      },
    });

  return {
    items: response.data.roles,
    totalItems: response.data.total_records_number || undefined,
    pageSize: params.pageSize,
    currentPage: params.currentPage,
    orderField: params.orderField,
    orderDirection: params.orderDirection,
  };
};

export const createRole = async (
  role: IFormEditRoleRequest
): Promise<IRole> => {
  const {
    data: response,
  } = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .post({endpoint: WLEndpoints.createRole}, {role});
  return response.role;
};

export const getRole = async (id: UUID4): Promise<IRole> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get({
      endpoint: WLEndpoints.getRoleDetails,
      params: {role_id: id},
    });
  return response.data.role;
};

export const deleteRole = async (id: UUID4): Promise<undefined> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .delete({
      endpoint: WLEndpoints.deleteRole,
      params: {role_id: id},
    });
  return response.data;
};

export const updateRole = async (params: IUpdateRole): Promise<IRole> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .patch(
      {
        endpoint: WLEndpoints.updateRole,
        params: {role_id: params.id},
      },
      {
        role: params.role,
        permissions: params.permissions,
      }
    );
  return response.data.role;
};
