import {Pages, TFiltersPresets, TypedMap} from './common';
import {IFiltersItemCheckbox} from './filter';
import HINTS_TYPES, {HINT_EVENT_TYPE} from './hints';

const FILTERS_PRESET_PREFIX = 'filters_presets_';

export type ServerStorageFiltersPresetsItem = Record<
  string,
  | string
  | number
  | boolean
  | (string | number | boolean | IFiltersItemCheckbox)[]
>;
export type ServerStorageFiltersPresetsItems = TFiltersPresets<ServerStorageFiltersPresetsItem>;

export const ServerStorageFiltersPresetsPages = {
  [Pages.BlackDashboard]: `${FILTERS_PRESET_PREFIX}${Pages.BlackDashboard}`,
  [Pages.BlackStatements]: `${FILTERS_PRESET_PREFIX}${Pages.BlackStatements}`,
  [Pages.BlackAccounts]: `${FILTERS_PRESET_PREFIX}${Pages.BlackAccounts}`,
  [Pages.Cards]: `${FILTERS_PRESET_PREFIX}${Pages.Cards}`,
  [Pages.Companies]: `${FILTERS_PRESET_PREFIX}${Pages.Companies}`,
  [Pages.FraudCases]: `${FILTERS_PRESET_PREFIX}${Pages.FraudCases}`,
  [Pages.FraudCaseHistory]: `${FILTERS_PRESET_PREFIX}${Pages.FraudCaseHistory}`,
  [Pages.FraudCaseAuthorizations]: `${FILTERS_PRESET_PREFIX}${Pages.FraudCaseAuthorizations}`,
  [Pages.CardHistory]: `${FILTERS_PRESET_PREFIX}${Pages.CardHistory}`,
  [Pages.CompanyHistory]: `${FILTERS_PRESET_PREFIX}${Pages.CompanyHistory}`,
  [Pages.CompanyCards]: `${FILTERS_PRESET_PREFIX}${Pages.CompanyCards}`,
  [Pages.CompanyAccounts]: `${FILTERS_PRESET_PREFIX}${Pages.CompanyAccounts}`,
  [Pages.CompanyRepresentatives]: `${FILTERS_PRESET_PREFIX}${Pages.CompanyRepresentatives}`,
  [Pages.CardAuthorizations]: `${FILTERS_PRESET_PREFIX}${Pages.CardAuthorizations}`,
  [Pages.CardTransactions]: `${FILTERS_PRESET_PREFIX}${Pages.CardTransactions}`,
  [Pages.CardTokens]: `${FILTERS_PRESET_PREFIX}${Pages.CardTokens}`,
  [Pages.Customers]: `${FILTERS_PRESET_PREFIX}${Pages.Customers}`,
  [Pages.CustomerCards]: `${FILTERS_PRESET_PREFIX}${Pages.CustomerCards}`,
  [Pages.CustomerHistory]: `${FILTERS_PRESET_PREFIX}${Pages.CustomerHistory}`,
  [Pages.CustomerCardBlockCalls]: `${FILTERS_PRESET_PREFIX}${Pages.CustomerCardBlockCalls}`,
  [Pages.CustomerAccounts]: `${FILTERS_PRESET_PREFIX}${Pages.CustomerAccounts}`,
  [Pages.BlackUsers]: `${FILTERS_PRESET_PREFIX}${Pages.BlackUsers}`,
  [Pages.BlackEmployees]: `${FILTERS_PRESET_PREFIX}${Pages.BlackEmployees}`,
  [Pages.BlackTopUp]: `${FILTERS_PRESET_PREFIX}${Pages.BlackTopUp}`,
  [Pages.BlackCardTransactions]: `${FILTERS_PRESET_PREFIX}${Pages.BlackCardTransactions}`,
  [Pages.ExpiringCards]: `${FILTERS_PRESET_PREFIX}${Pages.ExpiringCards}`,
  [Pages.PendingCards]: `${FILTERS_PRESET_PREFIX}${Pages.PendingCards}`,
  [Pages.PaymentTransactions]: `${FILTERS_PRESET_PREFIX}${Pages.PaymentTransactions}`,
  [Pages.PaymentAuthorizations]: `${FILTERS_PRESET_PREFIX}${Pages.PaymentAuthorizations}`,
  [Pages.WBApiKeys]: `${FILTERS_PRESET_PREFIX}${Pages.WBApiKeys}`,
  [Pages.AccountStatements]: `${FILTERS_PRESET_PREFIX}${Pages.AccountStatements}`,
  [Pages.AccountCards]: `${FILTERS_PRESET_PREFIX}${Pages.AccountCards}`,
  [Pages.AccountHistory]: `${FILTERS_PRESET_PREFIX}${Pages.AccountHistory}`,
  [Pages.Users]: `${FILTERS_PRESET_PREFIX}${Pages.Users}`,
  [Pages.BlackCards]: `${FILTERS_PRESET_PREFIX}${Pages.BlackCards}`,
  [Pages.Employees]: `${FILTERS_PRESET_PREFIX}${Pages.Employees}`,
} as const;

export type ServerStorageFiltersPresets = {
  [ServerStorageFiltersPresetsPages.blackDashboard]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.blackStatements]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.blackAccounts]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.cards]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.companies]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.fraudCases]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.fraudCaseHistory]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.fraudCaseAuthorizations]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.cardHistory]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.companyHistory]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.companyCards]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.companyAccounts]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.companyRepresentatives]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.cardAuthorizations]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.cardTransactions]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.cardTokens]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.customers]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.customerCards]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.customerHistory]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.customerCardBlockCalls]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.customerAccounts]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.blackUsers]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.blackEmployees]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.blackTopUp]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.blackCardTransactions]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.expiringCards]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.pendingCards]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.paymentTransactions]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.paymentAuthorizations]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.wbApiKeys]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.accountStatements]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.accountCards]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.accountHistory]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.users]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.blackCards]?: ServerStorageFiltersPresetsItems;
  [ServerStorageFiltersPresetsPages.employees]?: ServerStorageFiltersPresetsItems;
};

export type ServerStorageKeys =
  | 'verification_got_it'
  | 'saved_currency_request'
  | 'last_profile_id'
  | 'seo_source'
  | 'new_features'
  | 'filters'
  | 'is_showed_preset_menu_tooltip'
  | 'saved_delivery_address'
  | 'system_notification_dialogs_config'
  | 'layout_dashboard_type'
  | 'new_design'
  | 'hidden_product_info'
  | keyof ServerStorageFiltersPresets
  | HINTS_TYPES
  | HINT_EVENT_TYPE;
export type ServerStorageData = TypedMap<ServerStorageKeys, unknown>;
