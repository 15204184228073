import {v4} from 'uuid';
import moment from 'moment-timezone';
import {
  IAccount,
  IAccountStatement,
  MoneyTransferToAccountRequestParams,
} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const makeMoneyTransferToAccount: DemoApiHandler = ({
  config,
  instance,
  data,
}) => {
  const findAccountById = (accountId: string, arr: IAccount[]) => {
    return arr.find((acc) => acc.id === accountId);
  };

  const currentData = data as MoneyTransferToAccountRequestParams;
  const accounts = instance.getData().accounts[instance.getData().productId];

  const account = findAccountById(currentData.from_account_id, accounts);
  const receiver = findAccountById(currentData.to_account_id, accounts);

  if (currentData && account && receiver) {
    const updateAccount = (
      acc: IAccount,
      amount: number,
      isSender: boolean
    ) => ({
      ...acc,
      available_amount: isSender
        ? acc.available_amount - amount
        : acc.available_amount + amount,
      balance: isSender ? acc.balance - amount : acc.balance + amount,
    });

    const {statements} = instance.getData();

    const updatedSenderAccount = updateAccount(
      account,
      currentData.amount,
      true
    );

    const updatedReceiverAccount = updateAccount(
      receiver,
      currentData.amount,
      false
    );

    accounts.forEach((acc, index) => {
      if (
        acc.id === updatedSenderAccount.id ||
        acc.id === updatedReceiverAccount.id
      ) {
        accounts[index] =
          acc.id === updatedSenderAccount.id
            ? updatedSenderAccount
            : updatedReceiverAccount;
      }
    });

    const transactionId = v4();
    const transactionDate = moment().toISOString();

    const transaction: IAccountStatement = {
      id: transactionId,
      type: 'AccountAdjustment',
      date: transactionDate,
      group: 'Withdraw',
      transaction_amount: currentData.amount,
      transaction_currency_code: instance.getCurrentCurrency(),
      account_amount: 0,
      account_currency_code: instance.getCurrentCurrency(),
      has_payment_document_files: false,
      response: 'Approved',
      is_reversal: false,
      is_declined: false,
      is_cleared: false,
      merchant_name: '',
      purchase_date: transactionDate,
      status: 'Completed',
      sub_type: 'AccountToAccountTransfer',
      total_amount: currentData.amount,
      description: currentData.description,
    };

    statements.push({
      ...transaction,
      account_id: currentData.from_account_id,
      transaction_amount: -currentData.amount,
      transaction_currency_code: updatedSenderAccount.currency_code,
      account_amount: -currentData.amount,
      account_currency_code: updatedSenderAccount.currency_code,
      merchant_name: updatedSenderAccount.name,
    });

    statements.push({
      ...transaction,
      account_id: currentData.to_account_id,
      transaction_currency_code: updatedReceiverAccount.currency_code,
      account_amount: currentData.amount,
      account_currency_code: updatedReceiverAccount.currency_code,
      merchant_name: updatedReceiverAccount.name,
      group: 'Deposit',
    });

    instance.saveImpl(['accounts', 'statements']);

    return Demo.makeDataResponse<string>(config, 'ok');
  }

  return Demo.makeUnprocessableEntityResponse(config);
};

export default makeMoneyTransferToAccount;
