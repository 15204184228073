import * as React from 'react';
import type {ReactNode} from 'react';
import SimpleBarCore from 'simplebar-core';
import type {SimpleBarOptions} from 'simplebar-core';
import {useEffect, useMemo, useRef, useState} from 'react';
import './Scrollbar.less';
import clsx from 'clsx';

export interface Props extends SimpleBarOptions {
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  wrapperAttributes?: Partial<React.HTMLAttributes<HTMLDivElement>>;
  innerAttributes?: Partial<React.HTMLAttributes<HTMLDivElement>>;
  children?: ReactNode | ((a: SimpleBarCore | null) => ReactNode);
}

const Scrollbar = React.forwardRef<SimpleBarCore | null, Props>(
  (
    {
      forceVisible,
      clickOnTrack,
      scrollbarMinSize = 44,
      scrollbarMaxSize,
      classNames,
      ariaLabel,
      scrollableNode,
      contentNode,
      autoHide = false,
      onScroll,
      wrapperAttributes,
      innerAttributes,
      children,
    },
    ref
  ) => {
    const wrapperRef = React.useRef<HTMLDivElement | null>(null);
    const innerRef = React.useRef<HTMLDivElement | null>(null);
    const [instance, setInstance] = useState<SimpleBarCore | null>(null);

    const currentClassNames = useMemo(() => {
      return {
        ...SimpleBarCore.defaultOptions.classNames,
        ...classNames,
      } as typeof SimpleBarCore.defaultOptions['classNames'];
    }, [classNames]);

    const currentInnerAttributes = useMemo<
      Partial<React.HTMLAttributes<HTMLDivElement>>
    >(() => {
      return {
        ...innerAttributes,
        onScroll: (e) => {
          // console.log(e);

          if (innerRef.current) {
            innerRef.current.scrollTop = (e.target as HTMLDivElement).scrollTop;
          }

          if (innerAttributes?.onScroll) {
            innerAttributes.onScroll(e);
          }

          if (onScroll) {
            onScroll(e);
          }
        },
        className: `${currentClassNames.contentWrapper}${
          innerAttributes?.className ? ` ${innerAttributes.className}` : ''
        }`,
        tabIndex: 0,
        role: 'region',
        'aria-label': ariaLabel || SimpleBarCore.defaultOptions.ariaLabel,
      };
    }, [
      currentClassNames.contentWrapper,
      innerAttributes,
      onScroll,
      ariaLabel,
    ]);

    const currentChildren = useMemo(() => {
      return typeof children === 'function' ? children(instance) : children;
    }, [children, instance]);

    useEffect(() => {
      let simpleBarCore: SimpleBarCore | null;

      if (wrapperRef.current) {
        simpleBarCore = new SimpleBarCore(wrapperRef.current, {
          forceVisible,
          clickOnTrack,
          scrollbarMinSize,
          scrollbarMaxSize,
          classNames,
          ariaLabel,
          scrollableNode,
          contentNode,
          autoHide,
        });

        setInstance(simpleBarCore);

        if (typeof ref === 'function') {
          ref(simpleBarCore);
        } else if (ref) {
          // eslint-disable-next-line no-param-reassign
          ref.current = simpleBarCore;
        }
      }

      return () => {
        simpleBarCore?.unMount();
        simpleBarCore = null;

        if (typeof ref === 'function') {
          ref(null);
        } else if (ref) {
          // eslint-disable-next-line no-param-reassign
          ref.current = null;
        }
      };
    }, [
      ariaLabel,
      autoHide,
      classNames,
      clickOnTrack,
      contentNode,
      forceVisible,
      ref,
      scrollableNode,
      scrollbarMaxSize,
      scrollbarMinSize,
    ]);

    const scrollWidth = useRef(0);
    const scrollHeight = useRef(0);

    useEffect(() => {
      let t = 0;

      const observer = new MutationObserver(() => {
        clearTimeout(t);

        t = window.setTimeout(() => {
          if (
            scrollWidth.current !== innerRef.current?.scrollWidth ||
            scrollHeight.current !== innerRef.current?.scrollHeight
          ) {
            instance?.recalculate();

            scrollWidth.current = innerRef.current?.scrollWidth || 0;
            scrollHeight.current = innerRef.current?.scrollHeight || 0;
          }
        }, 100);
      });

      if (observer && innerRef?.current) {
        Array.from(innerRef.current?.children[0].children).forEach((item) => {
          observer.observe(item, {
            attributes: true,
            childList: true,
            subtree: true,
          });
        });
      }

      return () => {
        if (observer) {
          observer.disconnect();
        }
      };
    }, [instance]);

    return (
      <div
        {...wrapperAttributes}
        ref={wrapperRef}
        data-simplebar="init"
        className={clsx('simplebar', wrapperAttributes?.className)}
      >
        <div className={currentClassNames.wrapper}>
          <div className={currentClassNames.heightAutoObserverWrapperEl}>
            <div className={currentClassNames.heightAutoObserverEl} />
          </div>

          <div className={currentClassNames.mask}>
            <div className={currentClassNames.offset}>
              <div {...currentInnerAttributes} ref={innerRef}>
                <div className={currentClassNames.contentEl}>
                  {currentChildren}
                </div>
              </div>
            </div>
          </div>

          <div className={currentClassNames.placeholder} />
        </div>

        <div className={`${currentClassNames.track} simplebar-horizontal`}>
          <div className={currentClassNames.scrollbar} />
        </div>

        <div className={`${currentClassNames.track} simplebar-vertical`}>
          <div className={currentClassNames.scrollbar} />
        </div>
      </div>
    );
  }
);

export default Scrollbar;
