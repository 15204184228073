import {createEffect, createEvent, createStore} from 'effector';
import {Locale} from 'rc-picker/lib/interface';
import {createIntl} from 'react-intl';
import {IntlShape} from 'react-intl/src/types';
import {updateUserLang} from '../../api';
import logger from '../../logger';
import AuthService from '../../services/AuthService';
import {AvailableLanguages, UserLocale, LocaleMessages} from '../../types';
import {App$} from '../app';
import CurrentUserStore from '../currentUser';
import {redirect} from '../router';

const log = logger.module('i18n');

let initLang: UserLocale = AuthService?.getLastUserLocale() || 'en';
const r = /\/(\w{2})\//.exec(window.location.pathname);
if (r && AvailableLanguages.includes(r[1].toLowerCase() as UserLocale)) {
  [, initLang] = r as UserLocale[];
} else if (AuthService) {
  const currentLocale = AuthService.getInstance().getCurrentLocale();
  if (currentLocale) {
    initLang = currentLocale;
  }
} else {
  log.error('cyclic dependencies');
}

interface ILanguageStore {
  locale: UserLocale;
  momentLocale?: Locale;
  messages?: LocaleMessages;
  intl?: IntlShape;
}

export const CurrentLocaleStore = createStore<ILanguageStore>({
  locale: initLang as UserLocale,
});

export const setSystemLocale = createEvent<UserLocale>('setSystemLocale');

CurrentLocaleStore.on(setSystemLocale, (state, payload) => {
  return {
    ...state,
    locale: payload,
  };
});

export const setLocale = createEffect<UserLocale, UserLocale, Error>({
  name: 'setLocale',
  handler: async (locale): Promise<UserLocale> => {
    const {isGuest} = App$.getState();
    if (isGuest) {
      return locale;
    }

    const updatedUser = await updateUserLang(locale);
    AuthService.updateLastUserLocale(locale);

    return updatedUser.locale;
  },
});

CurrentUserStore.on(setLocale.doneData, (Store, locale) => {
  if (Store) {
    return {
      ...Store,
      locale,
    };
  }
  return Store;
});

export const setMomentLocale = createEvent<Locale>();

export const setLocaleMessages = createEvent<LocaleMessages>();

const localeRedirect = (lang: UserLocale) => {
  const r2 = /\/(\w{2})\//.exec(window.location.pathname);
  let url;
  if (r2) {
    [, initLang] = r2 as UserLocale[];
    url = window.location.pathname.replace(`/${r2[1]}/`, `/${lang}/`);
  } else {
    url = `${lang}${window.location.pathname}`;
  }
  if (window.location.search) {
    url += window.location.search;
  }
  redirect(url);
};

setLocale.watch((payload) => {
  localeRedirect(payload);
});

CurrentLocaleStore.on(setLocale.doneData, (state, locale) => {
  return {
    ...state,
    locale,
  };
});

CurrentLocaleStore.on(setLocale, (state, payload) => {
  return {
    ...state,
    locale: payload,
  };
});

CurrentLocaleStore.on(setLocaleMessages, (state, payload) => {
  return {
    ...state,
    messages: payload,
    intl: createIntl({
      locale: state.locale,
      messages: payload,
    }),
  };
});

CurrentLocaleStore.on(setMomentLocale, (state, payload) => {
  return {
    ...state,
    momentLocale: payload,
  };
});

export const getIntl = (): IntlShape => {
  const state = CurrentLocaleStore.getState();
  if (state.intl) {
    return state.intl;
  }
  return createIntl({
    messages: {},
    locale: state.locale,
  });
};
