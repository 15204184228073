import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import {
  ICreateApiKeyRequestParams,
  ICreateApiKeyResponse,
  IGetApiKeysListRequestParams,
  IGetApiKeysListResponse,
  UUID4,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const createApiKey = async (
  params: ICreateApiKeyRequestParams
): Promise<ICreateApiKeyResponse> => {
  const response: AxiosResponse<ICreateApiKeyResponse> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.createApiKey), params);

  return response.data;
};

export const getTotalApiKeys = async ({
  noCache,
}: {
  noCache: boolean;
}): Promise<number> => {
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(Endpoints.getApiKeysList, undefined, {
        from_record: 0,
        records_count: 1,
      }),
      {noCache}
    );

  return response.data.total_records_number;
};

export const getApiKeysList = async ({
  currentPage,
  pageSize,
  orderField,
  orderDirection,
  noCache,
  created_by_ids,
}: IGetApiKeysListRequestParams): Promise<IGetApiKeysListResponse> => {
  const params: Record<
    string,
    string | number | boolean | string[] | number[]
  > = {
    from_record: (currentPage - 1) * pageSize,
    records_count: pageSize,
    order_field: orderField || '',
    order_direction: orderDirection || '',
  };
  if (created_by_ids) {
    params.created_by_ids = created_by_ids;
  }
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(Endpoints.getApiKeysList, undefined, params, true),
      {
        noCache,
      }
    );

  return response.data;
};

export const deleteApiKey = async (apiKeyId: UUID4): Promise<boolean> => {
  const response: AxiosResponse<
    'ok' | 'err'
  > = await WallesterAxios.getInstance()
    .getBlackAxios()
    .delete(
      getApiPathParamsMaker(Endpoints.deleteApiKey, {api_key_id: apiKeyId})
    );

  return response.status === 200;
};

export default createApiKey;
