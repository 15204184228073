import {useStoreMap} from 'effector-react';
import {useMemo} from 'react';
import {App$} from '../models/app';

const defaultValue: Record<string, string> = {};
const useSessionStorageValue = (key: string): string | undefined => {
  const sessionStorage = useStoreMap({
    store: App$,
    keys: ['sessionStorage'],
    fn: (store$) => store$?.sessionStorage || defaultValue,
  });

  return useMemo(() => {
    return sessionStorage[key];
  }, [key, sessionStorage]);
};

export default useSessionStorageValue;
