import {useStoreMap} from 'effector-react';
import React, {useEffect, useMemo} from 'react';
import {Route, useLocation} from 'react-router-dom';
import Endpoints from '../../constants/endpoints';
import Features from '../../constants/features';
import Permissions from '../../constants/permissions';
import routes, {getRoutePath} from '../../constants/routes';
import useCheckEndpoints from '../../customHooks/useCheckEndpoints';
import useCheckFeatures from '../../customHooks/useCheckFeatures';
import useCheckPermissions from '../../customHooks/useCheckPermissions';
import useConfig from '../../customHooks/useConfig';
import useProductSettings from '../../customHooks/useProductSettings';
import {App$} from '../../models/app';
import {setLayoutState} from '../../models/layout';
import AuthService from '../../services/AuthService';
import {CustomRoutesPropsReact, RouteInfo} from '../../types';
import getUrl, {getCleanUrl} from '../../utils/language';
import {
  getRouteDemoRestricted,
  getRouteEndpoints,
  getRouteEndpointsPartialMode,
  getRouteFeatures,
  getRouteInfoByPath,
  getRoutePermissions,
  getRoutePermissionsPartialMode,
} from '../../utils/permissions';
import PermissionRedirect from './PermissionRedirect';
import Redirect from './Redirect';
import RouterWatcher from './RouterWatcher';

const PrivateRoute: React.FC<
  React.PropsWithChildren<CustomRoutesPropsReact>
> = ({
  component: Component,
  page,
  children,
  title,
  blackOnly,
  whiteOnly,
  allowUnverifiedUser,
  disabled,
  ...rest
}) => {
  const {isGuest, isWhiteLabeled, lastLogoutAt, fullyVerified} = useStoreMap({
    store: App$,
    keys: ['isGuest', 'mobileVerified', 'lastLogoutAt', 'fullyVerified'],
    fn: (store$) => ({
      isGuest: store$.isGuest,
      isWhiteLabeled: store$?.isWhiteLabeled,
      lastLogoutAt: store$.lastLogoutAt,
      fullyVerified: store$.fullyVerified,
    }),
  });
  const {program} = useProductSettings();
  const loc = useLocation();
  const {path} = rest;

  const [
    routePermissions,
    permissionPartialMode,
    routeRouteInfo,
    routeFeature,
    routeEndpoints,
    endpointPartialMode,
    demoRestricted,
  ]: [
    Permissions[],
    boolean,
    RouteInfo | undefined,
    Features | undefined,
    Endpoints[],
    boolean,
    boolean
  ] = useMemo(() => {
    const cleanPath = getCleanUrl(path);
    const permissions = getRoutePermissions(cleanPath);
    const feature = getRouteFeatures(cleanPath);
    const endpoints = getRouteEndpoints(cleanPath);
    const routeInfo = getRouteInfoByPath(cleanPath);
    return [
      Array.isArray(permissions) ? permissions : [permissions],
      getRoutePermissionsPartialMode(cleanPath),
      routeInfo,
      feature,
      Array.isArray(endpoints) ? endpoints : [endpoints],
      getRouteEndpointsPartialMode(cleanPath),
      getRouteDemoRestricted(cleanPath),
    ];
  }, [path]);

  const isHasPermissionAccess = useCheckPermissions(
    routePermissions,
    permissionPartialMode
  );

  const isHasFeatureAccess = useCheckFeatures(routeFeature);

  const isHasEndpointsAccess = useCheckEndpoints(
    routeEndpoints,
    endpointPartialMode
  );

  const {demo} = useConfig();

  const isAccessDenied = useMemo<boolean>(() => {
    if (demo && demoRestricted) {
      return true;
    }
    if (blackOnly && isWhiteLabeled) {
      return true;
    }
    if (whiteOnly && !isWhiteLabeled) {
      return true;
    }

    const allowUnverifiedUserFinal =
      routeRouteInfo !== undefined
        ? routeRouteInfo.allowUnverifiedUser === true
        : allowUnverifiedUser;

    if (
      !isWhiteLabeled &&
      !fullyVerified &&
      allowUnverifiedUserFinal !== true
    ) {
      return true;
    }

    if (
      routeRouteInfo &&
      routeRouteInfo.productProgramType !== undefined &&
      routeRouteInfo.productProgramType !== program
    ) {
      return true;
    }

    if (
      routePermissions.length === 0 &&
      !routeFeature &&
      routeEndpoints.length === 0
    ) {
      return false;
    }

    return (
      !isHasPermissionAccess ||
      !isHasFeatureAccess ||
      (!isHasEndpointsAccess &&
        (fullyVerified || allowUnverifiedUserFinal !== true))
    );
  }, [
    demoRestricted,
    demo,
    blackOnly,
    isWhiteLabeled,
    whiteOnly,
    routeRouteInfo,
    allowUnverifiedUser,
    fullyVerified,
    program,
    routePermissions.length,
    routeFeature,
    routeEndpoints.length,
    isHasPermissionAccess,
    isHasFeatureAccess,
    isHasEndpointsAccess,
  ]);

  useEffect(() => {
    setLayoutState({pageClass: page});
  }, [page]);

  if (disabled) {
    return null;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isGuest) {
          return (
            <Redirect to={AuthService.getGuestRedirectUrl(loc, lastLogoutAt)} />
          );
        }

        if (isAccessDenied) {
          return <PermissionRedirect to={getUrl(getRoutePath(routes.main))} />;
        }

        return (
          <RouterWatcher
            path={props.match.path}
            url={props.match.url}
            title={title}
          >
            {Component ? <Component {...props} /> : children}
          </RouterWatcher>
        );
      }}
    />
  );
};

export default PrivateRoute;
