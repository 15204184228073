const base64Encoder = (files: File[]): Promise<Record<string, string>[]> => {
  return new Promise((resolve) => {
    const result: Record<string, string>[] = [];

    files.forEach((file) => {
      const fr = new FileReader();
      fr.onloadend = () => {
        result.push({[file.name as string]: fr.result as string});
        if (result.length === files.length) {
          resolve(result);
        }
      };
      fr.readAsDataURL(file);
    });
  });
};

export const base64ToString = (data: string): string => {
  return decodeURIComponent(escape(window.atob(data)));
};

export const base64ToByte = (data: string): Uint8Array => {
  return Uint8Array.from(atob(data), (c) => c.charCodeAt(0));
};

export default base64Encoder;
