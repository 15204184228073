import {useUnit} from 'effector-react';
import {useEffect, useMemo} from 'react';
import {ProductStore} from '../models/product';
import {
  defaultProductSettings,
  getProductSettingsFx,
  ProductSettingsStore,
} from '../models/productSettings';
import {ProductSettings} from '../types';
import useIsGuest from './useIsGuest';

const useProductSettings = (): ProductSettings => {
  const product = useUnit(ProductStore);
  const isGuest = useIsGuest();
  const productSettings = useUnit(ProductSettingsStore);

  useEffect(() => {
    if (!isGuest && product?.id) {
      getProductSettingsFx();
    }
  }, [product?.id, isGuest]);

  return useMemo(() => {
    if (productSettings) {
      return productSettings;
    }

    return defaultProductSettings;
  }, [productSettings]);
};

export default useProductSettings;
