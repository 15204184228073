import {useStoreMap} from 'effector-react';
import React, {lazy, Suspense, useEffect} from 'react';
import {Router} from 'react-router';
import Loader from '../../components/Loader';
import {AppStatus} from '../../constants/app';
import routes, {InitialProductKey} from '../../constants/routes';
import useLang from '../../customHooks/useLang';
import {App$, initAppAction} from '../../models/app';
import {UUID4} from '../../types';
import componentLoader from '../../utils/componentLoader';
import Forbidden from '../Error/Forbidden';
import Maintenance from '../Error/Maintenance/Maintenance';
import LangProvider from '../i18n/LangProvider';
import LayoutController from '../LayoutController/LayoutController';
import GuestRoute from '../Router/GuestRoute';
import LocalizedRouter from '../Router/LocalizedRouter';
import LocalizedSwitch from '../Router/LocalizedSwitch';
import PermissionRedirect from '../Router/PermissionRedirect';
import PrivateRoute from '../Router/PrivateRoute';
import Redirect from '../Router/Redirect';
import RegularRoute from '../Router/RegularRoute';
import UnverifiedRoute from '../Router/UnverifiedRoute';
import SystemManager from '../SystemManager/SystemManager';
import useIsWhiteLabel from '../../customHooks/useIsWhiteLabel';
import getMainPath from '../../utils/route';
import NoPermission from '../NoPermission/NoPermission';
import cssLayout from '../LayoutDefault/LayoutDefault.module.less';
import './App.less';

const Components = lazy(
  () => import('../Components' /* webpackChunkName: "Components" */)
);

const NotFoundPage = lazy(() =>
  componentLoader(
    () => import('../Error/NotFound' /* webpackChunkName: "NotFoundPage" */)
  )
);

const ForbiddenPage = lazy(() =>
  componentLoader(
    () => import('../Error/Forbidden' /* webpackChunkName: "ForbiddenPage" */)
  )
);

const VerificationDashboard = lazy(() =>
  componentLoader(
    () =>
      import(
        '../VerificationDashboard' /* webpackChunkName: "VerificationDashboard" */
      )
  )
);

const MyCustomers = lazy(() =>
  componentLoader(
    () => import('../MyCustomers' /* webpackChunkName: "MyCustomers" */)
  )
);

const CardDetails = lazy(() =>
  componentLoader(
    () => import('../CardDetails' /* webpackChunkName: "CardDetails" */)
  )
);

const CustomerDetails = lazy(() =>
  componentLoader(
    () => import('../CustomerDetails' /* webpackChunkName: "CustomerDetails" */)
  )
);

const CreateCardholder = lazy(() =>
  componentLoader(
    () =>
      import('../CreateCardholder' /* webpackChunkName: "CreateCardholder" */)
  )
);

const CreateCompany = lazy(() =>
  componentLoader(
    () =>
      import(
        '../Companies/CreateCompany' /* webpackChunkName: "CreateCompany" */
      )
  )
);

const SearchResults = lazy(() =>
  componentLoader(
    () => import('../SearchResults' /* webpackChunkName: "SearchResults" */)
  )
);

const CreateCard = lazy(() =>
  componentLoader(
    () => import('../CreateCard' /* webpackChunkName: "CreateCard" */)
  )
);

const BlackCreateCard = lazy(() =>
  componentLoader(
    () => import('../BlackCreateCard' /* webpackChunkName: "BlackCreateCard" */)
  )
);

const BlackCreateExpensesCard = lazy(() =>
  componentLoader(
    () =>
      import(
        '../BlackCreateExpensesCard' /* webpackChunkName: "BlackCreateExpensesCard" */
      )
  )
);

const BlackCreateEmployeesCard = lazy(() =>
  componentLoader(
    () =>
      import(
        '../BlackCreateEmployeesCard' /* webpackChunkName: "BlackCreateEmployeesCard" */
      )
  )
);

const BlackUpdateEmployeesCard = lazy(() =>
  componentLoader(
    () =>
      import(
        '../BlackUpdateEmployeesCard' /* webpackChunkName: "BlackUpdateEmployeesCard" */
      )
  )
);

const Cards = lazy(() =>
  componentLoader(() => import('../Cards' /* webpackChunkName: "Cards" */))
);

const Companies = lazy(() =>
  componentLoader(
    () => import('../Companies' /* webpackChunkName: "Companies" */)
  )
);

const FraudCases = lazy(() =>
  componentLoader(() => import('../Fraud' /* webpackChunkName: "FraudCases" */))
);

const FraudResolution = lazy(() =>
  componentLoader(
    () =>
      import(
        '../Fraud/FraudResolution/FraudResolution' /* webpackChunkName: "FraudResolution" */
      )
  )
);

const FraudCaseDetails = lazy(() =>
  componentLoader(
    () =>
      import(
        '../Fraud/FraudCaseDetails' /* webpackChunkName: "FraudCaseDetails" */
      )
  )
);

const AccountDetails = lazy(() =>
  componentLoader(
    () => import('../AccountDetails' /* webpackChunkName: "AccountDetails" */)
  )
);

const CreateAccount = lazy(() =>
  componentLoader(
    () => import('../CreateAccount' /* webpackChunkName: "CreateAccount" */)
  )
);

const Dashboard = lazy(() =>
  componentLoader(
    () => import('../Dashboard' /* webpackChunkName: "Dashboard" */)
  )
);

const BlackDashboard = lazy(() =>
  componentLoader(
    () => import('../BlackDashboard' /* webpackChunkName: "BlackDashboard" */)
  )
);

const CurrencyRequest = lazy(() =>
  componentLoader(
    () => import('../CurrencyRequest' /* webpackChunkName: "CurrencyRequest" */)
  )
);

const TopUp = lazy(() =>
  componentLoader(() => import('../TopUp' /* webpackChunkName: "TopUp" */))
);

const TopUpHistory = lazy(() =>
  componentLoader(
    () => import('../TopUp/TopUpHistory' /* webpackChunkName: "TopUpHistory" */)
  )
);

const ExpiringCardsList = lazy(() =>
  componentLoader(
    () =>
      import(
        '../Cards/ExpiringCardsList' /* webpackChunkName: "ExpiringCardsList" */
      )
  )
);

const PendingCardsList = lazy(() =>
  componentLoader(
    () =>
      import(
        '../Cards/PendingCardsList' /* webpackChunkName: "PendingCardsList" */
      )
  )
);

const MoneyTransfer = lazy(() =>
  componentLoader(
    () => import('../MoneyTransfer' /* webpackChunkName: "MoneyTransfer" */)
  )
);

const CompanyInformationVerification = lazy(() =>
  componentLoader(
    () =>
      import(
        '../CompanyInformationVerification' /* webpackChunkName: "CompanyInformationVerification" */
      )
  )
);

const ClientIdentityVerification = lazy(() =>
  componentLoader(
    () =>
      import(
        '../ClientIdentityVerification/ClientIdentityVerification' /* webpackChunkName: "ClientIdentityVerification" */
      )
  )
);

const ClientCreatePerson = lazy(() =>
  componentLoader(
    () =>
      import(
        '../ClientIdentityVerification/ClientPerson/ClientPersonCreate' /* webpackChunkName: "ClientCreatePerson" */
      )
  )
);

const CompanyDocumentsVerification = lazy(() =>
  componentLoader(
    () =>
      import(
        '../CompanyDocumentsVerification' /* webpackChunkName: "CompanyDocumentsVerification" */
      )
  )
);

const AccountingPayments = lazy(() =>
  componentLoader(
    () =>
      import(
        '../AccountingPayments' /* webpackChunkName: "AccountingPayments" */
      )
  )
);

const BlackStatements = lazy(() =>
  componentLoader(
    () => import('../BlackStatements' /* webpackChunkName: "BlackStatements" */)
  )
);

const Employees = lazy(() =>
  componentLoader(
    () => import('../Employees' /* webpackChunkName: "Employees" */)
  )
);

const BlackCreateUser = lazy(() =>
  componentLoader(
    () => import('../BlackCreateUser' /* webpackChunkName: "BlackCreateUser" */)
  )
);

const User = lazy(() =>
  componentLoader(() => import('../User' /* webpackChunkName: "User" */))
);

const BlackUser = lazy(() =>
  componentLoader(
    () => import('../User/BlackUser' /* webpackChunkName: "BlackUser" */)
  )
);

const BlackAccounts = lazy(() =>
  componentLoader(
    () =>
      import(
        '../BlackAccounts/BlackAccounts' /* webpackChunkName: "BlackAccounts" */
      )
  )
);

const BlackAccountDetails = lazy(() =>
  componentLoader(
    () =>
      import(
        '../BlackAccounts/BlackAccountDetails' /* webpackChunkName: "BlackAccountDetails" */
      )
  )
);

const RolesList = lazy(() =>
  componentLoader(
    () => import('../RolesList' /* webpackChunkName: "RolesList" */)
  )
);

const CreateRole = lazy(() =>
  componentLoader(
    () => import('../CreateRole' /* webpackChunkName: "CreateRole" */)
  )
);

const RoleDetails = lazy(() =>
  componentLoader(
    () => import('../RoleDetails' /* webpackChunkName: "RoleDetails" */)
  )
);

const FeeDetails = lazy(() =>
  componentLoader(
    () => import('../FeeDetails' /* webpackChunkName: "FeeDetails" */)
  )
);

const AccountAdjustmentsDetails = lazy(() =>
  componentLoader(
    () =>
      import(
        '../AccountAdjustmentsDetails' /* webpackChunkName: "AccountAdjustmentsDetails" */
      )
  )
);

const AuthorizationDetailsPage = lazy(() =>
  componentLoader(
    () =>
      import(
        '../AccountingPayments/AuthorizationDetailsPage' /* webpackChunkName: "AuthorizationDetailsPage" */
      )
  )
);

const TransactionDetailsPage = lazy(() =>
  componentLoader(
    () =>
      import(
        '../AccountingPayments/TransactionDetailsPage' /* webpackChunkName: "TransactionDetailsPage" */
      )
  )
);

const CreateCardTransaction = lazy(() =>
  componentLoader(
    () =>
      import('../CreateTransaction' /* webpackChunkName: "CreateTransaction" */)
  )
);

const CreateAuthorization = lazy(() =>
  componentLoader(
    () =>
      import(
        '../CreateAuthorization' /* webpackChunkName: "CreateAuthorization" */
      )
  )
);

const Reports = lazy(() =>
  componentLoader(() => import('../Reports' /* webpackChunkName: "Reports" */))
);

const FilesList = lazy(() =>
  componentLoader(
    () => import('../FilesList' /* webpackChunkName: "FilesList" */)
  )
);

const CurrentUserDetails = lazy(() =>
  componentLoader(
    () =>
      import(
        '../CurrentUserDetails' /* webpackChunkName: "CurrentUserDetails" */
      )
  )
);

const CompanyDetails = lazy(() =>
  componentLoader(
    () => import('../CompanyDetails' /* webpackChunkName: "CompanyDetails" */)
  )
);

const CompanyDetailsWhiteLabel = lazy(() =>
  componentLoader(
    () =>
      import(
        '../Companies/CompanyDetails' /* webpackChunkName: "CompanyDetailsWhiteLabel" */
      )
  )
);

const RepresentativeDetails = lazy(() =>
  componentLoader(
    () =>
      import(
        '../Companies/RepresentativeDetails' /* webpackChunkName: "RepresentativeDetails" */
      )
  )
);

const CreateRepresentative = lazy(() =>
  componentLoader(
    () =>
      import(
        '../Companies/CreateRepresentative' /* webpackChunkName: "CreateRepresentative" */
      )
  )
);

const AcceptAgreement = lazy(() =>
  componentLoader(
    () => import('../AcceptAgreement' /* webpackChunkName: "AcceptAgreement" */)
  )
);

const PricingPlan = lazy(() =>
  componentLoader(
    () => import('../PricingPlan' /* webpackChunkName: "PricingPlan" */)
  )
);

const PricingPlanList = lazy(() =>
  componentLoader(
    () => import('../PricingPlanList' /* webpackChunkName: "PricingPlanList" */)
  )
);

const OrderPricingPlan = lazy(() =>
  componentLoader(
    () =>
      import('../OrderPricingPlan' /* webpackChunkName: "OrderPricingPlan" */)
  )
);

const WBApiKeys = lazy(() =>
  componentLoader(
    () => import('../WBApiKeys' /* webpackChunkName: "WBApiKeys" */)
  )
);

const WBCreateApiKeyIntroduction = lazy(() =>
  componentLoader(
    () =>
      import(
        '../WBCreateApiKeyIntroduction' /* webpackChunkName: "WBCreateApiKeyIntroduction" */
      )
  )
);

const WBCreateApiKeyCreation = lazy(() =>
  componentLoader(
    () =>
      import(
        '../WBCreateApiKeyCreation' /* webpackChunkName: "WBCreateApiKeyCreation" */
      )
  )
);

const WBCreateApiKeyGeneration = lazy(() =>
  componentLoader(
    () =>
      import(
        '../WBCreateApiKeyGeneration' /* webpackChunkName: "WBCreateApiKeyGeneration" */
      )
  )
);

const MultiplePayments = lazy(() =>
  componentLoader(
    () =>
      import('../MultiplePayments' /* webpackChunkName: "MultiplePayments" */)
  )
);

const MultiplePaymentsFileDetails = lazy(() =>
  componentLoader(
    () =>
      import(
        '../MultiplePayments/MultiplePaymentsFileDetails/MultiplePaymentsFileDetails' /* webpackChunkName: "MultiplePaymentsFileDetails" */
      )
  )
);

const WhiteList3DSAndBins = lazy(() =>
  componentLoader(
    () =>
      import(
        '../WhiteList3DSAndBins' /* webpackChunkName: "WhiteList3DSAndBins" */
      )
  )
);

const SignInPage = lazy(() =>
  componentLoader(() => import('../SignIn' /* webpackChunkName: "SignIn" */))
);

const SignUpPage = lazy(() =>
  componentLoader(() => import('../SignUp' /* webpackChunkName: "SignUp" */))
);

const DemoSignUpPage = lazy(() =>
  componentLoader(
    () => import('../DemoSignUp' /* webpackChunkName: "DemoSignUp" */)
  )
);

const App: React.FC = () => {
  const appStatus = useStoreMap({
    store: App$,
    keys: ['appStatus'],
    fn: ($store) => $store.appStatus,
  });
  const lang = useLang();
  const isWhiteLabel = useIsWhiteLabel();

  useEffect(() => {
    if (appStatus === AppStatus.initialized) {
      return;
    }

    if (appStatus === AppStatus.error) {
      return;
    }

    if (appStatus === AppStatus.wait) {
      const url = new URL(window.location.href);
      const productId: UUID4 | undefined =
        url.searchParams.get(InitialProductKey) || undefined;
      initAppAction(productId).then();
    }
  }, [appStatus]);

  if (AppStatus.wait === appStatus) {
    return (
      <LangProvider lang={lang}>
        <Loader size="large" transparent={false} />
      </LangProvider>
    );
  }

  if (AppStatus.forbidden === appStatus) {
    return (
      <>
        <Suspense fallback={<Loader size="large" transparent={false} />}>
          <LangProvider lang={lang}>
            <Forbidden />
          </LangProvider>
        </Suspense>
      </>
    );
  }

  if (AppStatus.error === appStatus) {
    return (
      <>
        <Suspense fallback={<Loader size="large" transparent={false} />}>
          <LangProvider lang={lang}>
            <Maintenance />
          </LangProvider>
        </Suspense>
      </>
    );
  }

  return (
    <>
      <Suspense fallback={<Loader size="large" transparent={false} />}>
        <LocalizedRouter
          RouterComponent={Router}
          defaultLanguage={lang}
          language={lang}
        >
          <LangProvider lang={lang}>
            <SystemManager />

            <LayoutController>
              <LocalizedSwitch>
                <RegularRoute exact path={routes.main.path}>
                  <PermissionRedirect to={getMainPath()} />
                </RegularRoute>
                <PrivateRoute
                  exact
                  path={routes.components.path}
                  component={Components}
                  title={() => {
                    return 'Components';
                  }}
                  page="p-components"
                />
                <PrivateRoute
                  exact
                  path={routes.cardDetailHistory.path}
                  component={CardDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Card_history'});
                  }}
                  page="p-card-history"
                />
                <PrivateRoute
                  exact
                  path={routes.search.path}
                  component={SearchResults}
                  title={(params, intl) => {
                    return params.query
                      ? intl.formatMessage(
                          {id: 'App-Search_result_for___phrase__'},
                          {phrase: params.query}
                        )
                      : intl.formatMessage({id: 'App-Search_result'});
                  }}
                  page="p-search-result"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.cardDetailAuthorizations.path}
                  component={CardDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Card_authorizations',
                    });
                  }}
                  page="p-card-authorizations"
                />
                <PrivateRoute
                  exact
                  path={routes.cardDetailTransactions.path}
                  component={CardDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: isWhiteLabel
                        ? 'App-Card_transactions'
                        : 'App-Payments',
                    });
                  }}
                  page={
                    isWhiteLabel
                      ? 'p-card-transactions'
                      : 'p-black-card-transactions'
                  }
                />
                <PrivateRoute
                  exact
                  path={routes.cardDetailUsers.path}
                  component={CardDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Card_users'});
                  }}
                  page="p-card-users"
                />
                <PrivateRoute
                  exact
                  path={routes.cardDetailLimits.path}
                  component={CardDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Card_limits'});
                  }}
                  page="p-card-limits"
                />
                <PrivateRoute
                  exact
                  path={routes.cardDetail3DSecure.path}
                  component={CardDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Card_3D_secure'});
                  }}
                  page="p-card-3dsecure"
                />
                <PrivateRoute
                  exact
                  blackOnly
                  path={routes.blackUsers.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Users'});
                  }}
                  component={CompanyDetails}
                  page="p-black-users"
                />
                <PrivateRoute
                  exact
                  path={routes.cardDetailTokens.path}
                  component={CardDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Tokens'});
                  }}
                  page="p-card-tokens"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.authorizationDetail.path}
                  component={AuthorizationDetailsPage}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Authorization_details',
                    });
                  }}
                  page="p-authorization"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.transactionDetail.path}
                  component={TransactionDetailsPage}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Transaction_details',
                    });
                  }}
                  page="p-transaction"
                />
                <PrivateRoute
                  exact
                  blackOnly
                  path={routes.currencyRequest.path}
                  component={CurrencyRequest}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Add_currency'});
                  }}
                  page="p-black-create-card"
                />
                <PrivateRoute
                  exact
                  blackOnly
                  path={routes.createCardTypes.path}
                  component={BlackCreateCard}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Order_new_card'});
                  }}
                  page="p-black-create-card"
                />
                <PrivateRoute
                  exact
                  blackOnly
                  path={routes.createExpensesCard.path}
                  component={BlackCreateExpensesCard}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Order_new_card'});
                  }}
                  page="p-black-create-expenses-card"
                />
                <PrivateRoute
                  exact
                  blackOnly
                  path={routes.createEmployeesCard.path}
                  component={BlackCreateEmployeesCard}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Invite_payroll_cardholder',
                    });
                  }}
                  page="p-black-payroll-cardholder"
                />
                <PrivateRoute
                  exact
                  blackOnly
                  path={routes.updateEmployeesCard.path}
                  component={BlackUpdateEmployeesCard}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Update_an_employee'});
                  }}
                  page="p-black-update-employees-card"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.createCard.path}
                  component={CreateCard}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Create_a_card'});
                  }}
                  page="p-create-card"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.createCardCompany.path}
                  component={CreateCard}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Create_a_card'});
                  }}
                  page="p-create-company-card"
                />
                <PrivateRoute
                  exact
                  path={routes.cardDetail.path}
                  component={CardDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Card_details'});
                  }}
                  page="p-card"
                />
                <RegularRoute
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Page_not_found'});
                  }}
                  exact
                  path={routes.notFound.path}
                  component={NotFoundPage}
                  page={cssLayout.layout_middle}
                />
                <RegularRoute
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Forbidden'});
                  }}
                  exact
                  path={routes.forbidden.path}
                  component={ForbiddenPage}
                  page={cssLayout.layout_middle}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.customerDetails.path}
                  component={CustomerDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Customer_details'});
                  }}
                  page="p-customer-details"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.customerCardsBlockCalls.path}
                  component={CustomerDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Customer_cards_block_calls',
                    });
                  }}
                  page="p-customer-cards-block-calls"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.customerHistory.path}
                  component={CustomerDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Customer_history'});
                  }}
                  page="p-customer-history"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.customerAlertsHistory.path}
                  component={CustomerDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Customer_alerts_history',
                    });
                  }}
                  page="p-customer-alerts-history"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.createCardholder.path}
                  component={CreateCardholder}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Create_cardholder'});
                  }}
                  page="p-create-cardholder"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.createCompany.path}
                  component={CreateCompany}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Add_company'});
                  }}
                  page="p-create-company"
                />
                <PrivateRoute
                  exact
                  path={routes.makeTransfer.path}
                  component={MoneyTransfer}
                  blackOnly
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Expense_payments'});
                  }}
                  page="p-money-transfer"
                />
                <PrivateRoute
                  exact
                  path={routes.makeAccountTransfer.path}
                  component={MoneyTransfer}
                  blackOnly
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Expense_payments'});
                  }}
                  page="p-money-transfer"
                />
                <PrivateRoute
                  exact
                  path={routes.makeToAccountTransfer.path}
                  component={MoneyTransfer}
                  blackOnly
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Expense_payments'});
                  }}
                  page="p-money-transfer"
                />
                <RegularRoute
                  exact={false}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Sign_in'});
                  }}
                  path={routes.signIn.path}
                  component={SignInPage}
                  page="p-sign-in p-web-auth"
                />
                <RegularRoute
                  exact={false}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Sign_up'});
                  }}
                  path={routes.signUp.path}
                  component={SignUpPage}
                  page="p-sign-up p-web-auth"
                />
                <RegularRoute
                  exact={false}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Sign_up'});
                  }}
                  path={routes.demoSignUp.path}
                  component={DemoSignUpPage}
                  page="p-demo-sign-up p-web-auth"
                />
                <UnverifiedRoute
                  exact
                  path={routes.verificationStatus.path}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Verification_status',
                    });
                  }}
                  component={VerificationDashboard}
                  page="p-verification-dashboard"
                />
                <UnverifiedRoute
                  exact
                  path={routes.companyInfoVerification.path}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Company_Info_Verification',
                    });
                  }}
                  component={CompanyInformationVerification}
                  page="p-company-info-verification"
                />
                <UnverifiedRoute
                  exact
                  path={routes.identityVerification.path}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Identity_Verification',
                    });
                  }}
                  component={ClientIdentityVerification}
                  page="p-identity-verification"
                />
                <UnverifiedRoute
                  exact
                  path={routes.identityVerificationCreatePerson.path}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Identity_Verification',
                    });
                  }}
                  component={ClientCreatePerson}
                  page="p-identity-verification-create-person"
                />
                <UnverifiedRoute
                  exact
                  path={routes.corporateDocumentsVerification.path}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Corporate_Documents_Verification',
                    });
                  }}
                  component={CompanyDocumentsVerification}
                  page="p-corporate-documents-verification"
                />

                <GuestRoute
                  exact={false}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Sign_up'});
                  }}
                  path={routes.signUp.path}
                  component={SignUpPage}
                  page="p-sign-up"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.myCustomers.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-My_cardholders'});
                  }}
                  component={MyCustomers}
                  page="p-my-customers"
                />
                <PrivateRoute
                  exact
                  path={routes.cards.path}
                  component={Cards}
                  page="p-cards"
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: isWhiteLabel ? 'App-Cards' : 'App-Expense_cards',
                    });
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.fraudCases.path}
                  component={FraudCases}
                  page="p-fraud_cases"
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Card_fraud_cases'});
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.fraudCaseDetailEdit.path}
                  component={FraudResolution}
                  page="p-fraud_cases_resolution"
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'Fraud-Change_resolution',
                    });
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.fraudCaseDetailAuthorizations.path}
                  component={FraudCaseDetails}
                  page="p-fraud_cases_details"
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'Fraud-Case_authorizations',
                    });
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.fraudCaseDetailAuthorizationDetail.path}
                  component={FraudCaseDetails}
                  page="p-fraud_cases_details"
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Authorization_details',
                    });
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.fraudCaseDetailScoring.path}
                  component={FraudCaseDetails}
                  page="p-fraud_cases_details"
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'Fraud-Case_scoring'});
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.fraudCaseDetailHistory.path}
                  component={FraudCaseDetails}
                  page="p-fraud_cases_details"
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'Fraud-Case_history'});
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.fraudCaseDetail.path}
                  component={FraudCaseDetails}
                  page="p-fraud_cases_details"
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'Fraud-Case_view'});
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.myCompanies.path}
                  component={Companies}
                  page="p-companies"
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Companies'});
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.companyDetailCards.path}
                  component={CompanyDetailsWhiteLabel}
                  page="p-company_details"
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Company_cards'});
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.companyRepresentativeDetails.path}
                  component={RepresentativeDetails}
                  page="p-company_details"
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Representative_details',
                    });
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.addCompanyRepresentative.path}
                  component={CreateRepresentative}
                  page="p-add_representative"
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Add_representative'});
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.companyDetailAccounts.path}
                  component={CompanyDetailsWhiteLabel}
                  page="p-company_details"
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Company_accounts'});
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.companyDetailRepresentatives.path}
                  component={CompanyDetailsWhiteLabel}
                  page="p-company_details"
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Company_representatives',
                    });
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.companyDetailHistory.path}
                  component={CompanyDetailsWhiteLabel}
                  page="p-company_details"
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Company_history'});
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.companyDetail.path}
                  component={CompanyDetailsWhiteLabel}
                  page="p-company_details"
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Company_details'});
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.companyDetailAlertsHistory.path}
                  component={CompanyDetailsWhiteLabel}
                  page="p-company_alerts_history"
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Company_alerts_history',
                    });
                  }}
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.accountDetails.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Account_details'});
                  }}
                  component={AccountDetails}
                  page="p-account-details"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.accountDetailsStatement.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Account_statement'});
                  }}
                  component={AccountDetails}
                  page="p-account-statement"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.accountDetailsLimits.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Account_limits'});
                  }}
                  component={AccountDetails}
                  page="p-account-limits"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.accountDetailsCards.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Account_cards'});
                  }}
                  component={AccountDetails}
                  page="p-account-cards"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.accountDetailsHistory.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Account_history'});
                  }}
                  component={AccountDetails}
                  page="p-account-history"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.createAccount.path}
                  component={CreateAccount}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Create_new_account'});
                  }}
                  page="p-create-account"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.createAccountCompany.path}
                  component={CreateAccount}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Create_new_account'});
                  }}
                  page="p-create-account-company"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.mainDashboard.path}
                  component={Dashboard}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Dashboard'});
                  }}
                  page="p-dashboard"
                />
                <PrivateRoute
                  exact
                  path={routes.blackDashboard.path}
                  component={BlackDashboard}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Dashboard'});
                  }}
                  blackOnly
                  page="p-black-dashboard"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.expiringCardsList.path}
                  component={ExpiringCardsList}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Expiring_cards'});
                  }}
                  page="p-expiring-cards"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.pendingCardsList.path}
                  component={PendingCardsList}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Pending_orders'});
                  }}
                  page="p-pending-cards"
                />
                <PrivateRoute
                  exact
                  path={routes.mainTopUp.path}
                  component={TopUp}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: isWhiteLabel
                        ? 'App-Top_up'
                        : 'App-Top_up_your_account',
                    });
                  }}
                  page={isWhiteLabel ? 'p-top-up' : 'p-black-top-up'}
                />
                <PrivateRoute
                  exact
                  path={routes.mainTopUpHistory.path}
                  component={TopUpHistory}
                  blackOnly
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Top_up_history'});
                  }}
                  page="p-top-up-history"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.accountingPayments.path}
                  component={AccountingPayments}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Payments'});
                  }}
                  page="p-accounting-payments"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.accountingPaymentsAuthorizations.path}
                  component={AccountingPayments}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Payments'});
                  }}
                  page="p-accounting-payments-authorizations"
                />
                <PrivateRoute
                  exact
                  path={routes.blackAccounts.path}
                  component={BlackAccounts}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Accounts'});
                  }}
                  blackOnly
                  page="p-black-accounts"
                />
                <PrivateRoute
                  exact
                  path={routes.blackAccountDetails.path}
                  component={BlackAccountDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Account_Details'});
                  }}
                  blackOnly
                  page="p-black-account-details"
                />
                <PrivateRoute
                  exact
                  path={routes.blackStatements.path}
                  component={BlackStatements}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Statement'});
                  }}
                  blackOnly
                  page="p-black-statements"
                />
                <PrivateRoute
                  exact
                  path={routes.myEmployees.path}
                  component={Employees}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Payroll_cards'});
                  }}
                  blackOnly
                  page="p-black-payroll-cards"
                />
                <PrivateRoute
                  exact
                  path={routes.blackAddUser.path}
                  component={BlackCreateUser}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Add_new_user'});
                  }}
                  blackOnly
                  page="p-black-add-user"
                />
                <PrivateRoute
                  exact
                  path={routes.blackUserDetails.path}
                  component={BlackUser}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-User_details'});
                  }}
                  page="p-black-user"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.administrationUserDetails.path}
                  component={User}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-User_details'});
                  }}
                  page="p-administration-user"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.administrationUserHistory.path}
                  component={User}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-History'});
                  }}
                  page="p-administration-user-history"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.rolesListProduct.path}
                  component={RolesList}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Roles'});
                  }}
                  page="p-roles-product"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.rolesListGlobal.path}
                  component={RolesList}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Roles'});
                  }}
                  page="p-roles-global"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.administrationUsersList.path}
                  component={RolesList}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Users'});
                  }}
                  page="p-administration-users"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.createRole.path}
                  component={CreateRole}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id:
                        params.type === 'global'
                          ? 'App-Create_global_role'
                          : 'App-Create_product_role',
                    });
                  }}
                  page="p-create-role"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.roleDetails.path}
                  component={RoleDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Role_details'});
                  }}
                  page="p-role"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.feeDetails.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Fee_Details'});
                  }}
                  component={FeeDetails}
                  page="p-fee-details"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.accountAdjustmentsDetails.path}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Account_Adjustments_Details',
                    });
                  }}
                  component={AccountAdjustmentsDetails}
                  page="p-fee-details"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.createCardDetailTransactions.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Create_transaction'});
                  }}
                  component={CreateCardTransaction}
                  page="p-create-transaction"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.createAuthorization.path}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Create_Authorization',
                    });
                  }}
                  component={CreateAuthorization}
                  page="p-create-authorization"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.reports.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Reports'});
                  }}
                  component={Reports}
                  page="p-reports"
                />
                <PrivateRoute
                  exact
                  whiteOnly
                  path={routes.accountingDirectoryFilesList.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Files_list'});
                  }}
                  component={FilesList}
                  page="p-extract-files-list"
                />
                <PrivateRoute
                  exact
                  path={routes.currentUserDetails.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-User_details'});
                  }}
                  component={CurrentUserDetails}
                  page="p-current-user"
                />

                <PrivateRoute
                  exact
                  blackOnly
                  path={routes.companyProfileDetails.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Company_profile'});
                  }}
                  component={CompanyDetails}
                  page="p-black-company-profile"
                />
                <PrivateRoute
                  exact
                  blackOnly
                  path={routes.companyBoardMembersAndShareholders.path}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Board_members_and_shareholders',
                    });
                  }}
                  component={CompanyDetails}
                  page="p-black-company-members"
                />
                <PrivateRoute
                  exact
                  blackOnly
                  path={routes.companyLimits.path}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Limits'});
                  }}
                  component={CompanyDetails}
                  page="p-black-company-limits"
                />
                <PrivateRoute
                  exact
                  path={routes.acceptAgreement.path}
                  component={AcceptAgreement}
                  title={(params, intl) => {
                    return intl.formatMessage({
                      id: 'App-Accept_the_Agreement',
                    });
                  }}
                  blackOnly
                  page="p-accept-agreement"
                />
                <PrivateRoute
                  exact
                  path={routes.pricingPlan.path}
                  component={PricingPlan}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Pricing_plan'});
                  }}
                  page="p-pricing-plan"
                  blackOnly
                />
                <PrivateRoute
                  exact
                  path={routes.pricingPlanList.path}
                  component={PricingPlanList}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Pricing_plan'});
                  }}
                  page="p-pricing-plans"
                  blackOnly
                />
                <PrivateRoute
                  exact
                  path={routes.orderPackage.path}
                  component={OrderPricingPlan}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Order_new_package'});
                  }}
                  page="p-order-package"
                  blackOnly
                />
                <PrivateRoute
                  exact
                  path={routes.wbApiKeys.path}
                  component={WBApiKeys}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-API'});
                  }}
                  page="p-api-keys"
                  blackOnly
                />
                <PrivateRoute
                  exact
                  path={routes.wbCreateApiKeyIntroduction.path}
                  component={WBCreateApiKeyIntroduction}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Create_API_key'});
                  }}
                  page="p-create-api-key-introduction"
                  blackOnly
                />
                <PrivateRoute
                  exact
                  path={routes.wbCreateApiKeyCreation.path}
                  component={WBCreateApiKeyCreation}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Create_API_key'});
                  }}
                  page="p-create-api-key-creation"
                  blackOnly
                />
                <PrivateRoute
                  exact
                  path={routes.wbCreateApiKeyGeneration.path}
                  component={WBCreateApiKeyGeneration}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Create_API_key'});
                  }}
                  page="p-create-api-key-generation"
                  blackOnly
                />
                <PrivateRoute
                  exact
                  path={routes.multiplePayments.path}
                  component={MultiplePayments}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Payroll_payments'});
                  }}
                  page="p-multiple-payments"
                  blackOnly
                />
                <PrivateRoute
                  exact
                  path={routes.multiplePaymentsSingle.path}
                  component={MultiplePayments}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Payroll_payments'});
                  }}
                  page="p-multiple-payments-single"
                  blackOnly
                />
                <PrivateRoute
                  exact
                  path={routes.multiplePaymentsFile.path}
                  component={MultiplePaymentsFileDetails}
                  title={(params, intl) => {
                    return intl.formatMessage({id: 'App-Multiple_payment'});
                  }}
                  page="p-multiple-payments-file"
                  blackOnly
                />
                <PrivateRoute
                  exact
                  path={routes.whiteList3DSAndBins.path}
                  component={WhiteList3DSAndBins}
                  title={(_, intl) => {
                    return intl.formatMessage({
                      id: 'App-3DS_Whitelist___BINs_Request',
                    });
                  }}
                  page="p-whitelist-3ds-and-bins"
                  blackOnly
                />
                <PrivateRoute
                  exact
                  path={routes.noPermission.path}
                  component={NoPermission}
                  page="p-no-permission"
                />
                <RegularRoute exact path="*">
                  <Redirect to={routes.notFound.path} />
                </RegularRoute>
              </LocalizedSwitch>
            </LayoutController>
          </LangProvider>
        </LocalizedRouter>
      </Suspense>
    </>
  );
};

export default App;
