import Rollbar, {LogArgument} from 'rollbar';
import zerg from 'zerg';
import {TLogMessage} from 'zerg/dist/types';
import config from '../constants/config';
import Demo from '../utils/demo';
// import config from '../constants/config';

const SENTRY_LEVEL_MAP: Record<
  string,
  'debug' | 'info' | 'warn' | 'error' | 'critical'
> = {
  debug: 'debug',
  verbose: 'debug',
  info: 'info',
  warn: 'warn',
  error: 'error',
  fatal: 'critical',
  metric: 'info',
  event: 'info',
};

interface TCopyErrorsParams {
  name: string;
  message: string;
  originalError: Error;
}
function copyError({name, message, originalError}: TCopyErrorsParams) {
  const error = new Error();

  error.name = name;
  error.message = message;
  error.stack = originalError.stack;

  return error;
}

type Trace = {filename?: string; method?: string};

function extractException(logMessage: TLogMessage) {
  if (logMessage.extendedData && logMessage.extendedData.error) {
    return logMessage.extendedData.error;
  }
  return null;
}

const rollbar =
  !Demo.isDemoMode() && config.rollbarEnable && config.rollbarToken
    ? new Rollbar({
        accessToken: config.rollbarToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
        stackTraceLimit: 100,
        environment: config.appEnv,
        // version: config.version,
        ignoredMessages: [
          '^ResizeObserver',
          '^Script error',
          '^Timeout$',
          'Network Error',
          'Error Request aborted',
          '^"Timeout"$',
          ...(config.isProd ? [] : ['^Chunk load error']),
        ],
        checkIgnore: (
          isUncaught: boolean,
          args: LogArgument[],
          item: object
        ) => {
          const data = item as {
            body?: {
              trace?: {
                exception?: {class?: string};
                frames?: Trace[];
              };
              frames?: Trace[];
            };
            custom?: Record<string, unknown>;
            client?: {
              javascript?: {
                browser?: string;
              };
            };
          };
          if (data?.body?.trace?.exception?.class === '(unknown)') {
            return true;
          }

          let lastTrace: Trace | null = null;

          if (
            (data?.client?.javascript?.browser || '')
              .toLocaleLowerCase()
              .includes('adsbot-google')
          ) {
            return true;
          }

          if (data.custom && data.custom.type === 'sw') {
            const stopWords: string[] = [
              'rejected',
              'abort',
              'notsupportederror',
              'securityerror',
              '407',
              '403',
              'addEventListener',
              'failed to access storage',
              'current origin',
            ];
            const msg = ((data.custom.message as string) || '').toLowerCase();
            if (
              stopWords.some((stopWord) => {
                return msg.includes(stopWord);
              })
            )
              return true;
          }

          if (
            data?.body?.frames &&
            data?.body?.frames[data?.body?.frames.length - 1]
          ) {
            lastTrace = data?.body?.frames[data?.body?.frames.length - 1];
          } else if (
            data?.body?.trace?.frames &&
            data?.body?.trace?.frames[data?.body?.trace?.frames.length - 1]
          ) {
            lastTrace =
              data?.body?.trace?.frames[data?.body?.trace?.frames.length - 1];
          }
          if (
            lastTrace &&
            lastTrace.filename &&
            (lastTrace.filename.match('webkit-masked-url') ||
              lastTrace.filename.match(/extension:\/\//))
          ) {
            return true;
          }

          return false;
        },
        // enabled: config.isProd, // TODO: Uncomment
      })
    : null;

export function rollbarHandler(logMessage: TLogMessage): void {
  if (rollbar) {
    const level = SENTRY_LEVEL_MAP[logMessage.level];

    const error = extractException(logMessage);
    if (error && error instanceof Error) {
      const errorCopy = copyError({
        name: logMessage.message,
        message: `${error.name} ${error.message || ''}`,
        originalError: error,
      });
      rollbar[level](errorCopy, {
        module: logMessage.moduleName,
        ...(logMessage.extendedData || {}),
      });
    } else {
      rollbar[level](logMessage.message, {
        module: logMessage.moduleName,
        ...(logMessage.extendedData || {}),
      });
    }
  }
}

const rollbarTransport = zerg.createListener({
  handler: rollbarHandler,
  levels: ['error', 'warn'],
});

export default rollbarTransport;
