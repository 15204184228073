import clsx from 'clsx';
import {Modal} from 'antd';
import React, {useCallback, useMemo, useState} from 'react';
import {IntlShape} from 'react-intl';
import Button from '../../components/Button';
import {modalWidth} from '../../constants/styles';
import useMedias from '../../customHooks/useMedias';
import gcss from '../../utils/styles';
import css from './DemoModeNotification.module.less';

export type DemoModeNotificationPropsMode =
  | 'upload_photo'
  | 'limit_increase'
  | 'currency'
  | 'invite_cardholder'
  | 'add_user'
  | 'topup'
  | 'api'
  | 'card_user'
  | 'receipts'
  | 'search'
  | '3ds_whitelist_and_bins'
  | 'create_payment'
  | 'stablecoin';

export type DemoModeNotificationIcon = 'welcome' | 'showing' | 'demo' | 'edit';

export type DemoModeNotificationProps = {
  intl: IntlShape;
  mode: DemoModeNotificationPropsMode;
  icon?: DemoModeNotificationIcon;
  onClose?: () => void;
  onSuccess?: () => void;
  afterClose?: () => void;
};

const DemoModeNotification: React.FC<DemoModeNotificationProps> = ({
  intl,
  mode,
  icon,
  onClose,
  onSuccess,
  afterClose,
}) => {
  const media = useMedias();

  const [visible, setVisible] = useState(true);

  const description = useMemo(() => {
    let key: string | null = null;

    switch (mode) {
      case 'upload_photo':
        key =
          'DemoModeNotification-Just_a_reminder_that_you_re_using_the_demo_';
        break;
      case 'limit_increase':
        key =
          'DemoModeNotification-As_this_is_a_demo_environment__the_limit_request';
        break;
      case 'currency':
        key =
          'DemoModeNotification-You_re_using_our_demo_environment__so_all_extern_currency';
        break;
      case '3ds_whitelist_and_bins':
        key =
          'DemoModeNotification-You_re_using_our_demo_environment__so_all_extern';
        break;
      case 'invite_cardholder':
        key =
          'DemoModeNotification-We_will_not_send_invitations_to_this_particular_';
        break;
      case 'add_user':
        key =
          'DemoModeNotification-This_is_a_demo_environment__so_there_will_be_no_';
        break;
      case 'topup':
        key =
          'DemoModeNotification-Because_this_is_a_demo_environment__you_cannot_p';
        break;
      case 'api':
        key =
          'DemoModeNotification-This_environment_is_intended_as_a_demonstration_';
        break;
      case 'card_user':
        key =
          'DemoModeNotification-We_re_sorry__but_we_can_t_let_you_add_a_card_use';
        break;
      case 'receipts':
        key =
          'DemoModeNotification-Oops__this_action_cannot_be_done_in_the_demo_env';
        break;
      case 'search':
        key =
          'DemoModeNotification-As_you_explore_our_demo_environment__we_d_like_t';
        break;
      case 'create_payment':
        key =
          'DemoModeNotification-Oh__once_again__demo_environment__and_some_funct';
        break;
      case 'stablecoin':
        key =
          'DemoModeNotification-Oh__once_again__demo_environment__and_some_funct';
        break;
      default:
        break;
    }
    if (key) {
      return intl.formatMessage({
        id: key,
      });
    }
    return 'DEMO';
  }, [mode, intl]);

  const handleCancel = useCallback(() => {
    setVisible(false);

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleSuccess = useCallback(() => {
    setVisible(false);

    if (onSuccess) {
      onSuccess();
    }
  }, [onSuccess]);

  return (
    <Modal
      className={clsx(css.modal, 'ant-modal_sticky')}
      visible={visible}
      width={modalWidth}
      footer={false}
      closable={false}
      onCancel={handleCancel}
      destroyOnClose
      centered={!media.xs}
      afterClose={afterClose}
      maskClosable={false}
    >
      {icon && (
        <div className={clsx(css.modal__image, css[`modal__image_${icon}`])} />
      )}

      <div className={css.modal__title}>
        {intl.formatMessage({id: 'DemoModeNotification-It_s_a_Demo_'})}
      </div>

      <div className={css.modal__description}>{description}</div>

      <Button
        className={clsx(
          'ant-btn_wide ant-btn_green-emerald',
          gcss('t_font-weight_700'),
          gcss('t_transform_uppercase'),
          media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger'
        )}
        onClick={() => {
          handleSuccess();
        }}
      >
        {intl.formatMessage({id: 'App-Got_it'})}
      </Button>
    </Modal>
  );
};

export default DemoModeNotification;
