export const apiErrorsCodeAssociations: Record<string, string> = {
  '10000': 'no_person_found',
  '10004': 'person_is_deactivated',
  '10005': 'person_has_opened_accounts',
  '10006':
    'person_with_specified_personal_number_already_exists_in_the_product',
  '10011': 'person_must_not_be_represented_by_someone_else',
  '10012': 'person_must_be_beneficial_owner',
  '10018': 'loyalty_number_cannot_be_changed',
  '10019': 'mobile_already_exists',
  '10020':
    'person_must_not_be_politically_exposed_and_reside_outside_eu_or_eea',
  '10023': 'product_settings_onfidoreportnames_are_empty',
  '20001': 'no_account_found',
  '20002': 'no_account_owner_found',
  '20003': 'closing_not_possible_account_has_opened_cards',
  '20004': 'account_is_closed',
  '20005': 'not_enough_funds_on_account_available_amount',
  '20006': 'account_is_in_closing_state',
  '20010': 'resulting_balance_cannot_be_more_than_credit_limit',
  '20011': 'no_fee_found',
  '30001': 'no_card_found',
  '30002': 'no_cardholder_found',
  '30003': 'card_is_closed',
  '30004': 'card_is_in_closing_state',
  '30005': 'card_is_blocked',
  '30006': 'card_is_not_blocked',
  '30011': 'card_cannot_be_activated',
  '30015': 'card_is_expired',
  '30016': 'card_is_hardblocked',
  '30020': 'card_type_not_allowed_to_send_sms',
  '30023': 'no_card_3d_secure_settings_found',
  '30024':
    'card_must_be_linked_to_an_account_and_account_holder_before_activation',
  '30025': 'card_3d_secure_password_is_empty',
  '30026': 'no_active_accounts_found',
  '50001': 'no_transaction_found',
  '50002': 'transaction_already_cleared',
  '60000': 'no_authorization_found',
  '80002': 'no_user_found',
  '90000': 'productid_is_not_in_uuid4_format',
  '90002': 'personid_is_not_in_uuid4_format',
  '90015': 'audit_user_id_is_required',
  '90021': 'delivery_address_is_required',
  '90027': 'productid_is_required',
  '90030': 'client_not_found',
  '90033': 'no_settings_found_for_product',
  '90037': 'product_not_found',
  '90048': 'user_account_is_locked',
  '90049': 'user_has_no_password_set',
  '90060': 'card3dsecuresettings_is_required',
  '90065': 'clientid_is_not_in_uuid4_format',
  '90068': 'card_3ds_settings_are_disabled_for_given_product',
  '90069': 'product_code_already_exists',
  '90070': 'product_code_is_required',
  '90071': 'card_is_not_enrolled_for_3d_secure',
  '90075': 'user_name_already_exists',
  '90080': 'event_is_not_allowed',
  '90094': 'card_is_already_enrolled_for_3d_secure',
  '90118': 'creditlimit_exceeds_max_amount',
  '90120': 'deviceid_is_required',
  '90121': 'deviceindex_is_required',
  '90139': 'at_least_one_authorization_type_is_required',
  '90151':
    'card_soft_block_is_not_allowed_for_created__ordered__pesonalized_and_dispatched_card_statuses',
  '90154': 'externalid_already_exists_in_the_product',
  '90157':
    'internet_purchases_cannot_be_enabled_if_3ds_settings_are_not_provided_and_product_has_3ds_enabled',
  '90169': 'invalid_card_status',
  '90171': 'usedcredit_cannot_be_more_than_creditlimit',
  '90173': 'Not_enough_data',
  '90174': 'email_is_required',
  '90175': 'card_already_linked_to_account',
  '90181': 'cardtypechipandpin_requires_accountid',
  '90182': 'cardtypechipandpinanonymous_does_not_allow_accountid',
  '90183': 'no_settlement_risk_limit_found',
  '90184': 'resulting_amount_cannot_be_negative',
  '90188': 'settlement_risk_limit_cannot_be_more_than_max_limit',
  '90205': 'personid_is_required',
  '90212': 'template_not_found',
  '90214': 'secured_card_data_not_found',
  '90215': 'activation_code_is_required',
  '90231': 'template_already_exists',
  '90236': 'card_limits_cannot_exceed_maximum_limits',
  '90237': 'account_limits_cannot_exceed_maximum_limits',
  '90240': 'token_not_found',
  '90259': 'email_is_not_in_valid_format',
  '90265': 'email_already_exists',
  '90266': 'search_returned_no_results',
  '90268': 'cardtypevirtual_requires_accountid',
  '90269': 'no_cards_found',
  '90270': 'user_is_already_deleted',
  '90272': 'clientid_is_required',
  '90273': 'person_is_already_active',
  '90279': 'token_status_transition_not_allowed',
  '90280': 'account_is_not_closed',
  '90284': 'event_service_settings_not_found',
  '90285': 'locale_is_required',
  '90286': 'firstname_is_required',
  '90287': 'lastname_is_required',
  '90298': 'mobile_is_already_verified',
  '90299': 'email_is_already_verified',
  '90300': 'no_verification_log_found',
  '90301': 'verification_secret_has_already_expired',
  '90302': 'otp_has_already_expired',
  '90304': 'role_name_already_exists',
  '90305': 'permission_not_found',
  '90306': 'client_is_already_deleted',
  '90307': 'api_user_is_already_deleted',
  '90309': 'invalid_token',
  '90313': 'role_not_found',
  '90314': 'role_is_already_deleted',
  '90325': 'user_is_not_locked',
  '90327': 'user_has_been_deleted',
  '90329': 'product_is_already_deleted',
  '90330': 'product_is_deleted',
  '90331': 'user_is_deleted',
  '90332': 'role_is_deleted',
  '90333': 'cannot_assign_global_permission_to_product_based_role',
  '90334': 'cannot_assign_product_based_permission_to_global_role',
  '90336': 'personalizationproductcode_does_not_belong_to_product',
  '90337': 'settlement_risk_limit_exceeds_database_limit',
  '90338': 'transaction_is_already_archived',
  '90340': 'transaction_is_archived',
  '90342': 'jwt_token_is_not_valid',
  '90343': 'user_does_not_have_required_permission',
  '90344': 'invalid_user_login_name_or_password',
  '90349': 'directory_not_found',
  '90350': 'client_is_deleted',
  '90351': 'daily_limit_exceeds_monthly_limit',
  '90352': 'transaction_limit_exceeds_daily_limit',
  '90353': 'applicant_id_not_found',
  '90356': 'product_settings_are_not_valid',
  '90358': 'daily_limit_exceeds_weekly_limit',
  '90359': 'weekly_limit_exceeds_monthly_limit',
  '90382': 'serviceids_cannot_have_duplicate_service_id',
  '90383': 'invalid_service_id',
  '90384': 'email_is_not_verified',
  '90388': 'jwt_token_expired',
  '90424': 'specified_product_code_does_not_belong_to_the_user',
  '90275': 'card_pin_code_must_not_have_same_digits',
  '90276': 'card_pin_code_must_not_have_sequential_digits',
  '90295': 'sms_verification_code_is_invalid',
  '90447': 'mobile_verification_sms_requests_exceeded',
  '90448': 'mobile_verification_sms_verify_attempts_exceeded',
  '90449': 'too_frequent_requests',
  '90003': 'cardid_is_not_in_uuid4_format',
  '90013': 'recordscount_must_be_a_positive_integer',
  '90026': 'fromrecord_must_be_zero_or_a_positive_integer',
  '90042': 'orderfield_is_not_within_allowed_range',
  '90061': 'type_is_required',
  '90062': 'mobile_is_required',
  '90064': 'mobile_has_invalid_format',
  '90102': 'data_is_required',
  '90106': 'filename_is_required',
  '90123': 'productids_is_required',
  '90143': 'name_must_be_maximum_of_100_characters_in_length',
  '90225': 'card_metadata_profiles_not_found',
  '90288': 'name_is_required',
  '90294': 'otp_is_required',
  '90296': 'verificationsecret_is_required',
  '90297': 'verificationsecret_is_invalid',
  '90397': 'registrationcountrycode_is_required',
  '90398': 'registrationnumber_is_required',
  '90411': 'company_name_must_be_maximum_of_100_characters_in_length',
  '90416': 'firstname_must_be_maximum_100_characters_in_length',
  '90417': 'lastname_must_be_maximum_100_characters_in_length',
  '90425': 'product_does_not_belong_to_the_client',
  '90427': 'disposable_card_requires_cardtypevirtual',
  '90433': 'bin_must_be_max_6_digits_in_length',
  '90450': 'fullcompanyname_is_required',
  '90451': 'fullcompanyname_must_be_maximum_100_characters_in_length',
  '90452': 'incorporationcountrycode_is_required',
  '90453': 'incorporationcountrycode_is_not_within_allowed_range',
  '90454': 'incorporationdate_is_required',
  '90455': 'registrationnumber_must_be_maximum_20_characters_in_length',
  '90456': 'activitytype_is_required',
  '90457': 'activitytype_is_not_within_allowed_range',
  '90458': 'contactmobile_is_required',
  '90459': 'contactmobile_has_invalid_format',
  '90460': 'contactemail_is_required',
  '90461': 'contactemail_has_invalid_format',
  '90462': 'registrationcountrycode_is_not_within_allowed_range',
  '90463': 'registrationstreet_is_required',
  '90464': 'registrationstreet_must_be_maximum_200_characters_in_length',
  '90465': 'registrationflat_is_required',
  '90466': 'registrationflat_must_be_maximum_50_characters_in_length',
  '90467': 'registrationcity_is_required',
  '90468': 'registrationcity_must_be_maximum_100_characters_in_length',
  '90469': 'registrationregion_is_required',
  '90470': 'registrationregion_must_be_maximum_255_characters_in_length',
  '90471': 'registrationpostalcode_is_required',
  '90472': 'registrationpostalcode_must_be_maximum_10_characters_in_length',
  '90474': 'actualcountrycode_is_not_within_allowed_range',
  '90475': 'actualstreet_is_required',
  '90476': 'actualstreet_must_be_maximum_200_characters_in_length',
  '90477': 'actualflat_is_required',
  '90478': 'actualflat_must_be_maximum_50_characters_in_length',
  '90479': 'actualcity_is_required',
  '90480': 'actualcity_must_be_maximum_100_characters_in_length',
  '90481': 'actualregion_is_required',
  '90482': 'actualregion_must_be_maximum_255_characters_in_length',
  '90483': 'actualpostalcode_is_required',
  '90484': 'actualpostalcode_must_be_maximum_10_characters_in_length',
  '90485': 'employeesquantity_is_required',
  '90486': 'employeesquantity_is_not_within_allowed_range',
  '90487': 'cardspendingamount_is_required',
  '90488': 'cardspendingamount_is_not_within_allowed_range',
  '90489': 'person_must_have_kyc_status_verified',
  '90490': 'client_must_not_be_white_label',
  '90491': 'documenttype_is_required',
  '90492': 'filebody_is_required',
  '90493': 'corporatedocumentid_is_required',
  '90494': 'corporatedocumentid_not_in_uuid4_format',
  '90495': 'corporate_document_not_found',
  '90496': 'filetype_not_supported',
  '90497': 'invalid_corporate_document_type',
  '90498': 'filename_must_be_maximum_1024_chars_in_length',
  '90499': 'changing_corporate_documents_is_not_allowed',
  '90500': 'registration_incorporation_certificate_is_required',
  '90502': 'latest_financial_report_is_required',
  '90506': 'changing_board_members_is_not_allowed',
  '90507': 'board_member_not_found',
  '90508': 'boardmemberid_not_in_uuid4_format',
  '90509': 'boardmemberid_is_required',
  '90510': 'birthdate_is_required',
  '90511': 'percentageowned_must_be_between_1_and_100',
  '90512': 'registrationdate_is_required',
  '90513': 'registrationnumber_must_be_maximum_100_characters_in_length',
  '90514': 'companyname_is_required',
  '90515': 'shareholder_not_found',
  '90516': 'changing_shareholders_is_not_allowed',
  '90517': 'invalid_shareholder_type',
  '90518': 'can_not_change_phone/email_for_non_individual_shareholder',
  '90519': 'registrationnumber_already_exists',
  '90520': 'shareholderid_is_required',
  '90521': 'shareholderid_not_in_uuid4_format',
  '90524': 'shareholder_is_deleted',
  '90525': 'board_member_is_deleted',
  '90553': 'at_least_one_board_member_must_be_marked_as_company_representative',
  '90563': 'newproductid_is_required',
  '90564': 'newproductid_is_not_in_uuid4_format',
  '90587': 'beneficial_owners_certificate_is_required',
  '90591': 'corporate_document_file_size_exceeds_maximum_file_size',
  '90597': 'rsa_public_key_not_valid',
  '90609': 'client_name_already_exists',
  '90722': 'employee_already_exists',
  '90716': 'profile_already_exists',
  '90739': 'client_rejected',
  '90754': 'not_enough_funds_on_account_available_amount',
  '90858':
    'Closing_not_possible__account_balance_and_available_amount_should_be_null',
  '90645': 'Storage_size_exceeded',
  '90698': 'Company_has_opened_accounts',
  '90871': 'is_bank_confirmed_required',
  '90696': 'The_limit_for_sending_currency_requests_has_been_exceeded_',
  '90986': 'payment_file_cannot_contain_more_than_1500_records_at_a_time',
  '90987': 'parsing_XML_file_failed',
  '91009': 'Invalid_file_format',
  '91028': 'Invalid_file_format',
  '91016': 'cannot_update_completed_payment_file_record',
  '91017': 'invalid_payment_file_record_status',
  '91013': 'invalid_payment_file_status',
  '20014': 'multiple_accounts_found',
  '91015': 'payment_file_completed',
  '91014': 'reason_is_not_within_allowed_range',
  '91022': 'resend_is_available_at_least_after_one_hour',
  '101001': 'viban_not_found',
};
export default apiErrorsCodeAssociations;

export const onBoardValidationErrorCodes: Record<string, string> = {
  '1': 'on_board_error_1',
};

export const errorCodesToIgnore: number[] = [
  90309,
  10019,
  80002,
  90739,
  20003,
  90858,
  90871,
  90987,
];
