import {
  Card3DSecurityType,
  CardBlockType,
  CardSecurityKeys,
  CardSecuritySettings,
  CardSecuritySettingsKeys,
  ECardSecuritySettingsKeys,
  TCardType,
  TypedMap,
} from '../types';

export const CardNameMaxLength = 23;
export const CardExternalIdMaxLength = 50;

export const Card3DSecurityTypes: {[key: string]: string} = {
  [Card3DSecurityType.SMSOTPAndStaticPassword]: 'SMS OTP and static password',
};

export const CardTransactionGroups: {[key: string]: string} = {
  Deposit: 'Deposit',
  InternetPurchase: 'Internet Purchase',
  Other: 'Other',
  Purchase: 'Purchase',
  Withdraw: 'Withdraw',
};

export const CardAuthorizationGroups: {[key: string]: string} = {
  Deposit: 'Deposit',
  InternetPurchase: 'Internet Purchase',
  Other: 'Other',
  Purchase: 'Purchase',
  Withdraw: 'Withdraw',
  PINManagement: 'PINManagement',
  Refund: 'Refund',
};

export const PinRules = [
  'NotAllowedConsecutiveNumbers',
  'NotAllowedSameDigits',
] as const;

export const ChipAndPinCardsAuthorizationTypes = [
  'ATMBalanceInquiry',
  'ATMWithdrawal',
  'AccountVerification',
  'AutomatedFuelDispenser',
  'AutomatedFuelDispenserConfirmation',
  'CreditVoucherAndMerchandiseReturn',
  'MOTOPurchase',
  'ManualCashPurchase',
  'PINChange',
  'PINUnblock',
  'POSPurchase',
  'PurchaseWithCashback',
  'QuasiCashPurchase',
  'RecurringPayment',
  'StatusCheck',
  'UnattendedCardholderActivatedTerminal',
  'Unknown',
  'eCommercePurchase',
  'payWaveHighValuePurchase',
  'payWaveLowValuePurchase',
] as const;

export const CardType: TypedMap<TCardType, string> = {
  ChipAndPin: 'App-Physical',
  ChipAndPinAnonymous: 'App-Physical_promo',
  Virtual: 'App-Virtual',
};

export const BlackCardType: TypedMap<TCardType, string> = {
  ChipAndPin: 'App-Physical',
  ChipAndPinAnonymous: 'App-Physical',
  Virtual: 'App-Virtual',
};

export const limitChangeSuccessNotification = (
  key: CardSecurityKeys
): string => {
  let message: string | undefined;

  switch (key) {
    case 'daily_purchase':
    case 'weekly_purchase':
    case 'monthly_purchase':
    case 'transaction_purchase':
      message =
        'App-Limits_on_purchases_via_POS_terminals_successfully_changed_';
      break;

    case 'daily_contactless_purchase':
    case 'weekly_contactless_purchase':
    case 'monthly_contactless_purchase':
    case 'transaction_contactless_purchase':
      message = 'App-Limits_on_contactless_payments_successfully_changed_';
      break;

    case 'daily_internet_purchase':
    case 'weekly_internet_purchase':
    case 'monthly_internet_purchase':
    case 'transaction_internet_purchase':
      message = 'App-Limits_on_online_purchases_successfully_changed_';
      break;

    case 'daily_withdrawal':
    case 'weekly_withdrawal':
    case 'monthly_withdrawal':
    case 'transaction_withdrawal':
      message = 'App-Cash_withdrawal_limits_successfully_changed_';
      break;

    case 'daily_overall_purchase':
    case 'weekly_overall_purchase':
    case 'monthly_overall_purchase':
      message = 'App-Overall_limits_on_purchases_via_POS_terminal_successfu';
      break;

    default:
      break;
  }

  return message || key;
};

export const limitSettingsChangeNotification = (
  key: CardSecuritySettingsKeys,
  success: boolean,
  enable: boolean
): string => {
  const map: TypedMap<
    keyof CardSecuritySettings,
    {
      success: {
        enable: string;
        disable: string;
      };
      error: {
        enable: string;
        disable: string;
      };
    }
  > = {
    [ECardSecuritySettingsKeys.WithdrawalEnabled]: {
      success: {
        enable: 'CardLimits-Cash_withdrawals_successfully_activated_',
        disable: 'CardLimits-Cash_withdrawals_disabled_',
      },
      error: {
        enable: 'CardLimits-Failed_to_activate_cash_withdrawals_',
        disable: 'CardLimits-Failed_to_disable_cash_withdrawals_',
      },
    },
    [ECardSecuritySettingsKeys.InternetPurchaseEnabled]: {
      success: {
        enable: 'CardLimits-Online_purchases_successfully_activated',
        disable: 'CardLimits-Online_purchases_disabled_',
      },
      error: {
        enable: 'CardLimits-Failed_to_activate_online_purchases',
        disable: 'CardLimits-Failed_to_disable_online_purchases_',
      },
    },
    [ECardSecuritySettingsKeys.ContactlessEnabled]: {
      success: {
        enable: 'CardLimits-Contactless_payments_successfully_activated_',
        disable: 'CardLimits-Contactless_payments_disabled_',
      },
      error: {
        enable: 'CardLimits-Failed_to_activate_contactless_payments_',
        disable: 'CardLimits-Failed_to_disable_contactless_payments_',
      },
    },
    [ECardSecuritySettingsKeys.OverallLimitsEnabled]: {
      success: {
        enable:
          'CardLimits-Overall_purchases_via_POS_terminals_terminals_successfully_a',
        disable: 'CardLimits-Overall_purchases_via_POS_terminals_disabled_',
      },
      error: {
        enable:
          'CardLimits-Failed_to_activate_overall_purchases_via_POS_terminals_',
        disable:
          'CardLimits-Failed_to_disable_overall_purchases_via_POS_terminals_',
      },
    },
  };

  const item = map[key];

  if (item !== undefined) {
    return item[success ? 'success' : 'error'][enable ? 'enable' : 'disable'];
  }

  return key;
};

export const SoftBlocks: CardBlockType[] = [
  'BlockedByCardholder',
  'BlockedByCardholderViaPhone',
  'BlockedByClient',
  'BlockedByCardUser',
  'BlockedByIssuer',
];

export const HardBlocks: CardBlockType[] = [
  'Counterfeit',
  'Fraudulent',
  'Lost',
  'Stolen',
];

export const VirtualCardAuthorizationTypesList = [
  'ATMBalanceInquiry',
  'ATMWithdrawal',
  'AccountVerification',
  'AutomatedFuelDispenser',
  'AutomatedFuelDispenserConfirmation',
  'CreditVoucherAndMerchandiseReturn',
  'MOTOPurchase',
  'ManualCashPurchase',
  'PINChange',
  'PINUnblock',
  'POSPurchase',
  'PurchaseWithCashback',
  'QuasiCashPurchase',
  'RecurringPayment',
  'StatusCheck',
  'UnattendedCardholderActivatedTerminal',
  'Unknown',
  'eCommercePurchase',
  'payWaveHighValuePurchase',
  'payWaveLowValuePurchase',
] as const;

export const limitTypes = [
  'daily',
  'weekly',
  'monthly',
  'transaction',
] as const;

export default Card3DSecurityTypes;
