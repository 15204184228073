import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {setTitle} from '../../models/app';
import {scrollChangeEvent} from '../../models/layout';
import {setCurrentRoute} from '../../models/router';
import {RouteTitle} from '../../types';
import {getCleanUrl} from '../../utils/language';

type RouterWatcherProps = React.PropsWithChildren<{
  path: string;
  url: string;
  title?: RouteTitle;
}>;

const RouterWatcher: React.FC<React.PropsWithChildren<RouterWatcherProps>> = ({
  children,
  path,
  url,
  title,
}) => {
  const intl = useIntl();
  const params = useParams();

  useEffect(() => {
    setCurrentRoute({path, url, route: getCleanUrl(path)});
    scrollChangeEvent({current: 0, headerHeight: 100});
  }, [path, url]);

  useEffect(() => {
    if (title) {
      if (typeof title === 'string') {
        setTitle(title);
      } else if (typeof title === 'function') {
        setTitle(title(params, intl));
      }
    } else {
      setTitle();
    }
  }, [intl, params, title]);

  return <>{children}</>;
};

export default RouterWatcher;
