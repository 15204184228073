import {mediaDelimiter} from '../constants/styles';
import {ScreenSize} from '../types';
import css from '../styles/app/app.module.less';

export const gcss = (name: string, mediaKey?: ScreenSize): string => {
  let key = name;

  if (mediaKey) {
    key += mediaDelimiter + mediaKey;
  }

  return css[key];
};

export default gcss;
