import cssSwipeableNew from '../components/Swipeable/Swipeable.bridge.module.less';
import cssTableTitledNew from '../components/TableTitled/TableTitled.bridge.module.less';
import cssPhoneInputNew from '../modules/PhoneInput/PhoneInput.bridge.module.less';
import cssSelectCountryNew from '../modules/SelectCountry/SelectCountry.bridge.module.less';
import cssSelectThumbedNew from '../modules/SelectThumbed/SelectThumbed.bridge.module.less';
import cssLayoutDashboardProfileMenuNew from '../modules/LayoutDashboardProfileMenu/LayoutDashboardProfileMenu.bridge.module.less';
import cssLayoutNew from '../modules/LayoutDashboard/LayoutDashboard.bridge.module.less';
import cssCardVerificationNew from '../components/CardVerification/CardVerification.bridge.module.less';
import cssMenuNew from '../modules/MainMenu/MenuMain/MenuMain.bridge.module.less';
import cssSelectDropdownNew from '../components/SelectDropdown/SelectDropdown.bridge.module.less';
import cssChartLineSimpleNew from '../components/ChartLineSimple/ChartLineSimple.bridge.module.less';
import cssSelectNew from '../components/Select/Select.bridge.module.less';
import cssFiltersSubGroupNew from '../modules/Filters/FiltersSubGroup/FiltersSubGroup.bridge.module.less';
import cssSearchListNew from '../components/SearchList/SearchList.bridge.module.less';
import cssColumnsManagerSubGroupNew from '../components/ColumnsManager/ColumnsManagerSubGroup/ColumnsManagerSubGroup.bridge.module.less';

export const popupContainerGetter = (node: HTMLElement): HTMLElement => {
  const cssSwipeable = cssSwipeableNew;
  const cssTableTitled = cssTableTitledNew;
  const cssPhoneInput = cssPhoneInputNew;
  const cssSelectCountry = cssSelectCountryNew;
  const cssSelectThumbed = cssSelectThumbedNew;
  const cssLayoutDashboardProfileMenu = cssLayoutDashboardProfileMenuNew;
  const cssLayout = cssLayoutNew;
  const cssCardVerification = cssCardVerificationNew;
  const cssMenu = cssMenuNew;
  const cssSelectDropdown = cssSelectDropdownNew;
  const cssChartLineSimple = cssChartLineSimpleNew;
  const cssSelect = cssSelectNew;
  const cssFiltersSubGroup = cssFiltersSubGroupNew;
  const cssSearchList = cssSearchListNew;
  const cssColumnsManagerSubGroup = cssColumnsManagerSubGroupNew;

  return (
    (node
      ?.closest(`.${cssFiltersSubGroup.filtersSubGroup__item}`)
      ?.closest('body') as HTMLElement) ||
    (node
      ?.closest(`.${cssColumnsManagerSubGroup.filtersSubGroup__item}`)
      ?.closest('body') as HTMLElement) ||
    (node?.closest('.slide-down')?.parentElement as HTMLElement) ||
    (node?.closest(`.${cssSwipeable.swipeable}`)
      ?.parentElement as HTMLElement) ||
    (node?.closest(`.${cssTableTitled.tableTitled}`)
      ?.parentElement as HTMLElement) ||
    (node
      ?.closest(`.${cssPhoneInput.phoneInputCode}`)
      ?.closest(
        `.${cssCardVerification.cardVerificationItem}`
      ) as HTMLElement) ||
    (node?.closest(`.${cssPhoneInput.phoneInputCode}`) as HTMLElement) ||
    (node?.closest(`.${cssSelectCountry.selectWrap}`) as HTMLElement) ||
    (node?.closest(`.${cssSelectThumbed.selectWrap}`)
      ?.parentElement as HTMLElement) ||
    (node
      ?.closest(`.${cssLayoutDashboardProfileMenu.dropdown}`)
      ?.closest('body') as HTMLElement) ||
    (node?.closest(`.${cssMenu.menuBadge}`) as HTMLElement) ||
    (node?.closest(`.${cssMenu.subMenu}`)?.closest('body') as HTMLElement) ||
    (node?.closest(`.${cssMenu.menuItem}`)?.closest('body') as HTMLElement) ||
    (node?.closest(`.${cssSelectDropdown.dropdown}`)
      ?.parentElement as HTMLElement) ||
    (node?.closest(`.${cssChartLineSimple.chartLineSimple}`)
      ?.parentElement as HTMLElement) ||
    (node
      ?.closest(`.${cssSelect.selectOptionLabel}`)
      ?.closest('body') as HTMLElement) ||
    (node
      ?.closest(`.${cssSearchList.searchListItem}`)
      ?.closest('body') as HTMLElement) ||
    (node?.closest(
      `.${cssCardVerification.cardVerificationItem}`
    ) as HTMLElement) ||
    (node?.closest('.ant-table-wrapper')?.parentElement as HTMLElement) ||
    (node?.closest(`.simplebar-content`)?.parentElement as HTMLElement) ||
    (node?.closest('.ant-dropdown')?.parentElement as HTMLElement) ||
    (node?.closest('.ant-tooltip')?.parentElement as HTMLElement) ||
    (node?.closest('.ant-menu')?.parentElement as HTMLElement) ||
    (node?.parentElement as HTMLElement) ||
    (node?.closest(`.${cssLayout.layoutContent}`) as HTMLElement) ||
    document.body
  );
};

export default popupContainerGetter;
