import {TypedMap} from '../types';

enum Features {
  ABTestingRegistration = 'a_b_testing_registration',
  RestrictedCountriesCheck = 'restricted_countries_check',
  EnablePreferredLanguage = 'enable_preferred_language',
  EnableNewStatementExport = 'enable_new_statement_export',
  EnableFeeInvoices = 'enable_fee_invoices',
  EnablePaymentNotesFrontend = 'enable_payment_notes_frontend',
  CaptchaOnSignIn = 'captcha_verification',
  IntercomArticleIds = 'intercom_article_ids',
  EnableReferenceNumber = 'enable_reference_number',
  Enable3DSOOB = 'enable_3ds_oob',
  EnableReceiptReminders = 'enable_receipt_reminders',
  EnableDebtResolutionFlow = 'enable_debt_resolution_flow',
}

export enum TreatmentStatus {
  Enabled = 'on',
  Disabled = 'off',
}

export const ProductFeatures: TypedMap<Features, boolean> = {
  [Features.EnableNewStatementExport]: true,
};

export const ClientFeatures: TypedMap<Features, boolean> = {};

export const UserFeatures: TypedMap<Features, boolean> = {};

export default Features;
