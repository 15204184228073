import {ScreenSize, TypedMap} from '../types';
import {sortedMedias} from '../constants/styles';

export const getMediaFromMap = ({
  medias,
  media,
}: {
  medias: ScreenSize[];
  media: ScreenSize | undefined;
}): ScreenSize | undefined => {
  let currentMedia;

  if (media) {
    if (medias.includes(media)) {
      currentMedia = media;
    } else {
      for (
        let a = sortedMedias.indexOf(media) + 1;
        a < sortedMedias.length - 1;
        a += 1
      ) {
        if (medias.includes(sortedMedias[a])) {
          currentMedia = sortedMedias[a];
          break;
        }
      }
    }
  }

  return currentMedia;
};

export const getMediaData = <T>({
  media,
  data,
}: {
  media: ScreenSize | undefined;
  data: TypedMap<ScreenSize, T>;
}): T | undefined => {
  if (media) {
    const currentMedia = getMediaFromMap({
      medias: Object.keys(data) as ScreenSize[],
      media,
    });

    if (currentMedia) {
      return data[currentMedia] as T;
    }
  }

  return undefined;
};

export const getCurrentMedia = (
  medias: Record<ScreenSize, boolean>
): ScreenSize | undefined => {
  let currentMedia;
  const currentMedias = {...medias};

  sortedMedias.some((media, a) => {
    if (
      currentMedias[media] &&
      (a === 0 || !currentMedias[sortedMedias[a - 1]])
    ) {
      currentMedia = media;

      return true;
    }

    return false;
  });

  return currentMedia;
};

export const getCurrentMediaData = <T>({
  data,
  medias,
}: {
  data?: TypedMap<ScreenSize, T>;
  medias: Record<ScreenSize, boolean>;
}): T | undefined => {
  if (!data) {
    return undefined;
  }

  return getMediaData<T>({
    data,
    media: getCurrentMedia(medias),
  });
};

export default getCurrentMediaData;
