import {IProfile} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const getProfiles: DemoApiHandler = ({config, instance, url}) => {
  const {productUsers, user, roles} = instance.getData();
  const savedUsers = [...productUsers, {...user, role: roles[3] || roles[0]}];
  const items = Demo.applySorterAndPager(
    savedUsers,
    Demo.toListSortOrderParams(url.queryParams)
  );

  return Demo.makeDataResponse<{
    users: IProfile[];
    total_records_number: number;
  }>(config, {
    users: items,
    total_records_number: savedUsers.length,
  });
};

export default getProfiles;
