import {useStoreMap} from 'effector-react';
import React, {useEffect} from 'react';
import {Route, useLocation} from 'react-router-dom';
import routes from '../../constants/routes';
import {App$} from '../../models/app';
import {setLayoutState} from '../../models/layout';
import AuthService from '../../services/AuthService';
import {CustomRoutesPropsReact} from '../../types';
import Redirect from './Redirect';
import RouterWatcher from './RouterWatcher';

const UnverifiedRoute: React.FC<
  React.PropsWithChildren<CustomRoutesPropsReact>
> = ({component: Component, page, children, title, ...rest}) => {
  const {isGuest, fullyVerified, isWhiteLabeled, lastLogoutAt} = useStoreMap({
    store: App$,
    keys: ['isGuest', 'fullyVerified', 'isWhiteLabeled', 'lastLogoutAt'],
    fn: (store$) => ({
      isGuest: store$.isGuest,
      fullyVerified: store$?.fullyVerified,
      isWhiteLabeled: store$?.isWhiteLabeled,
      lastLogoutAt: store$.lastLogoutAt,
    }),
  });
  const loc = useLocation();

  useEffect(() => {
    setLayoutState({pageClass: page});
  }, [page]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isGuest) {
          return (
            <Redirect to={AuthService.getGuestRedirectUrl(loc, lastLogoutAt)} />
          );
        }

        if (isWhiteLabeled || fullyVerified) {
          return <Redirect to={routes.main.path} />;
        }

        return (
          <RouterWatcher
            path={props.match.path}
            url={props.match.url}
            title={title}
          >
            {Component ? <Component {...props} /> : children}
          </RouterWatcher>
        );
      }}
    />
  );
};

export default UnverifiedRoute;
