import {useContext, useMemo} from 'react';
import TestContext from '../modules/TestProvider';

export type DataTestContext = string[] | undefined;

export const getContextTestId: (
  testId: string | undefined,
  context: DataTestContext
) => string[] | undefined = (testId, context) => {
  if (testId !== undefined) {
    return context ? [testId, ...context] : [testId];
  }

  return context ? [...context] : undefined;
};

const useContextTestId: (testId?: string) => string[] | undefined = (
  testId
) => {
  const contextValue = useContext(TestContext);

  return useMemo(() => {
    return getContextTestId(testId, contextValue);
  }, [contextValue, testId]);
};

export default useContextTestId;
