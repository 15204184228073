import Demo, {DemoApiHandler} from '../demo';
import {signDataImpl} from '../crypto';

const getCard3dsPassword: DemoApiHandler = ({config, instance, url, data}) => {
  const item = instance
    .getData()
    .cards[instance.getData().productId].find(
      (a) => a.id === url?.params?.card_id
    );

  if (item) {
    const pem = atob(data.public_key as string);

    return Demo.makeDataResponse<{encrypted_3ds_password: string}>(config, {
      encrypted_3ds_password: atob(
        signDataImpl(
          `${item['3d_secure_settings']?.password}`,
          pem,
          'Card3DSecurePassword'
        ) || ''
      ),
    });
  }

  return Demo.makeUnprocessableEntityResponse(config, 30001);
};

export default getCard3dsPassword;
