import {useUnit} from 'effector-react';
import {useEffect} from 'react';
import {
  getProductPricingPlanFx,
  ProductPricingPlanStore,
  ProductPricingPlanStoreType,
} from '../models/productPricingPlan';
import useProduct from './useProduct';

const useProductPricingPlan = (): ProductPricingPlanStoreType => {
  const data = useUnit(ProductPricingPlanStore);
  const product = useProduct();

  useEffect(() => {
    getProductPricingPlanFx(product.id);
  }, [product.id]);

  return data;
};

export default useProductPricingPlan;
