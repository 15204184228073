import logger from './index';
import sentryTransport from './sentryTransport';
import rollbarTransport from './rollbarTransport';
import consoleDevTransport from './consoleDevTransport';
import consoleProdTransport from './consoleProdTransport';
import config from '../constants/config';

const consoleTransport = config.isProd
  ? consoleProdTransport
  : consoleDevTransport;

logger.module('logger transports').info('setup transports');
logger.addListener(consoleTransport);
if (config.sentryEnable && config.sentryDSN !== '') {
  logger.addListener(sentryTransport);
}

if (config.rollbarEnable && config.rollbarToken) {
  logger.addListener(rollbarTransport);
}
