import {generatePath} from 'react-router';
import {ProgramType, RouteInfo} from '../types';
import Endpoints from './endpoints';
import Permissions from './permissions';

const UUIDRegExp =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89ABab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}';

const _routes /*: Record<string, RouteInfo> */ = {
  main: {path: '/', isNotMain: true, allowUnverifiedUser: true},
  components: {path: '/components', isNotMain: true},
  notFound: {path: '/404', isNotMain: true, allowUnverifiedUser: true},
  forbidden: {path: '/403', isNotMain: true, allowUnverifiedUser: true},
  signIn: {path: '/sign-in', isNotMain: true},
  signUp: {path: '/sign-up', isNotMain: true},
  demoSignUp: {path: '/demo-sign-up', isNotMain: true},
  verificationStatus: {
    path: '/verification-status',
    isNotMain: true,
    allowUnverifiedUser: true,
  },

  companyInfoVerification: {
    path: '/verify-company-info',
    isNotMain: true,
    allowUnverifiedUser: true,
  },
  makeTransfer: {
    path: `/money-transfer`,
    isNotMain: true,
    allowUnverifiedUser: true,
    endpoints: [
      Endpoints.makeMoneyTransferToEmployee,
      Endpoints.makeMoneyTransferToAccount,
    ],
  },
  makeAccountTransfer: {
    path: `/money-transfer/account/:accountId(${UUIDRegExp})?`,
    isNotMain: true,
    endpoints: [
      Endpoints.makeMoneyTransferToAccount,
      Endpoints.makeMoneyTransferToEmployee,
    ],
  },
  makeToAccountTransfer: {
    path: `/money-transfer/to-account/:toAccountId(${UUIDRegExp})?/:amount?`,
    isNotMain: true,
    endpoints: [Endpoints.makeMoneyTransferToAccount],
  },
  // shareHolderAdd: {
  //   path: '/share-holders/add/:type(Individual|Company)',
  //   isNotMain: true,
  // },
  // shareHolderEdit: {path: `/share-holders/:id(${UUIDRegExp})`, isNotMain: true},
  identityVerification: {
    path: `/verify-identity`,
    isNotMain: true,
    allowUnverifiedUser: true,
  },
  corporateDocumentsVerification: {
    path: `/verify-documents`,
    isNotMain: true,
    allowUnverifiedUser: true,
  },
  mainDashboard: {
    path: '/dashboard',
    permission: 232941,
    // permission: Permissions.PermissionViewDashboard,
    isNotMain: true,
    allowUnverifiedUser: true,
  },
  blackAccounts: {
    path: `/my-accounts`,
    blackOnly: true,
    permission: Permissions.PermissionViewAccounts,
    allowUnverifiedUser: true,
  },
  blackAccountDetails: {
    path: `/account-details/:accountId(${UUIDRegExp})`,
    permission: Permissions.PermissionViewAccountDetails,
    blackOnly: true,
  },
  blackDashboard: {
    path: '/wb-dashboard',
    blackOnly: true,
    allowUnverifiedUser: true,
    permission: Permissions.PermissionViewAccounts,
  },
  myCustomers: {
    path: '/my-cardholders',
    permission: Permissions.PermissionSearchPersons,
    productProgramType: 'Consumer' as ProgramType,
    whiteOnly: true,
  },
  myCompanies: {
    path: '/my-companies',
    permission: Permissions.PermissionViewCompanies,
    productProgramType: 'Business' as ProgramType,
    whiteOnly: true,
  },
  customerDetails: {
    path: `/cardholder/:customerId(${UUIDRegExp})`,
    permission: Permissions.PermissionViewPersonDetails,
  },
  customerHistory: {
    path: `/cardholder/:customerId(${UUIDRegExp})/history`,
    permission: Permissions.PermissionViewPersonHistory,
  },
  customerAlertsHistory: {
    path: `/cardholder/:customerId(${UUIDRegExp})/alerts-history`,
    permission: Permissions.PermissionViewFrauds,
  },
  customerCardsBlockCalls: {
    path: `/cardholder/:customerId(${UUIDRegExp})/cards-block-calls`,
    permission: Permissions.PermissionViewCardBlockCalls,
  },
  createCardholder: {
    path: `/cardholder/create`,
    permission: Permissions.PermissionCreatePerson,
    isNotMain: true,
  },
  cards: {
    path: `/cards/:accountId(${UUIDRegExp})?`,
    permission: [
      Permissions.PermissionViewCards,
      Permissions.PermissionOrderPromoCardsBatch,
    ],
    permissionsPartialMode: true,
    allowUnverifiedUser: true,
  },
  administrationUsersList: {
    path: '/administration/users',
    permission: Permissions.PermissionManageUsers,
    whiteOnly: true,
  },
  search: {
    path: '/search/:query?',
    permission: Permissions.PermissionSearch,
    demoRestricted: true,
  },
  cardDetail: {
    path: `/card/:id(${UUIDRegExp})`,
    permission: [Permissions.PermissionViewCardDetails],
  },
  companyDetail: {
    path: `/company/:id(${UUIDRegExp})`,
    permission: [Permissions.PermissionViewCompanies],
  },
  companyDetailHistory: {
    path: `/company/:id(${UUIDRegExp})/history`,
    permission: [Permissions.PermissionViewCompanies],
  },
  companyDetailRepresentatives: {
    path: `/company/:id(${UUIDRegExp})/representatives`,
    permission: [Permissions.PermissionViewCompanies],
  },
  companyRepresentativeDetails: {
    path: `/company/:company_id(${UUIDRegExp})/representative/:id(${UUIDRegExp})`,
    permission: [Permissions.PermissionViewCompanies],
  },
  addCompanyRepresentative: {
    path: `/company/:company_id(${UUIDRegExp})/representative/add`,
    permission: [Permissions.PermissionEditCompanies],
    endpoints: [Endpoints.createCompanyRepresentative],
  },
  companyDetailAccounts: {
    path: `/company/:id(${UUIDRegExp})/accounts`,
    permission: [Permissions.PermissionViewCompanies],
  },
  companyDetailCards: {
    path: `/company/:id(${UUIDRegExp})/cards`,
    permission: [Permissions.PermissionViewCompanies],
  },
  companyDetailAlertsHistory: {
    path: `/company/:id(${UUIDRegExp})/alerts-history`,
    permission: [Permissions.PermissionViewFrauds],
  },
  fraudCases: {
    path: '/fraud-cases',
    permission: [Permissions.PermissionViewFrauds],
  },
  fraudCaseDetail: {
    path: `/fraud/:id(${UUIDRegExp})`,
    permission: [Permissions.PermissionViewFrauds],
  },
  fraudCaseDetailEdit: {
    path: `/fraud/:id(${UUIDRegExp})/edit`,
    permission: [Permissions.PermissionEditFrauds],
  },
  fraudCaseDetailHistory: {
    path: `/fraud/:id(${UUIDRegExp})/history`,
    permission: [Permissions.PermissionViewFrauds],
  },
  fraudCaseDetailAuthorizations: {
    path: `/fraud/:id(${UUIDRegExp})/authorizations`,
    permission: [Permissions.PermissionViewFrauds],
  },
  fraudCaseDetailAuthorizationDetail: {
    path: `/fraud/:id(${UUIDRegExp})/authorization/:authorization_id(${UUIDRegExp})`,
    permission: [Permissions.PermissionViewFrauds],
  },
  fraudCaseDetailScoring: {
    path: `/fraud/:id(${UUIDRegExp})/scoring`,
    permission: [Permissions.PermissionViewFrauds],
  },
  orderPromoCards: {
    path: '/promo-cards/order',
    permission: Permissions.PermissionOrderCardsBatch,
    isNotMain: true,
  },
  createCard: {
    path: `/card/add/customer/:customer_id/:account_id?`,
    permission: Permissions.PermissionCreateCard,
    endpoints: [Endpoints.createCard],
  },
  createCardCompany: {
    path: `/card/add/company/:company_id/:account_id?`,
    permission: Permissions.PermissionCreateCard,
    endpoints: [Endpoints.createCard],
  },
  createCardTypes: {
    path: `/create-card`,
    isNotMain: true,
    endpoints: [Endpoints.createCard, Endpoints.createEmployeesCard],
    endpointsPartialMode: true,
  },
  createCompany: {
    path: `/create-company`,
    permission: Permissions.PermissionEditCompanies,
    isNotMain: true,
    endpoints: [Endpoints.createCompany],
  },
  createExpensesCard: {
    path: `/create-expenses-card/:accountId(${UUIDRegExp})?`,
    permission: Permissions.PermissionCreateCard,
    endpoints: [Endpoints.createCard],
  },
  createEmployeesCard: {
    path: `/create-employees-card`,
    permission: Permissions.PermissionManageEmployees,
    isNotMain: true,
    endpoints: [Endpoints.createEmployeesCard],
  },
  updateEmployeesCard: {
    path: `/employees-card/:id(${UUIDRegExp})`,
    permission: Permissions.PermissionManageEmployees,
    isNotMain: true,
    endpoints: [Endpoints.updateEmployeesCard],
  },
  cardDetailLimits: {
    path: `/card/:id(${UUIDRegExp})/limits`,
    permission: [Permissions.PermissionViewCardDetails],
  },
  cardDetail3DSecure: {
    path: `/card/:id(${UUIDRegExp})/3d-secure`,
    permission: [Permissions.PermissionViewCardDetails],
  },
  cardDetailTokens: {
    path: `/card/:id(${UUIDRegExp})/tokens`,
    permission: [Permissions.PermissionViewCardDetails],
  },
  cardDetailHistory: {
    path: `/card/:id(${UUIDRegExp})/history`,
    permission: [Permissions.PermissionViewCardHistory],
  },
  cardDetailAuthorizations: {
    path: `/card/:id(${UUIDRegExp})/authorizations`,
    permission: [Permissions.PermissionViewCardAuthorizations],
  },
  cardDetailTransactions: {
    path: `/card/:id(${UUIDRegExp})/transactions`,
    permission: [Permissions.PermissionViewCardTransactions],
  },
  cardDetailUsers: {
    path: `/card/:id(${UUIDRegExp})/users`,
    permission: [Permissions.PermissionViewCardDetails],
  },
  createCardDetailTransactions: {
    path: `/card/:id(${UUIDRegExp})/transactions/create`,
    permission: [Permissions.PermissionViewCardTransactions],
  },
  transactionDetail: {
    path: `/transaction/:transactionId(${UUIDRegExp})`,
    permission: [Permissions.PermissionViewTransactionDetails],
  },
  authorizationDetail: {
    path: `/authorization/:authorizationId(${UUIDRegExp})`,
    permission: [Permissions.PermissionViewAuthorizationDetails],
  },
  createAccount: {
    path: `/account/add/customer/:customer_id`,
    permission: Permissions.PermissionCreateAccount,
  },
  createAccountCompany: {
    path: `/account/add/company/:company_id`,
    permission: Permissions.PermissionCreateAccount,
    endpoints: [Endpoints.createCompany],
  },
  accountDetails: {
    path: `/account/:accountId(${UUIDRegExp})`,
    permission: Permissions.PermissionViewAccountDetails,
  },
  accountDetailsStatement: {
    path: `/account/:accountId(${UUIDRegExp})/statement`,
    permission: Permissions.PermissionViewAccountStatementRecords,
  },
  accountDetailsLimits: {
    path: `/account/:accountId(${UUIDRegExp})/limits`,
    permission: Permissions.PermissionChangeAccountLimits,
  },
  accountDetailsCards: {
    path: `/account/:accountId(${UUIDRegExp})/cards`,
    permission: Permissions.PermissionViewAccountCards,
  },
  accountDetailsHistory: {
    path: `/account/:accountId(${UUIDRegExp})/history`,
    permission: Permissions.PermissionViewAccountHistory,
  },
  expiringCardsList: {
    path: '/cards/expiring-cards',
    permission: Permissions.PermissionViewExpiringCards,
    whiteOnly: true,
  },
  pendingCardsList: {
    path: '/cards/pending-cards',
    permission: Permissions.PermissionViewPendingOrders,
    whiteOnly: true,
  },
  accountingPayments: {
    path: '/accounting/payments',
    permission: Permissions.PermissionViewPayments,
  },
  accountingPaymentsAuthorizations: {
    path: '/accounting/authorizations',
    permission: Permissions.PermissionViewPayments,
  },
  accountingExtractFiles: {
    path: '/accounting/extract-folders',
    permission: Permissions.PermissionDownloadExtractFiles,
  },
  accountingDirectoryFilesList: {
    path: '/accounting/extract-folders/:path',
    permission: Permissions.PermissionDownloadExtractFiles,
  },
  reports: {
    path: '/reports/:type?',
    permission: Permissions.PermissionDownloadExtractFiles,
  },
  mainTopUp: {
    path: `/top-up/:accountId(${UUIDRegExp})?`,
    permission: [Permissions.PermissionViewSettlement],
  },
  mainTopUpHistory: {
    path: `/top-up/history/:accountId(${UUIDRegExp})?`,
    permission: [Permissions.PermissionViewSettlement],
    blackOnly: true,
  },
  blackStatements: {
    path: `/statement/:accountId(${UUIDRegExp})?`,
    permission: Permissions.PermissionViewAccountStatementRecords,
    blackOnly: true,
    allowUnverifiedUser: true,
  },
  blackUsers: {
    path: '/users',
    permission: Permissions.PermissionGetAllProductUserProfiles,
    blackOnly: true,
    allowUnverifiedUser: true,
  },
  blackAddUser: {
    path: '/users/add',
    isNotMain: true,
    blackOnly: true,
    allowUnverifiedUser: true,
    endpoints: [Endpoints.ensureCompanyAccess, Endpoints.getRolesList],
  },
  blackUserDetails: {
    path: `/user/:userId(${UUIDRegExp})`,
    endpoints: [Endpoints.getUser],
    isNotMain: true,
    blackOnly: true,
  },
  administrationUserDetails: {
    path: `/administration/user/:userId(${UUIDRegExp})`,
    permission: Permissions.PermissionManageUsers,
    isNotMain: true,
  },
  administrationUserHistory: {
    path: `/administration/user/:userId(${UUIDRegExp})/history`,
    permission: Permissions.PermissionManageUsers,
    isNotMain: true,
  },
  rolesListGlobal: {
    path: '/roles/global',
    permission: Permissions.PermissionManageRoles,
    whiteOnly: true,
  },
  rolesListProduct: {
    path: '/roles/product',
    permission: Permissions.PermissionManageRoles,
    whiteOnly: true,
  },
  roleDetails: {
    path: `/role/:roleId(${UUIDRegExp})`,
    permission: Permissions.PermissionManageRoles,
    isNotMain: true,
  },
  createRole: {
    path: '/role/create/:type(global|product)',
    permission: Permissions.PermissionManageRoles,
    isNotMain: true,
  },
  feeDetails: {
    path: `/fee/:feeId(${UUIDRegExp})`,
    permission: Permissions.PermissionViewFeeDetails,
    isNotMain: true,
  },
  accountAdjustmentsDetails: {
    path: `/account-adjustment/:accountAdjustmentId(${UUIDRegExp})`,
    permission: Permissions.PermissionViewAccountAdjustmentDetails,
    isNotMain: true,
  },
  myEmployees: {
    path: `/my-employees`,
    allowUnverifiedUser: true,
    blackOnly: true,
    endpoints: [Endpoints.getEmployees],
    permission: Permissions.PermissionManageEmployees,
  },
  createAuthorization: {
    path: `/card/:cardId(${UUIDRegExp})/authorization/create`,
    permission: Permissions.PermissionCreatePayment,
    isNotMain: true,
  },
  identityVerificationCreatePerson: {
    path: '/identity/person/add',
    isNotMain: true,
  },
  currentUserDetails: {
    path: `/user`,
    allowUnverifiedUser: true,
  },
  companyProfileDetails: {
    path: `/company-profile`,
    allowUnverifiedUser: true,
  },
  companyBoardMembersAndShareholders: {
    path: `/company-board-members-shareholders`,
  },
  companyLimits: {
    path: `/company-limits`,
    endpoints: [Endpoints.getCompanyLimitsUsage],
  },
  acceptAgreement: {
    path: `/accept-agreement`,
    allowUnverifiedUser: true,
  },
  pricingPlan: {
    path: `/pricing-plan`,
    allowUnverifiedUser: true,
    permission: [Permissions.PermissionViewAccounts],
  },
  currencyRequest: {
    path: `/currency-request`,
    allowUnverifiedUser: false,
    permission: [Permissions.PermissionManageAccounts],
    endpoints: [Endpoints.currencyRequest],
  },
  pricingPlanList: {
    path: `/pricing-plans`,
    permission: Permissions.PermissionUpdateProductPricingPlan,
  },
  orderPackage: {
    path: `/order-package`,
    permission: Permissions.PermissionUpdateProductPricingPlan,
  },
  wbApiKeys: {
    path: `/api-keys`,
    allowUnverifiedUser: true,
    endpoints: [Endpoints.getApiKeysList],
    permission: Permissions.PermissionViewApiKeys,
  },
  wbCreateApiKeyIntroduction: {
    path: `/api-keys/add-introduction`,
    demoRestricted: true,
    permission: Permissions.PermissionCreateApiKey,
    endpoints: [Endpoints.createApiKey],
    isNotMain: true,
  },
  wbCreateApiKeyCreation: {
    path: `/api-keys/add-creation`,
    demoRestricted: true,
    permission: Permissions.PermissionCreateApiKey,
    endpoints: [Endpoints.createApiKey],
  },
  wbCreateApiKeyGeneration: {
    demoRestricted: true,
    path: `/api-keys/add-generation`,
    permission: Permissions.PermissionCreateApiKey,
    endpoints: [Endpoints.createApiKey],
  },
  multiplePaymentsSingle: {
    path: `/multiple-payments/single/:employeeId(${UUIDRegExp})?`,
  },
  multiplePayments: {
    path: `/multiple-payments/multiple`,
    // endpoints: [
    //   Endpoints.getMassPaymentsFiles,
    //   Endpoints.createMassPaymentFile,
    // ],
  },
  multiplePaymentsFile: {
    path: `/multiple-payments/:fileId(${UUIDRegExp})`,
    // endpoints: [Endpoints.getMassPaymentsFile],
  },
  whiteList3DSAndBins: {
    path: `/whitelist-3ds-and-bins`,
    endpoints: [Endpoints.getClientTickets],
  },
  noPermission: {
    path: `/empty`,
  },
};

const routes: Record<keyof typeof _routes, RouteInfo> = _routes;

export const getRoutePath = (
  route: RouteInfo,
  params: Record<string, string | number | undefined> = {}
): string => {
  let {path} = route;
  if (params) {
    path = generatePath(path, params);
  }
  return path;
};

export const InitialProductKey = 'product';

export default routes;
