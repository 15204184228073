import Browser from '../types/browsers';
import BrowserDetect from './browserDetect';

type source = 'site' | 'appStore' | 'googlePlay';

const browsers = {
  chrome: {
    title: 'Chrome',
    link: {
      site: 'https://www.google.com/intl/en/chrome/',
      appStore: 'https://apps.apple.com/us/app/google-chrome/id535886823',
      googlePlay:
        'https://play.google.com/store/apps/details?id=com.android.chrome',
    },
  },
  firefox: {
    title: 'Firefox',
    link: {
      site: 'https://www.mozilla.org/en-US/firefox/new/',
      appStore:
        'https://apps.apple.com/us/app/firefox-private-safe-browser/id989804926',
      googlePlay:
        'https://play.google.com/store/apps/details?id=org.mozilla.firefox',
    },
  },
  safari: {
    title: 'Safari',
    link: {
      site: 'https://support.apple.com/downloads/safari',
      appStore: '',
      googlePlay: '',
    },
  },
  opera: {
    title: 'Opera',
    link: {
      site: 'https://www.opera.com/en/download',
      appStore:
        'https://apps.apple.com/us/app/opera-browser-fast-private/id1411869974',
      googlePlay:
        'https://play.google.com/store/apps/details?id=com.opera.browser',
    },
  },
  edge: {
    title: 'Edge',
    link: {
      site: 'https://www.microsoft.com/en-us/edge',
      appStore:
        'https://apps.apple.com/us/app/microsoft-edge-web-browser/id1288723196',
      googlePlay:
        'https://play.google.com/store/apps/details?id=com.microsoft.emmx',
    },
  },
};

export const getBrowserLink = (browser?: Browser): string | undefined => {
  let type: source = 'site';

  if (BrowserDetect.isMobile()) {
    if (BrowserDetect.isIOS()) {
      type = 'appStore';
    } else if (BrowserDetect.isAndroid()) {
      type = 'googlePlay';
    }
  }

  return browser
    ? browsers[browser].link[type] || browsers[browser].link.site
    : undefined;
};

export const getBrowserTitle = (browser?: Browser): string | undefined => {
  return browser ? browsers[browser].title : undefined;
};
