import {notification} from 'antd';
import {ArgsProps, NotificationInstance} from 'antd/lib/notification';
import React from 'react';
import clsx from 'clsx';
import {v4} from 'uuid';
import {MessageType} from 'antd/lib/message';
import ModalExt from '../components/ModalExt';
import apiErrorsCodeAssociations from '../constants/errors';
import {getIntl} from '../models/i18n';
import DemoModeNotificationNew, {
  DemoModeNotificationProps,
} from '../modules/DemoModeNotification/DemoModeNotification';
import renderToBody from './render';
import RequestError from './RequestError';
import {ModalExtProps} from '../components/ModalExt/ModalExt';
import {ReactComponent as IconInfo} from '../images/icons/warning.svg';
import {ReactComponent as IconWarning} from '../images/icons/warning-triangle.svg';
import {ReactComponent as IconSuccess} from '../images/icons/success.svg';
import {ReactComponent as IconError} from '../images/icons/decline-polygon.svg';
import {ReactComponent as IconClose} from '../images/icons/close.svg';
import BlackCreateCardTopUpNotificationNew from '../modules/BlackCreateCardTopUpNotification';
import {BlackCreateCardTopUpNotificationProps as BlackCreateCardTopUpNotificationPropsNew} from '../modules/BlackCreateCardTopUpNotification/BlackCreateCardTopUpNotification';
import confirm from './modal';
import gcss from './styles';
import {
  DataTestContext,
  getContextTestId,
} from '../customHooks/useContextTestId';

interface INoticeProps extends Omit<ArgsProps, 'type'> {
  type?: keyof NotificationInstance;
  content?: React.ReactNode;
  closable?: boolean;
}

interface IMessageProps extends ArgsProps {
  closable?: boolean;
}

// let lastProductNotification: UUID4 | null = null;

export const notice = ({
  content,
  type,
  closable,
  duration = 4,
  key = v4(),
  ...rest
}: INoticeProps): MessageType => {
  const note = notification[type || 'info'];
  let Icon;

  switch (type) {
    case 'info':
      Icon = IconInfo;
      break;

    case 'warning':
      Icon = IconWarning;
      break;

    case 'success':
      Icon = IconSuccess;
      break;

    case 'error':
      Icon = IconError;
      break;

    default:
      Icon = IconInfo;
      break;
  }

  note({
    key,
    icon: <Icon className="ant-message-notice-icon" />,
    closeIcon: <IconClose className="ant-message-notice-icon" />,
    message: content,
    className: clsx(
      'ant-message_dark',
      `ant-message_${type || 'info'}`,
      closable && 'ant-message_closable'
    ),
    duration: closable ? 0 : duration,
    placement: 'topRight',
    getContainer: () => {
      return document.querySelector('.ant-messages') as HTMLElement;
    },
    ...rest,
  });

  return (() => {
    notification.close(key);
  }) as MessageType;
};

export const info = (
  content: React.ReactNode,
  props?: Partial<IMessageProps>
): MessageType => {
  return notice({
    type: 'info',
    content,
    ...props,
    message: content,
  });
};

export const warning = (
  content: React.ReactNode,
  props?: Partial<IMessageProps>
): MessageType => {
  return notice({
    type: 'warning',
    content,
    ...props,
    message: content,
  });
};

export const success = (
  content: React.ReactNode,
  props?: Partial<IMessageProps>
): MessageType => {
  return notice({
    type: 'success',
    content,
    ...props,
    message: content,
  });
};

export const error = (
  content: React.ReactNode,
  props?: Partial<IMessageProps>
): MessageType => {
  return notice({
    type: 'error',
    content,
    ...props,
    message: content,
  });
};

export const apiErrorNotification = (
  defaultContent: React.ReactNode,
  e?: Error,
  props?: IMessageProps
): MessageType | PromiseLike<void> => {
  if (e && (e as RequestError).getBusinessLogicError !== undefined) {
    const blError = (e as RequestError).getBusinessLogicError();
    if (blError && apiErrorsCodeAssociations[blError.error_code]) {
      return Promise.resolve();
    }
  }
  return error(defaultContent, props);
};

export const leftPageNotification = ({
  title,
  stayButtonText,
  contextTestId,
}: {
  title: React.ReactNode;
  stayButtonText: React.ReactNode;
  contextTestId?: DataTestContext;
}): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    const intl = getIntl();
    const modalContextTestId = getContextTestId(
      'LeftPageNotification',
      contextTestId
    );

    confirm({
      className: 'ant-modal-confirm_sticky ant-modal_simple-vertical-actions',
      title,
      content: intl.formatMessage({id: 'App-Are_you_sure_you_want_to_exit_'}),
      okText: stayButtonText,
      cancelText: intl.formatMessage({
        id: 'App-Exit',
      }),
      cancelButtonProps: {
        className: clsx(
          gcss('t_decoration_underline'),
          'ant-btn_middle-extra ant-btn_wide ant-btn_transparent ant-btn_link'
        ),
      },
      onOk: () => {
        resolve(false);
      },
      onCancel: () => {
        resolve(true);
      },
      closable: false,
      keyboard: false,
      contextTestId: modalContextTestId,
    });
  });
};

// export const clientFrozeNotification = (): Promise<boolean> => {
//   return new Promise<boolean>((resolve) => {
//     const intl = getIntl();
//     const productId = ProductStore.getState()?.id;
//     if (!productId || lastProductNotification === productId) {
//       resolve(false);
//     } else {
//       lastProductNotification = productId;
//       const unmount = renderToBody(
//         <ClientFrozenModal
//           intl={intl}
//           onClose={(status) => {
//             unmount();
//             resolve(status);
//           }}
//         />
//       );
//     }
//   });
// };

export const modalExt = ({
  content,
  ...rest
}: ModalExtProps): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    const intl = getIntl();

    const unmount = renderToBody(
      <ModalExt
        {...rest}
        intl={intl}
        afterClose={() => {
          setTimeout(() => {
            unmount();
          }, 100);
        }}
        onClose={(status) => {
          resolve(status);
        }}
      >
        {content}
      </ModalExt>
    );
  });
};

export const modalNotEnoughFunds = ({
  afterClose,
  ...rest
}: Omit<BlackCreateCardTopUpNotificationPropsNew, 'intl'>): void => {
  const intl = getIntl();
  const BlackCreateCardTopUpNotification = BlackCreateCardTopUpNotificationNew;

  const unmount = renderToBody(
    <BlackCreateCardTopUpNotification
      {...rest}
      intl={intl}
      afterClose={() => {
        if (afterClose) {
          afterClose();
        }

        setTimeout(() => {
          unmount();
        }, 100);
      }}
    />
  );
};

export const modalDemoNotification = ({
  afterClose,
  ...rest
}: Omit<DemoModeNotificationProps, 'intl'>): void => {
  const intl = getIntl();
  const DemoModeNotification = DemoModeNotificationNew;

  const unmount = renderToBody(
    <DemoModeNotification
      {...rest}
      intl={intl}
      afterClose={() => {
        if (afterClose) {
          afterClose();
        }

        setTimeout(() => {
          unmount();
        }, 100);
      }}
    />
  );
};

export default success;
