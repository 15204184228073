import {
  combine,
  createEffect,
  createEvent,
  createStore,
  Store,
  StoreWritable,
} from 'effector';
import {
  cancelProductPricingPlan,
  getProductPricingPlan,
  setProductPricingPlan,
} from '../../api';
import {IProduct, ProductPricingPlan, UUID4} from '../../types';
import PricingPlanType from '../../types/pricingPlan';
import AuthService from '../../services/AuthService';
import checkIsWhiteLabel from '../../utils/whiteLabel';
import logger from '../../logger';
import clearAppStores from '../clearStore';
import {ProductStore} from '../product';

const log = logger.module('PricingPlan');
export interface ProductPricingPlanStoreType {
  plan?: ProductPricingPlan;
  loaded?: boolean;
  loading?: boolean;
  productId?: UUID4;
}

export const ProductPricingPlanInnerStore = createStore<ProductPricingPlanStoreType>(
  {}
);

export const ProductPricingPlanStore = combine<
  StoreWritable<ProductPricingPlanStoreType>,
  Store<IProduct | null>,
  ProductPricingPlanStoreType
>(ProductPricingPlanInnerStore, ProductStore, (innerStore, productStore) => {
  if (productStore?.id !== innerStore.productId) {
    return {
      loaded: false,
      loading: true,
      productId: innerStore.productId,
    };
  }
  return innerStore;
});

export const getProductPricingPlanFx = createEffect<
  UUID4,
  ProductPricingPlan | undefined,
  Error
>({
  name: 'getProductPricingPlanFx',
  handler: async (productId) => {
    const isWhiteLabel = checkIsWhiteLabel();

    if (!isWhiteLabel && AuthService.getInstance().isFullyVerified()) {
      const pricingPlan = await getProductPricingPlan(productId);
      if (!pricingPlan?.pricing_plan_type) {
        log.error('Wrong pricing plan type: Empty pricing plan');
      }
      return pricingPlan;
    }

    return undefined;
  },
});

ProductPricingPlanInnerStore.on(getProductPricingPlanFx, (state) => {
  return {...state, loading: true};
});

ProductPricingPlanInnerStore.on(
  getProductPricingPlanFx.done,
  (state, {result: data, params}) => {
    return {...state, plan: data, productId: params};
  }
);

ProductPricingPlanInnerStore.on(getProductPricingPlanFx.finally, (state) => {
  return {...state, loaded: true, loading: false};
});

ProductPricingPlanInnerStore.on(
  getProductPricingPlanFx.fail,
  (state, {params}) => {
    return {...state, plan: undefined, productId: params};
  }
);

export const setProductPricingPlanFx = createEffect<
  {plan: PricingPlanType; productId: UUID4},
  ProductPricingPlan,
  Error
>({
  name: 'setProductPricingPlanFx',
  handler: async ({plan: type, productId}) => {
    await setProductPricingPlan({type, accept_pricing_plan_agreement: true});
    return getProductPricingPlan(productId, true);
  },
});

export const cancelProductPricingPlanFx = createEffect<
  UUID4,
  ProductPricingPlan,
  Error
>({
  name: 'cancelProductPricingPlanFx',
  handler: async (productId) => {
    await cancelProductPricingPlan();
    return getProductPricingPlan(productId, true);
  },
});

ProductPricingPlanInnerStore.on(setProductPricingPlanFx, (state) => {
  return {...state, loading: true};
});

ProductPricingPlanInnerStore.on(setProductPricingPlanFx.finally, (state) => {
  return {...state, loading: false};
});

ProductPricingPlanInnerStore.on(cancelProductPricingPlanFx, (state) => {
  return {...state, loading: true};
});

ProductPricingPlanInnerStore.on(cancelProductPricingPlanFx.finally, (state) => {
  return {...state, loading: false};
});

ProductPricingPlanInnerStore.on(
  cancelProductPricingPlanFx.doneData,
  (state, data) => {
    return {...state, plan: data};
  }
);

ProductPricingPlanInnerStore.on(
  setProductPricingPlanFx.doneData,
  (state, data) => {
    return {...state, plan: data};
  }
);

export const cleanProductPricingPlanStoreFx = createEvent();

ProductPricingPlanInnerStore.reset(
  cleanProductPricingPlanStoreFx,
  clearAppStores
);
