import React, {useEffect} from 'react';
import Permissions from '../../../constants/permissions';
import useCheckPermissions from '../../../customHooks/useCheckPermissions';
import {useProduct} from '../../../customHooks/useProduct';
import {loadBalance, loadContactInfo} from '../../../models/product';
import {resetSystemDialogNotificationStore} from '../../../models/systemDialogNotifications';
import {setMessengerClientInfo} from '../../../utils/messenger';
// import {clientFrozeNotification} from '../../../utils/notifications';

const ProductManager: React.FC = () => {
  const product = useProduct();

  const isHasPermissionLoadBalance = useCheckPermissions([
    Permissions.PermissionViewSettlementBalance,
  ]);

  useEffect(() => {
    if (product?.id) {
      if (isHasPermissionLoadBalance) {
        loadBalance();
      }
      loadContactInfo();
    }
  }, [product?.id, isHasPermissionLoadBalance]);

  useEffect(() => {
    if (product?.id) {
      resetSystemDialogNotificationStore();
      setMessengerClientInfo();
    }
  }, [product?.id]);

  return null;
};

export default ProductManager;
