import routes, {getRoutePath} from '../constants/routes';
import checkIsWhiteLabel from './whiteLabel';

export const getMainPath = (): string => {
  return checkIsWhiteLabel()
    ? getRoutePath(routes.myCustomers)
    : getRoutePath(routes.blackDashboard);
};

export default getMainPath;
