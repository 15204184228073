import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {StoreValue} from 'rc-field-form/lib/interface';
import {createIntl} from 'react-intl';
import {ICard, UUID4, CountryCode as CountryCodes2l} from '../types';

const ruIntl = createIntl({locale: 'ru-RU'});

export const formatCurrencyNumber = (
  raw: number,
  opt: {
    decimalPlaces?: number;
  } = {
    decimalPlaces: 2,
  },
  withSigh = false
): string => {
  return ruIntl.formatNumber(raw, {
    style: 'decimal',
    minimumFractionDigits: opt?.decimalPlaces,
    maximumFractionDigits: opt?.decimalPlaces,
    signDisplay: withSigh ? 'auto' : 'never',
  });
};

export const formatPhoneNumber = (
  phone: string,
  international?: boolean,
  countryCode?: CountryCodes2l
): string => {
  if (phone.trim() !== '') {
    const parsedNumber = parsePhoneNumberFromString(phone, countryCode);
    if (parsedNumber && parsedNumber.isPossible()) {
      if (international === true) {
        return parsedNumber.formatInternational();
      }
      return parsedNumber.formatNational();
    }
  }
  return phone;
};

export const formatCardNumber = (
  cardNumber: string,
  short?: boolean,
  middle?: boolean
): string => {
  const str = String(cardNumber).trim();

  if (str !== '') {
    if (short) {
      return `*${str.substr(str.length - 4)}`;
    }

    if (middle) {
      return `**** ${str.substr(str.length - 4)}`;
    }

    const groups = str.match(/.{1,4}/g);

    if (groups) {
      return groups.join(' ');
    }
  }

  return str;
};

export const formatCard = (card: ICard, isWhiteLabel = true): ICard => {
  if (isWhiteLabel) {
    return card;
  }

  return {
    ...card,
    masked_card_number: card.masked_card_number.replace(/^\d{1,6}/, '******'),
  };
};

export const formatNumber = (number: number): string => {
  return number.toLocaleString('ru-RU');
};

export const formatId = (id: UUID4): string => {
  return `${id.substring(8, 0)}...${id.substring(id.length - 5)}`;
};

export const shortNumber = (number: number, digits?: number): string => {
  const lookup = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'k'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'G'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'},
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((el) => {
      return number >= el.value;
    });
  return item
    ? (number / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};

export const toNumber = (value: string | number): number => {
  return parseFloat(`${value}`.replace(',', '.')) || 0;
};

export const toNumberOrUndefined = (
  value: string | number | undefined,
  checkUndefined: boolean
): number | undefined => {
  if (checkUndefined && value === undefined) {
    return undefined;
  }
  return parseFloat(`${value}`) || 0;
};

export const transformRule = (value: StoreValue): StoreValue => {
  return toNumber(value);
};

export const transformToNumberNotEmptyRule = (
  value: StoreValue
): StoreValue | undefined => {
  if (value === '' || value === undefined) {
    return undefined;
  }
  return toNumber(value);
};

export const transformNotEmptyRule = (
  value: StoreValue
): StoreValue | undefined => {
  if (value === '' || value === undefined) {
    return undefined;
  }
  return value;
};

export const splitCamelCase = (str: string): string => {
  return str
    .split(/([A-Z][a-z]+)/)
    .filter((e) => e)
    .join(' ');
};

export default formatPhoneNumber;
