import HINTS_TYPES, {
  HINT_EVENT_TYPE,
  HINT_ROUTE_MODIFICATION,
  HintConfig,
} from '../types/hints';
import routes from './routes';

export default HINTS_TYPES;

export const InitHints: HintConfig[] = [
  {
    id: HINTS_TYPES.INIT_TOP_UP,
    type: 'single',
    title_key: 'Hints-Top_up_the_balance',
    content_key:
      'Hints-Top_up_your_balance_to_make_payments__access_paid_services__',
    modalPlacement: 'right',
    mediaData: {
      sm: {
        hidden: true,
      },
    },
  },
  {
    id: HINTS_TYPES.INIT_CREATE_CARD,
    type: 'single',
    title_key: 'Hints-Create_cards',
    content_key:
      'Hints-Create_virtual_VISA_cards_to_immediately_access_contactless_',
    modalPlacement: 'right',
    mediaData: {
      sm: {
        hidden: true,
      },
    },
  },
  {
    id: HINTS_TYPES.INIT_SWITCH_PRODUCTS,
    type: 'single',
    title_key: 'Hints-Switch_between_the_products',
    content_key: 'Hints-Manage_all_available_products_in_one_account_',
    modalPlacement: 'right',
    mediaData: {
      sm: {
        hidden: true,
      },
    },
  },
  {
    id: HINTS_TYPES.INIT_CREATE_USERS,
    type: 'single',
    title_key: 'Hints-Create_users',
    content_key:
      'Hints-Create_user_accounts_for_employees_to_access_company_s_accou',
    modalPlacement: 'right',
    mediaData: {
      sm: {
        hidden: true,
      },
    },
  },
  {
    id: HINTS_TYPES.INIT_MAKE_PAYMENTS,
    type: 'single',
    title_key: 'Hints-Make_payments',
    content_key:
      'Hints-Make_fast_and_secure_money_transfers_to_companies__accounts_',
    modalPlacement: 'right',
    mediaData: {
      sm: {
        hidden: true,
      },
    },
  },
];

export const EventHints: HintConfig[] = [
  {
    id: HINTS_TYPES.EVENT_CREATE_CARD,
    eventType: HINT_EVENT_TYPE.EVENT_CREATE_CARD,
    type: 'inline',
    title_key: 'Hints-Install_Wallester_app',
    content_key:
      'Hints-Download_the_official_Wallester_app_with_access_to_all_your_',
    tooltipProps: {
      placement: 'right',
    },
    mediaData: {
      sm: {
        tooltipProps: {
          placement: 'bottom',
        },
      },
      xs: {
        outOfScreenVisible: true,
      },
    },
  },
  {
    id: HINTS_TYPES.EVENT_RECEIPT_REMINDERS,
    eventType: HINT_EVENT_TYPE.EVENT_RECEIPT_REMINDERS,
    type: 'inline',
    title_key: 'App-Receipt_reminders',
    content_key:
      'Hints-You_can_now_enable_automatic_receipt_reminders_for_card_users',
    tooltipProps: {
      placement: 'left',
    },
    mediaData: {
      md: {
        tooltipProps: {
          placement: 'topLeft',
        },
      },
      xs: {
        outOfScreenVisible: true,
      },
    },
  },
];

export const RouteHints: HintConfig[] = [];

export const FeatureHints: HintConfig[] = [
  {
    id: HINTS_TYPES.FEATURE_EXPORT_STATEMENTS,
    type: 'inline',
    outOfScreenVisible: true,
    shadowed: true,
    mediaData: {
      xs: {
        shadowed: false,
      },
    },
  },
];

export const DemoInitHints: HintConfig[] = [];

export const DemoEventHints: HintConfig[] = [];

export const DemoRouteHints: HintConfig[] = [
  {
    id: HINTS_TYPES.ROUTE_TOP_UP,
    type: 'route',
    hintRoute: routes.mainTopUp,
    title_key: 'App-Top_up',
    content_key: 'Hints-Here_you_can_select_the_account_you_would_like',
  },
  {
    id: HINTS_TYPES.ROUTE_ACCOUNTS,
    type: 'route',
    hintRoute: routes.blackAccounts,
    title_key: 'App-Accounts',
    content_key: 'Hints-All_created_accounts_and_related_info_to_them',
  },
  {
    id: HINTS_TYPES.ROUTE_ACCOUNTS,
    type: 'route',
    hintRoute: routes.blackAccounts,
    title_key: 'App-Accounts',
    content_key: 'Hints-All_created_accounts_and_related_info_to_them',
  },
  {
    id: HINTS_TYPES.ROUTE_COMPANY_LIMITS,
    type: 'route',
    hintRoute: routes.companyLimits,
    title_key: 'App-Accounts',
    content_key:
      'Hints-View_your_company_s_overall_limits_and_adjust_them_for_different',
  },
  {
    id: HINTS_TYPES.ROUTE_CREATE_CARD,
    type: 'route',
    hintRoute: routes.createCardTypes,
    title_key: 'App-Cards',
    content_key:
      'Hints-Select_the_card_you_want_to_create_Expense_or_Payroll_Card_both_',
  },

  {
    id: HINTS_TYPES.ROUTE_CREATE_CARD_VIRTUAL,
    type: 'route',
    hintRoute: routes.createExpensesCard,
    hintRouteModification: HINT_ROUTE_MODIFICATION.CREATE_CARD_VIRTUAL,
    title_key: 'App-Cards',
    content_key:
      'Hints-Connect_your_card_by_choosing_the_account_assigning_a_card_nickn',
  },
  {
    id: HINTS_TYPES.ROUTE_CREATE_CARD_PHYS,
    type: 'route',
    hintRoute: routes.createExpensesCard,
    hintRouteModification: HINT_ROUTE_MODIFICATION.CREATE_CARD_PHYSICAL,
    title_key: 'App-Cards',
    content_key:
      'Hints-Connect_your_card_swiftly_select_the_linked_account_assign_an_in',
  },
  {
    id: HINTS_TYPES.ROUTE_PAYROLL_CARD,
    type: 'route',
    hintRoute: routes.createEmployeesCard,
    title_key: 'App-Cards',
    content_key:
      'Hints-Invite_cardholders_with_a_click_simple_process_Provide_the_phone',
  },
  {
    id: HINTS_TYPES.ROUTE_CARD_DETAILS,
    type: 'route',
    hintRoute: routes.cardDetail,
    title_key: 'App-Cards',
    content_key:
      'Hints-You_can_view_and_manage_your_card_details_the_users_linked_with_',
  },
  {
    id: HINTS_TYPES.ROUTE_ACCOUNT_DETAILS,
    type: 'route',
    hintRoute: routes.blackAccountDetails,
    title_key: 'App-Cards',
    content_key:
      'Hints-View_the_details_of_the_account_such_as_the_number_of_cards_avai',
  },
  {
    id: HINTS_TYPES.ROUTE_EXPENSE_PAYMENTS,
    type: 'route',
    hintRoute: routes.makeTransfer,
    title_key: 'App-Cards',
    content_key:
      'Hints-Transfer_the_money_between_your_accounts_to_keep_your_expenses_o',
  },
  {
    id: HINTS_TYPES.ROUTE_PAYROLL_PAYMENTS,
    type: 'route',
    hintRoute: routes.multiplePaymentsSingle,
    title_key: 'App-Cards',
    content_key:
      'Hints-Choose_which_cardholder_you_want_to_pay_from_the_list_fill_the_a',
  },
  {
    id: HINTS_TYPES.ROUTE_MASS_PAYMENTS,
    type: 'route',
    hintRoute: routes.multiplePayments,
    title_key: 'App-Cards',
    content_key:
      'Hints-Process_up_to_1500_payments_using_the_provided_template_to_simpl',
  },
  {
    id: HINTS_TYPES.ROUTE_STATEMENTS,
    type: 'route',
    hintRoute: routes.blackStatements,
    title_key: 'App-Cards',
    content_key:
      'Hints-Apply_the_filters_and_generate_the_statement_report_in_suitable_',
  },
  {
    id: HINTS_TYPES.ROUTE_API,
    type: 'route',
    hintRoute: routes.wbApiKeys,
    title_key: 'App-Cards',
    content_key:
      'Hints-Connect_and_automatize_your_working_flow_by_creating_the_API_key',
  },
  {
    id: HINTS_TYPES.ROUTE_PRICING_PLAN,
    type: 'route',
    hintRoute: routes.pricingPlan,
    title_key: 'App-Cards',
    content_key:
      'Hints-Look_through_each_pricing_plan_and_choose_one_that_suits_your_bu',
  },
  {
    id: HINTS_TYPES.ROUTE_MY_PROFILE,
    type: 'route',
    hintRoute: routes.currentUserDetails,
    title_key: 'App-Cards',
    content_key:
      'Hints-View_your_profile_details_and_if_needed_update_profile_picture_f',
  },
  {
    id: HINTS_TYPES.ROUTE_COMPANY_PROFILE,
    type: 'route',
    hintRoute: routes.companyProfileDetails,
    title_key: 'App-Cards',
    content_key:
      'Hints-Have_a_look_at_the_information_about_your_company_that_was_given',
  },
  {
    id: HINTS_TYPES.ROUTE_CURRENCY_REQUEST,
    title_key: 'App-Currency_request',
    hintRoute: routes.currencyRequest,
    type: 'route',
    content_key:
      'Hints-Select_the_currency_you_would_like_to_use__provide_additional_in',
  },
  {
    id: HINTS_TYPES.ROUTE_3DS_BIN,
    title_key: 'App-Currency_request',
    hintRoute: routes.whiteList3DSAndBins,
    type: 'route',
    content_key:
      'Hints-To_improve_the_efficiency_and_security_of_your_payment_processes',
  },
];

export const DemoFeatureHints: HintConfig[] = [];
