import {CountryCode} from 'libphonenumber-js';
import {TypedMap} from './common';
import {CountryCodes3l} from './geo';

export const language2lCodes = [
  'AA',
  'AB',
  'AE',
  'AF',
  'AK',
  'AM',
  'AN',
  'AR',
  'AS',
  'AV',
  'AY',
  'AZ',
  'BA',
  'BE',
  'BG',
  'BH',
  'BI',
  'BM',
  'BN',
  'BO',
  'BR',
  'BS',
  'CA',
  'CE',
  'CH',
  'CO',
  'CR',
  'CS',
  'CU',
  'CV',
  'CY',
  'DA',
  'DE',
  'DV',
  'DZ',
  'EE',
  'EL',
  'EN',
  'EO',
  'ES',
  'ET',
  'EU',
  'FA',
  'FF',
  'FI',
  'FJ',
  'FO',
  'FR',
  'FY',
  'GA',
  'GD',
  'GL',
  'GN',
  'GU',
  'GV',
  'HA',
  'HE',
  'HI',
  'HO',
  'HR',
  'HT',
  'HU',
  'HY',
  'HZ',
  'IA',
  'ID',
  'IE',
  'IG',
  'II',
  'IK',
  'IO',
  'IS',
  'IT',
  'IU',
  'JA',
  'JV',
  'KA',
  'KG',
  'KI',
  'KJ',
  'KK',
  'KL',
  'KM',
  'KN',
  'KO',
  'KR',
  'KS',
  'KU',
  'KV',
  'KW',
  'KY',
  'LA',
  'LB',
  'LG',
  'LI',
  'LN',
  'LO',
  'LT',
  'LU',
  'LV',
  'MG',
  'MH',
  'MI',
  'MK',
  'ML',
  'MN',
  'MR',
  'MS',
  'MT',
  'MY',
  'NA',
  'NB',
  'ND',
  'NE',
  'NG',
  'NL',
  'NN',
  'NO',
  'NR',
  'NV',
  'NY',
  'OC',
  'OJ',
  'OM',
  'OR',
  'OS',
  'PA',
  'PI',
  'PL',
  'PS',
  'PT',
  'QU',
  'RM',
  'RN',
  'RO',
  'RU',
  'RW',
  'SA',
  'SC',
  'SD',
  'SE',
  'SG',
  'SI',
  'SK',
  'SL',
  'SM',
  'SN',
  'SO',
  'SQ',
  'SR',
  'SS',
  'ST',
  'SU',
  'SV',
  'SW',
  'TA',
  'TE',
  'TG',
  'TH',
  'TI',
  'TK',
  'TL',
  'TN',
  'TO',
  'TR',
  'TS',
  'TT',
  'TW',
  'TY',
  'UG',
  'UK',
  'UR',
  'UZ',
  'VE',
  'VI',
  'VO',
  'WA',
  'WO',
  'XH',
  'YI',
  'YO',
  'ZA',
  'ZH',
  'ZU',
] as const;

export const language3lCodes = [
  'AAR',
  'ABK',
  'AFR',
  'AKA',
  'AMH',
  'ARA',
  'ARG',
  'ASM',
  'AVA',
  'AVE',
  'AYM',
  'AZE',
  'BAK',
  'BAL',
  'BAM',
  'BEL',
  'BEN',
  'BIH',
  'BIS',
  'BOD',
  'BOS',
  'BRE',
  'BUL',
  'CAT',
  'CES',
  'CHA',
  'CHE',
  'CHU',
  'CHV',
  'COR',
  'COS',
  'CRE',
  'CYM',
  'DAN',
  'DEU',
  'DIV',
  'DZO',
  'ELL',
  'ENG',
  'EPO',
  'EST',
  'EUS',
  'EWE',
  'FAO',
  'FAS',
  'FIJ',
  'FIN',
  'FRA',
  'FRY',
  'FUL',
  'GLA',
  'GLE',
  'GLG',
  'GLV',
  'GRN',
  'GUJ',
  'HAT',
  'HAU',
  'HEB',
  'HER',
  'HIN',
  'HMO',
  'HRV',
  'HUN',
  'HYE',
  'IBO',
  'IDO',
  'III',
  'IKU',
  'ILE',
  'INA',
  'IND',
  'IPK',
  'ISL',
  'ITA',
  'JAV',
  'JPN',
  'KAL',
  'KAN',
  'KAS',
  'KAT',
  'KAU',
  'KAZ',
  'KHM',
  'KIK',
  'KIN',
  'KIR',
  'KOM',
  'KON',
  'KOR',
  'KUA',
  'KUR',
  'LAO',
  'LAT',
  'LAV',
  'LIJ',
  'LIN',
  'LIM',
  'LIT',
  'LTZ',
  'LUB',
  'LUG',
  'MAH',
  'MAL',
  'MAR',
  'MKD',
  'MLG',
  'MLT',
  'MON',
  'MRI',
  'MSA',
  'MYA',
  'NAU',
  'NAV',
  'NBL',
  'NDE',
  'NDO',
  'NEP',
  'NLD',
  'NNO',
  'NOB',
  'NOR',
  'NYA',
  'OCI',
  'OJI',
  'ORI',
  'ORM',
  'OSS',
  'PAN',
  'PLI',
  'POL',
  'POR',
  'PUS',
  'QUE',
  'ROH',
  'RON',
  'RUN',
  'RUS',
  'SAG',
  'SAN',
  'SIN',
  'SLK',
  'SLV',
  'SME',
  'SMO',
  'SNA',
  'SND',
  'SOM',
  'SOT',
  'SPA',
  'SQI',
  'SRD',
  'SRP',
  'SSW',
  'SUN',
  'SWA',
  'SWE',
  'TAH',
  'TAM',
  'TAT',
  'TEL',
  'TGK',
  'TGL',
  'THA',
  'TIR',
  'TON',
  'TSN',
  'TSO',
  'TUK',
  'TUR',
  'TWI',
  'UIG',
  'UKR',
  'URD',
  'UZB',
  'VEN',
  'VIE',
  'VOL',
  'WLN',
  'WOL',
  'XHO',
  'YID',
  'YOR',
  'ZHA',
  'ZHO',
  'ZUL',
] as const;

export type LanguageCode2l = typeof language2lCodes[number];

export type LanguageCode3l = typeof language3lCodes[number];

export const languageCode2lTo3l: Record<LanguageCode2l, LanguageCode3l> = {
  AA: 'AAR',
  AB: 'ABK',
  AE: 'AVE',
  AF: 'AFR',
  AK: 'AKA',
  AM: 'AMH',
  AN: 'ARG',
  AR: 'ARA',
  AS: 'ASM',
  AV: 'AVA',
  AY: 'AYM',
  AZ: 'AZE',
  BA: 'BAK',
  BE: 'BEL',
  BG: 'BUL',
  BI: 'BIS',
  BH: 'BIH',
  BM: 'BAM',
  BN: 'BEN',
  BO: 'BOD',
  BR: 'BRE',
  BS: 'BOS',
  CA: 'CAT',
  CE: 'CHE',
  CH: 'CHA',
  CO: 'COS',
  CR: 'CRE',
  CS: 'CES',
  CU: 'CHU',
  CV: 'CHV',
  CY: 'CYM',
  DA: 'DAN',
  DE: 'DEU',
  DV: 'DIV',
  DZ: 'DZO',
  EE: 'EWE',
  EL: 'ELL',
  EN: 'ENG',
  EO: 'EPO',
  ES: 'SPA',
  ET: 'EST',
  EU: 'EUS',
  FA: 'FAS',
  FF: 'FUL',
  FI: 'FIN',
  FJ: 'FIJ',
  FO: 'FAO',
  FR: 'FRA',
  FY: 'FRY',
  GA: 'GLE',
  GD: 'GLA',
  GL: 'GLG',
  GN: 'GRN',
  GU: 'GUJ',
  GV: 'GLV',
  HA: 'HAU',
  HE: 'HEB',
  HI: 'HIN',
  HO: 'HMO',
  HR: 'HRV',
  HT: 'HAT',
  HU: 'HUN',
  HY: 'HYE',
  HZ: 'HER',
  IA: 'INA',
  ID: 'IND',
  IE: 'ILE',
  IG: 'IBO',
  II: 'III',
  IK: 'IPK',
  IO: 'IDO',
  IS: 'ISL',
  IT: 'ITA',
  IU: 'IKU',
  JA: 'JPN',
  JV: 'JAV',
  KA: 'KAT',
  KG: 'KON',
  KI: 'KIK',
  KJ: 'KUA',
  KK: 'KAZ',
  KL: 'KAL',
  KM: 'KHM',
  KN: 'KAN',
  KO: 'KOR',
  KR: 'KAU',
  KS: 'KAS',
  KU: 'KUR',
  KV: 'KOM',
  KW: 'COR',
  KY: 'KIR',
  LA: 'LAT',
  LB: 'LTZ',
  LG: 'LUG',
  LI: 'LIM',
  LN: 'LIN',
  LO: 'LAO',
  LT: 'LIT',
  LU: 'LUB',
  LV: 'LAV',
  MG: 'MLG',
  MH: 'MAH',
  MI: 'MRI',
  MK: 'MKD',
  ML: 'MAL',
  MN: 'MON',
  MR: 'MAR',
  MS: 'MSA',
  MT: 'MLT',
  MY: 'MYA',
  NA: 'NAU',
  NB: 'NOB',
  ND: 'NDE',
  NE: 'NEP',
  NG: 'NDO',
  NL: 'NLD',
  NN: 'NNO',
  NO: 'NOR',
  NR: 'NBL',
  NV: 'NAV',
  NY: 'NYA',
  OC: 'OCI',
  OJ: 'OJI',
  OM: 'ORM',
  OR: 'ORI',
  OS: 'OSS',
  PA: 'PAN',
  PI: 'PLI',
  PL: 'POL',
  PS: 'PUS',
  PT: 'POR',
  QU: 'QUE',
  RM: 'ROH',
  RN: 'RUN',
  RO: 'RON',
  RU: 'RUS',
  RW: 'KIN',
  SA: 'SAN',
  SC: 'SRD',
  SD: 'SND',
  SE: 'SME',
  SG: 'SAG',
  SI: 'SIN',
  SK: 'SLK',
  SL: 'SLV',
  SM: 'SMO',
  SN: 'SNA',
  SO: 'SOM',
  SQ: 'SQI',
  SR: 'SRP',
  SS: 'SSW',
  ST: 'SOT',
  SU: 'SUN',
  SV: 'SWE',
  SW: 'SWA',
  TA: 'TAM',
  TE: 'TEL',
  TG: 'TGK',
  TH: 'THA',
  TI: 'TIR',
  TK: 'TUK',
  TL: 'TGL',
  TN: 'TSN',
  TO: 'TON',
  TR: 'TUR',
  TS: 'TSO',
  TT: 'TAT',
  TW: 'TWI',
  TY: 'TAH',
  UG: 'UIG',
  UK: 'UKR',
  UR: 'URD',
  UZ: 'UZB',
  VE: 'VEN',
  VI: 'VIE',
  VO: 'VOL',
  WA: 'WLN',
  WO: 'WOL',
  XH: 'XHO',
  YI: 'YID',
  YO: 'YOR',
  ZA: 'ZHA',
  ZH: 'ZHO',
  ZU: 'ZUL',
};

export const languageCode3lTo2l: TypedMap<
  LanguageCode3l,
  LanguageCode2l
> = (() => {
  const data: TypedMap<LanguageCode3l, LanguageCode2l> = {};

  (Object.keys(languageCode2lTo3l) as LanguageCode2l[]).forEach((locale) => {
    data[languageCode2lTo3l[locale]] = locale;
  });

  return data;
})();

export const languageCode2lToTitle: Record<LanguageCode2l, string> = {
  AE: 'Avestan',
  LU: 'Kiluba',
  NB: 'Bokmål',
  AA: 'Afar',
  AB: 'Аҧсуа',
  AF: 'Afrikaans',
  AK: 'Akana',
  AM: 'አማርኛ',
  AN: 'Aragonés',
  AR: 'العربية',
  AS: 'অসমীয়া',
  AV: 'Авар',
  AY: 'Aymar',
  AZ: 'Azərbaycanca / آذربايجان',
  BA: 'Башҡорт',
  BE: 'Беларуская',
  BG: 'Български',
  BH: 'भोजपुरी',
  BI: 'Bislama',
  BM: 'Bamanankan',
  BN: 'বাংলা',
  BO: 'བོད་ཡིག / Bod skad',
  BR: 'Brezhoneg',
  BS: 'Bosanski',
  CA: 'Català',
  CE: 'Нохчийн',
  CH: 'Chamoru',
  CO: 'Corsu',
  CR: 'Nehiyaw',
  CS: 'Čeština',
  CU: 'словѣньскъ / slověnĭskŭ',
  CV: 'Чăваш',
  CY: 'Cymraeg',
  DA: 'Dansk',
  DE: 'Deutsch',
  DV: 'ދިވެހިބަސް',
  DZ: 'རྫོང་ཁ',
  EE: 'Ɛʋɛ',
  EL: 'Ελληνικά',
  EN: 'English',
  EO: 'Esperanto',
  ES: 'Español',
  ET: 'Eesti',
  EU: 'Euskara',
  FA: 'فارسی',
  FF: 'Fulfulde',
  FI: 'Suomi',
  FJ: 'Na Vosa Vakaviti',
  FO: 'Føroyskt',
  FR: 'Français',
  FY: 'Frysk',
  GA: 'Gaeilge',
  GD: 'Gàidhlig',
  GL: 'Galego',
  GN: "Avañe'ẽ",
  GU: 'ગુજરાતી',
  GV: 'Gaelg',
  HA: 'هَوُسَ',
  HE: 'עברית',
  HI: 'हिन्दी',
  HO: 'Hiri Motu',
  HR: 'Hrvatski',
  HT: 'Krèyol ayisyen',
  HU: 'Magyar',
  HY: 'Հայերեն',
  HZ: 'Otsiherero',
  IA: 'Interlingua',
  ID: 'Bahasa Indonesia',
  IE: 'Interlingue',
  IG: 'Igbo',
  II: 'ꆇꉙ / 四川彝语',
  IK: 'Iñupiak',
  IO: 'Ido',
  IS: 'Íslenska',
  IT: 'Italiano',
  IU: 'ᐃᓄᒃᑎᑐᑦ',
  JA: '日本語',
  JV: 'Basa Jawa',
  KA: 'ქართული',
  KG: 'KiKongo',
  KI: 'Gĩkũyũ',
  KJ: 'Kuanyama',
  KK: 'Қазақша',
  KL: 'Kalaallisut',
  KM: 'ភាសាខ្មែរ',
  KN: 'ಕನ್ನಡ',
  KO: '한국어',
  KR: 'Kanuri',
  KS: 'कॉशुर / کٲشُر',
  KU: 'Kurdî',
  KV: 'Коми',
  KW: 'Kernewek',
  KY: 'Kırgızca / Кыргызча',
  LA: 'Latina',
  LB: 'Lëtzebuergesch',
  LG: 'Luganda',
  LI: 'Limburgs',
  LN: 'Lingála',
  LO: 'ລາວ / Pha xa lao',
  LT: 'Lietuvių',
  LV: 'Latviešu',
  MG: 'Malagasy',
  MH: 'Kajin Majel / Ebon',
  MI: 'Māori',
  MK: 'Македонски',
  ML: 'മലയാളം',
  MN: 'Монгол',
  MR: 'मराठी',
  MS: 'Bahasa Melayu',
  MT: 'bil-Malti',
  MY: 'Myanmasa',
  NA: 'Dorerin Naoero',
  ND: 'Sindebele',
  NE: 'नेपाली',
  NG: 'Oshiwambo',
  NL: 'Nederlands',
  NN: 'Norsk (nynorsk)',
  NO: 'Norsk (bokmål / riksmål)',
  NR: 'isiNdebele',
  NV: 'Diné bizaad',
  NY: 'Chi-Chewa',
  OC: 'Occitan',
  OJ: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin',
  OM: 'Oromoo',
  OR: 'ଓଡ଼ିଆ',
  OS: 'Иронау',
  PA: 'ਪੰਜਾਬੀ / پنجابی',
  PI: 'Pāli / पाऴि',
  PL: 'Polski',
  PS: 'پښتو',
  PT: 'Português',
  QU: 'Runa Simi',
  RM: 'Rumantsch',
  RN: 'Kirundi',
  RO: 'Română',
  RU: 'Русский',
  RW: 'Kinyarwandi',
  SA: 'संस्कृतम्',
  SC: 'Sardu',
  SD: 'सिंधी / سنڌي',
  SE: 'Davvisámegiella',
  SG: 'Sängö',
  SI: 'සිංහල',
  SK: 'Slovenčina',
  SL: 'Slovenščina',
  SM: 'Gagana Samoa',
  SN: 'chiShona',
  SO: 'Soomaaliga',
  SQ: 'Shqip',
  SR: 'Српски',
  SS: 'SiSwati',
  ST: 'Sesotho',
  SU: 'Basa Sunda',
  SV: 'Svenska',
  SW: 'Kiswahili',
  TA: 'தமிழ்',
  TE: 'తెలుగు',
  TG: 'Тоҷикӣ',
  TH: 'ไทย / Phasa Thai',
  TI: 'ትግርኛ',
  TK: 'Туркмен / تركمن',
  TL: 'Tagalog',
  TN: 'Setswana',
  TO: 'Lea Faka-Tonga',
  TR: 'Türkçe',
  TS: 'Xitsonga',
  TT: 'Tatarça',
  TW: 'Twi',
  TY: 'Reo Mā`ohi',
  UG: 'Uyƣurqə / ئۇيغۇرچە',
  UK: 'Українська',
  UR: 'اردو',
  UZ: 'Ўзбек',
  VE: 'Tshivenḓa',
  VI: 'Tiếng Việt',
  VO: 'Volapük',
  WA: 'Walon',
  WO: 'Wollof',
  XH: 'isiXhosa',
  YI: 'ייִדיש',
  YO: 'Yorùbá',
  ZA: 'Cuengh / Tôô / 壮语',
  ZH: '中文',
  ZU: 'isiZulu',
};

export const languageCode3lToTitle: TypedMap<LanguageCode3l, string> = (() => {
  const data: TypedMap<LanguageCode3l, string> = {};

  (Object.keys(languageCode2lToTitle) as LanguageCode2l[]).forEach((l2) => {
    data[languageCode2lTo3l[l2]] = languageCode2lToTitle[l2];
  });

  return data;
})();

export const countryCode2ltoLanguageCode3l: TypedMap<
  CountryCode,
  LanguageCode3l
> = {
  US: 'ENG',
  UA: 'UKR',
  BG: 'BUL',
  HR: 'HRV',
  CZ: 'CES',
  DK: 'DAN',
  NL: 'NLD',
  IE: 'ENG',
  EE: 'EST',
  FI: 'FIN',
  BE: 'FRA',
  FR: 'FRA',
  LU: 'FRA',
  AT: 'DEU',
  DE: 'DEU',
  CY: 'ELL',
  GR: 'ELL',
  HU: 'HUN',
  IT: 'ITA',
  LV: 'LAV',
  LT: 'LIT',
  MT: 'MLT',
  PL: 'POL',
  PT: 'POR',
  RO: 'RON',
  SK: 'SLK',
  SI: 'SLV',
  ES: 'SPA',
  SE: 'SWE',
};

export const languageCode3lToFlagCountryCode3l: TypedMap<
  LanguageCode3l,
  CountryCodes3l
> = {
  ABK: 'GEO',
  ENG: 'GBR',
  CES: 'CZE',
  DAN: 'DNK',
  DEU: 'DEU',
  SPA: 'ESP',
  EST: 'EST',
  FIN: 'FIN',
  FRA: 'FRA',
  ITA: 'ITA',
  LIT: 'LTU',
  LAV: 'LVA',
  NLD: 'NLD',
  POL: 'POL',
  POR: 'PRT',
  RUS: 'RUS',
  SWE: 'SWE',
  UKR: 'UKR',
};
