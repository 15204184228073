import {AxiosResponse} from 'axios';
import moment from 'moment-timezone';
import Endpoints, {WLEndpoints} from '../constants/endpoints';
import {DownloadTransactionsRequestParams} from '../models/blackAccountTransactions';
import {
  AccountCloseReason,
  AccountTransactionsRequestParams,
  AllowedTopUpMethodsInfo,
  CreateAccountParams,
  GetAccountTransactionsResponse,
  IAccount,
  IAccountCardsRequest,
  IAccountHistoryItems,
  IAccountHistoryRequestParams,
  IAccountStatement,
  IAdjustAccountBalanceReqParams,
  ICardList,
  IChangeAccountExternalIdRequestParams,
  IChangeAccountNameRequestParams,
  IUpdateAccountCreditParams,
  IUpdateAccountTopUpDetailsParams,
  LimitUsageInfo,
  TransactionFileInfo,
  UpdateAccountLimitsParams,
  UUID4,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const getAccount = async (accountId: UUID4): Promise<IAccount> => {
  const response: AxiosResponse<{
    account: IAccount;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(Endpoints.getAccount, {account_id: accountId}));
  return response.data.account;
};

export const getSystemAccount = async (): Promise<IAccount | null> => {
  const response: AxiosResponse<{
    accounts: IAccount[];
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(
        Endpoints.getAccounts,
        {},
        {from_record: 0, records_count: 1, is_main: true}
      )
    );
  return response.data.accounts ? response.data.accounts[0] : null;
};

export const getAllowedTopUpMethods = async (): Promise<AllowedTopUpMethodsInfo> => {
  const response: AxiosResponse<AllowedTopUpMethodsInfo> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({endpoint: Endpoints.getAllowedTopUpMethods});
  return response.data;
};

export const getCardTopUpFees = async (
  accountId: UUID4,
  amount: number
): Promise<{total: number; fee: number}> => {
  const response: AxiosResponse<{
    fees: {fee_type: string; amount: number}[];
    total_amount: number;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({
      endpoint: Endpoints.getCardTopUpFees,
      queryParams: {account_id: accountId, amount},
    });
  return {
    fee: response.data.fees.reduce((prev, fee) => {
      return prev + fee.amount;
    }, 0),
    total: response.data.total_amount,
  };
};

export const createAccount = async (
  account: CreateAccountParams
): Promise<IAccount> => {
  const response: AxiosResponse<{
    account: IAccount;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .post(getApiPathParamsMaker(Endpoints.createAccount), account);
  return response.data.account;
};

export const getAccountTransactionsFileRequest = async ({
  format,
  accountId,
  signal,
  fromDate,
  toDate,
  languageCode,
  merchantTypes,
  statuses,
  includeTransactions,
  includeAuthorizations,
  includeAccountAdjustments,
  includeFees,
  excludeDeclinedAuthorizations,
  excludePendingAuthorizations,
  excludePendingFees,
  excludeDeclinedFees,
  excludeReversedAuthorizations,
  excludeClearedAuthorizations,
}: DownloadTransactionsRequestParams): Promise<TransactionFileInfo> => {
  const queryParams: Record<
    string,
    string | number | string[] | number[] | boolean
  > = {
    from_date: fromDate.clone().startOf('day').utc().format(),
    to_date: toDate.clone().endOf('day').utc().format(),
    language_code: languageCode || 'ENG',
    statement_file_type: format,
    ...(statuses && statuses.length > 0 ? {statuses} : {}),
    ...(merchantTypes && merchantTypes.length > 0
      ? {merchant_category_types: merchantTypes}
      : {}),
    ...(typeof includeAuthorizations !== 'undefined'
      ? {include_authorizations: includeAuthorizations}
      : {}),
    ...(typeof includeFees !== 'undefined' ? {include_fees: includeFees} : {}),
    ...(typeof includeTransactions !== 'undefined'
      ? {include_transactions: includeTransactions}
      : {}),
    ...(typeof includeAccountAdjustments !== 'undefined'
      ? {include_account_adjustments: includeAccountAdjustments}
      : {}),
    ...(typeof excludeDeclinedAuthorizations !== 'undefined'
      ? {exclude_declined_authorizations: excludeDeclinedAuthorizations}
      : {}),
    ...(typeof excludePendingAuthorizations !== 'undefined'
      ? {exclude_pending_authorizations: excludePendingAuthorizations}
      : {}),
    ...(typeof excludePendingFees !== 'undefined'
      ? {exclude_pending_fees: excludePendingFees}
      : {}),
    ...(typeof excludeDeclinedFees !== 'undefined'
      ? {exclude_declined_fees: excludeDeclinedFees}
      : {}),
    ...(typeof excludeReversedAuthorizations !== 'undefined'
      ? {exclude_reversed_authorizations: excludeReversedAuthorizations}
      : {}),
    ...(typeof excludeClearedAuthorizations !== 'undefined'
      ? {exclude_cleared_authorizations: excludeClearedAuthorizations}
      : {}),
  };

  const response: AxiosResponse<{
    file: TransactionFileInfo;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(
        Endpoints.getAccountStatementFile,
        {account_id: accountId},
        queryParams,
        true
      ),
      {
        timeout: 300000,
        signal,
        noCache: true,
      }
    );

  return {...response.data.file, body: atob(response.data.file.body)};
};

export const getAccountTransactionsRequest = async ({
  currentPage,
  fromRecord,
  accountId,
  pageSize,
  fromDate,
  toDate,
  orderField,
  orderDirection,
  statuses,
  merchantTypes,
  includeTransactions,
  includeAuthorizations,
  includeAccountAdjustments,
  includeFees,
  excludeDeclinedAuthorizations,
  excludeReversedAuthorizations,
  excludeClearedAuthorizations,
  adjustmentType,
  hasPaymentDocumentFiles,
  hasPaymentNotes,
}: AccountTransactionsRequestParams): Promise<GetAccountTransactionsResponse> => {
  let queryParams: Record<
    string,
    string | number | string[] | number[] | boolean
  > = {
    from_record:
      typeof fromRecord !== 'undefined'
        ? fromRecord
        : ((currentPage || 1) - 1) * pageSize,
    records_count: pageSize,
    from_date: fromDate.clone().startOf('day').utc().format(),
    to_date: toDate.clone().endOf('day').utc().format(),
    ...(typeof orderField !== 'undefined' ? {order_field: orderField} : {}),
    ...(typeof orderDirection !== 'undefined'
      ? {order_direction: orderDirection}
      : {}),
    ...(statuses && statuses.length > 0 ? {statuses} : {}),
    ...(merchantTypes && merchantTypes.length > 0
      ? {merchant_category_types: merchantTypes}
      : {}),
    ...(typeof adjustmentType !== 'undefined'
      ? {adjustment_types: adjustmentType}
      : {}),
    ...(typeof includeTransactions !== 'undefined'
      ? {include_transactions: includeTransactions}
      : {}),
    ...(typeof includeAuthorizations !== 'undefined'
      ? {include_authorizations: includeAuthorizations}
      : {}),
    ...(typeof includeAccountAdjustments !== 'undefined'
      ? {include_account_adjustments: includeAccountAdjustments}
      : {}),
    ...(typeof includeFees !== 'undefined' ? {include_fees: includeFees} : {}),
    ...(typeof excludeDeclinedAuthorizations !== 'undefined'
      ? {exclude_declined_authorizations: excludeDeclinedAuthorizations}
      : {}),
    ...(typeof excludeReversedAuthorizations !== 'undefined'
      ? {exclude_reversed_authorizations: excludeReversedAuthorizations}
      : {}),
    ...(typeof excludeClearedAuthorizations !== 'undefined'
      ? {exclude_cleared_authorizations: excludeClearedAuthorizations}
      : {}),
    ...(typeof hasPaymentDocumentFiles !== 'undefined'
      ? {has_payment_document_files: hasPaymentDocumentFiles}
      : {}),
    ...(typeof hasPaymentNotes !== 'undefined'
      ? {has_payment_notes: hasPaymentNotes}
      : {}),
  };

  if (
    !includeTransactions &&
    !includeAuthorizations &&
    !includeAccountAdjustments &&
    !includeFees
  ) {
    queryParams = {
      ...queryParams,
      include_transactions: true,
      include_authorizations: true,
      include_account_adjustments: true,
      include_fees: true,
    };
  }

  const response: AxiosResponse<{
    records: IAccountStatement[];
    total_records_number: number;
    pending_amount?: number;
    opening_balance?: number;
    debit_turnover?: number;
    credit_turnover?: number;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(
        Endpoints.getAccountStatement,
        {account_id: accountId},
        queryParams,
        true
      )
    );

  return {
    items: response.data.records || [],
    totalItems: response.data.total_records_number || 0,
    pendingAmount: response.data.pending_amount,
    openingBalance: response.data.opening_balance,
    debitTurnover: response.data.debit_turnover,
    creditTurnover: response.data.credit_turnover,
    hasPaymentDocumentFiles,
  };
};

export const getTopUpLink = async (
  accountId: UUID4,
  amount: number,
  date?: moment.Moment
): Promise<string> => {
  const response: AxiosResponse<{
    link: string;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .post(
      getApiPathParamsMaker(Endpoints.getTopUpLink, {account_id: accountId}),
      {
        amount,
        date: date ? date.format('YYYY-MM-DD') : undefined,
      }
    );
  return response.data.link;
};

export const getCardTopUpLink = async (
  cardTopUpPaymentId: UUID4
): Promise<string> => {
  const response: AxiosResponse<{
    link: string;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(Endpoints.getCardTopUpLink, {
        account_adjustment_id: cardTopUpPaymentId,
      })
    );
  return response.data.link;
};

export const getTopUpPreview = async (
  accountId: UUID4,
  amount: number,
  date?: moment.Moment
): Promise<string> => {
  const response: AxiosResponse<{
    file: {
      mime_type: string;
      body: string;
    };
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({
      endpoint: Endpoints.topUpPreview,
      params: {account_id: accountId},
      queryParams: {amount, date: date ? date.format('YYYY-MM-DD') : undefined},
    });
  return decodeURIComponent(escape(atob(response.data.file.body)));
};

export const getCardTopUpPreview = async (
  cardTopUpPaymentId: UUID4
): Promise<string> => {
  const response: AxiosResponse<{
    file: {
      mime_type: string;
      body: string;
    };
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({
      endpoint: Endpoints.getCardTopUpPreview,
      params: {account_adjustment_id: cardTopUpPaymentId},
    });
  return decodeURIComponent(escape(atob(response.data.file.body)));
};

export const getAccountLimitsUsage = async (
  id: UUID4
): Promise<LimitUsageInfo> => {
  // return {
  //   purchase: {
  //     daily: 1,
  //     monthly: 2,
  //     weekly: 3,
  //   },
  //   withdrawal: {
  //     daily: 11,
  //     monthly: 12,
  //     weekly: 13,
  //   },
  //   contactless_purchase: {
  //     daily: 101,
  //     monthly: 102,
  //     weekly: 103,
  //   },
  //   internet_purchase: {
  //     daily: 201,
  //     monthly: 202,
  //     weekly: 203,
  //   },
  //   overall_purchase: {
  //     daily: 301,
  //     monthly: 302,
  //     weekly: 303,
  //   },
  // };
  const response: AxiosResponse<LimitUsageInfo> = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(Endpoints.getAccountLimitsUsage, {account_id: id})
    );
  return response.data;
};

export const getAccountHistory = async ({
  accountId,
  currentPage,
  pageSize,
  fromDate,
  toDate,
  orderField,
  orderDirection,
  isNotSystem,
}: IAccountHistoryRequestParams): Promise<IAccountHistoryItems> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(Endpoints.getAccountHistory, {
        account_id: accountId,
      }),
      {
        params: {
          from_record: (currentPage - 1) * pageSize,
          records_count: pageSize,
          ...(typeof fromDate !== 'undefined'
            ? {from_date: fromDate.clone().startOf('day').utc().format()}
            : {}),
          ...(typeof toDate !== 'undefined'
            ? {to_date: toDate.clone().startOf('day').utc().format()}
            : {}),
          ...(typeof orderField !== 'undefined'
            ? {order_field: orderField}
            : {}),
          ...(typeof orderDirection !== 'undefined'
            ? {order_direction: orderDirection}
            : {}),
          ...(typeof isNotSystem !== 'undefined'
            ? {is_not_system: isNotSystem}
            : {}),
        },
      }
    );

  return {
    items: response.data.history || [],
    totalItems: response.data.total_records_number || 0,
  };
};

export const updateAccountName = async (
  params: IChangeAccountNameRequestParams
): Promise<IAccount> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(
      getApiPathParamsMaker(Endpoints.updateAccountName, {
        account_id: params.accountId,
      }),
      {
        name: params.name,
      }
    );
  if (response.status === 200) {
    return response.data.account;
  }
  throw new Error('error');
};

export const updateAccountExternalId = async (
  params: IChangeAccountExternalIdRequestParams
): Promise<IAccount> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(
      getApiPathParamsMaker(WLEndpoints.updateAccountExternalID, {
        account_id: params.accountId,
      }),
      {
        external_id: params.externalId,
      }
    );

  if (response.status === 200) {
    return response.data.account;
  }

  throw new Error('error');
};

export const closeAccount = async ({
  accountId,
}: {
  accountId: UUID4;
  closeReason: AccountCloseReason;
}): Promise<IAccount> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .delete(
      getApiPathParamsMaker(Endpoints.closeAccount, {account_id: accountId})
    );

  return response.data.account;
};

export const reopenAccount = async (id: UUID4): Promise<IAccount> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .patch(getApiPathParamsMaker(WLEndpoints.reopenAccount, {account_id: id}));

  return response.data.account;
};

export const updateAccountLimits = async (
  id: UUID4,
  params: UpdateAccountLimitsParams
): Promise<IAccount> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(
      getApiPathParamsMaker(Endpoints.updateAccountLimits, {
        account_id: id,
      }),
      {
        limits: params,
      }
    );
  return response.data.account;
};

export const updateAccountBalance = async (
  params: IAdjustAccountBalanceReqParams
): Promise<IAccount> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(
      getApiPathParamsMaker(Endpoints.adjustAccountBalance, {
        account_id: params.accountId,
      }),
      {
        amount: params.amount,
        description: params.description,
        sender_name: params.senderName,
      }
    );
  if (response.status === 200) {
    return response.data.account;
  }
  throw new Error(response.data);
};

export const getAccountCards = async (
  params: IAccountCardsRequest
): Promise<ICardList> => {
  const {
    accountId,
    currentPage,
    pageSize,
    orderField,
    orderDirection,
    cardStatuses,
  } = params;

  const queryParams: Record<
    string,
    string | number | string[] | number[] | boolean
  > = {
    from_record: currentPage ? (currentPage - 1) * pageSize : '',
    records_count: pageSize || '',
    order_field: orderField || '',
    order_direction: orderDirection || '',
  };

  if (cardStatuses && cardStatuses?.length > 0) {
    queryParams.card_statuses = cardStatuses;
  }

  const response = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(
        Endpoints.getAccountCards,
        {account_id: accountId},
        queryParams,
        true
      )
    );

  return {
    items: response.data.cards === null ? [] : response.data.cards,
    totalItems:
      response.data.cards === null ? 0 : response.data.total_records_number,
  };
};

export const updateAccountCreditLimit = async (
  params: IUpdateAccountCreditParams
): Promise<IAccount> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(
      getApiPathParamsMaker(Endpoints.updateAccountCreditLimit, {
        account_id: params.accountId,
      }),
      {
        credit_limit: params.creditLimit,
        description: params.description,
      }
    );
  return response.data.account;
};

export const updateAccountTopUpDetails = async ({
  accountId,
  params,
}: IUpdateAccountTopUpDetailsParams): Promise<IAccount> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .put(
      {
        endpoint: WLEndpoints.updateAccountTopUpDetails,
        params: {account_id: accountId},
      },
      {
        top_up_details: params,
      }
    );
  return response.data.account;
};

export default getAccount;
