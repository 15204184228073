import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import WallesterAxios from '../utils/WAxios';
import {
  IProductTransactions,
  IProductTransactionsParams,
  ProductPricingPlan,
  ProductSettings,
  ProductStatistic,
  UUID4,
} from '../types';
import {getApiPathParamsMaker} from './endpoints';
import PricingPlanType from '../types/pricingPlan';

export const getProductTransactions = async (
  params: IProductTransactionsParams
): Promise<IProductTransactions> => {
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getProductTransactions), {
      params: {
        from_record: (params.fromRecord - 1) * params.recordsCount,
        records_count: params.recordsCount,
        order_field: params.orderField,
        order_direction: params.orderDirection,
      },
    });
  return {
    items: response.data.transactions || [],
    totalItems: response.data.total_records_number,
    orderField: params.orderField,
    orderDirection: params.orderDirection,
    recordsCount: params.recordsCount,
    fromRecord: (params.fromRecord - 1) * params.recordsCount,
  };
};

export const loadStatisticsRequest = async (): Promise<ProductStatistic> => {
  const response: AxiosResponse<ProductStatistic> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getProductStatistics));

  return response.data;
};

export const getProductSettings = async (): Promise<ProductSettings> => {
  const response: AxiosResponse<{
    product_settings: ProductSettings;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(Endpoints.getProductSettings));

  return {...response.data.product_settings};
};

export const getProductPricingPlan = async (
  productId: UUID4,
  noCache?: boolean
): Promise<ProductPricingPlan> => {
  const response: AxiosResponse<ProductPricingPlan> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getProductPricingPlan), {
      noCache,
      cacheTags: productId ? {productId} : undefined,
    });

  return response.data;
};

export const setProductPricingPlan = async (data: {
  type: PricingPlanType;
  accept_pricing_plan_agreement: boolean;
}): Promise<boolean> => {
  const response: AxiosResponse<string> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.setProductPricingPlan), data);

  return response.data === 'ok';
};

export const cancelProductPricingPlan = async (): Promise<boolean> => {
  const response: AxiosResponse<string> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .patch(getApiPathParamsMaker(Endpoints.cancelProductPricingPlan));

  return response.data === 'ok';
};

export default getProductTransactions;
