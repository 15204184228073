import axios from 'axios';
import fullySupportedRE from '../fullySupportedRE';
import logger from '../logger';
import {AppConfig} from '../types';
import sessionStorageWrapper from '../utils/sessionStorageWrapper';

const log = logger.module('App/service');

const AppService = {
  loadAppConfig(): Promise<Partial<AppConfig>> {
    return axios.get('/config.json').then(({data}) => {
      if (typeof data === 'string') {
        log.info('load invalid config', {data});
        return {};
      }
      return data;
    });
  },

  isBrowserOutdated: (): boolean => {
    const key = 'WALLESTER_BROWSER_CHECKED';
    if (sessionStorageWrapper.getItem(key) === null) {
      sessionStorageWrapper.setItem(key, `${+new Date()}`);
      if (!fullySupportedRE.test(navigator.userAgent)) {
        return true;
      }
    }
    return false;
  },
};

export default AppService;
