enum SYSTEM_NOTIFICATION_DIALOG_TYPES {
  FROZEN_CLIENT = 'frozen_client',
  WELCOME_SCREEN = 'welcome_screen',
  SALARY_PROGRAM = 'salary_program',
  TUTORIAL = 'tutorial',
  VERIFICATION_3DS = 'verification_3ds',
  PRODUCT_DEBTOR = 'product_debtor',
}

export type SystemNotificationDialogsConfig = {
  [SYSTEM_NOTIFICATION_DIALOG_TYPES.WELCOME_SCREEN]?: {
    show: boolean;
  };
  [SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL]?: {
    show: boolean;
  };
  [SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM]?: {
    show_count: number;
    last_show: number;
    full_show: boolean;
  };
  [SYSTEM_NOTIFICATION_DIALOG_TYPES.VERIFICATION_3DS]?: {
    show: boolean;
  };
  [SYSTEM_NOTIFICATION_DIALOG_TYPES.PRODUCT_DEBTOR]?: {
    show: boolean;
  };
};

export default SYSTEM_NOTIFICATION_DIALOG_TYPES;
