import Endpoints, {WLEndpoints} from '../constants/endpoints';
import {App$} from '../models/app';
import {AllowedEndpoints} from '../types';

export const isAllowedEndpoint = (
  endpoint: Endpoints | WLEndpoints,
  allowedEndpoints?: AllowedEndpoints
): boolean => {
  const {
    allowedEndpoints: appAllowedEndpoints,
    isWhiteLabeled,
  } = App$.getState();
  const endpointsSource: AllowedEndpoints =
    allowedEndpoints || appAllowedEndpoints;

  if (
    endpointsSource === null ||
    isWhiteLabeled ||
    typeof endpointsSource[endpoint] !== 'undefined'
  ) {
    return true;
  }
  return false;
};

export default isAllowedEndpoint;
