import {AxiosResponse} from 'axios';
import Endpoints, {WLEndpoints} from '../constants/endpoints';
import {
  BlackCreateCardParams,
  Card3DSecureSettings,
  CardAuthorizationItem,
  CardAuthorizationsRequestParams,
  CardBlockType,
  CardDispatchMethodInfo,
  CardHistoryItem,
  CardHistoryRequestParams,
  CardId,
  CardStatementItem,
  CardTransactionItem,
  CardTransactionsRequestParams,
  CountryCodes3l,
  CreateCardParams,
  Employee,
  GetBlackCardTransactionsResponse,
  GetCardAuthorizationsResponse,
  GetCardHistoryResponse,
  GetCardResponse,
  GetCardTransactionsResponse,
  ICard,
  ICardUserCreateParams,
  IEmployeesCardCreateParams,
  IProfile,
  KeyDecryptor,
  OrderPromoCardParams,
  Send3DSOtpParams,
  TCardNotifications,
  UpdateCardLimitParams,
  UpdateCardSecurityParams,
  UpdateCardStatusRequestParams,
  UUID4,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const updateCardSecurityRequest = async (
  id: CardId,
  params: UpdateCardSecurityParams
): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .patch(
      {endpoint: WLEndpoints.updateCardSecurity, params: {card_id: id}},
      params
    );
  return response.data.card;
};

export const blackUpdateCardSecurityRequest = async (
  id: CardId,
  params: UpdateCardSecurityParams
): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .patch(
      getApiPathParamsMaker(Endpoints.updateCardSecurity, {card_id: id}),
      params
    );
  return response.data.card;
};

export const remindCardPinRequest = async (id: CardId): Promise<void> => {
  await WallesterAxios.getInstance()
    .getCommon()
    .put(getApiPathParamsMaker(Endpoints.remindCardPin, {card_id: id}));
};

export const remind3DSPasswordRequest = async (id: CardId): Promise<void> => {
  await WallesterAxios.getInstance()
    .getCommon()
    .put(getApiPathParamsMaker(Endpoints.remind3DSPassword, {card_id: id}));
};

export const updateCardLimitRequest = async (
  id: CardId,
  params: UpdateCardLimitParams
): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.updateCardLimit, {card_id: id}), {
      limits: params,
    });
  return response.data.card;
};

export const getCardSecretNumberRequest = async (
  id: CardId,
  keyDecryptor: KeyDecryptor
): Promise<string> => {
  const {publicKey, decryptor} = keyDecryptor;
  const params = {public_key: publicKey};
  const response: AxiosResponse<{
    encrypted_card_number: string;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .post(
      getApiPathParamsMaker(Endpoints.getCardNumber, {card_id: id}),
      params
    );
  return decryptor(response.data.encrypted_card_number);
};

export const getCardSecretCodeRequest = async (
  id: CardId,
  keyDecryptor: KeyDecryptor
): Promise<string> => {
  const {publicKey, decryptor} = keyDecryptor;
  const params = {public_key: publicKey};
  const response: AxiosResponse<{
    encrypted_cvv2: string;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .post(
      getApiPathParamsMaker(Endpoints.getCardCvvCode, {card_id: id}),
      params
    );
  return decryptor(response.data.encrypted_cvv2);
};

export const getCardSecretPinRequest = async (
  id: CardId,
  keyDecryptor: KeyDecryptor
): Promise<string> => {
  const {publicKey, decryptor} = keyDecryptor;
  const params = {public_key: publicKey};
  const response: AxiosResponse<{
    encrypted_pin: string;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(
      getApiPathParamsMaker(Endpoints.getCardPinCode, {card_id: id}),
      params
    );
  return decryptor(response.data.encrypted_pin);
};

export const getCardSecret3dsPasswordRequest = async (
  id: CardId,
  keyDecryptor: KeyDecryptor
): Promise<string> => {
  const {publicKey, decryptor} = keyDecryptor;
  const params = {public_key: publicKey};
  const response: AxiosResponse<{
    encrypted_3ds_password: string;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(
      getApiPathParamsMaker(Endpoints.getCard3dsPassword, {card_id: id}),
      params
    );
  return decryptor(response.data.encrypted_3ds_password);
};

export const getCard = async (
  id: CardId,
  noCache?: boolean
): Promise<GetCardResponse> => {
  const response = (await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(Endpoints.getCard, {card_id: id}), {
      noCache,
    })) as AxiosResponse<{card: ICard}>;
  return {
    card: response.data.card,
    id,
  };
};

export const updateCardNameRequest = async (
  id: CardId,
  cardName: string
): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.updateCardName, {card_id: id}), {
      name: cardName,
    });
  return response.data.card;
};

export const updateCardExternalIdRequest = async ({
  id,
  externalId,
}: {
  id: CardId;
  externalId: string;
}): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .patch(
      getApiPathParamsMaker(WLEndpoints.updateCardExternalId, {card_id: id}),
      {
        external_id: externalId,
      }
    );

  return response.data.card;
};

export const linkCardToAccount = async (
  id: CardId,
  accountId: UUID4
): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .patch(
      getApiPathParamsMaker(Endpoints.linkCardToAccount, {
        card_id: id,
        account_id: accountId,
      })
    );
  return response.data.card;
};

export const blockCardRequest = async (
  id: CardId,
  blockType: CardBlockType
): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.blockCard, {card_id: id}), {
      block_type: blockType,
    });
  return response.data.card;
};

export const unblockCardRequest = async (id: CardId): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.unblockCard, {card_id: id}));
  return response.data.card;
};

export const closeCardRequest = async (id: CardId): Promise<ICard> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.closeCard, {card_id: id}), {
      close_reason: 'ClosedByClient',
    });
  return response.data.card;
};

export const sendTest3DSRequest = async (
  id: UUID4,
  params: Send3DSOtpParams
): Promise<boolean> => {
  const response: AxiosResponse<'ok'> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .post(getApiPathParamsMaker(WLEndpoints.send3DSOtp, {card_id: id}), params);
  return response.data === 'ok';
};

export const updateCard3dSecureSettings = async (
  id: CardId,
  params: Card3DSecureSettings
): Promise<ICard> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(
      getApiPathParamsMaker(Endpoints.updateCard3dSecureSettings, {
        card_id: id,
      }),
      {
        ...params,
      }
    );
  return response.data.card;
};

export const createCardUser = async (
  card: ICard,
  user: ICardUserCreateParams
): Promise<IProfile> => {
  const response: AxiosResponse<{
    user: IProfile;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .post(getApiPathParamsMaker(Endpoints.addCardUser, {card_id: card.id}), {
      ...user,
    });
  return response.data.user;
};

export const changeCardPinRequest = async (
  id: CardId,
  encryptedPin: string
): Promise<ICard> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.changeCardPin, {card_id: id}), {
      encrypted_pin: encryptedPin,
    });
  return response.data.card;
};

export const replaceCardRequest = async (id: CardId): Promise<ICard> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.replaceCard, {card_id: id}));
  return response.data.card;
};

export const activateCardRequest = async (card: ICard): Promise<ICard> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.activateCard, {card_id: card.id}));
  return response.data.card;
};

export const renewCardRequest = async (card: ICard): Promise<ICard> => {
  const response = await WallesterAxios.getInstance().getWhiteLabelAxios().post(
    {endpoint: WLEndpoints.createCard},
    {
      predecessor_card_id: card.id,
      type: card.type,
      account_id: card.account_id,
    }
  );
  return response.data.card;
};

export const blackRenewCardRequest = async (card: ICard): Promise<ICard> => {
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.createCard), {
      predecessor_card_id: card.id,
      type: card.type,
      account_id: card.account_id,
    });
  return response.data.card;
};

export const isCanRenewRequest = async (cardId: CardId): Promise<boolean> => {
  const response: AxiosResponse<{
    IsCardRenewalAllowed: boolean;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(Endpoints.isCanRenewCard, {card_id: cardId}));
  return response.data.IsCardRenewalAllowed;
};

export const getCardDeliveryOptions = async (
  countryCode: CountryCodes3l
): Promise<CardDispatchMethodInfo[]> => {
  const response: AxiosResponse<{
    dispatch_methods: CardDispatchMethodInfo[];
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(
        Endpoints.getCardDeliveryOptions,
        {},
        {country_code: countryCode}
      )
    );
  return response.data.dispatch_methods || [];
};

export const getCardHistoryRequest = async ({
  currentPage,
  cardId,
  pageSize,
  fromDate,
  toDate,
}: CardHistoryRequestParams): Promise<GetCardHistoryResponse> => {
  const response: AxiosResponse<{
    history: CardHistoryItem[];
    total_records_number: number;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(
        Endpoints.getCardHistory,
        {card_id: cardId},
        {
          from_record: (currentPage - 1) * pageSize,
          records_count: pageSize,
          from_date: fromDate.clone().startOf('day').utc().format(),
          to_date: toDate.clone().endOf('day').utc().format(),
        }
      )
    );

  return {
    items: response.data.history || [],
    totalItems: response.data.total_records_number,
  };
};

export const getCardTransactionsRequest = async ({
  currentPage,
  fromRecord,
  cardId,
  pageSize,
  fromDate,
  toDate,
}: CardTransactionsRequestParams): Promise<GetCardTransactionsResponse> => {
  const response: AxiosResponse<{
    transactions: CardTransactionItem[];
    total_records_number: number;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(
        Endpoints.getCardTransactions,
        {card_id: cardId},
        {
          from_record:
            typeof fromRecord !== 'undefined'
              ? fromRecord
              : ((currentPage || 1) - 1) * pageSize,
          records_count: pageSize,
          from_date: fromDate.clone().startOf('day').utc().format(),
          to_date: toDate.clone().endOf('day').utc().format(),
        }
      )
    );

  return {
    items: response.data.transactions || [],
    totalItems: response.data.total_records_number,
  };
};

export const getBlackCardTransactionsRequest = async ({
  currentPage,
  fromRecord,
  cardId,
  pageSize,
  fromDate,
  toDate,
  orderField,
  orderDirection,
  includeAuthorizations,
  includeTransactions,
  includeFees,
  excludeDeclinedAuthorizations,
  excludeReversedAuthorizations,
  excludeClearedAuthorizations,
  excludePendingAuthorizations,
  excludeStatusAuthorizations,
  excludePendingFees,
  excludeClearedFees,
  excludeDeclinedFees,
  mergeFees,
  searchKeyword,
}: CardTransactionsRequestParams): Promise<GetBlackCardTransactionsResponse> => {
  let queryParams: Record<
    string,
    string | number | string[] | number[] | boolean
  > = {
    from_record:
      typeof fromRecord !== 'undefined'
        ? fromRecord
        : ((currentPage || 1) - 1) * pageSize,
    records_count: pageSize,
    from_date: fromDate.clone().startOf('day').utc().format(),
    to_date: toDate.clone().endOf('day').utc().format(),
    ...(typeof orderField !== 'undefined' ? {order_field: orderField} : {}),
    ...(typeof orderDirection !== 'undefined'
      ? {order_direction: orderDirection}
      : {}),
    ...(typeof includeAuthorizations !== 'undefined'
      ? {include_authorizations: includeAuthorizations}
      : {}),
    ...(typeof includeTransactions !== 'undefined'
      ? {include_transactions: includeTransactions}
      : {}),
    ...(typeof includeFees !== 'undefined' ? {include_fees: includeFees} : {}),
    ...(typeof excludeDeclinedAuthorizations !== 'undefined'
      ? {exclude_declined_authorizations: excludeDeclinedAuthorizations}
      : {}),
    ...(typeof excludeReversedAuthorizations !== 'undefined'
      ? {exclude_reversed_authorizations: excludeReversedAuthorizations}
      : {}),
    ...(typeof excludeClearedAuthorizations !== 'undefined'
      ? {exclude_cleared_authorizations: excludeClearedAuthorizations}
      : {}),
    ...(typeof excludePendingAuthorizations !== 'undefined'
      ? {exclude_pending_authorizations: excludePendingAuthorizations}
      : {}),
    ...(typeof excludeStatusAuthorizations !== 'undefined'
      ? {exclude_status_authorizations: excludeStatusAuthorizations}
      : {}),
    ...(typeof excludePendingFees !== 'undefined'
      ? {exclude_pending_fees: excludePendingFees}
      : {}),
    ...(typeof excludeClearedFees !== 'undefined'
      ? {exclude_cleared_fees: excludeClearedFees}
      : {}),
    ...(typeof excludeDeclinedFees !== 'undefined'
      ? {exclude_declined_fees: excludeDeclinedFees}
      : {}),
    ...(typeof mergeFees !== 'undefined' ? {merge_fees: mergeFees} : {}),
    ...(typeof searchKeyword !== 'undefined'
      ? {search_keyword: searchKeyword}
      : {}),
  };

  if (!includeTransactions && !includeAuthorizations && !includeFees) {
    queryParams = {
      ...queryParams,
      include_transactions: true,
      include_authorizations: true,
      include_fees: true,
    };
  }

  const response: AxiosResponse<{
    records: CardStatementItem[];
    total_records_number: number;
    pending_amount?: number;
    debit_turnover?: number;
    credit_turnover?: number;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(
        Endpoints.getCardStatements,
        {card_id: cardId},
        queryParams,
        true
      )
    );

  return {
    items: response.data.records || [],
    totalItems: response.data.total_records_number || 0,
    pendingAmount: response.data.pending_amount,
    debitTurnover: response.data.debit_turnover,
    creditTurnover: response.data.credit_turnover,
  };
};

export const getCardAuthorizationsRequest = async ({
  currentPage,
  fromRecord,
  cardId,
  pageSize,
  fromDate,
  toDate,
}: CardAuthorizationsRequestParams): Promise<GetCardAuthorizationsResponse> => {
  const response: AxiosResponse<{
    authorizations: CardAuthorizationItem[];
    total_records_number: number;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(
        Endpoints.getCardAuthorizations,
        {card_id: cardId},
        {
          from_record:
            typeof fromRecord !== 'undefined'
              ? fromRecord
              : ((currentPage || 1) - 1) * pageSize,
          records_count: pageSize,
          from_date: fromDate.clone().startOf('day').utc().format(),
          to_date: toDate.clone().endOf('day').utc().format(),
        }
      )
    );

  return {
    authorizations: response.data.authorizations || [],
    totalItems: response.data.total_records_number,
  };
};

export const createCard = async (card: CreateCardParams): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .post({endpoint: WLEndpoints.createCard}, card);
  return response.data.card;
};

export const blackCreateCard = async (
  card: BlackCreateCardParams
): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.createCard), card);

  return response.data.card;
};

export const blackCreateEmployeesCard = async (
  employee: IEmployeesCardCreateParams
): Promise<Employee> => {
  const response: AxiosResponse<{
    employee: Employee;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.createEmployeesCard), employee);

  return response.data.employee;
};

export const blackUpdateEmployeesCard = async (
  id: UUID4,
  employee: IEmployeesCardCreateParams
): Promise<Employee> => {
  const response: AxiosResponse<{
    employee: Employee;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .patch(
      {params: {employee_id: id}, endpoint: Endpoints.updateEmployeesCard},
      employee
    );

  return response.data.employee;
};

export const orderPromoCards = async (
  params: OrderPromoCardParams
): Promise<ICard[]> => {
  const response: AxiosResponse<{
    cards: ICard[];
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .post(getApiPathParamsMaker(Endpoints.orderPromoCards), params);
  return response.data.cards;
};

export const updateCardStatusRequest = async (
  cardId: CardId,
  params: UpdateCardStatusRequestParams
): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .patch(
      getApiPathParamsMaker(WLEndpoints.updateCardStatus, {card_id: cardId}),
      params
    );
  return response.data.card;
};

export const enrolCardRequest = async (id: CardId): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .patch(getApiPathParamsMaker(WLEndpoints.enrolCard, {card_id: id}));
  return response.data.card;
};

export const unenrolCardRequest = async (id: CardId): Promise<ICard> => {
  const response: AxiosResponse<{
    card: ICard;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .delete(getApiPathParamsMaker(WLEndpoints.unenrolCard, {card_id: id}));
  return response.data.card;
};

export const getCardNotifications = async (
  id: CardId
): Promise<TCardNotifications> => {
  const response: AxiosResponse<{
    card_notification_settings: TCardNotifications;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get({
      endpoint: Endpoints.getCardNotifications,
      params: {
        card_id: id,
      },
    });

  await new Promise((r) => setTimeout(r, 5000));

  return response.data.card_notification_settings;
};

export const updateCardNotifications = async ({
  id,
  notifications,
}: {
  id: UUID4;
  notifications: TCardNotifications;
}): Promise<TCardNotifications> => {
  const response: AxiosResponse<{
    card_notification_settings: TCardNotifications;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .patch(
      {
        endpoint: Endpoints.changeCardNotifications,
        params: {
          card_id: id,
        },
      },
      notifications
    );

  return response.data.card_notification_settings;
};

export default getCard;
