import {UUID4} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const retryMassPaymentsPayment: DemoApiHandler = ({
  config,
  instance,
  url,
  data,
}) => {
  const paymentsFiles = instance.getData().massPaymentsFiles[
    instance.getData().productId
  ];

  const payments = instance
    .getData()
    .massPayments[instance.getData().productId].filter(
      (a) => a.file_id === url?.params?.payment_file_id
    );

  const batchPayments = payments.filter((a) =>
    (data?.record_ids as UUID4).includes(a.id)
  );

  const currentItem = paymentsFiles.find(
    (item) => item.id === url?.params?.payment_file_id
  );

  if (currentItem) {
    const index = paymentsFiles.indexOf(currentItem);

    if (index !== -1) {
      batchPayments.forEach((__, i) => {
        batchPayments[i].status = 'Pending';
      });

      currentItem.status = 'Pending';

      setTimeout(() => {
        batchPayments.forEach((a, i) => {
          batchPayments[i].status = 'Rejected';
          batchPayments[i].reject_reason = 'Other';
        });

        currentItem.status = 'Review';
      }, 5000);

      instance.saveImpl(['massPayments', 'massPaymentsFiles']);
      return Demo.makeDataResponse<string>(config, 'ok');
    }
  }

  return Demo.makeUnprocessableEntityResponse(config, 90559);
};

export default retryMassPaymentsPayment;
