import moment from 'moment-timezone';
import {
  OrderBy,
  PagerProps,
  RequestWithSortsOrders,
  ToListApiRequestCustomFormatterData,
  ToListApiRequestData,
} from '../types';
import ObjectKeys from './object';

export const toListRequest = <T extends OrderBy & PagerProps>(
  params: T
): RequestWithSortsOrders<T> => {
  return {
    ...params,
    orderField:
      params.orderField !== undefined ? [params.orderField] : undefined,
    orderDirection:
      params.orderDirection !== undefined ? [params.orderDirection] : undefined,
  };
};

export const toListApiRequest = <
  T extends Record<string, unknown> & OrderBy & PagerProps
>(
  {
    currentPage,
    pageSize,
    orderField,
    orderDirection,
    fromDate,
    toDate,
    ...restParams
  }: RequestWithSortsOrders<T>,
  customFormatter?: (
    customFormatterParams: T
  ) => ToListApiRequestCustomFormatterData,
  specialSortingFormat?: boolean
): ToListApiRequestData => {
  const orderPart: Record<
    string,
    string | number | string[] | number[] | boolean
  > = {
    ...(typeof orderField !== 'undefined' && {
      order_field: orderField.reduce<string[]>((ret, field) => {
        return [
          ...ret,
          ...(Array.isArray(field) ? field : [field]).filter((f) => f !== ''),
        ];
      }, []),
    }),
    ...(typeof orderDirection !== 'undefined' && {
      order_direction: orderDirection,
    }),
  };
  if (specialSortingFormat === true && typeof orderField !== 'undefined') {
    const fields: string[] = [];
    orderField.forEach((field, index) => {
      [...(Array.isArray(field) ? field : [field])].forEach((_field) => {
        const direction = (orderDirection || [])[index];
        if (_field !== '') {
          fields.push(`${_field}${direction ? `.${direction}` : ''}`);
        }
      });
    });
    orderPart.order_field = fields;
    orderPart.order_direction = [];
  }
  const customData = customFormatter ? customFormatter(restParams as T) : {};

  const ret: Record<string, string | number | string[] | number[] | boolean> = {
    from_record: `${(currentPage - 1) * pageSize}`,
    records_count: `${pageSize}`,
    ...orderPart,
    ...ObjectKeys(customData).reduce<ToListApiRequestData>(
      (prev, currentKey) => {
        const currentValue = customData[currentKey];
        if (currentValue !== null) {
          return {...prev, [currentKey]: currentValue};
        }
        return prev;
      },
      {}
    ),
  };

  if (fromDate !== undefined) {
    if (moment.isMoment(fromDate)) {
      ret.from_date = moment(fromDate as string)
        .startOf('days')
        .utc()
        .format();
    }
  }

  if (toDate !== undefined) {
    if (moment.isMoment(toDate)) {
      ret.to_date = moment(toDate as string)
        .endOf('days')
        .utc()
        .format();
    }
  }
  ObjectKeys(restParams).forEach((restParamsKey) => {
    if (
      restParams[restParamsKey] !== undefined &&
      ret[restParamsKey as string] === undefined &&
      customData[restParamsKey as string] !== null
    ) {
      ret[restParamsKey as string] = restParams[restParamsKey] as string;
    }
  });
  return ret;
};

export default toListRequest;
