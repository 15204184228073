import {IGetApiKeysListResponse} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const getApiKeysList: DemoApiHandler = ({config, instance, url}) => {
  const items = instance.getData().apiKeys[instance.getData().productId];
  const total = items.length;

  const sorted = Demo.applySorterAndPager(
    items,
    Demo.toListSortOrderParams(url.queryParams)
  );

  return Demo.makeDataResponse<IGetApiKeysListResponse>(config, {
    api_keys: sorted,
    total_records_number: total,
  });
};

export default getApiKeysList;
