import Endpoints from '../constants/endpoints';
import WallesterAxios from '../utils/WAxios';
import {IAuthorizationList, IProductTransactionsParams} from '../types';
import {getApiPathParamsMaker} from './endpoints';

export const getAccountingAuthorizations = async (
  params: IProductTransactionsParams
): Promise<IAuthorizationList> => {
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getProductAuthorizations), {
      params: {
        from_record: (params.fromRecord - 1) * params.recordsCount,
        records_count: params.recordsCount,
        order_field: params.orderField,
        order_direction: params.orderDirection,
      },
    });
  return {
    items: response.data.authorizations,
    totalItems: response.data.total_records_number,
    orderField: params.orderField,
    orderDirection: params.orderDirection,
    fromRecord: (params.fromRecord - 1) * params.recordsCount,
    recordsCount: params.recordsCount,
  };
};

export default getAccountingAuthorizations;
