import {combine, createEvent, createStore, StoreWritable} from 'effector';
import {mediaBreakpoints} from '../../constants/styles';
import {ScreenSize} from '../../types/styles';
import ObjectKeys from '../../utils/object';
import clearAppStores from '../clearStore';

export function mediaMatcher(query: string): StoreWritable<boolean> {
  const queryChange = createEvent<MediaQueryListEvent>('query change');

  if (window.matchMedia) {
    const mediaQueryList = window.matchMedia(query);

    if (typeof mediaQueryList.addEventListener === 'function') {
      mediaQueryList.addEventListener('change', queryChange);
    } else {
      mediaQueryList.addListener(queryChange);
    }

    return createStore(mediaQueryList.matches).on(
      queryChange,
      (state, e) => e.matches
    );
  }

  return createStore(false).on(queryChange, (state, e) => e.matches);
}

const medias: {
  [key in ScreenSize]: StoreWritable<boolean>;
} = {
  xxs: mediaMatcher(mediaBreakpoints.xxs),
  xs: mediaMatcher(mediaBreakpoints.xs),
  sm: mediaMatcher(mediaBreakpoints.sm),
  md: mediaMatcher(mediaBreakpoints.md),
  lg: mediaMatcher(mediaBreakpoints.lg),
  xl: mediaMatcher(mediaBreakpoints.xl),
  xxl: mediaMatcher(mediaBreakpoints.xxl),
  xxxl: mediaMatcher(mediaBreakpoints.xxxl),
  lg_web: mediaMatcher(mediaBreakpoints.lg_web),
  md_web: mediaMatcher(mediaBreakpoints.md_web),
  sm_web: mediaMatcher(mediaBreakpoints.sm_web),
};

export const mediaBreakpoints$ = combine(medias);

ObjectKeys(medias).forEach((key) => {
  medias[key].reset(clearAppStores);
});

export const getMedia = (): {
  [key in ScreenSize]: boolean;
} => {
  return mediaBreakpoints$.getState();
};
