import {v4} from 'uuid';
import {ICreateBlackUserParams, UUID4} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const addUser: DemoApiHandler = ({config, instance, data}) => {
  const users = instance.getData().productUsers;

  const params = data as ICreateBlackUserParams;

  if (params) {
    const role =
      instance.getData().roles.find((r) => r.id === params.role_id) ||
      instance.getData().roles[0];
    const newUser = {
      id: v4(),
      last_name: params.create_user_info.last_name,
      first_name: params.create_user_info.first_name,
      mobile: params.create_user_info.mobile,
      email: params.create_user_info.email,
      role,
    };
    users.push(newUser);
    instance.saveImpl(['productUsers']);

    return Demo.makeDataResponse<{user_id: UUID4}>(config, {
      user_id: newUser.id,
    });
  }

  return Demo.makeUnprocessableEntityResponse(config, 30001);
};

export default addUser;
