import Demo, {DemoApiHandler} from '../demo';
import {AllowedTopUpMethodsInfo} from '../../types';

const getAllowedTopUpMethods: DemoApiHandler = ({config}) => {
  return Demo.makeDataResponse<AllowedTopUpMethodsInfo>(config, {
    card: {allowed: false, enabled: false, is_bank_confirmed: true},
    wire_transfer: {allowed: true, enabled: true},
  });
};

export default getAllowedTopUpMethods;
