import clsx from 'clsx';
import React from 'react';
import useIntl from '../../customHooks/useIntl';
import Button from '../Button';
import {ReactComponent as IconClose} from '../../images/icons/close.svg';
import css from './HintInner.module.less';

interface Props {
  className?: string;
  title?: string;
  content?: React.ReactNode;
  okText?: string;
  cancelText?: string | false;
  onOk?: (event: React.MouseEvent<HTMLElement>) => void;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  onCancel?: (event: React.MouseEvent<HTMLElement>) => void;
  closable?: boolean;
}

const HintInner: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  title,
  content,
  okText,
  cancelText,
  onOk,
  onClose,
  onCancel,
  closable = true,
}) => {
  const intl = useIntl();
  return (
    <div className={clsx(css.hintInner, className)}>
      {!!title && (
        <>
          <div className={css.hintInner__title}>{title}</div>

          {closable && (
            <Button
              onClick={onClose}
              className={clsx(
                css.hintInner__close,
                'ant-btn_transparent ant-btn_clear'
              )}
            >
              <IconClose />
            </Button>
          )}
        </>
      )}

      {!!content && <div className={css.hintInner__content}>{content}</div>}

      <div className={css.hintInner__footer}>
        {cancelText !== false ? (
          <Button onClick={onCancel}>
            {cancelText || intl.formatMessage({id: 'App-Previous'})}
          </Button>
        ) : null}

        <Button type="primary" onClick={onOk}>
          {okText || intl.formatMessage({id: 'App-Next'})}
        </Button>
      </div>
    </div>
  );
};

export default HintInner;
