import moment from 'moment-timezone';
import Demo, {DemoApiHandler} from '../demo';
import {ICard, UUID4} from '../../types';
import {hideCardsPrivacyInfo} from './getCard';

const unblockCardsBatch: DemoApiHandler = ({config, instance, data}) => {
  const items = instance.getData().cards[instance.getData().productId];
  const cards = items.filter((a) => (data?.card_ids as UUID4[]).includes(a.id));

  cards.forEach((__, i) => {
    cards[i].status = 'Active';
    cards[i].block_type = undefined;
    cards[i].blocked_at = undefined;
    cards[i].updated_at = moment().toISOString();
  });

  return Demo.makeDataResponse<{
    cards: ICard[];
  }>(config, {
    cards: hideCardsPrivacyInfo(cards),
  });
};

export default unblockCardsBatch;
