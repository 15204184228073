import React, {useCallback, useEffect} from 'react';
import useClient from '../../../customHooks/useClient';
import useInterval from '../../../customHooks/useInterval';
import useIsFullyVerified from '../../../customHooks/useIsFullyVerified';
import useIsWhiteLabel from '../../../customHooks/useIsWhiteLabel';
import {updateClientVerificationState} from '../../../models/client';

const UnverifiedDataManager: React.FC = () => {
  const client = useClient();
  const isWhiteLabeled = useIsWhiteLabel();
  const fullyVerified = useIsFullyVerified();

  const intervalCallback = useCallback(() => {
    updateClientVerificationState(undefined);
  }, []);

  const [stopInterval, startInterval] = useInterval(intervalCallback, 60000);

  useEffect(() => {
    if (client?.id && !isWhiteLabeled && !fullyVerified) {
      startInterval();
    } else {
      stopInterval();
    }
  }, [client?.id, fullyVerified, isWhiteLabeled, startInterval, stopInterval]);

  useEffect(() => {
    return () => {
      stopInterval();
    };
  }, [stopInterval]);

  return null;
};

export default UnverifiedDataManager;
