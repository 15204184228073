import React, {useContext, useMemo} from 'react';
import useConfig from '../../customHooks/useConfig';
import TestContext from './context';

interface TestProviderProps {
  testId?: string;
}

const TestProvider: React.FC<React.PropsWithChildren<TestProviderProps>> = ({
  children,
  testId,
}) => {
  const currentValue = useContext(TestContext);
  const {isProd, isUseTestId} = useConfig();
  const value = useMemo(() => {
    if (testId !== undefined) {
      return [testId, ...currentValue];
    }
    return currentValue;
  }, [testId, currentValue]);
  if (isProd || !isUseTestId) {
    return <>{children}</>;
  }

  return <TestContext.Provider value={value}>{children}</TestContext.Provider>;
};

export default TestProvider;
