import {createEffect, createEvent, createStore} from 'effector';
import {getCurrentClient} from '../../api';
import {IClient} from '../../types';
import clearAppStores from '../clearStore';

import {getClientFx, updateClientVerificationState} from '../client';

export const CurrentClientStore = createStore<IClient | null>(null);

CurrentClientStore.reset(clearAppStores);

export const reloadCurrentClient = createEffect<boolean | void, IClient, Error>(
  {
    name: 'reloadCurrentClient',
    handler: async (noCache: boolean | void): Promise<IClient> => {
      return getCurrentClient(noCache || false);
    },
  }
);
export const setCurrentClient = createEvent<IClient>('setCurrentClient');

CurrentClientStore.on(
  [reloadCurrentClient.doneData, getClientFx.doneData, setCurrentClient],
  (Store, client) => {
    return {...client};
  }
);

CurrentClientStore.on(
  updateClientVerificationState.done,
  (State, {result: data}) => {
    if (State && State.id === data.client_id) {
      return {
        ...State,
        client_information_verification_status:
          data.client_information_verification_status ||
          State.client_information_verification_status,
        documents_verification_status:
          data.documents_verification_status ||
          State.documents_verification_status,
        identity_verification_status:
          data.identity_verification_status ||
          State.identity_verification_status,
        onboarding_verification_status:
          data.onboarding_verification_status ||
          State.onboarding_verification_status,
      };
    }
    return State;
  }
);

export const setMobileVerified = createEvent('setMobileVerified');
CurrentClientStore.on(setMobileVerified, (Store) => {
  if (Store) {
    return {
      ...Store,
      mobile_verified: true,
    };
  }
  return Store;
});

export default CurrentClientStore;
