import clsx from 'clsx';
import React, {useMemo, useState, useEffect, useCallback} from 'react';
import useIntl from '../../customHooks/useIntl';
import Button from '../Button';
import useMedias from '../../customHooks/useMedias';
import {ReactComponent as IconArrowRight} from '../../images/icons/arrow-right.svg';
import {ReactComponent as IconArrowLeft} from '../../images/icons/arrow-left.svg';
import css from './Wizard.module.less';
import gcss from '../../utils/styles';
import PointerSteps from '../PointerSteps';
import map from '../../utils/common';

interface Props {
  contentClassName?: string;
  actionsClassName?: string;
  items?: React.ReactNode[];
  action?: React.ReactNode;
  onChange?: (activeIndex: number) => void;
}

const Wizard: React.FC<React.PropsWithChildren<Props>> = ({
  contentClassName,
  actionsClassName,
  items,
  action,
  onChange,
}) => {
  const intl = useIntl();
  const media = useMedias();
  const [activeIndex, setActiveIndex] = useState(0);

  const getIndex = useCallback(
    (isNext: boolean) => {
      if (isNext) {
        return Math.min((items?.length || 0) - 1, activeIndex + 1);
      }

      return Math.max(0, activeIndex - 1);
    },
    [activeIndex, items?.length]
  );

  const processedAction = useMemo(() => {
    if (action) {
      if (React.isValidElement(action)) {
        return React.cloneElement(action, {
          className: clsx(action.props?.className, css.wizard__nextAction),
          onClick: (e: MouseEvent) => {
            if (action.props?.onClick) {
              action.props?.onClick(e);
            }

            setActiveIndex(getIndex(true));
          },
        });
      }
    }

    return (
      <Button
        className={clsx(
          'ant-btn_stable ant-btn_green-emerald',
          media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger',
          gcss('t_font-weight_700'),
          gcss('t_transform_uppercase'),
          css.wizard__nextAction
        )}
        onClick={() => {
          setActiveIndex(getIndex(true));
        }}
      >
        <span>{intl.formatMessage({id: 'App-Next'})}</span>

        <IconArrowRight
          className={clsx(
            'ant-btn-post-icon',
            media.xs
              ? 'ant-btn-post-icon_arrow-right'
              : 'ant-btn-post-icon_arrow-right-small'
          )}
        />
      </Button>
    );
  }, [action, getIndex, intl, media.xs]);

  const pointerSteps = useMemo(() => {
    return map(items?.length || 0, () => {
      return {};
    });
  }, [items?.length]);

  useEffect(() => {
    if (onChange) {
      onChange(activeIndex);
    }
  }, [activeIndex, onChange]);

  return (
    <>
      <div className={contentClassName}>{!!items && items[activeIndex]}</div>

      <div className={clsx(css.wizard__actions, actionsClassName)}>
        {activeIndex !== 0 && (
          <Button
            className={clsx(
              'ant-btn_transparent ant-btn_text-blue',
              media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger',
              gcss('t_font-weight_700'),
              gcss('t_transform_uppercase'),
              css.wizard__backAction
            )}
            onClick={() => {
              setActiveIndex(getIndex(false));
            }}
          >
            <IconArrowLeft />
          </Button>
        )}

        {activeIndex !== 0 && (
          <PointerSteps
            className={css.wizard__progress}
            steps={pointerSteps}
            active={activeIndex}
            onClick={(i) => {
              setActiveIndex(i);
            }}
          />
        )}

        {processedAction}
      </div>
    </>
  );
};

export default Wizard;
