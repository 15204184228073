import Endpoints from '../constants/endpoints';
import Features from '../constants/features';
import Permissions from '../constants/permissions';
import routes from '../constants/routes';
import {RouteInfo, StoredPermissions} from '../types';
import ObjectKeys from './object';

export const makePermissions = (permissions: number[]): Set<Permissions> => {
  return new Set<Permissions>(permissions);
};

const makeRoutes = (): Record<string, RouteInfo> => {
  const routeByPath: Record<string, RouteInfo> = {};

  ObjectKeys(routes).forEach((route) => {
    const routeInfo = routes[route];
    if (routeInfo) {
      routeByPath[routeInfo.path] = routeInfo;
    }
  });

  return routeByPath;
};

export const routesByPath: Record<string, RouteInfo> = makeRoutes();

export const getRoutePermissions = (
  path: string
): Permissions | Permissions[] => {
  return routesByPath[path] !== undefined
    ? routesByPath[path].permission || []
    : [];
};

export const getRouteDemoRestricted = (path: string): boolean => {
  return routesByPath[path] !== undefined
    ? routesByPath[path].demoRestricted === true
    : false;
};

export const getRoutePermissionsPartialMode = (path: string): boolean => {
  return routesByPath[path] !== undefined
    ? routesByPath[path].permissionsPartialMode === true
    : false;
};

export const getRouteFeatures = (path: string): Features | undefined => {
  return routesByPath[path]?.feature || undefined;
};

export const getRouteEndpoints = (path: string): Endpoints | Endpoints[] => {
  return routesByPath[path] !== undefined
    ? routesByPath[path].endpoints || []
    : [];
};

export const getRouteEndpointsPartialMode = (path: string): boolean => {
  return routesByPath[path] !== undefined
    ? routesByPath[path].endpointsPartialMode === true
    : false;
};

export const getRouteInfoByPath = (path: string): RouteInfo | undefined => {
  return routesByPath[path] !== undefined ? routesByPath[path] : undefined;
};

export const checkPermissions = ({
  permissions,
  existPermissions,
  partialMode,
}: {
  permissions: Permissions | Permissions[];
  existPermissions?: StoredPermissions | undefined;
  partialMode?: boolean;
}): boolean => {
  const p: Permissions[] = Array.isArray(permissions)
    ? permissions
    : [permissions];

  if (p.length === 0) {
    return true;
  }

  const userPermissions = {
    user: existPermissions?.user || new Set<Permissions>([]),
    profile: existPermissions?.profile || new Set<Permissions>([]),
  };

  return partialMode
    ? p.some(
        (checkedPermission) =>
          userPermissions.user.has(checkedPermission) ||
          userPermissions.profile.has(checkedPermission)
      )
    : !p.some(
        (checkedPermission) =>
          !userPermissions.user.has(checkedPermission) &&
          !userPermissions.profile.has(checkedPermission)
      );
};

export default makePermissions;
