import {Modal} from 'antd';
import clsx from 'clsx';
import React, {useCallback, useMemo, useState} from 'react';
import {IntlShape} from 'react-intl';
import Button from '../../components/Button';
import Link from '../../components/Link';
import routes, {getRoutePath} from '../../constants/routes';
import {modalWidth} from '../../constants/styles';
import useMedias from '../../customHooks/useMedias';
import {UUID4} from '../../types';
import {redirect} from '../../models/router';
import {ReactComponent as IconPlus} from '../../images/icons/plus-fill.svg';
import gcss from '../../utils/styles';
import css from './BlackCreateCardTopUpNotification.module.less';

export type BlackCreateCardTopUpNotificationProps = {
  intl: IntlShape;
  accountId?: UUID4;
  amount?: number;
  mode?: 'this' | 'main' | 'second_card' | 'physical' | 'employee';
  showTransfer?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  afterClose?: () => void;
};

const BlackCreateCardTopUpNotification: React.FC<BlackCreateCardTopUpNotificationProps> = ({
  intl,
  accountId,
  mode = 'main',
  showTransfer = false,
  onClose,
  onSuccess,
  amount,
  afterClose,
}) => {
  const media = useMedias();

  const [visible, setVisible] = useState(true);

  const title = useMemo(() => {
    if (mode === 'this') {
      return intl.formatMessage({
        id:
          'BlackCreateCardTopUpNotification-You_do_not_have_enough_funds_in_this_account_',
      });
    }
    if (mode === 'physical') {
      return intl.formatMessage({
        id:
          'BlackCreateCardTopUpNotification-You_do_not_have_enough_funds_for_creating_a_physical_card_',
      });
    }
    if (mode === 'employee') {
      return intl.formatMessage({
        id:
          'BlackCreateCardTopUpNotification-You_do_not_have_enough_funds_to_invite_the_payroll_cardholder',
      });
    }
    if (mode === 'second_card') {
      return intl.formatMessage({
        id:
          'BlackCreateCardTopUpNotification-You_can_t_create_a_second_card_before_making_your_first_top_up_',
      });
    }

    return intl.formatMessage({
      id: 'PricingPlan-You_do_not_have_enough_funds_in_your_main_account',
    });
  }, [mode, intl]);

  const description = useMemo(() => {
    if (mode === 'second_card') {
      return intl.formatMessage({
        id:
          'BlackCreateCardTopUpNotification-Please_top_up_your_account_with_the_minimum_amount_',
      });
    }
    if (mode === 'physical') {
      return intl.formatMessage({
        id: 'BlackCreateCardTopUpNotification-Please_fund_your_accounts_',
      });
    }

    return intl.formatMessage({
      id:
        'BlackCreateCardTopUpNotification-Please_fund_your_account_with_the_required_amount_',
    });
  }, [mode, intl]);

  const handleCancel = useCallback(() => {
    setVisible(false);

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleSuccess = useCallback(() => {
    setVisible(false);

    if (onSuccess) {
      onSuccess();
    }
  }, [onSuccess]);

  return (
    <Modal
      className="ant-modal_sticky"
      visible={visible}
      title={title}
      width={modalWidth}
      footer={false}
      closable
      onCancel={handleCancel}
      destroyOnClose
      centered={!media.xs}
      maskClosable={false}
      afterClose={afterClose}
    >
      <div>{description}</div>

      <div
        className={clsx(
          css.blackCreateCardTopUpNotification__btns,
          'ant-modal-body__btns'
        )}
      >
        <Button
          className={clsx(
            'ant-btn_wide ant-btn_green-emerald',
            gcss('t_font-weight_700'),
            gcss('t_transform_uppercase'),
            media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger'
          )}
          icon={<IconPlus className="ant-btn-icon ant-btn-icon_plus-fill" />}
          onClick={() => {
            handleSuccess();
            redirect(
              getRoutePath(routes.mainTopUp, {
                accountId,
              })
            );
          }}
        >
          <span>{intl.formatMessage({id: 'App-Top_up'})}</span>
        </Button>

        {showTransfer && (
          <Link
            className={css.blackCreateCardTopUpNotification__link}
            to={
              accountId
                ? getRoutePath(routes.makeToAccountTransfer, {
                    toAccountId: accountId,
                    amount,
                  })
                : getRoutePath(routes.makeTransfer)
            }
            onClickAction={() => {
              handleSuccess();
            }}
          >
            {intl.formatMessage({
              id:
                'BlackCreateCardTopUpNotification-Transfer_money_from_another_account_',
            })}
          </Link>
        )}
      </div>
    </Modal>
  );
};

export default BlackCreateCardTopUpNotification;
