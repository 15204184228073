import React from 'react';
import clsx from 'clsx';
import CardThumbInfo, {
  CardThumbInfoProps,
} from '../CardThumbInfo/CardThumbInfo';
import css from './CardThumbInfoList.module.less';

interface Props {
  className?: string;
  items: CardThumbInfoProps[];
}

const CardThumbInfoList: React.FC<Props> = ({className, items}) => {
  return (
    <div className={clsx(css.cardThumbInfoList, className)}>
      {items.map((item, idx) => {
        const key = `${idx}`;

        return (
          <CardThumbInfo
            key={key}
            className={css.cardThumbInfoList__item}
            {...item}
          />
        );
      })}
    </div>
  );
};

export default CardThumbInfoList;
