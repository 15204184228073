import React, {useCallback, useMemo, useState} from 'react';
import {Modal} from 'antd';
import clsx from 'clsx';
import {useIntl} from 'react-intl';
import Button from '../../../../../../components/Button';
import useMedias from '../../../../../../customHooks/useMedias';
import {modalWidth} from '../../../../../../constants/styles';
import Wizard from '../../../../../../components/Wizard';
import InfoPost from '../../../../../../components/InfoPost';
import employeesVideoSrc from '../../../../../../videos/sallary-programm/employees.mp4';
import multiplePaymentsVideoSrc from '../../../../../../videos/sallary-programm/multiple-payments.mp4';
import paymentHistoryVideoSrc from '../../../../../../videos/sallary-programm/payment-history.mp4';
import {ReactComponent as IconClose} from '../../../../../../images/icons/close.svg';
import visaSpaceSrc from '../../../../../../images/bg/visa-space.svg';
import {ReactComponent as IconArrowRight} from '../../../../../../images/icons/arrow-right.svg';
import css from './SalaryProgramModal.module.less';
import gcss from '../../../../../../utils/styles';

interface Props {
  onClose: (status: boolean) => void;
}

const SalaryProgramModal: React.FC<Props> = ({onClose}) => {
  const intl = useIntl();
  const media = useMedias();
  const [visible, setVisible] = useState(true);
  const [isLastStep, setIsLastStep] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const wizardSteps = useMemo(() => {
    return [
      {
        media: employeesVideoSrc,
        title: intl.formatMessage({
          id: 'SalaryProgramModal-Invite_cardholders_with_just_a_few_clicks',
        }),
        desc: intl.formatMessage({
          id:
            'SalaryProgramModal-Invite_cardholders_with_a_click_simple_process_Enter_phone_nu',
        }),
      },
      {
        media: multiplePaymentsVideoSrc,
        title: intl.formatMessage({
          id:
            'SalaryProgramModal-Save_time_of_your_accountant_with_multiple_payments_solution',
        }),
        desc: intl.formatMessage(
          {
            id:
              'SalaryProgramModal-Efficiently_process_up_to__count__payments_with_Wallester_s_plat',
          },
          {
            count: 1500,
          }
        ),
      },
      {
        media: paymentHistoryVideoSrc,
        title: intl.formatMessage({
          id:
            'SalaryProgramModal-Easy_tracking_of_payment_history_in_one_place',
        }),
        desc: intl.formatMessage({
          id:
            'SalaryProgramModal-Efficiently_track_payment_history_in_one_place_with_Wallester',
        }),
      },
    ];
  }, [intl]);

  const getWizardNextAction = useCallback(
    (isLast?: boolean) => {
      if (!isLast) {
        return (
          <Button
            className={clsx(
              'ant-btn_stable ant-btn_green-emerald',
              media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger',
              gcss('t_font-weight_700'),
              gcss('t_transform_uppercase')
            )}
          >
            <span>{intl.formatMessage({id: 'App-Next'})}</span>

            <IconArrowRight
              className={clsx(
                'ant-btn-post-icon',
                media.xs
                  ? 'ant-btn-post-icon_arrow-right'
                  : 'ant-btn-post-icon_arrow-right-small'
              )}
            />
          </Button>
        );
      }

      return (
        <Button
          className={clsx(
            'ant-btn_stable ant-btn_green-emerald',
            media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger',
            gcss('t_font-weight_700'),
            gcss('t_transform_uppercase')
          )}
          onClick={() => {
            setVisible(false);
          }}
        >
          {intl.formatMessage({id: 'SalaryProgramModal-Explore_now'})}
        </Button>
      );
    },
    [intl, media.xs]
  );

  const wizardItems = useMemo(() => {
    return wizardSteps.map((wizardStep) => {
      return (
        <InfoPost
          media={
            <div className={clsx(css.modal__media, css.modal__media_styled)}>
              <video
                src={wizardStep.media}
                muted
                autoPlay
                loop
                playsInline
                style={{objectFit: 'cover'}}
              />
            </div>
          }
          title={wizardStep.title}
          desc={wizardStep.desc}
        />
      );
    });
  }, [wizardSteps]);

  const [wizardNextAction, setWizardNextAction] = useState(
    getWizardNextAction()
  );

  const handleWizardChange = useCallback(
    (activeIndex: number) => {
      setIsLastStep(activeIndex + 1 >= wizardItems.length);
      setWizardNextAction(
        getWizardNextAction(activeIndex + 1 >= wizardItems.length)
      );
    },
    [getWizardNextAction, wizardItems.length]
  );

  return (
    <Modal
      className={clsx(
        'ant-modal_sticky-full ant-modal_hide-header-border',
        css.modal
      )}
      visible={visible}
      afterClose={() => {
        onClose(isLastStep);
      }}
      destroyOnClose
      centered={!media.xs}
      title=" "
      footer={false}
      onCancel={() => {
        setVisible(false);
      }}
      maskClosable={false}
      closeIcon={<IconClose />}
      width={modalWidth}
    >
      <div className={css.modal__inner}>
        {inProgress ? (
          <Wizard
            contentClassName={css.modal__content}
            actionsClassName={css.modal__actions}
            items={wizardItems}
            action={wizardNextAction}
            onChange={handleWizardChange}
          />
        ) : (
          <>
            <InfoPost
              className={css.modal__content}
              media={
                <div className={css.modal__media}>
                  <img
                    src={visaSpaceSrc}
                    alt=""
                    onLoad={() => {
                      wizardSteps.forEach((wizardStep) => {
                        const video = document.createElement('video');

                        video.src = wizardStep.media;
                      });
                    }}
                  />
                </div>
              }
              title={intl.formatMessage({
                id:
                  'SalaryProgramModal-Explore_new_features_with_Wallester_s_Payroll_program_',
              })}
              desc={intl.formatMessage({
                id:
                  'SalaryProgramModal-Elevate_your_business_with_Wallester_s_new_Payroll_program__d',
              })}
            />

            <div className={css.modal__actions}>
              <Button
                className={clsx(
                  'ant-btn_transparent ant-btn_text-blue',
                  media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger',
                  gcss('t_font-weight_700'),
                  gcss('t_transform_uppercase'),
                  css.modal__actionCancel
                )}
                onClick={() => {
                  setVisible(false);
                }}
              >
                {intl.formatMessage({id: 'App-No__thank_you'})}
              </Button>

              <Button
                className={clsx(
                  'ant-btn_stable ant-btn_green-emerald',
                  media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger',
                  gcss('t_font-weight_700'),
                  gcss('t_transform_uppercase')
                )}
                onClick={() => {
                  setInProgress(true);
                }}
              >
                {intl.formatMessage({id: 'SalaryProgramModal-Show_me_around'})}
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default SalaryProgramModal;
