import React from 'react';
import ReactDOM from 'react-dom/client';

const renderToBody = (element: React.ReactNode): (() => void) => {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = ReactDOM.createRoot(div);
  root.render(element);
  return () => {
    root.unmount();
    document.body.removeChild(div);
  };
};

export default renderToBody;
