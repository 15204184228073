import ExpectedMonthlyTopUpAmounts from '../constants/topUp';
import {TopUpModeType} from '../modules/TopUp/BlackTopUpContent';
import {IAccount} from './account';
import {CardSpendingAmount} from './card-spending-amounth';
import {UUID4, DateTime} from './common';
import {CorporateDocumentType} from './company-documents-verification';
import {EmployeesQuantity} from './employees-quantity';
import {CountryCodes3l} from './geo';
import {LanguageCode3l} from './languageCodes';
import {IPhone} from './phone';
import {TSupportManagers} from './supportManagers';

export enum VerificationStatus {
  NotConfirmed = 'NotConfirmed',
  PendingApproval = 'PendingApproval',
  Confirmed = 'Confirmed',
  VerificationFailed = 'Failed',
  Rejected = 'Rejected',
  Required = 'Required',
}

export type VerificationStatusObject = {
  status: VerificationStatus;
  reason?: {
    code: string;
    text: string;
  };
};

export enum ActivityType {
  ActivitiesOfExtraterritorialOrganizationsAndBodies = 'ActivitiesOfExtraterritorialOrganizationsAndBodies',
  AdministrativeActivity = 'AdministrativeActivity',
  AdultEntertainment = 'AdultEntertainment',
  AdvertisingMarketing = 'AdvertisingMarketing',
  AgricultureForestryFisheries = 'AgricultureForestryFisheries',
  AlcoholicBeveragesAndTobacco = 'AlcoholicBeveragesAndTobacco',
  AlimentaryProducts = 'AlimentaryProducts',
  ArchitectureEngineeringAndTechnicalConsultingInTheseAreas = 'ArchitectureEngineeringAndTechnicalConsultingInTheseAreas',
  ArtEntertainmentAndRecreation = 'ArtEntertainmentAndRecreation',
  ArtworksCollectionItemsAntiques = 'ArtworksCollectionItemsAntiques',
  BeverageService = 'BeverageService',
  BoardAndLodging = 'BoardAndLodging',
  BuildingAndTerritoryMaintenance = 'BuildingAndTerritoryMaintenance',
  Charity = 'Charity',
  CinemaVideoFilmsAndTvProgramsSoundRecordingAndRecordedMusic = 'CinemaVideoFilmsAndTvProgramsSoundRecordingAndRecordedMusic',
  ClothingAndFootwear = 'ClothingAndFootwear',
  ComputerProgrammingConsultingAndOtherRelatedServices = 'ComputerProgrammingConsultingAndOtherRelatedServices',
  ConstructionActivities = 'ConstructionActivities',
  CreativeWorkAndArt = 'CreativeWorkAndArt',
  CreditsOrMonetaryIntermediation = 'CreditsOrMonetaryIntermediation',
  Cryptocurrencies = 'Cryptocurrencies',
  DataProcessingWebPortals = 'DataProcessingWebPortals',
  Design = 'Design',
  DomesticAppliancesAndElectronics = 'DomesticAppliancesAndElectronics',
  Education = 'Education',
  Energy = 'Energy',
  EngineeringTests = 'EngineeringTests',
  EventManagement = 'EventManagement',
  FinancialAndInsuranceServices = 'FinancialAndInsuranceServices',
  FoodDelivery = 'FoodDelivery',
  FuelAndGas = 'FuelAndGas',
  FundManagement = 'FundManagement',
  GamblingGamesAndBetting = 'GamblingGamesAndBetting',
  HealthCareAndSocialServices = 'HealthCareAndSocialServices',
  HighValueGoods = 'HighValueGoods',
  HoldingCompaniesAndPropertyManagement = 'HoldingCompaniesAndPropertyManagement',
  HotelsAndRelatedAccommodation = 'HotelsAndRelatedAccommodation',
  HrAgencies = 'HrAgencies',
  InformationAndCommunication = 'InformationAndCommunication',
  InformationAndReferenceServices = 'InformationAndReferenceServices',
  Insurance = 'Insurance',
  InvestmentActivities = 'InvestmentActivities',
  LandscapeDesign = 'LandscapeDesign',
  LegalServicesAndAccounting = 'LegalServicesAndAccounting',
  LibrariesArchivesMuseums = 'LibrariesArchivesMuseums',
  LodgingAndCateringServices = 'LodgingAndCateringServices',
  ManagementConsulting = 'ManagementConsulting',
  Manufacturing = 'Manufacturing',
  MedicationAndPharmaceuticals = 'MedicationAndPharmaceuticals',
  MiningIndustry = 'MiningIndustry',
  MotorVehicles = 'MotorVehicles',
  OffshoreServices = 'OffshoreServices',
  Other = 'Other',
  OtherAccommodationTypes = 'OtherAccommodationTypes',
  OtherAdministrativeActivities = 'OtherAdministrativeActivities',
  OtherFinancialServicesExcludingInsuranceAndPensionFundServices = 'OtherFinancialServicesExcludingInsuranceAndPensionFundServices',
  OtherGoods = 'OtherGoods',
  OtherInformationServices = 'OtherInformationServices',
  OtherProfessionalScientificAndTechnicalActivities = 'OtherProfessionalScientificAndTechnicalActivities',
  OtherServices = 'OtherServices',
  OtherServicesProvision = 'OtherServicesProvision',
  PensionFunds = 'PensionFunds',
  Photography = 'Photography',
  PoliticalOrganization = 'PoliticalOrganization',
  PrivateBanking = 'PrivateBanking',
  ProfessionalMembershipOrganization = 'ProfessionalMembershipOrganization',
  ProfessionalScientificAndTechnicalActivities = 'ProfessionalScientificAndTechnicalActivities',
  PublicAdministrationAndDefense = 'PublicAdministrationAndDefense',
  PublishingActivities = 'PublishingActivities',
  RadioBroadcasting = 'RadioBroadcasting',
  RealEstateActivities = 'RealEstateActivities',
  RealEstateLeasingAndManagement = 'RealEstateLeasingAndManagement',
  RealEstatePurchaseAndSale = 'RealEstatePurchaseAndSale',
  RealEstateTransactions = 'RealEstateTransactions',
  RecreationAndEntertainment = 'RecreationAndEntertainment',
  ReligiousOrganization = 'ReligiousOrganization',
  Renovation = 'Renovation',
  RentingLeasing = 'RentingLeasing',
  RepairingServices = 'RepairingServices',
  Restaurants = 'Restaurants',
  ScientificResearchAndDevelopment = 'ScientificResearchAndDevelopment',
  SecurityAndInvestigation = 'SecurityAndInvestigation',
  ShortTermAccommodationServices = 'ShortTermAccommodationServices',
  Software = 'Software',
  Sport = 'Sport',
  SupportingActivitiesRelatedToFinancialServices = 'SupportingActivitiesRelatedToFinancialServices',
  SupportingActivitiesRelatedToInsuranceAndPensionFunds = 'SupportingActivitiesRelatedToInsuranceAndPensionFunds',
  Telecommunications = 'Telecommunications',
  TourOperatorTravelAgent = 'TourOperatorTravelAgent',
  TouristCampsRecreationAndEntertainmentParks = 'TouristCampsRecreationAndEntertainmentParks',
  TradeUnion = 'TradeUnion',
  TranslationServices = 'TranslationServices',
  TransportationAndStorage = 'TransportationAndStorage',
  TrustsFundsAndOtherRelatedFinancialFacilities = 'TrustsFundsAndOtherRelatedFinancialFacilities',
  ValuablesJewelry = 'ValuablesJewelry',
  WaterSupplySewerageWasteCollectionAndDistribution = 'WaterSupplySewerageWasteCollectionAndDistribution',
  WholesaleAndRetailTrade = 'WholesaleAndRetailTrade',
  NonProfitOrganizationFoundations = 'NonProfitOrganizationFoundations',
  NonProfitOrganizationOther = 'NonProfitOrganizationOther',
  EstonianIndustryType = 'EstonianIndustryType',
  EmbassiesConsulates = 'EmbassiesConsulates',
  Crowdfunding = 'Crowdfunding',
}

export enum EstimatedMonthlyDeposit {
  // TODO: Deprecated section
  EstimatedMonthlyDepositFirstLevel = 'EstimatedMonthlyDepositFirstLevel',
  EstimatedMonthlyDepositSecondLevel = 'EstimatedMonthlyDepositSecondLevel',
  EstimatedMonthlyDepositThirdLevel = 'EstimatedMonthlyDepositThirdLevel',

  EstimatedMonthlyDeposit0To25000 = 'EstimatedMonthlyDepositFirstLevel',
  EstimatedMonthlyDeposit25000To50000 = 'EstimatedMonthlyDepositSecondLevel',
  EstimatedMonthlyDeposit100000AndMore = 'EstimatedMonthlyDepositThirdLevel',
}

export enum EstimatedTransactionNumber {
  // TODO: Deprecated section
  EstimatedTransactionNumberFirstLevel = 'EstimatedTransactionNumberFirstLevel',
  EstimatedTransactionNumberFourthLevel = 'EstimatedTransactionNumberFourthLevel',
  EstimatedTransactionNumberSecondLevel = 'EstimatedTransactionNumberSecondLevel',
  EstimatedTransactionNumberThirdLevel = 'EstimatedTransactionNumberThirdLevel',

  EstimatedTransactionNumber0To10 = 'EstimatedTransactionNumberFirstLevel',
  EstimatedTransactionNumber10To100 = 'EstimatedTransactionNumberFourthLevel',
  EstimatedTransactionNumber100To500 = 'EstimatedTransactionNumberSecondLevel',
  EstimatedTransactionNumber500AndMore = 'EstimatedTransactionNumberThirdLevel',
}

export type AgreementFormat = 'pdf' | 'html';

export type ClientOnboardingVerificationStatus = 'Confirmed' | 'NotConfirmed';

export interface ClientVerificationStatuses {
  client_information_verification_status: VerificationStatus;
  documents_verification_status: VerificationStatus;
  identity_verification_status: VerificationStatus;
}

export interface IClient extends ClientVerificationStatuses {
  id: UUID4;
  name: string;
  registration_street: string;
  address2?: string;
  registration_city: string;
  contact_person?: string;
  registration_country_code?: CountryCodes3l;
  custom_logo?: string;
  email: string;
  mobile: string;
  organizational_number: string;
  phone?: string;
  registration_postal_code: string;
  preferred_language_code?: LanguageCode3l;
  created_at: string;
  created_by?: string;
  updated_at?: string;
  deleted_at?: string;
  deleted_by?: string;
  color?: string;
  email_verified: boolean;
  mobile_verified: boolean;
  email_verified_at: string;
  mobile_verified_at: string;

  registration_flat?: string;
  registration_region?: string;
  actual_country_code?: CountryCodes3l;
  actual_street?: string;
  actual_flat?: string;
  employees_quantity?: EmployeesQuantity;
  card_spending_amount?: CardSpendingAmount;
  actual_city?: string;
  actual_region?: string;
  actual_postal_code?: string;
  activity_type?: ActivityType;
  incorporation_date?: DateTime;
  estimated_monthly_deposit?: EstimatedMonthlyDeposit;
  estimated_transaction_number?: EstimatedTransactionNumber;
  business_card_account_agreement?: boolean;
  fee_info_agreement?: boolean;
  contact_mobile?: string;
  contact_email?: string;
  updated_by?: string;
  white_label?: boolean;
  status?: 'Active' | 'Frozen' | 'Deleted' | 'Terminated';
  onboarding_verification_status?: ClientOnboardingVerificationStatus;
  agreement_signed_at?: DateTime;
  is_got_it_verification?: boolean;

  vat_number?: string;
  web_representation?: string;
  business_description?: string;
  is_bank_confirmed?: boolean;
}

export interface IClientDetails {
  client: IClient | null;
  loading: boolean;
}

export interface IClientList {
  items: IClient[];
  totalItems?: number;
}

export interface IClientsRequestParams {
  fromRecord: number;
  recordsCount: number;
  orderField?: string;
  orderDirection?: string;
}

export interface ICreateClientParams {
  name: string;
  registration_street?: string;
  address2?: string;
  registration_city?: string;
  contact_person?: string;
  registration_country_code?: CountryCodes3l;
  custom_logo?: string;
  email: string;
  mobile: string;
  organizational_number?: string;
  phone?: string;
  registration_postal_code?: string;
}

export interface IUpdateClientParams {
  id: UUID4;
  name: string;
  mobile: string;
  email: string;
  organizational_number?: string;
  phone?: string;
  contact_person?: string;
  registration_country_code?: CountryCodes3l;
  registration_street?: string;
  registration_flat?: string;
  registration_city?: string;
  registration_region?: string;
  registration_postal_code?: string;
  address2?: string;
  custom_logo?: string;
  is_got_it_verification?: boolean;
}

export interface IFormCreateClient {
  name: string;
  email: string;
  registration_street?: string;
  address2?: string;
  registration_city?: string;
  registration_postal_code?: string;
  contact_person?: string;
  registration_country_code?: CountryCodes3l;
  mobile: IPhone;
  organizational_number?: string;
  phone?: IPhone;
  custom_logo?: string;
}

export type ClientVerificationConfig = {
  activity_types: string[];
  activity_type_linked_list: {
    id: string;
    parent?: string;
    selectable?: true;
  }[];
  employees_quantities: string[];
  card_spending_amounts: string[];
  required_corporate_documents?: CorporateDocumentType[];
};

export type IClientAccountRequestParams = {
  currentPage: number;
  pageSize: number;
  orderField?: string;
  orderDirection?: string;
  is_main?: boolean;
};

export interface IClientAccounts {
  accounts: IAccount[];
  totalItems?: number;
}

export type TClientSupportManagersResponse = {
  support_managers: TSupportManagers;
};

export const ClientTicketStatuses = [
  'Submitted',
  'InProgress',
  'WaitingOnCustomer',
  'OnHold',
  'Resolved',
] as const;

export type TClientTicketStatus = typeof ClientTicketStatuses[number];

export type TClientTicket = {
  id: 'string';
  external_id: 'string';
  status: TClientTicketStatus;
  exclusive_dedicated_bin: boolean;
  exclusive_shared_bin: boolean;
  dedicated_bin_range: boolean;
  shared_bin_range: boolean;
  whitelist_3ds: boolean;
};

export interface IClientGetTicketsRequestParams {
  ticket_statuses?: Omit<TClientTicketStatus, 'Resolved'>[];
}

export interface IClientCreateTicketRequestParams {
  exclusiveDedicatedBin?: boolean;
  exclusiveSharedBin?: boolean;
  dedicatedBinRange?: boolean;
  sharedBinRange?: boolean;
  whiteList3DS?: boolean;
}

export const TopUpTicketStatuses = [
  'Submitted',
  'InProgress',
  'WaitingOnCustomer',
  'OnHold',
  'Resolved',
] as const;

export type TTopUpTicketStatus = typeof TopUpTicketStatuses[number];

export type TTopUpTicket = {
  id: string;
  external_id: string;
  status: TTopUpTicketStatus;
  top_up_type: TopUpModeType;
  minimum_amount: number;
  maximum_amount: number;
};

export interface ITopUpGetTicketsRequestParams {
  ticket_statuses?: Omit<TTopUpTicketStatus, 'Resolved'>[];
}

export interface ITopUpCreateTicketRequestParams {
  top_up_type: TopUpModeType;
  minimum_amount: number;
  maximum_amount?: number;
}

export type ExpectedMonthlyTopUpAmount = typeof ExpectedMonthlyTopUpAmounts[number];
