import moment from 'moment-timezone';
import Demo, {DemoApiHandler} from '../demo';
import {Card3DSecureSettings, Card3DSecurityType, ICard} from '../../types';
import {hideCardPrivacyInfo} from './getCard';

const updateCard3dSecureSettings: DemoApiHandler = ({
  config,
  instance,
  url,
  data,
}) => {
  const item = instance
    .getData()
    .cards[instance.getData().productId].find(
      (a) => a.id === url?.params?.card_id
    );
  const currentData = (data as unknown) as Card3DSecureSettings;

  if (item) {
    if (!item['3d_secure_settings']) {
      item['3d_secure_settings'] = {
        type: 'SMSOTPAndStaticPassword' as Card3DSecurityType,
        password: '',
      };
    }

    item['3d_secure_settings'] = {
      ...item['3d_secure_settings'],
      mobile:
        typeof currentData.mobile !== 'undefined'
          ? currentData.mobile
          : item['3d_secure_settings'].mobile,
      email:
        typeof currentData.email !== 'undefined'
          ? currentData.email
          : item['3d_secure_settings'].email,
      password:
        typeof currentData.password !== 'undefined'
          ? currentData.password
          : item['3d_secure_settings'].password,
    };

    item.updated_at = moment().toISOString();

    instance.saveImpl(['cards']);

    return Demo.makeDataResponse<{card: ICard}>(config, {
      card: hideCardPrivacyInfo(item),
    });
  }

  return Demo.makeUnprocessableEntityResponse(config, 30001);
};

export default updateCard3dSecureSettings;
