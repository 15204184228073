import {useUnit} from 'effector-react';
import React, {lazy, Suspense, useMemo} from 'react';
import {CookiesProvider} from 'react-cookie';
import {SplitFactory} from '@splitsoftware/splitio-react';
import FeatureStore, {WFactory} from './models/features';
// import './libs/wrappers/Sentry/Sentry';
import logger from './logger';
import MaintenanceBoundary from './modules/Error/Maintenance/MaintenanceBoundary';
import App from './modules/App';

const log = logger.module('Bootstrap');
log.info('Run Bootstrap');

const SvgSprite = lazy(
  () => import('./modules/SvgSprite' /* webpackChunkName: "SvgSprite" */)
);

const SplitFactoryWrapper: React.FC<
  React.PropsWithChildren<{factory: WFactory}>
> = ({factory, children}) => {
  if (factory) {
    return (
      <SplitFactory updateOnSdkUpdate updateOnSdkReady factory={factory}>
        <>{children}</>
      </SplitFactory>
    );
  }
  return <>{children}</>;
};

const Bootstrap: React.FC = () => {
  const factory = useUnit(FeatureStore);

  const sprite = useMemo(() => {
    return <SvgSprite />;
  }, []);
  return (
    <>
      <Suspense fallback={<div />}>{sprite}</Suspense>

      <MaintenanceBoundary>
        <CookiesProvider>
          <SplitFactoryWrapper factory={factory.sdk}>
            <App />
          </SplitFactoryWrapper>
        </CookiesProvider>
      </MaintenanceBoundary>
    </>
  );
};

export default Bootstrap;
