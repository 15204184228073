import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import clsx from 'clsx';
import {useStoreMap} from 'effector-react';
import {HintConfig} from '../../../types';
import ModalMagic from '../../../components/ModalMagic';
import {setHintState} from '../../../models/serverStorage';
import Button from '../../../components/Button';
import Layout$ from '../../../models/layout';
import LAYOUT_TYPES from '../../../constants/layout';
import useMedias from '../../../customHooks/useMedias';
import HintsStore from '../../../models/hints';
import useHint from '../../../customHooks/useHint';
import HINTS_TYPES from '../../../types/hints';
import css from './HintRouteItem.module.less';
import gcss from '../../../utils/styles';

const HintRouteItem: React.FC = () => {
  const intl = useIntl();
  const media = useMedias();
  const {routeHintId} = useStoreMap({
    store: HintsStore,
    keys: ['activeRouteHint'],
    fn: (state) => {
      return {routeHintId: state.activeRouteHint};
    },
  });
  const [routeHint] = useHint((routeHintId?.id || '') as HINTS_TYPES);
  const [type, menuMobileVisible, modification] = useStoreMap({
    store: Layout$,
    keys: ['type', 'menuMobileVisible', 'modification'],
    fn: (state$) => [
      state$.type,
      state$.menuMobileVisible,
      state$.modification,
    ],
  });
  const [hint, setHint] = useState<HintConfig>();
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = useCallback(() => {
    if (routeHint) {
      setHintState({
        hint: routeHint?.id,
        state: true,
      });
    }
  }, [routeHint]);

  useLayoutEffect(() => {
    setIsVisible(false);

    if (routeHint) {
      setHint(routeHint);
    }
  }, [routeHint]);

  useEffect(() => {
    if (routeHint) {
      setIsVisible(true);
    }
  }, [routeHint]);

  if (!hint) {
    return null;
  }

  return (
    <div
      className={clsx(
        css.modal,
        (!media.sm && type === LAYOUT_TYPES.SMALL) ||
          (media.sm && !menuMobileVisible)
          ? css.modal_sidebarSmall
          : '',
        modification !== 'dashboard' ? css.modal_withoutSidebar : '',
        isVisible ? css.modal_animated : '',
        isVisible ? css.modal_visible : ''
      )}
    >
      <ModalMagic
        description={
          hint.content_key
            ? intl.formatMessage({id: hint.content_key})
            : undefined
        }
        actions={
          <Button
            onClick={handleClick}
            className={clsx(
              gcss('t_font-weight_700'),
              gcss('t_transform_uppercase')
            )}
          >
            {intl.formatMessage({id: 'App-Got_it'})}
          </Button>
        }
        visible={isVisible}
      />
    </div>
  );
};

export default HintRouteItem;
