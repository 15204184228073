import {Employee} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const getEmployee: DemoApiHandler = ({config, instance, url}) => {
  const item = instance
    .getData()
    .employees[instance.getData().productId].find(
      (a) => a.id === url?.params?.employee_id
    );

  if (item) {
    return Demo.makeDataResponse<{
      employee: Employee;
    }>(config, {
      employee: item,
    });
  }

  return Demo.makeUnprocessableEntityResponse(config, 90721);
};

export default getEmployee;
