import React from 'react';
import moment, {Moment} from 'moment-timezone';
import logger from '../logger';
import {
  CardBlockType,
  CardDispatchMethod,
  CardStatus,
  ICard,
  IProduct,
  TBadgeColors,
  TypedMap,
} from '../types';
import {CardNameMaxLength} from '../constants/cards';
import {sliceStringWithEllipsis} from './string';
import EllipsisTooltip from '../components/EllipsisTooltip';

interface GetCardNameProps {
  str: string;
  maxSymbols?: number;
  withEllipsisTooltip?: boolean;
}

const log = logger.module('CardHelper');

const EMPTY_PIN_SECRET = '{{NOKEY}}';
let missingPinMessageSanded = false;

export class Card {
  private readonly card: ICard;

  private readonly isNonWhiteLabel: boolean;

  static CloseStatuses: TypedMap<CardStatus> = {
    Closed: 'Closed',
    Closing: 'Closing',
  };

  static HardBlockStatuses: TypedMap<CardBlockType> = {
    Counterfeit: 'Counterfeit',
    Fraudulent: 'Fraudulent',
    Lost: 'Lost',
    Stolen: 'Stolen',
  };

  static SoftBlockStatuses: TypedMap<CardBlockType> = {
    BlockedByCardholder: 'BlockedByCardholder',
    BlockedByCardUser: 'BlockedByCardUser',
    BlockedByCardholderViaPhone: 'BlockedByCardholderViaPhone',
    BlockedByClient: 'BlockedByClient',
    BlockedByIssuer: 'BlockedByIssuer',
    MaxInvalidTriesCVV2: 'MaxInvalidTriesCVV2',
    MaxInvalidTriesPIN: 'MaxInvalidTriesPIN',
    NotDelivered: 'NotDelivered',
  };

  static DenySoftBlockCardStatuses: TypedMap<CardStatus> = {
    Created: 'Created',
    Ordered: 'Ordered',
    Personalized: 'Personalized',
    Dispatched: 'Dispatched',
  };

  static StatusColor: TypedMap<CardStatus, TBadgeColors> = {
    Active: 'green',
    AwaitingRenewal: 'yellow',
    Blocked: 'red',
    Closed: 'gray',
    Closing: 'yellow',
    Expired: 'gray',
    Created: 'blue',
    Dispatched: 'blue',
    Ordered: 'blue',
    Personalized: 'blue',
  };

  static DispatchedMethodsPeriods: TypedMap<CardDispatchMethod, number> = {
    DHLExpress: 4,
    DHLGlobalMail: 10,
    DPDExpress: 11,
    StandardLatvianPostMail: 13,
  };

  static getBlocksLabelKeys = (): TypedMap<CardBlockType, string> => {
    return {
      BlockedByCardholder: 'CardStatusButtons-BlockedByCardholder',
      BlockedByCardUser: 'CardStatusButtons-BlockedByCardUser',
      BlockedByCardholderViaPhone:
        'CardStatusButtons-BlockedByCardholderViaPhone',
      BlockedByClient: 'CardStatusButtons-BlockedByClient',
      BlockedByIssuer: 'CardStatusButtons-BlockedByIssuer',
      MaxInvalidTriesCVV2: 'CardStatusButtons-MaxInvalidTriesCVV2',
      MaxInvalidTriesPIN: 'CardStatusButtons-MaxInvalidTriesPIN',
      NotDelivered: 'CardStatusButtons-NotDelivered',
      Counterfeit: 'App-Counterfeit',
      Fraudulent: 'App-Fraudulent',
      Lost: 'App-Lost',
      Stolen: 'App-Stolen',
    };
  };

  static getDispatchMethodsTitles = (): TypedMap<
    CardDispatchMethod,
    string
  > => {
    return {
      BatchEconomy: 'Cards-Batch_Economy',
      DHLExpress: 'Cards-DHL_Express',
      DHLGlobalMailTracked: 'Cards-DHL_Global_Mail_Tracked',
      DHLGlobalMail: 'Cards-DHL_Global_Mail',
      DPDExpress: 'Cards-Dpd_Express',
      StandardLatvianPostMail: 'Cards-Latvian_Post',
    };
  };

  static getDispatchMethodsPeriod = (): TypedMap<
    CardDispatchMethod,
    string
  > => {
    return {
      DHLExpress: 'Cards-2_3_working_days',
      DHLGlobalMail: 'Cards-5_9_working_days',
      DPDExpress: 'Cards-9_10_working_days',
      StandardLatvianPostMail: 'Cards-10_12_working_days',
    };
  };

  static isSoftBlockType = (blockType: CardBlockType): boolean => {
    return typeof Card.SoftBlockStatuses[blockType] !== 'undefined';
  };

  static isHardBlockType = (blockType: CardBlockType): boolean => {
    return typeof Card.HardBlockStatuses[blockType] !== 'undefined';
  };

  static getCardName({
    str,
    maxSymbols = CardNameMaxLength,
    withEllipsisTooltip = true,
  }: GetCardNameProps): React.ReactNode | string {
    const name = maxSymbols ? sliceStringWithEllipsis({str, maxSymbols}) : str;

    const processedName = withEllipsisTooltip ? (
      <EllipsisTooltip title={str}>{name}</EllipsisTooltip>
    ) : (
      name
    );

    return processedName;
  }

  constructor(card: ICard, isNonWhiteLabel?: boolean) {
    this.card = card;
    this.isNonWhiteLabel = Boolean(isNonWhiteLabel);
  }

  isClosed(): boolean {
    return typeof Card.CloseStatuses[this.card.status] !== 'undefined';
  }

  isCanChangeCardPin(pinSecret: string): boolean {
    if (
      !this.isClosed() &&
      (!this.isNonWhiteLabel || this.isActive()) &&
      this.card.type !== 'Virtual'
    ) {
      if (pinSecret !== '' && pinSecret !== EMPTY_PIN_SECRET) {
        return true;
      }

      if (pinSecret !== EMPTY_PIN_SECRET && !missingPinMessageSanded) {
        missingPinMessageSanded = true;
        log.error('missing public key for change pin functionality');
      }
    }
    return false;
  }

  isCanChangeCard3DSPassword(): boolean {
    return !this.isClosed() && (!this.isNonWhiteLabel || this.isActive());
  }

  isHardBlocked(): boolean {
    return (
      !!this.card?.block_type && Card.isHardBlockType(this.card.block_type)
    );
  }

  isSoftBlocked(): boolean {
    return (
      !!this.card?.block_type && Card.isSoftBlockType(this.card?.block_type)
    );
  }

  isCanEdit(): boolean {
    return !this.isClosed() && !this.isHardBlocked() && !this.isExpired();
  }

  isExpired(): boolean {
    return this.card.status === 'Expired';
  }

  isActive(): boolean {
    return (
      this.card.status === 'Active' || this.card.status === 'AwaitingRenewal'
    );
  }

  isAwaitingRenewal(): boolean {
    return this.card.status === 'AwaitingRenewal';
  }

  isOrdered(): boolean {
    return this.card.status === 'Ordered';
  }

  isPersonalized(): boolean {
    return this.card.status === 'Personalized';
  }

  isDispatched(): boolean {
    return this.card.status === 'Dispatched';
  }

  isCreated(): boolean {
    return this.card.status === 'Created';
  }

  isCanHardBlock(): boolean {
    return (
      !this.isClosed() &&
      !this.isHardBlocked() &&
      !this.isExpired() &&
      !this.isAwaitingRenewal()
    );
  }

  isCanSoftBlock(): boolean {
    return (
      this.isActive() ||
      this.isAwaitingRenewal() ||
      (this.isSoftBlocked() &&
        typeof Card.DenySoftBlockCardStatuses[this.card.status] === 'undefined')
    );
  }

  isCanClose(): boolean {
    return (
      !this.isClosed() &&
      !this.isHardBlocked() &&
      !this.isExpired() &&
      (!this.isNonWhiteLabel || !this.isBlocked())
    );
  }

  isCanRemind(): boolean {
    return !this.isClosed() && !this.isHardBlocked() && !this.isExpired();
  }

  isCanRemindCardNumber(): boolean {
    return this.isCanRemind() && this.card.type.toString() === 'invalid';
  }

  isCanRemindPin(): boolean {
    return this.isCanRemind() && this.card.type !== 'Virtual';
  }

  isCanRemind3dsPassword(): boolean {
    return this.card['3d_secure_settings'] !== null;
  }

  isCanRemindCardCvv2(): boolean {
    return this.isCanRemind() && this.card.type.toString() === 'invalid';
  }

  isCanAddCardUsers(
    isProd: boolean,
    isStaging: boolean,
    isSandbox: boolean,
    product: IProduct
  ): boolean {
    const productId = product.id.toLowerCase();
    if (
      this.isNonWhiteLabel ||
      (isProd && productId === 'a1dbf3af-a755-412b-b3b9-6bd06a3046b0') ||
      (isStaging &&
        (productId === '0be5633f-7d76-4de3-b4a5-14f0f7b806a7' ||
          productId === '283ddff5-13de-4ced-b86d-62121aaf1f3c')) ||
      (isSandbox && productId === '0be5633f-7d76-4de3-b4a5-14f0f7b806a7') ||
      (isProd && productId === '283ddff5-13de-4ced-b86d-62121aaf1f3c') ||
      (!isProd && product.code.substr(0, 1).toLowerCase() === 'v')
    ) {
      return (
        this.card.status !== 'Blocked' && !this.isClosed() && !this.isExpired()
      );
    }
    return false;
  }

  isCanFreeze(): boolean {
    return (
      (this.card.status === 'Active' ||
        this.card.status === 'AwaitingRenewal') &&
      !this.isFrozen()
    );
  }

  isCanUnFreeze(): boolean {
    return this.isFrozen();
  }

  isBlocked(): boolean {
    if (this.isNonWhiteLabel) {
      return this.card.status === 'Blocked' && !this.isFrozen();
    }
    return this.card.status === 'Blocked';
  }

  isFrozen(): boolean {
    return (
      this.isNonWhiteLabel &&
      this.card.status === 'Blocked' &&
      (this.card.block_type === 'BlockedByCardholder' ||
        this.card.block_type === 'BlockedByCardholderViaPhone' ||
        this.card.block_type === 'BlockedByCardUser')
    );
  }

  isClientFrozen(): boolean {
    return (
      this.isNonWhiteLabel &&
      this.card.status === 'Blocked' &&
      this.card.block_type === 'Frozen'
    );
  }

  isPhysical(): boolean {
    return (
      this.card.type === 'ChipAndPin' ||
      this.card.type === 'ChipAndPinAnonymous'
    );
  }

  isCanUnblock(): boolean {
    return !this.isClosed() && !this.isHardBlocked() && this.isBlocked();
  }

  is3DSecureActivated(): boolean {
    return this.card.is_card_3d_secure_activated || false;
  }

  isEnroled(): boolean {
    return !!this.card.is_enrolled_for_3d_secure;
  }

  isCanEnrol(): boolean {
    return (
      (this.isActive() || this.isAwaitingRenewal() || this.isSoftBlocked()) &&
      this.is3dSecureSettingsAvailable() &&
      this.isNonWhiteLabel &&
      !this.isEnroled()
    );
  }

  is3dSecureSettingsAvailable(): boolean {
    return Boolean(this.card['3d_secure_settings']);
  }

  isUnenroled(): boolean {
    return !this.card.is_enrolled_for_3d_secure;
  }

  isCanUnenrol(): boolean {
    return (
      !this.isOrdered() &&
      !this.isPersonalized() &&
      !this.isDispatched() &&
      !this.isCreated() &&
      this.isNonWhiteLabel &&
      this.is3dSecureSettingsAvailable() &&
      !this.isUnenroled()
    );
  }

  isReplaced(): boolean {
    return this.card.close_reason === 'ClosedByReplace';
  }

  isCanReplace(): boolean {
    return !this.isReplaced();
  }

  isCanTrackShippingStatus(): boolean {
    return (
      this.isPhysical() &&
      (this.isOrdered() || this.isPersonalized() || this.isDispatched())
    );
  }

  isCanTrackShipping(): boolean {
    return (
      this.isPhysical() &&
      (this.isOrdered() || this.isPersonalized() || this.isDispatched()) &&
      !!this.card.delivery_address?.tracking_number
    );
  }

  getTrackShippingLabel(): string | undefined {
    const data: TypedMap<CardDispatchMethod, string> = {
      DHLExpress: 'Cards-DHL_tracking_number',
      DHLGlobalMail: 'Cards-DHL_tracking_number',
      DPDExpress: 'Cards-DPD_Express_tracking_number',
      StandardLatvianPostMail: 'Cards-Post_Mail_tracking_number',
    };

    return this.card.delivery_address?.dispatch_method
      ? data[this.card.delivery_address?.dispatch_method]
      : undefined;
  }

  getTrackShippingLink(trackingLinkDHL: string): string | undefined {
    const data: TypedMap<CardDispatchMethod, string> = {
      DHLExpress: trackingLinkDHL,
      DHLGlobalMail: trackingLinkDHL,
    };

    return this.isCanTrackShipping() &&
      this.card.delivery_address?.dispatch_method
      ? data[this.card.delivery_address?.dispatch_method]?.replace(
          '{link}',
          this.card.delivery_address?.tracking_number || ''
        )
      : undefined;
  }

  getExpectedDeliveryDate(): Moment | null {
    if (this.card.dispatched_at) {
      const WEEKEND = [
        moment().day('Saturday').weekday(),
        moment().day('Sunday').weekday(),
      ];
      let daysAdded = 0;
      let momentDate = moment(this.card.created_at);
      const daysToAdd = this.card?.delivery_address?.dispatch_method
        ? Card.DispatchedMethodsPeriods[
            this.card.delivery_address.dispatch_method
          ] || 0
        : 0;
      while (daysAdded < daysToAdd) {
        momentDate = momentDate.add(1, 'days');
        if (!WEEKEND.includes(momentDate.weekday())) {
          daysAdded += 1;
        }
      }

      return momentDate;
    }
    return null;
  }

  canEditNotifications(): boolean {
    return this.isActive();
  }
}

const CardHelper = (card: ICard, isNonWhiteLabel?: boolean): Card => {
  return new Card(card, isNonWhiteLabel);
};

export default CardHelper;
