import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {Button as ButtonAntd} from 'antd';
import {ButtonProps as ButtonPropsAntd} from 'antd/lib/button/button';
import clsx from 'clsx';
import useDataTestId from '../../customHooks/useDataTestId';
import gcss from '../../utils/styles';

export const ButtonSizes = ['small', 'big', 'large'];
export const ButtonBackgroundColors = [
  'white',
  'purple',
  'gray',
  'lightBlue',
  'green',
  'greenLight',
  'greenEmerald',
  'greenWeb',
  'gradientBlue',
  'whiteRed',
  'whiteGreen',
  'whiteBlue',
  'whiteBlack',
  'whiteBlackRed',
  'whiteGreenEmerald',
  'whiteBlackActive',
  'whiteRedActive',
  'whiteGreenActive',
  'whiteBlueActive',
  'transparent',
  'transparentActive',
  'clear',
];
export const ButtonTextColors = ['blue', 'purple', 'coral'];
export const ButtonSpaces = ['big', 'large'];

type TButtonSizes = typeof ButtonSizes[number];
type TButtonBackgroundColors = typeof ButtonBackgroundColors[number];
type TButtonTextColors = typeof ButtonTextColors[number];
type TButtonSpaces = typeof ButtonSpaces[number];

export type ButtonProps = Omit<ButtonPropsAntd, 'size' | 'color'> & {
  size?: TButtonSizes;
  backgroundColor?: TButtonBackgroundColors;
  textColor?: TButtonTextColors;
  uppercase?: boolean;
  bold?: boolean;
  flat?: boolean;
  wide?: boolean;
  stable?: boolean;
  spaced?: TButtonSpaces;
};

const sizeMap: Record<TButtonSizes, string> = {
  small: 'ant-btn_small',
  big: 'ant-btn_middle-extra',
  large: 'ant-btn_middle-bigger',
};

const spaceMap: Record<TButtonSpaces, string> = {
  big: 'ant-btn_spaced',
  large: 'ant-btn_spacedMiddle',
};

const textColorsMap: Record<TButtonTextColors, string> = {
  blue: 'ant-btn_text-blue',
  purple: 'ant-btn_text-purple',
  coral: 'ant-btn_text-coral',
};

const backgroundColorMap: Record<TButtonBackgroundColors, string> = {
  white: 'ant-btn_white',
  purple: 'ant-btn_purple',
  gray: 'ant-btn_gray',
  lightBlue: 'ant-btn_light-blue',
  green: 'ant-btn_green',
  greenLight: 'ant-btn_green-light',
  greenEmerald: 'ant-btn_green-emerald',
  greenWeb: 'ant-btn_green-web',
  gradientBlue: 'ant-btn_gradient-blue',
  whiteRed: 'ant-btn_default-red',
  whiteGreen: 'ant-btn_default-green',
  whiteBlue: 'ant-btn_default-blue',
  whiteBlack: 'ant-btn_default-black',
  whiteBlackRed: 'ant-btn_default-black-red',
  whiteGreenEmerald: 'ant-btn_default-green-emerald',
  whiteBlackActive: 'ant-btn_default-black-active',
  whiteRedActive: 'ant-btn_default-red-active',
  whiteGreenActive: 'ant-btn_default-green-active',
  whiteBlueActive: 'ant-btn_default-blue-active',
  transparent: 'ant-btn_transparent',
  transparentActive: 'ant-btn_transparent-active',
  clear: 'ant-btn_clear',
};

const Button = forwardRef<HTMLButtonElement | null, ButtonProps>(
  (
    {
      children,
      onClick,
      className,
      size,
      backgroundColor,
      textColor,
      uppercase,
      bold,
      flat,
      wide,
      stable,
      spaced,
      ...rest
    },
    ref
  ) => {
    const addDataTestId = useDataTestId();
    const refButton = useRef<HTMLButtonElement>(null);

    useImperativeHandle<HTMLButtonElement | null, HTMLButtonElement | null>(
      ref,
      () => {
        return refButton.current;
      },
      [refButton]
    );

    return (
      <ButtonAntd
        ref={refButton}
        {...addDataTestId('Button')}
        onClick={(e) => {
          e.currentTarget.blur();

          if (onClick) {
            onClick(e);
          }
        }}
        className={clsx(
          className,
          size ? sizeMap[size] : '',
          backgroundColor ? backgroundColorMap[backgroundColor] : '',
          textColor ? textColorsMap[textColor] : '',
          uppercase ? gcss('t_transform_uppercase') : '',
          bold ? gcss('t_font-weight_700') : '',
          flat ? 'ant-btn_flat' : '',
          wide ? 'ant-btn_wide' : '',
          stable ? 'ant-btn_stable' : '',
          spaced ? spaceMap[spaced] : ''
        )}
        {...rest}
      >
        {children}
      </ButtonAntd>
    );
  }
);

export default Button;
