import {createEffect, createEvent, createStore} from 'effector';
import {getCompanyRequest, updateCompanyLimits} from '../../api/company';
import {
  CompanyLimitsSettings,
  UpdateCompanyLimitsParams,
  UUID4,
  ICompany,
} from '../../types';
import attachErrorHandler from '../../utils/error';
import {softClearAppStores} from '../clearStore';

export const defaultCompanyLimits = {
  company_limits_enabled: true,
  default_company_daily_contactless_purchase_limit: 15000,
  default_company_daily_internet_purchase_limit: 15000,
  default_company_daily_purchase_limit: 15000,
  default_company_daily_withdrawal_limit: 15000,
  default_company_monthly_contactless_purchase_limit: 15000,
  default_company_monthly_internet_purchase_limit: 15000,
  default_company_monthly_purchase_limit: 15000,
  default_company_monthly_withdrawal_limit: 15000,
  max_company_daily_contactless_purchase_limit: 15000,
  max_company_daily_internet_purchase_limit: 15000,
  max_company_daily_purchase_limit: 15000,
  max_company_daily_withdrawal_limit: 15000,
  max_company_monthly_contactless_purchase_limit: 15000,
  max_company_monthly_internet_purchase_limit: 15000,
  max_company_monthly_purchase_limit: 15000,
  max_company_monthly_withdrawal_limit: 15000,
} as CompanyLimitsSettings;

type CompanyDetails = {
  loading: boolean;
  loaded: boolean;
  company: ICompany | null;
};

export const CompanyStore = createStore<CompanyDetails>({
  loading: false,
  loaded: false,
  company: null,
});

export const getCompanyFx = createEffect<
  {
    id: UUID4;
    noCache?: boolean;
  },
  ICompany,
  Error
>({
  handler: async (props) => {
    return getCompanyRequest(props);
  },
});

export const updateCompanyLimitsFx = createEffect<
  UpdateCompanyLimitsParams,
  ICompany,
  Error
>({
  handler: async (params) => {
    return updateCompanyLimits(params);
  },
});

CompanyStore.on(getCompanyFx, (State) => {
  return {
    ...State,
    loading: true,
  };
});

CompanyStore.on(getCompanyFx.doneData, (State, company) => {
  return {
    ...State,
    company,
  };
});

CompanyStore.on(updateCompanyLimitsFx.doneData, (State, company) => {
  return {...State, company};
});

CompanyStore.on(getCompanyFx.finally, (State) => {
  return {
    ...State,
    loaded: true,
    loading: false,
  };
});

attachErrorHandler([getCompanyFx]);

export const cleanCompanyStore = createEvent();

CompanyStore.reset(cleanCompanyStore, softClearAppStores);

export default CompanyStore;
