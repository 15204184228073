import {AxiosResponse} from 'axios';
import {WLEndpoints} from '../constants/endpoints';
import {CompanyRepresentative, UUID4} from '../types';
import WallesterAxios from '../utils/WAxios';

export const getRepresentativeRequest = async (
  id: UUID4,
  companyId: UUID4
): Promise<CompanyRepresentative> => {
  const response: AxiosResponse<{
    representative: CompanyRepresentative;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get({
      endpoint: WLEndpoints.getCompanyRepresentative,
      params: {
        representative_id: id,
        company_id: companyId,
      },
    });
  return response.data.representative;
};

export const deleteCompanyRepresentative = async (
  representativeId: UUID4,
  companyId: UUID4
): Promise<CompanyRepresentative> => {
  const response: AxiosResponse<{
    representative: CompanyRepresentative;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .delete({
      endpoint: WLEndpoints.deleteCompanyRepresentative,
      params: {
        company_id: companyId,
        representative_id: representativeId,
      },
    });

  return response.data.representative;
};

export default getRepresentativeRequest;
