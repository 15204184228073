import {BroadcastChannel} from 'broadcast-channel';
import BroadcastChannelName from '../constants/broadcast';
import {UUID4} from '../types';
import BroadcastMessage, {
  BroadcastMessageTypeProfileChange,
} from '../types/broadcast';

type BroadcastMessageEventCallback<
  T extends Record<string, unknown> = Record<string, unknown>
> = (data: T) => void;

class BroadcastHelper {
  private static instance: BroadcastHelper | null = null;

  private cbs: {
    profile_change?: BroadcastMessageEventCallback<BroadcastMessageTypeProfileChange>[];
  } = {
    profile_change: [],
  };

  private bc: BroadcastChannel;

  static getInstance = (): BroadcastHelper => {
    if (BroadcastHelper.instance === null) {
      BroadcastHelper.instance = new BroadcastHelper();
    }

    return BroadcastHelper.instance;
  };

  constructor() {
    this.bc = new BroadcastChannel(BroadcastChannelName);
    this.bc.onmessage = (data) => {
      let decodedEvent: BroadcastMessage | null = null;
      try {
        decodedEvent = JSON.parse(data) as BroadcastMessage;
      } catch (e) {
        decodedEvent = null;
      }
      if (decodedEvent) {
        switch (decodedEvent.type) {
          case 'profile_change':
            (this.cbs.profile_change || []).forEach((cb) => {
              if (decodedEvent?.data) {
                cb(decodedEvent.data as BroadcastMessageTypeProfileChange);
              }
            });
            break;

          default:
            break;
        }
      }
    };
  }

  async sendProfileChangeEvent(profileId: UUID4) {
    const message: BroadcastMessage<BroadcastMessageTypeProfileChange> = {
      type: 'profile_change',
      data: {
        profileId,
      },
    };
    await this.bc.postMessage(JSON.stringify(message));
  }

  onProfileChangeEvent(
    cb: BroadcastMessageEventCallback<BroadcastMessageTypeProfileChange>
  ) {
    this.cbs.profile_change = [...(this.cbs.profile_change || []), cb];
  }

  async close() {
    await this.bc.close();
    this.cbs = {};
  }
}

export default BroadcastHelper;
