import {useContext} from 'react';
import ConfigContext from '../modules/ConfigProvider';
import {AppConfig} from '../types';

const useConfig = (): AppConfig => {
  const {config} = useContext(ConfigContext);
  return config;
};

export default useConfig;
