import {AxiosResponse} from 'axios';
import {TTimeZone} from '../constants/dateTime';
import Endpoints from '../constants/endpoints';
import logger from '../logger';
import AuthService from '../services/AuthService';
import {
  AssignProfileResponse,
  AuthChallenge,
  CountryCodes3l,
  IClient,
  IProduct,
  LoginResponse,
  NewChallengeResponse,
  RefreshTokenResponse,
  RestrictedCountryType,
  SetupChallengeResponse,
  SignUpAddCompanyParams,
  SystemProfile,
  SystemUser,
  TypedMap,
  UserLocale,
  UUID4,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

const log = logger.module('auth');

export const logoutRequest = async (): Promise<boolean> => {
  const as = AuthService.getInstance();

  if (as.hasToken()) {
    const isValid = await as.checkToken();
    if (isValid) {
      await WallesterAxios.getInstance()
        .getAuthAxios()
        .get(
          {endpoint: Endpoints.logout},
          {
            headers: {
              Authorization: as.getAuthBearerString() || '',
            },
          }
        );
    }
  }

  return true;
};

// export const assignProduct = async (
//   productId: UUID4
// ): Promise<AssignProductResponse> => {
//   const data = await WallesterAxios.getInstance().getBlackAxios().post<AssignProductResponse>(
//     getApiPathParamsMaker(Endpoints.assignProduct, {product_id: productId})
//   );
//   return data.data;
// };

export const assignProfile = async (
  profileId: UUID4
): Promise<AssignProfileResponse> => {
  const data = await WallesterAxios.getInstance()
    .getCommon()
    .post<AssignProfileResponse>({
      endpoint: Endpoints.assignProfile,
      params: {profile_id: profileId},
    });
  return data.data;
};

export const getAllowedEndpoints = async (): Promise<Endpoints[]> => {
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(getApiPathParamsMaker(Endpoints.getAllowedEndpoints));

  return response.data;
};

export const getAllowedCountries = async (
  types?: RestrictedCountryType[]
): Promise<TypedMap<RestrictedCountryType, CountryCodes3l[]>> => {
  const filteredTypes = (types || []).filter(
    (t) => t !== RestrictedCountryType.Global
  );
  const response: AxiosResponse<
    TypedMap<RestrictedCountryType, CountryCodes3l[]>
  > = await WallesterAxios.getInstance()
    .getBaseInstance()
    .get(
      getApiPathParamsMaker(
        Endpoints.getRestrictedCountries,
        undefined,
        filteredTypes && filteredTypes.length
          ? {
              entity_type: filteredTypes,
            }
          : undefined,
        true
      )
    );

  return response.data;
};

export const refreshTokenRequest = async (
  refreshToken?: string,
  doNotExtendSession?: boolean
): Promise<RefreshTokenResponse> => {
  const response = await WallesterAxios.getInstance().getAuthAxios().post(
    {endpoint: Endpoints.refreshToken},
    {
      refresh_token: refreshToken,
      do_not_extend_session: doNotExtendSession,
    }
  );

  return response.data;
};

export const signUp = async (
  phone: string,
  locale: UserLocale,
  timezoneName: TTimeZone
): Promise<boolean> => {
  log.info('signUp', {phone, locale, timezoneName});
  // return true;
  const response: AxiosResponse<'ok'> = await WallesterAxios.getInstance()
    .getAuthAxios()
    .post(
      {endpoint: Endpoints.signUp},
      {
        mobile: phone,
        locale,
        timezone_name: timezoneName,
      }
    );
  return response.data === 'ok';
};

export const signInUpdateEmailRequest = async (
  email: string,
  token: string
): Promise<boolean> => {
  const response: AxiosResponse<'ok'> = await WallesterAxios.getInstance()
    .getAuthAxios()
    .patch(
      {endpoint: Endpoints.signInUpdateEmail},
      {email},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return response.data === 'ok';
};

export const signInCreateSessionRequest = async (
  token: string
): Promise<LoginResponse> => {
  const response: AxiosResponse<LoginResponse> = await WallesterAxios.getInstance()
    .getAuthAxios()
    .post(
      {endpoint: Endpoints.signInCreateSession},
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return response.data;
};

export const signIn = async (
  phone: string,
  captchaToken?: string,
  captchaSolutionDecisionSeconds?: number
): Promise<NewChallengeResponse> => {
  const response: AxiosResponse<NewChallengeResponse> = await WallesterAxios.getInstance()
    .getAuthAxios()
    .post(
      {endpoint: Endpoints.signIn},
      {
        mobile: phone,
        captcha_solution: captchaToken,
        captcha_solution_decision_seconds: captchaSolutionDecisionSeconds,
        meta: {
          user_agent: window.navigator.userAgent,
          ip: '127.0.0.1',
          audit_source_type: 'Backend',
          js_enabled: true,
          cookies_enabled: true,
          language: 'ENG',
          browser_page_resolution: '1x1',
        },
      }
    );
  return response.data;
};

export const setupChallenge = async (
  type: AuthChallenge,
  token: string
): Promise<SetupChallengeResponse> => {
  // log.info('setupChallenge', {setupChallenge, token});
  // return {
  //   attempts_left: 5,
  //   masked_email: 'asdas@****.com',
  //   masked_mobile: '+7********77',
  //   next_attempt_after: 60,
  // };
  const response: AxiosResponse<SetupChallengeResponse> = await WallesterAxios.getInstance()
    .getAuthAxios()
    .get(
      {endpoint: Endpoints.setupChallenge, queryParams: {type}},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

  return response.data;
};

export const resolveChallengeRequest = async (
  type: AuthChallenge,
  value: string,
  token: string
): Promise<NewChallengeResponse> => {
  // log.info('resolveChallenge', {setupChallenge, token});
  // return {auth_token: 'xdasd-2', challenges: ['email_otp']};
  const response: AxiosResponse<NewChallengeResponse> = await WallesterAxios.getInstance()
    .getAuthAxios()
    .post(
      {endpoint: Endpoints.resolveChallenge},
      {type, value},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

  return response.data;
};

export const signUpAddCompanyRequest = async ({
  companyName,
  countryCode,
  token,
  gaId,
  gcId,
  utmCampaign,
  utmTerm,
  utmSource,
  utmMedium,
  origin,
  deviceType,
  affiliateHash,
}: SignUpAddCompanyParams): Promise<{
  client: IClient;
  profile?: SystemProfile;
}> => {
  const params: Record<string, unknown> = {
    name: companyName,
    country_code: countryCode,
  };

  params.source = {
    hash: affiliateHash,
    device_type: deviceType || 'regular',
    ga_id: gaId,
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_term: utmTerm,
    gc_id: gcId,
    origin,
  };

  const data = await WallesterAxios.getInstance()
    .getBaseInstance()
    .post<{client: IClient}>({endpoint: Endpoints.signUpAddCompany}, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  const assignedProducts = await WallesterAxios.getInstance()
    .getBaseInstance()
    .get<{products: IProduct[]}>(
      {endpoint: Endpoints.assignedProduct},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

  const product = assignedProducts.data.products.find(
    (p) => p.client_id === data.data.client.id
  );
  let profile: SystemProfile | undefined;
  if (product) {
    const currentUser = await WallesterAxios.getInstance()
      .getBaseInstance()
      .get<{user: SystemUser}>(
        {endpoint: Endpoints.getCurrentUser},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    profile = currentUser.data.user.profiles.find(
      (p) =>
        p.target_entity_name === 'product' && p.target_entity_id === product.id
    );
  }

  return {client: data.data.client, profile};
};

export const signInGetCurrentUser = async (
  token: string
): Promise<SystemUser> => {
  const response = await WallesterAxios.getInstance()
    .getBaseInstance()
    .get<{user: SystemUser}>(
      {endpoint: Endpoints.getCurrentUser},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

  return response.data.user;
};
