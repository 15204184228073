import {IAccount} from '../../types';
import CardHelper from '../CardHelper';
import Demo, {DemoApiHandler} from '../demo';

const getAccount: DemoApiHandler = ({config, instance, url}) => {
  const {productId} = instance.getData();
  const item = instance
    .getData()
    .accounts[productId].find((a) => a.id === url?.params?.account_id);

  if (item) {
    return Demo.makeDataResponse<{
      account: IAccount;
    }>(config, {
      account: {
        ...item,
        cards_count: (instance.getData().cards[productId] || []).reduce<number>(
          (prev, card) => {
            if (card.account_id === item.id) {
              const ch = CardHelper(card);
              if (
                ch.isActive() ||
                ch.isAwaitingRenewal() ||
                ch.isSoftBlocked()
              ) {
                return prev + 1;
              }
            }
            return prev;
          },
          0
        ),
      },
    });
  }

  return Demo.makeUnprocessableEntityResponse(config, 20001);
};

export default getAccount;
