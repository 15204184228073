import {useStoreMap} from 'effector-react';
import {useMemo} from 'react';
import Permissions from '../constants/permissions';
import {App$} from '../models/app';
import ServerStorageStore from '../models/serverStorage';
import SystemDialogNotificationsStateStore from '../models/systemDialogNotifications';
import TutorialStore from '../models/tutorial';
import ObjectKeys from '../utils/object';
import useCheckPermissions from './useCheckPermissions';
import useIsGuest from './useIsGuest';
import useIsWhiteLabel from './useIsWhiteLabel';

const useIsHintsAllowed = (): boolean => {
  const isGuest = useIsGuest();
  const isWhiteLabel = useIsWhiteLabel();
  const {tutorialIsActive} = useStoreMap({
    store: TutorialStore,
    keys: ['active', 'finishStep'],
    fn: (state) => {
      return {tutorialIsActive: state.active || state.finishStep};
    },
  });

  const dialogsFinished = useStoreMap({
    store: SystemDialogNotificationsStateStore,
    keys: ['finished'],
    fn: (state) => state.finished,
  });
  const isAccountsViewAllowed = useCheckPermissions(
    Permissions.PermissionViewAccounts
  ); // TODO: Remove crutch
  const hints = useStoreMap({
    store: ServerStorageStore,
    keys: ['hints'],
    fn: (state) => {
      return state.hints || null;
    },
  });

  const allHintsVisited = useMemo(() => {
    if (hints) {
      return ObjectKeys(hints).reduce((prevValue, currentKey) => {
        return prevValue && hints[currentKey];
      }, true);
    }
    return false;
  }, [hints]);

  const isFullyVerified = useStoreMap({
    store: App$,
    keys: ['fullyVerified'],
    fn: (state) => state.fullyVerified,
  });

  return !(
    isWhiteLabel ||
    !dialogsFinished ||
    tutorialIsActive ||
    !isFullyVerified ||
    !isAccountsViewAllowed ||
    isGuest ||
    allHintsVisited
  );
};

export default useIsHintsAllowed;
