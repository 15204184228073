import {useStoreMap} from 'effector-react';
import {CurrentLocaleStore} from '../models/i18n';
import {UserLocale} from '../types';

const useLang = (): UserLocale => {
  return useStoreMap({
    keys: ['locale'],
    store: CurrentLocaleStore,
    fn: (state) => state.locale,
  });
};

export default useLang;
