import {Locale} from 'rc-picker/lib/interface';
import {createContext} from 'react';
import config from '../../constants/config';
import {AppConfig, UserLocale} from '../../types';

const ConfigContext = createContext<{
  config: AppConfig;
  locale: UserLocale;
  momentLocale?: Locale;
}>({
  config,
  locale: 'en',
});

export default ConfigContext;
