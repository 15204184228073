import moment from 'moment-timezone';
import Demo, {DemoApiHandler} from '../demo';
import {ICard} from '../../types';
import {hideCardPrivacyInfo} from './getCard';

const updateCardSecurity: DemoApiHandler = ({config, instance, url, data}) => {
  const item = instance
    .getData()
    .cards[instance.getData().productId].find(
      (a) => a.id === url?.params?.card_id
    );

  if (item) {
    item.security = {
      ...item.security,
      ...data,
    };
    item.updated_at = moment().toISOString();

    instance.saveImpl(['cards']);

    return Demo.makeDataResponse<{card: ICard}>(config, {
      card: hideCardPrivacyInfo(item),
    });
  }

  return Demo.makeUnprocessableEntityResponse(config, 30001);
};

export default updateCardSecurity;
