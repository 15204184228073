import {AxiosResponse} from 'axios';
import moment from 'moment-timezone';
import Endpoints from '../constants/endpoints';
import {
  IAccountList,
  IAccountsRequestParams,
  TransactionDownloadFormat,
  IBlackAccountsRequest,
  IAccount,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const getAccounts = async (
  params: IAccountsRequestParams
): Promise<IAccountList> => {
  const response: AxiosResponse = await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(Endpoints.getAccounts), {
      params: {
        from_record: (params.currentPage - 1) * params.pageSize,
        records_count: params.pageSize,
        order_field: params.orderField,
        order_direction: params.orderDirection,
      },
    });

  return {
    items: response.data.accounts || [],
    totalItems: response.data.total_records_number,
    pageSize: params.pageSize,
    currentPage: params.currentPage,
    orderDirection: params.orderDirection,
    orderField: params.orderField,
  };
};

export const getSummaryStatementsLink = async (
  date: moment.Moment,
  format: TransactionDownloadFormat
): Promise<string> => {
  const response: AxiosResponse<{
    link: string;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get({
      endpoint: Endpoints.downloadSummaryStatements,
      queryParams: {
        format,
        date: date.format('YYYY-MM-DD'),
      },
    });

  return response.data.link;
};

export const getBlackAccounts = async (
  params: IBlackAccountsRequest
): Promise<IAccountList> => {
  const {
    currentPage,
    pageSize,
    isActive,
    isMain,
    orderField,
    orderDirection,
    all,
  } = params;
  let items: IAccount[] = [];
  const reqParams: Record<
    string,
    string | number | string[] | number[] | boolean
  > = {
    from_record: (currentPage - 1) * pageSize,
    records_count: pageSize,
    order_field: orderField || '',
    order_direction: orderDirection || '',
  };

  if (isActive) {
    reqParams.is_active = isActive;
  }

  if (isMain !== undefined) {
    reqParams.is_main = isMain;
  }

  if (all) {
    reqParams.records_count = 1000;
  }

  const {data: response} = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(Endpoints.getAccounts, undefined, reqParams, true)
    );

  items = [...(response.accounts || [])];

  if (all && currentPage * pageSize < (response.total_records_number || 0)) {
    items = [
      ...items,
      ...(
        await getBlackAccounts({
          ...params,
          currentPage: currentPage + 1,
        })
      ).items,
    ];
  }

  return {
    items,
    totalItems: response.total_records_number,
    pageSize,
    currentPage,
    orderField,
    orderDirection,
  };
};

export default getAccounts;
