import React, {useEffect} from 'react';
import {Route} from 'react-router-dom';
import {setLayoutState} from '../../models/layout';
import {CustomRoutesPropsReact} from '../../types';
import RouterWatcher from './RouterWatcher';

const RegularRoute: React.FC<
  React.PropsWithChildren<CustomRoutesPropsReact>
> = ({component: Component, page, children, title, ...rest}) => {
  useEffect(() => {
    setLayoutState({pageClass: page});
  }, [page]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <RouterWatcher
            path={props.match.path}
            url={props.match.url}
            title={title}
          >
            {Component ? <Component {...props} /> : children}
          </RouterWatcher>
        );
      }}
    />
  );
};

export default RegularRoute;
