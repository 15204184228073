import {createEffect, createStore} from 'effector';
import getServerStorageKeys, {
  setServerStorageValue,
} from '../../api/serverStorage';
import HINTS_TYPES from '../../constants/hints';
import SeoSource from '../../constants/seo';
import logger from '../../logger';
import {
  BlackCardFormSavedDeliveryAddress,
  BlackCardFormSavedDeliveryAddresses,
  CurrencyRequestFormParamsItem,
  HINT_EVENT_TYPE,
  ServerStorageData,
  ServerStorageFiltersPresets,
  SystemNotificationDialogsConfig,
  UUID4,
} from '../../types';
import SYSTEM_NOTIFICATION_DIALOG_TYPES from '../../types/systemDialogNotifications';
import ObjectKeys from '../../utils/object';
import {isValidUUIDv4} from '../../utils/uuid';
import clearAppStores from '../clearStore';
import CurrentClientStore from '../currentClient';
import LAYOUT_TYPES from '../../constants/layout';
import {ProductStore} from '../product';

const log = logger.module('ServerStorage');

export enum ServerStoragePrefixes {
  Hint = 'hint_',
  Event = 'event_',
  FiltersPresets = 'filters_presets_',
}

export type SetEventStateProps = {
  event: HINT_EVENT_TYPE;
  state: boolean;
};

export type SetHintStateProps = {
  hint: HINTS_TYPES;
  state: boolean;
};

export type ServerStorage = ServerStorageFiltersPresets & {
  loaded: boolean;
  hints?: Record<HINTS_TYPES, boolean>;
  events?: Record<HINT_EVENT_TYPE, boolean>;
  verification_got_it?: Record<UUID4, boolean>;
  saved_currency_request?: Record<UUID4, CurrencyRequestFormParamsItem[]>;
  last_profile_id?: UUID4;
  seo_source?: SeoSource;
  new_features?: string;
  system_notification_dialogs_config?: Record<
    UUID4,
    SystemNotificationDialogsConfig
  >;
  layout_dashboard_type?: LAYOUT_TYPES;
  hidden_product_info?: Record<string, boolean>;
};

const INIT_KEYS: string[] = [
  'verification_got_it',
  'saved_currency_request',
  'last_profile_id',
  'seo_source',
  'new_features',
  'system_notification_dialogs_config',
  'layout_dashboard_type',
  'hidden_product_info',
];

const INIT_PREFIXES: string[] = [
  ServerStoragePrefixes.Hint,
  ServerStoragePrefixes.Event,
];

const ServerStorageStore = createStore<ServerStorage>({loaded: false});

ServerStorageStore.reset(clearAppStores);

export const initServerStorage = createEffect<void, ServerStorageData, Error>({
  name: 'InitServerStorage',
  handler: async (): Promise<ServerStorageData> => {
    return getServerStorageKeys(INIT_KEYS, INIT_PREFIXES);
  },
});

const makeDefaultConfig = (): SystemNotificationDialogsConfig => {
  return {
    [SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM]: {
      show_count: 0,
      last_show: 0,
      full_show: false,
    },
    [SYSTEM_NOTIFICATION_DIALOG_TYPES.WELCOME_SCREEN]: {
      show: false,
    },
    [SYSTEM_NOTIFICATION_DIALOG_TYPES.VERIFICATION_3DS]: {
      show: false,
    },
  };
};

const formatSystemNotificationDialogsConfig = (
  data: Record<UUID4, SystemNotificationDialogsConfig> | undefined
): Record<UUID4, SystemNotificationDialogsConfig> => {
  const ret: Record<UUID4, SystemNotificationDialogsConfig> = {};

  if (data && typeof data === 'object') {
    ObjectKeys(data).forEach((clientId) => {
      ret[clientId] = makeDefaultConfig();
      const clientData: SystemNotificationDialogsConfig = data[clientId];
      const globalData: SystemNotificationDialogsConfig = data._;

      if (clientData[SYSTEM_NOTIFICATION_DIALOG_TYPES.WELCOME_SCREEN]) {
        ret[clientId][SYSTEM_NOTIFICATION_DIALOG_TYPES.WELCOME_SCREEN] = {
          show:
            clientData[SYSTEM_NOTIFICATION_DIALOG_TYPES.WELCOME_SCREEN]
              ?.show === true,
        };
      }

      if (
        globalData &&
        globalData[SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM]
      ) {
        const showCount =
          globalData[SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM]
            ?.show_count;
        const lastShow =
          globalData[SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM]
            ?.last_show;
        const fullShow =
          globalData[SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM]
            ?.full_show;

        ret[clientId][SYSTEM_NOTIFICATION_DIALOG_TYPES.SALARY_PROGRAM] = {
          show_count: typeof showCount === 'number' ? showCount : 0,
          last_show: typeof lastShow === 'number' ? lastShow : 0,
          full_show: typeof fullShow === 'boolean' ? fullShow : false,
        };
      }

      if (globalData && globalData[SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL]) {
        const show =
          globalData[SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL]?.show;

        ret[clientId][SYSTEM_NOTIFICATION_DIALOG_TYPES.TUTORIAL] = {
          show: typeof show === 'boolean' ? show : false,
        };
      }

      if (
        globalData &&
        globalData[SYSTEM_NOTIFICATION_DIALOG_TYPES.VERIFICATION_3DS]
      ) {
        const show =
          globalData[SYSTEM_NOTIFICATION_DIALOG_TYPES.VERIFICATION_3DS]?.show;

        ret[clientId][SYSTEM_NOTIFICATION_DIALOG_TYPES.VERIFICATION_3DS] = {
          show: typeof show === 'boolean' ? show : false,
        };
      }
    });
  }
  return ret;
};

ServerStorageStore.on(initServerStorage.doneData, (State, data) => {
  const ret: ServerStorage = {
    loaded: true,
    verification_got_it:
      typeof data.verification_got_it === 'object'
        ? (data.verification_got_it as Record<UUID4, boolean>)
        : {},
    saved_currency_request:
      typeof data.saved_currency_request === 'object'
        ? (data.saved_currency_request as Record<
            UUID4,
            CurrencyRequestFormParamsItem[]
          >)
        : {},
    last_profile_id:
      typeof data.last_profile_id === 'string'
        ? data.last_profile_id
        : undefined,
    seo_source:
      typeof data.seo_source === 'string'
        ? (data.seo_source as SeoSource)
        : undefined,
    new_features:
      typeof data.new_features === 'string' ? data.new_features : '',
    hints: ObjectKeys(HINTS_TYPES).reduce<Record<HINTS_TYPES, boolean>>(
      (obj, key) => {
        return {...obj, [HINTS_TYPES[key]]: data[HINTS_TYPES[key]] === true};
      },
      {} as Record<HINTS_TYPES, boolean>
    ),
    events: {
      [HINT_EVENT_TYPE.EVENT_CREATE_CARD]:
        data[HINT_EVENT_TYPE.EVENT_CREATE_CARD] === true,
      [HINT_EVENT_TYPE.EVENT_RECEIPT_REMINDERS]:
        data[HINT_EVENT_TYPE.EVENT_RECEIPT_REMINDERS] === true,
    },
    system_notification_dialogs_config: formatSystemNotificationDialogsConfig(
      data.system_notification_dialogs_config as Record<
        UUID4,
        SystemNotificationDialogsConfig
      >
    ),
    layout_dashboard_type: data.layout_dashboard_type as LAYOUT_TYPES,
    hidden_product_info: data.hidden_product_info as Record<string, boolean>,
  };

  return {...State, ...ret};
});

export const setEventState = createEffect<
  SetEventStateProps,
  ServerStorageData,
  Error
>({
  name: 'setEventState',
  handler: async ({event, state}): Promise<ServerStorageData> => {
    return setServerStorageValue({[event]: state});
  },
});

export const setHintState = createEffect<
  SetHintStateProps,
  ServerStorageData,
  Error
>({
  name: 'setHintState',
  handler: async ({hint, state}): Promise<ServerStorageData> => {
    // return setServerStorageValue({[hint]: state});
    setServerStorageValue({[hint]: state}).catch((e) => {
      log.error('Update server storage value fail', {
        data: {[hint]: state},
        exception: e,
      });
    });
    return {[hint]: state};
  },
});

export const setVerificationGotItState = createEffect<
  boolean,
  ServerStorageData,
  Error
>({
  name: 'setVerificationGotItState',
  handler: async (state): Promise<ServerStorageData> => {
    const currentServerData = await getServerStorageKeys(INIT_KEYS);
    let currentData: Record<UUID4, boolean> = {};
    if (typeof currentServerData.verification_got_it === 'object') {
      currentData = currentServerData.verification_got_it as Record<
        UUID4,
        boolean
      >;
    }
    const clientId = CurrentClientStore.getState()?.id;
    if (clientId) {
      currentData[clientId] = state;
    }
    return setServerStorageValue({verification_got_it: currentData});
  },
});

export const setLastCurrencyRequestState = createEffect<
  CurrencyRequestFormParamsItem[],
  ServerStorageData,
  Error
>({
  name: 'setLastCurrencyRequestState',
  handler: async (items): Promise<ServerStorageData> => {
    const currentServerData = await getServerStorageKeys(INIT_KEYS);
    let currentData: Record<UUID4, CurrencyRequestFormParamsItem[]> = {};
    if (typeof currentServerData.saved_currency_request === 'object') {
      currentData = currentServerData.saved_currency_request as Record<
        UUID4,
        CurrencyRequestFormParamsItem[]
      >;
    }
    const clientId = CurrentClientStore.getState()?.id;
    if (clientId) {
      currentData[clientId] = items.map((item) => {
        return {
          ...item,
          label: '',
        };
      });
    }
    return setServerStorageValue({saved_currency_request: currentData});
  },
});

export const setSystemNotificationsDialogState = createEffect<
  {config: SystemNotificationDialogsConfig; isCommon?: boolean},
  ServerStorageData,
  Error
>({
  name: 'setSystemNotificationsDialogState',
  handler: async ({config: state, isCommon}): Promise<ServerStorageData> => {
    const currentServerData = await getServerStorageKeys([
      'system_notification_dialogs_config',
    ]);
    const currentData = formatSystemNotificationDialogsConfig(
      currentServerData.system_notification_dialogs_config as Record<
        UUID4,
        SystemNotificationDialogsConfig
      >
    );

    const clientId = isCommon ? '_' : CurrentClientStore.getState()?.id;
    if (clientId) {
      if (currentData[clientId]) {
        currentData[clientId] = {
          ...currentData[clientId],
          ...state,
        };
      } else {
        currentData[clientId] = state;
      }
    }

    // console.log(currentData);
    return setServerStorageValue({
      system_notification_dialogs_config: currentData,
    });
  },
});

export const setLastProfileId = createEffect<UUID4, ServerStorageData, Error>({
  name: 'setLastProfileId',
  handler: async (id): Promise<ServerStorageData> => {
    return setServerStorageValue({last_profile_id: id});
  },
});

export const setSeoSource = createEffect<SeoSource, ServerStorageData, Error>({
  name: 'setSeoSource',
  handler: async (seoSource): Promise<ServerStorageData> => {
    return setServerStorageValue({seo_source: seoSource});
  },
});

export const setNewFeatures = createEffect<string, ServerStorageData, Error>({
  name: 'setNewFeatures',
  handler: async (data): Promise<ServerStorageData> => {
    return setServerStorageValue({new_features: data});
  },
});

export const setLayoutDashboardType = createEffect<
  string,
  ServerStorageData,
  Error
>({
  name: 'setLayoutDashboardType',
  handler: async (data): Promise<ServerStorageData> => {
    return setServerStorageValue({layout_dashboard_type: data});
  },
});

export const setProductInfoVisibility = createEffect<
  boolean,
  ServerStorageData,
  Error
>({
  name: 'setProductInfoVisibility',
  handler: async (data): Promise<ServerStorageData> => {
    return setServerStorageValue({
      hidden_product_info: {
        ...ServerStorageStore.getState().hidden_product_info,
        [`${ProductStore.getState()?.id}`]: data || undefined,
      },
    });
  },
});

export const clearStorage = createEffect<void, ServerStorageData, Error>({
  name: 'clearStorage',
  handler: async (): Promise<ServerStorageData> => {
    return setServerStorageValue({
      event_create_card: false,
      event_receipt_reminders: false,
      hint_event_create_card: false,
      hint_event_receipt_reminders: false,
      hint_init_create_cards: false,
      hint_init_create_users: false,
      hint_init_make_payments: false,
      hint_init_switch_products: false,
      hint_init_top_up: false,
      hint_feature_export_statements: false,
      layout_dashboard_type: '',
      new_features: '',
      system_notification_dialogs_config: {},
    });
  },
});

ServerStorageStore.on(setEventState.doneData, (State, data) => {
  const newEventStates: Record<HINT_EVENT_TYPE, boolean> = {
    [HINT_EVENT_TYPE.EVENT_CREATE_CARD]:
      State.events?.event_create_card === true,
    [HINT_EVENT_TYPE.EVENT_RECEIPT_REMINDERS]:
      State.events?.event_receipt_reminders === true,
  };

  if (data.event_create_card !== undefined) {
    newEventStates.event_create_card = data.event_create_card === true;
  }

  if (data.event_receipt_reminders !== undefined) {
    newEventStates.event_receipt_reminders =
      data.event_receipt_reminders === true;
  }

  return {
    ...State,
    events: {
      ...State.events,
      ...newEventStates,
    },
  };
});

ServerStorageStore.on(setHintState.doneData, (State, data) => {
  const newHintStates: Record<HINTS_TYPES, boolean> = ObjectKeys(
    HINTS_TYPES
  ).reduce<Record<HINTS_TYPES, boolean>>((obj, key) => {
    return {...obj, [HINTS_TYPES[key]]: (State.hints || {})[HINTS_TYPES[key]]};
  }, {} as Record<HINTS_TYPES, boolean>);

  ObjectKeys(newHintStates).forEach((key) => {
    if (data[key] !== undefined) {
      newHintStates[key] = data[key] === true;
    }
  });

  return {
    ...State,
    hints: {
      ...State.hints,
      ...newHintStates,
    },
  };
});

ServerStorageStore.on(setVerificationGotItState.doneData, (State, data) => {
  return {
    ...State,
    verification_got_it: data.verification_got_it as Record<UUID4, boolean>,
  };
});

ServerStorageStore.on(setLastCurrencyRequestState.doneData, (State, data) => {
  return {
    ...State,
    saved_currency_request: data.saved_currency_request as Record<
      UUID4,
      CurrencyRequestFormParamsItem[]
    >,
  };
});

ServerStorageStore.on(
  setSystemNotificationsDialogState.doneData,
  (State, data) => {
    return {
      ...State,
      system_notification_dialogs_config: data.system_notification_dialogs_config as Record<
        UUID4,
        SystemNotificationDialogsConfig
      >,
    };
  }
);

ServerStorageStore.on(setLastProfileId.doneData, (State, data) => {
  return {
    ...State,
    last_profile_id: data.last_profile_id as UUID4,
  };
});

ServerStorageStore.on(setSeoSource.doneData, (State, data) => {
  return {
    ...State,
    seo_source: data.seo_source as SeoSource,
  };
});

ServerStorageStore.on(setNewFeatures.doneData, (State, data) => {
  return {
    ...State,
    new_features: data.new_features as string,
  };
});

ServerStorageStore.on(setLayoutDashboardType.doneData, (State, data) => {
  return {
    ...State,
    layout_dashboard_type: data.layout_dashboard_type as LAYOUT_TYPES,
  };
});

ServerStorageStore.on(setProductInfoVisibility.doneData, (State, data) => {
  return {
    ...State,
    hidden_product_info: data.hidden_product_info as Record<string, boolean>,
  };
});

export const getSavedDeliveryData = createEffect<
  void,
  BlackCardFormSavedDeliveryAddresses,
  Error
>({
  name: 'getSavedDeliveryData',
  handler: async (): Promise<BlackCardFormSavedDeliveryAddresses> => {
    const ret: BlackCardFormSavedDeliveryAddresses = {};

    const savedData: BlackCardFormSavedDeliveryAddresses = ((
      await getServerStorageKeys(['saved_delivery_address'])
    ).saved_delivery_address || {}) as BlackCardFormSavedDeliveryAddresses;

    ObjectKeys(savedData).forEach((productId) => {
      const deliveryInfo = savedData[productId];
      if (isValidUUIDv4(productId) && typeof deliveryInfo === 'object') {
        ret[productId] = deliveryInfo;
      }
    });

    return ret;
  },
});

export const getSystemNotificationsDialogData = createEffect<
  boolean | undefined,
  SystemNotificationDialogsConfig,
  Error
>({
  name: 'getSystemNotificationsDialogData',
  handler: async (isCommon): Promise<SystemNotificationDialogsConfig> => {
    const currentServerData = await getServerStorageKeys([
      'system_notification_dialogs_config',
    ]);
    const currentData = formatSystemNotificationDialogsConfig(
      currentServerData.system_notification_dialogs_config as Record<
        UUID4,
        SystemNotificationDialogsConfig
      >
    );
    const clientId = isCommon ? '_' : CurrentClientStore.getState()?.id;
    if (clientId) {
      return currentData[clientId] || makeDefaultConfig();
    }
    return makeDefaultConfig();
  },
});

export const updateSavedDeliveryData = createEffect<
  {productId: UUID4; data: BlackCardFormSavedDeliveryAddress},
  boolean,
  Error
>({
  name: 'updateSavedDeliveryData',
  handler: async ({productId, data}): Promise<boolean> => {
    const currentData = await getSavedDeliveryData();

    setServerStorageValue({
      saved_delivery_address: {
        ...currentData,
        [productId]: {
          ...data,
        },
      },
    });
    return true;
  },
});

export default ServerStorageStore;
