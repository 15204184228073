import {useStoreMap} from 'effector-react';
import {App$} from '../models/app';

const useIsDemo = (): boolean => {
  return useStoreMap({
    store: App$,
    keys: ['config'],
    fn: (store$) => store$.config.demo,
  });
};

export default useIsDemo;
