import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import {
  ICard,
  IUserListRequestParams,
  UUID4,
  GetBlackProfilesResponse,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const getProfilesList = async ({
  currentPage,
  pageSize,
  orderField,
  orderDirection,
}: IUserListRequestParams): Promise<GetBlackProfilesResponse> => {
  let orderParams: string[] | null = null;
  if (orderField === 'last_name') {
    orderParams = ['first_name', 'last_name'];
  } else if (orderField) {
    orderParams = [orderField];
  }
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(
        Endpoints.getProfiles,
        undefined,
        {
          from_record: (currentPage - 1) * pageSize,
          records_count: pageSize,
          order_field: orderParams || '',
          order_direction: orderDirection || '',
        },
        true
      ),
      {noCache: true}
    );

  return {
    items: response.data.users || [],
    totalItems: response.data.total_records_number,
  };
};

export const getCardUsersList = async (
  cardId: UUID4,
  {currentPage, pageSize, orderField, orderDirection}: IUserListRequestParams
): Promise<GetBlackProfilesResponse> => {
  let orderParams: string[] | null = null;
  if (orderField === 'last_name') {
    orderParams = ['first_name', 'last_name'];
  } else if (orderField) {
    orderParams = [orderField];
  }
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(
        Endpoints.getCardUsers,
        {card_id: cardId},
        {
          from_record: (currentPage - 1) * pageSize,
          records_count: pageSize,
          order_field: orderParams || '',
          order_direction: orderDirection || '',
        },
        true
      )
    );
  return {
    items: response.data.users || [],
    totalItems: response.data.total_records_number,
  };
};

export const deleteProfiles = async ({
  userIds,
  deleteApiKeys,
}: {
  userIds: UUID4[];
  deleteApiKeys?: boolean;
}): Promise<boolean> => {
  const response: AxiosResponse<'ok'> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .delete(
      getApiPathParamsMaker(
        Endpoints.deleteProfilesBatch,
        {},
        {user_ids: userIds, delete_api_keys: Boolean(deleteApiKeys)},
        true
      )
    );
  return response.data === 'ok';
};

export const deleteCardUsers = async (
  card: ICard,
  userIds: UUID4[]
): Promise<boolean> => {
  const response: AxiosResponse<'ok'> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .delete(
      getApiPathParamsMaker(
        Endpoints.deleteCardUsersBatch,
        {card_id: card.id},
        {user_ids: userIds},
        true
      )
    );
  return response.data === 'ok';
};

export default getProfilesList;
