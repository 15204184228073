import moment from 'moment-timezone';
import DEMO_STATEMENT_FILE, {
  DEMO_STATEMENT_FILE_CSV,
  DEMO_STATEMENT_FILE_XLSX,
  DEMO_STATEMENT_FILE_XML,
} from '../../constants/demo/statement';
import Demo, {DemoApiHandler} from '../demo';
import {LanguageCode3l, TransactionDownloadFormat} from '../../types';

const getAccountStatementFile: DemoApiHandler = ({config, instance, url}) => {
  const params = url.queryParams as {
    from_date: string;
    to_date: string;
    statement_file_type: TransactionDownloadFormat;
    language_code: LanguageCode3l;
  };
  const {user} = instance.getData();
  let ext: string | undefined;
  let mimeType: string | undefined;
  let body = 'application/pdf';

  switch (params.statement_file_type) {
    case 'pdf':
      ext = 'pdf';
      mimeType = 'application/pdf';
      body = DEMO_STATEMENT_FILE;
      break;

    case 'excel':
      ext = 'pdf';
      mimeType = 'application/pdf';
      body = DEMO_STATEMENT_FILE_XLSX;
      break;

    case 'iso20022.053.001.02':
      ext = 'pdf';
      mimeType = 'application/pdf';
      body = DEMO_STATEMENT_FILE_XML;
      break;

    case 'csv':
      ext = 'pdf';
      mimeType = 'application/pdf';
      body = DEMO_STATEMENT_FILE_CSV;
      break;

    default:
      ext = 'pdf';
      mimeType = 'application/pdf';
      body = DEMO_STATEMENT_FILE;
      break;
  }

  return Demo.makeDataResponse<{
    file: {
      name: string;
      mime_type: string;
      body: string;
    };
  }>(config, {
    file: {
      name: `statement_${moment(params?.from_date as string).format(
        'YYYY-MM-DD'
      )}-${moment(params?.to_date as string).format(
        'YYYY-MM-DD'
      )}(GMT ${moment().tz(user.timezone_name).format('Z')}).${ext}`,
      mime_type: mimeType || '',
      body,
    },
  });
};

export default getAccountStatementFile;
