import {UUID4} from './common';
import {IPermission} from './permission';
import {IUser} from './users';

export enum ERoleTypes {
  GLOBAL = 'global',
  PRODUCT = 'product',
}

export interface IRole {
  client_id?: UUID4;
  name: string;
  description?: string;
  restricted: boolean;
  product_based: boolean;
  client_name?: string;
  created_at?: string;
  created_by?: UUID4;
  deleted_at?: string;
  deleted_by?: UUID4;
  updated_at?: string;
  updated_by?: UUID4;
  id?: UUID4;
  permissions?: IPermission[];
  users?: IUser[];
}

export interface IRoleListMap {
  [key: string]: IRole[];
}

export interface IRoleList {
  items: IRole[];
  totalItems?: number;
  pageSize?: number;
  currentPage?: number;
  orderField?: string;
  orderDirection?: string;
}

export interface IRolesListRequest {
  role_type?: ERoleTypes;
  currentPage: number;
  pageSize: number;
  orderField?: string;
  orderDirection?: string;
  product_type?: 'WBLACK';
}

export interface IFormEditRole {
  name: string;
  description: string;
  restricted: boolean;
  product_based: boolean;
}

export type IFormEditRoleRequest = {
  name: string;
  description: string;
  restricted: boolean;
  product_based: boolean;
};

export interface IEditRoleParams extends IFormEditRole {
  selectedPermissions: string[];
}

export interface IUpdateRole {
  id: UUID4;
  role: IFormEditRole;
  permissions: number[];
}
