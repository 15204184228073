import React from 'react';
import {Redirect as RouterRedirect, RedirectProps} from 'react-router';
import useUrl from '../../customHooks/useUrl';

const Redirect: React.FC<React.PropsWithChildren<RedirectProps>> = ({
  to,
  ...props
}) => {
  const url = useUrl(to);
  return <RouterRedirect to={url} {...props} />;
};

export default Redirect;
