import {useStoreMap} from 'effector-react';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';
import TutorialStore, {
  confirmFinish,
  nextStep,
  showTutorialStep,
  ShowTutorialStepParams,
} from '../../../models/tutorial';
import TutorialCongratulationsModal from '../TutorialCongratulationsModal';
import TutorialModal from '../TutorialModal';
import css from './TutorialLayer.module.less';

const getElementParams = (el?: HTMLElement | null) => {
  const rect = el?.getBoundingClientRect();
  const width = el ? el.offsetWidth : 0;
  const height = el ? el.offsetHeight : 0;
  const left = rect ? rect.left : 0;
  const top = rect ? rect.top : 0;

  return {
    width,
    height,
    left,
    top,
  };
};

const TutorialLayer: React.FC = () => {
  const [isVisibleElements, setIsVisibleElements] = useState(false);
  const [config, setConfig] = useState<ShowTutorialStepParams | null>(null);
  const {target} = config || {};
  const [targetParams, setTargetParams] = useState(getElementParams(target));

  const {active, steps, finishStep, prepared} = useStoreMap({
    store: TutorialStore,
    keys: ['active', 'steps', 'finishStep', 'prepared'],
    fn: (state) => {
      return {
        active: state.active,
        steps: state.steps,
        finishStep: state.finishStep,
        prepared: state.prepared,
      };
    },
  });

  const recalculateTargetParams = useCallback(() => {
    setTargetParams(getElementParams(target));
  }, [target]);

  const activeStep = useMemo(() => {
    return steps.find((s) => s.id === config?.step) || null;
  }, [config?.step, steps]);

  const targetOverlay = useMemo(() => {
    const {width, height, left, top} = targetParams;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        className={css.layer__targetOverlay}
      >
        <defs>
          <mask id="tutorialTargetOverlayMask">
            <rect
              width="300%"
              height="300%"
              x="-100%"
              y="-100%"
              fill="rgba(255,255,255,.6)"
            />

            <rect
              width={width}
              height={height}
              x={left}
              y={top}
              rx="16px"
              fill="#000"
              className={css.layer__targetOverlayRect}
            />
          </mask>
        </defs>

        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="rgb(11, 21, 46)"
          mask="url(#tutorialTargetOverlayMask)"
        />
      </svg>
    );
  }, [targetParams]);

  const modal = useMemo<React.ReactNode>(() => {
    if (active && activeStep && config?.target) {
      return (
        <TutorialModal
          config={config}
          handleBeforeNext={() => {
            setIsVisibleElements(false);
          }}
          handleAfterNext={() => {
            nextStep();
          }}
        />
      );
    }

    return null;
  }, [active, activeStep, config]);

  useEffect(() => {
    setTargetParams(getElementParams(target));
  }, [target]);

  useEffect(() => {
    window.addEventListener('resize', recalculateTargetParams);

    const observer = new MutationObserver(recalculateTargetParams);

    if (observer) {
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener('resize', recalculateTargetParams);

      if (observer) {
        observer.disconnect();
      }
    };
  }, [recalculateTargetParams]);

  useEffect(() => {
    if (active && prepared) {
      setIsVisibleElements(true);
    }
  }, [active, prepared]);

  useLayoutEffect(() => {
    const unwatch = showTutorialStep.done.watch(({params: newConfig}) => {
      setConfig(newConfig);
    });

    return () => {
      unwatch();
    };
  }, []);

  return (
    <>
      <div
        className={clsx(
          css.layer,
          active ? css.layer_active : '',
          isVisibleElements ? css.layer_visibleElements : ''
        )}
      >
        {targetOverlay}

        {modal}
      </div>

      <TutorialCongratulationsModal
        visible={finishStep}
        onOk={() => {
          confirmFinish();
        }}
        onCancel={() => {
          confirmFinish();
        }}
      />
    </>
  );
};

export default TutorialLayer;
