import {useStoreMap} from 'effector-react';
import React, {useCallback, useEffect} from 'react';
import Messenger from '../../../components/Messenger/Messenger';
import useConfig from '../../../customHooks/useConfig';
import useIsGuest from '../../../customHooks/useIsGuest';
import {CurrentLocaleStore} from '../../../models/i18n';
import {
  setMessengerInitState,
  setMessengerUnreadMessages,
  setMessengerVisibleState,
} from '../../../models/messenger';
import {setMessengerLocale} from '../../../utils/messenger';

const MessengerManager: React.FC = () => {
  const isGuest = useIsGuest();
  const {messengerDisabled, demo} = useConfig();
  const locale = useStoreMap({
    store: CurrentLocaleStore,
    keys: ['locale'],
    fn: (state) => state.locale,
  });

  useEffect(() => {
    if (!isGuest) {
      // hideMessengerWindow();
      // messengerLogout();
    }
  }, [isGuest]);

  useEffect(() => {
    setMessengerLocale(locale);
  }, [locale]);

  const onLoad = useCallback(() => {
    setMessengerInitState(true);
  }, []);

  const onMaximize = useCallback(() => {
    setMessengerVisibleState(true);
  }, []);

  const onMinimize = useCallback(() => {
    setMessengerVisibleState(false);
  }, []);

  const onMessagesChange = useCallback((data: number) => {
    setMessengerUnreadMessages(data);
  }, []);

  if (messengerDisabled || demo) {
    return null;
  }

  return (
    <Messenger
      onLoad={onLoad}
      onShow={onMaximize}
      onHide={onMinimize}
      onMessagesChange={onMessagesChange}
    />
  );
};

export default MessengerManager;
