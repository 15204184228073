import moment from 'moment-timezone';
import endpoints from '../api/endpoints';
import {
  AccountLimitsWithUsage,
  ActivityType,
  Card3DSecurityType,
  CardDispatchMethodInfo,
  ClientCompany,
  ClientPerson,
  CompanyLimitsWithUsage,
  CorporateDocument,
  CurrencyRequestConfig,
  CurrencyType,
  Employee,
  IAccount,
  IApiKey,
  IClient,
  ICompany,
  IProductBase,
  IProfile,
  IRole,
  LimitUsageInfo,
  MassPaymentsFile,
  MassPaymentsPaymentWithFileId,
  PricingPlans,
  ProductPricingPlan,
  ProductSettings,
  ReservedSettlementBalance,
  ServerStorageData,
  SettlementPaymentDetails,
  SystemUser,
  TokenInfo,
  TypedMap,
  UUID4,
  VerificationStatus,
} from '../types';
import DemoData, {IAccountStatementExt, ICardExt} from '../types/demo';
import PaymentDocumentFile from '../types/payment';
import TreatmentsWithConfig from '../types/split-io';
import {TopUpHistoryItem} from '../types/statement';
import Endpoints from './endpoints';
import Features, {TreatmentStatus} from './features';
import {getMaskedCardNumberById} from '../utils/demo/getCard';

export const DEMO_USER_ID = 'c6f19e43-ffe2-473b-9bcf-4402b7568f16';
export const DEMO_CLIENT_ID = '39384c84-a286-48cb-8eb1-414e67ca9206';
export const DEMO_PRODUCT_ID = '4c83fbb0-f221-4c6c-9900-47c6102df256';
export const DEMO_PERSON_ID = '5eca13a1-0c76-495f-ae7d-d715046f05ac';
export const DEMO_PROFILE_ID = 'f16fa048-9e33-48ed-8089-bc3275cdec64';
export const DEMO_MAIN_ACCOUNT_ID = '5060f262-0ade-45c4-8aee-5fae6f8e1a45';
export const DEMO_COMPANY_ID = 'd600517c-6790-4284-8d5a-d36ff733c887';

export const DEMO_CURRENCIES_PROFILES: TypedMap<CurrencyType, UUID4> = {
  EUR: DEMO_PROFILE_ID,
  USD: '9e684dd6-9c7b-468e-b34d-4816042b0bbe',
  GBP: '319303d3-786c-4bbf-bbd9-9fc80737df49',
  SEK: 'a03e69c8-f315-46a9-bc83-237c64113d6a',
};

export const DEMO_CURRENCIES_PRODUCTS: TypedMap<CurrencyType, UUID4> = {
  EUR: DEMO_PRODUCT_ID,
  USD: '723339ff-36e3-4e7d-88a0-27ec8fbd6f55',
  GBP: '16687025-d0d5-4151-89e1-1569233fdd79',
  SEK: 'ffcf192a-6476-4ab5-b338-ca99bf7feea6',
};
// EGOR R
// EGOR O
// RUS
const DEMO_VERSION = '4';

// KUSH
const DEMO_USER: SystemUser = {
  first_name: 'John',
  last_name: 'Doe',
  email: 'johndoe@doe.com',
  mobile: '+19013453453',
  locale: 'en',
  timezone_name: 'Europe/Helsinki',
  id: DEMO_USER_ID,
  client_id: DEMO_CLIENT_ID,
  created_at: '2022-12-12T13:27:47Z',
  updated_at: '2024-01-25T11:53:33Z',
  last_logon_time: '2024-01-25T11:53:29Z',
  permissions: [
    1000,
    1001,
    1002,
    1003,
    1004,
    1005,
    1006,
    1404,
    1423,
    1700,
    1701,
    1702,
    3000,
    3001,
    4000,
    4001,
    4002,
    4004,
    4005,
    4006,
  ],
  profile_image: '',
  profile_background_color: '#E84040',
  roles: {
    global: [],
    product: {
      [DEMO_PRODUCT_ID]: [
        {
          name: 'Admin',
          description:
            'WB Client Portal role. It contains all allowed WBLACK permissions',
          restricted: false,
          product_based: true,
          id: '0ac76328-739f-4dc8-bfde-251e6613c910',
          created_at: '2023-07-26T10:06:13Z',
          created_by: 'system',
        },
        {
          name: 'ProductAdmin',
          description: 'Can do everything with specified product',
          restricted: false,
          product_based: true,
          id: '78dde75b-3381-42c8-becc-993311858360',
          created_at: '2018-07-23T12:11:07Z',
          created_by: 'system',
          updated_at: '2023-10-13T13:07:44Z',
          updated_by: '4755180e-9d3f-4a5a-994c-3b3874b18054',
        },
      ],
    },
  },
  product_code: 'WB-CLIENT-SALARY',
  product_id: DEMO_PRODUCT_ID,
  person_id: DEMO_PERSON_ID,
  profiles: [
    {
      id: DEMO_PROFILE_ID,
      name: 'LEMINATECH INNOVATIONS LTD. WB-LEMINATECHINNOVATIONSLTD.-107137',
      target_entity_name: 'product',
      target_entity_id: DEMO_PRODUCT_ID,
      product_code: 'WB-LEMINATECHINNOVATIONSLTD.-107137',
      product_id: DEMO_PRODUCT_ID,
      roles: [
        {
          name: 'Admin',
          description:
            'WB Client Portal role. It contains all allowed WBLACK permissions',
          restricted: false,
          product_based: true,
          id: '0ac76328-739f-4dc8-bfde-251e6613c910',
          created_at: '2023-07-26T10:06:13Z',
          created_by: 'system',
        },
        {
          name: 'ProductAdmin',
          description: 'Can do everything with specified product',
          restricted: false,
          product_based: true,
          id: '78dde75b-3381-42c8-becc-993311858360',
          created_at: '2018-07-23T12:11:07Z',
          created_by: 'system',
          updated_at: '2023-10-13T13:07:44Z',
          updated_by: '4755180e-9d3f-4a5a-994c-3b3874b18054',
        },
      ],
      permissions: [
        1100,
        1101,
        1102,
        1103,
        1104,
        1105,
        1300,
        1301,
        1302,
        1303,
        1304,
        1307,
        1308,
        1309,
        1310,
        1311,
        1312,
        1313,
        1314,
        1315,
        1316,
        1317,
        1400,
        1401,
        1402,
        1403,
        1404,
        1405,
        1407,
        1408,
        1409,
        1410,
        1411,
        1412,
        1414,
        1415,
        1416,
        1420,
        1421,
        1422,
        1423,
        1424,
        1425,
        1426,
        1427,
        1429,
        1430,
        1431,
        1432,
        1433,
        1434,
        1435,
        1436,
        1437,
        1438,
        1439,
        1440,
        1441,
        1600,
        1800,
        1801,
        1802,
        1900,
        2000,
        2001,
        2002,
        2003,
        3100,
        3101,
        3102,
        3103,
        4001,
        4003,
        4100,
        4200,
        4201,
        4202,
        4203,
        4204,
        4205,
        4206,
        4207,
        4208,
        4209,
        4210,
        4211,
        4212,
        4213,
        4214,
        4215,
        4300,
        4301,
        4302,
        4303,
        4304,
        4400,
        4401,
        4402,
        4403,
        4404,
        4406,
        4407,
        4408,
        4409,
        4410,
        4411,
        4412,
        4413,
        4414,
        4415,
        4416,
        4417,
        4418,
        4419,
        4420,
        4421,
        4422,
        4423,
        4424,
        4425,
        4426,
        4427,
        4428,
        4429,
        4430,
        4431,
        4432,
        4433,
        4434,
        4435,
        4436,
        4437,
        4438,
        4439,
        4448,
      ],
    },
  ],
};

const DEMO_TOKEN_INFO: TokenInfo = {
  token: '',
  profile_id: DEMO_PROFILE_ID,
  product_id: DEMO_PRODUCT_ID,
  refresh_token: '',
  refresh_token_expire_ts: 0,
  sessionStorage: {},
};

const DEMO_ASSIGNED_PRODUCT: IProductBase[] = [
  {
    code: 'WB-LEMINATECHINNOVATIONSLTD.-107137(EUR)',
    description: 'LEMINATECH INNOVATIONS LTD. product(EUR)',
    id: DEMO_PRODUCT_ID,
    client_id: DEMO_CLIENT_ID,
    client_name: 'LEMINATECH INNOVATIONS LTD.(EUR)',
    product_type: 'WBLACK',
    pricing_plan_type_id: 'Free',
    pricing_plan_subscription_started_at: '2024-01-23',
    pricing_plan_subscription_end_at: '2024-02-23',
    pricing_plan_selected_at: '2024-01-23T10:35:55Z',
    next_pricing_plan_type: 'Free',
    created_at: '2024-01-23T10:13:19Z',
    is_frozen: false,
    currency_code: 'EUR',
  },
  {
    code: 'WB-LEMINATECHINNOVATIONSLTD.-107138(USD)',
    description: 'LEMINATECH INNOVATIONS LTD. product(USD)',
    id: DEMO_CURRENCIES_PRODUCTS.USD || DEMO_PRODUCT_ID,
    client_id: DEMO_CLIENT_ID,
    client_name: 'LEMINATECH INNOVATIONS LTD.(USD)',
    product_type: 'WBLACK',
    pricing_plan_type_id: 'Free',
    pricing_plan_subscription_started_at: '2024-01-23',
    pricing_plan_subscription_end_at: '2024-02-23',
    pricing_plan_selected_at: '2024-01-23T10:35:55Z',
    next_pricing_plan_type: 'Free',
    created_at: '2024-01-23T10:13:19Z',
    is_frozen: false,
    currency_code: 'USD',
  },
  {
    code: 'WB-LEMINATECHINNOVATIONSLTD.-107138(GBP)',
    description: 'LEMINATECH INNOVATIONS LTD. product(GBP)',
    id: DEMO_CURRENCIES_PRODUCTS.GBP || DEMO_PRODUCT_ID,
    client_id: DEMO_CLIENT_ID,
    client_name: 'LEMINATECH INNOVATIONS LTD.(GBP)',
    product_type: 'WBLACK',
    pricing_plan_type_id: 'Free',
    pricing_plan_subscription_started_at: '2024-01-23',
    pricing_plan_subscription_end_at: '2024-02-23',
    pricing_plan_selected_at: '2024-01-23T10:35:55Z',
    next_pricing_plan_type: 'Free',
    created_at: '2024-01-23T10:13:19Z',
    is_frozen: false,
    currency_code: 'GBP',
  },
  {
    code: 'WB-LEMINATECHINNOVATIONSLTD.-107138(SEK)',
    description: 'LEMINATECH INNOVATIONS LTD. product(SEK)',
    id: DEMO_CURRENCIES_PRODUCTS.SEK || DEMO_PRODUCT_ID,
    client_id: DEMO_CLIENT_ID,
    client_name: 'LEMINATECH INNOVATIONS LTD.(SEK)',
    product_type: 'WBLACK',
    pricing_plan_type_id: 'Free',
    pricing_plan_subscription_started_at: '2024-01-23',
    pricing_plan_subscription_end_at: '2024-02-23',
    pricing_plan_selected_at: '2024-01-23T10:35:55Z',
    next_pricing_plan_type: 'Free',
    created_at: '2024-01-23T10:13:19Z',
    is_frozen: false,
    currency_code: 'SEK',
  },
];

const DEMO_SERVER_STORAGE_DATA: ServerStorageData = {
  layout_dashboard_type: 'layout_base',
  last_profile_id: DEMO_PROFILE_ID,
  system_notification_dialogs_config: {
    _: {
      salary_program: {
        show_count: 5,
        last_show: 1706105492,
        full_show: true,
      },
    },
    [DEMO_CLIENT_ID]: {
      salary_program: {
        show_count: 10,
        last_show: 0,
        full_show: true,
      },
      welcome_screen: {
        show: true,
      },
    },
  },
  hint_init_top_up: true,
  hint_event_create_card: true,
  hint_init_create_cards: true,
  hint_init_make_payments: true,
  verification_got_it: {
    [DEMO_CLIENT_ID]: true,
  },
};

const DEMO_ALLOWED_ENDPOINTS: Endpoints[] = Object.keys(
  endpoints
) as Endpoints[];

const DEMO_CLIENTS: Record<UUID4, IClient> = {
  [DEMO_PRODUCT_ID]: {
    id: DEMO_CLIENT_ID,
    organizational_number: 'EST123456',
    name: 'LEMINATECH INNOVATIONS LTD.',
    mobile: '+19013453453',
    email: 'johndoe@doe.com',
    registration_country_code: 'EST',
    registration_street: '123 Energy Street',
    registration_city: 'Tallinn',
    registration_region: 'Harjumaa',
    registration_postal_code: '123123',
    actual_country_code: 'EST',
    actual_street: '123 Energy Street',
    actual_city: 'Tallinn',
    actual_region: 'Harjumaa',
    actual_postal_code: '123123',
    activity_type: ActivityType.Energy,
    incorporation_date: '2000-04-12T00:00:00Z',
    employees_quantity: 'EmployeesQuantity101to500',
    card_spending_amount: 'CardSpendingAmount100001to500000',
    contact_mobile: '+19013453453',
    contact_email: 'johndoe@doe.com',
    created_at: '2024-01-23T10:13:19Z',
    created_by: DEMO_USER_ID,
    updated_at: '2024-01-25T00:11:01Z',
    updated_by: 'admin-backend',
    email_verified: true,
    email_verified_at: '2024-01-23T10:13:19Z',
    mobile_verified: true,
    mobile_verified_at: '2024-01-23T10:13:19Z',
    agreement_signed_at: '2024-01-23T10:29:48Z',
    client_information_verification_status: VerificationStatus.Confirmed,
    documents_verification_status: VerificationStatus.Confirmed,
    identity_verification_status: VerificationStatus.Confirmed,
    onboarding_verification_status: 'Confirmed',
    white_label: false,
    web_representation: 'www.leminatech.io',
    business_description:
      " LeminaTech Innovations Ltd. is a forward-thinking company specializing in sustainable energy solutions. Our expertise lies in developing innovative technologies for renewable energy sources, with a commitment to creating a cleaner and more sustainable future. Through cutting-edge research and development, we aim to provide scalable solutions to address the world's energy challenges.",
    vat_number: 'EE987654321',
    is_bank_confirmed: true,
    status: 'Active',
  },
};

const DEMO_DEFAULT_PROFILE_PERMISSIONS = [
  1404,
  1405,
  1309,
  1407,
  4204,
  1307,
  1408,
  4413,
  1310,
  1312,
  4443,
  4414,
  4415,
  1440,
  4412,
  4403,
  4435,
  4436,
  4437,
  4409,
  4300,
  4408,
  4407,
  4301,
  1316,
  4406,
  4302,
  4303,
  4411,
  4215,
  4001,
  4201,
  1434,
  1410,
  1314,
  1414,
  1441,
  1422,
  1421,
  4444,
  4410,
  1900,
  1303,
  1302,
  1301,
  3102,
  1300,
  1315,
  1304,
  2000,
  1402,
  1401,
  3103,
  1400,
  1403,
  4445,
  4429,
  1600,
  3100,
  4100,
  4416,
  1427,
  1800,
];

const DEMO_PRODUCT_SETTINGS: ProductSettings = {
  credit_limit_max_amount: 0,
  default_account_daily_contactless_purchase_limit: 15000,
  default_account_daily_internet_purchase_limit: 15000,
  default_account_daily_purchase_limit: 15000,
  default_account_daily_withdrawal_limit: 5000,
  default_account_weekly_contactless_purchase_limit: 0,
  default_account_weekly_internet_purchase_limit: 0,
  default_account_weekly_purchase_limit: 0,
  default_account_weekly_withdrawal_limit: 0,
  default_account_monthly_contactless_purchase_limit: 25000,
  default_account_monthly_internet_purchase_limit: 25000,
  default_account_monthly_purchase_limit: 25000,
  default_account_monthly_withdrawal_limit: 15000,
  max_account_daily_contactless_purchase_limit: 25000,
  max_account_daily_internet_purchase_limit: 25000,
  max_account_daily_purchase_limit: 25000,
  max_account_daily_withdrawal_limit: 10000,
  max_account_weekly_contactless_purchase_limit: 0,
  max_account_weekly_internet_purchase_limit: 0,
  max_account_weekly_purchase_limit: 0,
  max_account_weekly_withdrawal_limit: 0,
  max_account_monthly_contactless_purchase_limit: 50000,
  max_account_monthly_internet_purchase_limit: 50000,
  max_account_monthly_purchase_limit: 50000,
  max_account_monthly_withdrawal_limit: 25000,
  program_type: 'Prepaid',
  default_card_daily_contactless_purchase_limit: 5000,
  default_card_daily_internet_purchase_limit: 5000,
  default_card_daily_purchase_limit: 5000,
  default_card_daily_withdrawal_limit: 1500,
  default_card_weekly_contactless_purchase_limit: 0,
  default_card_weekly_internet_purchase_limit: 0,
  default_card_weekly_purchase_limit: 0,
  default_card_weekly_withdrawal_limit: 0,
  default_card_monthly_contactless_purchase_limit: 15000,
  default_card_monthly_internet_purchase_limit: 15000,
  default_card_monthly_purchase_limit: 5000,
  default_card_monthly_withdrawal_limit: 5000,
  default_card_transaction_contactless_purchase_limit: 5000,
  default_card_transaction_internet_purchase_limit: 5000,
  default_card_transaction_purchase_limit: 5000,
  default_card_transaction_withdrawal_limit: 1500,
  max_card_daily_contactless_purchase_limit: 10000,
  max_card_daily_internet_purchase_limit: 10000,
  max_card_daily_purchase_limit: 10000,
  max_card_daily_withdrawal_limit: 3000,
  max_card_weekly_contactless_purchase_limit: 0,
  max_card_weekly_internet_purchase_limit: 0,
  max_card_weekly_purchase_limit: 0,
  max_card_weekly_withdrawal_limit: 0,
  max_card_monthly_contactless_purchase_limit: 25000,
  max_card_monthly_internet_purchase_limit: 25000,
  max_card_monthly_purchase_limit: 10000,
  max_card_monthly_withdrawal_limit: 10000,
  max_card_transaction_contactless_purchase_limit: 10000,
  max_card_transaction_internet_purchase_limit: 10000,
  max_card_transaction_purchase_limit: 10000,
  max_card_transaction_withdrawal_limit: 30000,
  default_card_daily_overall_purchase_limit: 0,
  max_card_daily_overall_purchase_limit: 0,
  default_card_weekly_overall_purchase_limit: 0,
  max_card_weekly_overall_purchase_limit: 0,
  default_card_monthly_overall_purchase_limit: 0,
  max_card_monthly_overall_purchase_limit: 0,
  weekly_limits_enabled: false,
  settlement_payment_details:
    'LEMINATECH INNOVATIONS LTD. (WALLESTER BUSINESS)',
  settlement_payment_details_iban: 'EE427700771005692629',
  settlement_payment_details_receiver_name: 'Wallester AS',
  settlement_payment_details_swift_code: 'LHVBEE22',
  settlement_payment_details_bank_name: 'JSC “LPB Bank”',
  settlement_payment_details_bank_address: 'Brivibas street 54, Riga, LV-1011',
  settlement_payment_details_registration_number: '50103189561',
  default_fee_complex_authorization_atm_withdrawal_fixed_part: 0,
  default_fee_complex_authorization_atm_withdrawal_min: 0,
  default_fee_complex_authorization_atm_withdrawal_percentage_part: 0,
  default_fee_fixed_rate_authorization_atm_balance_inquiry: 0.5,
  default_fee_fixed_rate_authorization_atm_withdrawal: 0,
  default_fee_fixed_rate_authorization_declined: 0,
  default_fee_fixed_rate_authorization_declined_atm: 0,
  default_fee_fixed_rate_card_replacement: 0,
  default_fee_fixed_rate_pin_change: 0,
  default_fee_percentage_rate_authorization_atm_withdrawal: 0,
  default_fee_percentage_rate_authorization_atm_withdrawal_fixed_min: 0,
  default_fee_percentage_rate_authorization_foreign_exchange: 2,
  default_fee_percentage_rate_authorization_foreign_exchange_fixed_min: 0,
  default_fee_fixed_rate_card_usage: 0,
  default_fee_payment_percentage_part: 0,
  default_fee_payment_fixed_part: 0,
  default_fee_payment_fixed_min: 0,
  default_fee_complex_authorization_atm_withdrawal_eea_percentage_part: 2,
  default_fee_complex_authorization_atm_withdrawal_eea_fixed_part: 0,
  default_fee_complex_authorization_atm_withdrawal_eea_min: 2,
  default_fee_complex_authorization_atm_withdrawal_international_percentage_part: 2,
  default_fee_complex_authorization_atm_withdrawal_international_fixed_part: 0,
  default_fee_complex_authorization_atm_withdrawal_international_min: 0,
  default_fee_complex_payment_eea_percentage_part: 0,
  default_fee_complex_payment_eea_fixed_part: 0,
  default_fee_complex_payment_eea_min: 0,
  default_fee_complex_payment_international_percentage_part: 0,
  default_fee_complex_payment_international_fixed_part: 0,
  default_fee_complex_payment_international_min: 0,
  default_fee_fixed_rate_remind_3ds_password: 0,
  default_fee_fixed_rate_remind_pin: 0,
  default_fee_fixed_rate_plastic_card_issuance: 0,
  default_fee_fixed_rate_virtual_card_issuance: 0,
  new_employee_addition_fee: 1,
  monthly_fee_per_employee: 1,
  card_types_enabled: {
    chip_and_pin: true,
    chip_and_pin_anonymous: false,
    virtual: true,
  },
  company_limits_enabled: true,
  default_company_daily_purchase_limit: 15000,
  default_company_daily_withdrawal_limit: 5000,
  default_company_daily_internet_purchase_limit: 15000,
  default_company_daily_contactless_purchase_limit: 15000,
  default_company_weekly_purchase_limit: 0,
  default_company_weekly_withdrawal_limit: 0,
  default_company_weekly_internet_purchase_limit: 0,
  default_company_weekly_contactless_purchase_limit: 0,
  default_company_monthly_purchase_limit: 25000,
  default_company_monthly_withdrawal_limit: 15000,
  default_company_monthly_internet_purchase_limit: 25000,
  default_company_monthly_contactless_purchase_limit: 25000,
  max_company_daily_purchase_limit: 25000,
  max_company_daily_withdrawal_limit: 10000,
  max_company_daily_internet_purchase_limit: 25000,
  max_company_daily_contactless_purchase_limit: 25000,
  max_company_weekly_purchase_limit: 0,
  max_company_weekly_withdrawal_limit: 0,
  max_company_weekly_internet_purchase_limit: 0,
  max_company_weekly_contactless_purchase_limit: 0,
  max_company_monthly_purchase_limit: 25000,
  max_company_monthly_withdrawal_limit: 25000,
  max_company_monthly_internet_purchase_limit: 25000,
  max_company_monthly_contactless_purchase_limit: 25000,
  max_company_daily_overall_purchase_limit: 25000,
  max_company_weekly_overall_purchase_limit: 0,
  max_company_monthly_overall_purchase_limit: 25000,
  currency_code: 'EUR',
  employee_cards_enabled: true,
  program: 'Business',
  kyb_required: true,
  allowed_3ds_auth_types_enabled: {
    out_of_band: false,
    sms: true,
    email: true,
  },
  personalization_product_codes_virtual: null,
  personalization_product_codes_chip_and_pin: null,
  personalization_product_codes_two_lines_embossing_name: ['1209-0001'],
  personalization_product_codes_three_lines_embossing_name: ['1209-0001'],
  personalization_default_product_code: '1209-0001',
  personalization_virtual_default_product_code: '9999-0038',
  partner_type: 'Regulated',
  allowed_card_delivery_country_codes: [
    'BEL',
    'BGR',
    'CZE',
    'DNK',
    'CYP',
    'LVA',
    'LTU',
    'LUX',
    'ESP',
    'FRA',
    'HRV',
    'ITA',
    'POL',
    'PRT',
    'ROU',
    'SVN',
    'SVK',
    'FIN',
    'SWE',
    'DEU',
    'EST',
    'IRL',
    'GRC',
    'HUN',
    'MLT',
    'NLD',
    'AUT',
    'ISL',
    'GBR',
    'NOR',
    'CHE',
    'USA',
  ],
  settings_loaded: true,
  personalization_product_codes: [],
};

const DEMO_RESERVED_SETTLEMENT_BALANCE: ReservedSettlementBalance = {
  amount: 15179.48,
  authorizations_count: 0,
  authorizations_total_reserved_amount: 0,
  pending_authorizations_count: 2,
  pending_authorizations_total_reserved_amount: 1005,
  currency_code: 'EUR',
};

const DEMO_SETTLEMENT_PAYMENT_DETAILS: SettlementPaymentDetails = {
  iban: 'LV49LAPB0000020999032',
  receiver_name: 'WALLESTER SCHOOL',
  swift_code: 'LHVBEE22',
  payment_details: 'WALLESTER SCHOOL (WB) LV49LAPB0000020999032',
  bank_name: 'JSC “LPB Bank”',
  bank_address: 'Brivibas street 54, Riga, LV-1011',
  registration_number: '50103189561',
};

const DEMO_ACCOUNTS: Record<UUID4, IAccount[]> = {
  [DEMO_PRODUCT_ID]: [
    {
      id: DEMO_MAIN_ACCOUNT_ID,
      product_id: DEMO_PRODUCT_ID,
      company_id: DEMO_COMPANY_ID,
      name: 'LEMINATECH INNOVATIONS LTD.',
      currency_code: 'EUR',
      credit_limit: 0,
      used_credit: 0,
      balance: 4967.74,
      available_amount: 4967.74,
      blocked_amount: 0,
      status: 'Active',
      created_at: '2024-01-23T10:29:48Z',
      updated_at: '2024-01-25T00:11:05Z',
      limits: {
        daily_purchase: 15000,
        daily_withdrawal: 5000,
        daily_internet_purchase: 15000,
        daily_contactless_purchase: 15000,
        weekly_purchase: 25000,
        weekly_withdrawal: 15000,
        weekly_internet_purchase: 25000,
        weekly_contactless_purchase: 25000,
        monthly_purchase: 25000,
        monthly_withdrawal: 15000,
        monthly_internet_purchase: 25000,
        monthly_contactless_purchase: 25000,
      } as AccountLimitsWithUsage,
      is_main: true,
      cards_count: 2,
      top_up_details: {
        iban: 'EE427700771005692629',
        swift_code: 'LHVBEE22',
        receiver_name: 'Wallester AS',
        payment_details: 'LEMINATECH INNOVATIONS LTD. (WALLESTER BUSINESS)',
        bank_name: 'JSC “LPB Bank”',
        bank_address: 'Brivibas street 54, Riga, LV-1011',
        registration_number: '50103189561',
      },
    },
    {
      id: '0999c66e-78cc-442c-8a06-b5bef2efe4ca',
      product_id: DEMO_PRODUCT_ID,
      company_id: DEMO_COMPANY_ID,
      name: 'Google Ads',
      currency_code: 'EUR',
      credit_limit: 0,
      used_credit: 0,
      balance: 1469.21,
      available_amount: 1469.21,
      blocked_amount: 0,
      status: 'Active',
      created_at: '2024-01-23T10:39:38Z',
      updated_at: '2024-01-23T14:55:20Z',
      limits: {
        daily_purchase: 15000,
        daily_withdrawal: 5000,
        daily_internet_purchase: 15000,
        daily_contactless_purchase: 15000,
        weekly_purchase: 25000,
        weekly_withdrawal: 15000,
        weekly_internet_purchase: 25000,
        weekly_contactless_purchase: 25000,
        monthly_purchase: 25000,
        monthly_withdrawal: 15000,
        monthly_internet_purchase: 25000,
        monthly_contactless_purchase: 25000,
      } as AccountLimitsWithUsage,
      is_main: false,
      cards_count: 1,
      top_up_details: {
        iban: 'EE427700771005692629',
        swift_code: 'LHVBEE22',
        receiver_name: 'Wallester AS',
        payment_details: 'LEMINATECH INNOVATIONS LTD. (WALLESTER BUSINESS)',
        bank_name: 'JSC “LPB Bank”',
        bank_address: 'Brivibas street 54, Riga, LV-1011',
        registration_number: '50103189561',
      },
    },
    {
      id: 'cc447d08-dea7-43ff-9a28-60dbc9d2e85d',
      product_id: DEMO_PRODUCT_ID,
      company_id: DEMO_COMPANY_ID,
      name: 'Office Expenses',
      currency_code: 'EUR',
      credit_limit: 0,
      used_credit: 0,
      balance: 4714,
      available_amount: 4714,
      blocked_amount: 0,
      status: 'Active',
      created_at: '2024-01-23T10:40:43Z',
      updated_at: '2024-01-23T14:19:47Z',
      limits: {
        daily_purchase: 15000,
        daily_withdrawal: 5000,
        daily_internet_purchase: 15000,
        daily_contactless_purchase: 15000,
        weekly_purchase: 25000,
        weekly_withdrawal: 15000,
        weekly_internet_purchase: 25000,
        weekly_contactless_purchase: 25000,
        monthly_purchase: 25000,
        monthly_withdrawal: 15000,
        monthly_internet_purchase: 25000,
        monthly_contactless_purchase: 25000,
      } as AccountLimitsWithUsage,
      is_main: false,
      cards_count: 2,
      top_up_details: {
        iban: 'EE427700771005692629',
        swift_code: 'LHVBEE22',
        receiver_name: 'Wallester AS',
        payment_details: 'LEMINATECH INNOVATIONS LTD. (WALLESTER BUSINESS)',
        bank_name: 'JSC “LPB Bank”',
        bank_address: 'Brivibas street 54, Riga, LV-1011',
        registration_number: '50103189561',
      },
    },
    {
      id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
      product_id: DEMO_PRODUCT_ID,
      company_id: DEMO_COMPANY_ID,
      name: 'Conferences 2024',
      currency_code: 'EUR',
      credit_limit: 0,
      used_credit: 0,
      balance: 5265.91,
      available_amount: 3260.81,
      blocked_amount: 2005.1,
      status: 'Active',
      created_at: '2024-01-23T10:42:40Z',
      updated_at: '2024-01-23T14:56:29Z',
      limits: {
        daily_purchase: 15000,
        daily_withdrawal: 5000,
        daily_internet_purchase: 15000,
        daily_contactless_purchase: 15000,
        weekly_purchase: 25000,
        weekly_withdrawal: 15000,
        weekly_internet_purchase: 25000,
        weekly_contactless_purchase: 25000,
        monthly_purchase: 25000,
        monthly_withdrawal: 15000,
        monthly_internet_purchase: 25000,
        monthly_contactless_purchase: 25000,
      } as AccountLimitsWithUsage,
      is_main: false,
      cards_count: 1,
      top_up_details: {
        iban: 'EE427700771005692629',
        swift_code: 'LHVBEE22',
        receiver_name: 'Wallester AS',
        payment_details: 'LEMINATECH INNOVATIONS LTD. (WALLESTER BUSINESS)',
        bank_name: 'JSC “LPB Bank”',
        bank_address: 'Brivibas street 54, Riga, LV-1011',
        registration_number: '50103189561',
      },
    },
    {
      id: '037c35ea-c928-4c4f-a1a6-69248fafd697',
      product_id: DEMO_PRODUCT_ID,
      company_id: DEMO_COMPANY_ID,
      name: 'Car Rent',
      currency_code: 'EUR',
      credit_limit: 0,
      used_credit: 0,
      balance: 767.72,
      available_amount: 767.72,
      blocked_amount: 0,
      status: 'Active',
      created_at: '2024-01-23T10:46:11Z',
      updated_at: '2024-01-23T13:39:02Z',
      limits: {
        daily_purchase: 15000,
        daily_withdrawal: 5000,
        daily_internet_purchase: 15000,
        daily_contactless_purchase: 15000,
        weekly_purchase: 25000,
        weekly_withdrawal: 15000,
        weekly_internet_purchase: 25000,
        weekly_contactless_purchase: 25000,
        monthly_purchase: 25000,
        monthly_withdrawal: 15000,
        monthly_internet_purchase: 25000,
        monthly_contactless_purchase: 25000,
      } as AccountLimitsWithUsage,
      is_main: false,
      cards_count: 2,
      top_up_details: {
        iban: 'EE427700771005692629',
        swift_code: 'LHVBEE22',
        receiver_name: 'Wallester AS',
        payment_details: 'LEMINATECH INNOVATIONS LTD. (WALLESTER BUSINESS)',
        bank_name: 'JSC “LPB Bank”',
        bank_address: 'Brivibas street 54, Riga, LV-1011',
        registration_number: '50103189561',
      },
    },
  ],
};

const DEMO_PRICING_PLANS: PricingPlans = {
  Free: {
    cost: 0,
    additional_virtual_card_cost: 0.35,
    max_virtual_cards: 300,
  },
  Platinum: {
    cost: 999,
    additional_virtual_card_cost: 0.1,
    max_virtual_cards: 15000,
  },
  Premium: {
    cost: 199,
    additional_virtual_card_cost: 0.2,
    max_virtual_cards: 3000,
  },
};

const DEMO_PRICING_PLAN: Record<UUID4, ProductPricingPlan> = {
  [DEMO_PRODUCT_ID]: {
    total_virtual_cards: 7,
    pricing_plan_type: 'Free',
    next_pricing_plan_type: 'Free',
    pricing_plan_cost: 0,
    pricing_plan_subscription_start_date: '2024-01-23',
    pricing_plan_subscription_end_date: '2024-02-23',
    pricing_plan_additional_virtual_card_cost: 0.35,
    pricing_plan_max_virtual_cards: 300,
    total_active_virtual_cards: 7,
    total_closed_virtual_cards: 0,
  },
};

const DEMO_DEFAULT_CARD_DISPATCH_METHODS: CardDispatchMethodInfo[] = [
  {
    dispatch_method: 'DHLGlobalMail',
    price: 5,
    description: '5-9 working days',
    delivery_time: {min: '5d', max: '9d'},
  },
  {
    dispatch_method: 'DHLExpress',
    price: 20,
    description: '2-3 working days',
    delivery_time: {min: '2d', max: '3d'},
  },
];

const DEMO_COMPANIES: ICompany[] = [
  {
    id: DEMO_COMPANY_ID,
    name: 'LEMINATECH INNOVATIONS LTD.',
    registration_number: 'EST123456',
    registration_address: {
      country_code: 'EST',
      address1: '123 Energy Street',
      address2: 'Harjumaa',
      city: 'Tallinn',
      postal_code: '123123',
    },
    headquarter_address: {
      country_code: 'EST',
      address1: '123 Energy Street',
      address2: 'Harjumaa',
      city: 'Tallinn',
      postal_code: '123123',
    },
    risk_profile: 'Medium',
    mobile: '+19013453453',
    email: 'johndoe@doe.com',
    created_at: '2024-01-23T10:29:48Z',
    created_by: DEMO_USER_ID,
    updated_by: DEMO_USER_ID,
    industry_type: 'Energy',
    date_of_incorporation: '2000-04-12T00:00:00Z',
    business_relationship_purpose: 'Business expense card as a mean of payment',
    is_sanctions_related: false,
    is_adverse_media_involved: false,
    employees_quantity: 'EmployeesQuantity101to500',
    card_spending_amount: 'CardSpendingAmount100001to500000',
    limits: {
      daily_purchase: 15000,
      daily_withdrawal: 5000,
      daily_internet_purchase: 20000,
      daily_contactless_purchase: 15000,
      weekly_purchase: 25000,
      weekly_withdrawal: 15000,
      weekly_internet_purchase: 25000,
      weekly_contactless_purchase: 25000,
      monthly_purchase: 25000,
      monthly_withdrawal: 15000,
      monthly_internet_purchase: 25000,
      monthly_contactless_purchase: 25000,
    } as CompanyLimitsWithUsage,
    status: 'Active',
    push_notifications_enabled: false,
    preferred_language_code: 'ENG',
    vat_number: 'EE987654321',
  },
];

const DEMO_CORPORATE_DOCUMENTS: CorporateDocument[] = [
  {
    id: 'e915c6e7-dc3b-40c4-b31d-ea62c6bccdf3',
    client_id: DEMO_CLIENT_ID,
    created_at: '2024-01-01T00:00:00',
    type: 'BeneficialOwnersCertificate',
    created_by: '62387023-ead6-4bf2-8fd4-0e7240940e51',
    file_name: 'LeminaTech.pdf',
    file_mime_type: 'application/pdf',
    file_size: 10000000,
    is_required: false,
    verification_state: {status: 'Approved'},
  },
  {
    id: 'c85023fc-77d2-49d6-a17a-0753842e4761',
    client_id: DEMO_CLIENT_ID,
    created_at: '2024-01-01T00:00:00',
    type: 'RegistrationIncorporationCertificate',
    created_by: '62387023-ead6-4bf2-8fd4-0e7240940e51',
    file_name: 'LeminaTech.pdf',
    file_mime_type: 'application/pdf',
    file_size: 10000000,
    is_required: false,
    verification_state: {status: 'Approved'},
  },
];

const DEMO_COMPANY_LIMITS_USAGE: Record<UUID4, LimitUsageInfo> = {
  [DEMO_COMPANY_ID]: {
    purchase: {daily: 0, weekly: 0, monthly: 0},
    withdrawal: {daily: 0, weekly: 150, monthly: 150},
    internet_purchase: {daily: 0, weekly: 14607.16, monthly: 14607.16},
    contactless_purchase: {daily: 0, weekly: 0, monthly: 0},
    overall_purchase: {daily: 0, weekly: 14757.16, monthly: 14757.16},
  },
};
const DEMO_ACCOUNT_LIMITS_USAGE: LimitUsageInfo = {
  purchase: {
    daily: 0,
    weekly: 0,
    monthly: 0,
    all_time: 0,
  },
  withdrawal: {
    daily: 0,
    weekly: 0,
    monthly: 0,
    all_time: 0,
  },
  internet_purchase: {
    daily: 0,
    weekly: 0,
    monthly: 0,
    all_time: 0,
  },
  contactless_purchase: {
    daily: 0,
    weekly: 0,
    monthly: 0,
    all_time: 0,
  },
  overall_purchase: {
    daily: 0,
    weekly: 0,
    monthly: 0,
    all_time: 0,
  },
};

const DEMO_CURRENCY_REQUEST_CONFIG: CurrencyRequestConfig = {
  expected_cards_number: [
    'ExpectedCardsNumber1to20',
    'ExpectedCardsNumber21to100',
    'ExpectedCardsNumber101to300',
    'ExpectedCardsNumber301to1000',
    'ExpectedCardsNumber1001to3000',
    'ExpectedCardsNumber3001to15000',
    'ExpectedCardsNumber15001AndMore',
  ],
  expected_turnover: [
    'ExpectedTurnover0to2000',
    'ExpectedTurnover2001to10000',
    'ExpectedTurnover10001to50000',
    'ExpectedTurnover50001to100000',
    'ExpectedTurnover100001to500000',
    'ExpectedTurnover500000AndMore',
  ],
};

export const DEMO_TREATMENTS: TreatmentsWithConfig = {
  [Features.RestrictedCountriesCheck]: {
    treatment: TreatmentStatus.Enabled,
    config: null,
  },
  [Features.CaptchaOnSignIn]: {
    treatment: TreatmentStatus.Enabled,
    config: null,
  },
  [Features.ABTestingRegistration]: {
    treatment: TreatmentStatus.Disabled,
    config: null,
  },
  [Features.EnableNewStatementExport]: {
    treatment: TreatmentStatus.Disabled,
    config: null,
  },
  [Features.IntercomArticleIds]: {
    treatment: TreatmentStatus.Disabled,
    config: null,
  },
  [Features.EnableFeeInvoices]: {
    treatment: TreatmentStatus.Enabled,
    config: null,
  },
  [Features.EnablePreferredLanguage]: {
    treatment: TreatmentStatus.Enabled,
    config: null,
  },
  [Features.EnablePaymentNotesFrontend]: {
    treatment: TreatmentStatus.Disabled,
    config: null,
  },
  [Features.Enable3DSOOB]: {
    treatment: TreatmentStatus.Disabled,
    config: null,
  },
  [Features.EnableReceiptReminders]: {
    treatment: TreatmentStatus.Enabled,
    config: null,
  },
  [Features.EnableDebtResolutionFlow]: {
    treatment: TreatmentStatus.Enabled,
    config: null,
  },
};

const DEMO_VERIFICATION_PERSONS: ClientPerson[] = [
  {
    id: 'c524a912-80b4-4d85-9220-805cb96663c4',
    first_name: 'John',
    last_name: 'Doe',
    birth_date: '1981-01-01',
    residence_country_code: 'EST',
    email: 'johndoe@doe.com',
    role: {
      is_board_member: true,
      is_shareholder: true,
      is_company_representative: true,
      percentage_owned: 100,
    },
    verification_state: {
      status: 'Confirmed',
    },
    is_politically_exposed: false,
    is_adverse_media_involved: false,
    created_at: '2024-01-23T10:23:20Z',
    created_by: DEMO_USER_ID,
    updated_at: '2024-01-23T10:26:40Z',
    updated_by: '121',
  },
];

const DEMO_VERIFICATION_COMPANIES: ClientCompany[] = [];

const DEMO_PRODUCT_USERS: IProfile[] = [];

const DEMO_ROLES: IRole[] = [
  {
    name: 'Accountant',
    description:
      'WB Client Portal role. It contains everything that accountant needs on WBLACK',
    restricted: false,
    product_based: true,
    id: '4922a2ab-e881-4f2c-8ab7-69ecf155cd85',
    created_at: '2023-07-26T10:06:13Z',
    created_by: 'system',
  },
  {
    name: 'Admin',
    description:
      'WB Client Portal role. It contains all allowed WBLACK permissions',
    restricted: false,
    product_based: true,
    id: '0ac76328-739f-4dc8-bfde-251e6613c910',
    created_at: '2023-07-26T10:06:13Z',
    created_by: 'system',
  },
  {
    name: 'Chief Financial Officer',
    description:
      'WB Client Portal role. It contains everything that CFO needs on WBLACK',
    restricted: false,
    product_based: true,
    id: '8b78173c-a4f8-4d57-b7df-db8bf922cf2c',
    created_at: '2023-07-26T10:06:13Z',
    created_by: 'system',
  },
  {
    name: 'Developer',
    description:
      'WB Client Portal role. It contains API key accesses on WBLACK',
    restricted: false,
    product_based: true,
    id: '933fcba6-1ebb-4474-bc2d-bdf127df2ff1',
    created_at: '2023-07-26T10:06:13Z',
    created_by: 'system',
  },
];

// EGOR R
// EGOR O
export const DEMO_CARDS: Record<UUID4, ICardExt[]> = {
  [DEMO_PRODUCT_ID]: [
    {
      id: '231712ca-3132-4cd7-b520-915259b06c82',
      type: 'ChipAndPinAnonymous',
      masked_card_number: '4065809493103123',
      expiry_date: '2029-01-31T23:59:59Z',
      status: 'Closed',
      embossing_name: 'CAR RENTAL',
      currency_code: 'EUR',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 5000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 5000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 5000,
        all_time_withdrawal: 5000,
        all_time_internet_purchase: 15000,
        all_time_contactless_purchase: 15000,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 5000,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 0,
        weekly_withdrawal_available: 5000,
        weekly_internet_purchase_used: 0,
        weekly_internet_purchase_available: 15000,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 0,
        monthly_withdrawal_available: 5000,
        monthly_internet_purchase_used: 0,
        monthly_internet_purchase_available: 15000,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 0,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 0,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 5000,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 5000,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 15000,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 15000,
      },
      '3d_secure_settings': null,
      delivery_address: {
        first_name: 'John',
        last_name: 'Doe',
        company_name: 'LEMINATECH INNOVATIONS LTD.',
        address1: '123 Energy Street',
        postal_code: '123123',
        city: 'Tallinn',
        country_code: 'EST',
        dispatch_method: 'DHLExpress',
      },
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '1209-0001',
      created_at: '2024-01-23T12:49:59Z',
      updated_at: '2024-01-23T12:54:38Z',
      closed_at: '2024-01-23T12:54:38Z',
      closed_by: DEMO_USER_ID,
      close_reason: 'ClosedByClient',
      cvv: 123,
      account_id: DEMO_MAIN_ACCOUNT_ID,
      card_notification_settings: {
        receipts_reminder_enabled: true,
      },
    },
    {
      id: '38cce284-d191-4b65-8de7-24d1a927ec5f',
      type: 'ChipAndPinAnonymous',
      masked_card_number: '4826823560815374',
      expiry_date: '2029-01-31T23:59:59Z',
      status: 'Active',
      embossing_name: 'CAR RENTAL',
      currency_code: 'EUR',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 5000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 5000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 5000,
        all_time_withdrawal: 5000,
        all_time_internet_purchase: 15000,
        all_time_contactless_purchase: 15000,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 5000,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 0,
        weekly_withdrawal_available: 5000,
        weekly_internet_purchase_used: 0,
        weekly_internet_purchase_available: 15000,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 0,
        monthly_withdrawal_available: 5000,
        monthly_internet_purchase_used: 0,
        monthly_internet_purchase_available: 15000,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 0,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 0,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 5000,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 5000,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 15000,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 15000,
      },
      '3d_secure_settings': null,
      delivery_address: {
        first_name: 'John',
        last_name: 'Doe',
        company_name: 'LEMINATECH INNOVATIONS LTD.',
        address1: '123 Energy Street',
        postal_code: '123123',
        city: 'Tallinn',
        country_code: 'EST',
        dispatch_method: 'DHLExpress',
      },
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '1209-0001',
      activated_at: '2024-01-23T13:09:32Z',
      created_at: '2024-01-23T12:49:59Z',
      updated_at: '2024-01-23T13:09:32Z',
      cvv: 123,
      account_id: DEMO_MAIN_ACCOUNT_ID,
      card_notification_settings: {
        receipts_reminder_enabled: true,
      },
    },
    {
      id: 'c2ee9a21-2c5a-4f03-b281-9167daf9f763',
      type: 'ChipAndPinAnonymous',
      masked_card_number: '4608457085113247',
      expiry_date: '2029-01-31T23:59:59Z',
      status: 'Closed',
      embossing_name: 'CAR RENTAL',
      currency_code: 'EUR',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 5000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 5000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 5000,
        all_time_withdrawal: 5000,
        all_time_internet_purchase: 15000,
        all_time_contactless_purchase: 15000,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 5000,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 0,
        weekly_withdrawal_available: 5000,
        weekly_internet_purchase_used: 0,
        weekly_internet_purchase_available: 15000,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 0,
        monthly_withdrawal_available: 5000,
        monthly_internet_purchase_used: 0,
        monthly_internet_purchase_available: 15000,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 0,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 0,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 5000,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 5000,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 15000,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 15000,
      },
      '3d_secure_settings': null,
      delivery_address: {
        first_name: 'John',
        last_name: 'Doe',
        company_name: 'LEMINATECH INNOVATIONS LTD.',
        address1: '123 Energy Street',
        postal_code: '123123',
        city: 'Tallinn',
        country_code: 'EST',
        dispatch_method: 'DHLExpress',
      },
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '1209-0001',
      created_at: '2024-01-23T12:49:59Z',
      updated_at: '2024-01-23T12:54:38Z',
      closed_at: '2024-01-23T12:54:38Z',
      closed_by: DEMO_USER_ID,
      close_reason: 'ClosedByClient',
      cvv: 123,
      account_id: DEMO_MAIN_ACCOUNT_ID,
      card_notification_settings: {
        receipts_reminder_enabled: false,
      },
    },
    {
      id: 'cd764796-0fe7-4fd0-9285-abd76a82f36f',
      type: 'ChipAndPinAnonymous',
      masked_card_number: '4490617782323158',
      expiry_date: '2029-01-31T23:59:59Z',
      status: 'Closed',
      embossing_name: 'CAR RENTAL',
      currency_code: 'EUR',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 5000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 5000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 5000,
        all_time_withdrawal: 5000,
        all_time_internet_purchase: 15000,
        all_time_contactless_purchase: 15000,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 5000,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 0,
        weekly_withdrawal_available: 5000,
        weekly_internet_purchase_used: 0,
        weekly_internet_purchase_available: 15000,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 0,
        monthly_withdrawal_available: 5000,
        monthly_internet_purchase_used: 0,
        monthly_internet_purchase_available: 15000,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 0,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 0,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 5000,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 5000,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 15000,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 15000,
      },
      '3d_secure_settings': null,
      delivery_address: {
        first_name: 'John',
        last_name: 'Doe',
        company_name: 'LEMINATECH INNOVATIONS LTD.',
        address1: '123 Energy Street',
        postal_code: '123123',
        city: 'Tallinn',
        country_code: 'EST',
        dispatch_method: 'DHLExpress',
      },
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '1209-0001',
      created_at: '2024-01-23T12:49:59Z',
      updated_at: '2024-01-23T12:54:21Z',
      closed_at: '2024-01-23T12:54:21Z',
      closed_by: DEMO_USER_ID,
      close_reason: 'ClosedByIssuer',
      cvv: 123,
      account_id: DEMO_MAIN_ACCOUNT_ID,
      card_notification_settings: {
        receipts_reminder_enabled: false,
      },
    },
    {
      id: 'ecd9f8f6-857f-4102-b28d-ad12c48f6c51',
      type: 'ChipAndPinAnonymous',
      masked_card_number: '4332421700996736',
      expiry_date: '2029-01-31T23:59:59Z',
      status: 'Closed',
      embossing_name: 'CAR RENTAL',
      currency_code: 'EUR',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 5000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 5000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 5000,
        all_time_withdrawal: 5000,
        all_time_internet_purchase: 15000,
        all_time_contactless_purchase: 15000,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 5000,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 0,
        weekly_withdrawal_available: 5000,
        weekly_internet_purchase_used: 0,
        weekly_internet_purchase_available: 15000,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 0,
        monthly_withdrawal_available: 5000,
        monthly_internet_purchase_used: 0,
        monthly_internet_purchase_available: 15000,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 0,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 0,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 5000,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 5000,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 15000,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 15000,
      },
      '3d_secure_settings': null,
      delivery_address: {
        first_name: 'John',
        last_name: 'Doe',
        company_name: 'LEMINATECH INNOVATIONS LTD.',
        address1: '123 Energy Street',
        postal_code: '123123',
        city: 'Tallinn',
        country_code: 'EST',
        dispatch_method: 'DHLExpress',
      },
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '1209-0001',
      created_at: '2024-01-23T12:49:59Z',
      updated_at: '2024-01-23T12:54:38Z',
      closed_at: '2024-01-23T12:54:38Z',
      closed_by: DEMO_USER_ID,
      close_reason: 'ClosedByClient',
      cvv: 123,
      account_id: DEMO_MAIN_ACCOUNT_ID,
      card_notification_settings: {
        receipts_reminder_enabled: false,
      },
    },
    {
      id: 'f6884bb9-d556-4d37-a499-220fc1babbb7',
      account_id: '5060f262-0ade-45c4-8aee-5fae6f8e1a45',
      type: 'ChipAndPin',
      name: 'CEO Card',
      masked_card_number: '4447153870858120',
      currency_code: 'EUR',
      expiry_date: '2029-01-31T23:59:59Z',
      status: 'Active',
      embossing_name: 'JOHN DOE',
      embossing_company_name: 'LEMINATECH INNOVATIONS LTD',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 5000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 5000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 0,
        all_time_withdrawal: 0,
        all_time_internet_purchase: 0,
        all_time_contactless_purchase: 0,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 5000,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 0,
        weekly_withdrawal_available: 5000,
        weekly_internet_purchase_used: 4039.65,
        weekly_internet_purchase_available: 10960.35,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 0,
        monthly_withdrawal_available: 5000,
        monthly_internet_purchase_used: 4039.65,
        monthly_internet_purchase_available: 10960.35,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 4039.65,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 4039.65,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 0,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 0,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 0,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 0,
      },
      '3d_secure_settings': null,
      delivery_address: {
        first_name: 'John',
        last_name: 'Doe',
        company_name: 'LEMINATECH INNOVATIONS LTD.',
        address1: '123 Energy Street',
        postal_code: '123123',
        city: 'Tallinn',
        country_code: 'EST',
        dispatch_method: 'DHLExpress',
        phone: '+19013453453',
      },
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '1209-0001',
      activated_at: '2024-01-23T12:47:42Z',
      created_at: '2024-01-23T10:48:19Z',
      updated_at: '2024-01-23T12:47:42Z',
      company_id: DEMO_COMPANY_ID,
      cvv: 123,
      card_notification_settings: {
        receipts_reminder_enabled: false,
      },
    },
    {
      id: '07f5d7c1-59fc-462b-bfae-2df3f97878a7',
      account_id: '0999c66e-78cc-442c-8a06-b5bef2efe4ca',
      type: 'Virtual',
      name: 'Michael R.',
      masked_card_number: '4168107130174410',
      currency_code: 'EUR',
      expiry_date: '2027-01-31T23:59:59Z',
      status: 'Active',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 5000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 5000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 0,
        all_time_withdrawal: 0,
        all_time_internet_purchase: 0,
        all_time_contactless_purchase: 0,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 5000,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 0,
        weekly_withdrawal_available: 5000,
        weekly_internet_purchase_used: 2280.79,
        weekly_internet_purchase_available: 12719.21,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 0,
        monthly_withdrawal_available: 5000,
        monthly_internet_purchase_used: 2280.79,
        monthly_internet_purchase_available: 12719.21,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 2280.79,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 2280.79,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 0,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 0,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 0,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 0,
      },
      '3d_secure_settings': {
        type: Card3DSecurityType.SMSOTPAndStaticPassword,
        language_code: 'ENG',
        password: 'password',
        email: 'johndoe@doe.com',
        out_of_band_enabled: false,
      },
      is_card_3d_secure_activated: true,
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '9999-0038',
      activated_at: '2024-01-23T13:15:01Z',
      created_at: '2024-01-23T13:15:01Z',
      updated_at: '2024-01-23T13:15:01Z',
      company_id: DEMO_COMPANY_ID,
      cvv: 123,
      card_notification_settings: {
        receipts_reminder_enabled: false,
      },
    },
    {
      id: '7c8af33b-03ae-4f57-8656-dc3f27e3bf30',
      account_id: 'cc447d08-dea7-43ff-9a28-60dbc9d2e85d',
      type: 'Virtual',
      name: 'Tim P.',
      masked_card_number: '4529897318689773',
      currency_code: 'EUR',
      expiry_date: '2027-01-31T23:59:59Z',
      status: 'Active',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 5000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 5000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 0,
        all_time_withdrawal: 0,
        all_time_internet_purchase: 0,
        all_time_contactless_purchase: 0,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 5000,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 150,
        weekly_withdrawal_available: 4850,
        weekly_internet_purchase_used: 1633,
        weekly_internet_purchase_available: 13367,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 150,
        monthly_withdrawal_available: 4850,
        monthly_internet_purchase_used: 1633,
        monthly_internet_purchase_available: 13367,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 1783,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 1783,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 0,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 0,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 0,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 0,
      },
      '3d_secure_settings': {
        type: Card3DSecurityType.SMSOTPAndStaticPassword,
        mobile: '+19013453453',
        language_code: 'ENG',
        password: 'password',
        out_of_band_enabled: false,
      },
      is_card_3d_secure_activated: true,
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '9999-0038',
      activated_at: '2024-01-23T13:18:07Z',
      created_at: '2024-01-23T13:18:06Z',
      updated_at: '2024-01-23T13:18:07Z',
      company_id: DEMO_COMPANY_ID,
      cvv: 166,
      card_notification_settings: {
        receipts_reminder_enabled: false,
      },
    },
    {
      id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
      account_id: '5060f262-0ade-45c4-8aee-5fae6f8e1a45',
      type: 'Virtual',
      name: 'Blade F.',
      masked_card_number: '4909945812198218',
      currency_code: 'EUR',
      expiry_date: '2027-01-31T23:59:59Z',
      status: 'Active',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 5000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 5000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 0,
        all_time_withdrawal: 0,
        all_time_internet_purchase: 0,
        all_time_contactless_purchase: 0,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 5000,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 0,
        weekly_withdrawal_available: 5000,
        weekly_internet_purchase_used: 660.39,
        weekly_internet_purchase_available: 14339.61,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 0,
        monthly_withdrawal_available: 5000,
        monthly_internet_purchase_used: 660.39,
        monthly_internet_purchase_available: 14339.61,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 660.39,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 660.39,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 0,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 0,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 0,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 0,
      },
      '3d_secure_settings': {
        type: Card3DSecurityType.SMSOTPAndStaticPassword,
        language_code: 'ENG',
        password: 'password',
        email: 'johndoe@doe.com',
        out_of_band_enabled: false,
      },
      is_card_3d_secure_activated: true,
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '9999-0038',
      activated_at: '2024-01-23T12:59:15Z',
      created_at: '2024-01-23T12:59:15Z',
      updated_at: '2024-01-23T12:59:15Z',
      company_id: DEMO_COMPANY_ID,
      cvv: 123,
      card_notification_settings: {
        receipts_reminder_enabled: false,
      },
    },
    {
      id: '93fd9c73-2e03-40a7-86f5-34425f669293',
      account_id: '037c35ea-c928-4c4f-a1a6-69248fafd697',
      type: 'Virtual',
      name: 'Henry T.',
      masked_card_number: '4674759668293094',
      currency_code: 'EUR',
      expiry_date: '2027-01-31T23:59:59Z',
      status: 'Active',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 5000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 5000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 0,
        all_time_withdrawal: 0,
        all_time_internet_purchase: 0,
        all_time_contactless_purchase: 0,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 5000,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 0,
        weekly_withdrawal_available: 5000,
        weekly_internet_purchase_used: 13.49,
        weekly_internet_purchase_available: 14986.51,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 0,
        monthly_withdrawal_available: 5000,
        monthly_internet_purchase_used: 13.49,
        monthly_internet_purchase_available: 14986.51,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 13.49,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 13.49,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 0,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 0,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 0,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 0,
      },
      '3d_secure_settings': {
        type: Card3DSecurityType.SMSOTPAndStaticPassword,
        mobile: '+19013453453',
        language_code: 'ENG',
        password: 'password',
        out_of_band_enabled: false,
      },
      is_card_3d_secure_activated: true,
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '9999-0038',
      activated_at: '2024-01-23T13:22:01Z',
      created_at: '2024-01-23T13:22:00Z',
      updated_at: '2024-01-23T13:22:01Z',
      company_id: DEMO_COMPANY_ID,
      cvv: 123,
      card_notification_settings: {
        receipts_reminder_enabled: false,
      },
    },
    {
      id: 'a16f4865-525c-4c02-b440-d75c6717cc76',
      account_id: 'cc447d08-dea7-43ff-9a28-60dbc9d2e85d',
      type: 'Virtual',
      name: 'Lenny G.',
      masked_card_number: '4253327582800201',
      currency_code: 'EUR',
      expiry_date: '2027-01-31T23:59:59Z',
      status: 'Active',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 5000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 5000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 0,
        all_time_withdrawal: 0,
        all_time_internet_purchase: 0,
        all_time_contactless_purchase: 0,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 5000,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 0,
        weekly_withdrawal_available: 5000,
        weekly_internet_purchase_used: 0,
        weekly_internet_purchase_available: 15000,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 0,
        monthly_withdrawal_available: 5000,
        monthly_internet_purchase_used: 0,
        monthly_internet_purchase_available: 15000,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 0,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 0,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 0,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 0,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 0,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 0,
      },
      '3d_secure_settings': {
        type: Card3DSecurityType.SMSOTPAndStaticPassword,
        language_code: 'ENG',
        password: 'password',
        email: 'johndoe@doe.com',
        out_of_band_enabled: false,
      },
      is_card_3d_secure_activated: true,
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '9999-0038',
      activated_at: '2024-01-23T12:56:24Z',
      created_at: '2024-01-23T12:56:24Z',
      updated_at: '2024-01-23T12:56:24Z',
      company_id: DEMO_COMPANY_ID,
      cvv: 123,
      card_notification_settings: {
        receipts_reminder_enabled: false,
      },
    },
    {
      id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
      account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
      type: 'Virtual',
      name: 'Natalie T.',
      masked_card_number: '4922541191665550',
      currency_code: 'EUR',
      expiry_date: '2027-01-31T23:59:59Z',
      status: 'Active',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 10000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 10000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 0,
        all_time_withdrawal: 0,
        all_time_internet_purchase: 0,
        all_time_contactless_purchase: 0,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 9988.95,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 0,
        weekly_withdrawal_available: 5000,
        weekly_internet_purchase_used: 5011.05,
        weekly_internet_purchase_available: 9988.95,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 0,
        monthly_withdrawal_available: 5000,
        monthly_internet_purchase_used: 5011.05,
        monthly_internet_purchase_available: 9988.95,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 5011.05,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 5011.05,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 0,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 0,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 0,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 0,
      },
      '3d_secure_settings': {
        type: Card3DSecurityType.SMSOTPAndStaticPassword,
        language_code: 'ENG',
        password: 'password',
        email: 'johndoe@doe.com',
        out_of_band_enabled: false,
      },
      is_card_3d_secure_activated: true,
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '9999-0038',
      activated_at: '2024-01-23T13:15:56Z',
      created_at: '2024-01-23T13:15:56Z',
      updated_at: '2024-01-23T14:14:47Z',
      company_id: DEMO_COMPANY_ID,
      cvv: 123,
      card_notification_settings: {
        receipts_reminder_enabled: false,
      },
    },
    {
      id: 'de754181-2e79-4be3-84e1-bc00ca136649',
      account_id: '037c35ea-c928-4c4f-a1a6-69248fafd697',
      type: 'Virtual',
      name: 'John K.',
      masked_card_number: '4483714958018083',
      currency_code: 'EUR',
      expiry_date: '2027-01-31T23:59:59Z',
      status: 'Active',
      limits: {
        daily_purchase: 5000,
        daily_withdrawal: 1500,
        daily_internet_purchase: 5000,
        daily_contactless_purchase: 5000,
        weekly_purchase: 5000,
        weekly_withdrawal: 5000,
        weekly_internet_purchase: 15000,
        weekly_contactless_purchase: 15000,
        monthly_purchase: 5000,
        monthly_withdrawal: 5000,
        monthly_internet_purchase: 15000,
        monthly_contactless_purchase: 15000,
        transaction_purchase: 5000,
        transaction_withdrawal: 1500,
        transaction_internet_purchase: 5000,
        transaction_contactless_purchase: 5000,
        daily_overall_purchase: 0,
        weekly_overall_purchase: 0,
        monthly_overall_purchase: 0,
        all_time_purchase: 0,
        all_time_withdrawal: 0,
        all_time_internet_purchase: 0,
        all_time_contactless_purchase: 0,
        daily_purchase_used: 0,
        daily_purchase_available: 5000,
        daily_withdrawal_used: 0,
        daily_withdrawal_available: 1500,
        daily_internet_purchase_used: 0,
        daily_internet_purchase_available: 5000,
        daily_contactless_purchase_used: 0,
        daily_contactless_purchase_available: 5000,
        weekly_purchase_used: 0,
        weekly_purchase_available: 5000,
        weekly_withdrawal_used: 0,
        weekly_withdrawal_available: 5000,
        weekly_internet_purchase_used: 968.79,
        weekly_internet_purchase_available: 14031.21,
        weekly_contactless_purchase_used: 0,
        weekly_contactless_purchase_available: 15000,
        monthly_purchase_used: 0,
        monthly_purchase_available: 5000,
        monthly_withdrawal_used: 0,
        monthly_withdrawal_available: 5000,
        monthly_internet_purchase_used: 968.79,
        monthly_internet_purchase_available: 14031.21,
        monthly_contactless_purchase_used: 0,
        monthly_contactless_purchase_available: 15000,
        daily_overall_purchase_used: 0,
        weekly_overall_purchase_available: 0,
        monthly_overall_purchase_used: 968.79,
        daily_overall_purchase_available: 0,
        weekly_overall_purchase_used: 968.79,
        monthly_overall_purchase_available: 0,
        all_time_purchase_used: 0,
        all_time_purchase_available: 0,
        all_time_withdrawal_used: 0,
        all_time_withdrawal_available: 0,
        all_time_internet_purchase_used: 0,
        all_time_internet_purchase_available: 0,
        all_time_contactless_purchase_used: 0,
        all_time_contactless_purchase_available: 0,
      },
      '3d_secure_settings': {
        type: Card3DSecurityType.SMSOTPAndStaticPassword,
        language_code: 'ENG',
        password: 'password',
        email: 'johndoe@doe.com',
        out_of_band_enabled: false,
      },
      is_card_3d_secure_activated: true,
      security: {
        contactless_enabled: true,
        withdrawal_enabled: true,
        internet_purchase_enabled: true,
        overall_limits_enabled: false,
        all_time_limits_enabled: false,
      },
      personalization_product_code: '9999-0038',
      activated_at: '2024-01-23T13:17:26Z',
      created_at: '2024-01-23T13:17:26Z',
      updated_at: '2024-01-23T13:17:26Z',
      company_id: DEMO_COMPANY_ID,
      cvv: 234,
      card_notification_settings: {
        receipts_reminder_enabled: false,
      },
    },
  ],
};

const DEMO_EMPLOYEES: Record<UUID4, Employee[]> = {
  [DEMO_PRODUCT_ID]: [
    {
      id: '598b27a1-9d3d-4635-92c6-67058c884af9',
      name: 'John Doe',
      mobile: '+19013453453',
      email: 'johndoe@doe.com',
      status: 'Deleted',
      last_invited_at: '2024-01-23T15:57:24Z',
      created_at: '2024-01-23T15:57:22Z',
      created_by: DEMO_USER_ID,
      deleted_at: '2024-01-23T16:02:05Z',
      deleted_by: DEMO_USER_ID,
    },
    {
      id: '8450bbf3-3408-4fcd-9287-c144415e4ec7',
      name: 'John Doe',
      mobile: '+19013453453',
      email: 'johndoe@doe.com',
      status: 'Deleted',
      last_invited_at: '2024-01-23T15:48:50Z',
      created_at: '2024-01-23T15:48:43Z',
      created_by: DEMO_USER_ID,
      deleted_at: '2024-01-23T15:56:57Z',
      deleted_by: DEMO_USER_ID,
    },
    {
      id: '7c5e2717-e564-4d81-b543-3f032d1724e6',
      name: 'Tom Cruz',
      mobile: '+19013453453',
      email: 'johndoe@doe.com',
      status: 'Deleted',
      created_at: '2024-01-23T16:07:56Z',
      created_by: DEMO_USER_ID,
      deleted_at: '2024-01-23T16:10:34Z',
      deleted_by: DEMO_USER_ID,
    },
    {
      id: 'fb931817-4b31-40d3-a107-34395281608a',
      name: 'Tom Cruz',
      person_first_name: 'John',
      person_last_name: 'Doe',
      mobile: '+19013453453',
      email: 'johndoe@doe.com',
      status: 'Active',
      last_invited_at: '2024-01-23T16:11:30Z',
      created_at: '2024-01-23T16:11:23Z',
      created_by: DEMO_USER_ID,
      iban: 'EE299258112996152339',
    },
    {
      id: 'd7fcba3c-64f9-4011-a16a-3a53b070d59b',
      name: 'Tom Cruz',
      mobile: '+19013453453',
      email: 'johndoe@doe.com',
      status: 'Deleted',
      last_invited_at: '2024-01-23T16:02:28Z',
      created_at: '2024-01-23T16:02:24Z',
      created_by: DEMO_USER_ID,
      deleted_at: '2024-01-23T16:03:23Z',
      deleted_by: DEMO_USER_ID,
    },
  ],
};

const DEMO_ACCOUNT_STATEMENTS: IAccountStatementExt[] = [
  {
    id: 'd907c48f-fde0-401a-b0a4-1dbc41155365',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '3a99b5be-630a-46b3-8684-635757f27e89',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-02-22T08:21:18Z',
    transaction_amount: -500,
    transaction_currency_code: 'EUR',
    account_amount: -500,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'FinLeap',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '3133bd60-4ad6-4720-a59e-ceb8e5d38337',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '3a99b5be-630a-46b3-8684-635757f27e89'
    ),
    has_payment_document_files: false,
    card_name: '123',
    purchase_date: '2024-02-22T08:21:17Z',
    enriched_merchant_data: {},
    total_amount: -500,
  },
  {
    id: '6fa587e3-73da-42f2-878f-2764d7edcdc3',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '3725272b-e4e7-40a3-8ecf-bfb4912b4697',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-02-22T08:20:39Z',
    transaction_amount: -150,
    transaction_currency_code: 'EUR',
    account_amount: -150,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Ebay',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '51f56319-cf95-4592-ba90-8ca473e88ed4',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '3725272b-e4e7-40a3-8ecf-bfb4912b4697'
    ),
    has_payment_document_files: false,
    purchase_date: '2024-02-22T08:20:38Z',
    enriched_merchant_data: {
      name: 'eBay',
      url: 'https://www.ebay.com/',
      domain: 'www.ebay.com',
      telephone_number: '4169692540',
      icon_url: 'https://sandbox.enrich.zone/content/f3/27/6066.thumb.png',
    },
    total_amount: -150,
  },
  {
    id: '41bd03a4-4c0a-4377-83f8-0a1a134d2d8b',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: 'f6884bb9-d556-4d37-a499-220fc1babbb7',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-02-22T08:19:45Z',
    transaction_amount: -465,
    transaction_currency_code: 'EUR',
    account_amount: -465,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'finbyte',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '93011fe1-ad70-4a55-a12f-b19f126e526b',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'f6884bb9-d556-4d37-a499-220fc1babbb7'
    ),
    has_payment_document_files: false,
    card_name: 'CEO Card',
    embossing_name: 'JOHN DOE',
    embossing_company_name: 'LEMINATECH INNOVATIONS LTD',
    purchase_date: '2024-02-22T08:19:44Z',
    enriched_merchant_data: {},
    total_amount: -465,
  },
  {
    id: '60131ddc-14b1-47ce-afa1-1f91429a5cb0',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: 'f6884bb9-d556-4d37-a499-220fc1babbb7',
    type: 'Authorization',
    group: 'InternetPurchase',
    date: '2024-02-22T08:18:25Z',
    transaction_amount: -3200,
    transaction_currency_code: 'EUR',
    account_amount: -3200,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'financeAds International GmbH',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    is_reversal: false,
    is_declined: true,
    is_cleared: false,
    status: 'Canceled',
    response: 'Declined',
    response_code: 'NotSufficientFunds',
    masked_card_number: getMaskedCardNumberById(
      'f6884bb9-d556-4d37-a499-220fc1babbb7'
    ),
    has_payment_document_files: false,
    card_name: 'CEO Card',
    embossing_name: 'JOHN DOE',
    embossing_company_name: 'LEMINATECH INNOVATIONS LTD',
    purchase_date: '2024-02-22T08:18:25Z',
    enriched_merchant_data: {},
    total_amount: 0,
  },
  {
    id: 'c3d1fb78-6e4f-4217-9053-6ac827dc0222',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-02-22T08:17:32Z',
    transaction_amount: -243.68,
    transaction_currency_code: 'EUR',
    account_amount: -243.68,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'X Corp.',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '005c49ff-e580-490a-8488-66d34815d32a',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a'
    ),
    has_payment_document_files: false,
    card_name: 'Blade F.',
    purchase_date: '2024-02-22T08:17:32Z',
    enriched_merchant_data: {},
    total_amount: -243.68,
  },
  {
    id: '791fae4a-0a5e-45ae-8681-f7734060232f',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-02-22T08:16:41Z',
    transaction_amount: -1199,
    transaction_currency_code: 'EUR',
    account_amount: -1199,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Microsoft Corporation',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: 'ae293d21-804e-443e-b2f4-9d6c268d1435',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a'
    ),
    has_payment_document_files: false,
    card_name: 'Blade F.',
    purchase_date: '2024-02-22T08:16:41Z',
    enriched_merchant_data: {},
    total_amount: -1199,
  },
  {
    id: '35520852-ab59-4a3c-b7f5-fd9351dca1ff',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-02-22T08:16:21Z',
    transaction_amount: -200,
    transaction_currency_code: 'EUR',
    account_amount: -200,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Wise',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '0c0f1c01-03ed-4942-9e8e-bf8a527b5833',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a'
    ),
    has_payment_document_files: false,
    card_name: 'Blade F.',
    purchase_date: '2024-02-22T08:16:20Z',
    enriched_merchant_data: {},
    total_amount: -200,
  },
  {
    id: 'fe80b94a-84bf-4855-b6eb-4bbb64091789',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-02-22T08:07:37Z',
    transaction_amount: -340,
    transaction_currency_code: 'EUR',
    account_amount: -340,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Meta Platforms Inc.',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '0fa01e7a-a0a2-448d-bd4a-100eda159993',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a'
    ),
    has_payment_document_files: false,
    card_name: 'Blade F.',
    purchase_date: '2024-02-22T08:07:36Z',
    enriched_merchant_data: {},
    total_amount: -340,
  },
  {
    id: '52fb2b8f-b710-4845-ab14-d70d4052bb30',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: 'f6884bb9-d556-4d37-a499-220fc1babbb7',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-02-22T08:05:55Z',
    transaction_amount: -175,
    transaction_currency_code: 'EUR',
    account_amount: -175,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'PayPal Inc.',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '5c2f6186-7b73-466b-aa3c-8ee2ec6c1e4e',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'f6884bb9-d556-4d37-a499-220fc1babbb7'
    ),
    has_payment_document_files: false,
    card_name: 'CEO Card',
    embossing_name: 'JOHN DOE',
    embossing_company_name: 'LEMINATECH INNOVATIONS LTD',
    purchase_date: '2024-02-22T08:05:54Z',
    enriched_merchant_data: {},
    total_amount: -175,
  },
  {
    id: '8d169852-18bd-43f6-8234-c59c17adc1eb',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: 'f6884bb9-d556-4d37-a499-220fc1babbb7',
    type: 'Authorization',
    group: 'InternetPurchase',
    date: '2024-02-09T12:23:34Z',
    transaction_amount: -100,
    transaction_currency_code: 'EUR',
    account_amount: -100,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Ebay',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Pending',
    response: 'Approved',
    response_code: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'f6884bb9-d556-4d37-a499-220fc1babbb7'
    ),
    has_payment_document_files: false,
    card_name: 'CEO Card',
    embossing_name: 'JOHN DOE',
    embossing_company_name: 'LEMINATECH INNOVATIONS LTD',
    purchase_date: '2024-02-09T12:23:34Z',
    enriched_merchant_data: {
      name: 'eBay',
      url: 'https://www.ebay.com/',
      domain: 'www.ebay.com',
      telephone_number: '4169692540',
      icon_url: 'https://sandbox.enrich.zone/content/f3/27/6066.thumb.png',
    },
    total_amount: -100,
  },
  {
    id: '4e5af1c6-877b-4a17-a4d7-a837c522f648',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    type: 'Fee',
    group: 'MonthlyFeePerEmployee',
    date: '2024-02-01T00:00:11Z',
    transaction_amount: -1,
    transaction_currency_code: 'EUR',
    account_amount: -1,
    account_currency_code: 'EUR',
    merchant_name: 'Wallester',
    description: 'MonthlyFeePerEmployee',
    is_reversal: false,
    is_declined: false,
    is_cleared: true,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'MonthlyFeePerEmployee',
    purchase_date: '2024-02-01T00:00:11Z',
    enriched_merchant_data: {},
    total_amount: -1,
  },
  {
    id: '0c6eeb25-279e-49bd-800b-ac1710c5ae00',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: 'f6884bb9-d556-4d37-a499-220fc1babbb7',
    type: 'Fee',
    group: 'CardExpressDeliveryFixedFee',
    date: '2024-01-24T00:50:04Z',
    transaction_amount: -20,
    transaction_currency_code: 'EUR',
    account_amount: -20,
    account_currency_code: 'EUR',
    merchant_name: 'Wallester',
    description: 'CardExpressDeliveryFixedFee',
    is_reversal: false,
    is_declined: false,
    is_cleared: true,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'f6884bb9-d556-4d37-a499-220fc1babbb7'
    ),
    has_payment_document_files: false,
    card_name: 'CEO Card',
    embossing_name: 'JOHN DOE',
    embossing_company_name: 'LEMINATECH INNOVATIONS LTD',
    sub_type: 'CardExpressDeliveryFixedFee',
    purchase_date: '2024-01-23T10:48:19Z',
    enriched_merchant_data: {},
    total_amount: -20,
  },
  {
    id: '4be67e09-c4ae-4178-b6ef-4e9c439d94bd',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    type: 'Fee',
    group: 'NewEmployeeAdditionFee',
    date: '2024-01-23T16:11:23Z',
    transaction_amount: -1,
    transaction_currency_code: 'EUR',
    account_amount: -1,
    account_currency_code: 'EUR',
    merchant_name: 'Wallester',
    description: 'NewEmployeeAdditionFee',
    is_reversal: false,
    is_declined: false,
    is_cleared: true,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'NewEmployeeAdditionFee',
    purchase_date: '2024-01-23T16:11:23Z',
    enriched_merchant_data: {},
    total_amount: -1,
  },
  {
    id: '5f53c69a-e5fb-488c-b3bf-d83ff8c28436',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    type: 'Fee',
    group: 'NewEmployeeAdditionFee',
    date: '2024-01-23T16:07:56Z',
    transaction_amount: -1,
    transaction_currency_code: 'EUR',
    account_amount: -1,
    account_currency_code: 'EUR',
    merchant_name: 'Wallester',
    description: 'NewEmployeeAdditionFee',
    is_reversal: false,
    is_declined: false,
    is_cleared: true,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'NewEmployeeAdditionFee',
    purchase_date: '2024-01-23T16:07:56Z',
    enriched_merchant_data: {},
    total_amount: -1,
  },
  {
    id: '344d1cc7-21d4-453f-8114-c1e8164c48d2',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    type: 'Fee',
    group: 'NewEmployeeAdditionFee',
    date: '2024-01-23T16:02:24Z',
    transaction_amount: -1,
    transaction_currency_code: 'EUR',
    account_amount: -1,
    account_currency_code: 'EUR',
    merchant_name: 'Wallester',
    description: 'NewEmployeeAdditionFee',
    is_reversal: false,
    is_declined: false,
    is_cleared: true,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'NewEmployeeAdditionFee',
    purchase_date: '2024-01-23T16:02:24Z',
    enriched_merchant_data: {},
    total_amount: -1,
  },
  {
    id: '2932f41b-0ef1-4770-be09-e44d897a0e06',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    type: 'Fee',
    group: 'NewEmployeeAdditionFee',
    date: '2024-01-23T15:57:22Z',
    transaction_amount: -1,
    transaction_currency_code: 'EUR',
    account_amount: -1,
    account_currency_code: 'EUR',
    merchant_name: 'Wallester',
    description: 'NewEmployeeAdditionFee',
    is_reversal: false,
    is_declined: false,
    is_cleared: true,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'NewEmployeeAdditionFee',
    purchase_date: '2024-01-23T15:57:22Z',
    enriched_merchant_data: {},
    total_amount: -1,
  },
  {
    id: '63f39f7a-e81c-40f8-91fc-8dd9300f13b3',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    type: 'Fee',
    group: 'NewEmployeeAdditionFee',
    date: '2024-01-23T15:48:43Z',
    transaction_amount: -1,
    transaction_currency_code: 'EUR',
    account_amount: -1,
    account_currency_code: 'EUR',
    merchant_name: 'Wallester',
    description: 'NewEmployeeAdditionFee',
    is_reversal: false,
    is_declined: false,
    is_cleared: true,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'NewEmployeeAdditionFee',
    purchase_date: '2024-01-23T15:48:43Z',
    enriched_merchant_data: {},
    total_amount: -1,
  },
  {
    id: 'b5b90ddb-353c-46a2-93ac-374abc9c978b',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T15:40:07Z',
    transaction_amount: -168.78,
    transaction_currency_code: 'EUR',
    account_amount: -168.78,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Amazon DE',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: 'd88dfd88-c006-42db-8a59-dd0d6b993eca',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a'
    ),
    has_payment_document_files: true,
    card_name: 'Blade F.',
    purchase_date: '2024-01-23T15:40:06Z',
    enriched_merchant_data: {
      name: 'Amazon',
      url: 'https://www.amazon.com/',
      domain: 'www.amazon.com',
      telephone_number: '800-07728574',
      icon_url: 'https://sandbox.enrich.zone/content/f2/7e/2277.thumb.png',
    },
    total_amount: -168.78,
  },
  {
    id: '943cdf73-d0c6-4234-a725-5b596c09bd55',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T15:38:37Z',
    transaction_amount: -14.99,
    transaction_currency_code: 'EUR',
    account_amount: -14.99,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Youtube',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: 'c612563c-3737-4e9c-82f7-84a30080b37c',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a'
    ),
    has_payment_document_files: false,
    card_name: 'Blade F.',
    purchase_date: '2024-01-23T15:38:37Z',
    enriched_merchant_data: {
      name: 'Youtube',
      url: 'https://www.youtube.com/',
      domain: 'www.youtube.com',
      icon_url: 'https://sandbox.enrich.zone/content/c8/92/6897.svg',
    },
    total_amount: -14.99,
  },
  {
    id: '969fd4a7-1615-4f7c-8f8a-407c4cd79127',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T15:38:21Z',
    transaction_amount: -15.69,
    transaction_currency_code: 'EUR',
    account_amount: -15.69,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'CircleK',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: 'f633cabd-1c97-492b-9e05-da096d8cc1d3',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a'
    ),
    has_payment_document_files: false,
    card_name: 'Blade F.',
    purchase_date: '2024-01-23T15:38:21Z',
    enriched_merchant_data: {
      name: 'Circle K',
      url: 'https://www.circlek.com/',
      domain: 'www.circlek.com',
      icon_url: 'https://sandbox.enrich.zone/content/96/5d/3990.thumb.png',
    },
    total_amount: -15.69,
  },
  {
    id: 'fdcfa5af-8b17-45e5-aec1-813801d3f84e',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T15:37:52Z',
    transaction_amount: -150,
    transaction_currency_code: 'GBP',
    account_amount: -175.38,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Westfield Stratford City',
    merchant_city: 'London',
    merchant_country_code: 'GBR',
    original_authorization_id: 'da684770-f7b1-4999-8a28-8cfc2fae5ace',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a'
    ),
    has_payment_document_files: false,
    card_name: 'Blade F.',
    purchase_date: '2024-01-23T15:37:51Z',
    enriched_merchant_data: {},
    total_amount: -178.89,
  },
  {
    id: 'f703a8ff-442f-44d7-8562-e3e7eba7ffec',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
    type: 'Fee',
    group: 'AuthorizationForeignExchangePercentageFee',
    date: '2024-01-23T15:37:52Z',
    transaction_amount: -3.51,
    transaction_currency_code: 'EUR',
    account_amount: -3.51,
    account_currency_code: 'EUR',
    merchant_name: 'Wallester',
    description: 'AuthorizationForeignExchangePercentageFee',
    original_authorization_id: 'da684770-f7b1-4999-8a28-8cfc2fae5ace',
    is_reversal: false,
    is_declined: false,
    is_cleared: true,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a'
    ),
    has_payment_document_files: false,
    card_name: 'Blade F.',
    sub_type: 'AuthorizationForeignExchangePercentageFee',
    purchase_date: '2024-01-23T15:37:52Z',
    enriched_merchant_data: {},
    total_amount: -3.51,
  },
  {
    id: 'c88e5b03-7266-4c83-b6ba-caa62a48795d',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: 'f6884bb9-d556-4d37-a499-220fc1babbb7',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T15:34:47Z',
    transaction_amount: -20,
    transaction_currency_code: 'EUR',
    account_amount: -20,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Sage',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '658bf107-2d6a-4db1-b39d-7ae4875bd6ec',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'f6884bb9-d556-4d37-a499-220fc1babbb7'
    ),
    has_payment_document_files: false,
    card_name: 'CEO Card',
    embossing_name: 'JOHN DOE',
    embossing_company_name: 'LEMINATECH INNOVATIONS LTD',
    purchase_date: '2024-01-23T15:34:46Z',
    enriched_merchant_data: {},
    total_amount: -20,
  },
  {
    id: '70412e79-4e5c-4901-a564-7b3744e51c47',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: 'f6884bb9-d556-4d37-a499-220fc1babbb7',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T15:34:06Z',
    transaction_amount: -11.99,
    transaction_currency_code: 'EUR',
    account_amount: -11.99,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Netflix',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '1d3e2b96-5b27-4cd1-9cad-c524dfd38997',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'f6884bb9-d556-4d37-a499-220fc1babbb7'
    ),
    has_payment_document_files: true,
    card_name: 'CEO Card',
    embossing_name: 'JOHN DOE',
    embossing_company_name: 'LEMINATECH INNOVATIONS LTD',
    purchase_date: '2024-01-23T15:34:05Z',
    enriched_merchant_data: {
      name: 'Netflix',
      url: 'https://www.netflix.com/',
      domain: 'www.netflix.com',
      telephone_number: '8665797172',
      icon_url: 'https://sandbox.enrich.zone/content/03/d0/3422.thumb.png',
    },
    total_amount: -11.99,
  },
  {
    id: 'fc5628ca-9fce-4a17-8923-f9bf54c70a08',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: 'f6884bb9-d556-4d37-a499-220fc1babbb7',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T15:33:50Z',
    transaction_amount: -136.75,
    transaction_currency_code: 'EUR',
    account_amount: -136.75,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'BestBuy',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: 'a2a6d690-a39d-46c7-82dc-af89205bb925',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'f6884bb9-d556-4d37-a499-220fc1babbb7'
    ),
    has_payment_document_files: false,
    card_name: 'CEO Card',
    embossing_name: 'JOHN DOE',
    embossing_company_name: 'LEMINATECH INNOVATIONS LTD',
    purchase_date: '2024-01-23T15:33:49Z',
    enriched_merchant_data: {
      name: 'Best Buy',
      url: 'https://www.bestbuy.com/',
      domain: 'www.bestbuy.com',
      icon_url: 'https://sandbox.enrich.zone/content/f6/5a/7521.thumb.png',
    },
    total_amount: -136.75,
  },
  {
    id: '00422ce5-245e-46cd-bfc2-048f0219ef85',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: 'f6884bb9-d556-4d37-a499-220fc1babbb7',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T15:33:26Z',
    transaction_amount: -36.34,
    transaction_currency_code: 'EUR',
    account_amount: -36.34,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Walmart',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '92ff64a3-0757-4d88-b7d2-4b9c41012c2e',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'f6884bb9-d556-4d37-a499-220fc1babbb7'
    ),
    has_payment_document_files: false,
    card_name: 'CEO Card',
    embossing_name: 'JOHN DOE',
    embossing_company_name: 'LEMINATECH INNOVATIONS LTD',
    purchase_date: '2024-01-23T15:33:26Z',
    enriched_merchant_data: {
      name: 'Walmart',
      url: 'https://www.walmart.com/',
      domain: 'www.walmart.com',
      telephone_number: '999-999-9999',
      icon_url: 'https://sandbox.enrich.zone/content/3d/57/2771.thumb.png',
    },
    total_amount: -36.34,
  },
  {
    id: 'e5e0d6de-aecb-4379-b8b5-f054ab5bf2e5',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: 'f6884bb9-d556-4d37-a499-220fc1babbb7',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T15:32:00Z',
    transaction_amount: -134.57,
    transaction_currency_code: 'EUR',
    account_amount: -134.57,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Amazon',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: 'db95cad4-341d-428f-8a4e-ed5cde7e3ea8',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'f6884bb9-d556-4d37-a499-220fc1babbb7'
    ),
    has_payment_document_files: false,
    card_name: 'CEO Card',
    embossing_name: 'JOHN DOE',
    embossing_company_name: 'LEMINATECH INNOVATIONS LTD',
    purchase_date: '2024-01-23T15:31:59Z',
    enriched_merchant_data: {
      name: 'Amazon',
      url: 'https://www.amazon.com/',
      domain: 'www.amazon.com',
      telephone_number: '800-07728574',
      icon_url: 'https://sandbox.enrich.zone/content/f2/7e/2277.thumb.png',
    },
    total_amount: -134.57,
  },
  {
    id: '232f3713-02d5-43fd-aa85-8817f9a2f94e',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: 'f6884bb9-d556-4d37-a499-220fc1babbb7',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T15:31:15Z',
    transaction_amount: -5,
    transaction_currency_code: 'EUR',
    account_amount: -5,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'CircleK',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '015729eb-5d3c-4785-b2ef-c87cb1681e81',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'f6884bb9-d556-4d37-a499-220fc1babbb7'
    ),
    has_payment_document_files: false,
    card_name: 'CEO Card',
    embossing_name: 'JOHN DOE',
    embossing_company_name: 'LEMINATECH INNOVATIONS LTD',
    purchase_date: '2024-01-23T15:31:14Z',
    enriched_merchant_data: {
      name: 'Circle K',
      url: 'https://www.circlek.com/',
      domain: 'www.circlek.com',
      icon_url: 'https://sandbox.enrich.zone/content/96/5d/3990.thumb.png',
    },
    total_amount: -5,
  },
  {
    id: 'f35c3270-a008-4bee-ba10-a44eb81fb92c',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    type: 'AccountAdjustment',
    group: 'Withdraw',
    date: '2024-01-23T14:08:37Z',
    transaction_amount: -4000,
    transaction_currency_code: 'EUR',
    account_amount: -4000,
    account_currency_code: 'EUR',
    merchant_name: 'LEMINATECH INNOVATIONS LTD.',
    description: 'Tickets',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'AccountToAccountTransfer',
    purchase_date: '2024-01-23T14:08:37Z',
    enriched_merchant_data: {
      icon_url:
        'https://wallester-production-client-files.s3.eu-west-1.amazonaws.com/wallester-icon64x64.png',
    },
    total_amount: -4000,
  },
  {
    id: 'a6786c1c-31d5-4f75-a98a-57f709369dea',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T13:33:44Z',
    transaction_amount: -217,
    transaction_currency_code: 'EUR',
    account_amount: -185.55,
    account_currency_code: 'GBP',
    merchant_category_code: '5713',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Dr. Martnes',
    merchant_city: 'London',
    merchant_country_code: 'EST',
    original_authorization_id: 'f0092daa-abc7-414d-b05b-f164abed6412',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a'
    ),
    has_payment_document_files: false,
    card_name: 'Blade F.',
    purchase_date: '2024-01-23T13:33:43Z',
    enriched_merchant_data: {},
    total_amount: -189.26,
  },
  {
    id: '01e53187-457b-4b5a-8639-b6ca93d9d7f6',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
    type: 'Fee',
    group: 'AuthorizationForeignExchangePercentageFee',
    date: '2024-01-23T13:33:44Z',
    transaction_amount: -3.71,
    transaction_currency_code: 'EUR',
    account_amount: -3.71,
    account_currency_code: 'EUR',
    merchant_name: 'Wallester',
    description: 'AuthorizationForeignExchangePercentageFee',
    original_authorization_id: 'f0092daa-abc7-414d-b05b-f164abed6412',
    is_reversal: false,
    is_declined: false,
    is_cleared: true,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a'
    ),
    has_payment_document_files: false,
    card_name: 'Blade F.',
    sub_type: 'AuthorizationForeignExchangePercentageFee',
    purchase_date: '2024-01-23T13:33:43Z',
    enriched_merchant_data: {},
    total_amount: -3.71,
  },
  {
    id: 'a85ac260-10f4-46f4-9078-ab1a709b939b',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    card_id: '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T13:31:47Z',
    transaction_amount: -100,
    transaction_currency_code: 'EUR',
    account_amount: -100,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Ebay',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '9e3466f4-cfc5-43e4-8220-cec6e7f2e8dc',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '8be9a7c4-768e-4ba6-bbf0-b960fc4b343a'
    ),
    has_payment_document_files: false,
    card_name: 'Blade F.',
    purchase_date: '2024-01-23T13:31:46Z',
    enriched_merchant_data: {
      name: 'eBay',
      url: 'https://www.ebay.com/',
      domain: 'www.ebay.com',
      telephone_number: '4169692540',
      icon_url: 'https://sandbox.enrich.zone/content/f3/27/6066.thumb.png',
    },
    total_amount: -100,
  },
  {
    id: '89fac83c-d036-4a02-a826-95a61fc2712f',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    type: 'AccountAdjustment',
    group: 'Withdraw',
    date: '2024-01-23T10:46:50Z',
    transaction_amount: -1750,
    transaction_currency_code: 'EUR',
    account_amount: -1750,
    account_currency_code: 'EUR',
    merchant_name: 'LEMINATECH INNOVATIONS LTD.',
    description: 'Car Rental Service @LuxCarEst',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'AccountToAccountTransfer',
    purchase_date: '2024-01-23T10:46:50Z',
    enriched_merchant_data: {
      icon_url:
        'https://wallester-production-client-files.s3.eu-west-1.amazonaws.com/wallester-icon64x64.png',
    },
    total_amount: -1750,
  },
  {
    id: '96338ccb-fd55-4956-9bce-c594695f6308',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    type: 'AccountAdjustment',
    group: 'Withdraw',
    date: '2024-01-23T10:45:57Z',
    transaction_amount: -4300,
    transaction_currency_code: 'EUR',
    account_amount: -4300,
    account_currency_code: 'EUR',
    merchant_name: 'LEMINATECH INNOVATIONS LTD.',
    description: 'Tickets + Hotels + Flights',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'AccountToAccountTransfer',
    purchase_date: '2024-01-23T10:45:57Z',
    enriched_merchant_data: {
      icon_url:
        'https://wallester-production-client-files.s3.eu-west-1.amazonaws.com/wallester-icon64x64.png',
    },
    total_amount: -4300,
  },
  {
    id: '485061bc-425b-43d6-b98f-8aff09d88dec',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    type: 'AccountAdjustment',
    group: 'Withdraw',
    date: '2024-01-23T10:41:24Z',
    transaction_amount: -6500,
    transaction_currency_code: 'EUR',
    account_amount: -6500,
    account_currency_code: 'EUR',
    merchant_name: 'LEMINATECH INNOVATIONS LTD.',
    description: 'Q3 Balance Adjust',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'AccountToAccountTransfer',
    purchase_date: '2024-01-23T10:41:24Z',
    enriched_merchant_data: {
      icon_url:
        'https://wallester-production-client-files.s3.eu-west-1.amazonaws.com/wallester-icon64x64.png',
    },
    total_amount: -6500,
  },
  {
    id: '208e02dc-76ef-4e84-ad84-bead82647e9f',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    type: 'AccountAdjustment',
    group: 'Withdraw',
    date: '2024-01-23T10:40:17Z',
    transaction_amount: -3750,
    transaction_currency_code: 'EUR',
    account_amount: -3750,
    account_currency_code: 'EUR',
    merchant_name: 'LEMINATECH INNOVATIONS LTD.',
    description: 'For Campaigns @Google',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'AccountToAccountTransfer',
    purchase_date: '2024-01-23T10:40:17Z',
    enriched_merchant_data: {
      icon_url:
        'https://wallester-production-client-files.s3.eu-west-1.amazonaws.com/wallester-icon64x64.png',
    },
    total_amount: -3750,
  },
  {
    id: '4810846d-b597-4e50-b9ef-9144e5954f9f',
    account_id: DEMO_MAIN_ACCOUNT_ID,
    type: 'AccountAdjustment',
    group: 'Deposit',
    date: '2024-01-23T10:34:08Z',
    transaction_amount: 30000,
    transaction_currency_code: 'EUR',
    account_amount: 30000,
    account_currency_code: 'EUR',
    merchant_name: 'LEMINATECH INNOVATIONS LTD.',
    description: 'Account Top-Up',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'Adjustment',
    purchase_date: '2024-01-23T10:34:08Z',
    enriched_merchant_data: {
      icon_url:
        'https://wallester-production-client-files.s3.eu-west-1.amazonaws.com/wallester-icon64x64.png',
    },
    total_amount: 30000,
  },
  {
    id: '4c72444d-dee7-4c8d-a95f-356b5fa0a963',
    account_id: '5060f262-0ade-45c4-8aee-5fae6f8e1a45',
    card_id: 'de754181-2e79-4be3-84e1-bc00ca136649',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T13:39:02Z',
    transaction_amount: -968.79,
    transaction_currency_code: 'EUR',
    account_amount: -968.79,
    account_currency_code: 'EUR',
    merchant_category_code: '5300',
    merchant_id: 'merchant25',
    terminal_id: 'ITA12453',
    merchant_name: 'Luxury Cars Milan',
    merchant_city: 'Milan',
    merchant_country_code: 'ITA',
    original_authorization_id: 'f4a42692-9757-4d00-b319-da844a41f590',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'de754181-2e79-4be3-84e1-bc00ca136649'
    ),
    has_payment_document_files: false,
    card_name: 'John K.',
    purchase_date: '2024-01-23T13:39:01Z',
    enriched_merchant_data: {},
    total_amount: -968.79,
  },
  {
    id: '52b45897-319f-4ac4-84e8-ee6fbdc9c64d',
    account_id: '5060f262-0ade-45c4-8aee-5fae6f8e1a45',
    card_id: '93fd9c73-2e03-40a7-86f5-34425f669293',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T13:37:33Z',
    transaction_amount: -13.49,
    transaction_currency_code: 'EUR',
    account_amount: -13.49,
    account_currency_code: 'EUR',
    merchant_category_code: '7342',
    merchant_id: 'merchant21',
    terminal_id: 'ITA12123',
    merchant_name: 'CityBee',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: 'baf182aa-a727-4334-82eb-4369e9648f2a',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '93fd9c73-2e03-40a7-86f5-34425f669293'
    ),
    has_payment_document_files: false,
    card_name: 'Henry T.',
    purchase_date: '2024-01-23T13:37:33Z',
    enriched_merchant_data: {
      name: 'CityBee',
      url: 'https://citybee.lt/',
      domain: 'citybee.lt',
      icon_url: 'https://sandbox.enrich.zone/content/20/53/4954.thumb.png',
    },
    total_amount: -13.49,
  },
  {
    id: '95debc58-67a1-4941-8c2b-3e6573f2fd0f',
    account_id: '5060f262-0ade-45c4-8aee-5fae6f8e1a45',
    type: 'AccountAdjustment',
    group: 'Deposit',
    date: '2024-01-23T10:46:50Z',
    transaction_amount: 1750,
    transaction_currency_code: 'EUR',
    account_amount: 1750,
    account_currency_code: 'EUR',
    merchant_name: 'LEMINATECH INNOVATIONS LTD.',
    description: 'Car Rental Service @LuxCarEst',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'AccountToAccountTransfer',
    purchase_date: '2024-01-23T10:46:50Z',
    enriched_merchant_data: {
      icon_url:
        'https://wallester-production-client-files.s3.eu-west-1.amazonaws.com/wallester-icon64x64.png',
    },
    total_amount: 1750,
  },
  {
    id: 'b9684f93-d2b1-4fb9-85f4-3487beca5349',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-02-22T08:08:59Z',
    transaction_amount: -179.99,
    transaction_currency_code: 'EUR',
    account_amount: -179.99,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Microsoft Corporation',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: 'b86282a5-eae4-463b-8084-2a0a9aac1c6d',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    purchase_date: '2024-02-22T08:08:58Z',
    enriched_merchant_data: {},
    total_amount: -179.99,
  },
  {
    id: '1994943f-9e43-4f7e-9a6a-76a28d896b44',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Fee',
    group: 'AuthorizationForeignExchangePercentageFee',
    date: '2024-01-23T14:56:28Z',
    transaction_amount: -28.04,
    transaction_currency_code: 'EUR',
    account_amount: -28.04,
    account_currency_code: 'EUR',
    merchant_name: 'Wallester',
    description: 'AuthorizationForeignExchangePercentageFee',
    original_authorization_id: 'd0714306-1415-4f0e-b210-5d57db1751cd',
    is_reversal: false,
    is_declined: false,
    is_cleared: true,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    sub_type: 'AuthorizationForeignExchangePercentageFee',
    purchase_date: '2024-01-23T14:56:28Z',
    enriched_merchant_data: {},
    total_amount: -28.04,
  },
  {
    id: 'b325f721-a423-46e7-84c4-5082f48015bd',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T14:56:29Z',
    transaction_amount: -1200,
    transaction_currency_code: 'GBP',
    account_amount: -1402.16,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'British Airways',
    merchant_city: 'London',
    merchant_country_code: 'GBR',
    original_authorization_id: 'd0714306-1415-4f0e-b210-5d57db1751cd',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    purchase_date: '2024-01-23T14:56:28Z',
    enriched_merchant_data: {
      name: 'British Airways',
      url: 'https://www.britishairways.com',
      domain: 'www.britishairways.com',
      telephone_number: '004-4477757027',
      icon_url: 'https://sandbox.enrich.zone/content/60/a4/6356.svg',
    },
    total_amount: -1430.2,
  },
  {
    id: 'b7625e29-7006-46d3-ad7c-e80894281f8f',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Fee',
    group: 'AuthorizationForeignExchangePercentageFee',
    date: '2024-01-23T14:47:44Z',
    transaction_amount: -0.1,
    transaction_currency_code: 'EUR',
    account_amount: -0.1,
    account_currency_code: 'EUR',
    merchant_name: 'Wallester',
    description: 'AuthorizationForeignExchangePercentageFee',
    original_authorization_id: '5ba6aea8-ab76-45da-8a7f-c3b011dc6428',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Pending',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    sub_type: 'AuthorizationForeignExchangePercentageFee',
    purchase_date: '2024-01-23T14:47:44Z',
    enriched_merchant_data: {},
    total_amount: -0.1,
  },
  {
    id: '5ba6aea8-ab76-45da-8a7f-c3b011dc6428',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Authorization',
    group: 'InternetPurchase',
    date: '2024-01-23T14:47:44Z',
    transaction_amount: -5,
    transaction_currency_code: 'GBP',
    account_amount: -5,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Ebay',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Pending',
    response: 'Approved',
    response_code: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    purchase_date: '2024-01-23T14:47:44Z',
    enriched_merchant_data: {
      name: 'eBay',
      url: 'https://www.ebay.com/',
      domain: 'www.ebay.com',
      telephone_number: '4169692540',
      icon_url: 'https://sandbox.enrich.zone/content/f3/27/6066.thumb.png',
    },
    total_amount: -5,
  },
  {
    id: '2b2a27fe-600e-4b1a-897d-2021f7d50067',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Authorization',
    group: 'InternetPurchase',
    date: '2024-01-23T14:47:29Z',
    transaction_amount: -1000,
    transaction_currency_code: 'EUR',
    account_amount: -1000,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Ebay',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Pending',
    response: 'Approved',
    response_code: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    purchase_date: '2024-01-23T14:47:29Z',
    enriched_merchant_data: {
      name: 'eBay',
      url: 'https://www.ebay.com/',
      domain: 'www.ebay.com',
      telephone_number: '4169692540',
      icon_url: 'https://sandbox.enrich.zone/content/f3/27/6066.thumb.png',
    },
    total_amount: -1000,
  },
  {
    id: '3771ff02-a6ba-4dae-84bf-b7020a9973f7',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Authorization',
    group: 'InternetPurchase',
    date: '2024-01-23T14:38:14Z',
    transaction_amount: -1000,
    transaction_currency_code: 'GBP',
    account_amount: -1000,
    account_currency_code: 'GBP',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Ebay',
    merchant_city: 'Tallinn',
    merchant_country_code: 'GBR',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Pending',
    response: 'Approved',
    response_code: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    purchase_date: '2024-01-23T14:38:14Z',
    enriched_merchant_data: {
      name: 'eBay',
      url: 'https://www.ebay.com/',
      domain: 'www.ebay.com',
      telephone_number: '4169692540',
      icon_url: 'https://sandbox.enrich.zone/content/f3/27/6066.thumb.png',
    },
    total_amount: -1000,
  },
  {
    id: '638db029-d7b0-453f-836e-e1cc9ae51e9f',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Authorization',
    group: 'InternetPurchase',
    date: '2024-01-23T14:37:11Z',
    transaction_amount: 0,
    transaction_currency_code: 'GBP',
    account_amount: 0,
    account_currency_code: 'GBP',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Ebay',
    merchant_city: 'Tallinn',
    merchant_country_code: 'GBR',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    response_code: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    purchase_date: '2024-01-23T14:37:11Z',
    enriched_merchant_data: {
      name: 'eBay',
      url: 'https://www.ebay.com/',
      domain: 'www.ebay.com',
      telephone_number: '4169692540',
      icon_url: 'https://sandbox.enrich.zone/content/f3/27/6066.thumb.png',
    },
    total_amount: 0,
  },
  {
    id: '76202ea0-1421-4505-a35f-2e35809c03b9',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Authorization',
    group: 'InternetPurchase',
    date: '2024-01-23T14:36:55Z',
    transaction_amount: 0,
    transaction_currency_code: 'GBP',
    account_amount: 0,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Ebay',
    merchant_city: 'Tallinn',
    merchant_country_code: 'GBR',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    response_code: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    purchase_date: '2024-01-23T14:36:55Z',
    enriched_merchant_data: {
      name: 'eBay',
      url: 'https://www.ebay.com/',
      domain: 'www.ebay.com',
      telephone_number: '4169692540',
      icon_url: 'https://sandbox.enrich.zone/content/f3/27/6066.thumb.png',
    },
    total_amount: 0,
  },
  {
    id: 'f3284b74-f1f0-4962-aa8d-2ee944b269cb',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Authorization',
    group: 'InternetPurchase',
    date: '2024-01-23T14:36:37Z',
    transaction_amount: 0,
    transaction_currency_code: 'EUR',
    account_amount: 0,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Ebay',
    merchant_city: 'Tallinn',
    merchant_country_code: 'GBR',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    response_code: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    purchase_date: '2024-01-23T14:36:37Z',
    enriched_merchant_data: {},
    total_amount: 0,
  },
  {
    id: 'cc465302-2f48-4c00-b24d-c0596e4b9331',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T14:35:19Z',
    transaction_amount: -364,
    transaction_currency_code: 'EUR',
    account_amount: -364,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'AirBaltic',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: 'e782ae75-6407-4b67-b56e-af6784b41092',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    purchase_date: '2024-01-23T14:35:18Z',
    enriched_merchant_data: {
      name: 'airBaltic',
      url: 'https://www.airbaltic.com',
      domain: 'www.airbaltic.com',
      icon_url: 'https://sandbox.enrich.zone/content/40/94/4680.thumb.png',
    },
    total_amount: -364,
  },
  {
    id: '313e0c4a-83ce-49ba-826d-d4b0b04666fb',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Authorization',
    group: 'InternetPurchase',
    date: '2024-01-23T14:29:14Z',
    transaction_amount: 0,
    transaction_currency_code: 'EUR',
    account_amount: 0,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Ebay',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    response_code: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    purchase_date: '2024-01-23T14:29:14Z',
    enriched_merchant_data: {
      name: 'eBay',
      url: 'https://www.ebay.com/',
      domain: 'www.ebay.com',
      telephone_number: '4169692540',
      icon_url: 'https://sandbox.enrich.zone/content/f3/27/6066.thumb.png',
    },
    total_amount: 0,
  },
  {
    id: 'b8009305-9cb6-4b42-b98a-b998c6042f9c',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    type: 'AccountAdjustment',
    group: 'Deposit',
    date: '2024-01-23T14:08:37Z',
    transaction_amount: 4000,
    transaction_currency_code: 'EUR',
    account_amount: 4000,
    account_currency_code: 'EUR',
    merchant_name: 'LEMINATECH INNOVATIONS LTD.',
    description: 'Tickets',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'AccountToAccountTransfer',
    purchase_date: '2024-01-23T14:08:37Z',
    enriched_merchant_data: {
      icon_url:
        'https://wallester-production-client-files.s3.eu-west-1.amazonaws.com/wallester-icon64x64.png',
    },
    total_amount: 4000,
  },
  {
    id: '8501fc8b-bfb7-4438-90e7-7c3fd1098700',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Authorization',
    group: 'InternetPurchase',
    date: '2024-01-23T14:05:30Z',
    transaction_amount: 0,
    transaction_currency_code: 'EUR',
    account_amount: 0,
    account_currency_code: 'USD',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'American Airlines',
    merchant_city: 'Texas',
    merchant_country_code: 'USA',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    response_code: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    purchase_date: '2024-01-23T14:05:30Z',
    enriched_merchant_data: {},
    total_amount: 0,
  },
  {
    id: '6963c906-08f6-4d9a-b4ac-a682c74b9f60',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    card_id: 'd6dfe059-49e0-412c-88b3-9168d045e705',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T13:40:31Z',
    transaction_amount: -1239.89,
    transaction_currency_code: 'EUR',
    account_amount: -1239.89,
    account_currency_code: 'EUR',
    merchant_category_code: '5211',
    merchant_id: 'merchant20',
    terminal_id: 'ITA12127',
    merchant_name: 'Booking.com',
    merchant_city: 'Amsterdam',
    merchant_country_code: 'NLD',
    original_authorization_id: 'a29e9e7b-4a27-4f19-8f23-a808ee892f73',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'd6dfe059-49e0-412c-88b3-9168d045e705'
    ),
    has_payment_document_files: false,
    card_name: 'Natalie T.',
    purchase_date: '2024-01-23T13:40:31Z',
    enriched_merchant_data: {
      name: 'Booking.Com',
      url: 'http://www.booking.com',
      domain: 'www.booking.com',
      icon_url: 'https://sandbox.enrich.zone/content/50/2d/7204.thumb.png',
    },
    total_amount: -1239.89,
  },
  {
    id: '6a9bdd48-807f-4fc2-9035-9606b5753858',
    account_id: '2f83a1a6-f79f-4353-8169-2e558ba17e10',
    type: 'AccountAdjustment',
    group: 'Deposit',
    date: '2024-01-23T10:45:58Z',
    transaction_amount: 4300,
    transaction_currency_code: 'EUR',
    account_amount: 4300,
    account_currency_code: 'EUR',
    merchant_name: 'LEMINATECH INNOVATIONS LTD.',
    description: 'Tickets + Hotels + Flights',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'AccountToAccountTransfer',
    purchase_date: '2024-01-23T10:45:58Z',
    enriched_merchant_data: {
      icon_url:
        'https://wallester-production-client-files.s3.eu-west-1.amazonaws.com/wallester-icon64x64.png',
    },
    total_amount: 4300,
  },
  {
    id: '70451bfb-550d-4d5f-be0a-14323efc3020',
    account_id: '0999c66e-78cc-442c-8a06-b5bef2efe4ca',
    card_id: '07f5d7c1-59fc-462b-bfae-2df3f97878a7',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T14:55:20Z',
    transaction_amount: -0.79,
    transaction_currency_code: 'EUR',
    account_amount: -0.79,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Google',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '4d9a90ac-552e-4eb9-881e-739423594ffa',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '07f5d7c1-59fc-462b-bfae-2df3f97878a7'
    ),
    has_payment_document_files: false,
    card_name: 'Michael R.',
    purchase_date: '2024-01-23T14:55:19Z',
    enriched_merchant_data: {
      name: 'Google',
      url: 'https://www.google.com',
      domain: 'www.google.com',
      telephone_number: '650-2530000',
      icon_url: 'https://sandbox.enrich.zone/content/f9/3b/4.svg',
    },
    total_amount: -0.79,
  },
  {
    id: '022b7c26-10fe-4424-ab87-a71894464c4a',
    account_id: '0999c66e-78cc-442c-8a06-b5bef2efe4ca',
    card_id: '07f5d7c1-59fc-462b-bfae-2df3f97878a7',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T14:54:29Z',
    transaction_amount: -133,
    transaction_currency_code: 'EUR',
    account_amount: -133,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Google Inc.',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '1a2ba5ef-f6c7-44b6-9b45-39e6864ea6df',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '07f5d7c1-59fc-462b-bfae-2df3f97878a7'
    ),
    has_payment_document_files: false,
    card_name: 'Michael R.',
    purchase_date: '2024-01-23T14:54:28Z',
    enriched_merchant_data: {
      name: 'Google',
      url: 'https://www.google.com',
      domain: 'www.google.com',
      telephone_number: '650-2530000',
      icon_url: 'https://sandbox.enrich.zone/content/f9/3b/4.svg',
    },
    total_amount: -133,
  },
  {
    id: '38fb78c5-1804-441f-a4a9-7260b4e686ec',
    account_id: '0999c66e-78cc-442c-8a06-b5bef2efe4ca',
    card_id: '07f5d7c1-59fc-462b-bfae-2df3f97878a7',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T14:54:15Z',
    transaction_amount: -13,
    transaction_currency_code: 'EUR',
    account_amount: -13,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Google Inc.',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: 'ade62f83-1b02-4e13-9884-8fbd24a574ad',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '07f5d7c1-59fc-462b-bfae-2df3f97878a7'
    ),
    has_payment_document_files: false,
    card_name: 'Michael R.',
    purchase_date: '2024-01-23T14:54:14Z',
    enriched_merchant_data: {
      name: 'Google',
      url: 'https://www.google.com',
      domain: 'www.google.com',
      telephone_number: '650-2530000',
      icon_url: 'https://sandbox.enrich.zone/content/f9/3b/4.svg',
    },
    total_amount: -13,
  },
  {
    id: 'e812dffe-5fa1-4790-b819-dbd392f8a765',
    account_id: '0999c66e-78cc-442c-8a06-b5bef2efe4ca',
    card_id: '07f5d7c1-59fc-462b-bfae-2df3f97878a7',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T14:44:26Z',
    transaction_amount: -2134,
    transaction_currency_code: 'EUR',
    account_amount: -2134,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Google Inc.',
    merchant_city: 'Amsterdam',
    merchant_country_code: 'NLD',
    original_authorization_id: '51024ecc-435a-4e08-8584-b7d2bca159fd',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '07f5d7c1-59fc-462b-bfae-2df3f97878a7'
    ),
    has_payment_document_files: false,
    card_name: 'Michael R.',
    purchase_date: '2024-01-23T14:44:25Z',
    enriched_merchant_data: {
      name: 'Google',
      url: 'https://www.google.com',
      domain: 'www.google.com',
      telephone_number: '650-2530000',
      icon_url: 'https://sandbox.enrich.zone/content/f9/3b/4.svg',
    },
    total_amount: -2134,
  },
  {
    id: 'edfbb9ff-6b79-4a01-8781-a132415ce62e',
    account_id: '0999c66e-78cc-442c-8a06-b5bef2efe4ca',
    type: 'AccountAdjustment',
    group: 'Deposit',
    date: '2024-01-23T10:40:17Z',
    transaction_amount: 3750,
    transaction_currency_code: 'EUR',
    account_amount: 3750,
    account_currency_code: 'EUR',
    merchant_name: 'LEMINATECH INNOVATIONS LTD.',
    description: 'For Campaigns @Google',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'AccountToAccountTransfer',
    purchase_date: '2024-01-23T10:40:17Z',
    enriched_merchant_data: {
      icon_url:
        'https://wallester-production-client-files.s3.eu-west-1.amazonaws.com/wallester-icon64x64.png',
    },
    total_amount: 3750,
  },
  {
    id: 'ad34a4c3-33c6-4e32-bf4e-da86e6a953b2',
    account_id: 'cc447d08-dea7-43ff-9a28-60dbc9d2e85d',
    card_id: 'a16f4865-525c-4c02-b440-d75c6717cc76',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-02-22T08:04:43Z',
    transaction_amount: -150,
    transaction_currency_code: 'EUR',
    account_amount: -150,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Wise',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '70909278-cbb1-40ef-94ff-ebe5fb163006',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      'a16f4865-525c-4c02-b440-d75c6717cc76'
    ),
    has_payment_document_files: false,
    card_name: 'Lenny G.',
    purchase_date: '2024-02-22T08:04:42Z',
    enriched_merchant_data: {},
    total_amount: -150,
  },
  {
    id: '51cd5377-8623-4387-a221-591e6944568c',
    account_id: 'cc447d08-dea7-43ff-9a28-60dbc9d2e85d',
    card_id: '7c8af33b-03ae-4f57-8656-dc3f27e3bf30',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-02-22T08:04:10Z',
    transaction_amount: -124.89,
    transaction_currency_code: 'EUR',
    account_amount: -124.89,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Google Ads',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: 'fc139275-1f14-4bb0-b13f-3745544c449b',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '7c8af33b-03ae-4f57-8656-dc3f27e3bf30'
    ),
    has_payment_document_files: false,
    card_name: 'Tim P.',
    purchase_date: '2024-02-22T08:04:09Z',
    enriched_merchant_data: {},
    total_amount: -124.89,
  },
  {
    id: 'ee05caba-7da7-48e9-80f3-bf5d2172fed4',
    account_id: 'cc447d08-dea7-43ff-9a28-60dbc9d2e85d',
    card_id: '7c8af33b-03ae-4f57-8656-dc3f27e3bf30',
    type: 'Fee',
    group: 'AuthorizationATMWithdrawalEEAComplexFee',
    date: '2024-01-23T14:19:47Z',
    transaction_amount: -3,
    transaction_currency_code: 'EUR',
    account_amount: -3,
    account_currency_code: 'EUR',
    merchant_name: 'Wallester',
    description: 'AuthorizationATMWithdrawalEEAComplexFee',
    original_authorization_id: '473d2231-0af8-4bab-83d7-73f02d4a0bc1',
    is_reversal: false,
    is_declined: false,
    is_cleared: true,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '7c8af33b-03ae-4f57-8656-dc3f27e3bf30'
    ),
    has_payment_document_files: false,
    card_name: 'Tim P.',
    sub_type: 'AuthorizationATMWithdrawalEEAComplexFee',
    purchase_date: '2024-01-23T14:19:47Z',
    enriched_merchant_data: {},
    total_amount: -3,
  },
  {
    id: '0776e9a7-80a0-49f4-bea6-ab5b77560005',
    account_id: 'cc447d08-dea7-43ff-9a28-60dbc9d2e85d',
    card_id: '7c8af33b-03ae-4f57-8656-dc3f27e3bf30',
    type: 'Transaction',
    group: 'Withdraw',
    date: '2024-01-23T14:19:47Z',
    transaction_amount: -150,
    transaction_currency_code: 'EUR',
    account_amount: -150,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Swedbank',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '473d2231-0af8-4bab-83d7-73f02d4a0bc1',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '7c8af33b-03ae-4f57-8656-dc3f27e3bf30'
    ),
    has_payment_document_files: false,
    card_name: 'Tim P.',
    purchase_date: '2024-01-23T14:19:46Z',
    enriched_merchant_data: {},
    total_amount: -150,
  },
  {
    id: '2df99968-e066-4072-83bb-ee82ef508684',
    account_id: 'cc447d08-dea7-43ff-9a28-60dbc9d2e85d',
    card_id: '7c8af33b-03ae-4f57-8656-dc3f27e3bf30',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T14:19:19Z',
    transaction_amount: -1199,
    transaction_currency_code: 'EUR',
    account_amount: -1199,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Apple',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: 'a4a39136-f073-47f8-9642-76f48a81f633',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '7c8af33b-03ae-4f57-8656-dc3f27e3bf30'
    ),
    has_payment_document_files: false,
    card_name: 'Tim P.',
    purchase_date: '2024-01-23T14:19:18Z',
    enriched_merchant_data: {},
    total_amount: -1199,
  },
  {
    id: '07e8c1df-1cd7-419e-8fdd-060b123b9f20',
    account_id: 'cc447d08-dea7-43ff-9a28-60dbc9d2e85d',
    card_id: '7c8af33b-03ae-4f57-8656-dc3f27e3bf30',
    type: 'Transaction',
    group: 'InternetPurchase',
    date: '2024-01-23T14:18:36Z',
    transaction_amount: -434,
    transaction_currency_code: 'EUR',
    account_amount: -434,
    account_currency_code: 'EUR',
    merchant_category_code: '6010',
    merchant_id: 'merchant22',
    terminal_id: 'ITA12127',
    merchant_name: 'Ikea',
    merchant_city: 'Tallinn',
    merchant_country_code: 'EST',
    original_authorization_id: '67ea41dd-84fa-4412-a9e1-a1cbed45784e',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    masked_card_number: getMaskedCardNumberById(
      '7c8af33b-03ae-4f57-8656-dc3f27e3bf30'
    ),
    has_payment_document_files: false,
    card_name: 'Tim P.',
    purchase_date: '2024-01-23T14:18:35Z',
    enriched_merchant_data: {
      name: 'Ikea',
      url: 'https://www.ikea.ee/et',
      domain: 'www.ikea.ee',
      icon_url: 'https://sandbox.enrich.zone/content/82/0d/2999.thumb.png',
    },
    total_amount: -434,
  },
  {
    id: '8cd93f72-48ab-4f8c-a17d-4e4ca61295ef',
    account_id: 'cc447d08-dea7-43ff-9a28-60dbc9d2e85d',
    type: 'AccountAdjustment',
    group: 'Deposit',
    date: '2024-01-23T10:41:24Z',
    transaction_amount: 6500,
    transaction_currency_code: 'EUR',
    account_amount: 6500,
    account_currency_code: 'EUR',
    merchant_name: 'LEMINATECH INNOVATIONS LTD.',
    description: 'Q3 Balance Adjust',
    is_reversal: false,
    is_declined: false,
    is_cleared: false,
    status: 'Completed',
    response: 'Approved',
    has_payment_document_files: false,
    sub_type: 'AccountToAccountTransfer',
    purchase_date: '2024-01-23T10:41:24Z',
    enriched_merchant_data: {
      icon_url:
        'https://wallester-production-client-files.s3.eu-west-1.amazonaws.com/wallester-icon64x64.png',
    },
    total_amount: 6500,
  },
];

const DEMO_PAYMENT_DOCUMENTS: PaymentDocumentFile[] = [
  {
    id: '3b33ebe5-66e3-488e-ade1-d05548110af5',
    payment_id: DEMO_ACCOUNT_STATEMENTS[1].id,
    payment_type: 'transaction',
    file_name: 'demo.pdf',
    mime_type: 'application/pdf',
    file_body: '',
    created_at: moment().toISOString(),
    created_by: DEMO_USER_ID,
  },
];

// RUS
const DEMO_MASS_PAYMENTS: Record<UUID4, MassPaymentsPaymentWithFileId[]> = {
  [DEMO_PRODUCT_ID]: [
    {
      id: '446c8ec4-5d08-4852-809b-c7b7c77533f7',
      name: 'Tim P.',
      iban: 'EE7598024759522345324',
      amount: 50,
      description: 'Gym',
      status: 'Rejected',
      reject_reason: 'Other',
      created_at: '2024-01-24T09:43:09.133839Z',
      created_by: DEMO_USER_ID,
      file_id: '2f5680c2-7c18-4bb5-91ca-3db9c24dd546',
    },
    {
      id: '5b72b97b-9d79-4c09-9ca4-b6175b774103',
      name: 'Blade F.',
      iban: 'EE5745890248374',
      amount: 345,
      description: 'Compensation',
      status: 'Rejected',
      reject_reason: 'Other',
      created_at: '2024-01-24T09:43:09.133893Z',
      created_by: DEMO_USER_ID,
      updated_at: '2024-01-24T09:43:34.437834Z',
      updated_by: 'SYSTEM',
      file_id: '2f5680c2-7c18-4bb5-91ca-3db9c24dd546',
    },
    {
      id: '61080f9c-aa93-445c-a7ac-c0558f5b2e83',
      name: 'Blade F.',
      iban: 'EE5745890248374',
      amount: 10,
      description: 'Food',
      status: 'Rejected',
      reject_reason: 'Other',
      created_at: '2024-01-24T09:43:09.133851Z',
      created_by: DEMO_USER_ID,
      updated_at: '2024-01-24T09:43:28.697585Z',
      updated_by: 'SYSTEM',
      file_id: '2f5680c2-7c18-4bb5-91ca-3db9c24dd546',
    },
    {
      id: '68f882dd-8d3d-448f-b48b-db6fc3fbf3a3',
      name: 'Lenny G.',
      iban: 'EE57891027458943759434',
      amount: 250,
      description: 'Bonus',
      status: 'Rejected',
      reject_reason: 'Other',
      created_at: '2024-01-24T09:43:09.133883Z',
      created_by: DEMO_USER_ID,
      updated_at: '2024-01-24T09:43:36.645143Z',
      updated_by: 'SYSTEM',
      file_id: '2f5680c2-7c18-4bb5-91ca-3db9c24dd546',
    },
    {
      id: '8f71f4e4-2a38-4635-b355-3cfab9455c57',
      name: 'Michael R.',
      iban: 'EE5468756359071345611',
      amount: 10,
      description: 'Food',
      status: 'Rejected',
      reject_reason: 'Other',
      created_at: '2024-01-24T09:43:09.133825Z',
      created_by: DEMO_USER_ID,
      updated_at: '2024-01-24T09:43:36.54832Z',
      updated_by: 'SYSTEM',
      file_id: '2f5680c2-7c18-4bb5-91ca-3db9c24dd546',
    },
    {
      id: 'e3e1c66f-f819-4d10-b491-2c975b5e1f6f',
      name: 'Lenny G.',
      iban: 'EE57891027458943759434',
      amount: 100,
      description: 'Bonus',
      status: 'Rejected',
      reject_reason: 'Other',
      created_at: '2024-01-24T09:43:09.133873Z',
      created_by: DEMO_USER_ID,
      updated_at: '2024-01-24T09:43:28.158071Z',
      updated_by: 'SYSTEM',
      file_id: '2f5680c2-7c18-4bb5-91ca-3db9c24dd546',
    },
    {
      id: 'e7410aed-6e25-475e-9595-a677bc833484',
      name: 'Henry T.',
      iban: 'EE99398129940139483293',
      amount: 50,
      description: 'Gym',
      status: 'Rejected',
      reject_reason: 'Other',
      created_at: '2024-01-24T09:43:09.133862Z',
      created_by: DEMO_USER_ID,
      updated_at: '2024-01-24T09:43:32.839614Z',
      updated_by: 'SYSTEM',
      file_id: '2f5680c2-7c18-4bb5-91ca-3db9c24dd546',
    },
    {
      id: '2bfe7dcc-13d0-4fb9-ad6b-144138a4185c',
      name: 'Lenny G.',
      iban: 'EE11111111111111',
      amount: 1000,
      description: 'Bonus',
      status: 'Rejected',
      reject_reason: 'Other',
      created_at: '2024-01-24T09:27:41.560754Z',
      created_by: DEMO_USER_ID,
      updated_at: '2024-01-24T09:41:58.262428Z',
      updated_by: 'SYSTEM',
      file_id: 'a3b9d87c-d29b-4655-a352-0b4131c9c7e4',
    },
    {
      id: '58b6a781-1c63-4353-9847-3a54f6dfc992',
      name: 'Henry T.',
      iban: 'EE91111111111111',
      amount: 50,
      description: 'Gym',
      status: 'Rejected',
      reject_reason: 'Other',
      created_at: '2024-01-24T09:27:41.56074Z',
      created_by: DEMO_USER_ID,
      updated_at: '2024-01-24T09:41:53.933913Z',
      updated_by: 'SYSTEM',
      file_id: 'a3b9d87c-d29b-4655-a352-0b4131c9c7e4',
    },
    {
      id: '73bf7080-aae6-45df-bce9-92d8c4b2cf3e',
      name: 'Tim P.',
      iban: 'EE341111111111132',
      amount: 50,
      description: 'Gym',
      status: 'Rejected',
      reject_reason: 'Other',
      created_at: '2024-01-24T09:27:41.560714Z',
      created_by: DEMO_USER_ID,
      updated_at: '2024-01-24T09:41:57.757134Z',
      updated_by: 'SYSTEM',
      file_id: 'a3b9d87c-d29b-4655-a352-0b4131c9c7e4',
    },
    {
      id: '7f097141-d99f-4083-8dd3-a1e733b084e8',
      name: 'Michael R.',
      iban: 'EE111111111111111',
      amount: 35,
      description: 'Food',
      status: 'Rejected',
      reject_reason: 'Other',
      created_at: '2024-01-24T09:27:41.5607Z',
      created_by: DEMO_USER_ID,
      updated_at: '2024-01-24T09:41:56.030805Z',
      updated_by: 'SYSTEM',
      file_id: 'a3b9d87c-d29b-4655-a352-0b4131c9c7e4',
    },
    {
      id: 'c42419d7-ecb7-495f-a3ff-f681b3105853',
      name: 'Blade F.',
      iban: 'EE1111111111111',
      amount: 25,
      description: 'Food',
      status: 'Rejected',
      reject_reason: 'Other',
      created_at: '2024-01-24T09:27:41.560727Z',
      created_by: DEMO_USER_ID,
      updated_at: '2024-01-24T09:41:58.17332Z',
      updated_by: 'SYSTEM',
      file_id: 'a3b9d87c-d29b-4655-a352-0b4131c9c7e4',
    },
  ],
};

const DEMO_MASS_PAYMENTS_FILES: Record<UUID4, MassPaymentsFile[]> = {
  [DEMO_PRODUCT_ID]: [
    {
      id: '2f5680c2-7c18-4bb5-91ca-3db9c24dd546',
      file_name: 'SAMPLE XLS v2.xlsx',
      from_account_id: DEMO_MAIN_ACCOUNT_ID,
      from_account_iban: 'EE427700771005692629',
      from_account_name: '',
      amount: 815,
      current_amount_of_payments: 0,
      total_number_of_payments: 7,
      current_number_of_payments: 0,
      status: 'Review',
      created_at: '2024-01-24T09:43:09.128455Z',
      created_by: DEMO_USER_ID,
      confirmed_at: '2024-01-24T09:43:17.986458Z',
      confirmed_by: DEMO_USER_ID,
    },
    {
      id: 'a3b9d87c-d29b-4655-a352-0b4131c9c7e4',
      file_name: 'SAMPLE XLS v1.xlsx',
      from_account_id: DEMO_MAIN_ACCOUNT_ID,
      from_account_iban: 'EE427700771005692629',
      from_account_name: '',
      amount: 1160,
      current_amount_of_payments: 0,
      total_number_of_payments: 5,
      current_number_of_payments: 0,
      status: 'Review',
      created_at: '2024-01-24T09:27:41.554974Z',
      created_by: DEMO_USER_ID,
      confirmed_at: '2024-01-24T09:41:49.002001Z',
      confirmed_by: DEMO_USER_ID,
    },
  ],
};

const DEMO_TOP_UP_HISTORY: Record<UUID4, TopUpHistoryItem[]> = {
  [DEMO_PRODUCT_ID]: [
    {
      id: '96131e43-f63e-4aa6-b281-b53c607fa998',
      created_at: '2021-01-01 12:12:12',
      amount: 202.4,
      account_id: DEMO_MAIN_ACCOUNT_ID,
      type: 'CardTopUp',
      description: '',
      account_name: 'LEMINATECH INNOVATIONS LTD.',
      sender_iban: 'IBAN',
      top_up_amount: 200,
      sender_name: 'sender',
      fees: [
        {
          id: '779a64dc-1bb2-48f9-81fc-6c5c75e6678c',
          amount: 2.4,
          type: 'CardTopUpPercentageFee',
          completed_at: '2021-01-01 12:12:12',
        },
      ],
    },
    {
      id: '5eae31e8-4db3-49da-a35c-e3e56c48875d',
      created_at: '2021-01-02 12:12:12',
      amount: 1000,
      account_id: DEMO_MAIN_ACCOUNT_ID,
      type: 'IncomingSEPAPayment',
      description: '',
      account_name: 'LEMINATECH INNOVATIONS LTD.',
      sender_iban: 'IBAN',
      top_up_amount: 1000,
      sender_name: 'sender',
      fees: [],
    },
  ],
};

const DEMO_API_KEYS: Record<UUID4, IApiKey[]> = {
  [DEMO_PRODUCT_ID]: [
    {
      id: 'e6380deb-2cbb-4e89-8e7a-5a74278bc399',
      name: 'LEMINATECH API',
      status: 'Active',
      created_at: '2024-01-23T15:47:53Z',
      created_by: DEMO_USER_ID,
    },
  ],
};

export const DemoInitData: DemoData = {
  // KUSH
  user: DEMO_USER,
  tokenInfo: DEMO_TOKEN_INFO,
  assignedProduct: DEMO_ASSIGNED_PRODUCT,
  serverStorage: DEMO_SERVER_STORAGE_DATA,
  allowedEndpoints: DEMO_ALLOWED_ENDPOINTS,
  clients: DEMO_CLIENTS,
  productId: DEMO_PRODUCT_ID,
  defaultProfilePermissions: DEMO_DEFAULT_PROFILE_PERMISSIONS,
  productSettings: DEMO_PRODUCT_SETTINGS,
  reservedSettlementBalance: DEMO_RESERVED_SETTLEMENT_BALANCE,
  settlementPaymentDetails: DEMO_SETTLEMENT_PAYMENT_DETAILS,
  accounts: DEMO_ACCOUNTS,
  pricingPlans: DEMO_PRICING_PLANS,
  pricingPlan: DEMO_PRICING_PLAN,
  cardDispatchMethods: DEMO_DEFAULT_CARD_DISPATCH_METHODS,
  companies: DEMO_COMPANIES,
  companyLimitsUsage: DEMO_COMPANY_LIMITS_USAGE,
  accountLimitsUsage: DEMO_ACCOUNT_LIMITS_USAGE,
  corporateDocuments: DEMO_CORPORATE_DOCUMENTS,
  verificationPersonsAndCompanies: [
    {
      person: DEMO_VERIFICATION_PERSONS[0],
      company: DEMO_VERIFICATION_COMPANIES[0],
    },
  ],
  productUsers: DEMO_PRODUCT_USERS,
  roles: DEMO_ROLES,
  // EGOR R
  // EGOR O
  statements: DEMO_ACCOUNT_STATEMENTS,
  cards: DEMO_CARDS,
  employees: DEMO_EMPLOYEES,
  paymentDocuments: DEMO_PAYMENT_DOCUMENTS,
  // RUS
  massPaymentsFiles: DEMO_MASS_PAYMENTS_FILES,
  massPayments: DEMO_MASS_PAYMENTS,
  apiKeys: DEMO_API_KEYS,
  currencyRequestConfig: DEMO_CURRENCY_REQUEST_CONFIG,
  version: DEMO_VERSION,
  topUpHistory: DEMO_TOP_UP_HISTORY,
};

export default DemoInitData;
