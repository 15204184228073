export default function ObjectKeys<T extends Record<string, unknown>>(
  obj: T
): (keyof T)[] {
  return Object.keys(obj);
}

export const removeUndefinedValues = <T extends Record<string, unknown>>(
  obj: T
): T => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'undefined') {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });

  return obj;
};
