import {ComponentType} from 'react';
import {IntlShape} from 'react-intl';
import {RouteComponentProps} from 'react-router-dom';
import Endpoints from '../constants/endpoints';
import Features from '../constants/features';
import Permissions from '../constants/permissions';
import {ProgramType} from './account';

export type RouteTitleResolver = (
  params: Record<string, string>,
  intl: IntlShape
) => string;

export type RouteTitle = string | RouteTitleResolver;

export type AccessConfiguration = {
  productProgramType?: ProgramType;
  blackOnly?: boolean;
  whiteOnly?: boolean;
  allowUnverifiedUser?: boolean;
};

export type CustomRoutesPropsReact = AccessConfiguration & {
  component?: ComponentType<RouteComponentProps>;
  path: string;
  exact: boolean;
  page?: string;
  title?: RouteTitle;
  disabled?: boolean;
};

export type RouteInfo = AccessConfiguration & {
  path: string;
  permission?: Permissions | Permissions[];
  feature?: Features;
  endpoints?: Endpoints | Endpoints[];
  permissionsPartialMode?: boolean;
  endpointsPartialMode?: boolean;
  demoRestricted?: boolean;
  isNotMain?: boolean;
};

export enum GoToLoginMode {
  Expired = 'se',
  Rejected = 'rejected',
}
