import clsx from 'clsx';
import React from 'react';
import css from './InfoPost.module.less';

interface Props {
  className?: string;
  media?: React.ReactNode;
  title?: React.ReactNode;
  desc?: React.ReactNode;
}

const InfoPost: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  media,
  title,
  desc,
}) => {
  return (
    <div className={clsx(css.infoPost, className)}>
      {!!media && <div className={css.infoPost__media}>{media}</div>}

      {!!title && <div className={css.infoPost__title}>{title}</div>}

      {!!desc && <div className={css.infoPost__desc}>{desc}</div>}
    </div>
  );
};

export default InfoPost;
