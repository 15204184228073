import {useMemo} from 'react';
import Permissions from '../constants/permissions';
import useGetCheckerPermissions from './useGetCheckerPermissions';

export const useCheckPermissions = (
  permissions: Permissions | Permissions[],
  partialMode?: boolean
): boolean => {
  const checkPermissions = useGetCheckerPermissions();

  return useMemo<boolean>(() => {
    return checkPermissions(permissions, partialMode);
  }, [checkPermissions, partialMode, permissions]);
};

export default useCheckPermissions;
