import React from 'react';
import {RouterProps} from 'react-router';
import {Route} from 'react-router-dom';
import {UserLocale} from '../../types';
import {history} from '../../models/router';
import LangRedirector from './LangRedirector';

interface Props {
  RouterComponent: React.ComponentClass<RouterProps>;
  defaultLanguage?: UserLocale;
  language?: UserLocale;
}

const LocalizedRouter: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  RouterComponent,
  language,
  defaultLanguage,
}) => {
  return (
    <RouterComponent history={history}>
      <Route path="/:lang([a-z]{2})">
        {(props) => {
          return (
            <LangRedirector
              match={props.match}
              location={props.location}
              language={language}
              defaultLanguage={defaultLanguage}
            >
              {children}
            </LangRedirector>
          );
        }}
      </Route>
    </RouterComponent>
  );
};
export default LocalizedRouter;
