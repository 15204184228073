import {
  combine,
  createEffect,
  createEvent,
  createStore,
  Store as EffectorStore,
  StoreWritable,
} from 'effector';
import {
  assignProfile,
  getProductContactInfo,
  getProductSettings,
  getReservedSettlementBalance,
} from '../../api';
import Endpoints from '../../constants/endpoints';
import Features, {TreatmentStatus} from '../../constants/features';
import Permissions from '../../constants/permissions';
import AuthService from '../../services/AuthService';
import SavedFiltersService from '../../services/SavedFiltersService';
import {
  AssignProfileResponse,
  CurrencyType,
  IProduct,
  IProductBalance,
  IProductContactInfo,
  IProductWithProfile,
  ReservedSettlementBalance,
  SettlementPaymentDetails,
  UUID4,
} from '../../types';
import isAllowedEndpoint from '../../utils/freeze';
import {makePermissions} from '../../utils/permissions';
import RequestCache from '../../utils/RequestCache';
import clearAppStores from '../clearStore';
import CurrentUserStore from '../currentUser';
import FeatureStore, {FeatureStoreType} from '../features';
import {setLastProfileId} from '../serverStorage';

export const defaultProduct: IProduct = {
  balance: 0,
  currency_code: 'EUR',
  reserved_settlement_balance: 0,
  reserved_currency_code: 'EUR',
  icon: '',
  id: '',
  client_name: '',
  code: '',
  authorizations_count: 0,
  profile_id: '',
  authorizations_total_reserved_amount: 0,
  paymentDetails: {
    payment_details: '',
    iban: '',
    receiver_name: '',
    swift_code: '',
    registration_number: '',
    bank_address: '',
    bank_name: '',
  },
  productSettings: {
    WeeklyLimitsEnabled: true,
    PersonalizationProductCodeThreeLineEmbossingName: [],
    PersonalizationProductCodeTwoLineEmbossingName: [],
  },
  product_type: 'WhiteLabel',
};

export const ProductInnerStore = createStore<IProduct | null>(null);
ProductInnerStore.reset(clearAppStores);

export const ProductStore = combine<
  StoreWritable<IProduct | null>,
  EffectorStore<FeatureStoreType>,
  IProduct | null
>(ProductInnerStore, FeatureStore, (innerState, featureStore) => {
  if (
    featureStore.commonIsReady &&
    (featureStore.treatments || {})[Features.EnableDebtResolutionFlow]
      ?.treatment === TreatmentStatus.Enabled
  ) {
    return innerState;
  }
  if (innerState) {
    return {
      ...innerState,
      product_additional_info: {
        ...innerState.product_additional_info,
        has_debts: false,
      },
    };
  }
  return null;
});

export const setCurrentProduct = createEvent<IProductWithProfile>();

setCurrentProduct.watch(async (product) => {
  SavedFiltersService.getInstance().reloadLocalState(product.id);
  await AuthService.getInstance().updateTokenProductId(
    product.id,
    product.profile_id
  );
});

export const loadBalance = createEffect<void, IProductBalance, Error>({
  name: 'loadBalance',
  handler: async () => {
    const currentProduct = ProductInnerStore.getState();
    if (currentProduct !== null) {
      const rsb = await getReservedSettlementBalance(currentProduct.id);
      return {balance: rsb.amount, currency_code: rsb.currency_code};
    }
    return {balance: 0, currency_code: 'EUR' as CurrencyType};
  },
});

export const loadContactInfo = createEffect<void, IProductContactInfo, Error>({
  name: 'loadContactInfo',
  handler: async () => {
    const currentProduct = ProductInnerStore.getState();
    if (currentProduct !== null) {
      return getProductContactInfo(currentProduct.id);
    }
    return {};
  },
});

interface TopUpInfo extends ReservedSettlementBalance {
  paymentDetails: SettlementPaymentDetails;
}

export const assignProfileFx = createEffect<
  UUID4,
  AssignProfileResponse,
  Error
>({
  name: 'assignProfileFx',
  handler: async (profileId) => {
    return assignProfile(profileId);
  },
});

assignProfileFx.done.watch(({params: profileId}) => {
  setLastProfileId(profileId);
  RequestCache.getInstance().clearCache();
});

export const loadTopUpInfo = createEffect<UUID4 | undefined, TopUpInfo, Error>({
  name: 'loadTopUpInfo',
  handler: async (productId) => {
    const authService = AuthService.getInstance();
    let balance: ReservedSettlementBalance;
    try {
      if (
        authService.checkPermissions([
          Permissions.PermissionViewSettlementBalance,
        ]) &&
        isAllowedEndpoint(Endpoints.getReservedSettlementBalance)
      ) {
        balance = await getReservedSettlementBalance(productId);
      } else {
        throw new Error();
      }
    } catch (e) {
      balance = {
        amount: 0,
        pending_authorizations_count: 0,
        pending_authorizations_total_reserved_amount: 0,
        authorizations_total_reserved_amount: 0,
        authorizations_count: 0,
        currency_code: 'EUR',
      };
    }
    let {paymentDetails} = defaultProduct;

    try {
      const productSettings = await getProductSettings();
      if (productSettings) {
        paymentDetails = {
          bank_address: productSettings.settlement_payment_details_bank_address,
          bank_name: productSettings.settlement_payment_details_bank_name,
          iban: productSettings.settlement_payment_details_iban,
          payment_details: productSettings.settlement_payment_details,
          receiver_name:
            productSettings.settlement_payment_details_receiver_name,
          registration_number: '',
          swift_code: productSettings.settlement_payment_details_swift_code,
        };
      }
    } catch (e) {
      paymentDetails = defaultProduct.paymentDetails;
    }

    return {
      ...balance,
      paymentDetails,
    };
  },
});

ProductInnerStore.on(setCurrentProduct, (Store, payload) => {
  const state = Store || defaultProduct;
  return {...state, ...payload};
});

ProductInnerStore.on(
  loadBalance.doneData,
  (Store, {balance, currency_code}) => {
    const state = Store || defaultProduct;
    return {...state, balance, currency_code};
  }
);

ProductInnerStore.on(loadContactInfo.doneData, (Store, contactInfo) => {
  const state = Store || defaultProduct;
  return {...state, ...contactInfo};
});

ProductInnerStore.on(loadTopUpInfo.doneData, (Store, payload) => {
  if (Store) {
    return {
      ...Store,
      amount: payload.amount,
      authorizations_count: payload.authorizations_count,
      authorizations_total_reserved_amount:
        payload.authorizations_total_reserved_amount,
      pending_authorizations_count: payload.pending_authorizations_count,
      pending_authorizations_total_reserved_amount:
        payload.pending_authorizations_total_reserved_amount,
      reserved_settlement_balance: payload.amount,
      reserved_currency_code: payload.currency_code,
      paymentDetails: payload.paymentDetails,
    };
  }
  return Store;
});

CurrentUserStore.on(
  assignProfileFx.doneData,
  (Store, {profile_permission_ids}) => {
    if (Store) {
      return {
        ...Store,
        permissions: {
          user: Store?.permissions?.user,
          profile: makePermissions(profile_permission_ids || []),
        },
      };
    }
    return Store;
  }
);
