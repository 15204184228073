import {useMemo} from 'react';
import Endpoints from '../constants/endpoints';
import useGetCheckerEndpoints from './useGetCheckerEndpoints';

const useCheckEndpoints = (
  endpoints: Endpoints[],
  partialMode?: boolean
): boolean => {
  const checkEndpoints = useGetCheckerEndpoints();

  return useMemo(() => {
    return checkEndpoints(endpoints, partialMode);
  }, [checkEndpoints, endpoints, partialMode]);
};

export default useCheckEndpoints;
