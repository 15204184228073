import moment from 'moment-timezone';
import {MerchantFilterType, StatementStatus} from './account';

export type UUID4 = string;

export type Base64Image = string;

export type DateTime = string;

export interface LoadingProps {
  skeleton?: boolean;
  loading?: boolean;
}

export type OrderBy = {
  orderField?: string;
  orderDirection?: string;
};

export type PagerProps = {
  currentPage: number;
  pageSize: number;
};

export interface IAccountStatementFilters {
  statuses?: StatementStatus[];
  merchantTypes?: MerchantFilterType[];
  includeTransactions?: boolean;
  includeAuthorizations?: boolean;
  includeAccountAdjustments?: boolean;
  includeFees?: boolean;
  excludeDeclinedAuthorizations?: boolean;
  excludePendingAuthorizations?: boolean;
  excludePendingFees?: boolean;
  excludeDeclinedFees?: boolean;
  excludeReversedAuthorizations?: boolean;
  excludeClearedAuthorizations?: boolean;
  selectedAccountId?: UUID4;
}

export interface DatePicker {
  fromDate?: moment.Moment;
  toDate?: moment.Moment;
}

export interface Selected {
  selectedIDs?: string[];
}

export type EmptyFilter = {
  isActive: boolean;
};

export interface TFiltersPreset<T> {
  title: string;
  id: UUID4;
  created_at: string;
  filters?: T;
}

export interface TFiltersPresets<T> {
  filtersPresets?: TFiltersPreset<T>[];
  selectedFiltersPresetId?: UUID4;
  isShowedPresetMenuTooltip?: boolean;
}

export type TablePagerSorterParams = PagerProps & OrderBy;

export type TColumns = string[];

export interface IListState<T, P = EmptyFilter>
  extends PagerProps,
    Selected,
    OrderBy,
    DatePicker {
  items: T[];
  totalItems?: number;
  loading: boolean;
  loaded: boolean;
  filters?: P;
  columns?: TColumns;
}

export const BadgeColors = [
  'white',
  'green',
  'emeraldGreen',
  'extraGreen',
  'blue',
  'extraBlue',
  'lightBlue',
  'yellow',
  'sunnyYellow',
  'red',
  'orange',
  'carminePink',
  'gray',
];

export type TBadgeColors = typeof BadgeColors[number];

export type TLabelColors = 'green' | 'yellow';

export type TTooltipColors = 'white' | 'blue';

export type TypedMap<T extends string, S = T> = {
  [K in T]?: S;
};

export type HeaderBackActionConfirm = () => Promise<boolean>;

export type RequestWithSortsOrders<
  T extends Record<string, unknown> = Record<string, unknown>
> = Omit<T, 'orderField' | 'orderDirection' | 'currentPage' | 'pageSize'> & {
  orderField?: string[];
  orderDirection?: string[];
  currentPage: number;
  pageSize: number;
};

export type ListSortOrderParams = {
  from_record?: number;
  records_count?: number;
  order_field?: string;
  order_direction?: string;
};

export type ToListApiRequestCustomFormatterData = Record<
  string,
  string | number | string[] | number[] | boolean | null
>;

export type ToListApiRequestData = Record<
  string,
  string | number | string[] | number[] | boolean
>;

export type BooleanString = 'true' | 'false';

export enum Pages {
  BlackDashboard = 'blackDashboard',
  BlackStatements = 'blackStatements',
  BlackAccounts = 'blackAccounts',
  Cards = 'cards',
  Companies = 'companies',
  FraudCases = 'fraudCases',
  FraudCaseHistory = 'fraudCaseHistory',
  FraudCaseAuthorizations = 'fraudCaseAuthorizations',
  CardHistory = 'cardHistory',
  CompanyHistory = 'companyHistory',
  CompanyAlertsHistory = 'companyAlertsHistory',
  CompanyCards = 'companyCards',
  CompanyAccounts = 'companyAccounts',
  CompanyRepresentatives = 'companyRepresentatives',
  CardAuthorizations = 'cardAuthorizations',
  CardTransactions = 'cardTransactions',
  CardTokens = 'cardTokens',
  Customers = 'customers',
  CustomerCards = 'customerCards',
  CustomerHistory = 'customerHistory',
  CustomerAlertsHistory = 'customerAlertsHistory',
  CustomerCardBlockCalls = 'customerCardBlockCalls',
  CustomerAccounts = 'customerAccounts',
  BlackUsers = 'blackUsers',
  BlackEmployees = 'blackEmployees',
  BlackTopUp = 'blackTopUp',
  BlackCardTransactions = 'blackCardTransactions',
  ExpiringCards = 'expiringCards',
  PendingCards = 'pendingCards',
  PaymentTransactions = 'paymentTransactions',
  PaymentAuthorizations = 'paymentAuthorizations',
  WBApiKeys = 'wbApiKeys',
  AccountStatements = 'accountStatements',
  AccountCards = 'accountCards',
  AccountHistory = 'accountHistory',
  Users = 'users',
  BlackCards = 'blackCards',
  Employees = 'employees',
  MultiplePayments = 'multiplePayments',
  MultiplePaymentsFile = 'multiplePaymentsFile',
}

export type MomentFormatter = (
  momentObject: moment.Moment,
  format: string
) => string;
