import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import {ServerStorageData} from '../types';
import WallesterAxios from '../utils/WAxios';

const getServerStorageKeys = async (
  keys?: string[],
  prefixes?: string[]
): Promise<ServerStorageData> => {
  const params: Record<string, string[]> = {
    key: [],
    prefix: [],
  };
  if (keys !== undefined) {
    keys.forEach((key) => {
      params.key.push(key);
    });
  }
  if (prefixes !== undefined) {
    prefixes.forEach((prefix) => {
      params.prefix.push(prefix);
    });
  }

  const response: AxiosResponse<ServerStorageData> = await WallesterAxios.getInstance()
    .getCommon()
    .get({
      endpoint: Endpoints.getServerStorageData,
      queryParams: params,
      stupidArrayFormat: true,
    });

  return response.data;
};

export const setServerStorageValue = async (
  data: ServerStorageData
): Promise<ServerStorageData> => {
  // log.info(`Store server value [${JSON.stringify(data)}]`);
  // return data;
  const response: AxiosResponse<'ok'> = await WallesterAxios.getInstance()
    .getCommon()
    .post({endpoint: Endpoints.setServerStorageData}, data);
  if (response.data === 'ok') {
    return data;
  }
  return {};
};

export const setServerStorageValueByAccessToken = async (
  data: ServerStorageData,
  token: string
): Promise<ServerStorageData> => {
  // log.info(`Store server value [${JSON.stringify(data)}]`);
  // return data;
  const response: AxiosResponse<'ok'> = await WallesterAxios.getInstance()
    .getBaseInstance()
    .post({endpoint: Endpoints.setServerStorageData}, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  if (response.data === 'ok') {
    return data;
  }
  return {};
};

export default getServerStorageKeys;
