// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const map = <T = number>(
  times: number,
  callback: (i: number) => T = (i) => i as never
): T[] => {
  const arr = [];

  for (let i = 0; i < times; i += 1) {
    arr.push(callback(i));
  }

  return arr;
};

export default map;
