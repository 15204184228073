import React from 'react';
import clsx from 'clsx';
import css from './ModalMagic.module.less';
import ucFirst from '../../utils/string';
import {TooltipProps} from '../Tooltip';

interface Props {
  className?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  actions?: React.ReactNode;
  visible?: boolean;
  alwaysVisible?: boolean;
  arrowed?: boolean;
  arrowPlacement?: TooltipProps['placement'];
  color?: 'white';
  actionsIconed?: boolean;
}

const ModalMagic: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  title,
  description,
  actions,
  visible,
  alwaysVisible,
  arrowed,
  arrowPlacement = 'bottom',
  color,
  actionsIconed = true,
}) => {
  return (
    <div
      className={clsx(
        css.modal,
        visible ? css.modal_visible : '',
        alwaysVisible ? css.modal_alwaysVisible : '',
        arrowed ? css.modal_arrowed : '',
        actionsIconed ? css.modal_actionsIconed : '',
        arrowed && arrowPlacement
          ? css[`modal_arrowPlacement${ucFirst(arrowPlacement)}`]
          : '',
        color ? css[`modal_color${ucFirst(color)}`] : '',
        className
      )}
    >
      <div className={css.modal__inner}>
        {!!title && <div className={css.modal__title}>{title}</div>}

        {!!description && (
          <div className={css.modal__description}>{description}</div>
        )}

        {!!actions && <div className={css.modal__actions}>{actions}</div>}
      </div>
    </div>
  );
};

export default ModalMagic;
