import download from 'downloadjs';
import {WLEndpoints} from '../constants/endpoints';
import {IExtractionFile} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const getFilesDirectorys = async (): Promise<string[]> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get(getApiPathParamsMaker(WLEndpoints.getFilesDirectories));
  return response.data.directories;
};

export const getFilesInDirectory = async (
  name: string
): Promise<IExtractionFile[]> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get(
      getApiPathParamsMaker(WLEndpoints.getFilesListInDirectory, {
        directory_name: name,
      })
    );
  return response.data.files;
};

export const downloadFileByName = async (
  dirName: string,
  fileName: string
): Promise<void> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get(
      getApiPathParamsMaker(WLEndpoints.downloadFileByName, {
        directory_name: dirName,
        file_name: fileName,
      })
    );
  if (response.status === 200) {
    download(response.data, fileName);
    return Promise.resolve();
  }
  throw Error(response.data);
};
