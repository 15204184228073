import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import {
  UUID4,
  ClientCompany,
  ClientCompanyCreateParams,
  ClientCompanyUpdateParams,
  ClientPerson,
  ClientPersonCreateParams,
  ClientPersonUpdateParams,
  ClientPersonResendVerificationLinkTransport,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const createClientPerson = async (
  person: ClientPersonCreateParams
): Promise<ClientPerson> => {
  const response: AxiosResponse<{
    person: ClientPerson;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.clientVerificationAddPerson), person);

  return response.data.person;
};

export const updateClientPerson = async (
  id: UUID4,
  person: ClientPersonUpdateParams
): Promise<ClientPerson> => {
  const response: AxiosResponse<{
    person: ClientPerson;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .patch(
      getApiPathParamsMaker(Endpoints.clientVerificationUpdatePerson, {
        person_id: id,
      }),
      person
    );

  return response.data.person;
};

export const deleteClientPerson = async (id: UUID4): Promise<ClientPerson> => {
  const response: AxiosResponse<{
    person: ClientPerson;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .delete(
      getApiPathParamsMaker(Endpoints.clientVerificationDeletePerson, {
        person_id: id,
      })
    );

  return response.data.person;
};

export const createClientCompany = async (
  company: ClientCompanyCreateParams
): Promise<ClientCompany> => {
  const response: AxiosResponse<{
    company: ClientCompany;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(
      getApiPathParamsMaker(Endpoints.clientVerificationAddCompany),
      company
    );

  return response.data.company;
};

export const updateClientCompany = async (
  id: UUID4,
  company: ClientCompanyUpdateParams
): Promise<ClientCompany> => {
  const response: AxiosResponse<{
    company: ClientCompany;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .patch(
      getApiPathParamsMaker(Endpoints.clientVerificationUpdateCompany, {
        company_id: id,
      }),
      company
    );

  return response.data.company;
};

export const deleteClientCompany = async (
  id: UUID4
): Promise<ClientCompany> => {
  const response: AxiosResponse<{
    company: ClientCompany;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .delete(
      getApiPathParamsMaker(Endpoints.clientVerificationDeleteCompany, {
        company_id: id,
      })
    );

  return response.data.company;
};

export const sendClientPersonVerificationLink = async (
  id: UUID4,
  deliveryTypes: ClientPersonResendVerificationLinkTransport[]
): Promise<boolean> => {
  const response: AxiosResponse<'ok'> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(
      {
        endpoint: Endpoints.clientVerificationSendClientPersonVerificationLink,
        params: {
          person_id: id,
        },
      },
      {delivery_types: deliveryTypes}
    );

  return response.data === 'ok';
};

export const getClientPersonAndCompanyList = async (
  doNotExtendSession?: boolean
): Promise<{
  persons: ClientPerson[];
  companies: ClientCompany[];
}> => {
  // return {
  //   persons: [],
  //   companies: [],
  // };

  const response: AxiosResponse<{
    list: {person?: ClientPerson; company?: ClientCompany}[];
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(
        Endpoints.clientVerificationGetClientPersonAndCompanyList
      ),
      {
        params: {
          _: +new Date(),
        },
        doNotExtendSession,
      }
    );
  const ret: {
    persons: ClientPerson[];
    companies: ClientCompany[];
  } = {
    persons: [],
    companies: [],
  };

  (response.data.list || []).forEach((item) => {
    if (item.person) {
      ret.persons.push(item.person);
    }
    if (item.company) {
      ret.companies.push(item.company);
    }
  });

  return ret;
};
