import {createPath} from 'history';
import React, {useCallback, useMemo} from 'react';
import useDataTestId from '../../customHooks/useDataTestId';
import useUrl from '../../customHooks/useUrl';
import {redirect} from '../../models/router';

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  to: string;
  download?: string | boolean;
  newWindow?: boolean;
  className?: string;
  onClickAction?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  external?: boolean;
  title?: string;
}

const Link: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<Props>>
> = ({
  to,
  download,
  children,
  newWindow,
  className,
  onClickAction,
  external,
  ...rest
}) => {
  const url = useUrl(to);
  const addDataTestId = useDataTestId();

  const onClick = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      if (external) {
        return;
      }

      if (onClickAction) {
        onClickAction(event);
      }

      if (!event.isDefaultPrevented()) {
        if (!newWindow) {
          redirect(url);
          event.preventDefault();
        }
      }
    },
    [newWindow, url, onClickAction, external]
  );

  const externalProps = useMemo(() => {
    if (external) {
      return {
        target: '_blank',
        rel: 'noreferrer',
      };
    }

    return {};
  }, [external]);

  const urlPath = useMemo(() => {
    return typeof url === 'string' ? url : createPath(url);
  }, [url]);

  return (
    <a
      className={className || 'link'}
      href={external ? to : urlPath}
      onClick={onClick}
      download={download}
      {...externalProps}
      {...rest}
      {...addDataTestId('Link')}
    >
      {children}
    </a>
  );
};

export default Link;
