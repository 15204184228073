import React from 'react';
import clsx from 'clsx';
import useIntl from '../../customHooks/useIntl';
import css from './ModalWizard.module.less';
import Button from '../Button';
import gcss from '../../utils/styles';
import {ReactComponent as IconArrowRight} from '../../images/icons/arrow-right.svg';
import useMedias from '../../customHooks/useMedias';
import ModalCute from '../ModalCute';

interface Props {
  className?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  placement?:
    | 'topLeft'
    | 'top'
    | 'topRight'
    | 'rightTop'
    | 'right'
    | 'rightBottom'
    | 'bottomLeft'
    | 'bottom'
    | 'bottomRight'
    | 'leftTop'
    | 'left'
    | 'leftBottom';
  onNext?: () => void;
  currentStep?: number;
  totalSteps?: number;
}

const ModalWizard: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  title,
  description,
  placement,
  onNext,
  currentStep,
  totalSteps,
}) => {
  const media = useMedias();
  const intl = useIntl();

  return (
    <ModalCute
      className={clsx(css.modal, className)}
      title={title}
      description={description}
      actions={
        (!!onNext || !!currentStep || !!totalSteps) && (
          <>
            {(!!currentStep || !!totalSteps) && (
              <div className={css.steps}>
                <span className={css.steps__step}>{currentStep}</span>
                <span className={css.steps__stepSep}>/</span>
                <span className={css.steps__step}>{totalSteps}</span>
              </div>
            )}

            {!!onNext && (
              <Button
                className={clsx(
                  'ant-btn_green-emerald',
                  gcss('t_font-weight_700'),
                  gcss('t_transform_uppercase')
                )}
                onClick={onNext}
              >
                {intl.formatMessage({id: 'App-Next'})}

                <IconArrowRight
                  className={clsx(
                    'ant-btn-post-icon',
                    media.xs
                      ? 'ant-btn-post-icon_arrow-right'
                      : 'ant-btn-post-icon_arrow-right-small'
                  )}
                />
              </Button>
            )}
          </>
        )
      }
      placement={placement}
    />
  );
};

export default ModalWizard;
