import Demo, {DemoApiHandler} from '../demo';

const deletePaymentDocumentFile: DemoApiHandler = ({config, instance, url}) => {
  const items = instance.getData().paymentDocuments;
  const currentItem = items.find(
    (item) => item.id === url?.params?.payment_document_id
  );

  if (currentItem) {
    const index = items.indexOf(currentItem);

    if (index !== -1) {
      const statementId = currentItem.payment_id;

      items.splice(index, 1);

      if (!items.some((a) => a.payment_id === statementId)) {
        const statement = instance
          .getData()
          .statements.filter((a) => a.id === statementId)[0];

        statement.has_payment_document_files = false;

        instance.saveImpl(['statements']);
      }

      instance.saveImpl(['paymentDocuments']);

      return Demo.makeDataResponse<string>(config, 'ok');
    }
  }

  return Demo.makeUnprocessableEntityResponse(config, 90559);
};

export default deletePaymentDocumentFile;
