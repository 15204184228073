import React from 'react';
import clsx from 'clsx';
import {Tooltip as AntdTooltip, TooltipProps as AntdTooltipProps} from 'antd';
import popupContainerGetter from '../../utils/popups';
import {TTooltipColors} from '../../types';

export type TooltipProps = {
  bgColor?: TTooltipColors;
  touchable?: boolean;
} & AntdTooltipProps;

const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  bgColor,
  touchable,
  children,
  getPopupContainer,
  ...props
}) => {
  return (
    <AntdTooltip
      {...props}
      title={
        props.title ? (
          <div role="presentation">
            <>{props.title}</>
          </div>
        ) : undefined
      }
      getPopupContainer={getPopupContainer || popupContainerGetter}
      overlayClassName={clsx(
        bgColor ? `ant-tooltip_${bgColor}` : '',
        touchable ? 'ant-tooltip_touchable' : '',
        props.overlayClassName
      )}
      mouseEnterDelay={0.1}
      mouseLeaveDelay={0.01}
    >
      {children}
    </AntdTooltip>
  );
};

export default Tooltip;
