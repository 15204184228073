import {useUnit} from 'effector-react';
import {mediaBreakpoints$} from '../models/medias';
import {ScreenSize} from '../types/styles';

export type Medias = {[key in ScreenSize]: boolean};

const useMedias = (): Medias => {
  return useUnit(mediaBreakpoints$);
};

export default useMedias;
