import React, {useCallback, useMemo} from 'react';
import {RedirectProps} from 'react-router';
import Features from '../../constants/features';
import Permissions from '../../constants/permissions';
import routes from '../../constants/routes';
import useIsFullyVerified from '../../customHooks/useIsFullyVerified';
import useIsGuest from '../../customHooks/useIsGuest';
import useIsWhiteLabel from '../../customHooks/useIsWhiteLabel';
import useProductSettings from '../../customHooks/useProductSettings';
import AuthService from '../../services/AuthService';
import {RouteInfo} from '../../types';
import {getCleanUrl} from '../../utils/language';
import ObjectKeys from '../../utils/object';
import {
  getRouteFeatures,
  getRouteInfoByPath,
  getRoutePermissions,
} from '../../utils/permissions';
import Redirect from './Redirect';

const PermissionRedirect: React.FC<React.PropsWithChildren<RedirectProps>> = ({
  to,
  ...props
}) => {
  const isGuest = useIsGuest();
  const isFullyVerified = useIsFullyVerified();
  const isWhiteLabel = useIsWhiteLabel();
  const {program} = useProductSettings();

  const checkPermission = useCallback(
    (
      permissions: Permissions | Permissions[],
      feature?: Features,
      route?: RouteInfo
    ) => {
      const routePermissions = Array.isArray(permissions)
        ? permissions
        : [permissions];

      const routeFeatures = feature ? [feature] : [];

      let isHasPermissionAccess = true;
      let isHasFeatureAccess = true;

      if (route) {
        if (
          route.productProgramType !== undefined &&
          program !== route.productProgramType
        ) {
          return false;
        }

        if (route.whiteOnly === true && !isWhiteLabel) {
          return false;
        }
        if (route.blackOnly === true && isWhiteLabel) {
          return false;
        }

        if (
          !isWhiteLabel &&
          !isFullyVerified &&
          route.allowUnverifiedUser !== true
        ) {
          return false;
        }
      }

      if (routePermissions.length) {
        if (route?.permissionsPartialMode === true) {
          isHasPermissionAccess = routePermissions.some((rp) =>
            AuthService.getInstance().checkPermission(rp)
          );
        } else {
          isHasPermissionAccess = !routePermissions.some(
            (rp) => !AuthService.getInstance().checkPermission(rp)
          );
        }

        routePermissions.forEach((routePermission) => {
          if (!AuthService.getInstance().checkPermission(routePermission)) {
            if (route?.permissionsPartialMode === true) {
              isHasPermissionAccess ||= false;
            } else {
              isHasPermissionAccess &&= false;
            }
          }
        });
      }

      if (routeFeatures.length) {
        routeFeatures.forEach((routeFeature) => {
          if (!AuthService.getInstance().checkFeature(routeFeature)) {
            isHasFeatureAccess = false;
          }
        });
      }

      return isHasPermissionAccess && isHasFeatureAccess;
    },
    [program, isWhiteLabel, isFullyVerified]
  );

  const to2 = useMemo<string>(() => {
    const cleanUrl = getCleanUrl(to as string);
    const permissions = getRoutePermissions(cleanUrl);
    const feature = getRouteFeatures(cleanUrl);
    const programType = getRouteInfoByPath(cleanUrl);
    const isHasPermissionAccess = checkPermission(
      permissions,
      feature,
      programType
    );
    if (isHasPermissionAccess) {
      return to as string;
    }
    const routeKeys = ObjectKeys(routes);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < routeKeys.length; i += 1) {
      const route = routes[routeKeys[i]];
      if (
        !route.isNotMain &&
        (route.permission || route.feature) &&
        route.path.indexOf(':') === -1 &&
        checkPermission(route.permission || [], route.feature, route)
      ) {
        return route.path;
      }
    }
    return routes.forbidden.path;
  }, [to, checkPermission]);

  if (isGuest) {
    return <Redirect to={routes.signIn.path} {...props} />;
  }
  return <Redirect to={to2} />;
};

export default PermissionRedirect;
