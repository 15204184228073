import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import Tooltip, {TooltipProps} from '../Tooltip';
import css from './PointerSteps.module.less';
import ucFirst from '../../utils/string';
import useDataTestId from '../../customHooks/useDataTestId';

interface Props {
  className?: string;
  steps?: {
    tooltip?: React.ReactNode;
  }[];
  initialActive?: number;
  active?: number;
  onClick?: (clickedItemIndex: number) => void;
  tooltipProps?: TooltipProps;
  color?: 'black';
}

const PointerSteps: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  steps,
  initialActive,
  active,
  onClick,
  tooltipProps,
  color,
}) => {
  const addDataTestId = useDataTestId();

  const [activeIndex, setActiveIndex] = useState(
    (() => {
      if (typeof active !== 'undefined') {
        return active;
      }

      if (typeof initialActive !== 'undefined') {
        return initialActive;
      }

      return -1;
    })()
  );

  useEffect(() => {
    setActiveIndex(
      (() => {
        if (typeof active !== 'undefined') {
          return active;
        }

        return -1;
      })()
    );
  }, [active]);

  return (
    <div
      className={clsx(css.pointerSteps, className)}
      {...addDataTestId('PointerSteps')}
    >
      {steps?.map((step, i) => {
        const key = `${i}`;

        return (
          <Tooltip key={key} {...tooltipProps} title={step?.tooltip}>
            <div
              role="presentation"
              className={clsx(
                css.pointerItem,
                color ? css[`pointerItem_color${ucFirst(color)}`] : '',
                activeIndex === i ? css.pointerItem_active : ''
              )}
              onClick={() => {
                if (onClick) {
                  onClick(i);
                }

                if (typeof active === 'undefined') {
                  setActiveIndex(i);
                }
              }}
              {...addDataTestId(`${i}_PointerItem`)}
            />
          </Tooltip>
        );
      })}
    </div>
  );
};

export default PointerSteps;
