import React, {useEffect} from 'react';
import routes, {getRoutePath} from '../../../constants/routes';
import {ProductStore} from '../../../models/product';
import {redirect} from '../../../models/router';
import AuthService from '../../../services/AuthService';
import BroadcastHelper from '../../../utils/broadcastHelper';

const SyncManager: React.FC = () => {
  useEffect(() => {
    const bc = BroadcastHelper.getInstance();
    bc.onProfileChangeEvent((data) => {
      const currentProfileId = ProductStore.getState()?.profile_id;
      if (currentProfileId) {
        if (currentProfileId !== data.profileId) {
          AuthService.getInstance()
            .reAssignProfile(data.profileId, true)
            .then(() => {
              redirect(getRoutePath(routes.main));
            });
        }
      }
    });
  }, []);

  return null;
};

export default SyncManager;
