import React, {useMemo} from 'react';
import clsx from 'clsx';
import Tooltip, {TooltipProps} from '../Tooltip';
import css from './EllipsisTooltip.module.less';

export type EllipsisTooltipProps = {
  title?: string;
  classNameOuter?: string;
  classNameInner?: string;
  tooltipProps?: TooltipProps;
};

const EllipsisTooltip: React.FC<
  React.PropsWithChildren<EllipsisTooltipProps>
> = ({title, classNameOuter, classNameInner, children, tooltipProps}) => {
  const processedTitle = useMemo(() => {
    if (typeof title !== 'undefined') {
      return title;
    }

    return children;
  }, [children, title]);

  const content = useMemo(() => {
    if (typeof children !== 'undefined') {
      return children;
    }

    return title;
  }, [children, title]);

  return (
    <div className={clsx(css.wrapper, classNameOuter)}>
      <Tooltip
        title={processedTitle}
        bgColor="blue"
        overlayClassName="ant-tooltip_limitedLarge"
        {...tooltipProps}
      >
        <div className={clsx(css.inner, classNameInner)}>{content}</div>
      </Tooltip>
    </div>
  );
};

export default EllipsisTooltip;
