import React from 'react';
import LinkBrowser from '../LinkBrowser';
import useMedias from '../../customHooks/useMedias';
import Browser from '../../types/browsers';
import css from './BrowserNotSupported.module.less';

/*
 * *******************************************************************
 * WARNING. DO NOT USE intl in this file!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * *******************************************************************
 * */

interface Props {
  browsers?: Browser[];
}

const BrowserNotSupported: React.FC<React.PropsWithChildren<Props>> = ({
  browsers = ['chrome', 'firefox', 'safari', 'opera', 'edge'],
}) => {
  const media = useMedias();

  return (
    <div className={css.unsupported}>
      <div className={css.unsupportedWrap}>
        <div className={css.unsupportedLogo} />

        <div className={css.unsupportedTitle}>
          Please improve your experience!
        </div>

        <div className={css.unsupportedDesc}>
          You’re using an unsupported web browser. As Wallester works in the
          latest versions, we highly recommend you use an up-to-date version of
          one of these browsers:
        </div>

        <div className={css.unsupportedLinks}>
          {browsers.map((browser) => {
            return (
              <LinkBrowser
                key={browser}
                browser={browser}
                isHorizontal={media.sm}
                isRounded={!media.sm}
                isHovered={!media.sm}
                isWhite={media.sm}
                className={css.unsupportedLink}
                label="Download"
              />
            );
          })}
        </div>
      </div>

      <div className={css.unsupportedFooter}>
        © {new Date().getFullYear()} Wallester AS.&nbsp;&nbsp;All rights
        reserved
      </div>
    </div>
  );
};

export default BrowserNotSupported;
