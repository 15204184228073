import Demo, {DemoApiHandler} from '../demo';

const completeMassPaymentsFile: DemoApiHandler = ({config, instance, url}) => {
  const items = instance.getData().massPaymentsFiles[
    instance.getData().productId
  ];
  const currentItem = items.find(
    (item) => item.id === url?.params?.payment_file_id
  );

  if (currentItem) {
    const index = items.indexOf(currentItem);

    if (index !== -1) {
      currentItem.status = 'Completed';

      instance.saveImpl(['massPaymentsFiles']);

      return Demo.makeDataResponse<string>(config, 'ok');
    }
  }

  return Demo.makeUnprocessableEntityResponse(config, 90559);
};

export default completeMassPaymentsFile;
