import {
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import {getApiPath, getEndpointInfo} from '../api';
import AppStatus from '../constants/app';
import Endpoints, {WLEndpoints} from '../constants/endpoints';
import logger from '../logger';
import {App$, updateAppStatus} from '../models/app';
import CurrentClientStore from '../models/currentClient';
import {getIntl} from '../models/i18n';
import {setLoading} from '../models/layout';
import {getAssignedProductsFx} from '../models/products';
import {gotoLogin} from '../models/router';
import AuthService from '../services/AuthService';
import {AxiosUrl, GoToLoginMode, WAxios} from '../types';

import {buildAxiosInstance} from './buildAxiosInstance';
import Demo from './demo';
import isAllowedEndpoint from './freeze';
import {error as errorNotification} from './notifications';
import RequestCache from './RequestCache';
import RequestCacheError from './RequestCacheError';
import RequestError from './RequestError';

const log = logger.module('WAXIOS');

const API_ERROR_TOKEN_EXPIRED = 90388;
const API_ERROR_TOKEN_INVALID = 90342;

const checkEndpointPermission = (endpoint: Endpoints | WLEndpoints) => {
  if (!isAllowedEndpoint(endpoint)) {
    const intl = getIntl();
    errorNotification(
      intl.formatMessage({
        id: 'App-Operations_are_temporarily_suspended_',
      })
    );

    log.info(`Endpoint ${endpoint} is forbidden`);

    throw new RequestError({
      message: `Endpoint[${endpoint}] not allowed`,
      response: {status: 9999},
    });
  }
};

const makeWAxiosInstance: (
  axiosInstance: AxiosInstance,
  skipPermissionsCheck?: boolean
) => WAxios = (
  axiosInstance: AxiosInstance,
  skipPermissionsCheck?: boolean
): WAxios => {
  const WAxiosInstance: WAxios = {
    get: (url: AxiosUrl, config) => {
      if (skipPermissionsCheck !== true) {
        checkEndpointPermission(url.endpoint);
      }
      return axiosInstance.get(
        getApiPath(
          url.endpoint,
          url.params,
          url.queryParams,
          url.stupidArrayFormat
        ),
        {...config, wUrl: url}
      );
    },
    post: (url: AxiosUrl, data, config) => {
      if (skipPermissionsCheck === true) {
        checkEndpointPermission(url.endpoint);
      }
      return axiosInstance.post(
        getApiPath(
          url.endpoint,
          url.params,
          url.queryParams,
          url.stupidArrayFormat
        ),
        data,
        {...config, wUrl: url}
      );
    },
    patch: (url: AxiosUrl, data, config) => {
      if (skipPermissionsCheck === true) {
        checkEndpointPermission(url.endpoint);
      }
      return axiosInstance.patch(
        getApiPath(
          url.endpoint,
          url.params,
          url.queryParams,
          url.stupidArrayFormat
        ),
        data,
        {...config, wUrl: url}
      );
    },
    put: (url: AxiosUrl, data, config) => {
      if (skipPermissionsCheck === true) {
        checkEndpointPermission(url.endpoint);
      }
      return axiosInstance.put(
        getApiPath(
          url.endpoint,
          url.params,
          url.queryParams,
          url.stupidArrayFormat
        ),
        data,
        {...config, wUrl: url}
      );
    },
    delete: (url: AxiosUrl, config) => {
      if (skipPermissionsCheck === true) {
        checkEndpointPermission(url.endpoint);
      }
      return axiosInstance.delete(
        getApiPath(
          url.endpoint,
          url.params,
          url.queryParams,
          url.stupidArrayFormat
        ),
        {...config, wUrl: url}
      );
    },
  };
  return WAxiosInstance;
};

class WallesterAxios {
  private baseAxiosInstance?: AxiosInstance;

  private baseInstance?: WAxios;

  private wlInstance?: WAxios;

  private wbInstance?: WAxios;

  private authInstance?: WAxios;

  private isDemo = false;

  private isCommonWl = false;

  private switchingInProgress = false;

  private apiWhiteLabelUrl: string = App$.getState().config.apiWhiteLabelUrl;

  private apiAuthUrl: string = App$.getState().config.apiAuthUrl;

  private static instance?: WallesterAxios;

  public static getInstance(): WallesterAxios {
    if (!WallesterAxios.instance) {
      WallesterAxios.instance = new WallesterAxios();
    }
    return WallesterAxios.instance;
  }

  constructor() {
    this.initEvents();
  }

  private initEvents() {
    log.info('Subscribe to App$ store...');
    this.isCommonWl =
      !App$.getState().config.demo && App$.getState().isWhiteLabeled;
    this.apiWhiteLabelUrl = App$.getState().config.apiWhiteLabelUrl;
    this.apiAuthUrl = App$.getState().config.apiAuthUrl;
    this.isDemo = App$.getState().config.demo;

    App$.subscribe((state) => {
      this.isCommonWl = !state.config.demo && state.isWhiteLabeled;
      this.apiWhiteLabelUrl = state.config.apiWhiteLabelUrl;
      this.apiAuthUrl = state.config.apiAuthUrl;
      this.isDemo = state.config.demo;
    });
  }

  private setUpBeforeInterceptor(instance: AxiosInstance): void {
    instance.interceptors.request.use(
      async (config) => {
        const headers: AxiosRequestHeaders = new AxiosHeaders(config.headers);
        const as = AuthService.getInstance();

        if (!headers.Authorization && as.hasToken()) {
          const isValid = await as.checkToken(
            undefined,
            undefined,
            config.doNotExtendSession
          );
          if (isValid) {
            headers.Authorization = as.getAuthBearerString() || '';
          }
        }

        if (!headers.Authorization) {
          return Promise.reject(
            new RequestError({
              message: 'Access Forbidden',
              response: {
                status: 401,
                type: 'common',
              },
            })
          );
        }

        return {
          ...config,
          headers,
        };
      },
      (error) =>
        // Do something with request error
        Promise.reject(error)
    );
    instance.interceptors.request.use(async (config) => {
      const requestCache = RequestCache.getInstance();
      if (requestCache.isCached(config) && config.noCache !== true) {
        const skipXHRError = new RequestCacheError('skip');
        skipXHRError.isSkipXHR = true;
        skipXHRError.request = config;
        throw skipXHRError;
      } else {
        if (requestCache.shouldThrottle(config, config.noCache)) {
          requestCache.waitForResponse(config, config.noCache);
        }

        if (this.isDemo) {
          throw Demo.getInstance().makeDemoResponse(config);
        }

        return config;
      }
    });
  }

  private setUpAfterInterceptor(instance: AxiosInstance): void {
    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (typeof error.getResponse !== 'undefined') {
          const response = error.getResponse();
          const authResponse = error.getAuthenticationError();
          if (
            authResponse &&
            authResponse.error_code === API_ERROR_TOKEN_EXPIRED
          ) {
            if (error.response && error.response.config) {
              const as = AuthService.getInstance();
              const tokenInfo = as.getTokenInfo();
              const requestConfig = error.response.config as AxiosRequestConfig;

              return as
                .refreshToken(requestConfig.doNotExtendSession)
                .then((refreshTokenStatus) => {
                  if (refreshTokenStatus) {
                    if (requestConfig.headers) {
                      requestConfig.headers.Authorization =
                        as.getAuthBearerString() || '';
                    }
                    requestConfig.baseURL = undefined;
                    return instance.request(requestConfig);
                  }
                  if (tokenInfo) {
                    gotoLogin();
                  } else {
                    gotoLogin(GoToLoginMode.Expired);
                  }
                  return Promise.reject(error);
                });
            }
          } else if (
            authResponse &&
            authResponse.error_code === API_ERROR_TOKEN_INVALID
          ) {
            await AuthService.getInstance().logout();
            gotoLogin();

            return new Promise((r) => {
              setTimeout(r, 5000);
            });
          } else if (response && response.status === 401) {
            if (response.type !== 'common') {
              await AuthService.getInstance().logout();
              gotoLogin();

              return new Promise((r) => {
                setTimeout(r, 5000);
              });
            }
            gotoLogin();
            return Promise.reject(error);
          } else if (response && response.status === 500) {
            setTimeout(() => {
              const extendedData = {...error};
              const axiosRequestConfig = error.config as AxiosRequestConfig;

              log.error('Maintenance: Error in Base Request with 500', {
                ...extendedData,
                config: {
                  ...axiosRequestConfig,
                  headers: {},
                } as AxiosRequestConfig,
                request: null,
                response: {
                  ...extendedData.response,
                  config: {
                    ...extendedData.response.config,
                    headers: {},
                  },
                },
              });

              const endpointInfo = axiosRequestConfig.wUrl?.endpoint
                ? getEndpointInfo(axiosRequestConfig.wUrl.endpoint)
                : null;
              if (
                !axiosRequestConfig.wUrl?.endpoint ||
                !endpointInfo ||
                !endpointInfo.critical
              ) {
                const intl = getIntl();
                errorNotification(
                  intl.formatMessage({
                    id: 'App-Something_went_wrong__please_try_again_later_',
                  })
                );
              } else {
                updateAppStatus(AppStatus.error);
              }
            }, 100);
            return Promise.reject(error);
          }
          if (error.getBusinessLogicError) {
            const businessLogicErrorResponse = error.getBusinessLogicError();
            if (businessLogicErrorResponse) {
              const intl = getIntl();
              if (businessLogicErrorResponse.error_code === 90935) {
                errorNotification(
                  intl.formatMessage({
                    id: 'App-Operations_are_temporarily_suspended_',
                  })
                );
              } else if (businessLogicErrorResponse.error_code === 90739) {
                const assignedProducts = await getAssignedProductsFx();
                if (assignedProducts.length === 0) {
                  await AuthService.getInstance().logout();
                  gotoLogin(GoToLoginMode.Rejected);
                } else {
                  if (!this.switchingInProgress) {
                    this.switchingInProgress = true;
                    setLoading(true);
                    const companyName =
                      CurrentClientStore.getState()?.name || 'Client';
                    errorNotification(
                      intl.formatMessage(
                        {id: 'Error-client_rejected_with_company_name'},
                        {company_name: companyName}
                      )
                    );
                    await AuthService.getInstance().reAssignProfile(
                      assignedProducts[0].profile_id
                    );
                  }
                  return new Promise(() => {
                    setTimeout(() => {
                      window.location.reload();
                      // r({data: {}});
                    }, 3000);
                  });
                }
              }
            }
          }
        }

        return Promise.reject(error);
      }
    );
  }

  private getBaseAxiosInstance(): AxiosInstance {
    if (!this.baseAxiosInstance) {
      this.baseAxiosInstance = buildAxiosInstance();
    }
    return this.baseAxiosInstance;
  }

  public getBaseInstance(): WAxios {
    if (!this.baseInstance) {
      this.baseInstance = makeWAxiosInstance(this.getBaseAxiosInstance(), true);
    }
    return this.baseInstance;
  }

  public getBlackAxios(): WAxios {
    if (!this.wbInstance) {
      const authorizedAxiosInstance = buildAxiosInstance(
        {},
        this.getBaseAxiosInstance(),
        false
      );
      this.setUpBeforeInterceptor(authorizedAxiosInstance);
      this.setUpAfterInterceptor(authorizedAxiosInstance);
      this.wbInstance = makeWAxiosInstance(authorizedAxiosInstance);
    }
    return this.wbInstance;
  }

  public getAuthAxios(): WAxios {
    if (!this.authInstance) {
      const authAxiosInstance = buildAxiosInstance(
        {baseURL: this.apiAuthUrl, noBusinessLogicErrorsHandle: true},
        this.getBaseAxiosInstance()
      );

      authAxiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
          if (typeof error.getResponse !== 'undefined') {
            const response = error.getResponse();
            if (response && response.status === 500) {
              setTimeout(() => {
                const extendedData = {...error};
                const axiosRequestConfig = error.config as AxiosRequestConfig;

                log.error('Maintenance: Error in Base Request with 500', {
                  ...extendedData,
                  config: {
                    ...axiosRequestConfig,
                    headers: {},
                  } as AxiosRequestConfig,
                  request: null,
                  response: {
                    ...extendedData.response,
                    config: {
                      ...extendedData.response.config,
                      headers: {},
                    },
                  },
                });

                const endpointInfo = axiosRequestConfig.wUrl?.endpoint
                  ? getEndpointInfo(axiosRequestConfig.wUrl.endpoint)
                  : null;
                if (
                  !axiosRequestConfig.wUrl?.endpoint ||
                  !endpointInfo ||
                  !endpointInfo.critical
                ) {
                  const intl = getIntl();
                  errorNotification(
                    intl.formatMessage({
                      id: 'App-Something_went_wrong__please_try_again_later_',
                    })
                  );
                } else {
                  updateAppStatus(AppStatus.error);
                }
              }, 100);
              return Promise.reject(error);
            }
            if (response && response.status === 429) {
              const intl = getIntl();
              errorNotification(
                intl.formatMessage({
                  id: 'App-Something_went_wrong__please_try_again_later_',
                })
              );
              return Promise.reject(error);
            }
          }

          return Promise.reject(error);
        }
      );

      this.authInstance = makeWAxiosInstance(authAxiosInstance, true);
    }

    return this.authInstance;
  }

  public getWhiteLabelAxios(): WAxios {
    if (!this.wlInstance) {
      const wlAxiosInstance = buildAxiosInstance(
        {
          baseURL: this.apiWhiteLabelUrl,
        },
        undefined,
        true
      );
      this.setUpBeforeInterceptor(wlAxiosInstance);
      this.setUpAfterInterceptor(wlAxiosInstance);
      this.wlInstance = makeWAxiosInstance(wlAxiosInstance, true);
    }
    return this.wlInstance;
  }

  public getCommon(): WAxios {
    return this.isCommonWl ? this.getWhiteLabelAxios() : this.getBlackAxios();
  }
}

export default WallesterAxios;
