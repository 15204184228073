import {useUnit} from 'effector-react';
import {useEffect} from 'react';
import CurrentClientStore, {reloadCurrentClient} from '../models/currentClient';
import {IClient} from '../types';

const useClient = (noCache: boolean | void): IClient | null => {
  useEffect(() => {
    reloadCurrentClient(noCache);
  }, [noCache]);

  return useUnit(CurrentClientStore);
};

export default useClient;
