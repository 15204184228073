import React from 'react';
import clsx from 'clsx';
import css from './ModalCute.module.less';

interface Props {
  className?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  actions?: React.ReactNode;
  placement?:
    | 'topLeft'
    | 'top'
    | 'topRight'
    | 'rightTop'
    | 'right'
    | 'rightBottom'
    | 'bottomLeft'
    | 'bottom'
    | 'bottomRight'
    | 'leftTop'
    | 'left'
    | 'leftBottom';
  arrowed?: boolean;
}

const ModalCute: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  title,
  description,
  actions,
  placement,
  arrowed,
}) => {
  return (
    <div
      className={clsx(
        css.modal,
        placement ? css[`modal_${placement}`] : '',
        arrowed ? css.modal_arrowed : '',
        className
      )}
    >
      {!!title && <div className={css.modal__title}>{title}</div>}

      {!!description && (
        <div className={css.modal__description}>{description}</div>
      )}

      {!!actions && <div className={css.modal__actions}>{actions}</div>}

      {!!arrowed && <div className={css.modal__arrow} />}
    </div>
  );
};

export default ModalCute;
