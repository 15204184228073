import {useStoreMap} from 'effector-react';
import {App$} from '../models/app';

const useIsGuest = (): boolean => {
  return useStoreMap({
    store: App$,
    keys: ['isGuest'],
    fn: (store$) => store$.isGuest,
  });
};

export default useIsGuest;
