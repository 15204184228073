import {AxiosResponse} from 'axios';
import Endpoints, {WLEndpoints} from '../constants/endpoints';
import WallesterAxios from '../utils/WAxios';
import {
  IAuthorization,
  IAuthorizationAdditionalInfo,
  IAuthorizationCreateRequest,
  UUID4,
} from '../types';
import {getApiPathParamsMaker} from './endpoints';

export const getAuthorization = async (
  authorizationId: UUID4
): Promise<IAuthorization> => {
  const response = (await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(Endpoints.getAuthorization, {
        authorization_id: authorizationId,
      })
    )) as AxiosResponse<{authorization: IAuthorization}>;

  return response.data.authorization;
};

export const getAuthorizationAdditionalInfo = async (
  authorizationId: UUID4
): Promise<IAuthorizationAdditionalInfo> => {
  const response: AxiosResponse<{
    authorization_additional_info: IAuthorizationAdditionalInfo;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get({
      endpoint: WLEndpoints.getAuthorizationAdditionalInfo,
      params: {
        authorization_id: authorizationId,
      },
    });

  return response.data.authorization_additional_info;
};

export const createAuthorization = async (
  params: IAuthorizationCreateRequest
): Promise<IAuthorization> => {
  const response: AxiosResponse<{
    authorization: IAuthorization;
  }> = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .post(getApiPathParamsMaker(WLEndpoints.createAuthorization), {
      ...params,
      date: params.date.utc().format(),
    });

  return response.data.authorization;
};

export default getAuthorization;
