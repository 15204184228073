import moment from 'moment-timezone';
import {DateTime, IListState, UUID4} from './common';
import {CountryCodes3l} from './geo';
import {IPhone} from './phone';
import {CardStatus, ICard} from './card';
import {IAccount, TAccountStatus} from './account';

export enum EPersonTitle {
  MISS = 'Miss',
  MR = 'Mr.',
  MRS = 'Mrs.',
  MS = 'Ms.',
}

export type TPersonTitle =
  | EPersonTitle.MISS
  | EPersonTitle.MR
  | EPersonTitle.MRS
  | EPersonTitle.MS;

export enum ERisks {
  HIGH = 'High',
  LOW = 'Low',
  MEDIUM = 'Medium',
  PENDING = 'Pending',
  PROHIBITED = 'Prohibited',
}

export type TRisks =
  | ERisks.HIGH
  | ERisks.LOW
  | ERisks.MEDIUM
  | ERisks.PROHIBITED
  | ERisks.PENDING;

export enum EGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export type TGender = EGender.MALE | EGender.FEMALE;

export type TPersonID = UUID4;

export enum EPersonStatus {
  ACTIVE = 'Active',
  DEACTIVATED = 'Deactivated',
  REJECTED = 'Rejected',
}

export type TPersonStatus =
  | EPersonStatus.ACTIVE
  | EPersonStatus.DEACTIVATED
  | EPersonStatus.REJECTED;

export type PersonKYCStatus =
  | 'NotConfirmed'
  | 'Pending'
  | 'PendingManual'
  | 'Rejected'
  | 'Verified';

export interface ICustomer {
  address?: {
    address1: string;
    address2: string;
    city: string;
    postal_code: string;
  };
  address1: string;
  address2: string;
  birth_country_code?: CountryCodes3l;
  birth_date?: DateTime;
  city: string;
  document_number?: string;
  email: string;
  external_id: string;
  first_name: string;
  gender: TGender;
  is_adverse_media_involved?: boolean;
  is_beneficial_owner: boolean;
  is_politically_exposed_person?: boolean;
  is_represented_by_someone_else: boolean;
  is_sanctions_related?: boolean;
  last_name: string;
  loyalty_number: string;
  middle_name: string;
  mobile: string;
  personal_number: string;
  personal_number_issuer?: CountryCodes3l;
  politically_exposed_person_explanation: string;
  postal_code: string;
  residence_country_code?: CountryCodes3l;
  risk_profile?: TRisks;
  title: TPersonTitle;
  created_at: DateTime;
  deactivated_at: DateTime;
  id: UUID4;
  kyc_status?: PersonKYCStatus;
  push_notifications_enabled?: boolean;
  status: EPersonStatus;
  updated_at: DateTime;
}

export type ICustomerRaw = ICustomer;

export interface ICustomerRequestParams {
  first_name?: string;
  last_name?: string;
  personal_number?: string;
  mobile?: string;
  email?: string;
  from_record: number;
  records_count: number;
  order_field?: string;
  is_active?: boolean;
  order_direction?: string;
}

export interface ICustomerPagerParams {
  isActive?: boolean;
  lastName?: string;
  firstName?: string;
  personalNumber?: string;
  currentPage: number;
  pageSize: number;
  orderField?: string;
  orderDirection?: string;
}

export interface IDeactivateCutomersParams {
  idS: string[];
}

export interface ICustomerList {
  persons: ICustomer[];
  totalItems: number;
}

export interface ICustomerListResponse {
  persons: ICustomer[];
  total_records_number?: number;
}

export interface ICustomerResponse {
  person: ICustomer;
}

export interface ICustomerDetailsConfigItem {
  id: string;
  key: string;
  value: string | number | boolean | React.ReactNode;
  isContent?: boolean;
}

export interface ICustomerDetailsConfig {
  dataMain: ICustomerDetailsConfigItem[];
  dataPersonal: ICustomerDetailsConfigItem[];
  dataDetails: ICustomerDetailsConfigItem[];
}

export interface ICustomerCardsBlockCallsItem {
  id: UUID4;
  call_time: string;
  language_code: string;
  mobile_number: string;
}

export interface ICustomerCardsBlockCalls {
  loading: boolean;
  loaded: boolean;
  items: ICustomerCardsBlockCallsItem[];
  totalItems: number;
  pageSize: number;
  currentPage: number;
}

export interface ICustomerCardsBlockCallsParams {
  personId: UUID4;
  pageSize: number;
  currentPage: number;
  fromDate: moment.Moment;
  toDate: moment.Moment;
}

export interface ICustomerCardsBlockCallsResponse {
  items: ICustomerCardsBlockCallsItem[];
  totalItems: number;
  currentPage: number;
  fromDate: moment.Moment;
  toDate: moment.Moment;
}

export type TFormEditPersonal = {
  title: TPersonTitle;
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: TGender;
  personal_number: string;
  mobile: IPhone;
  birth_country_code?: CountryCodes3l;
  birth_date?: string;
  residence_country_code?: CountryCodes3l;
  email: string;
};

export type TFormEditCustomerDetails = {
  external_id: string;
  loyalty_number: string;
  is_beneficial_owner: string;
  is_represented_by_someone_else: string;
  is_politically_exposed_person?: string;
  is_sanctions_related?: string;
  is_adverse_media_involved?: string;
};

export type TFormCustomer = {
  title?: TPersonTitle;
  first_name: string;
  middle_name: string;
  last_name: string;
  gender?: TGender;
  personal_number: string;
  birth_country_code?: CountryCodes3l;
  birth_date?: string;
  residence_country_code?: CountryCodes3l;
  email: string;
  external_id: string;
  loyalty_number: string;
  document_number?: string;
  address1: string;
  address2: string;
  city: string;
  postal_code: string;
  risk_profile?: TRisks;
};

export interface IFormCreateCustomerData extends TFormCustomer {
  mobile: string;
  is_beneficial_owner: boolean;
  is_represented_by_someone_else: boolean;
  is_politically_exposed_person?: boolean;
  is_sanctions_related?: boolean;
  is_adverse_media_involved?: boolean;
}

export interface IFormCreateCustomer extends TFormCustomer {
  mobile: IPhone;
  is_beneficial_owner: string;
  is_sanctions_related?: string;
  is_adverse_media_involved?: string;
  is_represented_by_someone_else: string;
  is_politically_exposed_person?: string;
}

export interface IDateFilter {
  fromDate: moment.Moment;
  toDate: moment.Moment;
}

export interface IFilterStore {
  [key: string]: IDateFilter;
}

export interface ICustomerCardsFilters {
  statuses?: CardStatus[];
}

export type ICustomerCards = IListState<ICard, ICustomerCardsFilters>;

export interface ICustomerAccountsFilters {
  statuses?: TAccountStatus[];
  name?: string;
  vban?: string;
}

export type ICustomerAccounts = IListState<IAccount, ICustomerAccountsFilters>;
