import {AxiosResponse} from 'axios';
import {TTimeZone} from '../constants/dateTime';
import Endpoints, {WLEndpoints} from '../constants/endpoints';
import {
  SystemUser,
  UserLocale,
  UUID4,
  IUser,
  IUserHistoryList,
  IUserListRequestParams,
  IUserUpdateProfileParams,
  IUserUpdateSelfProfileParams,
  ICreateBlackUserParams,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const getUser = async (id: UUID4): Promise<IUser> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get(getApiPathParamsMaker(WLEndpoints.getUser, {user_id: id}));
  return response.data.user;
};

export const getCurrentUser = async (): Promise<SystemUser> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(Endpoints.getCurrentUser));
  return response.data.user;
};

export const lockUser = async (id: UUID4): Promise<IUser> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .patch(getApiPathParamsMaker(WLEndpoints.lockUser, {user_id: id}));
  return response.data.user;
};

export const unlockUser = async (id: UUID4): Promise<IUser> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .patch(getApiPathParamsMaker(WLEndpoints.unlockUser, {user_id: id}));
  return response.data.user;
};

export const blackLockUser = async (id: UUID4): Promise<IUser> => {
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .patch(getApiPathParamsMaker(Endpoints.lockUser, {user_id: id}));
  return response.data.user;
};

export const blackUnlockUser = async (id: UUID4): Promise<IUser> => {
  const response = await WallesterAxios.getInstance()
    .getBlackAxios()
    .patch(getApiPathParamsMaker(Endpoints.unlockUser, {user_id: id}));
  return response.data.user;
};

export const deleteUser = async (id: UUID4): Promise<IUser> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .delete(getApiPathParamsMaker(WLEndpoints.deleteUser, {user_id: id}));

  return response.data;
};

export const setUserRole = async (
  id: UUID4,
  role_id: UUID4
): Promise<IUser> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .post({endpoint: Endpoints.setUserRole, params: {user_id: id}}, {role_id});

  return response.data;
};

export const createUser = async (
  apiUser: IUserUpdateProfileParams
): Promise<IUser> => {
  const response: AxiosResponse<{
    user: IUser;
  }> = await WallesterAxios.getInstance().getWhiteLabelAxios().post(
    {endpoint: WLEndpoints.createUser},
    {
      user: apiUser,
    }
  );
  return response.data.user;
};

export const blackCreateUser = async (
  params: ICreateBlackUserParams
): Promise<UUID4> => {
  const response: AxiosResponse<{
    user_id: UUID4;
  }> = await WallesterAxios.getInstance()
    .getBlackAxios()
    .post(getApiPathParamsMaker(Endpoints.ensureCompanyAccess), params);
  return response.data.user_id;
};

export const getUserIdByMobile = async (mobile: string): Promise<UUID4> => {
  const response: AxiosResponse<{
    user_id: UUID4;
  }> = await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(Endpoints.getByMobile, {}, {mobile}));
  return response.data.user_id;
};

export const updateSelfPassword = async (password: string): Promise<void> => {
  await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.updateUserSelfPassword), {password});
};

export const getUserHistory = async (
  params: IUserListRequestParams
): Promise<IUserHistoryList> => {
  const response = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get(
      getApiPathParamsMaker(WLEndpoints.getUserHistory, {
        user_id: params.id || '',
      }),
      {
        params: {
          from_record: (params.currentPage - 1) * params.pageSize,
          records_count: params.pageSize,
          from_date: `${params.fromDate
            ?.clone()
            .utc()
            .format('YYYY-MM-DDT00:00:00')}Z`,
          to_date: `${params.toDate
            ?.clone()
            .utc()
            .format('YYYY-MM-DDT23:59:59')}Z`,
        },
      }
    );
  return {
    items: response.data.history || [],
    totalItems: response.data.total_records_number,
  };
};

export const updateUserLang = async (
  locale: UserLocale
): Promise<SystemUser> => {
  // This was updated do to task WA-1047 in clickup
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.changeUserLocale), {
      locale,
    });

  return response.data.user;
};

export const updateUserTimezone = async (
  timeZone: TTimeZone
): Promise<SystemUser> => {
  // This was updated do to task WA-1047 in clickup
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.changeUserLocale), {
      timezone_name: timeZone,
    });

  return response.data.user;
};

export const updateUserData = async (
  userId: UUID4,
  params: IUserUpdateProfileParams
): Promise<IUser> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(
      getApiPathParamsMaker(Endpoints.updateUserProfile, {user_id: userId}),
      {
        ...params,
      }
    );
  return response.data.user;
};

export const updateUserSelfData = async (
  params: IUserUpdateSelfProfileParams
): Promise<IUser> => {
  const response = await WallesterAxios.getInstance()
    .getCommon()
    .patch(getApiPathParamsMaker(Endpoints.updateUserSelfProfile), {
      ...params,
    });
  return response.data.user;
};

export default getUser;
