import {AppStatus} from '../constants/app';
import Endpoints, {WLEndpoints} from '../constants/endpoints';
import {DateTime, TypedMap, UUID4} from './common';
import {CountryCodes3l} from './geo';
import {MessengerConfig} from './messenger';
import {UserLocale} from './users';
import {CurrencyType} from './currency';

export type AllowedEndpoints = TypedMap<
  Endpoints | WLEndpoints,
  boolean
> | null;

export enum RestrictedCountryType {
  Employee = 'employee',
  Person = 'person',
  Carduser = 'carduser',
  ClientInformation = 'client_information',
  ClientPerson = 'client_person',
  ClientCompany = 'client_company',
  User = 'user',
  Global = 'countries',
}

export type AppRestrictedCountries = TypedMap<
  RestrictedCountryType,
  TypedMap<CountryCodes3l, boolean>
>;

export interface AppInnerState {
  pathname: string;
  config: AppConfig;
  title: string;
  menuItems: AppMenuItem[];
  appStatus: AppStatus;
  isGuest: boolean;
  isMobile: boolean;
  userUpdated: boolean;
  refreshTokenExpireTs: number;
  lastUsedProductId?: UUID4;
  sessionStorage?: Record<string, string>;
  lastLogoutAt?: DateTime;
  serviceWorkerRegistration?: ServiceWorkerRegistration;
  allowedEndpoints: AllowedEndpoints;
  restrictedCountries: AppRestrictedCountries;
  designChanging?: boolean;
  browserCompatibilityModalVisible?: boolean;
  isWhiteLabeled: boolean;
}

export interface AppState extends AppInnerState {
  companyId?: UUID4 | null;
  systemAccountId?: UUID4 | null;
  systemAccountCurrencyCode?: CurrencyType | null;
  isClientFrozen: boolean;
  fullyVerified: boolean;
  mobileVerified: boolean;
}

export interface AppMenuItem {
  url: string;
  text: string;
  permissions?: number[];
  items?: AppMenuItem[];
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export type AppConfig = {
  apiUrl: string;
  apiAuthUrl: string;
  apiWhiteLabelUrl: string;
  httpTimeout: number;
  locales: UserLocale[];
  requestCacheTTL: number;
  version: string;
  commit: string;
  buildNumber: string;
  buildDate: string;
  branch: string;
  isProdBuild: boolean;
  isDevBuild: boolean;
  demo: boolean;
  isDev: boolean;
  isSandbox: boolean;
  isStaging: boolean;
  isProd: boolean;
  appEnv: string;
  apiTimeout: number;
  maxChunkRetryAttempts: number;
  chunkRetryTimeout: number;
  requestInterval: number;
  siteDomain: string;
  rollbarToken: string | null;
  sentryDSN: string;
  sentryEnv: string;
  secretPublicKeyUpdate: string;
  secretPublicKeyCreate: string;
  rollbarEnable: boolean;
  sentryEnable: boolean;
  sentryAutoSessionTracking: boolean;
  hasMissingTranslation: boolean;
  developersUrl: string;
  supportEmail: string;
  businessEmail: string;
  googleAnalyticsId: string;
  googleAnalyticsV4Id: string;
  gtm: string;
  linkedInId: string;
  wallesterTariffPlans: string;
  allowDowngradePricingPlan: boolean;
  tablePageSizes: number[];
  apiDocLink: string;
  jwtDocLink: string;
  trackingLinkDHL: string;
  wallesterFrontUrl: string;
  authCookieDomain: string;
  reCaptchaKey: string;
  legalNoticeLink: string;
  privacyLink: string;
  cardAgreementLink: string;
  supportPhone: string;
  supportWhatsAppPhone: string;
  supportTelegramPhone: string;
  supportViberPhone: string;
  affiliateProgramUrl: string;
  splitIOApiKey: string;
  currencyRequestTTL: number;
  designChangingDuration: number;
  messengerDisabled: boolean;
  messengerKeyId: string;
  messengerConfig?: MessengerConfig;
  isUseTestId?: boolean;
  signInLink: string;
  registrationLink: string;
  registrationCommonLink: string;
  intercomWhiteListArticleID?: string;
  friendlyCaptchaApiKey?: string;
};

export type TSortOrder = 'asc' | 'desc' | 'ascend' | 'descend';
