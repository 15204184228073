import {CardStatus, ICard, UUID4} from '../../types';
import Demo, {DemoApiHandler} from '../demo';
import {hideCardsPrivacyInfo} from './getCard';

const getProductCards: DemoApiHandler = ({config, instance, url}) => {
  let items = instance.getData().cards[instance.getData().productId];
  const cardStatuses = url?.queryParams?.card_statuses as
    | CardStatus[]
    | undefined;
  const accountIds = url?.queryParams?.account_ids as UUID4[] | undefined;
  const isReceiptsReminderEnabled =
    url?.queryParams?.is_receipts_reminder_enabled;

  if (cardStatuses?.length) {
    items = items.filter((a) => cardStatuses.includes(a?.status));
  }

  if (accountIds?.length) {
    items = items.filter((a) => accountIds.includes(a?.account_id));
  }

  if (typeof isReceiptsReminderEnabled !== 'undefined') {
    items = items.filter(
      (a) =>
        a?.card_notification_settings?.receipts_reminder_enabled ===
        isReceiptsReminderEnabled
    );
  }

  items = Demo.applySorterAndPager(
    items,
    Demo.toListSortOrderParams(url.queryParams)
  );

  return Demo.makeDataResponse<{
    cards: ICard[];
    total_records_number: number;
  }>(config, {
    cards: hideCardsPrivacyInfo(items),
    total_records_number: items.length,
  });
};

export default getProductCards;
