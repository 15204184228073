import {useStoreMap} from 'effector-react';
import React, {useEffect} from 'react';
import BrowserOutdated from '../../../components/BrowserOutdated/BrowserOutdated';
import {
  App$,
  setBrowserCompatibilityModalVisibleState,
} from '../../../models/app';
import AppService from '../../../services/AppService';

const BrowserCompatibilityManager: React.FC = () => {
  const isVisible = useStoreMap({
    store: App$,
    keys: ['browserCompatibilityModalVisible'],
    fn: (state) => state.browserCompatibilityModalVisible,
  });

  useEffect(() => {
    const isBrowserOutdated = AppService.isBrowserOutdated();
    if (isBrowserOutdated) {
      setBrowserCompatibilityModalVisibleState(true);
    }
  }, []);

  return (
    <>
      {isVisible && (
        <BrowserOutdated
          onClose={() => {
            setBrowserCompatibilityModalVisibleState(false);
          }}
        />
      )}
    </>
  );
};

export default BrowserCompatibilityManager;
