import moment from 'moment-timezone';
import {
  CompanyRiskProfiles,
  CompanyStatuses,
  IndustryTypes,
} from '../constants/company';
import {AffiliateDeviceType} from './auth-form';
import {Available, Usage} from './card';
import {DateTime, UUID4} from './common';
import {CountryCodes3l} from './geo';
import {LanguageCode3l} from './languageCodes';
import {Limits} from './limits';

export type CompanyRiskProfile = typeof CompanyRiskProfiles[number];

export interface CompanyAddress {
  address1: string;
  address2: string;
  city: string;
  country_code: CountryCodes3l;
  postal_code: string;
}

export type CompanyLimits = Limits;

export interface CompanyLimitsWithUsage
  extends CompanyLimits,
    Usage,
    Available {
  //
}

export type CompanySecurityKeys = keyof CompanyLimits;

export type CompanyIndustryType = typeof IndustryTypes[number];

export type CompanyStatus = typeof CompanyStatuses[number];

export const CompanyStatusLabels: Record<CompanyStatus, string> = {
  Active: 'App-Active',
  Deactivated: 'App-Deactivated',
  Frozen: 'App-Frozen',
};

export interface CompanyHistoryItem {
  created_at: string;
  created_by: UUID4;
  created_by_name: UUID4;
  field: string;
  new_value: string;
  old_value: string;
}

export interface CompanyHistoryRequestParams {
  companyId: UUID4;
  currentPage: number;
  pageSize: number;
  fromDate: moment.Moment;
  toDate: moment.Moment;
}

export interface ICompanyHistory {
  items: CompanyHistoryItem[];
  totalItems?: number;
}

export interface ICompany {
  id: UUID4;
  name: string;
  registration_number: string;
  registration_address: CompanyAddress;
  headquarter_address: CompanyAddress;
  risk_profile: CompanyRiskProfile;
  mobile?: string;
  email?: string;
  created_at?: DateTime;
  created_by?: UUID4;
  created_by_user?: string;
  deleted_at?: DateTime;
  deleted_by?: UUID4;
  deleted_by_user?: string;
  updated_at?: DateTime;
  updated_by?: UUID4;
  updated_by_user?: string;
  industry_type?: CompanyIndustryType;
  status: CompanyStatus;
  push_notifications_enabled?: boolean;
  preferred_language_code?: LanguageCode3l;
  date_of_incorporation: DateTime;
  business_relationship_purpose?: string;
  is_sanctions_related: boolean;
  is_adverse_media_involved: boolean;
  employees_quantity: string;
  card_spending_amount: string;
  limits: CompanyLimitsWithUsage;
  vat_number?: string;
}

type CreateCompanyAddress = Partial<Omit<CompanyAddress, 'country_code'>> & {
  country_code: CountryCodes3l;
};

export type CreateCompanyParams = {
  name: string;
  registration_number: string;
  registration_address: CreateCompanyAddress;
  headquarter_address: CreateCompanyAddress;
  risk_profile?: CompanyRiskProfile;
  mobile?: string;
  email?: string;
  industry_type?: string;
  date_of_incorporation?: DateTime;
  is_sanctions_related?: boolean;
  is_adverse_media_involved?: boolean;
  push_notifications_enabled?: boolean;
  preferred_language_code?: LanguageCode3l;
  vat_number?: string;
};

export type CreateCompanyRepresentativeParams = {
  first_name: string;
  last_name: string;
  middle_name?: string;
  email?: string;
  mobile?: string;
  birth_date?: string;
  percentage_owned?: number;
  is_adverse_media_involved?: boolean;
  is_member_of_the_board?: boolean;
  is_politically_exposed?: boolean;
  is_representative?: boolean;
  is_sanctions_related?: boolean;
  is_ultimate_beneficial_owner?: boolean;
  position?: string;
  title?: string;
};

export type UpdateCompanyLimitsParams = {
  [key in CompanySecurityKeys]?: number;
} & {
  companyId: UUID4;
};

export type SignUpAddCompanyParams = {
  companyName: string;
  token: string;
  affiliateHash?: string;
  deviceType?: AffiliateDeviceType;
  gaId?: string;
  gcId?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  origin?: string;
  countryCode?: CountryCodes3l;
};
