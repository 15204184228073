import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {getEndpointInfo} from '../api';
import AppStatus from '../constants/app';
import config from '../constants/config';
import Endpoints, {WLEndpoints} from '../constants/endpoints';
import apiErrorsCodeAssociations, {
  errorCodesToIgnore,
} from '../constants/errors';
import logger from '../logger';
import {App$, updateAppStatus} from '../models/app';
import {getIntl} from '../models/i18n';
import Demo from './demo';
import {error as errorNotification} from './notifications';
import RequestCache from './RequestCache';
import RequestError from './RequestError';

const log = logger.module('BaseRequester');

const BlackListEndpointsWl: Endpoints[] = [Endpoints.getAllowedEndpoints];
const BlackListEndpointsBlack: WLEndpoints[] = [];

export function buildAxiosInstance(
  newConfig?: AxiosRequestConfig,
  fromAxiosInstance?: AxiosInstance,
  whiteLabelInstance?: boolean
): AxiosInstance {
  const newInstance = axios.create({
    ...(fromAxiosInstance
      ? {
          ...fromAxiosInstance.defaults,
          headers: fromAxiosInstance.defaults.headers.common,
        }
      : {
          baseURL: config.apiUrl,
          timeout: +config.httpTimeout,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        }),
    ...(newConfig || {}),
  });

  newInstance.interceptors.request.use((request) => {
    if (whiteLabelInstance !== undefined && request.wUrl?.endpoint) {
      const isWhiteLabel = App$.getState().isWhiteLabeled;
      if (
        whiteLabelInstance &&
        !isWhiteLabel &&
        !BlackListEndpointsBlack.includes(request.wUrl?.endpoint as WLEndpoints)
      ) {
        log.warn(`Call WL api on WB instance [${request.wUrl?.endpoint}]`);
      } else if (
        !whiteLabelInstance &&
        isWhiteLabel &&
        !BlackListEndpointsWl.includes(request.wUrl?.endpoint as Endpoints)
      ) {
        log.warn(`Call WB api on WL instance [${request.wUrl?.endpoint}]`);
      }
    }
    if (request.data === undefined) {
      request.data = {};
    }
    return request;
  });

  newInstance.interceptors.response.use(
    (response) => {
      const requestCache = RequestCache.getInstance();
      requestCache.setCachedResponse(response.config, response);
      return response;
    },
    (error) => {
      const isDemo = Demo.isDemoMode();
      if (isDemo && error.isDemo) {
        const requestCache = RequestCache.getInstance();
        if (error.response.status !== 200) {
          requestCache.setCachedError(error.response.config, error.response);
          return Promise.reject(error.response);
        }

        requestCache.setCachedResponse(error.response.config, error.response);
        return Promise.resolve(error.response);
      }

      const requestCache = RequestCache.getInstance();
      if (error.isSkipXHR) {
        return requestCache.getCachedResponse(error.request);
      }
      if (error.name === 'AxiosError') {
        requestCache.setCachedError(error.config, error);
      }
      return Promise.reject(error);
    }
  );

  newInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (axios.isCancel(error)) {
        throw error;
      }

      const requestError = new RequestError({
        message: error.message,
        stack: error.stack,
        ...error,
      });
      const extendedData = {
        ...error,
        response: {
          ...(error.response || {}),
          config: {...(error.response?.config || {})},
        },
      };
      const axiosRequestConfig = error.config as AxiosRequestConfig;
      const logData = {
        ...extendedData,
        config: {
          ...axiosRequestConfig,
          headers: {},
        } as AxiosRequestConfig,
        request: null,
        response: {
          ...(extendedData.response ?? {}),
          config: {
            ...(extendedData.response?.config ?? {}),
            headers: {},
          },
        },
      };

      if (
        error.code === 'ECONNABORTED' &&
        error.message.includes('timeout of')
      ) {
        log.error('Timeout API error', logData);
        throw requestError;
      }

      if (error.response && error.response.status === 500) {
        log.error('Maintenance: Error in Base Request with 500', logData);
        setTimeout(() => {
          const endpointInfo = axiosRequestConfig.wUrl?.endpoint
            ? getEndpointInfo(axiosRequestConfig.wUrl.endpoint)
            : null;
          if (
            !axiosRequestConfig.wUrl?.endpoint ||
            !endpointInfo ||
            !endpointInfo.critical
          ) {
            const intl = getIntl();
            errorNotification(
              intl.formatMessage({
                id: 'App-Something_went_wrong__please_try_again_later_',
              })
            );
          } else {
            updateAppStatus(AppStatus.error);
          }
        }, 100);
        return Promise.reject(error);
      }

      const blError = requestError.getBusinessLogicError();
      if (blError && axiosRequestConfig.noBusinessLogicErrorsHandle !== true) {
        if (
          blError.error_code &&
          apiErrorsCodeAssociations[blError.error_code] &&
          !errorCodesToIgnore.includes(blError.error_code)
        ) {
          const intl = getIntl();
          errorNotification(
            intl.formatMessage({
              id: `Error-${apiErrorsCodeAssociations[blError.error_code]}`,
            })
          );
        }
      }

      if (error.response && error.response.status > 500) {
        log.error('Error in Base Request', logData);
      } else {
        log.info(`Some business error`, logData);
      }

      throw requestError;
    }
  );

  return newInstance;
}

export default 5;
