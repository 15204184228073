import Endpoints from '../constants/endpoints';
import {UUID4, AccountAdjustment} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const getAccountAdjustment = async (
  id: UUID4
): Promise<AccountAdjustment> => {
  const {data: response} = await WallesterAxios.getInstance()
    .getBlackAxios()
    .get(
      getApiPathParamsMaker(Endpoints.getAccountAdjustment, {
        account_adjustment_id: id,
      })
    );
  return response.account_adjustment;
};

export default getAccountAdjustment;
