import {useStoreMap} from 'effector-react';
import {useMemo} from 'react';
import HintsStore from '../models/hints';
import HINTS_TYPES, {HintConfig, HintDisplayConfig} from '../types/hints';

const useHint = (
  hintType: HINTS_TYPES
): [HintConfig | undefined, HintDisplayConfig | undefined] => {
  const [hints, activeSingleHintDisplayConfig] = useStoreMap({
    store: HintsStore,
    keys: [
      'activeSingleHint',
      'activeInlineHints',
      'activeRouteHint',
      'activeSingleHintDisplayConfig',
    ],
    fn: (store$) => [
      [
        store$.activeSingleHint,
        store$.activeRouteHint,
        ...store$.activeInlineHints,
      ],
      store$.activeSingleHintDisplayConfig,
    ],
  });

  return useMemo(() => {
    const hint = hints.find((h) => h?.id === hintType);
    if (hint) {
      return [hint, activeSingleHintDisplayConfig];
    }
    return [undefined, undefined];
  }, [hintType, hints, activeSingleHintDisplayConfig]);
};

export default useHint;
