import {IAccount, UUID4} from '../../types';
import CardHelper from '../CardHelper';
import Demo, {DemoApiHandler} from '../demo';

const getAccountsHandler: DemoApiHandler = ({
  config,
  instance,
  url: {queryParams},
}) => {
  const {productId} = instance.getData();
  let productAccounts = instance.getData().accounts[productId] || [];
  if (queryParams) {
    if (queryParams.is_main !== undefined) {
      productAccounts = productAccounts.filter(
        (pa) => pa.is_main === queryParams.is_main
      );
    }
  }
  const sorted = Demo.applySorterAndPager(
    productAccounts,
    Demo.toListSortOrderParams(queryParams)
  );

  const cards = (instance.getData().cards[productId] || []).reduce<
    Record<UUID4, number>
  >((prev, card) => {
    const ret = {...prev};
    if (typeof ret[card.account_id] === 'undefined') {
      ret[card.account_id] = 0;
    }
    const ch = CardHelper(card);
    if (ch.isActive() || ch.isAwaitingRenewal() || ch.isSoftBlocked()) {
      ret[card.account_id] += 1;
    }
    return ret;
  }, {});

  return Demo.makeDataResponse<{
    accounts: IAccount[];
    total_records_number: number;
  }>(config, {
    accounts: sorted.map((account) => {
      return {...account, cards_count: cards[account.id] || 0};
    }),
    total_records_number: productAccounts.length,
  });
};

export default getAccountsHandler;
