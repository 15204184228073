import clsx from 'clsx';
import React, {FC} from 'react';
import browsers from '../../types/browsers';
import {getBrowserLink, getBrowserTitle} from '../../utils/browsers';
import iconChrome from '../../images/icons/chrome.svg';
import iconFirefox from '../../images/icons/firefox.svg';
import iconSafari from '../../images/icons/safari.svg';
import iconOpera from '../../images/icons/opera.svg';
import iconEdge from '../../images/icons/edge.svg';
import css from './LinkBrowser.module.less';

interface Props {
  browser?: browsers;
  className?: string;
  label?: string;
  isHorizontal?: boolean;
  isRounded?: boolean;
  isHovered?: boolean;
  isWhite?: boolean;
}

const LinkBrowser: FC<Props> = ({
  className,
  browser,
  label,
  isHorizontal,
  isRounded = true,
  isHovered = true,
  isWhite,
}) => {
  const title = getBrowserTitle(browser);
  const link = getBrowserLink(browser);
  let icon;

  switch (browser) {
    case 'chrome':
      icon = iconChrome;
      break;
    case 'firefox':
      icon = iconFirefox;
      break;
    case 'safari':
      icon = iconSafari;
      break;
    case 'opera':
      icon = iconOpera;
      break;
    case 'edge':
      icon = iconEdge;
      break;
    default:
      icon = iconChrome;
      break;
  }

  return (
    <a
      href={link}
      target="_blank"
      className={clsx(
        css.linkBrowser,
        isHorizontal ? css.linkBrowser_horizontal : '',
        isRounded ? css.linkBrowser_rounded : '',
        isHovered ? css.linkBrowser_hovered : '',
        isWhite ? css.linkBrowser_white : '',
        className
      )}
      rel="noreferrer"
    >
      <img src={icon} alt="" className={css.linkBrowserIcon} />
      <div className={css.linkBrowserTitle}>{title}</div>
      <div className={css.linkBrowserLink}>{label}</div>
    </a>
  );
};

export default LinkBrowser;
