import clsx from 'clsx';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useUnit} from 'effector-react';
import HintInner from '../../../components/HintInner';
import ucFirst from '../../../utils/string';
import HintsLayerStore, {
  closeSingleHintForSession,
} from '../../../models/hintsLayer';
import {setHintState} from '../../../models/serverStorage';
import css from './HintSingleItem.module.less';

const HintSingleItem: React.FC = () => {
  const intl = useIntl();
  const {
    activeSingleHintTarget,
    activeSingleHintDisplayConfig,
    activeSingleHint,
  } = useUnit(HintsLayerStore);
  const ref = useRef<HTMLDivElement>(null);
  const refArrow = useRef<HTMLDivElement>(null);
  const bcr = activeSingleHintTarget?.getBoundingClientRect();
  const topScreenLimit = 10;
  const arrowShift = 30;
  const [topParentLimit, setTopParentLimit] = useState(0);
  const [hintTop, setHintTop] = useState<number | null>(null);
  const [hintLeft, setHintLeft] = useState<number | null>(null);
  const [hintArrowTop, setHintArrowTop] = useState<number | undefined>(
    undefined
  );
  const [visible, setVisible] = useState(false);
  const [start, setStart] = useState(false);

  const handleCloseSingleHint = useCallback(() => {
    if (activeSingleHint) {
      closeSingleHintForSession();

      setHintState({
        hint: activeSingleHint.id,
        state: true,
      });
    }
  }, [activeSingleHint]);

  const handleOkSingleHint = useCallback(() => {
    if (activeSingleHint) {
      setHintState({
        hint: activeSingleHint.id,
        state: true,
      });
    }
  }, [activeSingleHint]);

  const handlePreviousSingleHint = useCallback(() => {
    if (activeSingleHint && activeSingleHintDisplayConfig?.previousHint) {
      setHintState({
        hint: activeSingleHintDisplayConfig.previousHint,
        state: false,
      });
    }
  }, [activeSingleHint, activeSingleHintDisplayConfig]);

  useEffect(() => {
    if (start) {
      const top = (bcr?.y || 0) + (bcr?.height || 0) / 2;
      const _topParentLimit = top - arrowShift;
      let _hintTop = top;
      if (ref !== null && ref?.current) {
        _hintTop = top - ref.current.offsetHeight / 2;

        if (_hintTop < topScreenLimit) {
          _hintTop = topScreenLimit;
        }

        if (_hintTop > topParentLimit) {
          _hintTop = topParentLimit;
        }

        if (!visible) {
          setTimeout(() => {
            setVisible(true);
          }, 1);
        }
      }
      const _hintArrowTop =
        top -
        _hintTop -
        (refArrow && refArrow?.current
          ? refArrow?.current.offsetHeight / 2
          : 0);
      const _hintLeft = (bcr?.x || 0) + (bcr?.width || 0);

      setTopParentLimit(_topParentLimit);
      setHintTop(_hintTop);
      setHintArrowTop(_hintArrowTop);
      setHintLeft(_hintLeft);
    }
  }, [
    bcr?.height,
    bcr?.width,
    bcr?.x,
    bcr?.y,
    topParentLimit,
    visible,
    hintTop,
    bcr,
    start,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setStart(true);
    }, 1);
  }, []);

  if (
    !activeSingleHint ||
    activeSingleHint.type === 'inline' ||
    !activeSingleHintTarget ||
    !activeSingleHintDisplayConfig
  ) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={clsx(
        css.hintItem,
        activeSingleHint.modalPlacement
          ? css[`hintItem_placement${ucFirst(activeSingleHint.modalPlacement)}`]
          : '',
        visible ? css.hintItem_visible : ''
      )}
      style={{
        transform: `translate(${hintLeft}px, ${hintTop}px)`,
      }}
    >
      <div
        ref={refArrow}
        className={css.hintItem__arrow}
        style={
          typeof hintArrowTop !== 'undefined'
            ? {transform: `translateY(${hintArrowTop}px)`}
            : undefined
        }
      >
        <div className={css.hintItem__arrowContent} />
      </div>

      <div className={css.hintItem__inner}>
        <HintInner
          title={
            activeSingleHint.title_key
              ? intl.formatMessage({id: activeSingleHint.title_key})
              : undefined
          }
          content={
            activeSingleHint.content_key
              ? intl.formatMessage({id: activeSingleHint.content_key})
              : undefined
          }
          onClose={handleCloseSingleHint}
          onOk={handleOkSingleHint}
          onCancel={handlePreviousSingleHint}
          closable
          okText={
            activeSingleHintDisplayConfig.nextHint !== undefined
              ? intl.formatMessage({id: 'App-Next'})
              : intl.formatMessage({id: 'App-Got_it'})
          }
          cancelText={
            activeSingleHintDisplayConfig.previousHint !== undefined
              ? intl.formatMessage({id: 'App-Previous'})
              : false
          }
        />
      </div>
    </div>
  );
};

export default HintSingleItem;
