import Endpoints, {WLEndpoints} from '../constants/endpoints';
import {
  ICardList,
  ICustomer,
  ICustomerCardsRequest,
  ICustomerRaw,
  IHistoryResponse,
  ICustomerAccountsRequest,
  IAccountList,
  IHistoryRequestParams,
  UUID4,
  IFormCreateCustomerData,
  ICustomerCardsBlockCallsParams,
  ICustomerCardsBlockCallsResponse,
} from '../types';
import WallesterAxios from '../utils/WAxios';
import {getApiPathParamsMaker} from './endpoints';

export const transformCustomerData = (data: ICustomerRaw): ICustomer => {
  return data;
  // let status: TPersonStatus = EPersonStatus.ACTIVE;
  // if (data.deactivated_at) {
  //   status = EPersonStatus.CLOSED;
  // }

  // return {
  //   ...data,
  //   status,
  // };
};

export const getCustomer = async (customerId: UUID4): Promise<ICustomer> => {
  const {data: response} = await WallesterAxios.getInstance()
    .getCommon()
    .get(getApiPathParamsMaker(Endpoints.customer, {person_id: customerId}));
  return transformCustomerData(response.person);
};

export const deactivateCustomer = async (
  customerId: UUID4
): Promise<ICustomer> => {
  const {data: response} = await WallesterAxios.getInstance()
    .getCommon()
    .delete(
      getApiPathParamsMaker(Endpoints.deactivateCustomer, {
        person_id: customerId,
      })
    );
  return transformCustomerData(response.person);
};

export const updateCustomer = async (
  customerId: UUID4,
  customer: Partial<ICustomer>
): Promise<ICustomer> => {
  const {data: response} = await WallesterAxios.getInstance()
    .getCommon()
    .patch(
      getApiPathParamsMaker(Endpoints.updateCustomer, {person_id: customerId}),
      customer
    );
  return transformCustomerData(response.person);
};

export const createCustomer = async (
  customer: IFormCreateCustomerData
): Promise<ICustomer> => {
  const {
    data: response,
  } = await WallesterAxios.getInstance()
    .getCommon()
    .post(getApiPathParamsMaker(Endpoints.createCustomer), {person: customer});
  return transformCustomerData(response.person);
};

export const getCustomerCards = async ({
  customerId,
  currentPage,
  pageSize,
  orderField,
  orderDirection,
  statuses,
}: ICustomerCardsRequest): Promise<ICardList> => {
  const queryParams: Record<
    string,
    string | number | string[] | number[] | boolean
  > = {
    from_record: currentPage ? (currentPage - 1) * pageSize : '',
    records_count: pageSize || '',
    order_field: orderField || '',
    order_direction: orderDirection || '',
  };

  if (statuses && statuses?.length > 0) {
    queryParams.card_statuses = statuses;
  }

  const {data: response} = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get(
      getApiPathParamsMaker(
        WLEndpoints.customerCards,
        {person_id: customerId},
        queryParams,
        true
      )
    );

  return {
    items: response.cards || [],
    totalItems: response.total_records_number || 0,
    orderField,
    orderDirection,
  };
};

export const getCustomerAccounts = async (
  params: ICustomerAccountsRequest
): Promise<IAccountList> => {
  const {
    customerId,
    currentPage,
    pageSize,
    isActive,
    statuses,
    vban,
    name,
  } = params;
  const reqParams: Record<
    string,
    string | number | string[] | number[] | boolean
  > = {
    from_record: (currentPage - 1) * pageSize,
    records_count: pageSize,
  };

  if (isActive) {
    reqParams.is_active = isActive;
  }

  if (vban) {
    reqParams.viban = vban;
  }

  if (name) {
    reqParams.account_name = name;
  }

  if (statuses?.length) {
    reqParams.account_statuses = statuses;
  }

  const {data: response} = await WallesterAxios.getInstance()
    .getWhiteLabelAxios()
    .get(
      getApiPathParamsMaker(
        WLEndpoints.customerAccounts,
        {person_id: customerId},
        reqParams,
        true
      )
    );

  return {
    items: response.accounts,
    totalItems: response.total_records_number,
    currentPage,
    pageSize,
  };
};

export const getCustomerHistory = async ({
  personId,
  currentPage,
  pageSize,
  fromDate,
  toDate,
}: IHistoryRequestParams): Promise<IHistoryResponse> => {
  const {data: response} = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(
        Endpoints.customerHistory,
        {person_id: personId},
        {
          from_record: (currentPage - 1) * pageSize,
          records_count: pageSize,
          from_date: fromDate.clone().startOf('day').utc().format(),
          to_date: toDate.clone().endOf('day').utc().format(),
        }
      )
    );

  return {
    items: response.history || [],
    totalItems: response.total_records_number || 0,
  };
};

export const getCustomerCardsBlockCalls = async ({
  personId,
  currentPage,
  pageSize,
  fromDate,
  toDate,
}: ICustomerCardsBlockCallsParams): Promise<ICustomerCardsBlockCallsResponse> => {
  const {data: response} = await WallesterAxios.getInstance()
    .getCommon()
    .get(
      getApiPathParamsMaker(
        Endpoints.customerCardsBlockCalls,
        {person_id: personId},
        {
          from_record: (currentPage - 1) * pageSize,
          records_count: pageSize,
          from_date: fromDate.clone().startOf('day').utc().format(),
          to_date: toDate.clone().endOf('day').utc().format(),
        }
      )
    );
  return {
    items: response.cards_block_logs,
    totalItems: response.total_records_number,
    currentPage,
    fromDate,
    toDate,
  };
};

export default getCustomer;
