import {ClientCompany, ClientPerson} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const getPersonsAndCompanies: DemoApiHandler = ({config, instance}) => {
  return Demo.makeDataResponse<{
    list: {person?: ClientPerson; company?: ClientCompany}[];
  }>(config, {
    list: instance.getData().verificationPersonsAndCompanies,
  });
};

export default getPersonsAndCompanies;
