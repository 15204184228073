import React from 'react';
import useIsGuest from '../../customHooks/useIsGuest';
import HintsLayer from '../Hints/HintsLayer/HintsLayer';
import TutorialLayer from '../Tutorial/TutorialLayer';
import BrowserCompatibilityManager from './Managers/BrowserCompatibilityManager';
import DialogNotificationsManager from './Managers/DialogNotificationsManager/DialogNotificationsManager';
import FeatureManager from './Managers/FeatureManager';
import MessengerManager from './Managers/MessengerManager';
import ProductManager from './Managers/ProductManager';
import SessionManager from './Managers/SessionManager/SessionManager';
import SyncManager from './Managers/SyncManager';
import TimezoneManager from './Managers/TimezoneManager';
import UnverifiedDataManager from './Managers/UnverifiedDataManager';
// import UpdateManager from './Managers/UpdateManager';

const SystemManager: React.FC = () => {
  const isGuest = useIsGuest();

  return (
    <>
      <BrowserCompatibilityManager />
      {/* <UpdateManager /> */}
      <HintsLayer />
      <FeatureManager />
      <TutorialLayer />
      <MessengerManager />

      {!isGuest && (
        <>
          <SyncManager />
          <SessionManager />
          <DialogNotificationsManager />
          <TimezoneManager />
          <ProductManager />
          <UnverifiedDataManager />
        </>
      )}
    </>
  );
};

export default SystemManager;
