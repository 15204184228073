import {MerchantFilterType, MerchantTypeCategoryInfo} from '../types';

export const MerchantTypeCategory: Record<
  MerchantFilterType,
  MerchantTypeCategoryInfo
> = {
  Cash: {
    categories: ['6010', '6011'],
    title_code: 'App-Cash',
  },
  Entertainment: {
    categories: [
      '4119',
      '7829',
      '7841',
      '7832',
      '7922',
      '7911',
      '7994',
      '7929',
      '7932',
      '7999',
      '7933',
      '7941',
      '7991',
      '5971',
      '7996',
      '7998',
      '7992',
      '7997',
      '7993',
    ],
    title_code: 'App-Entertainment',
  },
  Groceries: {
    categories: ['5499', '5411', '5422', '5441', '5451', '5462'],
    title_code: 'App-Groceries',
  },
  Health: {
    categories: [
      '4119',
      '8099',
      '8011',
      '8021',
      '8031',
      '8041',
      '8042',
      '8043',
      '8049',
      '8050',
      '8062',
      '8071',
      '5912',
      '5975',
      '5976',
      '5996',
      '7298',
    ],
    title_code: 'App-Health',
  },
  Restaurants: {
    categories: ['5813', '5811', '5812', '5814'],
    title_code: 'App-Restaurants',
  },
  Services: {
    categories: [
      '7399',
      '7512',
      '7531',
      '7538',
      '0780',
      '7519',
      '4011',
      '4214',
      '4215',
      '4225',
      '9402',
      '4789',
      '5962',
      '4457',
      '4829',
      '6051',
      '7273',
      '7277',
      '9223',
      '7995',
      '7230',
      '7261',
      '6300',
      '7513',
      '8111',
      '8351',
      '8911',
      '8931',
      '8999',
      '7278',
      '7296',
      '7299',
      '7210',
      '7211',
      '7216',
      '7217',
      '7221',
      '7251',
      '7276',
      '1520',
      '1711',
      '1731',
      '1740',
      '1750',
      '1761',
      '1771',
      '1799',
      '0742',
      '0763',
      '2842',
      '7311',
      '7321',
      '7322',
      '7333',
      '7338',
      '7339',
      '7342',
      '7349',
      '7361',
      '7372',
      '7375',
      '7379',
      '7392',
      '7393',
      '7394',
      '7395',
      '7297',
    ],
    title_code: 'App-Services',
  },
  Shopping: {
    categories: [
      '5611',
      '5137',
      '5651',
      '5719',
      '5699',
      '5631',
      '5681',
      '5932',
      '5691',
      '5697',
      '5947',
      '5139',
      '5621',
      '5641',
      '5661',
      '5655',
      '5933',
      '5950',
      '5972',
      '5941',
      '5949',
      '5994',
      '5732',
      '4812',
      '5200',
      '5251',
      '5271',
      '5300',
      '5309',
      '5310',
      '5331',
      '5311',
      '5733',
      '5735',
      '5931',
      '5937',
      '5940',
      '5942',
      '5943',
      '5945',
      '5946',
      '5948',
      '5970',
      '5973',
      '5977',
      '5978',
      '5992',
      '5993',
      '5995',
      '5997',
      '5998',
      '5999',
    ],
    title_code: 'App-Shopping',
  },
  Transport: {
    categories: [
      '5013',
      '5511',
      '5533',
      '5521',
      '5532',
      '5561',
      '5571',
      '5592',
      '5598',
      '5599',
      '5542',
      '5541',
      '4111',
      '4121',
      '7523',
    ],
    title_code: 'App-Transport',
  },
  Travel: {
    categories: [
      '7012',
      '4411',
      '7032',
      '7011',
      '7033',
      '4112',
      '4131',
      '4722',
      '4582',
      '4511',
      '4784',
    ],
    title_code: 'App-Travel',
  },
  Utilities: {
    categories: [
      '4821',
      '4814',
      '4899',
      '4900',
      '5983',
      '4468',
      '4816',
      '5967',
      '5211',
      '5231',
      '5261',
      '5712',
      '5713',
      '5714',
      '5718',
      '5722',
    ],
    title_code: 'App-Utilities',
  },
};

export default MerchantTypeCategory;
