import clsx from 'clsx';
import React, {useMemo} from 'react';
import {ReactComponent as IconCheckmark} from '../../images/icons/checkmark.svg';
import {ReactComponent as IconCheckmarkRounded} from '../../images/icons/success.svg';
import css from './MarkList.module.less';

export type MarklistItem = {
  content: React.ReactNode;
  disabled?: boolean;
};

interface MarkListProps {
  className?: string;
  items?: MarklistItem[];
  listStyle?: 'dots' | 'checkmark' | 'checkmarkRounded' | 'decimal';
  listStyleColor?: 'green' | 'blue';
  listStyleAlign?: 'top';
  tight?: boolean;
}

const MarkList: React.FC<React.PropsWithChildren<MarkListProps>> = ({
  className,
  items,
  listStyle,
  listStyleColor,
  listStyleAlign,
  tight,
}) => {
  const marker = useMemo(() => {
    const classNames = clsx(css.markList__itemIcon);

    switch (listStyle) {
      case 'dots':
        return <span className={classNames}>&#8226;</span>;

      case 'checkmark':
        return <IconCheckmark className={classNames} />;

      case 'checkmarkRounded':
        return <IconCheckmarkRounded className={classNames} />;

      case 'decimal':
        return (i: number) => <span className={classNames}>{i + 1}.</span>;

      default:
        return null;
    }
  }, [listStyle]);

  return (
    <ul
      className={clsx(
        css.markList,
        listStyle && css[`markList_${listStyle}`],
        listStyleColor && css[`markList_${listStyleColor}`],
        listStyleAlign && css[`markList_${listStyleAlign}`],
        tight && css.markList_tight,
        className
      )}
    >
      {items?.map((item, i) => {
        const key = i;

        return (
          <li
            key={key}
            className={clsx(
              css.markList__item,
              item.disabled && css.markList__item_disabled
            )}
          >
            {typeof marker === 'function' ? marker(i) : marker}

            <div className={css.markList__itemContent}>{item.content}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default MarkList;
