import Demo, {DemoApiHandler} from '../demo';
import {signDataImpl} from '../crypto';

const getCardNumber: DemoApiHandler = ({config, instance, url, data}) => {
  const item = instance
    .getData()
    .cards[instance.getData().productId].find(
      (a) => a.id === url?.params?.card_id
    );

  if (item) {
    const pem = atob(data.public_key as string);

    return Demo.makeDataResponse<{encrypted_card_number: string}>(config, {
      encrypted_card_number: atob(
        signDataImpl(`${item.masked_card_number}`, pem, 'CardNumber') || ''
      ),
    });
  }

  return Demo.makeUnprocessableEntityResponse(config, 30001);
};

export default getCardNumber;
