import Demo, {DemoApiHandler} from '../demo';
import {PaymentType} from '../../types';
import PaymentDocumentFile from '../../types/payment';

const getPaymentDocumentThumbnails: DemoApiHandler = ({
  config,
  instance,
  url,
}) => {
  const params = url.queryParams as {
    payment_id: string;
    payment_type: PaymentType;
  };

  const items = instance
    .getData()
    .paymentDocuments.filter(
      (item) =>
        item.payment_id === params.payment_id &&
        item.payment_type === params.payment_type
    );

  return Demo.makeDataResponse<{
    payment_documents: PaymentDocumentFile[];
  }>(config, {
    payment_documents: items,
  });
};

export default getPaymentDocumentThumbnails;
