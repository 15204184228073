import {AxiosResponse} from 'axios';
import Endpoints from '../constants/endpoints';
import {CountryCodes3l} from '../types';
import WallesterAxios from '../utils/WAxios';

export const getGeoByIp = async (): Promise<CountryCodes3l> => {
  const response: AxiosResponse<{
    country: CountryCodes3l;
  }> = await WallesterAxios.getInstance()
    .getBaseInstance()
    .get({endpoint: Endpoints.getCountryCodeByIp});

  return response.data.country;
};

export default getGeoByIp;
