import {ICompany, UpdateCompanyLimitsParams} from '../../types';
import Demo, {DemoApiHandler} from '../demo';
import ObjectKeys from '../object';

const updateCompanyLimitsHandler: DemoApiHandler = ({
  config,
  instance,
  url,
  data,
}) => {
  if (url?.params?.company_id) {
    const company = instance
      .getData()
      .companies.find((c) => c.id === url?.params?.company_id);
    if (company) {
      const limitData = data as {
        limits: Omit<UpdateCompanyLimitsParams, 'companyId'>;
      };
      if (limitData && limitData.limits) {
        ObjectKeys(limitData.limits).forEach((limitKey) => {
          const limitValue = limitData.limits[limitKey];
          if (limitValue !== undefined) {
            company.limits[limitKey] = limitValue;
          }
        });
        instance.saveImpl(['companies']);
        return Demo.makeDataResponse<{company: ICompany}>(config, {
          company,
        });
      }
    }
  }
  return Demo.makeUnprocessableEntityResponse(config);
};

export default updateCompanyLimitsHandler;
