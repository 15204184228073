import {useStoreMap} from 'effector-react';
import React from 'react';
import {App$} from '../../models/app';
import {CurrentLocaleStore} from '../../models/i18n';
import ConfigContext from './context';

const ConfigProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const config = useStoreMap({
    store: App$,
    keys: ['config'],
    fn: (store$) => store$.config,
  });

  const [locale, momentLocale] = useStoreMap({
    store: CurrentLocaleStore,
    keys: ['locale', 'momentLocale'],
    fn: (state) => [state.locale, state.momentLocale],
  });

  return (
    <ConfigContext.Provider
      value={{
        config,
        locale,
        momentLocale,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
