import forge from 'node-forge';
import config from '../constants/config';
import {KeyDecryptor, SecurityItemType} from '../types';

type KeyPair = forge.pki.rsa.KeyPair;

const decodePEM = (pem: string, label: string): string => {
  const arrayOfPEM = pem.split(`\n`).filter((i) => i);
  if (arrayOfPEM.length < 3) {
    throw Error('Invalid PEM');
  }
  if (arrayOfPEM[0] !== `-----BEGIN ${label} MESSAGE-----`) {
    if (arrayOfPEM[0].indexOf(label) === -1) {
      throw Error('Invalid label');
    }
    throw Error('Incorrect PEM structure1');
  }
  if (arrayOfPEM[arrayOfPEM.length - 1] !== `-----END ${label} MESSAGE-----`) {
    throw Error('Incorrect PEM structure2');
  }
  return arrayOfPEM.slice(1, -1).join(``);
};

export const generateKeyDecryptor = (
  type: SecurityItemType
): Promise<KeyDecryptor> => {
  return new Promise<KeyDecryptor>((resolve) => {
    const {pki} = forge;
    const {rsa} = pki;
    Promise.resolve()
      .then((): KeyPair | Promise<KeyPair> => {
        if (window.location.protocol === 'https-:') {
          return new Promise((resolve1) => {
            rsa.generateKeyPair(
              {
                bits: 1024,
              },
              (error, keyPair) => {
                resolve1(keyPair);
              }
            );
          });
        }
        return rsa.generateKeyPair({
          bits: 1024,
        });
      })
      .then((keypair: KeyPair) => {
        const pem = pki.publicKeyToPem(keypair.publicKey);
        const publicKey = btoa(pem);
        resolve({
          publicKey,
          decryptor: (encryptedResponseItem: string): string => {
            const decodedPem = decodePEM(encryptedResponseItem, type);
            return forge.util.decodeUtf8(
              keypair.privateKey.decrypt(atob(decodedPem), 'RSA-OAEP', {
                md: forge.md.sha256.create(),
                label: type,
              })
            );
          },
        });
      });
  });
};

export const checkPublicKeyValidity = (publicKey: string): boolean => {
  const {pki} = forge;
  try {
    pki.publicKeyFromPem(publicKey);
  } catch (e) {
    return false;
  }
  return true;
};

const signPinImpl = (pin: string, publicKey: string): string | null => {
  const {pki} = forge;
  try {
    const pk = pki.publicKeyFromPem(publicKey);
    const encryptedPin = pk.encrypt(pin, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      label: 'PIN',
    });
    const encryptedPrePin = btoa(encryptedPin);
    const encryptedParts = encryptedPrePin.match(/.{1,64}/g);

    const prePin = `-----BEGIN PIN MESSAGE-----
${(encryptedParts || []).join('\n')}
-----END PIN MESSAGE-----
`;
    return btoa(prePin);
  } catch (e) {
    return null;
  }
};

export const signDataImpl = (
  pin: string,
  publicKey: string,
  type: string
): string | null => {
  const {pki} = forge;
  try {
    const pk = pki.publicKeyFromPem(publicKey);
    const encryptedPin = pk.encrypt(pin, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      label: type,
    });
    const encryptedPrePin = btoa(encryptedPin);
    const encryptedParts = encryptedPrePin.match(/.{1,64}/g);

    const prePin = `-----BEGIN ${type} MESSAGE-----
${(encryptedParts || []).join('\n')}
-----END ${type} MESSAGE-----
`;
    return btoa(prePin);
  } catch (e) {
    return null;
  }
};

export const signChangePin = (pin: string): string | null => {
  if (config.secretPublicKeyUpdate) {
    return signPinImpl(pin, config.secretPublicKeyUpdate);
  }
  return null;
};

export const signCreateCardPin = (pin: string): string | null => {
  if (config.secretPublicKeyCreate) {
    return signPinImpl(pin, config.secretPublicKeyCreate);
  }
  return null;
};

export default generateKeyDecryptor;
