import clsx from 'clsx';
import {Modal, ModalFuncProps} from 'antd';
import React, {useMemo, useState, useEffect, useRef} from 'react';
import useIntl from '../../customHooks/useIntl';
import Button from '../Button';
import {modalWidth} from '../../constants/styles';
import BrowserDetect from '../../utils/browserDetect';
import LinkBrowser from '../LinkBrowser';
import useMedias from '../../customHooks/useMedias';
import Browser from '../../types/browsers';
import css from './BrowserOutdated.module.less';

interface BrowserOutdatedProps {
  onClose?: () => void;
}

const BrowserOutdated: React.FC<
  React.PropsWithChildren<BrowserOutdatedProps>
> = ({onClose}) => {
  const intl = useIntl();
  const media = useMedias();
  const [isVisible, setIsVisible] = useState(true);
  const browserType = useMemo((): Browser => {
    let type: Browser;

    if (BrowserDetect.isFirefox()) {
      type = 'firefox';
    } else if (BrowserDetect.isSafari()) {
      type = 'safari';
    } else if (BrowserDetect.isOpera()) {
      type = 'opera';
    } else if (BrowserDetect.isEdge()) {
      type = 'edge';
    } else {
      type = 'chrome';
    }

    return type;
  }, []);
  const modalInfo = useRef<{
    destroy: () => void;
    update?: (
      configUpdate:
        | ModalFuncProps
        | ((prevConfig: ModalFuncProps) => ModalFuncProps)
    ) => void;
  }>();

  const modalContent = useMemo(() => {
    return (
      <>
        <div className={css.modalDesc}>
          {intl.formatMessage({
            id:
              'App-Wallester_works_in_the_latest_versions__we_highly_recommend_',
          })}
        </div>

        <LinkBrowser
          className={css.modalLink}
          browser={browserType}
          isHorizontal
          isHovered={false}
          label={intl.formatMessage({id: 'App-Download'})}
        />

        <div className={css.modalCancel}>
          <Button
            onClick={() => {
              setIsVisible(false);
            }}
            className="ant-btn_transparent ant-btn_link"
          >
            {intl.formatMessage({
              id: 'App-Skip_for_now',
            })}
          </Button>
        </div>
      </>
    );
  }, [browserType, intl]);

  useEffect(() => {
    if (!media.xs) {
      if (!modalInfo.current) {
        modalInfo.current = Modal.info({
          title: intl.formatMessage({
            id: 'BrowserOutdated-You_re_using_an_unsupported_web_browser_',
          }),
          content: modalContent,
          className: css.modal,
          width: modalWidth,
          icon: <></>,
          centered: true,
          closable: true,
        });
      }
    } else if (modalInfo.current) {
      modalInfo.current?.destroy();
      modalInfo.current = undefined;
    }
  }, [intl, isVisible, media.xs, modalContent]);

  useEffect(() => {
    if (!isVisible && onClose) {
      onClose();
    }
  }, [isVisible, onClose]);

  useEffect(() => {
    return () => {
      if (modalInfo.current) {
        modalInfo.current?.destroy();
      }
    };
  }, []);

  if (!media.xs) {
    return <></>;
  }

  return (
    <Modal
      className={clsx('ant-modal_sticky', css.modal)}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'BrowserOutdated-You_re_using_an_unsupported_web_browser_',
      })}
      afterClose={onClose}
      width={modalWidth}
      destroyOnClose
      maskClosable={false}
    >
      {modalContent}
    </Modal>
  );
};

export default BrowserOutdated;
