import {Modal} from 'antd';
import clsx from 'clsx';
import {useStoreMap} from 'effector-react';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import Button from '../../../../components/Button';
import AppStatus from '../../../../constants/app';
import config from '../../../../constants/config';
import {modalWidth} from '../../../../constants/styles';
import useIsDemo from '../../../../customHooks/useIsDemo';
import useMedias from '../../../../customHooks/useMedias';
import useQuery from '../../../../customHooks/useQuery';
import {App$} from '../../../../models/app';
import {gotoLogin} from '../../../../models/router';
import AuthService from '../../../../services/AuthService';
import {GoToLoginMode} from '../../../../types';
import css from './SessionManager.module.less';

const SHOW_TIME = 60;

const SessionManager: React.FC = () => {
  const {refreshTokenExpireTs, appStatus} = useStoreMap({
    store: App$,
    keys: ['refreshTokenExpireTs', 'appStatus'],
    fn: (state) => ({
      refreshTokenExpireTs: state.refreshTokenExpireTs,
      appStatus: state.appStatus,
    }),
  });

  const isDemo = useIsDemo();
  const {test_mode} = useQuery();
  const [isVisible, setIsVisible] = useState(false);
  const [refreshInProgress, setRefreshInProgress] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const intl = useIntl();
  const media = useMedias();
  const timerCallbackRef = useRef<() => void>();

  const showTime = useMemo(() => {
    if (test_mode !== undefined && config.isDev) {
      return 1770;
    }

    return SHOW_TIME;
  }, [test_mode]);

  const handleLogout = useCallback(async (automatically = false) => {
    if (automatically) {
      setIsVisible(false);
    }
    const tokenInfo = AuthService.getInstance().getTokenInfo(true);
    if (tokenInfo) {
      await AuthService.getInstance().logout();
    }
    gotoLogin(automatically ? GoToLoginMode.Expired : undefined);
    if (!automatically) {
      setIsVisible(false);
    }
  }, []);

  const handleRefreshToken = useCallback(async () => {
    setRefreshInProgress(true);
    await AuthService.getInstance().checkToken(true, true);
    setRefreshInProgress(false);
  }, []);

  const timerCallback = useCallback(async () => {
    if (!isDemo) {
      const ts = Math.round(+new Date() / 1000);
      const diff = refreshTokenExpireTs - ts;

      if (diff <= showTime) {
        setSeconds(diff);

        if (diff <= 0) {
          await handleLogout(true);
        } else if (!isVisible) {
          setIsVisible(true);
        }
      } else if (isVisible) {
        setIsVisible(false);
      }
    }
  }, [isDemo, refreshTokenExpireTs, handleLogout, isVisible, showTime]);

  useEffect(() => {
    timerCallbackRef.current = timerCallback;
  }, [timerCallback, timerCallbackRef]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timerCallbackRef.current && appStatus === AppStatus.initialized) {
        timerCallbackRef.current();
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [timerCallbackRef, appStatus]);

  if (isDemo) {
    return null;
  }

  return (
    <Modal
      className={clsx('ant-modal_sticky', css.sessionManager)}
      visible={isVisible}
      title={intl.formatMessage({id: 'SessionManager-Shall_we_continue_'})}
      width={modalWidth}
      footer={false}
      closable={false}
      destroyOnClose
      centered={!media.xs}
      maskClosable={false}
    >
      <div className={css.sessionManagerDesc}>
        {intl.formatMessage(
          {
            id:
              'SessionManager-If_you_don_t_want_to_proceed__we_will_automatically_log_you_',
          },
          {
            seconds: (
              <span className={css.sessionManagerDesc__accent}>{seconds}</span>
            ),
          }
        )}
      </div>

      <div className="ant-modal-body__btns">
        <Button
          type="primary"
          loading={refreshInProgress}
          onClick={handleRefreshToken}
          className={clsx(
            css.sessionManager__action,
            css.sessionManager__action_ok,
            'ant-btn_green-emerald'
          )}
        >
          {intl.formatMessage({id: 'App-Continue'})}
        </Button>

        <Button
          onClick={() => {
            handleLogout(false);
          }}
          className={clsx(
            css.sessionManager__action,
            css.sessionManager__action_cancel,
            'ant-btn_transparent'
          )}
        >
          {intl.formatMessage({id: 'SessionManager-Log_out_now'})}
        </Button>
      </div>
    </Modal>
  );
};

export default SessionManager;
