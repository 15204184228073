import Endpoints from '../constants/endpoints';
import isAllowedEndpoint from './freeze';
import {AllowedEndpoints} from '../types';

export const checkEndpoints = ({
  endpoints,
  existEndpoints,
  partialMode,
  isWhiteLabel,
}: {
  endpoints: Endpoints[];
  existEndpoints: AllowedEndpoints;
  partialMode?: boolean;
  isWhiteLabel?: boolean;
}): boolean => {
  if (endpoints.length === 0 || isWhiteLabel) {
    return true;
  }

  return partialMode
    ? endpoints.some((endpoint) => isAllowedEndpoint(endpoint, existEndpoints))
    : !endpoints.some(
        (endpoint) => !isAllowedEndpoint(endpoint, existEndpoints)
      );
};

export default checkEndpoints;
