import moment from 'moment-timezone';
import {IEmployeesCardCreateParams} from '../../types';
import Demo, {DemoApiHandler} from '../demo';

const reInviteEmployee: DemoApiHandler = ({config, instance, url, data}) => {
  if (url?.params?.employee_id) {
    const employee = instance
      .getData()
      .employees[instance.getData().productId].find(
        (c) => c.id === url?.params?.employee_id
      );

    if (employee) {
      const currentData = data as IEmployeesCardCreateParams;

      if (currentData) {
        employee.last_invited_at = moment().toISOString();

        instance.saveImpl(['employees']);

        return Demo.makeDataResponse<{success: boolean}>(config, {
          success: true,
        });
      }
    }
  }

  return Demo.makeUnprocessableEntityResponse(config, 90721);
};

export default reInviteEmployee;
