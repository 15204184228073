import React, {ErrorInfo, PureComponent, Suspense} from 'react';
import Loader from '../../../components/Loader';
import logger from '../../../logger';
import LangProvider from '../../i18n/LangProvider';
import Maintenance from './Maintenance';

const log = logger.module('MaintenanceBoundary');

const initialState = {
  error: null,
  eventId: null,
};

class MaintenanceBoundary extends PureComponent<React.PropsWithChildren> {
  state = {...initialState};

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    log.error('Catch error', {error, errorInfo});
    this.setState({error});
  }

  render(): React.ReactNode {
    const {error} = this.state;
    const {children} = this.props;

    if (error) {
      return (
        <Suspense fallback={<Loader size="large" transparent={false} />}>
          <LangProvider lang="en">
            <Maintenance />
          </LangProvider>
        </Suspense>
      );
    }

    return children;
  }
}

export default MaintenanceBoundary;
