import TutorialStep, {TUTORIAL_STEP} from '../types/tutorial';

export const DEMO_TUTORIAL: TutorialStep[] = [
  {
    id: TUTORIAL_STEP.DEMO_GENERAL_VIEW,
    title_key: 'Tutorial-General_view',
    text_key: 'Tutorial-This_is_your_personal_dashboard_for_daily_activities',
  },
  {
    id: TUTORIAL_STEP.DEMO_TOP_UP,
    title_key: 'App-Top_up',
    text_key: 'Tutorial-First_thing_first__here_is_the_balance_that_can',
  },
  {
    id: TUTORIAL_STEP.DEMO_ACCOUNTS,
    title_key: 'App-Accounts',
    text_key: 'Tutorial-Set_up_accounts_to_reflect_your_business__then_divide',
  },
  {
    id: TUTORIAL_STEP.DEMO_CREATE_CARD,
    title_key: 'Tutorial-Create_a_card__Expense___Payroll_',
    text_key: 'Tutorial-To_start_with__you_will_need_to_have_the_cards_',
  },
  {
    id: TUTORIAL_STEP.DEMO_PAYMENTS,
    title_key: 'Tutorial-Payments__Expense_or_Payroll_',
    text_key:
      'Tutorial-Instantly_transfer_money_between_your_main_and_subaccounts__a',
  },
  {
    id: TUTORIAL_STEP.DEMO_STATEMENTS,
    title_key: 'Tutorial-Statements',
    text_key:
      'Tutorial-Generate_the_reports_in_a_suitable_format__language_and_speci',
  },
  {
    id: TUTORIAL_STEP.DEMO_COMPANY_LIMITS,
    title_key: 'Tutorial-Company_limits',
    text_key:
      'Tutorial-Adjust_the_company_limits_for_different_payment_methods__and_',
  },
  {
    id: TUTORIAL_STEP.DEMO_ADD_CURRENCY,
    title_key: 'Tutorial-Add_currency',
    text_key:
      'Tutorial-Manage_your_daily_expenses_in_the_most_convenient_currency_yo',
  },
  {
    id: TUTORIAL_STEP.DEMO_3DS_BIN,
    title_key: 'Tutorial-3DS_Whitelist___BIN',
    text_key:
      'Tutorial-Bypass_the_3D_Secure_authentication_process_to_speed_up_your',
  },
  {
    id: TUTORIAL_STEP.DEMO_PRICING_PLAN,
    title_key: 'Tutorial-Pricing_plan',
    text_key:
      'Tutorial-Explore_your_current_plan_and_card_usage_details__If_you_find',
  },
  {
    id: TUTORIAL_STEP.DEMO_API,
    title_key: 'Tutorial-API',
    text_key:
      'Tutorial-Seamless_integration_with_your_product_or_accounting_platform',
  },
  {
    id: TUTORIAL_STEP.DEMO_AFFILIATE_PROGRAM,
    title_key: 'Tutorial-Affiliate_program',
    text_key:
      'Tutorial-You_can_submit_an_application_and_earn_commission_for_the_ref',
  },
  {
    id: TUTORIAL_STEP.DEMO_PROFILE_SECTION,
    title_key: 'Tutorial-Profile_section',
    text_key:
      'Tutorial-Manage_your_company_and_personal_details__Change_the_timezone',
  },
  {
    id: TUTORIAL_STEP.DEMO_CONTACT_SUPPORT,
    title_key: 'Tutorial-Contact_Support',
    text_key:
      'Tutorial-Have_questions_or_need_assistance__Connect_with_our_support_',
  },
  {
    id: TUTORIAL_STEP.DEMO_SEARCH,
    title_key: 'Tutorial-Search',
    text_key:
      'Tutorial-Find_the_card__account__or_person_by_using_the_search_functio',
  },
];

const TUTORIAL: TutorialStep[] = [];

export default TUTORIAL;
