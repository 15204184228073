import clsx from 'clsx';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useIntl} from 'react-intl';
import {useStoreMap} from 'effector-react';
import css from '../TutorialLayer/TutorialLayer.module.less';
import ModalWizard from '../../../components/ModalWizard';
import TutorialStore, {
  PopupPlacement,
  ShowTutorialStepParams,
} from '../../../models/tutorial';

interface TutorialLayerItemProps {
  config?: ShowTutorialStepParams | null;
  handleBeforeNext?: () => void;
  handleAfterNext?: () => void;
  cuteSpace?: number;
}

const TutorialModal: React.FC<
  React.PropsWithChildren<TutorialLayerItemProps>
> = ({config, handleBeforeNext, handleAfterNext, cuteSpace = 12}) => {
  const intl = useIntl();
  const refModal = useRef<HTMLDivElement>(null);
  const {steps} = useStoreMap({
    store: TutorialStore,
    keys: ['steps'],
    fn: (state) => {
      return {
        steps: state.steps,
      };
    },
  });
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [visible, setVisible] = useState(false);

  const activeStep = useMemo(() => {
    return steps.find((s) => s.id === config?.step) || null;
  }, [config?.step, steps]);

  const activeStepIndex = useMemo(() => {
    return activeStep ? steps.indexOf(activeStep) + 1 : -1;
  }, [activeStep, steps]);

  const [currentPlacement, setCurrentPlacement] = useState(config?.placement);

  const recalculateModalParams = useCallback(() => {
    if (config?.target && config?.placement && activeStep) {
      const targetRect = config?.target.getBoundingClientRect();
      const wrapWidth = window.innerWidth || 0;
      const wrapHeight = window.innerHeight || 0;
      const popupWidth = refModal.current?.offsetWidth || 0;
      const popupHeight = refModal.current?.offsetHeight || 0;
      let placement = config?.placement;
      let currentTop = 0;
      let currentLeft = 0;

      if (
        (placement === 'top' ||
          placement === 'topRight' ||
          placement === 'topLeft') &&
        targetRect.y - popupHeight < 0 &&
        wrapHeight - targetRect.y - targetRect.height - popupHeight >= 0
      ) {
        placement = placement.replace('top', 'bottom') as PopupPlacement;
      }

      if (
        (placement === 'bottom' ||
          placement === 'bottomRight' ||
          placement === 'bottomLeft') &&
        targetRect.y - popupHeight >= 0 &&
        wrapHeight - targetRect.y - targetRect.height - popupHeight < 0
      ) {
        placement = placement.replace('bottom', 'top') as PopupPlacement;
      }

      if (
        (placement === 'right' ||
          placement === 'rightTop' ||
          placement === 'rightBottom') &&
        targetRect.x - popupWidth >= 0 &&
        wrapWidth - targetRect.x - targetRect.width - popupWidth < 0
      ) {
        placement = placement.replace('right', 'left') as PopupPlacement;
      }

      if (
        (placement === 'left' ||
          placement === 'leftTop' ||
          placement === 'leftBottom') &&
        targetRect.x - popupWidth < 0 &&
        wrapWidth - targetRect.x - targetRect.width - popupWidth >= 0
      ) {
        placement = placement.replace('left', 'right') as PopupPlacement;
      }

      switch (placement) {
        case 'topLeft':
          currentTop = targetRect.y - popupHeight;
          currentLeft = targetRect.x;
          break;

        case 'top':
          currentTop = targetRect.y - popupHeight;
          currentLeft = targetRect.x + targetRect.width / 2 - popupWidth / 2;
          break;

        case 'topRight':
          currentTop = targetRect.y - popupHeight;
          currentLeft = targetRect.x + targetRect.width - popupWidth;
          break;

        case 'rightTop':
          currentTop = targetRect.y;
          currentLeft = targetRect.x + targetRect.width;
          break;

        case 'right':
          currentTop = targetRect.y + targetRect.height / 2 - popupHeight / 2;
          currentLeft = targetRect.x + targetRect.width;
          break;

        case 'rightBottom':
          currentTop = targetRect.y + targetRect.height - popupHeight;
          currentLeft = targetRect.x + targetRect.width;
          break;

        case 'bottomLeft':
          currentTop = targetRect.y + targetRect.height;
          currentLeft = targetRect.x;
          break;

        case 'bottom':
          currentTop = targetRect.y + targetRect.height;
          currentLeft = targetRect.x + targetRect.width / 2 - popupWidth / 2;
          break;

        case 'bottomRight':
          currentTop = targetRect.y + targetRect.height;
          currentLeft = targetRect.x + targetRect.width - popupWidth;
          break;

        case 'leftTop':
          currentTop = targetRect.y;
          currentLeft = targetRect.x - popupWidth;
          break;

        case 'left':
          currentTop = targetRect.y + targetRect.height / 2 - popupHeight / 2;
          currentLeft = targetRect.x - popupWidth;
          break;

        case 'leftBottom':
          currentTop = targetRect.y + targetRect.height - popupHeight;
          currentLeft = targetRect.x - popupWidth;
          break;

        default:
          break;
      }

      if (currentLeft + popupWidth + cuteSpace > wrapWidth) {
        currentLeft = wrapWidth - popupWidth - cuteSpace;
      }

      if (currentLeft < 0) {
        currentLeft = 0;
      }

      if (currentTop + popupHeight + cuteSpace > wrapHeight) {
        currentTop = wrapHeight - popupHeight - cuteSpace;
      }

      if (currentTop < 0) {
        currentTop = 0;
      }

      setTop(currentTop);
      setLeft(currentLeft);
    }
  }, [activeStep, config?.placement, config?.target, cuteSpace]);

  const handleNext = useCallback(() => {
    if (handleBeforeNext) {
      handleBeforeNext();
    }

    if (handleAfterNext) {
      handleAfterNext();
    }
  }, [handleAfterNext, handleBeforeNext]);

  useEffect(() => {
    setCurrentPlacement(config?.placement);
  }, [config?.placement]);

  useLayoutEffect(() => {
    setVisible(false);
  }, [config, handleBeforeNext, handleAfterNext, cuteSpace]);

  useEffect(() => {
    setVisible(false);

    recalculateModalParams();

    if (config?.target && config?.placement && activeStep) {
      setTimeout(() => {
        setVisible(true);
      }, 1);
    }
  }, [
    activeStep,
    recalculateModalParams,
    config,
    handleBeforeNext,
    handleAfterNext,
    cuteSpace,
  ]);

  useEffect(() => {
    window.addEventListener('resize', recalculateModalParams);

    const observer = new MutationObserver(recalculateModalParams);

    if (observer) {
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener('resize', recalculateModalParams);

      if (observer) {
        observer.disconnect();
      }
    };
  }, [recalculateModalParams]);

  if (activeStep) {
    return (
      <div
        ref={refModal}
        className={clsx(
          css.layer__modal,
          visible ? css.layer__modal_visible : '',
          visible ? css.layer__modal_animated : '',
          currentPlacement ? css[`layer__modal_${currentPlacement}`] : ''
        )}
        style={{
          transform: `translate(${left}px, ${top}px)`,
        }}
      >
        <div className={css.layer__modalInner}>
          <ModalWizard
            title={intl.formatMessage({id: activeStep.title_key})}
            description={
              activeStep.component === undefined
                ? intl.formatMessage({id: activeStep.text_key})
                : activeStep.component
            }
            // placement={config?.placement}
            onNext={handleNext}
            currentStep={activeStepIndex}
            totalSteps={steps.length}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default TutorialModal;
