import Demo, {DemoApiHandler} from '../demo';
import {signDataImpl} from '../crypto';

const getCardPinCode: DemoApiHandler = ({config, instance, url, data}) => {
  const item = instance
    .getData()
    .cards[instance.getData().productId].find(
      (a) => a.id === url?.params?.card_id
    );

  if (item) {
    const pem = atob(data.public_key as string);

    return Demo.makeDataResponse<{encrypted_pin: string}>(config, {
      encrypted_pin: atob(signDataImpl(item.pin || '1234', pem, 'PIN') || ''),
    });
  }

  return Demo.makeUnprocessableEntityResponse(config, 30001);
};

export default getCardPinCode;
