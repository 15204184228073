import React, {useMemo, useState} from 'react';
import clsx from 'clsx';
import {Modal} from 'antd';
import {useIntl} from 'react-intl';
import Button from '../../../../../../components/Button';
import useMedias from '../../../../../../customHooks/useMedias';
import {modalWidth} from '../../../../../../constants/styles';
import gcss from '../../../../../../utils/styles';
import css from './Verification3DSModal.module.less';
import useCheckFeatureConfig from '../../../../../../customHooks/useCheckFeatureConfig';
import {IntercomHelpArticles} from '../../../../../../types';
import Features from '../../../../../../constants/features';
import {showMessengerArticle} from '../../../../../../utils/messenger';

interface Props {
  onClose: () => void;
}

const Verification3DSModal: React.FC<Props> = ({onClose}) => {
  const media = useMedias();
  const intl = useIntl();
  const articlesConfig = useCheckFeatureConfig<IntercomHelpArticles>(
    Features.IntercomArticleIds
  );
  const [visible, setVisible] = useState(true);

  const isButtonVisible = useMemo(() => {
    return (
      !!articlesConfig &&
      !!articlesConfig?.how_to_enable_in_app_method_learn_more
    );
  }, [articlesConfig]);

  return (
    <Modal
      className={clsx(css.modal, 'ant-modal_sticky')}
      visible={visible}
      width={modalWidth}
      footer={false}
      destroyOnClose
      centered={!media.xs}
      maskClosable={false}
      onCancel={() => {
        setVisible(false);
      }}
      afterClose={() => {
        onClose();
      }}
    >
      <div className={css.modal__image} />

      <div className={css.modal__title}>
        {intl.formatMessage({
          id:
            'Verification3DSModal-3DS_verification_through_the_Wallester_Business_Mobile_Applic',
        })}
      </div>

      <div className={css.modal__desc}>
        {intl.formatMessage({
          id:
            'Verification3DSModal-Confirm_payments_easily_with_a_swipe_in_the_Wallester_App_for',
        })}
      </div>

      <div
        className={clsx(
          css.modal__footer,
          !isButtonVisible ? css.modal__footer_centered : ''
        )}
      >
        <Button
          className={clsx(
            'ant-btn_transparent ant-btn_text-blue',
            media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger',
            gcss('t_font-weight_700'),
            gcss('t_transform_uppercase'),
            css.modal__actionCancel
          )}
          onClick={() => {
            setVisible(false);
          }}
        >
          {intl.formatMessage({id: 'App-Dont_show_again'})}
        </Button>

        {isButtonVisible && (
          <Button
            className={clsx(
              'ant-btn_green-emerald',
              gcss('t_font-weight_700'),
              gcss('t_transform_uppercase'),
              media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger'
            )}
            onClick={() => {
              setVisible(false);

              if (articlesConfig?.how_to_enable_in_app_method_learn_more) {
                showMessengerArticle(
                  articlesConfig.how_to_enable_in_app_method_learn_more
                );
              }
            }}
          >
            {intl.formatMessage({id: 'App-Learn_more'})}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default Verification3DSModal;
