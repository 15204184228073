import clsx from 'clsx';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import Button from '../../../components/Button';
import useMedias from '../../../customHooks/useMedias';
import {gotoLogin} from '../../../models/router';
import AuthService from '../../../services/AuthService';
import Scrollbar from '../../../components/Scrollbar';
import cssLayout from '../../LayoutSimple/LayoutSimple.module.less';
import css from './Maintenance.module.less';
import gcss from '../../../utils/styles';

const Maintenance: React.FC = () => {
  const media = useMedias();

  const handleHomeClick = async () => {
    await AuthService.getInstance().logout();
    gotoLogin();
  };

  return (
    <Scrollbar wrapperAttributes={{className: cssLayout.layout}}>
      <div className={cssLayout.layout__inner}>
        <div className={cssLayout.layout__content}>
          <div className={css.error}>
            <div className={css.errorInner}>
              <div className={css.errorLogo} />

              <div className={css.errorTitle}>
                <FormattedMessage id="App-Currently_under_maintenance_" />
              </div>

              <div className={css.errorDesc}>
                <FormattedMessage id="App-We_regret_the_inconvenience_caused_" />
              </div>

              <Button
                onClick={handleHomeClick}
                type="primary"
                shape="round"
                className={clsx(
                  'ant-btn_wide ant-btn_green-emerald',
                  gcss('t_font-weight_700'),
                  gcss('t_transform_uppercase'),
                  media.xs ? 'ant-btn_middle-extra' : 'ant-btn_middle-bigger',
                  css.errorAction
                )}
              >
                <FormattedMessage id="App-Go_to_home_page" />
              </Button>
            </div>
          </div>
        </div>

        <div className={cssLayout.layout__footer}>
          © {new Date().getFullYear()} Wallester AS.&nbsp;&nbsp;
          <FormattedMessage id="App-All_rights_reserved" />
        </div>
      </div>
    </Scrollbar>
  );
};

export default Maintenance;
